import { useEffect, useRef } from 'react'

// from https://medium.com/@pdx.lucasm/canvas-with-react-js-32e133c05258
// and https://github.com/lucas-lm/react-canvas-dom

const updateCanvasSize = (canvas, context, square = false) => {
  const { width, height } = canvas.getBoundingClientRect() // THIS IS THE PROBLEM LINE HERE...

  if (canvas.width !== width || canvas.height !== height) {
    const { devicePixelRatio: ratio = 1 } = window

    if (width < height) {
      canvas.height = width * ratio
      canvas.width = width * ratio
    } else {
      canvas.height = height * ratio
      canvas.width = width * ratio
    }
    context.scale(ratio, ratio)
    return true
  }
  return false
}

const useCanvas = (draw, square, options = {}) => {
  const canvasRef = useRef(null)

  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas.getContext(options.context || '2d')
    let frameCount = 0
    let animationFrameId

    const render = () => {
      frameCount++
      updateCanvasSize(canvas, context, square)
      draw(context, frameCount)
      animationFrameId = window.requestAnimationFrame(render)
    }
    render()

    return () => {
      window.cancelAnimationFrame(animationFrameId)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [draw /*, canvasRef*/])

  return canvasRef
}

export default useCanvas
