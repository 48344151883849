import { useRef, useEffect } from 'react'
import { CancelToken, isCancel } from 'axios'

// import { isDev } from '../env'

// sourced from: https://dev.to/tmns/usecanceltoken-a-custom-react-hook-for-cancelling-axios-requests-1ia4
// made into an npm package: https://github.com/AXeL-dev/react-use-cancel-token

/**
 * When a component unmounts, we need to cancel any potentially
 * ongoing axios calls that result in a state update on success / fail
 * Thie function sets up the appropriate useEffect to handle the cancelling.
 *
 * @returns {newCancelToken: function, cancelPreviousRequest: function, isCancel: function}
 * newCancelToken - used to generate the cancel token sent in the axios request.
 * cancelPreviousRequest - used to manually cancel the previous Axios request.
 * isCancel - used to check if error returned in response is a cancel token error.
 */
const useAxiosCancelToken = () => {
  const axiosSource = useRef(null)
  const newCancelToken = () => {
    axiosSource.current = CancelToken.source()
    return axiosSource.current.token
  }

  const cancelPreviousRequest = (message) => {
    if (axiosSource.current) {
      // if ( isDev ) console.log('manual cancel req')
      axiosSource.current.cancel(message)
    }
  }

  useEffect(
    () => () => {
      if (axiosSource.current) {
        // if (isDev) console.log('auto cancel req')
        axiosSource.current.cancel()
      }
    },
    [],
  )

  return { newCancelToken, cancelPreviousRequest, isCancel }
}

export default useAxiosCancelToken
