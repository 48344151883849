import React from 'react'

import { makeStyles } from '@material-ui/styles'
import { appColors } from 'utils'
import SimpleStyledList from 'components/common/SimpleStyledList'

const useStyles = makeStyles((theme) => ({
  listContainer: {
    display: 'flex',
    overflow: 'hidden',
    height: '100%',
    border: '1px solid #000',
    borderRadius: '4px',
  },
  headerText: {
    fontSize: 14,
    fontWeight: '500',
    color: appColors.headerTextColor,
    width: '96px',
    textAlign: 'right',
    paddingRight: '8px',
  },
  itemText: {
    fontSize: 18,
    color: appColors.itemTextColor,
    border: '',
  },
  itemMainContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '8px',
    marginBottom: '1px',
    border: `1px solid`,
    borderColor: appColors.itemBackColor,
    backgroundColor: 'inherit',
    '&:hover': {
      borderColor: appColors.accentColor,
    },
  },
  itemTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  itemTextWrapContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
  },
}))

const DbConnectionsList = ({ data }) => {
  const classes = useStyles()

  const renderTextField = (text, value) => {
    return (
      <div className={classes.itemTextContainer}>
        <div className={classes.headerText}>{text} </div>
        <div className={classes.itemText}>{value}</div>
      </div>
    )
  }

  const renderItem = (connInfo, idx) => {
    return (
      <div key={idx} className={classes.itemMainContainer}>
        <div className={classes.itemTextContainer}>
          <div className={classes.itemTextWrapContainer}>
            {renderTextField('IP Address: ', connInfo.clientNatAddress)}
            {renderTextField('Host Name: ', connInfo.applicationName)}
          </div>
          <div className={classes.itemTextWrapContainer}>
            {renderTextField('Login Name: ', connInfo.loginName)}
            {renderTextField('# Connections: ', connInfo.connectionCount)}
          </div>
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className={classes.listContainer}>
        <SimpleStyledList listItems={data} renderItem={renderItem}></SimpleStyledList>
      </div>
    </React.Fragment>
  )
}

export default DbConnectionsList
