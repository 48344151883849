import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'

import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { makeStyles } from '@material-ui/styles'
import { Menu, MenuItem, IconButton, Tooltip, Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { useRecoilState } from 'recoil'

import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { useSetRecoilState } from 'recoil'
import { currentPageAtom } from 'atoms'
import InputModal from 'components/common/InputModal'
import * as yup from 'yup'
import { userPrefsAtom } from 'atoms'
import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import { useAuth0 } from '@auth0/auth0-react'

const useStyles = makeStyles((theme) => ({
  list: {
    width: theme.spacing(32),
  },
  menuContainer: {
    marginRight: '12px',
  },
  menuButton: {
    margin: '16px',
  },
  item: {
    padding: '0px 4px',
  },
  tooltip: {
    backgroundColor: 'rgb(19,62,96)',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
}))

const UNITS = ['API', 'Metric-Mixed', 'Metric', 'US']

const NavBarUserProfile = ({ history, isAuthenticated, logout }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [showUnitsModal, setUnitsModal] = useState(false)
  const [showPasswordModal, setPasswordModal] = useState(false)
  const [userPrefs, setUserPrefs] = useRecoilState(userPrefsAtom)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const setActivePage = useSetRecoilState(currentPageAtom)
  const { user } = useAuth0()

  const { newCancelToken: updateUnitsCancelToken, cancelPreviousRequest: cancelUpdateUnitsReq } = useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorUpdateUnits }, updateUnits] = useAxios({
    url: '/user/storePrefs',
    manual: true,
    cancelToken: updateUnitsCancelToken(),
  })

  const { newCancelToken: updatePwdCancelToken, cancelPreviousRequest: cancelUpdatePwdReq } = useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorUpdatePwd }, updatePwd] = useAxios({
    url: '/admin/resetUserPwd',
    manual: true,
    cancelToken: updatePwdCancelToken(),
  })

  useEffect(() => {
    if (errorUpdateUnits !== undefined && errorUpdateUnits !== null) {
      if (cancelUpdateUnitsReq(errorUpdateUnits)) return
    }
  }, [errorUpdateUnits]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorUpdatePwd !== undefined && errorUpdatePwd !== null) {
      if (cancelUpdatePwdReq(errorUpdatePwd)) return
    }
  }, [errorUpdatePwd]) // eslint-disable-line react-hooks/exhaustive-deps

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const logoutAction = () => {
    logout({
      returnTo: window.location.origin,
    })
    closeMenu()
  }

  const profileAction = () => {
    setActivePage(PAGE_KEYS.userProfileKey)
    history.push('/user-profile')
    closeMenu()
  }

  const RenderChangeUnitsModal = ({ data, submitFunction, cancelFunction }) => {
    const formValidationSchema = yup.object({
      unitPrefs: yup.string().required('units required'),
    })

    let formData = [
      {
        tag: 'unitPrefs',
        value: userPrefs ? userPrefs : '',
        text: 'Units',
        inputType: 'dropdown',
        dropDownValues: UNITS.map((unit) => {
          return { label: unit, value: unit }
        }),
        useLabel: true,
      },
    ]

    return (
      <InputModal
        open={showUnitsModal}
        onClose={cancelFunction}
        title={'Change units'}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={cancelFunction}
        validationSchema={formValidationSchema}
      />
    )
  }

  const RenderChangePasswordModal = ({ data, submitFunction, cancelFunction }) => {
    const formValidationSchema = yup.object({
      password: yup
        .string()
        .required('Password is required')
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-])[A-Za-z\d@$!%*#?&-]{8,}$/,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
        ),
      passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
    })

    let formData = [
      {
        tag: 'authUserName',
        value: user?.name,
        inputType: '-',
      },
      {
        tag: 'password',
        value: '',
        text: 'New Password',
        inputType: 'text',
      },
      {
        tag: 'passwordConfirmation',
        value: '',
        text: 'Confirm Password',
        inputType: 'text',
      },
    ]

    return (
      <InputModal
        open={showPasswordModal}
        onClose={cancelFunction}
        title={'Change Password'}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={cancelFunction}
        validationSchema={formValidationSchema}
      />
    )
  }

  const handleCloseUnits = () => {
    setUnitsModal(false)
  }

  const handleClosePassword = () => {
    setPasswordModal(false)
  }

  const handleCloseStatus = (event, reason) => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const submitChangeUnits = async (data, formikActions) => {
    setUnitsModal(false)
    if (!data) return false

    let saveResponse = await updateUnits({ ...data }, updateUnitsCancelToken())

    if (saveResponse.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: 'Failed to update units',
      })
      formikActions?.setSubmitting(false)
      return false
    } else {
      setStatus({ show: true, severity: 'success', message: 'Units updated' })
      setUserPrefs(data.unitPrefs)
    }

    return true
  }

  const submitChangePassword = async (data, formikActions) => {
    setPasswordModal(false)
    if (!data) return false

    let saveResponse = await updatePwd({ ...data }, updatePwdCancelToken())

    if (saveResponse.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: 'Failed to update password',
      })
      formikActions?.setSubmitting(false)
      return false
    } else {
      setStatus({ show: true, severity: 'success', message: 'Password updated' })
    }

    return true
  }

  return isAuthenticated ? (
    <>
      {showUnitsModal ? (
        <RenderChangeUnitsModal data={null} submitFunction={submitChangeUnits} cancelFunction={handleCloseUnits} />
      ) : null}
      {showPasswordModal ? (
        <RenderChangePasswordModal
          data={null}
          submitFunction={submitChangePassword}
          cancelFunction={handleClosePassword}
        />
      ) : null}
      <Tooltip title={'User profile'} placement='bottom' classes={{ tooltip: classes.tooltip }}>
        <IconButton aria-controls='simple-menu' onClick={openMenu}>
          <AccountCircleIcon />
        </IconButton>
      </Tooltip>
      <Menu id='simple-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeMenu}>
        <MenuItem onClick={profileAction}>Profile</MenuItem>
        <MenuItem onClick={() => setUnitsModal(!showUnitsModal)}>Change Units</MenuItem>
        <MenuItem onClick={() => setPasswordModal(!showPasswordModal)}>Change Password</MenuItem>
        <MenuItem onClick={logoutAction}>Logout</MenuItem>
      </Menu>
      {status?.show ? (
        <Snackbar open={status?.show} autoHideDuration={2000} onClose={handleCloseStatus}>
          <MuiAlert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </MuiAlert>
        </Snackbar>
      ) : null}
    </>
  ) : null
}

export default withRouter(NavBarUserProfile)
