import React, { useEffect, useRef } from 'react'
import map from 'lodash/map'

import Table from 'components/common/Table'
import useAxiosWithUnitFetch from 'components/common/hooks/useAxiosWithUnitFetch'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'

import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'

import columns from '../utils'

const adjustColumnTitles = (key, units) =>
  map(columns[key], (column) => ({
    ...column,
    title: column.unit ? `${column.title} (${units[column.unit]})` : column.title,
  }))

const getRowColor = (actCode) => {
  let processedCode = actCode
  if (actCode.substring(0, 1) === '8') {
    processedCode = '8'
  }
  switch (processedCode) {
    case '8': // all 8X error codes
      return '#FF63637D'
    case '6O': // BHA pickup
    case '6P': // BHA laydown
      return '#FFEF007D'
    case '26':
      return '#FFB35C7D'
    case '2J': // sliding
      return '#87CEFA7D'
    case '2K': // rotating
      return '#75FA767D'
    default:
      return '#FFF'
  }
}

const DailyActivityTable = ({ apiRoute, columnKey, filtering, search, well }) => {
  const _isMounted = useRef(false)
  const { newCancelToken } = useAxiosCancelToken()
  const [{ data, loading, error }] = useAxiosWithUnitFetch({
    url: apiRoute,
    data: {
      wellName: well,
    },
    cancelToken: newCancelToken(),
  })

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (_isMounted.current && data) {
    }
  }, [data])

  useEffect(() => {
    if (error !== undefined && error !== null) {
      return undefined
    }
  }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

  const units = {
    [UNITS_FOR.Depth]: useUnits(UNITS_FOR.Depth),
    [UNITS_FOR.Diameter]: useUnits(UNITS_FOR.Diameter),
    [UNITS_FOR.Dogleg]: useUnits(UNITS_FOR.Dogleg),
  }
  if (loading) return null

  const currentColumns = adjustColumnTitles(columnKey, units)
  const options = {
    filtering: filtering || false,
    paging: false,
    search: false,
    toolbar: false,
    showTitle: false,
    maxBodyHeight: '625px',
    rowStyle: (rowData) => {
      const c = getRowColor(rowData.activity)
      return { backgroundColor: c }
    },
  }

  return <Table data={data} loading={loading} columns={currentColumns} options={options} />
}

export default DailyActivityTable
