import React, { useEffect } from 'react'

import DrillStringHeaderCard from './DrillStringHeaderCard'
import Page from '../../common/Page'
import { currentPageAtom } from '../../../atoms'
import { useSetRecoilState } from 'recoil'
import { PAGE_KEYS } from '../../../components/ActionBar/pageDefs'

const DrillStringHeaderPage = ({ history }) => {
  const setActivePage = useSetRecoilState(currentPageAtom)

  useEffect(() => {
    setActivePage(PAGE_KEYS.drillStringKey)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page maxWidth>
      <DrillStringHeaderCard history={history} />
    </Page>
  )
}

export default DrillStringHeaderPage
