import React from 'react'
import { actionBarWidthAtom, show3DControlsAtom } from '../../atoms'
import { appColors } from 'utils'
import { makeStyles } from '@material-ui/core/styles'
import { round } from '../../utils/numberFunctions'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Icon as Iconify } from '@iconify/react'

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    textAlign: 'left',
    display: 'box',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignSelf: 'start',
    pointerEvents: 'none',
    marginLeft: (props) => (props.onCard ? 0 : props.leftPos),
    top: (props) => (props.onCard ? 29 : 100),
    left: (props) => (props.onCard ? 6 : 32),
  },
  maxWidth: {
    maxWidth: '100%',
  },
  textHeader: {
    color: appColors.headerTextColor,
    fontWeight: 'bold',
  },
  textItem: {
    color: appColors.itemTextColor,
    fontWeight: 'bold',
  },
  iconCont: {
    position: 'relative',
    zIndex: 1,
    '&:hover': {
      border: '1px solid',
      borderColor: appColors.accentColor,
    },
  },
  iconStyle: {
    color: appColors.headerTextColor,
    fontSize: '16px',
    marginTop: '4px',
  },
}))

export const Well3DHeader = ({ well, refData, index, onCard = false }) => {
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const classes = useStyles({ leftPos, onCard })
  const [threeDeeControls, setThreeDeeControls] = useRecoilState(show3DControlsAtom)

  let showAcData = false
  let currentStation = null
  if (Array.isArray(refData) && refData.length > 0) {
    if (Array.isArray(refData[0].svyData)) {
      if (index >= 0 && index < refData[0].svyData.length) {
        currentStation = refData[0].svyData[index]
        if (currentStation.hasOwnProperty('acScan')) showAcData = true
      }
    }
  }

  const toggle3DSettings = () => {
    setThreeDeeControls(!threeDeeControls)
  }

  const render3DSettingsIcon = () => {
    return (
      <div className={classes.iconCont} onClick={() => toggle3DSettings()}>
        <Iconify icon='ci:settings-filled' className={classes.iconStyle} />
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <div className={classes.textHeader}>Well:</div>
        <div className={classes.textHeader}>MD: </div>
        <div className={classes.textHeader}>INC: </div>
        <div className={classes.textHeader}>AZI: </div>
        <div className={classes.textHeader}>TVD: </div>
        <div className={classes.textHeader}>NS: </div>
        <div className={classes.textHeader}>EW: </div>
        <div className={classes.textHeader}>DLS: </div>
        <div className={classes.textHeader}>TF: </div>
        <div className={classes.textHeader}>UD: </div>
        <div className={classes.textHeader}>LR: </div>
        <div className={classes.textHeader}>DIST: </div>
        {onCard ? render3DSettingsIcon() : null}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 10 }}>
        <div className={classes.textItem}>{well} </div>
        <div className={classes.textItem}>{currentStation ? currentStation.md : ''}</div>
        <div className={classes.textItem}>{currentStation ? currentStation.inc : ''}</div>
        <div className={classes.textItem}>{currentStation ? currentStation.azi : ''}</div>
        <div className={classes.textItem}>{currentStation ? currentStation.tvd : ''}</div>
        <div className={classes.textItem}>{currentStation ? currentStation.ns : ''}</div>
        <div className={classes.textItem}>{currentStation ? currentStation.ew : ''}</div>
        <div className={classes.textItem}>{currentStation ? currentStation.dls : ''}</div>
        <div className={classes.textItem}>{currentStation ? currentStation.tfo : ''}</div>
        <div className={classes.textItem}>{showAcData ? round(currentStation.acScan.UD, 2) : ''}</div>
        <div className={classes.textItem}>{showAcData ? round(currentStation.acScan.LR, 2) : ''}</div>
        <div className={classes.textItem}>{showAcData ? round(currentStation.acScan.dist, 2) : ''}</div>
      </div>
    </div>
  )
}
