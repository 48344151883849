import { searchFunction } from './searchFunctions'

export const reduceWellData = (data, searchText) => {
  if (!Array.isArray(data)) return []
  return data.reduce((output, well) => {
    if (
      searchFunction(well, searchText, ['actualWell', 'jobNum', 'operator', 'state', 'county', 'rig', 'wellStatus']) ===
      false
    ) {
      return output
    }
    let index = output.findIndex((element) => element.operator === well.operator)
    if (index < 0) {
      output.push({
        header: well.operator,
        operator: well.operator,
        data: [
          {
            id: well.actualWell,
            desc: well.actualWell,
            ...well,
          },
        ],
      })
    }
    if (index >= 0) {
      output[index].data.push({
        id: well.actualWell,
        desc: well.actualWell,
        ...well,
      })
    }
    return output
  }, [])
}

export const reduceVirtualizedWellData = (data, searchText) => {
  if (!Array.isArray(data)) return []
  const opOrderedData = data.reduce((output, well) => {
    if (
      searchFunction(well, searchText, ['actualWell', 'jobNum', 'operator', 'state', 'county', 'rig', 'wellStatus']) ===
      false
    ) {
      return output
    }

    let index = output.findIndex((element) => element.operator === well.operator)
    if (index < 0) {
      output.push({
        header: well.operator,
        operator: well.operator,
        data: [
          {
            ...well,
          },
        ],
      })
    }
    if (index >= 0) {
      output[index].data.push({
        ...well,
      })
    }
    return output
  }, [])

  let flatList = []
  opOrderedData.forEach((item) => {
    flatList.push({
      header: item.header,
      operator: item.operator,
      ...item.data,
    })
    item.data.forEach((dataItem) => {
      flatList.push({ ...dataItem })
    })
  })

  return flatList
}
