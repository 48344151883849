import React, { useEffect, useRef, useState } from 'react'

import PrintIcon from '@material-ui/icons/Print'
import { makeStyles } from '@material-ui/styles'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { currentToolOrderAtom, userUserRoleAtom } from 'atoms'
import { IconButton, Tooltip, Snackbar, ListItem, Typography } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import AddIcon from '@material-ui/icons/Add'
import RefreshIcon from '@material-ui/icons/Refresh'
import { Icon as Iconify } from '@iconify/react'
import ConfirmDialog from 'components/common/ConfirmDialog'
import InputModal from 'components/common/InputModal'
import SearchBar from 'material-ui-search-bar'
import { filter as _filter } from 'lodash'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { searchFunction } from '../common/searchFunctions'
import { unescapeHtml } from '../../utils/htmlSymbolHandling'
import { checkPermission } from '../userPermissions'

import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import { appColors, isoStringToDateString, daysSinceActive, getDaysText } from 'utils'
import * as XLSX from 'xlsx'
import * as yup from 'yup'
import MenuButton from 'components/common/MenuButton'

const useStyles = makeStyles((theme) => ({
  listContainer: {
    display: 'flex',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(calc(100vh - 100px) * 1.00)',
    maxHeight: 'calc(calc(100vh - 210px) * 1.00)',
    borderRadius: '4px',
    backgroundColor: theme.palette.itemBackground,
    width: '100%',
  },
  headerText: {
    fontSize: 14,
    fontWeight: '500',
    color: appColors.headerTextColor,
    paddingRight: '8px',
  },
  itemText: {
    maxWidth: '200px',
    fontSize: 14,
    color: appColors.itemTextColor,
    border: '',
    alignItems: 'center',
  },
  title: {
    marginTop: 2,
    font: '18px Roboto',
    color: appColors.itemTextColorMobile,
    textAlign: 'left',
    width: '98%',
  },
  deleteIconStyle: {
    color: '#C00000',
    fontSize: '24px',
  },
  navigateIconStyle: {
    color: appColors.headerTextColor,
    fontSize: '24px',
  },
  fixedListContainer: {
    display: 'flex',
    borderRadius: '4px',
    backgroundColor: theme.palette.itemBackground,
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#136cb9', //'darkgrey', //'rgba(0,0,0,.1)',
      outline: '1px solid #1679d0',
    },
    overflow: 'auto',
  },
  itemMainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '4px',
    borderRadius: 5,
    marginLeft: '8px',
    background: 'linear-gradient(to bottom, #2d2d2d 0%, #1c1c1c 100%)',
    marginBottom: '4px',
    width: 'calc(100% - 10px)', // 8px for marginLeft, 2px for left & right border
    border: `1px solid`,
    borderColor: appColors.itemBackColor,
    backgroundColor: appColors.itemBackColor,
    '&:hover': {
      borderColor: appColors.accentColor,
    },
  },
  itemSubContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '8px',
    marginBottom: '1px',
    backgroundColor: 'inherit',
  },
  itemTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  itemTextWrapContainer: {
    flexDirection: 'column',
    flex: 3,
    marginLeft: '5px',
  },
  itemIconContainer: {
    width: 100,
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltip: {
    backgroundColor: 'rgb(19,62,96)',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
  menuIcon: {
    backgroundColor: 'transparent',
    margin: '4px',
    padding: '12px',
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  searchContainer: {
    backgroundColor: appColors.itemBackColor,
    padding: '8px',
  },
}))

const ToolOrdersList = ({ history }) => {
  const _isMounted = useRef(false)
  const classes = useStyles()
  const [searchText, setSearchText] = useState('')
  const setCurrentToolOrder = useSetRecoilState(currentToolOrderAtom)
  const [statusList, setStatusList] = React.useState([])
  const [connectionList, setConnectionList] = React.useState([])
  const userRole = useRecoilValue(userUserRoleAtom)
  const editIndex = useRef(-1)
  const [filteredList, setFilteredList] = useState([])
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [showEditor, setShowEditor] = useState(false)
  const listRef = useRef()

  const { newCancelToken: newToolOrderCancelToken, cancelPreviousRequest: cancelPreviousToolOrderRequest } =
    useAxiosCancelToken()
  const [{ data: toolOrders, error: toolOrdersError }, getToolOrders] = useAxios({
    url: '/toolOrders/getToolOrderHeaders',
    cancelToken: newToolOrderCancelToken(),
  })

  const { newCancelToken: newStatusListRawCancelToken, cancelPreviousRequest: cancelPreviousStatusListRawRequest } =
    useAxiosCancelToken()
  const [{ data: statusListRaw, error: statusListRawError }] = useAxios({
    url: '/toolOrders/getFilterLists',
    cancelToken: newStatusListRawCancelToken(),
  })

  const { newCancelToken: newConnListRawCancelToken, cancelPreviousRequest: cancelPreviousConnListRawRequest } =
    useAxiosCancelToken()
  const [{ data: connListRaw, error: connListRawError }] = useAxios({
    url: '/toolOrders/getDropDownLists',
    cancelToken: newConnListRawCancelToken(),
  })

  const { newCancelToken: newDeleteCancelToken, cancelPreviousRequest: cancelPreviousDeleteRequest } =
    useAxiosCancelToken()
  const [{ error: deleteError }, deleteToolOrder] = useAxios({
    url: '/toolOrders/deleteToolOrder',
    manual: true,
    cancelToken: newDeleteCancelToken(),
  })

  const { newCancelToken: createCancelToken, cancelPreviousRequest: cancelCreateReq } = useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorCreate }, addToolOrder] = useAxios({
    url: '/toolOrders/addToolOrder',
    manual: true,
    cancelToken: createCancelToken(),
  })

  const { newCancelToken: updateCancelToken, cancelPreviousRequest: cancelUpdateReq } = useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorUpdate }, updateToolOrder] = useAxios({
    url: '/toolOrders/updateToolOrder',
    manual: true,
    cancelToken: updateCancelToken(),
  })

  useEffect(() => {
    if (_isMounted.current) {
      getStatusList()
    }
  }, [statusListRaw]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      getConnectionList()
    }
  }, [connListRaw]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (toolOrders) {
      setFilteredList(handleSearch(searchText))
    }
  }, [toolOrders]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      const filtered = handleSearch(searchText)
      setFilteredList(filtered)
    }
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (toolOrdersError !== undefined && toolOrdersError !== null) {
      if (cancelPreviousToolOrderRequest(toolOrdersError)) return
    }
  }, [toolOrdersError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (statusListRawError !== undefined && statusListRawError !== null) {
      if (cancelPreviousStatusListRawRequest(statusListRawError)) return
    }
  }, [statusListRawError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (connListRawError !== undefined && connListRawError !== null) {
      if (cancelPreviousConnListRawRequest(connListRawError)) return
    }
  }, [connListRawError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleteError !== undefined && deleteError !== null) {
      if (cancelPreviousDeleteRequest(deleteError)) return
    }
  }, [deleteError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorUpdate !== undefined && errorUpdate !== null) {
      if (cancelUpdateReq(errorUpdate)) return
    }
  }, [errorUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorCreate !== undefined && errorCreate !== null) {
      if (cancelCreateReq(errorCreate)) return
    }
  }, [errorCreate]) // eslint-disable-line react-hooks/exhaustive-deps

  function getStatusList() {
    if (!statusListRaw) return
    if (!statusListRaw.hasOwnProperty('status')) return
    if (!Array.isArray(statusListRaw.status)) return

    let newList = []
    statusListRaw.status.forEach((stat) => {
      newList.push({ label: stat, value: `${stat}` })
    })

    if (_isMounted.current === true) setStatusList(newList)
  }

  function getConnectionList() {
    if (!Array.isArray(connListRaw)) return

    let connectionIndex = -1
    connListRaw.forEach((list, index) => {
      if (list.hasOwnProperty('tagName')) {
        if (list.tagName === 'CONNECTION_TYPE') connectionIndex = index
      }
    })

    let newList = []

    if (connectionIndex < 0) return
    if (!Array.isArray(connListRaw[connectionIndex].menuItems)) return
    connListRaw[connectionIndex].menuItems.forEach((listItem) => {
      newList.push({ label: unescapeHtml(listItem), value: `${unescapeHtml(listItem)}` })
    })

    if (_isMounted.current === true) setConnectionList(newList)
  }

  const showOrderDetails = (index) => {
    setCurrentToolOrder({
      toolOrderId: filteredList[index].toolOrderId,
      jobNumber: filteredList[index].jobNum,
      rig: filteredList[index].rig,
    })
    history.push('/toolOrderDetails')
  }

  const renderTextField = (text, value) => {
    return (
      <div className={classes.itemTextContainer}>
        <Typography noWrap className={classes.headerText}>
          {text}
        </Typography>
        <Typography noWrap className={classes.itemText}>
          {value}
        </Typography>
      </div>
    )
  }

  const onClickDelete = (toolOrder) => {
    setConfirm({
      show: true,
      title: 'Delete tool order',
      text: `Are you sure you want to delete for Job#: ${toolOrder?.jobNum} / Rig: ${toolOrder?.rig}?`,
      itemIndex: parseInt(toolOrder?.toolOrderId),
    })
  }

  const confirmDelete = async () => {
    if (confirm?.itemIndex >= 0) {
      const deleteResponse = await deleteToolOrder({
        toolOrderId: confirm?.itemIndex,
      })

      if (!deleteResponse.error) {
        setStatus({ show: true, severity: 'success', message: 'Tool order deleted successfully' })
        await getToolOrders()
      } else {
        setStatus({ show: true, severity: 'error', message: 'Delete tool order failed' })
      }
    }
  }

  const handleNewToolOrder = () => {
    setShowEditor(true)
  }

  const handleCloseStatus = (event, reason) => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const handleClose = () => {
    setShowEditor(false)
  }

  const getStatusTextColor = (status) => {
    if (typeof status !== 'string') return '#ff0a0a'
    status = status.toLowerCase()
    if (status === 'draft') return '#FFEF00EF'
    if (status === 'submitted') return '#0096FF'
    if (status === 'in progress') return '#F59B00EF'
    if (status === 'complete') return '#59FF00CF'
    if (status === 'shipped') return '#59FF00CF'
    if (status === 'request from rig') return '#ff0a0a'
    return '#ff0a0a'
  }

  const renderToolOrder = (props) => {
    const { index, style } = props
    const item = filteredList[index]

    let deltaDays = daysSinceActive(item.dateNeeded)
    let daysText = getDaysText(deltaDays)

    return (
      <ListItem style={style} disableGutters={true}>
        <div className={classes.itemMainContainer}>
          <div className={classes.title}>
            <span style={{ marginLeft: 5, marginTop: 8 }}>{unescapeHtml(item.operatorName)} - </span>
            <span style={{ color: getStatusTextColor(item.status) }}> {item.status}</span>
            <div className={classes.itemSubContainer}>
              <div
                className={classes.itemTextContainer}
                onClick={() => {
                  editIndex.current = index
                  setShowEditor(true)
                }}>
                <div className={classes.itemTextWrapContainer}>
                  {renderTextField('Rig:', item.rig)}
                  {renderTextField('Job#:', item.jobNum)}
                  {renderTextField('Well:', item.actualWell)}
                </div>
                <div className={classes.itemTextWrapContainer}>
                  {renderTextField('State:', item.state)}
                  {renderTextField('County:', item.county)}
                  {renderTextField('DD Coord:', item.ddCoordinator)}
                </div>
                <div className={classes.itemTextWrapContainer}>
                  {renderTextField('Hole Size:', item.holeSize)}
                  {renderTextField('Mud Type:', item.mudType)}
                  {renderTextField('Mud Weight:', item.mudWeight)}
                </div>
                <div className={classes.itemTextWrapContainer}>
                  {renderTextField('Min Temp:', item.minTemp)}
                  {renderTextField('Max Temp:', item.maxTemp)}
                  {renderTextField('DP Cnx:', item.drillPipeConnection)}
                </div>
                <div className={classes.itemTextWrapContainer}>
                  {renderTextField('Created:', item.createdBy)}
                  {renderTextField('Modified:', item.modifiedBy)}
                  {renderTextField('Needed:', isoStringToDateString(item.dateNeeded))}
                </div>
                <div className={classes.itemTextWrapContainer}>
                  {renderTextField('Created:', isoStringToDateString(item.dateNeeded))}
                  {renderTextField('Modified:', isoStringToDateString(item.modifiedDate))}
                  {renderTextField('Needed:', daysText)}
                </div>
              </div>
              {checkPermission('canDelete', userRole.roleAttributes?.permissions) ? (
                <Tooltip title='Delete tool order' placement='bottom' classes={{ tooltip: classes.tooltip }}>
                  <IconButton className={classes.itemIconContainer} onClick={() => onClickDelete(item)}>
                    <Iconify icon='fa-regular:trash-alt' className={classes.deleteIconStyle} />
                  </IconButton>
                </Tooltip>
              ) : null}
              <Tooltip title='Edit tool terms' placement='bottom' classes={{ tooltip: classes.tooltip }}>
                <IconButton className={classes.itemIconContainer} onClick={() => showOrderDetails(index)}>
                  <Iconify icon='akar-icons:chevron-right' className={classes.navigateIconStyle} />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        </div>
      </ListItem>
    )
  }

  const RenderCreateUpdateModal = ({ data, submitFunction, cancelFunction }) => {
    let useInputData = true
    if (!data) useInputData = false
    if (useInputData === true) {
      if (data.hasOwnProperty('toolOrderId') === false) useInputData = false
    }

    const formValidationSchema = yup.object({
      operatorName: yup.string().nullable(),
      jobNum: yup.string().nullable(),
      actualWell: yup.string().nullable(),
      holesize: yup.string().matches(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed'),
      mudType: yup.string().nullable(),
      mudWeight: yup.string().matches(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed'),
      dateNeeded: yup.string().nullable(),
      minTemp: yup.string().matches(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed'),
      maxTemp: yup.string().matches(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed'),
      status: yup.string().nullable(),
      comments: yup.string().nullable(),
      toolComments: yup.string().nullable(),
      motorComments: yup.string().nullable(),
      ddCoordinator: yup.string().nullable(),
      mwdCoordinator: yup.string().nullable(),
      drillPipeConnection: yup.string().nullable(),
    })

    let formData = [
      {
        tag: 'toolOrderId',
        value: useInputData === true ? data.toolOrderId : '',
        inputType: '-',
      },
      {
        tag: 'operatorName',
        value: useInputData === true ? data.operatorName : '',
        text: 'Operator',
        inputType: 'text',
      },
      {
        tag: 'jobNum',
        value: useInputData === true ? data.jobNum : '',
        text: 'Job #',
        inputType: 'text',
      },
      {
        tag: 'actualWell',
        value: useInputData === true ? data.actualWell : '',
        text: 'Well Name',
        inputType: 'text',
      },
      {
        tag: 'holeSize',
        value: useInputData === true ? (typeof data.holeSize === 'number' ? data.holeSize.toString() : 0) : '',
        text: 'Hole Size',
        inputType: 'text',
      },
      {
        tag: 'mudType',
        value: useInputData === true ? data.mudType : '',
        text: 'Mud Type',
        inputType: 'text',
      },
      {
        tag: 'mudWeight',
        value: useInputData === true ? (typeof data.mudWeight === 'number' ? data.mudWeight.toString() : 0) : '',
        text: 'Mud Weight',
        inputType: 'text',
      },
      {
        tag: 'dateNeeded',
        value: useInputData === true ? data.dateNeeded : '',
        text: 'Date Needed',
        inputType: 'date',
      },
      {
        tag: 'minTemp',
        value: useInputData === true ? (typeof data.minTemp === 'number' ? data.minTemp.toString() : 0) : '',
        text: 'Min Temperature',
        inputType: 'text',
      },
      {
        tag: 'maxTemp',
        value: useInputData === true ? (typeof data.maxTemp === 'number' ? data.maxTemp.toString() : 0) : '',
        text: 'Max Temperature',
        inputType: 'text',
      },
      {
        tag: 'status',
        value: useInputData === true ? data.status : '',
        text: 'Status',
        inputType: 'dropdown',
        dropDownValues: statusList,
      },
      {
        tag: 'comments',
        value: useInputData === true ? data.comments : '',
        text: 'Comments',
        inputType: 'text',
      },
      {
        tag: 'ddCoordinator',
        value: useInputData === true ? data.ddCoordinator : '',
        text: 'DD Coordinator',
        inputType: 'text',
      },
      {
        tag: 'mwdCoordinator',
        value: useInputData === true ? data.mwdCoordinator : '',
        text: 'MWD Coordinator',
        inputType: 'text',
      },
      {
        tag: 'toolComments',
        value: useInputData === true ? data.toolComments : '',
        text: 'Tool Comments',
        inputType: 'text',
      },
      {
        tag: 'motorComments',
        value: useInputData === true ? data.motorComments : '',
        text: 'Motor Comments',
        inputType: 'text',
      },
      {
        tag: 'drillPipeConnection',
        value: useInputData === true ? data.drillPipeConnection : '',
        text: 'Drill Pipe Connection',
        inputType: connectionList.length > 0 ? 'dropdown' : 'text',
        dropDownValues: connectionList,
      },
    ]

    const title = (useInputData ? 'Edit' : 'Add') + ' tool order'

    return (
      <InputModal
        open={showEditor}
        onClose={cancelFunction}
        title={title}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={cancelFunction}
        validationSchema={formValidationSchema}
      />
    )
  }

  const submitFunction = async (data, formikActions) => {
    setShowEditor(false)
    if (!data) return false
    let isAdd = false
    if (editIndex.current < 0) {
      isAdd = true
    }
    let payload = { ...data }

    let saveResponse
    if (isAdd) {
      saveResponse = await addToolOrder(payload, createCancelToken())
    } else {
      saveResponse = await updateToolOrder(payload, updateCancelToken())
    }

    if (saveResponse.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: isAdd ? 'Tool order failed to add' : 'Tool order update failed',
      })
      formikActions?.setSubmitting(false)
      return false
    } else {
      setStatus({ show: true, severity: 'success', message: isAdd ? 'Tool order added' : 'Tool order updated' })
    }

    await getToolOrders()
    return true
  }

  const onXlsxExport = () => {
    if (!toolOrders) return
    if (!Array.isArray(toolOrders)) return
    if (toolOrders.length === 0) return

    let headers = []
    headers.push('#')
    headers.push('Job #')
    headers.push('Operator')
    headers.push('Well')
    headers.push('State')
    headers.push('County')
    headers.push('Status')
    headers.push('Hole Size')
    headers.push('Mud Type')
    headers.push('Mud Weight')
    headers.push('Min Temp')
    headers.push('Max Temp')
    headers.push('DP Cnx')
    headers.push('DD Coord')
    headers.push('MWD Coord')
    headers.push('Created By')
    headers.push('Date Created')
    headers.push('Modified By')
    headers.push('Date Modified')
    headers.push('Date Needed')
    headers.push('Days Needed')
    headers.push('Comments')

    let ws = XLSX.utils.aoa_to_sheet([headers], {
      origin: 'A1',
    })

    let xlExport = []
    for (let i = 0; i < toolOrders.length; i++) {
      let deltaDays = daysSinceActive(toolOrders[i].dateNeeded)
      let daysText = getDaysText(deltaDays)

      let newRow = []
      newRow.push(i + 1)
      newRow.push(toolOrders[i].jobNum)
      newRow.push(toolOrders[i].operatorName)
      newRow.push(toolOrders[i].actualWell)
      newRow.push(toolOrders[i].state)
      newRow.push(toolOrders[i].county)
      newRow.push(toolOrders[i].status)
      newRow.push(toolOrders[i].holeSize)
      newRow.push(toolOrders[i].mudType)
      newRow.push(toolOrders[i].mudWeight)
      newRow.push(toolOrders[i].minTemp)
      newRow.push(toolOrders[i].maxTemp)
      newRow.push(toolOrders[i].drillPipeConnection)
      newRow.push(toolOrders[i].ddCoordinator)
      newRow.push(toolOrders[i].mwdCoordinator)
      newRow.push(toolOrders[i].createdBy)
      newRow.push(isoStringToDateString(toolOrders[i].createdDate))
      newRow.push(toolOrders[i].modifiedBy)
      newRow.push(isoStringToDateString(toolOrders[i].modifiedDate))
      newRow.push(isoStringToDateString(toolOrders[i].dateNeeded))
      newRow.push(isoStringToDateString(daysText))
      newRow.push(isoStringToDateString(toolOrders[i].comments))
      xlExport.push(newRow)
    }

    XLSX.utils.sheet_add_aoa(ws, xlExport, { origin: 'A2' })

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Tool Orders')

    XLSX.writeFile(wb, 'ToolOrders.xlsx')
  }

  const handleSearch = (text) => {
    if (text && typeof text === 'string' && text !== '') {
      const filterList = _filter(toolOrders, (item) => {
        return searchFunction(item, text, [
          'holeSize',
          'jobNum',
          'operator',
          'state',
          'county',
          'mudType',
          'comments',
          'operatorName',
          'rig',
          'mwdCoordinator',
          'ddCoordinator',
        ])
      })
      return filterList
    } else {
      return toolOrders
    }
  }

  return (
    <React.Fragment>
      {showEditor ? (
        <RenderCreateUpdateModal
          data={editIndex.current >= 0 ? filteredList[editIndex.current] : {}}
          submitFunction={submitFunction}
          cancelFunction={handleClose}
        />
      ) : null}
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => confirmDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <div className={classes.searchContainer}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={{
            padding: '1px',
            margin: '1px',
            backgroundColor: 'rgb(17, 17, 17)',
          }}
        />
      </div>
      <div className={classes.listContainer} ref={listRef}>
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              className={classes.fixedListContainer}
              height={height}
              width={width}
              itemCount={!Array.isArray(filteredList) ? 0 : filteredList.length}
              itemSize={120}>
              {renderToolOrder}
            </FixedSizeList>
          )}
        </AutoSizer>
      </div>
      <div className={classes.menuIcon}>
        <MenuButton
          actions={[
            {
              icon: <AddIcon />,
              name: 'Add',
              onClick: () => {
                editIndex.current = -1
                handleNewToolOrder(true)
              },
            },
            { icon: <RefreshIcon />, name: 'Refresh', getToolOrders },
            { icon: <PrintIcon />, name: 'Print', onClick: onXlsxExport },
          ]}
        />
      </div>
      {status?.show ? (
        <Snackbar open={status?.show} autoHideDuration={2000} onClose={handleCloseStatus}>
          <MuiAlert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </MuiAlert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default ToolOrdersList
