import { CatmullRomCurve3, DoubleSide } from 'three'

import React from 'react'

export const Wellbore = ({ wellData }) => {
  if (!wellData?.hasOwnProperty('data')) return null
  if (!Array.isArray(wellData.data)) return null
  if (wellData.data.length < 2) return null

  const path = new CatmullRomCurve3(wellData.data)

  return (
    <mesh visible>
      <tubeGeometry args={[path, wellData.data.length > 64 ? wellData.data.length : 64, 0.5, 16, false]} />
      <meshStandardMaterial color={wellData.color} side={DoubleSide} />
    </mesh>
  )
}
