import { appColors, chartSeriesColors } from '../components/theme'
import {
  checkDateRange,
  isoStringToNumeric,
  numberToTimeString,
  isoStringToDateString,
  getDeltaIsoTime,
  getDeltaIsoDays,
  getDeltaTime,
  getCurrentIsoDateString,
  timeSince,
  isoDateFromUnkn,
  getBHAdeltaHours,
  daysSinceActive,
  getDaysText,
  createDateArray,
} from './dateTimeFunctions'

import multiParse from './multiParse'

export {
  appColors,
  chartSeriesColors,
  checkDateRange,
  isoStringToNumeric,
  numberToTimeString,
  isoStringToDateString,
  getDeltaIsoTime,
  getDeltaIsoDays,
  getDeltaTime,
  getCurrentIsoDateString,
  timeSince,
  isoDateFromUnkn,
  getBHAdeltaHours,
  daysSinceActive,
  getDaysText,
  createDateArray,
  multiParse,
}

export const array2pipestr = (stringArray) => {
  let pipeStr = ''
  stringArray.forEach((str) => {
    if (pipeStr !== '') pipeStr += '|'
    pipeStr += str
  })
  return pipeStr
}

  // index is be 1-based
export const getXlsxColumnLetter = (columnIndex) => {
    let letters = ''
    let num = columnIndex
    while (num >= 0) {
      letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26] + letters
      num = Math.floor(num / 26) - 1
    }
    return letters
  }

