import React, { useEffect, useRef, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, FormHelperText } from '@material-ui/core'
import * as yup from 'yup'
import { Field, Form, Formik } from 'formik'
import { each as _each } from 'lodash'

import { Select } from 'components/common/formikMUI/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { TextField } from 'components/common/formikMUI/TextField'
import { CheckboxWithLabel } from 'components/common/formikMUI/CheckboxWithLabel'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import { Icon as Iconify } from '@iconify/react'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'

import useAxios from '../../common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import { makeStyles } from '@material-ui/styles'

import { appColors } from '../../../utils'
import { unitSetList } from 'components/common/units'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '720px',
  },
  paper: {
    width: '600px',
    backgroundColor: appColors.modalBackColor,
  },
  dialogTitle: {
    color: appColors.headerTextColor,
    alignSelf: 'center',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
  },
  formItem: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'center',
    width: '90%',
    // for select components:
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderColor: appColors.accentColor,
      borderWidth: '1px',
    },
  },
  formItemCB: {
    display: 'flex',
    flexDirection: 'column',
    margin: 5,
    paddingLeft: '8px',
    position: 'relative',
    alignItems: 'left',
    width: '95%',
    border: '1px solid',
    borderWidth: '1px',
    overflow: 'hidden',
    borderRadius: 4,
    borderColor: appColors.borderColor,
    backgroundColor: appColors.itemBackColor,
    color: appColors.itemTextColor,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: appColors.itemBackColor,
      borderColor: appColors.accentColor,
    },
    // '&$focused': {
    //   backgroundColor: appColors.itemBackColor,
    //   borderColor: appColors.accentColor,
    // },
    // for select components:
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderColor: appColors.accentColor,
      borderWidth: '1px',
    },
  },
  formItemCBOld: {
    display: 'flex',
    flexDirection: 'column',
    margin: 5,
    paddingLeft: '16px',
    position: 'relative',
    alignItems: 'left',
    width: '90%',
    // for select components:
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderColor: appColors.accentColor,
      borderWidth: '1px',
    },
  },
  buttonText: {
    fontWeight: '500',
    fontSize: 20,
    color: '#FFFFFF',
    justifyContent: 'center',
  },
  buttonContainer: {
    display: 'flex',
    flex: '0 0 auto',
    //flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '90%',
    //margin: 10,
    padding: 10,
    gap: 10,
  },
  iconStyle: {
    color: appColors.headerTextColor,
  },
  iconViewStyle: {
    position: 'absolute',
    left: 15,
    top: 15,
  },
  pickerSelect: {
    margin: 5,
    fontSize: 16,
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderWidth: 1,
    height: '32px',
    //    width: '80%',
    color: appColors.itemTextColor,
    borderColor: appColors.borderColor,
    backgroundColor: appColors.itemBackColor,
    borderRadius: 5,
    paddingRight: 10, // to ensure the text is never behind the icon
    '&:before': {
      backgroundColor: appColors.itemBackColor,
      borderColor: appColors.borderColor,
    },
  },
  checkBoxContainer: {
    backgroundColor: appColors.itemBackColor,
    borderColor: appColors.borderColor,
    borderWidth: 1,
    borderRadius: 5,
    height: '32px',
    width: '90%',
    margin: 5,
    marginLeft: 5,
    paddingLeft: 10,
    padding: 5,
    alignItems: 'flex-start',
    fontSize: 16,
    color: appColors.itemTextColor,
  },
  checkBoxText: {
    paddingLeft: 5,
    fontSize: 16,
    color: appColors.itemTextColor,
  },
}))

// uncomment if a 'root' class style is needed for material-ui Select comp.
const useMobileSelectStyles = makeStyles((theme) => ({
  root: {
    margin: 5,
    fontSize: 16,
    padding: '5px 10px 5px 10px',
    borderWidth: 1,
    height: '32px',
    width: '90%',
    color: appColors.itemTextColor,
    borderColor: appColors.borderColor,
    backgroundColor: appColors.itemBackColor,
    borderRadius: '5px',
    '&:before': {
      backgroundColor: appColors.itemBackColor,
      borderColor: appColors.borderColor,
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    display: 'flex',
    alignItems: 'center',
  },
  selectMenu: {
    backgroundColor: '#202020',
    '& ul': {
      backgroundColor: '#404040',
    },
    '& li': {
      fontSize: 12,
    },
  },
}))

const createOptions = (list, classes) => {
  const options = []

  _each(list, (item) => {
    options.push(
      <MenuItem value={item.value} key={item.value} className={classes}>
        {item.label}
      </MenuItem>,
    )
  })

  return options
}

const genUnitList = () => {
  let newListData = []
  unitSetList.forEach((unit) => {
    newListData.push({ label: unit.label, value: `${unit.label}` })
  })
  return newListData
}

const EditUserDialog = ({ open, onClose, data, useInputData, orgList }, ...Props) => {
  const _isMounted = useRef(false)
  const classes = useStyles()
  const selectClasses = useMobileSelectStyles()
  // const checkboxClasses = useCheckboxStyles()
  // const checkboxLabelClasses = useCheckboxLabelStyles()
  const [alert, setAlert] = useState({ showAlert: false, alertSeverity: '', alertContent: '' })

  const [unitList] = useState(genUnitList())
  const [roleList, setRoleList] = useState([])
  const { newCancelToken, cancelPreviousRequest } = useAxiosCancelToken()
  const [{ error }, saveUser] = useAxios({
    url: '/admin/updateUser',
    manual: true,
    cancelToken: newCancelToken(),
  })

  const { newCancelToken: resetCancelToken, cancelPreviousRequest: cancelResetPwdReq } = useAxiosCancelToken()
  const [{ error: resetPwdError }, resetUserPwd] = useAxios({
    url: '/admin/resetUserPwd',
    manual: true,
    cancelToken: resetCancelToken(),
  })

  const { newCancelToken: newUserCancelToken, cancelPreviousRequest: cancelNewUserReq } = useAxiosCancelToken()
  const [{ error: createUserError }, createUser] = useAxios({
    url: '/admin/createUser',
    manual: true,
    cancelToken: newUserCancelToken(),
  })

  const { newCancelToken: roleCancelToken, cancelPreviousRequest: roleCancelRequest } = useAxiosCancelToken()
  const [{ error: getRolesError }, getRoleData] = useAxios({
    url: '/admin/user/roles',
    cancelToken: roleCancelToken(),
    manual: true,
  })

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
      roleCancelRequest()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error !== undefined && error !== null) {
      if (cancelPreviousRequest(error)) return
    }
  }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (resetPwdError !== undefined && resetPwdError !== null) {
      if (cancelResetPwdReq(resetPwdError)) return
    }
  }, [resetPwdError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (createUserError !== undefined && createUserError !== null) {
      if (cancelNewUserReq(createUserError)) return
    }
  }, [createUserError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (getRolesError !== undefined && getRolesError !== null) {
      if (roleCancelRequest(getRolesError)) return
    }
  }, [getRolesError]) // eslint-disable-line react-hooks/exhaustive-deps

  const formValidationSchemaUpdate = yup.object({
    email: yup.string().email().required(),
    organization: yup.string().required(),
    roleId: yup.string().required(),
    unitPrefs: yup.string().required(),
    password: yup
      .string()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-])[A-Za-z\d@$!%*#?&-]{8,}$/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
      ),
  })

  const formValidationSchemaCreate = yup.object({
    email: yup.string().email().required(),
    organization: yup.string().required(),
    roleId: yup.string().required(),
    unitPrefs: yup.string().required(),
    password: yup
      .string()
      .required()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-])[A-Za-z\d@$!%*#?&-]{8,}$/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
      ),
  })

  let formData = [
    {
      tag: 'email',
      value: useInputData === true ? data.email : '',
      text: 'Email Address',
      inputType: 'text',
      inputFormType: 'email',
      icon: 'entypo:email',
      iconFamily: 'Iconify',
    },
    {
      tag: 'password',
      value: '',
      text: 'Password',
      inputType: 'text',
      inputFormType: 'password',
      icon: 'ic:sharp-security',
      iconFamily: 'Iconify',
    },
    {
      tag: 'organization',
      value: useInputData === true ? data.organization : '',
      text: 'Organization',
      inputType: 'dropdown',
      dropDownValues: orgList,
      useLabel: true,
    },
    {
      tag: 'roleId',
      value: useInputData === true ? String(data.roleId) : '',
      text: 'Role',
      inputType: 'dropdown',
      dropDownValues: roleList,
      useLabel: false,
      filterField: 'organization',
    },
    {
      tag: 'unitPrefs',
      value: useInputData === true ? data.unitPrefs : '',
      text: 'Units',
      inputType: 'dropdown',
      dropDownValues: unitList,
      useLabel: true,
    },
    {
      tag: 'active',
      value: useInputData === true ? data.active : true,
      text: 'Active',
      inputType: 'boolean',
    },
  ]

  const submitFunction = async (isCreate, data, formikActions, onClose) => {
    // ('EditUseDlg submit function', { isCreate, data })
    if (!data) return false
    if (data.hasOwnProperty('email') === false) return false

    data.email.toLowerCase()

    if (isCreate === true) {
      data.newUserName = data.email
      const createResponse = await createUser({
        ...data,
      })
      // console.log('createUser response: ', createResponse)
      if (createResponse.error) {
        setAlert({ showAlert: true, alertSeverity: 'error', alertContent: 'Create user failed' })
        formikActions?.setSubmitting(false)
        return false
      } else {
        setAlert({ showAlert: true, alertSeverity: 'success', alertContent: 'User created' })
      }
      // console.log('user created')
    } else {
      data.updateUserName = data.email
      await saveUser({
        ...data,
      })
      // console.log('user updated')
      if (data.password !== '') {
        await resetUserPwd({
          authUserName: data.updateUserName,
          password: data.password,
        })
      }
    }

    if (isCreate === false && data.password !== '') {
      await resetUserPwd({
        authUserName: data.updateUserName,
        password: data.password,
      })
    }

    // onClose can be used to trigger parent refresh, so
    // don't signal until this function is complete
    formikActions?.setSubmitting(false)
    onClose(null, 'submitted')
    return true
  }

  const createInitialValues = (data) => {
    if (!data) return {}
    if (Array.isArray(data) === false) return {}
    if (data.length <= 0) return {}

    // console.log('cIV: rawInitalData ', data)
    const reducedData = data.reduce((output, item) => {
      output[item.tag] = item.value
      return output
    }, {})
    // console.log('cIV: reducedInitialData ', reducedData)
    return reducedData
  }

  const processRoleData = (roleData, userOrg) => {
    if (_isMounted.current) {
      let newRoleData = []
      if (roleData) {
        roleData.forEach((i) => {
          if (i.organization === userOrg) {
            newRoleData.push({ label: i.name, value: `${i.id}`, organization: i.organization })
          }
        })
      }
      setRoleList(newRoleData)
    }
  }

  const handleEnter = async () => {
    // console.log('edit dialog enter', data?.organization, useInputData)
    if (useInputData) {
      const roleResponse = await getRoleData({ organization: data?.organization })
      if (roleResponse?.data) {
        processRoleData(roleResponse.data, data?.organization)
      }
    }
  }

  const handleEntering = () => {
    // console.log('edit dialog entering', data)
  }

  const handleOrgChange = async (event) => {
    // console.log('org change', event.target.value)
    const roleResponse = await getRoleData({ organization: event.target.value })
    if (roleResponse?.data) {
      processRoleData(roleResponse.data, event.target.value)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
      TransitionProps={{
        onEnter: handleEnter,
        onEntering: handleEntering,
        onEntered: () => {
          // console.log('edit dialog entered')
        },
      }}>
      <Snackbar open={alert?.showAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <MuiAlert
          variant='filled'
          severity={alert.alertSeverity}
          onClose={() => setAlert({ showAlert: false, alertSeverity: 'info', alertContent: '' })}>
          {alert.alertContent}
        </MuiAlert>
      </Snackbar>
      <DialogTitle className={classes.dialogTitle}>{useInputData ? 'Edit ' : 'Add '}User</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={createInitialValues(formData)}
          validationSchema={useInputData === false ? formValidationSchemaCreate : formValidationSchemaUpdate}
          onSubmit={(values, actions) => {
            // console.log('formik submit, values= ', values)
            formData.forEach((input) => {
              if (input.inputType === 'dropdown') {
                if (input.useLabel === true) {
                  let index = input.dropDownValues.findIndex((element) => element.value === values[input.tag])
                  if (index >= 0) values[input.tag] = input.dropDownValues[index].label
                }
              }
            })
            submitFunction(!useInputData, values, actions, onClose)
            //onClose(null, 'submitted')
          }}>
          {({ values, submitForm, isSubmitting, touched, errors }) => (
            <Form className={classes.formContainer}>
              <InputLabel id='user-email-label-id'>Email</InputLabel>
              <Field
                component={TextField}
                name='email'
                type='email'
                placeholder='email address'
                InputProps={{
                  startAdornment: <Iconify icon={'entypo:email'} size={30} className={classes.iconStyle} />,
                }}
              />
              {errors.email && touched.email && <FormHelperText>{errors.email}</FormHelperText>}

              <InputLabel id='user-pass-label-id'>Password</InputLabel>
              <Field
                component={TextField}
                name='password'
                type='password'
                placeholder='password'
                InputProps={{
                  startAdornment: <Iconify icon={'ic:sharp-security'} size={30} className={classes.iconStyle} />,
                }}
              />
              {errors.password && touched.password && <FormHelperText>{errors.password}</FormHelperText>}

              <InputLabel id='user-org-label-id'>Organization</InputLabel>
              <Field
                component={Select}
                name={'organization'}
                id={'organization'}
                labelId={'user-org-label-id'}
                classes={selectClasses}
                MenuProps={{ classes: { paper: selectClasses.selectMenu } }}
                onChange={handleOrgChange}
                variant='outlined'>
                {createOptions(orgList, classes.pickerSelect)}
              </Field>
              <InputLabel id='user-role-label-id'>Role</InputLabel>
              <Field
                component={Select}
                name={'roleId'}
                id={'roleId'}
                labelId={'user-role-label-id'}
                classes={selectClasses}
                MenuProps={{ classes: { paper: selectClasses.selectMenu } }}
                variant='outlined'>
                {createOptions(roleList, classes.pickerSelect)}
              </Field>
              <InputLabel id='user-units-label-id'>Unit Prefs</InputLabel>
              <Field
                component={Select}
                name={'unitPrefs'}
                id={'unitPrefs'}
                labelId={'user-units-label-id'}
                classes={selectClasses}
                MenuProps={{ classes: { paper: selectClasses.selectMenu } }}
                variant='outlined'>
                {createOptions(unitList, classes.pickerSelect)}
              </Field>
              <InputLabel id='user-active-label-id'>Is Active</InputLabel>
              <div className={classes.formItemCB}>
                <Field
                  component={CheckboxWithLabel}
                  name={'active'}
                  type='checkbox'
                  Label={{ label: 'Active', labelPlacement: 'end' }}
                  checkedIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'lime' }} />}
                  indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                  icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                  style={{ color: appColors.itemTextColor }}
                />
              </div>

              {/*formData.map((_, i) => renderFormItem(i, { touched, errors }))*/}
              <div className={classes.buttonContainer}>
                <Button color='secondary' variant='outlined' onClick={() => onClose(null, 'cancelled')}>
                  Cancel
                </Button>
                <Button color='primary' variant='contained' type='submit'>
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default EditUserDialog
