import React, { useEffect, useRef } from 'react'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  Button,
  MenuItem,
  FormHelperText,
  createMuiTheme,
} from '@material-ui/core'
import { makeStyles, ThemeProvider } from '@material-ui/styles'

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import * as yup from 'yup'
import { Field, Form, Formik } from 'formik'
import moment from 'moment'

import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import { Select } from 'components/common/formikMUI/Select'
import { CheckboxWithLabel } from 'components/common/formikMUI/CheckboxWithLabel'
import { each as _each } from 'lodash'

import { appColors } from 'utils'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '600px',
    backgroundColor: appColors.modalBackColor,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
  },
  formItem: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'left',
    width: '95%',
    paddingLeft: '8px',
    margin: '5px',
    border: '1px solid',
    borderWidth: '1px',
    overflow: 'hidden',
    borderRadius: 4,
    borderColor: appColors.borderColor,
    backgroundColor: appColors.itemBackColor,
    color: appColors.itemTextColor,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: appColors.itemBackColor,
      borderColor: appColors.accentColor,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderColor: appColors.accentColor,
      borderWidth: '1px',
    },
  },
  selectMenuItem: {
    height: '30px',
    '&:hover': {
      backgroundColor: appColors.componentBackColor,
    },
    '&$selected': {
      '&:hover': {
        backgroundColor: appColors.componentBackColor,
      },
    },
  },
  dialogContent: {
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#136cb9', //'darkgrey', //'rgba(0,0,0,.1)',
      outline: '1px solid #1679d0',
    },
  },
}))

const useMobileSelectStyles = makeStyles((theme) => ({
  root: {
    margin: 5,
    fontSize: 16,
    padding: '5px 10px 5px 10px',
    borderWidth: 1,
    height: '32px',
    width: '90%',
    color: appColors.itemTextColor,
    borderColor: appColors.borderColor,
    backgroundColor: appColors.itemBackColor,
    borderRadius: '5px',
    '&:before': {
      backgroundColor: appColors.itemBackColor,
      borderColor: appColors.borderColor,
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    display: 'flex',
    alignItems: 'center',
  },
  selectMenu: {
    color: appColors.itemTextColor,
    backgroundColor: '#202020',
    '& ul': {
      backgroundColor: '#202020',
    },
    '& li': {
      fontSize: 12,
    },
  },
}))

const pickerTheme = createMuiTheme({
  palette: {
    //primary: indigo,
    primary: {
      main: '#133f60', //'#429CEB', //itemTextColor
      contrastText: '#fff',
    },
    // TODO: What should the secondary color be?
    secondary: {
      main: '#fff',
      contrastText: '#202020',
    },
  },
})

pickerTheme.props = {
  MuiInput: {
    disableUnderline: true,
  },
}

pickerTheme.overrides = {
  MuiTextField: {
    root: {
      border: '1px solid',
      borderWidth: '1px',
      overflow: 'hidden',
      borderRadius: 4,
      width: '95%',
      margin: 5,
      fontSize: 16,
      height: '32px',
      padding: 5,
      paddingLeft: 5,
      borderColor: appColors.borderColor,
      backgroundColor: appColors.itemBackColor,
      color: appColors.itemTextColor,
      transition: pickerTheme.transitions.create(['border-color', 'box-shadow']),
      '&:hover': {
        backgroundColor: appColors.itemBackColor,
        borderColor: appColors.accentColor,
      },
      '&$focused': {
        backgroundColor: appColors.itemBackColor,
        borderColor: appColors.accentColor,
      },
    },
    focused: {},
    input: {},
  },
  MuiInputBase: {
    input: {
      paddingLeft: '4px',
      color: appColors.itemTextColor,
    },
  },
  MuiFormHelperText: {
    root: {
      fontSize: 12,
      color: 'yellow',
      margin: '0px 5px 5px 5px',
      padding: '0px 5px 5px 5px',
      '&$error': {
        color: 'yellow',
      },
    },
    error: {},
  },
  MuiOutlinedInput: {
    root: {
      marginBottom: '6px', // adds spacing below an outlined Select component...
    },
  },
  MuiSvgIcon: {
    root: {
      color: appColors.itemTextColor,
    },
  },
}

// Options and createOptions for Material-UI Select component:
const createOptions = (list, classes) => {
  const options = []

  _each(list, (item) => {
    options.push(
      <MenuItem value={item.value} key={item.value} className={classes}>
        {item.label}
      </MenuItem>,
    )
  })

  return options
}

const SearchModal = ({ open, onClose, submitSearch, data, filterLists, searchParams }, ...Props) => {
  const _isMounted = useRef(false)
  const classes = useStyles()
  const selectClasses = useMobileSelectStyles()
  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const formValidationSchema = yup.object({
    operator: yup.string().nullable(),
    state: yup.string().nullable(),
    county: yup.string().nullable(),
    rig: yup.string().nullable(),
    wellStatus: yup.string().nullable(),
    jobNum: yup.string().nullable(),
    directionalCompany: yup.string().nullable(),
    formation: yup.string().nullable(),
    holeSize: yup.string().nullable(),
    isLih: yup.string().nullable(),
    phase: yup.string().nullable(),
    dateFrom: yup.string().nullable(),
    dateTo: yup.string().nullable(),
  })

  let formData = [
    {
      tag: 'operator',
      value: searchParams.operator,
      text: 'Operator',
      inputType: 'dropdown',
      dropDownValues: filterLists?.operators,
      useLabel: true,
    },
    {
      tag: 'state',
      value: searchParams.state,
      text: 'State',
      inputType: 'dropdown',
      dropDownValues: filterLists?.states,
      useLabel: true,
    },
    {
      tag: 'county',
      value: searchParams.county,
      text: 'County',
      inputType: 'dropdown',
      dropDownValues: filterLists?.countys,
      useLabel: true,
    },
    {
      tag: 'rig',
      value: searchParams.rig,
      text: 'Rig',
      inputType: 'dropdown',
      dropDownValues: filterLists?.rigs,
      useLabel: true,
    },
    {
      tag: 'wellStatus',
      value: searchParams.wellStatus,
      text: 'Well Status',
      inputType: 'dropdown',
      dropDownValues: filterLists?.wellStatus,
      useLabel: true,
    },
    {
      tag: 'jobNum',
      value: searchParams.jobNum,
      text: 'Job #',
      inputType: 'dropdown',
      dropDownValues: filterLists?.jobNumbers,
      useLabel: true,
    },
    {
      tag: 'directionalCompany',
      value: searchParams.directionalCompany,
      text: 'Directional Company',
      inputType: 'dropdown',
      dropDownValues: filterLists?.directionalCompany,
      useLabel: true,
    },
    {
      tag: 'formation',
      value: searchParams.formation,
      text: 'Formation',
      inputType: 'dropdown',
      dropDownValues: filterLists?.formations,
      useLabel: true,
    },
    {
      tag: 'holeSize',
      value: searchParams.holeSize,
      text: 'Hole Size',
      inputType: 'dropdown',
      dropDownValues: filterLists?.holeSize,
      useLabel: true,
    },
    {
      tag: 'phase',
      value: searchParams.phase,
      text: 'phase',
      inputType: 'dropdown',
      dropDownValues: filterLists?.phases,
      useLabel: true,
    },
    {
      tag: 'dateFrom',
      value: searchParams.dateFrom === '' ? moment().format('YYYY-MM-DD') : searchParams.dateFrom,
      text: 'Start Date',
      inputType: '-',
    },
    {
      tag: 'dateTo',
      value: searchParams.dateTo === '' ? moment().format('YYYY-MM-DD') : searchParams.dateTo,
      text: 'End Date',
      inputType: '-',
    },
    {
      tag: 'isLih',
      value: searchParams.isLih,
      text: 'LIH',
      inputType: 'boolean',
    },
  ]

  const createInitialValues = (data) => {
    if (!data) return {}
    if (Array.isArray(data) === false) return {}
    if (data.length <= 0) return {}

    // console.log('cIV: rawInitalData ', data)
    const reducedData = data.reduce((output, item) => {
      output[item.tag] = item.value
      return output
    }, {})
    // console.log('cIV: reducedInitialData ', reducedData)
    return reducedData
  }

  // main modal form:
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
        <DialogTitle>Search wells</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Formik
            initialValues={createInitialValues(formData)}
            validationSchema={formValidationSchema}
            onSubmit={(values, actions) => {
              // console.log('formik submit, values= ', values)
              formData.forEach((input) => {
                if (input.inputType === 'dropdown') {
                  if (input.useLabel === true) {
                    let index = input.dropDownValues.findIndex((element) => element.value === values[input.tag])
                    if (index >= 0) values[input.tag] = input.dropDownValues[index].label
                  }
                }
              })
              submitSearch(values, actions, onClose)
            }}>
            {({ values, submitForm, isSubmitting, touched, errors, handleChange, setFieldValue }) => (
              <Form className={classes.formContainer} id='searchform'>
                <InputLabel id='oper-select-label-id'>Operator</InputLabel>
                <Field
                  component={Select}
                  name={'operator'}
                  id={'operator'}
                  labelId='oper-select-label-id'
                  classes={selectClasses}
                  MenuProps={{ classes: { paper: selectClasses.selectMenu } }}
                  variant='outlined'>
                  {createOptions(filterLists.operators, classes.selectMenuItem)}
                </Field>

                <InputLabel id='state-select-label-id'>State</InputLabel>
                <Field
                  component={Select}
                  name={'state'}
                  id={'state'}
                  labelId='state-select-label-id'
                  classes={selectClasses}
                  MenuProps={{ classes: { paper: selectClasses.selectMenu } }}
                  variant='outlined'>
                  {createOptions(filterLists.states, classes.selectMenuItem)}
                </Field>

                <InputLabel id='county-select-label-id'>County</InputLabel>
                <Field
                  component={Select}
                  name={'county'}
                  id={'county'}
                  labelId='county-select-label-id'
                  classes={selectClasses}
                  MenuProps={{ classes: { paper: selectClasses.selectMenu } }}
                  variant='outlined'>
                  {createOptions(filterLists.countys, classes.selectMenuItem)}
                </Field>

                <InputLabel id='rig-select-label-id'>Rig</InputLabel>
                <Field
                  component={Select}
                  name={'rig'}
                  id={'rig'}
                  labelId='rig-select-label-id'
                  classes={selectClasses}
                  MenuProps={{ classes: { paper: selectClasses.selectMenu } }}
                  variant='outlined'>
                  {createOptions(filterLists.rigs, classes.selectMenuItem)}
                </Field>

                <InputLabel id='wellStatus-select-label-id'>Well Status</InputLabel>
                <Field
                  component={Select}
                  name={'wellStatus'}
                  id={'wellStatus'}
                  labelId='wellStatus-select-label-id'
                  classes={selectClasses}
                  MenuProps={{ classes: { paper: selectClasses.selectMenu } }}
                  variant='outlined'>
                  {createOptions(filterLists.wellStatus, classes.selectMenuItem)}
                </Field>

                <InputLabel id='jobNum-select-label-id'>Job #</InputLabel>
                <Field
                  component={Select}
                  name={'jobNum'}
                  id={'jobNum'}
                  labelId='jobNum-select-label-id'
                  classes={selectClasses}
                  MenuProps={{ classes: { paper: selectClasses.selectMenu } }}
                  variant='outlined'>
                  {createOptions(filterLists.jobNumbers, classes.selectMenuItem)}
                </Field>

                <InputLabel id='directionalCompany-select-label-id'>Directional Company</InputLabel>
                <Field
                  component={Select}
                  name={'directionalCompany'}
                  id={'directionalCompany'}
                  labelId='directionalCompany-select-label-id'
                  classes={selectClasses}
                  MenuProps={{ classes: { paper: selectClasses.selectMenu } }}
                  variant='outlined'>
                  {createOptions(filterLists.directionalCompany, classes.selectMenuItem)}
                </Field>

                <InputLabel id='formation-select-label-id'>Formation</InputLabel>
                <Field
                  component={Select}
                  name={'formation'}
                  id={'formation'}
                  labelId='formation-select-label-id'
                  classes={selectClasses}
                  MenuProps={{ classes: { paper: selectClasses.selectMenu } }}
                  variant='outlined'>
                  {createOptions(filterLists.formation, classes.selectMenuItem)}
                </Field>

                <InputLabel id='holeSize-select-label-id'>Hole Size</InputLabel>
                <Field
                  component={Select}
                  name={'holeSize'}
                  id={'holeSize'}
                  labelId='holeSize-select-label-id'
                  classes={selectClasses}
                  MenuProps={{ classes: { paper: selectClasses.selectMenu } }}
                  variant='outlined'>
                  {createOptions(filterLists.holeSize, classes.selectMenuItem)}
                </Field>

                <InputLabel id='phase-select-label-id'>Phase</InputLabel>
                <Field
                  component={Select}
                  name={'phase'}
                  id={'phase'}
                  labelId='phase-select-label-id'
                  classes={selectClasses}
                  MenuProps={{ classes: { paper: selectClasses.selectMenu } }}
                  variant='outlined'>
                  {createOptions(filterLists.phases, classes.selectMenuItem)}
                </Field>

                <InputLabel id='dateFrom-label-id'>Start Date</InputLabel>
                <ThemeProvider theme={pickerTheme}>
                  <KeyboardDatePicker
                    id='dateFrom'
                    name='dateFrom'
                    format='dd-MMM-yyyy'
                    value={values.dateFrom}
                    maxDate={values.dateTo}
                    onChange={(value) => setFieldValue('dateFrom', value)}
                  />
                </ThemeProvider>
                {errors.dateFrom && touched.dateFrom && <FormHelperText>{errors.dateFrom}</FormHelperText>}

                <InputLabel id='dateTo-label-id'>End Date</InputLabel>
                <ThemeProvider theme={pickerTheme}>
                  <KeyboardDatePicker
                    id='dateTo'
                    name='dateTo'
                    format='dd-MMM-yyyy'
                    value={values.dateTo}
                    minDate={values.dateFrom}
                    onChange={(value) => setFieldValue('dateTo', value)}
                  />
                </ThemeProvider>
                {errors.dateTo && touched.dateTo && <FormHelperText>{errors.dateTo}</FormHelperText>}

                <InputLabel id='isLih-label-id'>LIH</InputLabel>
                <div className={classes.formItem}>
                  <Field
                    component={CheckboxWithLabel}
                    name={'isLih'}
                    type='checkbox'
                    Label={{ label: 'LIH', labelPlacement: 'end' }}
                    checkedIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'lime' }} />}
                    indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                    icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                    style={{ color: appColors.itemTextColor }}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => onClose(null, 'cancelled')} color='secondary'>
            Cancel
          </Button>
          <Button variant='contained' color='primary' type='submit' form='searchform'>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </MuiPickersUtilsProvider>
  )
}

export default SearchModal
