import { makeStyles } from '@material-ui/styles'
import React from 'react'

import WellViewPage from '../WellViewPage'
import SurveysCard from './SurveysCard'

const useStyles = makeStyles((theme) => ({
  container: {
    height: 'calc(100vh - 130px)',
    maxHeight: 'calc(100vh - 130px)',
  },
}))

const SurveysPage = () => {
  const classes = useStyles()
  return (
    <WellViewPage maxWidth>
      <div className={classes.container}>
        <SurveysCard />
      </div>
    </WellViewPage>
  )
}

export default SurveysPage
