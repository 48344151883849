import React from 'react'
import PropTypes from 'prop-types'
import useCanvas from './hooks/useCanvas'

// from https://medium.com/@pdx.lucasm/canvas-with-react-js-32e133c05258
// and https://github.com/lucas-lm/react-canvas-dom

const Canvas = (props) => {
  const { draw, square, options, ...rest } = props
  const canvasRef = useCanvas(draw, square, options)

  return <canvas ref={canvasRef} {...rest} />
}

Canvas.defaultProps = {
  draw: () => {},
}

Canvas.propTypes = {
  draw: PropTypes.func.isRequired,
  square: PropTypes.bool.isRequired,
  options: PropTypes.shape({
    context: PropTypes.oneOf(['2d', 'webgl', 'experimental-webgl', 'webgl2', 'bitmaprenderer']),
  }),
}

export default Canvas
