import React from 'react'
import { withRouter } from 'react-router-dom'

import { InfoBox } from '@react-google-maps/api'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  infoBox: {
    background: `white`,
    border: `1px solid #ccc`,
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: ' flex-start',
  },
}))

const WellsMapMarkerInfoBox = ({ showInfoBox, well }) => {
  const classes = useStyles()
  const position = {
    lat: well.latitude,
    lng: well.longitude,
  }

  if (!showInfoBox) {
    return null
  }

  return (
    <InfoBox
      position={position}
      options={{
        closeBoxURL: '',
        disableAutoPan: true,
      }}>
      <div className={classes.infoBox}>
        <h1>{well.actualWell}</h1>

        {well.rig ? <p>Rig Name: {well.rig} </p> : null}
        {false ? <p>Rig Type: m_mapData.rigLocations[i].rigType</p> : null}
        {well.operator ? <p>Operator: {well.operator}</p> : null}

        <p>Well Name: {well.actualWell}</p>
        {well.wellStatus ? <p>Status: {well.wellStatus}</p> : null}
        {false ? <p>Status Date: m_mapData.rigLocations[i].statusDate</p> : null}

        <p>Latitude: {well.latitude}</p>
        <p>Longitude: {well.longitude}</p>

        {false ? <p>Horse Power: m_mapData.rigLocations[i].horsePower</p> : null}
        {false ? <p>Rated Depth: m_mapData.rigLocations[i].ratedDepth</p> : null}
        {false ? <p>API#: m_mapData.rigLocations[i].apiNumber</p> : null}
        {false ? <p>Region: </p> : null}
      </div>
    </InfoBox>
  )
}

export default React.memo(withRouter(WellsMapMarkerInfoBox))
