import React from 'react'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

import { makeStyles } from '@material-ui/styles'
import RGL from 'react-grid-layout'
import { useResizeDetector } from 'react-resize-detector'

import SurveysCard from 'components/WellPages/SurveyPage/SurveysCard'
import ThreeDeeCard from './ThreeDeeCard'
import { appColors } from 'utils'
import FootagePerDayChart from 'components/WellPage/components/FootagePerDayChart'
import { useRecoilValue } from 'recoil'
import { currentWellAtom } from 'atoms'
import DepthTimeCard from './DepthTimeCard'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
  },
  rglCard: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#202020',
    // padding: '4px',
    // margin: '8px',
    border: '1px solid #000',
    borderRadius: '6px',
  },
  rglCardTitle: {
    flexGrow: 0,
    color: appColors.itemTextColor,
    fontWeight: 500,
    paddingBottom: '4px',
    cursor: 'pointer',
  },
  rglCardContents: {
    display: 'flex',
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'hidden',
  },
}))

const SummaryDashboard = () => {
  const classes = useStyles()
  const currentWell = useRecoilValue(currentWellAtom)
  const { width: gridWidth, ref: gridRef } = useResizeDetector({ refreshMode: 'debounce', refreshRate: 250 })

  const layout = [
    { i: 'a', x: 0, y: 0, w: 3, h: 2 },
    { i: 'b', x: 3, y: 0, w: 3, h: 2 },
    { i: 'c', x: 0, y: 2, w: 3, h: 2 },
    { i: 'd', x: 3, y: 2, w: 3, h: 2 },
  ]

  // restore for responsive layout dev work:
  // const responsiveLayout = {
  //   lg: [
  //     { i: 'a', x: 0, y: 0, w: 3, h: 2 },
  //     { i: 'b', x: 3, y: 0, w: 3, h: 2 },
  //     { i: 'c', x: 0, y: 2, w: 3, h: 2 },
  //     { i: 'd', x: 3, y: 2, w: 3, h: 2 },
  //   ],
  //   md: [
  //     { i: 'a', x: 0, y: 0, w: 3, h: 2 },
  //     { i: 'b', x: 3, y: 0, w: 3, h: 2 },
  //     { i: 'c', x: 0, y: 2, w: 3, h: 2 },
  //     { i: 'd', x: 3, y: 2, w: 3, h: 2 },
  //   ],
  // }

  return (
    <div className={classes.container} ref={gridRef}>
      <RGL
        className='layout'
        layout={layout}
        onLayoutChange={() => {}}
        cols={6}
        useCSSTransforms
        rowHeight={300}
        width={gridWidth}
        draggableHandle={'.dragHandle'}
        margin={[8, 8]}>
        <div key='a' className={classes.rglCard}>
          <div className={`${classes.rglCardTitle} dragHandle`}>Depth v Days</div>
          <div className={classes.rglCardContents}>
            <DepthTimeCard />
          </div>
        </div>
        <div key='b' className={classes.rglCard}>
          <div className={`${classes.rglCardTitle} dragHandle`}>3D View</div>
          <div className={classes.rglCardContents}>
            <ThreeDeeCard />
          </div>
        </div>
        <div key='c' className={classes.rglCard}>
          <div className={`${classes.rglCardTitle} dragHandle`}>Surveys</div>
          <div className={classes.rglCardContents}>
            <SurveysCard />
          </div>
        </div>
        <div key='d' className={classes.rglCard}>
          <div className={`${classes.rglCardTitle} dragHandle`}>Footage Per Day</div>
          <div className={classes.rglCardContents}>
            <FootagePerDayChart currentWell={currentWell} />
          </div>
        </div>
      </RGL>
    </div>
  )
}

export default SummaryDashboard
