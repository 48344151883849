import React, { useEffect, useRef, useState } from 'react'

import {
  Paper,
  Fab,
  Tooltip,
  ListItem,
  Avatar,
  Badge,
  ListItemSecondaryAction,
  Checkbox,
  Select,
  InputLabel,
  MenuItem,
  IconButton,
} from '@material-ui/core'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import { makeStyles } from '@material-ui/styles'
import { Icon as Iconify } from '@iconify/react'
import SearchBar from 'material-ui-search-bar'
import map from 'lodash/map'
import { each as _each } from 'lodash'

import useAxios from 'components/common/hooks/useAxios'
import useAxiosWithUnitFetch from 'components/common/hooks/useAxiosWithUnitFetch'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import { appColors } from 'utils'
import SectionPickList from 'components/common/SectionPickList'
import { searchFunction } from 'components/common/searchFunctions'
import { getFilterLists, blankFilterLists } from 'components/common/filterLists'
import SearchModal from 'components/common/SearchModal'

import { useRecoilValue, useRecoilState } from 'recoil'
import { analyticsPhaseFilterAtom, analyticsSearchParamsAtom, analyticsSelectedWells } from 'atoms'
import { actionBarWidthAtom } from '../../atoms'

import { GetActivityColor, GetActivityText, getStatusAvatar } from '../common/activitySelector'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: appColors.itemBackColor,
    maxWidth: '100%',
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 3,
    height: 'calc(100vh - 128px)',
    maxHeight: 'calc(100vh - 128px)',
  },

  // BEGIN WellList MUI overrides
  wellListContainer: {
    paddingLeft: '16px',
    paddingRight: '16px',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
    height: 'calc(100vh - 128px)',
    maxHeight: 'calc(100vh - 128px)',
  },
  wellListItem: {
    background: 'linear-gradient(#404040,#181818)',
  },
  wellListItemContent: {
    color: appColors.itemTextColorMobile,
    fontSize: '.75vw',
    marginLeft: '5%',
    marginTop: '4px',
    marginBottom: '4px',
    cursor: 'default',
  },
  avatarContainer: {
    margin: 5,
    marginRight: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'lightgrey',
    borderRadius: '50%',
  },

  // Begin Badge Activity Colors
  badgeActivityActive: {
    backgroundColor: '#8bc34a',
    color: 'black',
    fontWeight: 'bold',
  },
  badgeActivityComplete: {
    backgroundColor: '#03a9f4',
    color: 'black',
    fontWeight: 'bold',
  },
  badgeActivityUpcoming: {
    backgroundColor: '#87CEEB',
    color: 'black',
    fontWeight: 'bold',
  },
  badgeActivityStandby: {
    backgroundColor: '#ff6347',
    color: 'black',
    fontWeight: 'bold',
  },
  badgeActivityTd: {
    backgroundColor: '#FFD700',
    color: 'black',
    fontWeight: 'bold',
  },
  badgeActivityBatch: {
    backgroundColor: '#3edbf0',
    color: 'black',
    fontWeight: 'bold',
  },
  badgeActivityFinished: {
    backgroundColor: '#ffcc29',
    color: 'black',
    fontWeight: 'bold',
  },
  badgeActivityEowrReady: {
    backgroundColor: '#77acf1',
    color: 'black',
    fontWeight: 'bold',
  },
  badgeActivityEowrSent: {
    backgroundColor: '#c0fefc',
    color: 'black',
    fontWeight: 'bold',
  },
  badgeActivityTransferred: {
    backgroundColor: '#9f5f80',
    color: 'black',
    fontWeight: 'bold',
  },
  badgeActivityVerified: {
    backgroundColor: '#81b214',
    color: 'black',
    fontWeight: 'bold',
  },
  badgeActivityNotAvailable: {
    backgroundColor: '#b00020',
    color: 'black',
    fontWeight: 'bold',
  },
  //End Badge Activity Colors

  wellListItemContentDetails: {
    fontWeight: 'bold',
    color: 'rgb(192,192,192)',
  },
  //END WellList MUI Overrides

  sectionList: {
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#136cb9', //'darkgrey', //'rgba(0,0,0,.1)',
      outline: '1px solid #1679d0',
    },
  },
  tooltip: {
    backgroundColor: 'rgb(19,62,96)',
    fontSize: '12px',
    fontFamily: 'Roboto',
    zIndex: 2,
  },
  addIcon: {
    color: 'white',
    backgroundColor: '#1886e7', //appColors.accentColor,
    margin: '4px',
    padding: '12px',
    '&:hover': {
      backgroundColor: '#429CEB',
    },
    position: 'fixed', //'absolute',
    bottom: theme.spacing(10),
    right: theme.spacing(6),
    fontSize: '24px',
    zIndex: 2,
  },
  clearIcon: {
    color: 'white',
    backgroundColor: '#dd6347',
    margin: '4px',
    padding: '8px',
    '&:hover': {
      backgroundColor: '#ff6347',
    },
    position: 'fixed', //'absolute',
    bottom: theme.spacing(16),
    right: theme.spacing(6),
    fontSize: '24px',
    zIndex: 2,
  },
  searchContainer: {
    backgroundColor: appColors.itemBackColor,
    padding: '8px',
  },
  phaseOverlay: {
    minWidth: 120,
    position: 'fixed', //'absolute',
    top: '106px',
    right: '25%',
    zIndex: 2,
    width: '128px',
    height: '42px',
    border: '1px solid',
    backgroundColor: appColors.windowBackColor,
  },
  phaseInput: {
    textAlign: 'left',
    marginTop: '0px',
    fontSize: '10px',
  },
  select: {
    width: '100%',
  },
  selectMenuItem: {
    height: '30px',
    '&:hover': {
      backgroundColor: appColors.componentBackColor,
    },
  },
  flatTimeIcon: {
    minWidth: 24,
    position: 'fixed',
    top: '106px',
    right: 'calc(25% + 128px)',
    zIndex: 2,
  },
  xlsxIcon: {
    minWidth: 24,
    position: 'fixed',
    top: '106px',
    left: (props) => `calc(${props.leftPos}px + 24px)`, // float the icon relative to the ActionBar, so it appears fixed...
    zIndex: 2,
  },
}))

const useMobileSelectStyles = makeStyles((theme) => ({
  root: {
    margin: 5,
    fontSize: 16,
    padding: '5px 10px 5px 10px',
    borderWidth: 1,
    height: '32px',
    width: '90%',
    color: appColors.itemTextColor,
    borderColor: appColors.borderColor,
    backgroundColor: appColors.itemBackColor,
    borderRadius: '5px',
    '&:before': {
      backgroundColor: appColors.itemBackColor,
      borderColor: appColors.borderColor,
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    display: 'flex',
    alignItems: 'center',
  },
  selectMenu: {
    color: appColors.itemTextColor,
    backgroundColor: '#202020',
    '& ul': {
      backgroundColor: '#202020', //'#404040',
    },
    '& li': {
      fontSize: 12,
    },
  },
}))

// Options and createOptions for Material-UI Select component:
type Options = [{ label: string, value: string }]

const createOptions = (list: Options, classes: Any) => {
  const options = []

  _each(list, (item) => {
    options.push(
      <MenuItem value={item.value} key={item.value} className={classes}>
        {item.label}
      </MenuItem>,
    )
  })

  return options
}

type Props = {
  children: React.ReactNode,
}

const AnalyticsCard = ({ children, showFlatTime, flatTimeCB, exportXlsxCB }: Props) => {
  const _isMounted = useRef(false)
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const classes = useStyles({ leftPos })
  const selectClasses = useMobileSelectStyles()

  // const [data, setData] = useState([])
  const [wells, setWells] = useState([])
  const [selectedWells, setSelectedWells] = useRecoilState(analyticsSelectedWells) // useState([])
  const [searchText, setSearchText] = useState('')
  const [phaseFilter, setPhaseFilter] = useRecoilState(analyticsPhaseFilterAtom) //useState('0')
  const [filterLists, setFilterLists] = useState(blankFilterLists)
  const [showSearchModal, setShowSearchModal] = React.useState(false)
  const [searchParams, setSearchParams] = useRecoilState(analyticsSearchParamsAtom)
  const [removeFlatTime, setRemoveFlatTime] = useState(false)

  const { newCancelToken: wellCancelToken, cancelPreviousRequest: wellCancelRequest } = useAxiosCancelToken()
  const [{ data: wellData, error: wellError }] = useAxiosWithUnitFetch({
    url: '/wells',
    data: { allWells: true },
    cancelToken: wellCancelToken(),
  })

  const { newCancelToken: filterListCancelToken, cancelPreviousRequest: filterListCancelRequest } =
    useAxiosCancelToken()
  const [{ data: filterListData, error: filterListError }] = useAxios({
    url: '/filterLists',
    cancelToken: filterListCancelToken(),
  })

  const { newCancelToken: searchCancelToken, cancelPreviousRequest: searchCancelRequest } = useAxiosCancelToken()
  const [{ error: searchError }, wellSearch] = useAxios({
    url: '/kpi/wellSearch',
    cancelToken: searchCancelToken(),
    manual: true,
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
      wellCancelRequest()
      filterListCancelRequest()
      searchCancelRequest()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      //setWells(getPickListWells(wellData))
      setWells(wellData)
    }
  }, [wellData])

  useEffect(() => {
    if (_isMounted.current) {
      let newFilters = {}
      newFilters = getFilterLists(filterListData)
      // console.log('filters: ', newFilters)
      setFilterLists(newFilters)
    }
  }, [filterListData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (wellError !== undefined && wellError !== null) {
      if (wellCancelRequest(wellError)) return
    }
  }, [wellError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filterListError !== undefined && filterListError !== null) {
      if (filterListCancelRequest(filterListError)) return
    }
  }, [filterListError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (searchError !== undefined && searchError !== null) {
      if (searchCancelRequest(searchError)) return
    }
  }, [searchError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (flatTimeCB) {
      flatTimeCB(removeFlatTime)
    }
  }, [removeFlatTime]) // eslint-disable-line react-hooks/exhaustive-deps

  function reduceWellData(data) {
    if (!Array.isArray(data)) return []
    return data.reduce((output, well) => {
      if (
        searchFunction(well, searchText, [
          'actualWell',
          'jobNum',
          'operator',
          'state',
          'county',
          'rig',
          'wellStatus',
        ]) === false
      ) {
        return output
      }
      let index = output.findIndex((element) => element.operator === well.operator)
      if (index < 0) {
        output.push({
          header: well.operator,
          operator: well.operator,
          data: [
            {
              id: well.actualWell,
              desc: well.actualWell,
              ...well,
            },
          ],
        })
      }
      if (index >= 0) {
        output[index].data.push({
          id: well.actualWell,
          desc: well.actualWell,
          ...well,
        })
      }
      return output
    }, [])
  }

  const handleCloseSearch = async (event, reason) => {
    if (reason === 'submitted') {
      // getChartData()
    }
    setShowSearchModal(false)
  }

  const handleClearSearch = () => {
    setSelectedWells([])
  }

  const submitSearch = async (data, formikActions, onClose) => {
    if (!data) {
      setShowSearchModal(false)
      return
    }

    if (data.dateTo === data.dateFrom) {
      data.dateTo = ''
      data.dateFrom = ''
    }

    if (data.dateFrom !== '' || data.dateTo !== '') {
      if (data.dateFrom !== '' && data.dateTo === '') data.dateTo = '2200-01-01T00:00:00.000Z'
      if (data.dateFrom === '' && data.dateTo !== '') data.dateFrom = '1900-01-01T00:00:00.000Z'
    }

    if (data.isLih === true) data.isLih = 'true'

    let params = {} // []
    let newSearchParams = { ...searchParams }
    for (const property in data) {
      if (typeof data[property] === 'string' && data[property] !== '') {
        params[property] = data[property] //params.push({ tagName: property, value: data[property] })
      }

      if (newSearchParams.hasOwnProperty(property)) newSearchParams[property] = data[property]
    }

    if (_isMounted.current === true) setSearchParams(newSearchParams)
    const newWells = await wellSearch(params)
    if (!Array.isArray(newWells.data)) {
      setSelectedWells([])
      setShowSearchModal(false)
      return
    }
    if (_isMounted.current === true) setSelectedWells(newWells.data)

    setShowSearchModal(false)
  }

  // SectionPickList support

  const onItemSelect = (id) => {
    let newSelectedItems = [...selectedWells]

    if (getChecked(id) === false) {
      newSelectedItems.push(id)
      if (_isMounted.current === true) setSelectedWells(newSelectedItems)
    } else {
      if (Array.isArray(newSelectedItems) === true) {
        const index = newSelectedItems.indexOf(id)
        if (index > -1) newSelectedItems.splice(index, 1)
      } else {
        newSelectedItems = []
      }

      if (_isMounted.current === true) setSelectedWells(newSelectedItems)
    }
  }

  const getChecked = (id) => {
    if (!selectedWells) return false
    if (Array.isArray(selectedWells) === false) return false
    if (selectedWells.includes(id) === true) return true

    return false
  }

  const renderWellSectionListItem = (item) => {
    const getBadgeColor = (color) => {
      if (color === '#8bc34a') {
        return classes.badgeActivityActive
      }
      if (color === '#03a9f4') {
        return classes.badgeActivityComplete
      }
      if (color === '#87CEEB') {
        return classes.badgeActivityUpcoming
      }
      if (color === '#ff6347') {
        return classes.badgeActivityStandby
      }
      if (color === '#FFD700') {
        return classes.badgeActivityTd
      }
      if (color === '#3edbf0') {
        return classes.badgeActivityBatch
      }
      if (color === '#ffcc29') {
        return classes.badgeActivityFinished
      }
      if (color === '#77acf1') {
        return classes.badgeActivityEowrReady
      }
      if (color === '#c0fefc') {
        return classes.badgeActivityEowrSent
      }
      if (color === '#9f5f80') {
        return classes.badgeActivityTransferred
      }
      if (color === '#81b214') {
        return classes.badgeActivityVerified
      }
      if (color === '#b00020') {
        return classes.badgeActivityNotAvailable
      }
    }

    return (
      <ListItem key={item.id} className={classes.wellListItem}>
        <div className={classes.avatarContainer}>
          {/* check to see if well has an activity status. If not, return
          "unknown" Avatar Badge. If true, return propper abbreviation Badge*/}
          {/* {console.log(item.wellStatus)} */}
          <Badge
            classes={{ badge: getBadgeColor(GetActivityColor(item.wellStatus)) }}
            badgeContent={GetActivityText(item.wellStatus)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}>
            <Avatar alt='Well Icon' src={getStatusAvatar(item.wellStatus)}></Avatar>
          </Badge>
        </div>
        <dl className={classes.wellListItemContent}>
          <dt className={classes.wellListItemContentDetails}>{`${item.desc}`}</dt>
          <dt>{`${item.operator}`}</dt>
          <dt>{`${item.jobNum} - ${item.state}/${item.county}`}</dt>
        </dl>
        <ListItemSecondaryAction>
          <Checkbox
            edge='end'
            className={classes.wellListItemCheckbox}
            onChange={() => onItemSelect(item.id)}
            checked={getChecked(item.id)}
            checkedIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'lime' }} />}
            indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
            icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
          />
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  // export to XLSX file:

  // main render:
  return (
    <Paper className={classes.paper}>
      {showSearchModal ? (
        <SearchModal
          open={showSearchModal}
          onClose={handleCloseSearch}
          searchParams={searchParams}
          submitSearch={submitSearch}
          filterLists={filterLists}
        />
      ) : null}
      <div className={classes.chartContainer}>
        {React.Children.map(children, (child) => {
          return child
        })}
        <div className={classes.wellListContainer}>
          <div className={classes.searchContainer}>
            <SearchBar
              value={searchText}
              onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
              onCancelSearch={() => setSearchText('')}
              style={{
                padding: '1px',
                margin: '1px',
                backgroundColor: '#111',
              }}
            />
          </div>
          <div className={classes.sectionList}>
            <SectionPickList
              sectionList={reduceWellData(wells)}
              selItems={selectedWells}
              setSelItems={setSelectedWells}
              renderItem={renderWellSectionListItem}
            />
          </div>
        </div>
        {filterLists && filterLists.phases && filterLists.phases.length > 0 ? (
          <div className={classes.phaseOverlay}>
            <InputLabel className={classes.phaseInput} id='phase-select-input'>
              Phase
            </InputLabel>
            <Select
              name='phase'
              labelId='phase-select-input'
              className={classes.select}
              onChange={(event) => setPhaseFilter(event.target.value)}
              value={phaseFilter}
              MenuProps={{ classes: { paper: selectClasses.selectMenu } }}
              required>
              {createOptions}
              {map(filterLists?.phases, (phase) => (
                <MenuItem key={phase.value} value={phase.value} className={classes.selectMenuItem}>
                  {phase.label}
                </MenuItem>
              ))}
            </Select>
          </div>
        ) : null}
        {showFlatTime ? (
          removeFlatTime ? (
            <Tooltip title='Show flat time' placement='bottom' classes={{ tooltip: classes.tooltip }}>
              <IconButton className={classes.flatTimeIcon} onClick={() => setRemoveFlatTime(!removeFlatTime)}>
                <Iconify
                  icon='carbon:chart-line'
                  style={{ color: appColors.accentColor, fontSize: '24px', border: '1px solid' }}
                />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title='Remove flat time' placement='bottom' classes={{ tooltip: classes.tooltip }}>
              <IconButton className={classes.flatTimeIcon} onClick={() => setRemoveFlatTime(!removeFlatTime)}>
                <Iconify icon='carbon:chart-line' style={{ color: appColors.headerTextColor, fontSize: '24px' }} />
              </IconButton>
            </Tooltip>
          )
        ) : null}
        <Tooltip title='clear' placement='left' classes={{ tooltip: classes.tooltip }}>
          <Fab aria-label='clear' className={classes.clearIcon} onClick={() => handleClearSearch()} size='small'>
            <Iconify icon='la:times' className={classes.listIconStyle} />
          </Fab>
        </Tooltip>
        <Tooltip title='search' placement='left' classes={{ tooltip: classes.tooltip }}>
          <Fab
            aria-label='search'
            className={classes.addIcon}
            onClick={() => setShowSearchModal(!showSearchModal)}
            size='small'>
            <Iconify icon='ant-design:search-outlined' className={classes.listIconStyle} />
          </Fab>
        </Tooltip>
      </div>
      <Tooltip title='Export to XLSX' placement='bottom' classes={{ tooltip: classes.tooltip }}>
        <IconButton className={classes.xlsxIcon} onClick={() => exportXlsxCB()}>
          <Iconify icon='mdi:printer' style={{ color: appColors.headerTextColor, fontSize: '24px' }} />
        </IconButton>
      </Tooltip>
    </Paper>
  )
}

export default AnalyticsCard
