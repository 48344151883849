import React, { useEffect, useRef, useState } from 'react'

import Tooltip from '@material-ui/core/Tooltip'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import AddIcon from '@material-ui/icons/Add'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import { Icon as Iconify } from '@iconify/react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import * as yup from 'yup'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { currentWellAtom, currentWellFieldCostAtom } from 'atoms'
import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import SimpleStyledList from 'components/common/SimpleStyledList'
import { appColors } from 'utils'
import InputModal from 'components/common/InputModal'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { getCostIcon } from 'utils/dailyCostFunctions'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { numberWithCommas } from 'utils/stringFunctions'
import { checkPermission } from '../../userPermissions'
import { userUserRoleAtom } from 'atoms'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@material-ui/icons/Refresh'

const useStyles = makeStyles((theme) => ({
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: 'calc(100vh - 130px)',
    maxHeight: 'calc(100vh - 130px)',
    border: '1px solid #000',
    borderRadius: '4px',
  },
  headerText: {
    fontSize: 14,
    fontWeight: '500',
    color: appColors.headerTextColor,
    textAlign: 'center',
    width: '100%',
  },
  itemText: {
    fontSize: 18,
    color: appColors.itemTextColor,
    textAlign: 'center',
    border: '',
    width: '100%',
  },
  titleCode: {
    fontSize: 18,
    fontWeight: '500',
    color: appColors.itemTextColor,
    textAlign: 'right',
  },
  titleDesc: {
    fontSize: 18,
    fontWeight: '500',
    color: appColors.headerTextColor,
    textAlign: 'left',
    paddingLeft: '8px',
  },
  deleteIconStyle: {
    color: '#C00000',
    fontSize: '24px',
  },
  itemMainContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '8px',
    marginBottom: '1px',
    border: `1px solid`,
    borderColor: appColors.itemBackColor,
    backgroundColor: 'inherit',
    '&:hover': {
      borderColor: appColors.accentColor,
    },
  },
  itemRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '8px',
  },
  itemColContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  itemIconContainer: {
    width: 100,
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
  tooltip: {
    backgroundColor: 'rgb(19,62,96)',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
  returnButton: {
    display: 'flex',
  },
  avatarContainer: {
    margin: 5,
    marginRight: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'lightgrey',
    borderRadius: '50%',
  },
  menuIcon: {
    backgroundColor: 'transparent',
    margin: '4px',
    padding: '12px',
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}))

const DailyCostList = ({ history }) => {
  const _isMounted = useRef(false)
  const classes = useStyles()
  const editIndex = useRef(-1)
  const costCodes = useRef([])
  const userRole = useRecoilValue(userUserRoleAtom)
  const currentWell = useRecoilValue(currentWellAtom)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [showEditor, setShowEditor] = useState(false)
  const currentFieldCost = useRecoilValue(currentWellFieldCostAtom)
  const setCurrentFieldCost = useSetRecoilState(currentWellFieldCostAtom)
  const [bhaList, setBhaList] = useState([])
  const costUnit = useUnits(UNITS_FOR.Cost)

  const { newCancelToken: newBhaCancelToken, cancelPreviousRequest: cancelPreviousBhaRequest } = useAxiosCancelToken()
  const [{ error: bhaError }, getBhaList] = useAxios({
    url: '/well/wellCost/getBhaList',
    manual: true,
    cancelToken: newBhaCancelToken(),
  })

  const { newCancelToken: newCostCodeCancelToken, cancelPreviousRequest: cancelPreviousCostCodeRequest } =
    useAxiosCancelToken()
  const [{ error: costCodeError }, getCostCodes] = useAxios({
    url: '/well/wellCost/getCostCodes',
    manual: true,
    cancelToken: newCostCodeCancelToken(),
  })

  const { newCancelToken: newDailyCostsCancelToken, cancelPreviousRequest: cancelPreviousCostsRequest } =
    useAxiosCancelToken()
  const [{ data: costs, error: costsError }, getCosts] = useAxios({
    url: '/well/wellCost/getDailyCosts',
    data: {
      date: currentFieldCost.date,
      wellName: currentWell,
    },
    cancelToken: newDailyCostsCancelToken(),
  })

  const { newCancelToken: createCancelToken, cancelPreviousRequest: cancelCreateCostReq } = useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorCreate }, createCost] = useAxios({
    url: '/well/wellCost/addCost',
    manual: true,
    cancelToken: createCancelToken(),
  })

  const { newCancelToken: updateCancelToken, cancelPreviousRequest: cancelUpdateCostReq } = useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorUpdate }, updateCost] = useAxios({
    url: '/well/wellCost/updateCost',
    manual: true,
    cancelToken: updateCancelToken(),
  })

  const { newCancelToken: newDeleteCancelToken, cancelPreviousRequest: cancelDeleteCostReq } = useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorDelete }, deleteCost] = useAxios({
    url: '/well/wellCost/deleteCost',
    manual: true,
    cancelToken: newDeleteCancelToken(),
  })

  useEffect(() => {
    _isMounted.current = true
    getListData()

    return () => {
      _isMounted.current = false
      cancelPreviousCostCodeRequest()
      cancelPreviousBhaRequest()
      cancelCreateCostReq()
      cancelUpdateCostReq()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (bhaError !== undefined && bhaError !== null) {
      if (cancelPreviousBhaRequest(bhaError)) return
    }
  }, [bhaError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (costCodeError !== undefined && costCodeError !== null) {
      if (cancelPreviousCostCodeRequest(costCodeError)) return
    }
  }, [costCodeError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorCreate !== undefined && errorCreate !== null) {
      if (cancelCreateCostReq(errorCreate)) return
    }
  }, [errorCreate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorUpdate !== undefined && errorUpdate !== null) {
      if (cancelUpdateCostReq(errorUpdate)) return
    }
  }, [errorUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorDelete !== undefined && errorDelete !== null) {
      if (cancelDeleteCostReq(errorDelete)) return
    }
  }, [errorDelete]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (costsError !== undefined && costsError !== null) {
      if (cancelPreviousCostsRequest(costsError)) return
    }
  }, [costsError]) // eslint-disable-line react-hooks/exhaustive-deps

  const getListData = async () => {
    if (_isMounted.current && currentWell !== '') {
      const payload = {
        wellName: currentWell,
      }
      let newCostCodes = []
      const response = await getCostCodes(payload)
      if (response?.data) {
        response.data.forEach((code) => {
          newCostCodes.push({
            label: code.costCode + ':' + code.description,
            value: `${code.costCode}`,
            costValue: code.value,
          })
        })
        costCodes.current = newCostCodes
      }

      let newBhaList = []
      const responseBha = await getBhaList(payload)
      if (responseBha?.data) {
        responseBha.data.forEach((bha) => {
          newBhaList.push({ label: 'BHA# ' + bha.bhaNumRep.toString() + '-' + bha.description, value: `${bha.bhaNum}` })
        })
        setBhaList(newBhaList)
      }
    }
  }

  const getCostValue = (costCode) => {
    if (!Array.isArray(costCodes.current)) return ''
    if (costCodes.current.length === 0) return ''
    for (let i = 0; i < costCodes.current.length; i++) {
      if (costCodes.current[i].value === costCode) return costCodes.current[i].costValue
    }
    return 0
  }

  const getListDescription = (checkValue, returnLabel, inputArray) => {
    if (typeof checkValue === 'number') checkValue = checkValue.toString()
    if (!Array.isArray(inputArray)) return ''
    if (inputArray.length === 0) return ''
    for (let i = 0; i < inputArray.length; i++) {
      if (returnLabel && inputArray[i].value === checkValue) return inputArray[i].label
      if (!returnLabel && inputArray[i].label === checkValue) return inputArray[i].value
    }

    return ''
  }

  const onClickDeleteCost = (item, index) => {
    editIndex.current = parseInt(index)
    setConfirm({
      show: true,
      title: 'Delete Cost Item',
      text: `Are you sure you want to delete the cost ${item?.description}?`,
      itemIndex: editIndex.current,
    })
  }

  const renderItemHeader = (text, value) => {
    return (
      <div className={classes.itemRowContainer}>
        <div className={classes.titleCode}>{text} : </div>
        <div className={classes.titleDesc}>{value}</div>
      </div>
    )
  }

  const renderDataColumns = (header, value) => {
    return (
      <div className={classes.itemColContainer}>
        <div className={classes.headerText}>{header}</div>
        <div className={classes.itemText}>{value}</div>
      </div>
    )
  }

  const renderCostItem = (item, index) => {
    return (
      <div
        className={classes.itemMainContainer}
        onClick={() => {
          editIndex.current = index
          setShowEditor(true)
        }}>
        <div className={classes.itemRowContainer}>{renderItemHeader(item.costCode, item.description)}</div>
        <div className={classes.itemRowContainer}>
          <div className={classes.avatarContainer}>
            <Avatar alt={item.type} src={getCostIcon(item)}></Avatar>
          </div>
          {renderDataColumns('Qty', item.quantity)}
          {renderDataColumns(`Value ${costUnit}`, item.value)}
          {renderDataColumns(`Total ${costUnit}`, numberWithCommas(item.value * item.quantity))}
          {renderDataColumns('SN', item.serialNum !== '' ? item.serialNum : 'n/a')}
          {renderDataColumns('BHA#', item.bhaNum !== '' ? getListDescription(item.bhaNum, true, bhaList) : 'n/a')}
          {checkPermission('canDelete', userRole.roleAttributes?.permissions) ? (
            <Tooltip title='Delete Cost Item' placement='bottom' classes={{ tooltip: classes.tooltip }}>
              <IconButton
                className={classes.itemIconContainer}
                onClick={(event) => {
                  event.stopPropagation()
                  onClickDeleteCost(item, index)
                }}>
                <Iconify icon='fa-regular:trash-alt' className={classes.deleteIconStyle} />
              </IconButton>
            </Tooltip>
          ) : null}
        </div>
      </div>
    )
  }

  const RenderCreateUpdateModal = ({ data, submitFunction, cancelFunction }) => {
    let useInputData = true
    if (!data) useInputData = false
    if (useInputData === true) {
      if (data.hasOwnProperty('dailyReportId') === false) useInputData = false
    }
    const title = (useInputData ? 'Edit' : 'Add') + ' Cost'

    const formValidationSchema = yup.object({
      wellName: yup.string().required(),
      // dailyReportId: yup.string().required(), // deviation from mobile
      quantity: yup
        .string()
        .matches(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed')
        .required('Quantity cannot be blank'),
      costCode: yup.string().required('Cost code cannot be blank'),
      serialNum: yup.string().nullable(),
      // variableCostValue: yup.string().matches(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed'), // deviation from mobile
      bhaNum: yup.string().nullable(),
    })

    let formData = [
      {
        tag: 'wellName',
        value: currentWell,
        inputType: '-',
      },
      {
        tag: 'uid',
        value: useInputData === true ? (typeof data.uid === 'number' ? data.uid.toString() : '') : '',
        inputType: '-',
      },
      {
        tag: 'dailyReportId',
        value: typeof currentFieldCost.dailyReportId === 'number' ? currentFieldCost.dailyReportId.toString() : '',
        inputType: '-',
      },
      {
        tag: 'costCode',
        value: useInputData === true ? data.costCode : '',
        text: 'Cost Code',
        inputType: 'dropdown',
        dropDownValues: costCodes.current,
        useLabel: true,
        icon: 'ic:outline-qr-code',
        iconFamily: 'Iconify',
      },
      {
        tag: 'quantity',
        value: useInputData === true ? (typeof data.quantity === 'number' ? data.quantity.toString() : '') : '',
        text: 'Quantity',
        inputType: 'text',
        icon: 'mdi:numeric',
        iconFamily: 'Iconify',
        keyboardtype: 'decimal-pad',
      },
      {
        tag: 'serialNum',
        value: useInputData === true ? data.serialNum : '',
        text: 'Serial #',
        inputType: 'text',
        icon: 'mdi:numeric',
        iconFamily: 'Iconify',
      },
      {
        tag: 'variableCostValue',
        value:
          useInputData === true
            ? typeof data.variableCostValue === 'number'
              ? data.variableCostValue.toString()
              : ''
            : '',
        inputType: '-',
      },
      {
        tag: 'bhaNum',
        value: useInputData === true ? (typeof data.bhaNum === 'number' ? data.bhaNum.toString() : '') : '',
        text: 'BHA#',
        inputType: 'dropdown',
        useLabel: true,
        dropDownValues: bhaList,
        icon: 'mdi:numeric',
        iconFamily: 'Iconify',
      },
    ]

    return (
      <InputModal
        open={showEditor}
        onClose={handleCloseEditCosts}
        title={title}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={cancelFunction}
        validationSchema={formValidationSchema}
      />
    )
  }

  const handleNewCost = () => {
    editIndex.current = -1
    setShowEditor(true)
  }

  const handleClose = () => {
    setShowEditor(false)
  }

  const handleCloseStatus = (event, reason) => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const handleCloseEditCosts = async (event, reason) => {
    setShowEditor(false)
  }

  const submitFunction = async (data, formikActions) => {
    setShowEditor(false)

    if (!data) return false

    let isAdd = false
    if (editIndex.current < 0) {
      isAdd = true
    }
    let payload = { ...data }
    if (payload.hasOwnProperty('costCode')) {
      // since MUI Select handles value vs label already, the two getListdescription calls aren't needed (mobile deviation)
      // payload.costCode = getListDescription(unescapeHtml(data.costCode), false, costCodes)
      payload.variableCostValue = getCostValue(data.costCode)
    }
    if (payload.hasOwnProperty('bhaNum')) {
      // payload.bhaNum = getListDescription(unescapeHtml(data.bhaNum), false, bhaList)
    }

    let saveResponse
    if (isAdd) {
      saveResponse = await createCost(payload, createCancelToken())
    } else {
      saveResponse = await updateCost(payload, updateCancelToken())
    }

    if (saveResponse.error) {
      setStatus({ show: true, severity: 'error', message: 'Create cost failed' })
      formikActions?.setSubmitting(false)
      return false
    } else {
      setStatus({ show: true, severity: 'success', message: isAdd ? 'Cost created' : 'Cost updated' })
    }

    await getCosts()

    return true
  }

  const confirmDelete = async () => {
    if (confirm?.itemIndex >= 0) {
      const deleteResponse = await deleteCost({
        wellName: currentWell,
        uid: costs[confirm?.itemIndex].uid,
      })
      if (!deleteResponse.error) {
        setStatus({ show: true, severity: 'success', message: 'Cost deleted successfully' })
        await getCosts()
      } else {
        setStatus({ show: true, severity: 'error', message: 'Delete cost failed' })
      }
    }
  }

  return (
    <React.Fragment>
      {showEditor ? (
        <RenderCreateUpdateModal
          data={editIndex.current >= 0 ? costs[editIndex.current] : {}}
          submitFunction={submitFunction}
          cancelFunction={handleClose}
        />
      ) : null}
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => confirmDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <div className={classes.listContainer}>
        <div className={classes.returnButton}>
          <Button
            variant='outlined'
            color='primary'
            startIcon={<ArrowBackIcon />}
            onClick={() => {
              setCurrentFieldCost({
                date: '',
                dailyReportId: -1,
              })
              editIndex.current = -1
              history.goBack()
            }}>
            Back to Cost List
          </Button>
        </div>
        <SimpleStyledList listItems={costs} renderItem={renderCostItem}></SimpleStyledList>
      </div>
      <div className={classes.menuIcon}>
        <MenuButton
          actions={[
            {
              icon: <AddIcon />,
              name: 'Add',
              onClick: () => {
                editIndex.current = -1
                handleNewCost(true)
              },
            },
            { icon: <RefreshIcon />, name: 'Refresh', onClick: getCosts },
          ]}
        />
      </div>
      {status?.show ? (
        <Snackbar open={status?.show} autoHideDuration={2000} onClose={handleCloseStatus}>
          <MuiAlert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </MuiAlert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default DailyCostList
