import React from 'react'

import { Card } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import DailyCostHeaderList from './DailyCostHeaderList'

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: '#192734',
  },
}))

const DailyCostHeaderCard = ({ history }) => {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <DailyCostHeaderList history={history} />
    </Card>
  )
}

export default DailyCostHeaderCard
