import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { appColors } from 'utils'
import { IconButton, Tooltip, Snackbar, Fab } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'

import { Icon as Iconify } from '@iconify/react'

import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import SimpleStyledList from 'components/common/SimpleStyledList'
import { timeSince } from 'utils'

const useStyles = makeStyles((theme) => ({
  listTitle: {
    color: '#C0C0C0',
    fontSize: '18px',
    paddingBottom: '8px',
    paddingTop: '8px',
    backgroundColor: appColors.itemBackColor,
  },
  listContainer: {
    display: 'flex',
    overflow: 'hidden',
    height: 'calc(calc(100vh - 280px) * 1.00)',
    maxHeight: 'calc(calc(100vh - 280px) * 1.00)',
    borderRadius: '4px',
  },
  tooltip: {
    backgroundColor: 'rgb(19,62,96)',
    fontSize: '12px',
    fontFamily: 'Roboto',
    transition: '.5s',
    '&:hover': {
      dropShadow: ' 5px 5px 10px 10px grey',
      color: 'white',
    },
  },
  itemTextWrapContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    flex: 3,
  },
  itemMainContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '8px',
    paddingLeft: '8px',
    marginBottom: '4px',
    border: `1px solid`,
    borderColor: appColors.itemBackColor,
    backgroundColor: 'inherit',
    '&:hover': {
      borderColor: appColors.accentColor,
    },
  },
  itemTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 4,
  },
  itemLabelText: {
    fontSize: 14,
    fontWeight: '500',
    color: appColors.headerTextColor,
    width: '150px',
    textAlign: 'right',
    paddingRight: '8px',
  },
  itemText: {
    fontSize: 14,
    fontWeight: '400',
    color: appColors.itemTextColor,
    border: '',
  },
  listIconStyle: {
    color: '#C0C0C0',
    fontSize: '40px',
  },
  title: {
    margin: 'auto',
    font: '24px Roboto',
    color: appColors.headerTextColor,
    textAlign: 'left',
    width: '100%',
  },
  fabIconStyle: {
    color: '#C0C0C0',
    fontSize: '40px',
  },
  playIcon: {
    color: 'white',
    backgroundColor: 'inherit',
    margin: '0px',
    padding: '0px',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
    position: 'fixed',
    bottom: theme.spacing(18),
    right: theme.spacing(2),
    fontSize: '24px',
    zIndex: 2,
  },
  stopIcon: {
    color: 'white',
    backgroundColor: 'inherit',
    margin: '0px',
    padding: '0px',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
    position: 'fixed',
    bottom: theme.spacing(10),
    right: theme.spacing(2),
    fontSize: '24px',
    zIndex: 2,
  },
  restartIcon: {
    color: 'white',
    backgroundColor: 'inherit', // '#1886e7', //appColors.accentColor,
    margin: '0px',
    padding: '0px',
    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
    position: 'fixed', //'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    fontSize: '24px',
    zIndex: 2,
  },
}))

const ICDSList = () => {
  const _isMounted = useRef(false)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const classes = useStyles()

  const [{ data: icdsProcessList }, getIcdsProcessList] = useAxios({
    url: '/admin/icds/processList',
  })
  const { newCancelToken: startAllCancelToken } = useAxiosCancelToken()
  // eslint-disable-next-line no-empty-pattern
  const [{}, startAll] = useAxios({
    url: '/admin/icds/startAll',
    manual: true,
    cancelToken: startAllCancelToken(),
  })

  const { newCancelToken: startCancelToken } = useAxiosCancelToken()
  // eslint-disable-next-line no-empty-pattern
  const [{}, startIcds] = useAxios({
    url: '/admin/icds/start',
    manual: true,
    cancelToken: startCancelToken(),
  })

  const { newCancelToken: stopAllCancelToken } = useAxiosCancelToken()
  // eslint-disable-next-line no-empty-pattern
  const [{}, stopAll] = useAxios({
    url: '/admin/icds/stopAll',
    manual: true,
    cancelToken: stopAllCancelToken(),
  })

  const { newCancelToken: stopCancelToken } = useAxiosCancelToken()
  // eslint-disable-next-line no-empty-pattern
  const [{}, stopIcds] = useAxios({
    url: '/admin/icds/stop',
    manual: true,
    cancelToken: stopCancelToken(),
  })

  const { newCancelToken: refreshCancelToken } = useAxiosCancelToken()
  // eslint-disable-next-line no-empty-pattern
  const [{}, refreshAll] = useAxios({
    url: '/admin/icds/refreshAll',
    manual: true,
    cancelToken: refreshCancelToken(),
  })

  const { newCancelToken: restartCancelToken } = useAxiosCancelToken()
  // eslint-disable-next-line no-empty-pattern
  const [{}, restartIcds] = useAxios({
    url: '/admin/icds/refresh',
    manual: true,
    cancelToken: restartCancelToken(),
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleStopStart = async (item) => {

    if (item.pm2_env.status !== 'online') {
      // start
      const response = await startIcds({
        pid: item.pm_id,
      })
      if (!response.error) {
        setStatus({ show: true, severity: 'success', message: 'icdsServer started successfully' })
        await getIcdsProcessList()
      } else {
        setStatus({ show: true, severity: 'error', message: 'start icdsServer failed' })
      }
    } else {
      // stop
      const response = await stopIcds({
        pid: item.pm_id,
      })
      if (!response.error) {
        setStatus({ show: true, severity: 'success', message: 'icdsServer stopped successfully' })
        await getIcdsProcessList()
      } else {
        // console.log(response)
        setStatus({ show: true, severity: 'error', message: 'stop icdsServer failed' })
      }
    }
  }

  const onClickStartAll = async () => {
    const activateResponse = await startAll()

    if (!activateResponse.error) {
      setStatus({ show: true, severity: 'success', message: 'All icdsServers started successfully' })
      await getIcdsProcessList()
    } else {
      setStatus({ show: true, severity: 'error', message: 'StartAll icdsServers failed' })
    }
  }

  const onClickStopAll = async () => {
    const activateResponse = await stopAll()

    if (!activateResponse.error) {
      setStatus({ show: true, severity: 'success', message: 'All icdsServers stopped successfully' })
      await getIcdsProcessList()
    } else {
      setStatus({ show: true, severity: 'error', message: 'StopAll icdsServers failed' })
    }
  }

  const onClickRefreshAll = async () => {
    const activateResponse = await refreshAll()

    if (!activateResponse.error) {
      setStatus({ show: true, severity: 'success', message: 'All icdsServers restarted successfully' })
      await getIcdsProcessList()
    } else {
      setStatus({ show: true, severity: 'error', message: 'RestartAll icdsServers failed' })
    }
  }

  const onClickRestart = async (item) => {
    if (item) {
      const activateResponse = await restartIcds({
        pid: item.pm_id,
      })

      if (!activateResponse.error) {
        await getIcdsProcessList()
        setStatus({ show: true, severity: 'success', message: 'icdsServer restarted successfully' })
      } else {
        setStatus({ show: true, severity: 'error', message: 'Restart icdsServer failed' })
      }
    }
  }

  const handleCloseStatus = (event, reason) => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const renderTextColor = (text, value) => {
    switch (text) {
      case 'Uptime: ':
        return <span style={{ color: 'rgb(52,201,235)' }}>{value}</span>
      case 'Memory (Mb): ':
        if (value < 50) {
          return <span style={{ color: '#66FF00' }}>{value}</span>
        } else if (value < 100) {
          return <span style={{ color: 'orange' }}>{value}</span>
        } else {
          return <span style={{ color: 'red' }}>{value}</span>
        }
      case 'Status: ':
        if (value === 'online') {
          return <span style={{ color: '#66FF00' }}>{value}</span>
        } else {
          return <span style={{ color: 'red' }}>{value}</span>
        }
      default:
        return <span style={{ color: '#66FF00' }}>{value}</span>
    }
  }

  const renderTextField = (text, value) => {
    return (
      <div className={classes.itemTextContainer}>
        <div className={classes.itemLabelText}>{text} </div>
        <div className={classes.itemText}>{renderTextColor(text, value)}</div>
      </div>
    )
  }

  const renderProcessItem = (item, i) => {
    let mem = Math.round(item.monit.memory / 1e6)
    let statusColor = item.pm2_env.status === 'online' ? '#66FF00' : 'red'
    return (
      <div className={classes.title}>
        #{i + 1} - <span style={{ color: 'rgb(52,201,235)' }}>{item.name}</span> -{' '}
        <span style={{ color: statusColor }}>{item.pm2_env.status}</span>
        <hr
          style={{
            color: 'gray',
            backgroundColor: 'gray',
            height: 1,
            margin: '4px, 0px, 4px, 0px',
          }}
        />
        <div
          className={classes.itemMainContainer}
          onClick={() => {
            // console.log('click item:', item)
            handleStopStart(item)
          }}>
          <div className={classes.itemTextContainer}>
            <div className={classes.avatarContainer}>
              <Tooltip title='Restart' placement='bottom' classes={{ tooltip: classes.tooltip }}>
                <IconButton className={classes.itemIconContainer} onClick={() => onClickRestart(item, false)}>
                  <Iconify icon='mdi:restart' color='white' className={classes.listIconStyle} />
                </IconButton>
              </Tooltip>
            </div>

            <div className={classes.itemTextWrapContainer}>
              {renderTextField('name: ', item.name)}
              {renderTextField('pid:: ', item.pid)}
              {renderTextField('Status: ', item.pm2_env.status)}
              {renderTextField('Uptime: ', timeSince(item.pm2_env.pm_uptime))}
            </div>
            <div className={classes.itemTextWrapContainer}>
              {renderTextField('CPU Percent: ', item.monit.cpu)}
              {renderTextField('Memory (Mb): ', mem)}
              {renderTextField('# of Instances: ', item.pm2_env.instances)}
              {renderTextField('# of Restarts: ', item.pm2_env.restart_time)}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <div className={classes.listTitle}>ICDS List</div>
      <div className={classes.listContainer}>
        <SimpleStyledList listItems={icdsProcessList} renderItem={renderProcessItem}></SimpleStyledList>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            borderWidth: 1,
            position: 'fixed',
            bottom: 0,
            right: 0,
            alignSelf: 'flex-end',
          }}>
          <Tooltip title='Start All' placement='bottom' classes={{ tooltip: classes.tooltip }}>
            <Fab className={classes.playIcon} onClick={() => onClickStartAll()}>
              <Iconify icon='bi:play-circle-fill' color='green' className={classes.fabIconStyle} />
            </Fab>
          </Tooltip>
          <Tooltip
            title='Stop All'
            style={{ marginTop: '10px' }}
            placement='bottom'
            classes={{ tooltip: classes.tooltip }}>
            <Fab className={classes.stopIcon} onClick={() => onClickStopAll()}>
              <Iconify icon='bi:stop-circle-fill' color='red' className={classes.fabIconStyle} />
            </Fab>
          </Tooltip>
          <Tooltip title='Restart All' placement='bottom' classes={{ tooltip: classes.tooltip }}>
            <Fab className={classes.restartIcon} onClick={() => onClickRefreshAll()}>
              <Iconify
                icon='ic:twotone-replay-circle-filled'
                width='48'
                height='48'
                color='orange'
                className={classes.fabIconStyle}
              />
            </Fab>
          </Tooltip>
        </div>
        {status?.show ? (
          <Snackbar open={status?.show} autoHideDuration={2000} onClose={handleCloseStatus}>
            <MuiAlert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
              {status.message}
            </MuiAlert>
          </Snackbar>
        ) : null}
      </div>
    </React.Fragment>
  )
}

export default ICDSList
