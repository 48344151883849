import React from 'react'
import { makeStyles } from '@material-ui/styles'
import 'chart.js/auto'
import { Chart as ChartJS } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import { chartSeriesColors, appColors } from 'utils'

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    display: 'flex',
    flex: 1,
    width: '100%',
    height: '100%',
    background: appColors.itemBackColor,
  },
}))

const CenterTextPlugIn = {
  id: `CenterTextPlugIn`,
  afterDraw: (chart, args, options) => {
    const {
      ctx,
      chartArea: { top, left, width, height },
    } = chart

    ctx.save()
    ctx.textBaseline = 'middle'
    ctx.textAlign = 'center'
    ctx.font = ctx.font = `${Math.max(Math.floor(height * 0.15), 1)}px Arial`
    ctx.fillStyle = options.color
    ctx.fillText(`${options.visible ? options.centerText : ''}`, left + width / 2, top + height / 2)
  },
}

ChartJS.register(CenterTextPlugIn)

const DonutChartJs = ({
  title = null,
  showTitle = true,
  innerRadius = '60%',
  padding = '20%',
  data = [
    {
      value: 2,
      color: '#8bc34a',
      label: 'Series 1',
    },
    {
      value: 2,
      color: '#ff6347',
      label: 'Series 2',
    },
    {
      value: 3,
      color: '#87CEEB',
      label: 'Series 3',
    },
    {
      value: 5,
      color: '#03a9f4',
      label: 'Series 4',
    },
  ],
  centerText = null,
  showCenterText = true,
  showLegend = true,
  legendLocation = 'bottom', //bottom, top, left, right
  labelColor = appColors.headerTextColor,
  titleColor = '#ffffff',
}) => {
  const classes = useStyles()

  const calcRadiusStr = (paddingStr) => {
    if (typeof paddingStr !== 'string') return '80%'
    if (paddingStr === '') return '100%'
    paddingStr = paddingStr.replaceAll('%', '')
    let padding = 100 - parseInt(paddingStr)
    if (typeof padding !== 'number') return '100%'
    return `${padding}%`
  }

  const getSeriesColor = (data, index, opacity) => {
    if (typeof index !== 'number') getRandomColor()
    if (!data) getRandomColor()
    if (!Array.isArray(data)) return getRandomColor()
    if (index < 0 || index >= data.length) return getRandomColor()

    if (data[index].hasOwnProperty('color')) {
      if (typeof opacity !== 'string') opacity = 'ff'
      if (opacity === '') opacity = 'ff'
      return data[index].color + 'ff'
    }

    if (index < chartSeriesColors.length) return chartSeriesColors[index]
    return getRandomColor()
  }

  const chartOptions = {
    responsive: true,
    radius: calcRadiusStr(padding),
    maintainAspectRatio: false,
    cutout: innerRadius,
    plugins: {
      CenterTextPlugIn: {
        centerText: centerText ? centerText : '0',
        color: labelColor,
        visible: showCenterText,
      },
      legend: {
        position: legendLocation ? legendLocation : 'bottom',
        display: showLegend,
        maxWidth: 100,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          padding: 5,
          color: labelColor,
        },
      },
      title: {
        display: showTitle,
        color: titleColor,
        position: 'top',
        align: 'center',
        text: title,
      },
    },
  }

  function getRandomColor() {
    let letters = '0123456789ABCDEF'
    let color = '#'
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }

  const createChartData = () => {
    const chartData = {
      labels: data.map((item) => item.label),
      datasets: [
        {
          data: data.map((item) => item.value),
          backgroundColor: data.map((item, index) => getSeriesColor(data, index, 'dd')),
          borderColor: data.map((item, index) => '#141414'),
          hoverBorderColor: data.map((item, index) => getSeriesColor(data, index, 'ff')),
          hoverBackgroundColor: data.map((item, index) => getSeriesColor(data, index, 'aa')),
          hoverOffset: 1,
          borderWidth: 1,
          borderRadius: 3,
          borderAlign: 'inner',
        },
      ],
    }

    return chartData
  }

  if (!showCenterText) chartOptions.plugins.CenterTextPlugIn.centerText = ''
  if (showCenterText)
    chartOptions.plugins.CenterTextPlugIn.centerText = centerText ? centerText : data.reduce((a, b) => a + b.value, 0)

  return (
    <div className={classes.chartContainer}>
      <Doughnut type='doughnut' options={chartOptions} data={createChartData()} />
    </div>
  )
}

export default DonutChartJs
