import React, { useEffect, useRef } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Field, Form, Formik } from 'formik'
import { each as _each } from 'lodash'

import { appColors } from 'utils'
import { Select as MuiSelect } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import { TextField } from 'components/common/formikMUI/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import { Icon as Iconify } from '@iconify/react'
import { CheckboxWithLabel } from 'components/common/formikMUI/CheckboxWithLabel'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import { ThemeProvider } from '@material-ui/core'
import { datePickerTheme } from 'components/theme'
import { KeyboardDatePicker } from '@material-ui/pickers'

const useStyles = makeStyles((theme) => ({
  textInput: {
    width: '80%',
    margin: 5,
    fontSize: 16,
    borderWidth: 1,
    borderRadius: 5,
    height: 40,
    padding: 5,
    paddingLeft: 50,
    borderColor: appColors.borderColor,
    backgroundColor: appColors.itemBackColor,
    color: appColors.itemTextColor,
  },
  iconStyle: {
    color: appColors.headerTextColor,
    height: '32px',
    width: '32px',
  },
  iconViewStyle: {
    position: 'absolute',
    left: 15,
    top: 15,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
  },
  formItem: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'center',
    width: '95%',
    // for select components:
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderColor: appColors.accentColor,
      borderWidth: '1px',
    },
  },
  hiddenFormItem: {
    height: 0,
  },
  visibleFormItem: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  pickerSelect: {
    margin: 5,
    fontSize: 16,
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderWidth: 1,
    height: '32px',
    //    width: '80%',
    color: appColors.itemTextColor,
    borderColor: appColors.borderColor,
    backgroundColor: appColors.itemBackColor,
    borderRadius: 5,
    paddingRight: 10, // to ensure the text is never behind the icon
    '&:before': {
      backgroundColor: appColors.itemBackColor,
      borderColor: appColors.borderColor,
    },
  },
}))

const useMobileSelectStyles = makeStyles((theme) => ({
  root: {
    margin: 5,
    fontSize: 16,
    padding: '5px 10px 5px 10px',
    border: '1px solid',
    borderWidth: '1px',
    height: '32px',
    color: appColors.itemTextColor,
    borderColor: appColors.borderColor,
    backgroundColor: appColors.itemBackColor,
    borderRadius: '5px',
    '&:before': {
      backgroundColor: appColors.itemBackColor,
      borderColor: appColors.borderColor,
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '&:hover': {
      backgroundColor: appColors.itemBackColor,
      borderColor: appColors.accentColor,
    },

    display: 'flex',
    alignItems: 'center',
  },
  selectMenu: {
    backgroundColor: '#202020',
    '& ul': {
      backgroundColor: '#202020',
    },
    '& li': {
      fontSize: 12,
    },
  },
}))

// Options and createOptions for Material-UI Select component:
type Options = [{ label: string, value: string }]

const createOptions = (list: Options, classes: Any) => {
  const options = []

  _each(list, (item) => {
    options.push(
      <MenuItem value={item.value} key={item.value} className={classes}>
        {item.label}
      </MenuItem>,
    )
  })

  return options
}

const InputForm = ({ formId, inputData, usageType, submitForm, validationSchema }) => {
  const _isMounted = useRef(false)
  const classes = useStyles()
  const selectClasses = useMobileSelectStyles()
  const initialValues = createInitialValues(inputData)

  useEffect(() =>{
    _isMounted.current = true

    return(() => {
      _isMounted.current = false
    })
  }, [])

  function createInitialValues(data) {
    if (!data) return {}
    if (Array.isArray(data) === false) return {}
    if (data.length <= 0) return {}

    // console.log('cIV: rawInitalData ', data)
    const reducedData = data.reduce((output, item) => {
      output[item.tag] = item.value
      return output
    }, {})
    // console.log('cIV: reducedInitialData ', reducedData)
    return reducedData
  }

  const formFilterFunction = (formikProps, data) => {
    if (data === undefined || data === null) return true
    if (!data.hasOwnProperty('filterProperty')) return true
    if (!data.hasOwnProperty('filterValue') && !data.hasOwnProperty('filterFunction')) return true
    if (typeof data.filterProperty !== 'string') return true
    if (!formikProps.hasOwnProperty(data.filterProperty)) return true

    if (data.hasOwnProperty('filterFunction')) {
      return data.filterFunction(formikProps[data.filterProperty])
    }

    if (typeof data.filterValue !== 'string' && typeof data.filterValue !== 'object') return true

    let checkValue = formikProps[data.filterProperty]
    if (typeof checkValue === 'boolean') checkValue = checkValue.toString()
    if (typeof checkValue === 'string') checkValue = checkValue.toLowerCase()

    let filterValue = []
    if (!Array.isArray(data.filterValue)) filterValue.push(data.filterValue.toLowerCase())
    if (Array.isArray(data.filterValue)) {
      data.filterValue.forEach((val) => {
        if (typeof val === 'string') filterValue.push(val.toLowerCase())
      })
    }

    for (let i = 0; i < filterValue.length; i++) {
      if (checkValue === filterValue[i]) return !data.hasOwnProperty('filterValueNotEqual')
    }

    return data.hasOwnProperty('filterValueNotEqual')
  }

  const renderFormItem = (index, formikProps) => {
    let showItem = false

    if (formFilterFunction === null) showItem = true
    if (
      !showItem &&
      !inputData[index].hasOwnProperty('filterValue') &&
      !inputData[index].hasOwnProperty('filterFunction')
    ) {
      showItem = true
    }

    if (!showItem && !inputData[index].hasOwnProperty('filterProperty')) {
      showItem = true
    }

    if (!showItem) {
      if (!formFilterFunction(formikProps.values, inputData[index])) {
        return <div key={index} /> // this div should probably be classed with classes.hiddenFormItem
      }
    }

    return (
      <div
        key={index}
        className={inputData[index].inputType !== '-' ? classes.visibleFormItem : classes.hiddenFormItem}>
        {inputData[index].inputType === 'text' && (
          <>
            <InputLabel id={`${inputData[index].text}-label-id`}>{inputData[index].text}</InputLabel>
            <Field
              component={TextField}
              name={inputData[index].tag}
              type={inputData[index].inputFormType}
              placeholder={inputData[index].text}
              InputProps={{
                startAdornment:
                  renderIcon(index) /*<Iconify icon={'entypo:email'} size={30} className={classes.iconStyle} />*/,
              }}
            />
            {formikProps.errors[inputData[index].tag] && formikProps.touched[inputData[index].tag] && (
              <FormHelperText>{formikProps.errors[inputData[index].tag]}</FormHelperText>
            )}
          </>
        )}

        {inputData[index].inputType === 'boolean' && (
          <>
            <InputLabel id={`${inputData[index].text}-label-id`}>{inputData[index].text}</InputLabel>
            <div className={classes.formItemCB}>
              <Field
                component={CheckboxWithLabel}
                name={inputData[index].tag}
                type='checkbox'
                Label={{ label: inputData[index].text, labelPlacement: 'end' }}
                checkedIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'lime' }} />}
                indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                style={{ color: appColors.itemTextColor }}
              />
            </div>
          </>
        )}

        {inputData[index].inputType === 'dropdown' && (
          <>
            <InputLabel id={`${inputData[index].text}-label-id`}>{inputData[index].text}</InputLabel>
            <Field
              component={MuiSelect}
              name={inputData[index].tag}
              id={inputData[index].tag}
              value={formikProps.values[inputData[index].tag]}
              labelId={`${inputData[index].text}-label-id`}
              onChange={(e) => {
                formikProps.setFieldValue(inputData[index].tag, e.target.value)
              }}
              classes={selectClasses}
              MenuProps={{ classes: { paper: selectClasses.selectMenu } }}
              variant='outlined'>
              {/*createOptions(inputData[index].dropDownValues, classes.pickerSelect)*/}
              {createOptions(
                inputData[index].hasOwnProperty('filterField')
                  ? filterDropwDownList(
                      formikProps.values[inputData[index].filterField],
                      inputData[index].filterField,
                      inputData[index].dropDownValues,
                    )
                  : inputData[index].dropDownValues,
                classes.pickerSelect,
              )}
            </Field>
            {formikProps.errors[inputData[index].tag] && formikProps.touched[inputData[index].tag] && (
              <FormHelperText>{formikProps.errors[inputData[index].tag]}</FormHelperText>
            )}
          </>
        )}

        {inputData[index].inputType === 'date' && (
          <>
            <InputLabel id={`${inputData[index].text}-label-id`}>Start Date</InputLabel>
            <ThemeProvider theme={datePickerTheme}>
              <KeyboardDatePicker
                id={inputData[index].tag}
                name={inputData[index].tag}
                format='dd-MMM-yyyy'
                value={formikProps.values[inputData[index].tag]}
                maxDate={inputData[index].value}
                onChange={(value) => formikProps.setFieldValue(inputData[index].tag, value)}
              />
            </ThemeProvider>
            {formikProps.errors.dateFrom && formikProps.touched.dateFrom && (
              <FormHelperText>{formikProps.errors.dateFrom}</FormHelperText>
            )}
          </>
        )}
      </div>
    )
  }

  const filterDropwDownList = (filterValue, filterField, list) => {
    if (!filterValue || !filterField) return []
    if (filterValue === '' || filterField === '') return []
    if (!list) return []
    if (!Array.isArray(list)) return []
    if (list.length === 0) return []

    let output = []
    list.forEach((item) => {
      if (item[filterField] === filterValue) output.push({ label: item.label, value: item.value })
    })

    return output
  }

  const renderIcon = (index) => {
    let showIcon = checkRender()
    function checkRender() {
      if (inputData[index].hasOwnProperty('icon') === false) return false
      if (inputData[index].hasOwnProperty('iconFamily') === false) return false
      if (inputData[index].icon === '') return false
      if (inputData[index].iconFamily === '') return false

      return true
    }

    if (showIcon === true) {
      return (
        <>
          {inputData[index].iconFamily === 'Iconify' && (
            <Iconify icon={inputData[index].icon} size={30} className={classes.iconStyle} />
          )}
        </>
      )
    } else {
      return <></>
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        // console.log('inputForm(formik) submit, values= ', values)
        submitForm(values)
      }}>
      {({ values, touched, errors, setFieldValue }) => (
        <Form id={formId} className={classes.formContainer}>
          {inputData.map((_, i) => renderFormItem(i, { touched, errors, setFieldValue, values }))}
        </Form>
      )}
    </Formik>
  )
}

export default InputForm
