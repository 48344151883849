import React, { useEffect, useRef, useState } from 'react'

import { makeStyles } from '@material-ui/styles'
import { Icon as Iconify } from '@iconify/react'

import RefreshIcon from '@material-ui/icons/Refresh'
import { appColors } from 'utils'
import { IconButton, Tooltip, Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import AddIcon from '@material-ui/icons/Add'
import PrintIcon from '@material-ui/icons/Print'
import { currentWellAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import { round } from 'utils/numberFunctions'
import { numberWithCommas } from 'utils/stringFunctions'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { threeDeeScan } from 'utils/threeDeeScan'
import { checkPermission } from '../../userPermissions'
import { userUserRoleAtom } from 'atoms'
import InputModal from 'components/common/InputModal'
import * as yup from 'yup'
import * as XLSX from 'xlsx'

import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import SimpleStyledList from 'components/common/SimpleStyledList'
import MenuButton from 'components/common/MenuButton'

const useStyles = makeStyles((theme) => ({
  listContainer: {
    display: 'flex',
    overflow: 'hidden',
    height: '100%',
    border: '1px solid #000',
    borderRadius: '4px',
  },
  headerText: {
    fontSize: 14,
    fontWeight: '500',
    color: appColors.headerTextColor,
    width: '96px',
    textAlign: 'right',
    paddingRight: '8px',
  },
  itemText: {
    fontSize: 18,
    color: appColors.itemTextColor,
    border: '',
  },
  deleteIconStyle: {
    color: '#C00000',
    fontSize: '24px',
  },
  itemMainContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '8px',
    marginBottom: '1px',
    border: `1px solid`,
    borderColor: appColors.itemBackColor,
    backgroundColor: 'inherit',
    '&:hover': {
      borderColor: appColors.accentColor,
    },
  },
  itemTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    width: '100%',
  },
  itemTextWrapContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
  },
  itemTextWrapContainerWide: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1.5,
  },
  itemIconContainer: {
    width: 100,
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltip: {
    backgroundColor: 'rgb(19,62,96)',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
  menuIcon: {
    backgroundColor: 'transparent',
    margin: '4px',
    padding: '12px',
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2) - 20,
  },
}))

const SurveyList = () => {
  const _isMounted = useRef(false)
  const classes = useStyles()
  const editIndex = useRef(-1)
  const userRole = useRecoilValue(userUserRoleAtom)
  const currentWell = useRecoilValue(currentWellAtom)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [showEditor, setShowEditor] = useState(false)

  const { newCancelToken: newSurveyCancelToken, cancelPreviousRequest: cancelSurveyReq } = useAxiosCancelToken()
  const [{ data: surveyData, error: surveyError }, getSurveys] = useAxios({
    url: '/well/getActualWellbore',
    data: { wellName: currentWell },
    cancelToken: newSurveyCancelToken(),
  })

  const { newCancelToken: newWellPlanCancelToken, cancelPreviousRequest: cancelWellPlanReq } = useAxiosCancelToken()
  const [{ data: wellPlanData, error: wellPlanError }, getWellPlan] = useAxios({
    url: '/well/principalPlan',
    data: { wellName: currentWell },
    cancelToken: newWellPlanCancelToken(),
  })

  const { newCancelToken: newDeleteCancelToken, cancelPreviousRequest: cancelPreviousDeleteRequest } =
    useAxiosCancelToken()
  const [{ error: deleteError }, deleteSvy] = useAxios({
    url: '/well/deleteSurveyStation',
    manual: true,
    cancelToken: newDeleteCancelToken(),
  })

  const { newCancelToken: createCancelToken, cancelPreviousRequest: cancelCreateReq } = useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorCreate }, addSurvey] = useAxios({
    url: '/well/addSurveyStation',
    manual: true,
    cancelToken: createCancelToken(),
  })

  const { newCancelToken: updateCancelToken, cancelPreviousRequest: cancelUpdateReq } = useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorUpdate }, updateSurvey] = useAxios({
    url: '/well/updateSurveyStation',
    manual: true,
    cancelToken: updateCancelToken(),
  })

  useEffect(() => {
    if (deleteError !== undefined && deleteError !== null) {
      if (cancelPreviousDeleteRequest(deleteError)) return
    }
  }, [deleteError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      getSurveys()
      getWellPlan()
    }
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (surveyError !== undefined && surveyError !== null) {
      if (cancelSurveyReq(surveyError)) return
    }
  }, [surveyError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorUpdate !== undefined && errorUpdate !== null) {
      if (cancelUpdateReq(errorUpdate)) return
    }
  }, [errorUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorCreate !== undefined && errorCreate !== null) {
      if (cancelCreateReq(errorCreate)) return
    }
  }, [errorCreate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (wellPlanError !== undefined && wellPlanError !== null) {
      if (cancelWellPlanReq(wellPlanError)) return
    }
  }, [wellPlanError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const confirmDelete = async () => {
    if (confirm?.itemIndex > 0) {
      //This is the survey MD so should be > 0 not >= 0
      const deleteResponse = await deleteSvy({
        md: confirm?.itemIndex,
        wellName: currentWell,
      })

      if (!deleteResponse.error) {
        setStatus({ show: true, severity: 'success', message: 'Survey deleted successfully' })
        await getSurveys()
      } else {
        setStatus({ show: true, severity: 'error', message: 'Delete survey failed' })
      }
    }
  }

  const onClickDeleteSvy = (svy) => {
    setConfirm({
      show: true,
      title: 'Delete Survey',
      text: `Are you sure you want to delete the survey @ ${svy?.md}?`,
      itemIndex: parseInt(svy?.md),
    })
  }

  const handleCloseStatus = (event, reason) => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const renderTextField = (text, value) => {
    return (
      <div className={classes.itemTextContainer}>
        <div className={classes.headerText}>{text} </div>
        <div className={classes.itemText}>{value}</div>
      </div>
    )
  }

  const RenderCreateUpdateModal = ({ data, submitFunction, cancelFunction }) => {
    let useInputData = true
    if (!data) useInputData = false
    if (useInputData === true) {
      if (data.hasOwnProperty('md') === false) useInputData = false
    }

    const title = (useInputData ? 'Edit' : 'Add') + ' Survey'

    const formValidationSchema = yup.object({
      md: yup
        .string()
        .matches(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed')
        .required()
        .test('depth-within-limits', 'md less than tie on', function (md) {
          if (this.parent.inputMeth !== 'SURVEY') return true
          if (typeof md !== 'string') return false
          if (typeof md === 'string') md = parseFloat(md)
          if (!Array.isArray(surveyData)) return false
          if (surveyData.length === 0) return false
          return md > surveyData[0].md
        }),
      inc: yup
        .string()
        .matches(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed')
        .test('inc-test', 'inc must be between 0 - 180', function (inc) {
          if (typeof inc !== 'string') return false
          if (typeof inc === 'string') inc = parseFloat(inc)
          return inc >= 0 && inc <= 180
        })
        .required(),
      azi: yup
        .string()
        .matches(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed')
        .test('azi-test', 'inc must be between 0 - 360', function (azi) {
          if (typeof azi !== 'string') return false
          if (typeof azi === 'string') azi = parseFloat(azi)
          return azi >= 0 && azi <= 360
        })
        .required(),
    })

    let formData = [
      {
        tag: 'wellName',
        value: currentWell,
        inputType: '-',
      },
      {
        tag: 'md',
        value: useInputData === true ? data.md : '0',
        text: 'MD',
        inputType: useInputData === true ? '-' : 'text',
      },
      {
        tag: 'inc',
        value: useInputData === true ? data.inc : '0',
        text: 'INC',
        inputType: 'text',
      },
      {
        tag: 'azi',
        value: useInputData === true ? data.azi : '0',
        text: 'AZI',
        inputType: 'text',
      },
    ]

    return (
      <InputModal
        open={showEditor}
        onClose={cancelFunction}
        title={title}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={cancelFunction}
        validationSchema={formValidationSchema}
      />
    )
  }

  const renderSurvey = (orgSvy, idx) => {
    let svy = { ...orgSvy }
    if (wellPlanData?.hasOwnProperty('surveyData')) {
      svy = threeDeeScan(svy, wellPlanData.surveyData)
    }

    return (
      <div key={idx} className={classes.itemMainContainer}>
        <div
          className={classes.itemTextContainer}
          onClick={() => {
            editIndex.current = idx
            setShowEditor(true)
          }}>
          <div className={classes.itemTextWrapContainer}>
            {renderTextField('MD: ', numberWithCommas(round(svy.md, 2)))}
            {renderTextField('INC: ', numberWithCommas(round(svy.inc, 2)))}
            {renderTextField('AZI: ', numberWithCommas(round(svy.azi, 2)))}
            {renderTextField('CL: ', numberWithCommas(round(svy.cl, 2)))}
          </div>
          <div className={classes.itemTextWrapContainer}>
            {renderTextField('TVD: ', numberWithCommas(round(svy.tvd, 2)))}
            {renderTextField('NS: ', numberWithCommas(round(svy.ns, 2)))}
            {renderTextField('EW: ', numberWithCommas(round(svy.ew, 2)))}
            {renderTextField('DIST: ', numberWithCommas(round(svy.acScan?.dist, 2)))}
          </div>
          <div className={classes.itemTextWrapContainer}>
            {renderTextField('VS: ', numberWithCommas(round(svy.vs, 2)))}
            {renderTextField('TFO: ', numberWithCommas(round(svy.tf, 2)))}
            {renderTextField('DLS: ', numberWithCommas(round(svy.dls, 2)))}
            {renderTextField('UD: ', numberWithCommas(round(svy.acScan?.UD, 2)))}
          </div>
          <div className={classes.itemTextWrapContainer}>
            {renderTextField('LAT: ', numberWithCommas(round(svy.latDeg, 5)))}
            {renderTextField('LONG: ', numberWithCommas(round(svy.longDeg, 5)))}
            {renderTextField('BR: ', numberWithCommas(round(svy.br, 2)))}
            {renderTextField('LR: ', numberWithCommas(round(svy.acScan?.LR, 2)))}
          </div>
          <div className={classes.itemTextWrapContainerWide}>
            {renderTextField('GRID N: ', numberWithCommas(round(svy.gridNorth, 2)))}
            {renderTextField('GRID E: ', numberWithCommas(round(svy.gridEast, 2)))}
            {renderTextField('TR: ', numberWithCommas(round(svy.tr, 2)))}
            {renderTextField('IPM: ', svy.ipm)}
          </div>
        </div>
        {checkPermission('canDelete', userRole.roleAttributes?.permissions) ? (
          <Tooltip title='Delete Survey' placement='bottom' classes={{ tooltip: classes.tooltip }}>
            <IconButton className={classes.itemIconContainer} onClick={() => onClickDeleteSvy(svy)}>
              <Iconify icon='fa-regular:trash-alt' className={classes.deleteIconStyle} />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
    )
  }

  const submitFunction = async (data, formikActions) => {
    setShowEditor(false)
    if (!data) return false
    let isAdd = false
    if (editIndex.current < 0) {
      isAdd = true
    }
    let payload = { ...data }

    let saveResponse
    if (isAdd) {
      saveResponse = await addSurvey(payload, createCancelToken())
    } else {
      saveResponse = await updateSurvey(payload, updateCancelToken())
    }

    if (saveResponse.error) {
      setStatus({ show: true, severity: 'error', message: isAdd ? 'Survey failed to add' : 'Survey update failed' })
      formikActions?.setSubmitting(false)
      return false
    } else {
      setStatus({ show: true, severity: 'success', message: isAdd ? 'Survey added' : 'Survey updated' })
    }

    await getSurveys()
    return true
  }

  const handleNewSvy = () => {
    setShowEditor(true)
  }

  const handleClose = () => {
    setShowEditor(false)
  }

  const getSurveyData = () => {
    if (!Array.isArray(surveyData)) return []
    if (surveyData.length === 0) return []
    let svyOut = []
    surveyData.forEach((svy) => {
      let cpy = { ...svy }
      if (wellPlanData?.hasOwnProperty('surveyData')) {
        cpy = threeDeeScan(cpy, wellPlanData.surveyData)
      }

      svyOut.push(cpy)
    })

    return svyOut
  }

  const onXlsxExport = () => {
    const data = getSurveyData()
    let headers = []
    headers.push('#')
    headers.push('MD')
    headers.push('INC')
    headers.push('AZI')
    headers.push('TVD')
    headers.push('NS')
    headers.push('EW')
    headers.push('VS')
    headers.push('CL')
    headers.push('DLS')
    headers.push('BR')
    headers.push('TR')
    headers.push('TFO')
    headers.push('LAT')
    headers.push('LONG')
    headers.push('GRID N')
    headers.push('GRID E')
    headers.push('UD')
    headers.push('LR')
    headers.push('DIST')
    headers.push('IPM')

    let ws = XLSX.utils.aoa_to_sheet([headers], {
      origin: 'A1',
    })

    if (Array.isArray(data) && data.length > 0) {
      let svyExport = []
      for (let i = 0; i < data.length; i++) {
        let newSvyRow = []
        newSvyRow.push(i + 1) //#
        newSvyRow.push(data[i].md)
        newSvyRow.push(data[i].inc)
        newSvyRow.push(data[i].azi)
        newSvyRow.push(data[i].tvd)
        newSvyRow.push(data[i].ns)
        newSvyRow.push(data[i].ew)
        newSvyRow.push(data[i].vs)
        newSvyRow.push(data[i].cl)
        newSvyRow.push(data[i].dls)
        newSvyRow.push(data[i].br)
        newSvyRow.push(data[i].tr)
        newSvyRow.push(data[i].tf)
        newSvyRow.push(data[i].latDeg)
        newSvyRow.push(data[i].longDeg)
        newSvyRow.push(data[i].gridNorth)
        newSvyRow.push(data[i].gridEast)
        newSvyRow.push(data[i].hasOwnProperty('acScan') ? data[i].acScan.UD : 0)
        newSvyRow.push(data[i].hasOwnProperty('acScan') ? data[i].acScan.LR : 0)
        newSvyRow.push(data[i].hasOwnProperty('acScan') ? data[i].acScan.dist : 0)
        newSvyRow.push(data[i].ipm)
        svyExport.push(newSvyRow)
      }

      XLSX.utils.sheet_add_aoa(ws, svyExport, { origin: 'A2' })
    }
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Surveys')
    XLSX.writeFile(wb, 'Surveys.xlsx')
  }

  return (
    <React.Fragment>
      {showEditor ? (
        <RenderCreateUpdateModal
          data={editIndex.current >= 0 ? surveyData[editIndex.current] : {}}
          submitFunction={submitFunction}
          cancelFunction={handleClose}
        />
      ) : null}
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => confirmDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <div className={classes.listContainer}>
        <SimpleStyledList listItems={surveyData} renderItem={renderSurvey}></SimpleStyledList>
      </div>

      <div className={classes.menuIcon}>
        <MenuButton
          actions={[
            {
              icon: <AddIcon />,
              name: 'Add',
              onClick: () => {
                editIndex.current = -1
                handleNewSvy(true)
              },
            },
            { icon: <RefreshIcon />, name: 'Refresh', getSurveys },
            { icon: <PrintIcon />, name: 'Print', onClick: onXlsxExport },
          ]}
        />
      </div>

      {status?.show ? (
        <Snackbar open={status?.show} autoHideDuration={2000} onClose={handleCloseStatus}>
          <MuiAlert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </MuiAlert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default SurveyList
