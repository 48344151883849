import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

import Drawer from '@material-ui/core/Drawer'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'

import { useRecoilState, useRecoilValue } from 'recoil'
import { actionBarWidthAtom, currentPageAtom, userUserRoleAtom } from '../../atoms'
import { Icon as Iconify } from '@iconify/react'

import WellListDefaultIcon from 'assets/wellScreenIcons/view-list-grey.png'
import WellListActiveIcon from 'assets/wellScreenIcons/view-list-blue.png'
import WellViewDefaultIcon from 'assets/wellScreenIcons/drilling-parameters-grey.png'
import WellViewActiveIcon from 'assets/wellScreenIcons/drilling-parameters-blue.png'
import WellDetailsDefaultIcon from 'assets/wellScreenIcons/well-summary-grey.png'
import WellDetailsActiveIcon from 'assets/wellScreenIcons/well-summary-blue.png'
import DepthTimeDefaultIcon from 'assets/wellScreenIcons/graph-grey.png'
import DepthTimeActiveIcon from 'assets/wellScreenIcons/graph-blue.png'
import DailyActivityDefaultIcon from 'assets/wellScreenIcons/daily-report-grey.png'
import DailyActivityActiveIcon from 'assets/wellScreenIcons/daily-report-blue.png'
import SurveysDefaultIcon from 'assets/wellScreenIcons/surveys-grey.png'
import SurveysActiveIcon from 'assets/wellScreenIcons/surveys-blue.png'
import ToolInventoryDefaultIcon from 'assets/wellScreenIcons/inventory-grey.png'
import ToolInventoryActiveIcon from 'assets/wellScreenIcons/inventory-blue.png'
import DrillStringDefaultIcon from 'assets/wellScreenIcons/drill-string-grey.png'
import DrillStringActiveIcon from 'assets/wellScreenIcons/drill-string-blue.png'
import CostsDefaultIcon from 'assets/wellScreenIcons/currency-usd-grey.png'
import CostsActiveIcon from 'assets/wellScreenIcons/currency-usd-blue.png'
import SlideSheetDefaultIcon from 'assets/wellScreenIcons/drilling-parameters-grey.png'
import SlideSheetActiveIcon from 'assets/wellScreenIcons/drilling-parameters-blue.png'
import DrillingDashboardDefaultIcon from 'assets/wellScreenIcons/toolface-rosebud-grey.png'
import DrillingDashboardActiveIcon from 'assets/wellScreenIcons/toolface-rosebud-blue.png'
import Well3DDefaultIcon from 'assets/wellScreenIcons/3d-view-grey.png'
import Well3DActiveIcon from 'assets/wellScreenIcons/3d-view-blue.png'
import LogisticsDefaultIcon from 'assets/wellScreenIcons/logistics-flatbed-grey.png'
import LogisticsActiveIcon from 'assets/wellScreenIcons/logistics-flatbed-blue.png'
import AnalyticsDefaultIcon from 'assets/wellScreenIcons/analytics-ring-grey.png'
import AnalyticsActiveIcon from 'assets/wellScreenIcons/analytics-ring-blue.png'
import EdrDefaultIcon from 'assets/wellScreenIcons/edr-grey.png'
import EdrActiveIcon from 'assets/wellScreenIcons/edr-blue.png'

import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import { appColors } from 'utils'
import { checkBaseFeature, checkFeature } from '../userPermissions'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: (props) => props.drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: 'rgba(8,8,8,1.0)',
    width: (props) => props.drawerWidth,
  },
  drawerContainer: {
    overflow: 'auto',
  },
  menuItemText: {
    color: 'rgba(192,192,192,1.0)',
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  iconStyle: {
    color: 'rgba(208,208,208,1.0)',
    width: '35px',
    minWidth: '35px',
  },
  nested: {
    // paddingLeft: theme.spacing(4), // enable if indent required...
    paddingLeft: '16px',
    backgroundColor: 'rgba(32,32,32,1.0)',
  },
  subList: {
    border: '1px solid',
    borderColor: 'rgb(56, 219, 255)',
  },
  tooltip: {
    backgroundColor: 'rgb(19,62,96)',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
}))

const DRAWERWIDE = 160
const DRAWERSLIM = 60

const ActionBar = ({ history }) => {
  const [drawerWidth, setDrawerWidth] = useRecoilState(actionBarWidthAtom)
  const classes = useStyles({ drawerWidth })
  const [wellListOpen, setWellsListOpen] = useState(false)
  const { isAuthenticated } = useAuth0()
  const [activePage, setActivePage] = useRecoilState(currentPageAtom)
  const [analyticsListOpen, setAnalyticsListOpen] = useState(false)
  const userRole = useRecoilValue(userUserRoleAtom)

  if (!isAuthenticated) return null

  const navigate = (route) => {
    history.push(route)
  }

  const expandDrawer = () => {
    setDrawerWidth(DRAWERWIDE)
  }

  const contractDrawer = () => {
    setDrawerWidth(DRAWERSLIM)
  }

  const handleWellsListClick = () => {
    setActivePage(PAGE_KEYS.wellListKey)
    navigate('/well-list')
  }

  const handleWellViewsClick = () => {
    setWellsListOpen(!wellListOpen)
  }

  const handleAnalyticsClick = () => {
    setAnalyticsListOpen(!analyticsListOpen)
  }

  const handleActionClick = (pageKey, route) => {
    setActivePage(pageKey)
    navigate(route)
  }

  const isWellViewActive = () => {
    const wellViewPages = [
      PAGE_KEYS.wellDetailsKey,
      PAGE_KEYS.dailyActivityKey,
      PAGE_KEYS.depthTimeKey,
      PAGE_KEYS.inventoryKey,
      PAGE_KEYS.surveyKey,
      PAGE_KEYS.slideSheetKey,
      PAGE_KEYS.costsHeadersKey,
      PAGE_KEYS.drillStringKey,
      PAGE_KEYS.drillingDashboardKey,
      PAGE_KEYS.threeDViewKey,
    ]
    return wellViewPages.includes(activePage)
  }

  const isAnalyticsActive = () => {
    const analyticsPages = [
      PAGE_KEYS.depthVDaysKey,
      PAGE_KEYS.slideRotateFootageKey,
      PAGE_KEYS.slideRotateFootageAltKey,
      PAGE_KEYS.slideRotateRopKey,
      PAGE_KEYS.slideRotateHoursKey,
      PAGE_KEYS.costPerWellKey,
      PAGE_KEYS.costPerDayKey,
      PAGE_KEYS.costPerOperatorKey,
      PAGE_KEYS.rigDaysKey,
    ]
    return analyticsPages.includes(activePage)
  }

  const renderExpandContractListItem = (text) => {
    return drawerWidth === DRAWERWIDE ? (
      <ListItem button key='expander' onClick={contractDrawer}>
        <Tooltip title='collapse' placement='right' classes={{ tooltip: classes.tooltip }}>
          <ListItemIcon className={classes.iconStyle}>
            <KeyboardArrowLeftIcon />
          </ListItemIcon>
        </Tooltip>
      </ListItem>
    ) : (
      <ListItem button key='contractor' onClick={expandDrawer}>
        <Tooltip title='expand' placement='right' classes={{ tooltip: classes.tooltip }}>
          <ListItemIcon className={classes.iconStyle}>
            <KeyboardArrowRightIcon />
          </ListItemIcon>
        </Tooltip>
      </ListItem>
    )
  }

  const renderListItemText = (text) => {
    return drawerWidth === DRAWERWIDE ? (
      <ListItemText primary={text} classes={{ primary: classes.menuItemText }}></ListItemText>
    ) : null
  }

  const renderIconWithToolTip = (altText, component, height, width, toolTipText) => {
    return drawerWidth === DRAWERWIDE ? (
      <ListItemIcon className={classes.iconStyle}>
        <img alt={altText} src={component} style={{ height: height, width: width }} />
      </ListItemIcon>
    ) : (
      <Tooltip title={toolTipText} placement='right' classes={{ tooltip: classes.tooltip }}>
        <ListItemIcon className={classes.iconStyle}>
          <img alt={altText} src={component} style={{ height: height, width: width }} />
        </ListItemIcon>
      </Tooltip>
    )
  }

  const renderIconifyIconWithToolTip = (altText, component, color, height, width, toolTipText) => {
    return drawerWidth === DRAWERWIDE ? (
      <ListItemIcon className={classes.iconStyle}>
        <Iconify icon={component} style={{ color: color, height: height, width: width }} />
      </ListItemIcon>
    ) : (
      <Tooltip title={toolTipText} placement='right' classes={{ tooltip: classes.tooltip }}>
        <ListItemIcon className={classes.iconStyle}>
          <Iconify icon={component} style={{ color: color, height: height, width: width }} />
        </ListItemIcon>
      </Tooltip>
    )
  }

  const renderResponseList = () => {
    return (
      <React.Fragment>
        <List>
          {renderExpandContractListItem()}
          <ListItem button key='wellsList' onClick={handleWellsListClick}>
            {renderIconWithToolTip(
              'wellslist',
              activePage === PAGE_KEYS.wellListKey ? WellListActiveIcon : WellListDefaultIcon,
              20,
              20,
              'well list',
            )}
            {renderListItemText('Wells List')}
          </ListItem>
          <Divider />
          <Divider />
          <ListItem button key='wellViews' onClick={handleWellViewsClick}>
            {renderIconWithToolTip(
              'wellviews',
              isWellViewActive() ? WellViewActiveIcon : WellViewDefaultIcon,
              28,
              24,
              'well views',
            )}
            {renderListItemText('Well Views')}
          </ListItem>
          <Collapse in={wellListOpen} timeout='auto' unmountOnExit>
            <List component='div' disablePadding className={classes.subList}>
              <ListItem
                key='wellSummary'
                button
                className={classes.nested}
                onClick={() => handleActionClick(PAGE_KEYS.wellDetailsKey, '/well-pages/well-page')}>
                {renderIconWithToolTip(
                  'wellSummary',
                  activePage === PAGE_KEYS.wellDetailsKey ? WellDetailsActiveIcon : WellDetailsDefaultIcon,
                  20,
                  20,
                  'well details',
                )}
                {renderListItemText('Well Details')}
              </ListItem>
              {checkBaseFeature('dailyReports', userRole?.roleAttributes?.baseFeaturePerms) ? (
                <ListItem
                  key='dailyactivity'
                  button
                  className={classes.nested}
                  onClick={() => handleActionClick(PAGE_KEYS.dailyActivityKey, '/well-pages/daily-activity')}>
                  {renderIconWithToolTip(
                    'dailyactivity',
                    activePage === PAGE_KEYS.dailyActivityKey ? DailyActivityActiveIcon : DailyActivityDefaultIcon,
                    28,
                    20,
                    'daily activity list',
                  )}
                  {renderListItemText('Daily Activity')}
                </ListItem>
              ) : null}
              {checkBaseFeature('depthVdays', userRole?.roleAttributes?.baseFeaturePerms) ? (
                <ListItem
                  key='depthtime'
                  button
                  className={classes.nested}
                  onClick={() => handleActionClick(PAGE_KEYS.depthTimeKey, '/well-pages/depth-time')}>
                  {renderIconWithToolTip(
                    'depthtime',
                    activePage === PAGE_KEYS.depthTimeKey ? DepthTimeActiveIcon : DepthTimeDefaultIcon,
                    28,
                    28,
                    'depth v time',
                  )}
                  {renderListItemText('Depth/Time')}
                </ListItem>
              ) : null}
              {checkBaseFeature('toolInventory', userRole?.roleAttributes?.baseFeaturePerms) ? (
                <ListItem
                  key='inventory'
                  button
                  className={classes.nested}
                  onClick={() => handleActionClick(PAGE_KEYS.inventoryKey, '/well-pages/inventory')}>
                  {renderIconWithToolTip(
                    'inventory',
                    activePage === PAGE_KEYS.inventoryKey ? ToolInventoryActiveIcon : ToolInventoryDefaultIcon,
                    24,
                    24,
                    'inventory',
                  )}
                  {renderListItemText('Inventory')}
                </ListItem>
              ) : null}
              {checkBaseFeature('surveys', userRole?.roleAttributes?.baseFeaturePerms) ? (
                <ListItem
                  key='surveys'
                  button
                  className={classes.nested}
                  onClick={() => handleActionClick(PAGE_KEYS.surveyKey, '/well-pages/surveys')}>
                  {renderIconWithToolTip(
                    'surveys',
                    activePage === PAGE_KEYS.surveyKey ? SurveysActiveIcon : SurveysDefaultIcon,
                    24,
                    24,
                    'surveys',
                  )}
                  {renderListItemText('Surveys')}
                </ListItem>
              ) : null}
              {checkBaseFeature('slideSheets', userRole?.roleAttributes?.baseFeaturePerms) ? (
                <ListItem
                  key='slidesheets'
                  button
                  className={classes.nested}
                  onClick={() => handleActionClick(PAGE_KEYS.slideSheetKey, '/well-pages/slide-sheets')}>
                  {renderIconWithToolTip(
                    'slidesheets',
                    activePage === PAGE_KEYS.slideSheetKey ? SlideSheetActiveIcon : SlideSheetDefaultIcon,
                    28,
                    24,
                    'slide sheets',
                  )}
                  {renderListItemText('Slide Sheets')}
                </ListItem>
              ) : null}
              {checkBaseFeature('costs', userRole?.roleAttributes?.baseFeaturePerms) ? (
                <ListItem
                  key='costs'
                  button
                  className={classes.nested}
                  onClick={() => handleActionClick(PAGE_KEYS.costsHeadersKey, '/well-pages/costs/dailyCostHeaders')}>
                  {renderIconWithToolTip(
                    'costs',
                    activePage === PAGE_KEYS.costsHeadersKey ? CostsActiveIcon : CostsDefaultIcon,
                    24,
                    14,
                    'costs',
                  )}
                  {renderListItemText('Costs')}
                </ListItem>
              ) : null}
              {checkBaseFeature('drillString', userRole?.roleAttributes?.baseFeaturePerms) ? (
                <ListItem
                  key='drillstring'
                  button
                  className={classes.nested}
                  onClick={() => handleActionClick(PAGE_KEYS.drillStringKey, '/well-pages/drill-string')}>
                  {renderIconWithToolTip(
                    'drillstring',
                    activePage === PAGE_KEYS.drillStringKey ? DrillStringActiveIcon : DrillStringDefaultIcon,
                    28,
                    20,
                    'drill string',
                  )}
                  {renderListItemText('Drill String')}
                </ListItem>
              ) : null}
              <ListItem
                key='drillingdashboard'
                button
                className={classes.nested}
                onClick={() => handleActionClick(PAGE_KEYS.drillingDashboardKey, '/well-pages/drilling-dashboard')}>
                {renderIconWithToolTip(
                  'drilldash',
                  activePage === PAGE_KEYS.drillingDashboardKey
                    ? DrillingDashboardActiveIcon
                    : DrillingDashboardDefaultIcon,
                  24,
                  24,
                  'drilling dashboard',
                )}
                {renderListItemText('Drilling Dashboard')}
              </ListItem>
              {checkBaseFeature('surveys', userRole?.roleAttributes?.baseFeaturePerms) ? (
                <ListItem
                  key='3dview'
                  button
                  className={classes.nested}
                  onClick={() => handleActionClick(PAGE_KEYS.threeDViewKey, '/well-pages/well-3d-viewer')}>
                  {renderIconWithToolTip(
                    '3dview',
                    activePage === PAGE_KEYS.threeDViewKey ? Well3DActiveIcon : Well3DDefaultIcon,
                    28,
                    24,
                    '3D well view',
                  )}
                  {renderListItemText('3D View')}
                </ListItem>
              ) : null}
            </List>
          </Collapse>
        </List>
        {checkFeature(8, userRole?.roleAttributes?.featureId) ? (
          <List>
            <ListItem button key={'edr'} onClick={() => handleActionClick(PAGE_KEYS.edrPageKey, '/edr')}>
              {renderIconWithToolTip(
                'edr',
                activePage === PAGE_KEYS.edrPageKey ? EdrActiveIcon : EdrDefaultIcon,
                24,
                24,
                'edr',
              )}
              {renderListItemText('EDR')}
            </ListItem>
          </List>
        ) : null}
        {checkBaseFeature('logisticsDashboard', userRole?.roleAttributes?.baseFeaturePerms) ? (
          <List>
            <ListItem button key={'logistics'} onClick={() => handleActionClick(PAGE_KEYS.logisticsKey, '/logistics')}>
              {renderIconWithToolTip(
                'logistics',
                activePage === PAGE_KEYS.logisticsKey ? LogisticsActiveIcon : LogisticsDefaultIcon,
                20,
                28,
                'logistics',
              )}
              {renderListItemText('Logistics')}
            </ListItem>
          </List>
        ) : null}
        {checkFeature(2, userRole?.roleAttributes?.featureId) ? (
          <List>
            <ListItem button key={'analytics'} onClick={handleAnalyticsClick}>
              {renderIconWithToolTip(
                'analytics',
                isAnalyticsActive() ? AnalyticsActiveIcon : AnalyticsDefaultIcon,
                20,
                20,
                'analytics',
              )}
              {renderListItemText('Analytics')}
            </ListItem>
            <Collapse in={analyticsListOpen} timeout='auto' unmountOnExit>
              <List component='div' disablePadding className={classes.subList}>
                <ListItem
                  button
                  className={classes.nested}
                  key={'depthvdays'}
                  onClick={() => handleActionClick(PAGE_KEYS.depthVDaysKey, '/analytics/depthVDays')}>
                  {renderIconifyIconWithToolTip(
                    'depth v days',
                    'ci:line-chart-down',
                    activePage === PAGE_KEYS.depthVDaysKey ? appColors.itemTextColorMobile : appColors.headerTextColor,
                    20,
                    20,
                    'DepthVDays',
                  )}
                  {renderListItemText('Depth V Days')}
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  key={'sliderotatefootage'}
                  onClick={() => handleActionClick(PAGE_KEYS.slideRotateFootageKey, '/analytics/slideRotateFootage')}>
                  {renderIconifyIconWithToolTip(
                    'slide/rotate footage',
                    'ant-design:bar-chart-outlined',
                    activePage === PAGE_KEYS.slideRotateFootageKey
                      ? appColors.itemTextColorMobile
                      : appColors.headerTextColor,
                    20,
                    20,
                    'Slide/Rotate Footage',
                  )}
                  {renderListItemText('Slide Rotate Footage')}
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  key={'sliderotaterop'}
                  onClick={() => handleActionClick(PAGE_KEYS.slideRotateRopKey, '/analytics/slideRotateRop')}>
                  {renderIconifyIconWithToolTip(
                    'slide/rotate rop',
                    'carbon:chart-line-data',
                    activePage === PAGE_KEYS.slideRotateRopKey
                      ? appColors.itemTextColorMobile
                      : appColors.headerTextColor,
                    20,
                    20,
                    'Slide/Rotate ROP',
                  )}
                  {renderListItemText('Slide Rotate ROP')}
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  key={'sliderotatehours'}
                  onClick={() => handleActionClick(PAGE_KEYS.slideRotateHoursKey, '/analytics/slideRotateHours')}>
                  {renderIconifyIconWithToolTip(
                    'slide/rotate hours',
                    'healthicons:chart-bar-stacked-outline',
                    activePage === PAGE_KEYS.slideRotateHoursKey
                      ? appColors.itemTextColorMobile
                      : appColors.headerTextColor,
                    20,
                    20,
                    'Slide/Rotate Hours',
                  )}
                  {renderListItemText('Slide Rotate Hours')}
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  key={'costperwell'}
                  onClick={() => handleActionClick(PAGE_KEYS.costPerWellKey, '/analytics/costPerWell')}>
                  {renderIconifyIconWithToolTip(
                    'cost per well',
                    'cil:bar-chart',
                    activePage === PAGE_KEYS.costPerWellKey ? appColors.itemTextColorMobile : appColors.headerTextColor,
                    20,
                    20,
                    'Cost Per Well',
                  )}
                  {renderListItemText('Cost Per Well')}
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  key={'costperday'}
                  onClick={() => handleActionClick(PAGE_KEYS.costPerDayKey, '/analytics/costPerDay')}>
                  {renderIconifyIconWithToolTip(
                    'cost per day',
                    'carbon:chart-cluster-bar',
                    activePage === PAGE_KEYS.costPerDayKey ? appColors.itemTextColorMobile : appColors.headerTextColor,
                    20,
                    20,
                    'Cost Per Day',
                  )}
                  {renderListItemText('Cost Per Day')}
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  key={'costperoperator'}
                  onClick={() => handleActionClick(PAGE_KEYS.costPerOperatorKey, '/analytics/costPerOperator')}>
                  {renderIconifyIconWithToolTip(
                    'cost per operator',
                    'bi:bar-chart-line',
                    activePage === PAGE_KEYS.costPerOperatorKey
                      ? appColors.itemTextColorMobile
                      : appColors.headerTextColor,
                    20,
                    20,
                    'Cost Per Operator',
                  )}
                  {renderListItemText('Cost Per Operator')}
                </ListItem>
                <ListItem
                  button
                  className={classes.nested}
                  key={'rigdays'}
                  onClick={() => handleActionClick(PAGE_KEYS.rigDaysKey, '/analytics/rigDays')}>
                  {renderIconifyIconWithToolTip(
                    'rig days',
                    'icon-park-outline:chart-histogram',
                    activePage === PAGE_KEYS.rigDaysKey ? appColors.itemTextColorMobile : appColors.headerTextColor,
                    20,
                    20,
                    'Rig Days',
                  )}
                  {renderListItemText('Rig Days')}
                </ListItem>
              </List>
            </Collapse>
          </List>
        ) : null}
      </React.Fragment>
    )
  }

  return (
    <Drawer
      className={classes.drawer}
      variant='permanent'
      classes={{
        paper: classes.drawerPaper,
      }}>
      <Toolbar />
      {renderResponseList()}
      <div className={classes.drawerContainer}></div>
    </Drawer>
  )
}

export default withRouter(ActionBar)
