import React from 'react'

import WellViewPage from './WellViewPage'
import DailyActivityTable from 'components/WellDetailsPage/components/DailyActivityTable'

import { useRecoilValue } from 'recoil'
import { currentWellAtom } from 'atoms'

const DailyActivityPage = () => {
  const currentWell = useRecoilValue(currentWellAtom)

  const options = {
    title: 'Daily Activity',
    key: 'dailyActivity',
    route: '/well/dailyActivity',
    cardType: 'dailyActivityTable',
  }

  return (
    <WellViewPage>
        <DailyActivityTable
          columnKey={options.key}
          apiRoute={options.route}
          well={currentWell}
          search={options.search}
          filtering={options.filtering}
        />
    </WellViewPage>
  )
}

export default DailyActivityPage