import React from 'react'

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { makeStyles } from '@material-ui/styles'
import { merge as _merge } from 'lodash'

import { appColors } from 'utils'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: appColors.itemBackColor,
    padding: 16,
    display: 'flex',
    flex: 3,
    overflow: 'hidden',
  },
}))

type Props = {
  wellData: Array,
  units: String,
}

// BarChart - ChartJS
const BarChartCjs = ({ wellData, units, title, xAxisTitle, yAxisTitle, chartOptions }: Props) => {
  const classes = useStyles()

  const defaultOptions = {
    plugins: {
      title: {
        display: true,
        text: title,
        color: appColors.itemTextColor,
        font: {
          size: 20,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: xAxisTitle,
          color: appColors.headerTextColor,
        },
        stacked: true,
      },
      y: {
        title: {
          display: true,
          text: yAxisTitle,
          color: appColors.headerTextColor,
        },
        grid: {
          color: '#404040',
        },
        stacked: true,
      },
    },
  }

  const options = _merge(defaultOptions, chartOptions)
  // console.log('BarChart options', options)
  
  return (
    <div className={classes.container}>
      <Bar options={options} data={wellData} />
    </div>
  )
}

export default BarChartCjs
