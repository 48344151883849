import React from 'react'
import each from 'lodash/each'
import DonutChartJs from './WellList/DonutChartJs'
import { makeStyles } from '@material-ui/core/styles'
import { GetActivityColor } from 'components/common/activitySelector'

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: '#0c0c0c',
    borderRadius: '5px',
    padding: '1px',
    margin: '3px',
    height: '20vh',
    maxHeight: '200px',
  },
}))

const countStatus = (wells) => {
  let active = 0
  let standby = 0
  let batch = 0
  let upcoming = 0
  let complete = 0
  let td = 0
  let other = 0

  if (wells) {
    each(wells, (well) => {
      switch (well.wellStatus) {
        case 'Active':
          active += 1
          break
        case 'Standby':
          standby += 1
          break
        case 'Batch':
          batch += 1
          break
        case 'Upcoming':
          upcoming += 1
          break
        case 'Complete':
          complete += 1
          break
        case 'TD':
          td += 1
          break
        default:
          other += 1
          break
      }
    })
  }

  return { active, standby, batch, upcoming, complete, td, other }
}

export function getDistinctOperators(data) {
  if (!data) return []
  if (data.length <= 0) return []

  let output = []
  data.forEach((pt) => {
    if (output.findIndex((element) => element.label === pt.operator) < 0) output.push({ label: pt.operator, value: 0 })
  })

  return output
}

const getOperatorData = (wells) => {
  if (!wells) return []
  if (!Array.isArray(wells)) return []
  if (wells.length === 0) return []

  let ops = getDistinctOperators(wells)
  for (let i = 0; i < wells.length; i++) {
    let index = ops.findIndex((element) => element.label === wells[i].operator)
    if (index >= 0) ops[index].value++
  }
  return ops
}

const getDonutChartData = (status, wells) => {
  if (!status) return []
  if (!Array.isArray(status)) return []

  if (!wells) return []
  if (!Array.isArray(wells)) return []
  if (wells.length === 0) return []

  const counts = countStatus(wells)
  return status.map((stat) => {
    let statLwr = stat.toLowerCase()
    return { value: counts.hasOwnProperty(statLwr) ? counts[statLwr] : 0, color: GetActivityColor(stat), label: stat }
  })
}

const WellActivtyCharts = ({ wells }) => {
  const classes = useStyles()

  return (
    <React.Fragment>
      <div className={classes.chartContainer}>
        <DonutChartJs
          title={'Active wells'}
          data={getDonutChartData(['Active', 'Standby', 'Upcoming', 'TD'], wells)}
          legendLocation={'right'}
          showLegend={false}
          showTitle={true}
        />
      </div>
      <div className={classes.chartContainer}>
        <DonutChartJs
          title={'All wells'}
          data={getDonutChartData(['Active', 'Standby', 'Upcoming', 'TD', 'Complete', 'Batch', 'Other'], wells)}
          legendLocation={'right'}
          showLegend={false}
          showTitle={true}
        />
      </div>
      <div className={classes.chartContainer}>
        <DonutChartJs
          title={'Wells per operator'}
          data={getOperatorData(wells)}
          legendLocation={'right'}
          showLegend={false}
          showTitle={true}
        />
      </div>
    </React.Fragment>
  )
}

export default WellActivtyCharts
