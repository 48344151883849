import React, { useEffect, useRef, useState } from 'react'

import { makeStyles } from '@material-ui/styles'
import { Icon as Iconify } from '@iconify/react'

import { filter as _filter } from 'lodash'

import { appColors, daysSinceActive, getDaysText } from 'utils'
import { Avatar, Badge, Fab, IconButton, Snackbar, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import MuiAlert from '@material-ui/lab/Alert'

import SearchBar from 'material-ui-search-bar'

import SimpleStyledList from 'components/common/SimpleStyledList'
import EditUserDialog from './EditUserDialog'
import ConfirmDialog from 'components/common/ConfirmDialog'
import SimplePickListDialog from './SimplePickListDialog'
import SectionPickListDialog from 'components/common/SectionPickListDialog'
import { searchFunction } from '../../common/searchFunctions'

const useStyles = makeStyles((theme) => ({
  listContainer: {
    display: 'flex',
    overflow: 'hidden',
    height: 'calc(calc(100vh - 196px) * 1.00)',
    maxHeight: 'calc(calc(100vh - 196px) * 1.00)',
    borderRadius: '4px',
  },
  headerText: {
    fontSize: 14,
    fontWeight: '500',
    color: appColors.headerTextColor,
    marginLeft: 10,
  },
  itemHeaderText: {
    fontSize: 20,
    fontWeight: '600',
    color: appColors.itemTextColor,
    textAlign: 'left',
  },
  itemHeaderHelper: {
    fontSize: 14,
    fontWeight: '600',
    color: appColors.headerTextColor,
    textAlign: 'left',
  },
  itemLabelText: {
    fontSize: 14,
    fontWeight: '500',
    color: appColors.headerTextColor,
    width: '96px',
    textAlign: 'right',
    paddingRight: '8px',
  },
  itemText: {
    fontSize: 14,
    fontWeight: '400',
    color: appColors.itemTextColor,
    border: '',
  },
  avatarContainer: {
    margin: 5,
    marginRight: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'lightgrey',
    borderRadius: '50%',
  },
  badgeStyleGreen: {
    '&::after': {
      position: 'absolute',
      backgroundColor: '#44b700',
      color: '#44b700',
      bottom: 0,
      right: 0,
      width: '25%',
      height: '25%',
      borderRadius: '50%',
      animation: '1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  badgeStyleRed: {
    '&::after': {
      position: 'absolute',
      backgroundColor: 'red',
      color: 'red',
      bottom: 0,
      right: 0,
      width: '25%',
      height: '25%',
      borderRadius: '50%',
      // animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  listIconStyle: {
    color: '#C0C0C0',
    fontSize: '24px',
  },
  deleteIconStyle: {
    color: '#C00000',
    fontSize: '24px',
  },
  avatarDefault: {
    color: theme.palette.item.dark,
  },
  itemMainContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '8px',
    marginBottom: '4px',
    border: `1px solid`,
    borderColor: appColors.itemBackColor,
    backgroundColor: 'inherit',
    '&:hover': {
      borderColor: appColors.accentColor,
    },
  },
  itemTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 4,
    cursor: 'pointer',
  },
  itemTextWrapContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    flex: 3,
  },
  itemTextPrimaryContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    flex: 5,
  },
  itemIconContainer: {
    flex: 0.3,
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  itemHorizontalLine: {
    alignSelf: 'center',
    height: 1,
    width: '95%',
    backgroundColor: appColors.headerTextColor,
    marginBottom: 5,
  },
  tooltip: {
    backgroundColor: 'rgb(19,62,96)',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
  addIcon: {
    color: 'white',
    backgroundColor: '#192734', //appColors.accentColor,
    margin: '4px',
    padding: '12px',
    '&:hover': {
      backgroundColor: 'rgb(19, 62, 96)',
    },
    position: 'fixed', //'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  searchContainer: {
    backgroundColor: appColors.itemBackColor,
    padding: '8px',
  },
}))

const UserList = ({ orgs }) => {
  const _isMounted = useRef(false)
  const editIndex = React.useRef(-1)
  const classes = useStyles()
  const [selectedUser, setSelectedUser] = useState({ userName: '', counter: 0 })
  const [userList, setUserList] = useState([])
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [showEditUser, setShowEditUser] = useState(false)
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [showPickOperators, setShowPickOperators] = useState(false)
  const [showPickWells, setShowPickWells] = useState(false)
  const [operatorList, setOperatorList] = useState([])
  const [operatorAccessList, setOperatorAccessList] = useState([])
  const [wellList, setWellList] = useState([])
  const [wellAccessList, setWellAccessList] = useState([])
  const [searchText, setSearchText] = useState('')
  const [orgList, setOrgList] = useState([])

  const [filteredUsers, setFilteredUsers] = useState([])
  const [inverseSort] = useState(false)
  const [sortParameterPri] = useState('userName')
  const [sortParameterSec] = useState('organization')

  const { newCancelToken, cancelPreviousRequest } = useAxiosCancelToken()
  const [{ data }, refreshUsers] = useAxios({
    url: '/admin/users',
    cancelToken: newCancelToken(),
  })

  const { newCancelToken: operatorCancelToken, cancelPreviousRequest: operatorCancelRequest } = useAxiosCancelToken()
  const [{ error: opError }, getOperators] = useAxios({
    url: '/admin/getOperatorList',
    cancelToken: operatorCancelToken(),
    manual: true,
  })

  const { newCancelToken: updateAccessOpCancelToken, cancelPreviousRequest: updateOpAccessCancelRequest } =
    useAxiosCancelToken()
  const [{ error: updateOpAccessError }, updateAccessOperators] = useAxios({
    url: '/admin/updateOperatorAccess',
    cancelToken: updateAccessOpCancelToken(),
    manual: true,
  })

  const { newCancelToken: orgCancelToken, cancelPreviousRequest: orgCancelRequest } = useAxiosCancelToken()
  const [{ data: orgData }] = useAxios({
    url: '/admin/getOrgListByUser',
    cancelToken: orgCancelToken(),
  })

  const { newCancelToken: deleteCancelToken, cancelPreviousRequest: deleteCancelRequest } = useAxiosCancelToken()
  const [{ error }, deleteUser] = useAxios({
    url: '/admin/deleteUser',
    cancelToken: deleteCancelToken(),
    manual: true,
  })

  const { newCancelToken: operatorAccessCancelToken, cancelPreviousRequest: operatorAccessCancelRequest } =
    useAxiosCancelToken()
  const [{ error: operatorAccessError }, getOperatorAccess] = useAxios({
    url: '/admin/getOperatorAccess',
    cancelToken: operatorAccessCancelToken(),
    manual: true,
  })

  const { newCancelToken: wellsCancelToken, cancelPreviousRequest: wellsCancelRequest } = useAxiosCancelToken()
  const [{ error: wellsError }, getWells] = useAxios({
    url: '/admin/getWellList',
    cancelToken: wellsCancelToken(),
    manual: true,
  })

  const { newCancelToken: wellAccessCancelToken, cancelPreviousRequest: wellAccessCancelRequest } =
    useAxiosCancelToken()
  const [{ error: wellAccessError }, getWellAccess] = useAxios({
    url: '/admin/getWellAccess',
    cancelToken: wellAccessCancelToken(),
    manual: true,
  })

  const { newCancelToken: updateAccessWellCancelToken, cancelPreviousRequest: updateWellAccessCancelRequest } =
    useAxiosCancelToken()
  const [{ error: updateWellAccessError }, updateAccessWells] = useAxios({
    url: '/admin/updateWellAccess',
    cancelToken: updateAccessWellCancelToken(),
    manual: true,
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
      cancelPreviousRequest()
      operatorCancelRequest()
      orgCancelRequest()
      deleteCancelRequest()
      operatorAccessCancelRequest()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && data) {
      let sortData = [...data]
      sortData.sort(
        (firstEl, secondEl) =>
          firstEl[sortParameterSec].localeCompare(secondEl[sortParameterSec]) * (inverseSort ? -1 : 1) ||
          firstEl[sortParameterPri].localeCompare(secondEl[sortParameterPri]) * (inverseSort ? -1 : 1),
      )
      const keyedData = sortData.map((item, i) => ({
        key: i.toString(),
        ...item,
      }))
      setUserList(keyedData)
    }
  }, [data, sortParameterPri, sortParameterSec, inverseSort])

  useEffect(() => {
    if (_isMounted.current) {
      const filtered = handleSearch('')
      setFilteredUsers(filtered)
    }
  }, [userList]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      const filtered = handleSearch(searchText)
      setFilteredUsers(filtered)
    }
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && orgData) {
      let newOrgData = []
      orgData.forEach((i) => {
        newOrgData.push({ label: i, value: `${i}` })
      })
      setOrgList(newOrgData)
    }
  }, [orgData])

  useEffect(() => {
    if (error !== undefined && error !== null) {
      if (deleteCancelRequest(error)) return
    }
  }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (opError !== undefined && opError !== null) {
      if (operatorCancelRequest(opError)) return
    }
  }, [opError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (operatorAccessError !== undefined && operatorAccessError !== null) {
      if (operatorAccessCancelRequest(operatorAccessError)) return
    }
  }, [operatorAccessError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (updateOpAccessError !== undefined && updateOpAccessError !== null) {
      if (updateOpAccessCancelRequest(updateOpAccessError)) return
    }
  }, [updateOpAccessError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (wellsError !== undefined && wellsError !== null) {
      if (wellsCancelRequest(wellsError)) return
    }
  }, [wellsError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (wellAccessError !== undefined && wellAccessError !== null) {
      if (wellAccessCancelRequest(wellAccessError)) return
    }
  }, [wellAccessError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (updateWellAccessError !== undefined && updateWellAccessError !== null) {
      if (updateWellAccessCancelRequest(updateWellAccessError)) return
    }
  }, [updateWellAccessError]) // eslint-disable-line react-hooks/exhaustive-deps

  const getCompanyIcon = (orgName) => {
    if (orgs) {
      const org = orgs.find((item) => item.filename === orgName.toLowerCase())
      if (org) {
        let base64ImageString = 'data:image/*;base64,' + org.data
        return base64ImageString
      }
    } else {
      return ''
    }
  }

  const handleSearch = (text) => {
    if (text) {
      const data = _filter(userList, (item) => {
        return searchFunction(item, text, ['organization', 'userName', 'email'])
      })
      return data
    } else {
      return userList
    }
  }

  const refreshOperators = async (userName) => {
    if (editIndex?.current >= 0) {
      const opResponse = await getOperators({
        accessUser: userName,
      })
      if (opResponse?.data) {
        setOperatorList(opResponse?.data)
      }
      const getOpResponse = await getOperatorAccess({
        accessUser: userName,
      })
      if (getOpResponse?.data) {
        setOperatorAccessList(getOpResponse?.data)
      }
    }
  }

  const refreshWells = async (userName) => {
    if (editIndex?.current >= 0) {
      const wellResponse = await getWells({
        accessUser: userName,
      })
      if (wellResponse?.data) {
        // test area...
        // console.log('wells by user: ', wellResponse.data)
        let newList = []
        if (Array.isArray(wellResponse.data)) {
          wellResponse.data.forEach((well) => {
            let opIndex = newList.findIndex((element) => element.header === well.operator)
            if (opIndex >= 0) {
              newList[opIndex].data.push({ id: well.wellName, desc: well.wellName })
            } else {
              newList.push({
                header: well.operator,
                data: [{ id: well.wellName, desc: well.wellName }],
              })
            }
          })
        }

        if (_isMounted.current === true) {
          // console.log('setWellList: ', newList)
          setWellList(newList)
        }
      }
      const getWellResponse = await getWellAccess({
        accessUser: userName,
      })
      if (getWellResponse?.data) {
        setWellAccessList(getWellResponse?.data)
      }
    }
  }

  const onClickOperators = async (item) => {
    setSelectedUser({ userName: item.userName, counter: selectedUser.counter + 1 })
    editIndex.current = parseInt(item?.key)
    await refreshOperators(item?.userName)
    setShowPickOperators(true)
  }

  const onClickWells = async (item) => {
    setSelectedUser({ userName: item.userName, counter: selectedUser.counter + 1 })
    editIndex.current = parseInt(item?.key)
    await refreshWells(item?.userName)
    //setStatus({ show: true, severity: 'info', message: 'Coming soon!' })
    setShowPickWells(true)
  }

  const onClickDelete = (item) => {
    editIndex.current = parseInt(item?.key)
    setConfirm({
      show: true,
      title: 'Delete User',
      text: `Are you sure you want to delete the user ${item?.userName}?`,
      itemIndex: editIndex.current,
    })
  }

  const confirmDelete = async () => {
    if (confirm?.itemIndex > 0) {
      //Stops last user being deleted
      const deleteResponse = await deleteUser({
        authUserName: userList[confirm?.itemIndex].userName,
      })
      if (!deleteResponse.error) {
        // console.log('user deleted: ', userList[confirm.itemIndex].userName)
        setStatus({ show: true, severity: 'success', message: 'User deleted successfully' })
        await refreshUsers()
      } else {
        setStatus({ show: true, severity: 'error', message: 'Delete user failed' })
        // console.log(deleteResponse.error)
      }
    }
  }

  const onClickAddUser = () => {
    editIndex.current = -1
    // console.log('showEditUser Add', { idx: editIndex.current })
    setShowEditUser(true)
  }

  const onClickEditUser = (item) => {
    editIndex.current = parseInt(item.key)
    // console.log('showEditUser Edit', { item, idx: editIndex.current })
    //setSelectedUser({ userName: item.userName, counter: selectedUser.counter + 1 })
    setShowEditUser(true)
    // pass selected index to dialog
  }

  const handleCloseEditUser = async (event, reason) => {
    // console.log('handleCloseEditUser', event, reason)
    if (reason === 'submitted') {
      /* await */ refreshUsers()
    }
    setShowEditUser(false)
  }

  const applyPickOperator = async (selectedOperators) => {
    if (!selectedUser?.userName) return false
    if (selectedUser?.userName === '') return false
    if (!selectedOperators) return false
    if (Array.isArray(selectedOperators) === false) return false

    let strArray = ''
    selectedOperators.forEach((op) => {
      if (strArray !== '') strArray += '|'
      strArray += op
    })

    await updateAccessOperators({
      accessUser: selectedUser.userName,
      operatorList: strArray,
    })
    setShowPickOperators(false)
  }

  const applyPickWells = async (selectedWells) => {
    if (!selectedUser?.userName) return false
    if (selectedUser?.userName === '') return false
    if (!selectedWells) return false
    if (Array.isArray(selectedWells) === false) return false

    let strArray = ''
    selectedWells.forEach((op) => {
      if (strArray !== '') strArray += '|'
      strArray += op
    })

    await updateAccessWells({
      accessUser: selectedUser.userName,
      wellList: strArray,
    })
    setShowPickWells(false)
  }

  const handleClose = (event, reason) => {
    // if (reason === 'clickaway') {
    //   return
    // }
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const renderUserItem = (userItem, index, pickListModalOpen, pickListModalOpen2, setEditIndex) => {
    let loggedIn = '-'
    let createdDate = ''
    let loggedInColor = appColors.itemTextColor
    // console.log(userItem)
    if (userItem) {
      if (userItem.hasOwnProperty('createdAt') === true && userItem.hasOwnProperty('lastLogin') === true) {
        if (userItem.createdAt !== '') {
          let createdAtDate = new Date(Date.parse(userItem.createdAt))
          createdDate = createdAtDate.toLocaleDateString()
        }

        if (userItem.lastLogin !== '') {
          let deltaDays = daysSinceActive(userItem.lastLogin)
          loggedInColor = deltaDays < 14 ? appColors.rotateColor : deltaDays < 30 ? 'orange' : 'tomato'
          loggedIn = getDaysText(deltaDays)
        }
      }
    }

    return (
      <div key={userItem.key} className={classes.itemMainContainer}>
        <div className={classes.itemTextContainer} onClick={() => onClickEditUser(userItem)}>
          <div className={classes.avatarContainer}>
            {userItem.active === false ? (
              <Badge className={classes.badgeStyleRed}>
                <Avatar alt={userItem.userName} src={getCompanyIcon(userItem.organization)} />
              </Badge>
            ) : (
              <Badge className={classes.badgeStyleGreen}>
                <Avatar alt={userItem.userName} src={getCompanyIcon(userItem.organization)} />
              </Badge>
            )}
          </div>
          <div className={classes.itemTextPrimaryContainer}>
            <div className={classes.itemHeaderText}>{userItem.userName}</div>
            <div className={classes.itemHeaderHelper}>{userItem.organization}</div>
          </div>
          <div className={classes.itemTextWrapContainer}>
            <div className={classes.itemTextContainer}>
              <div className={classes.itemLabelText}>Created: </div>
              <div className={classes.itemText}>{createdDate}</div>
            </div>
            <div className={classes.itemTextContainer}>
              <div className={classes.itemLabelText}>Last Login: </div>
              <div className={classes.itemText} style={{ color: loggedInColor }}>
                {loggedIn}
              </div>
            </div>
          </div>
        </div>
        <Tooltip title='Operator Access' placement='bottom' classes={{ tooltip: classes.tooltip }}>
          <IconButton className={classes.itemIconContainer} onClick={() => onClickOperators(userItem)}>
            <Iconify icon='fa-solid:user' className={classes.listIconStyle} />
          </IconButton>
        </Tooltip>
        <Tooltip title='Well Access' placement='bottom' classes={{ tooltip: classes.tooltip }}>
          <IconButton className={classes.itemIconContainer} onClick={() => onClickWells(userItem)}>
            <Iconify icon='el:list' className={classes.listIconStyle} />
          </IconButton>
        </Tooltip>
        <Tooltip title='Delete User' placement='bottom' classes={{ tooltip: classes.tooltip }}>
          <IconButton className={classes.itemIconContainer} onClick={() => onClickDelete(userItem)}>
            <Iconify icon='fa-regular:trash-alt' className={classes.deleteIconStyle} />
          </IconButton>
        </Tooltip>
        {status?.show ? (
          <Snackbar open={status?.show} autoHideDuration={2000} onClose={handleClose}>
            <MuiAlert onClose={handleClose} severity={status.severity} elevation={4} variant='filled'>
              {status.message}
            </MuiAlert>
          </Snackbar>
        ) : null}
      </div>
    )
  }

  // const EditUserDialog = ({ open, onClose, data, useInputData, orgList, roleList, unitList }) => {
  return (
    <React.Fragment>
      <EditUserDialog
        open={showEditUser}
        onClose={handleCloseEditUser}
        orgList={orgList}
        data={editIndex.current > -1 ? userList[editIndex.current] : {}}
        useInputData={editIndex.current > -1}
      />
      <ConfirmDialog
        title={confirm?.title}
        open={confirm?.show}
        setOpen={() => setConfirm({ show: false })}
        onConfirm={() => confirmDelete()}>
        {confirm?.text}
      </ConfirmDialog>
      {showPickOperators ? (
        <SimplePickListDialog
          title='Select Operators'
          sectionHeader='Operator Names'
          open={showPickOperators}
          setOpen={() => setShowPickOperators(false)}
          onApply={applyPickOperator}
          items={operatorList}
          initSelItems={operatorAccessList}
        />
      ) : null}
      {showPickWells ? (
        <SectionPickListDialog
          title='Select Wells'
          open={showPickWells}
          setOpen={() => setShowPickWells(false)}
          onApply={applyPickWells}
          items={wellList}
          initSelItems={wellAccessList}
        />
      ) : null}
      <div className={classes.searchContainer}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onRequestSearch={() => console.log('onRequestSearch')}
          onCancelSearch={() => setSearchText('')}
          style={{
            padding: '1px',
            margin: '1px',
            backgroundColor: '#111',
          }}
        />
      </div>
      <div className={classes.listContainer}>
        <SimpleStyledList listItems={filteredUsers} renderItem={renderUserItem}></SimpleStyledList>
      </div>
      <Tooltip title='add user' placement='left' classes={{ tooltip: classes.tooltip }}>
        <Fab aria-label='add user' className={classes.addIcon} onClick={onClickAddUser}>
          <AddIcon></AddIcon>
        </Fab>
      </Tooltip>
    </React.Fragment>
  )
}

export default UserList
