import React, { useCallback, useEffect, useRef, useState } from 'react'

import { makeStyles } from '@material-ui/styles'
import moment from 'moment'
import 'chartjs-adapter-moment'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'

import { currentWellAtom, edrSchematicDataAtom, edrDataQueryAtom, curWitsAtom, currentPageAtom } from 'atoms'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { useResizeDetector } from 'react-resize-detector'
import AutoSizer from 'react-virtualized-auto-sizer'

import Page from 'components/common/Page'
import { appColors, chartSeriesColors } from 'utils'
import { Tooltip, IconButton } from '@material-ui/core'
import { Icon as Iconify } from '@iconify/react'
import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import useInterval from 'components/common/hooks/useInterval'
import GtkEdrChart from './GtkEdrChart'
import GtkBandCircularGauge from './GtkBandCircularGauge'
import GtkHalfCircularGauge from './GtkHalfCircularGauge'
import GtkWellSchematic from './GtkWellSchematic'
import EdrToolfaceControl from './EdrToolfaceControl'
import TextWidget from './TextWidget'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'

const useStyles = makeStyles((theme) => ({
  container: {
    background: '#2d2d2d',
    paddingTop: '16px',
    paddingBottom: '16px',
    height: 'calc(100vh - 96px)',
    maxHeight: 'calc(100vh - 96px)',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: (props) => props.leftPos,
    width: (props) => `calc(100% - ${props.leftPos}px)`,
  },
  gtkContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '16px',
    height: '100%',
  },
  chartContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 3,
    minHeight: '100px',
    overflow: 'hidden',
    marginTop: '16px',
    border: '1px solid ',
  },
  gaugeContainer: {
    display: 'flex',
    flex: 3,
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#2d2d2d', //'#1d1d1d',
    minWidth: '400px',
    marginLeft: '8px',
    marginRight: '8px',
    marginTop: '16px',
    overflow: 'hidden',
    border: '1px solid ',
  },
  gaugeRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    backgroundColor: '#202020',
    width: '100%',
  },
  textRow: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#202020',
    width: '100%',
    maxHeight: '82px',
  },
  schematicContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#2d2d2d', //'#1d1d1d',
    marginLeft: '8px',
    marginRight: '8px',
    overflow: 'hidden',
    minWidth: '300px',
    marginTop: '16px',
    border: '1px solid ',
  },
  tooltip: {
    backgroundColor: 'rgb(19,62,96)',
    fontSize: '12px',
    fontFamily: 'Roboto',
    zIndex: 2,
  },
  runningIcon: {
    minWidth: 24,
    position: 'fixed',
    top: '106px',
    left: '164px',
    zIndex: 2,
  },
  toolface: {
    flex: 4, //'4 1 auto',
    width: '100%',
    height: '100%',
    minHeight: '225px',
  },
}))

const EdrPage = () => {
  const _isMounted = useRef(false)
  const classes = useStyles()
  const [edrData, setEdrData] = useRecoilState(edrDataQueryAtom)
  const currentWell = useRecoilValue(currentWellAtom)
  const [delay, setDelay] = useState(5000)
  const [isRunning, setRunning] = useState(true) // false) // eslint-disable-line no-unused-vars
  const setCasingData = useSetRecoilState(edrSchematicDataAtom)
  const [curWitsData, setCurWitsData] = useRecoilState(curWitsAtom)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const unitPressure = useUnits(UNITS_FOR.Pressure)
  const unitWeight = useUnits(UNITS_FOR.Weight)

  const chartRef = useRef()
  const onChartResize = useCallback((width, height) => {
    chartRef.current.redraw(width, height)
  }, [])
  const {
    width: cWidth,
    height: cHeight,
    ref: cRef,
  } = useResizeDetector({ onResize: onChartResize, refreshMode: 'debounce', refreshRate: 500 })

  const schemRef = useRef()
  const onSchemResize = useCallback((width, height) => {
    schemRef.current.redraw(width, height)
  }, [])
  const {
    width: sWidth,
    height: sHeight,
    ref: sRef,
  } = useResizeDetector({ onResize: onSchemResize, refreshMode: 'debounce', refreshRate: 500 })

  const { newCancelToken, cancelPreviousRequest: cancelRequest } = useAxiosCancelToken()
  const [{ data, error }, fetchLatest] = useAxios({
    url: '/dataAcq/getData',
    data: {
      wellName: currentWell,
      numRecs: 1000,
    },
    cancelToken: newCancelToken(),
  })

  const { newCancelToken: wellDataCancelToken, cancelPreviousRequest: cancelWellDataRequest } = useAxiosCancelToken()
  const [{ data: wellData, error: wellDataError }, fetchWellCasing] = useAxios({
    url: '/well/getCasing',
    data: {
      wellName: currentWell,
    },
    cancelToken: wellDataCancelToken(),
  })

  const { newCancelToken: curWitsCancelToken, cancelPreviousRequest: cancelCurWitsRequest } = useAxiosCancelToken()
  const [{ error: curWitsError }, fetchCurWits] = useAxios({
    url: '/well/curwits',
    data: {
      wellName: currentWell,
    },
    cancelToken: curWitsCancelToken(),
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.edrPageKey)
    setEdrData(data)

    return () => {
      _isMounted.current = false
      cancelRequest()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error !== undefined && error !== null) {
      if (cancelRequest(error)) return
    }
  }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (wellDataError !== undefined && wellDataError !== null) {
      if (cancelWellDataRequest(wellDataError)) return
    }
  }, [wellDataError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (curWitsError !== undefined && curWitsError !== null) {
      if (cancelCurWitsRequest(curWitsError)) return
    }
  }, [curWitsError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isRunning) {
      setDelay(5000)
    } else {
      setDelay(null)
    }
  }, [isRunning])

  useEffect(() => {
    setEdrData([])
    getWellData(currentWell)
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && wellData) {
      setCasingData(wellData)
    }
  }, [wellData]) // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    let payload = {}
    if (edrData && edrData.length > 0) {
      const lastTimeStamp = moment(edrData[edrData.length - 1].timeStamp)
        .add(1, 'second')
        .toISOString()
      payload = {
        wellName: currentWell,
        dateFrom: lastTimeStamp,
        numRecs: null,
      }
    } else {
      payload = {
        wellName: currentWell,
        numRecs: 1000,
        dateFrom: null,
      }
    }
    const response = await fetchLatest(payload, newCancelToken())
    if (response?.data) {
      setEdrData(response.data)
    }

    payload = {
      wellName: currentWell,
    }

    const witsResponse = await fetchCurWits(payload, curWitsCancelToken())
    if (witsResponse?.data) {
      setCurWitsData(witsResponse.data)
    }
  }

  useInterval(() => {
    getData()
  }, delay)

  const getWellData = async (newWell) => {
    let payload = {
      wellName: currentWell,
    }
    const response = await fetchWellCasing(payload)
    if (response?.data) {
      setCasingData(response.data)
    }
  }

  let curveDefs = [
    {
      curveName: 'torque',
      name: 'Torque',
      depths: [],
      values: [],
      scaleMin: 0,
      scaleMax: 50,
      color: chartSeriesColors[0],
    },
    {
      curveName: 'rop',
      name: 'ROP',
      depths: [],
      values: [],
      scaleMin: 0,
      scaleMax: 3000,
      color: chartSeriesColors[1],
    },
    {
      curveName: 'flow',
      name: 'Flow Rate',
      depths: [],
      values: [],
      scaleMin: 0,
      scaleMax: 1000,
      color: chartSeriesColors[2],
    },
  ]

  let curveDefs1 = [
    {
      curveName: 'hkld',
      name: 'Hookload',
      units: 'klb',
      depths: [],
      values: [],
      scaleMin: 0,
      scaleMax: 250,
      color: chartSeriesColors[0],
    },
    {
      curveName: 'wob',
      name: 'Weight on Bit',
      depths: [],
      values: [],
      scaleMin: 0,
      scaleMax: 50,
      color: chartSeriesColors[1],
    },
    {
      curveName: 'blockPosition',
      name: 'Block Position',
      depths: [],
      values: [],
      scaleMin: 0,
      scaleMax: 100,
      color: chartSeriesColors[2],
    },
  ]

  let curveDefs2 = [
    {
      curveName: 'pressure',
      name: 'Pump Pressure',
      depths: [],
      values: [],
      scaleMin: 0,
      scaleMax: 10000,
      color: chartSeriesColors[0],
    },
    {
      curveName: 'diffPressure',
      name: 'Diff Pressure',
      depths: [],
      values: [],
      scaleMin: 0,
      scaleMax: 1000,
      color: chartSeriesColors[1],
    },
    {
      curveName: 'rpm',
      name: 'RPM',
      depths: [],
      values: [],
      scaleMin: 0,
      scaleMax: 150,
      color: chartSeriesColors[2],
    },
  ]

  const plotDefs = [
    {
      curves: curveDefs,
    },
    {
      curves: curveDefs1,
    },
    {
      curves: curveDefs2,
    },
  ]

  return (
    <Page className={classes.container} maxWidth>
      <div className={classes.gtkContainer}>
        <div className={classes.schematicContainer} id='schematicContainer' ref={sRef}>
          <GtkWellSchematic
            ref={schemRef}
            width={sWidth ? sWidth : document.getElementById('schematicContainer')?.offsetWidth}
            height={sHeight ? sHeight : document.getElementById('schematicContainer')?.offsetHeight}
            id='well-schematic'
          />
        </div>
        <div className={classes.chartContainer} id='widgetContainer' ref={cRef}>
          <GtkEdrChart
            ref={chartRef}
            width={cWidth ? cWidth : document.getElementById('widgetContainer')?.offsetWidth}
            height={cHeight ? cHeight : document.getElementById('widgetContainer')?.offsetHeight}
            plotDef={plotDefs}
          />
        </div>
        <div className={classes.gaugeContainer}>
          <div className={classes.toolface}>
            <AutoSizer>{({ height, width }) => <EdrToolfaceControl height={height} width={width} />}</AutoSizer>
          </div>
          <div className={classes.textRow}>
            <TextWidget
              title={'Hole Depth'}
              value={curWitsData && curWitsData.holeMD && curWitsData.holeMD.length > 0 ? curWitsData.holeMD : '--'}
              unit={curWitsData?.unitsInfo?.Depth}
            />
            <TextWidget
              title={'Bit Depth'}
              value={curWitsData && curWitsData.bitMD && curWitsData.bitMD.length > 0 ? curWitsData.bitMD : '--'}
              unit={curWitsData?.unitsInfo?.Depth}
            />
            <TextWidget title={'Rig State'} value={curWitsData?.rigState} unit={''} />
          </div>
          <div className={classes.gaugeRow}>
            <GtkBandCircularGauge
              id='pump-pressure-gauge'
              curveDef={{
                curveName: 'pressure',
                name: 'PRES',
                units: unitPressure,
                scaleMin: 0,
                scaleMax: 10000,
                color: chartSeriesColors[0],
              }}
            />
            <GtkBandCircularGauge
              id='rpm-gauge'
              curveDef={{
                curveName: 'rpm',
                name: 'RPM',
                units: 'rpm',
                scaleMin: 0,
                scaleMax: 150,
                color: chartSeriesColors[0],
              }}
            />
            <GtkBandCircularGauge
              id='wob-gauge'
              curveDef={{
                curveName: 'wob',
                name: 'WOB',
                units: unitWeight,
                scaleMin: 0,
                scaleMax: 50,
                color: chartSeriesColors[0],
              }}
            />
          </div>
          <div className={classes.gaugeRow}>
            <GtkHalfCircularGauge
              id='rop-gauge'
              width={200}
              height={200}
              curveDef={{
                curveName: 'rop',
                name: 'ROP',
                units: `${useUnits(UNITS_FOR.Depth)}/hr`,
                scaleMin: 0,
                scaleMax: 3000,
                color: chartSeriesColors[0],
              }}
            />
            <GtkHalfCircularGauge
              id='diffp-gauge'
              width={200}
              height={200}
              curveDef={{
                curveName: 'diffPressure',
                name: 'DIFFP',
                units: useUnits(UNITS_FOR.Pressure),
                scaleMin: 0,
                scaleMax: 1000,
                color: chartSeriesColors[0],
              }}
            />
            <GtkHalfCircularGauge
              id='torque-gauge'
              width={200}
              height={200}
              curveDef={{
                curveName: 'torque',
                name: 'Torque',
                units: useUnits(UNITS_FOR.Torque),
                scaleMin: 0,
                scaleMax: 50,
                color: chartSeriesColors[0],
              }}
            />
          </div>
        </div>
      </div>
      {!isRunning ? (
        <Tooltip
          title={`${isRunning ? 'stop Data Acq' : 'resume Data Acq'}`}
          placement='bottom'
          classes={{ tooltip: classes.tooltip }}>
          <IconButton className={classes.runningIcon} onClick={() => setRunning(!isRunning)}>
            <Iconify
              icon='raphael:refresh'
              style={{ color: isRunning ? appColors.accentColor : appColors.headerTextColor, fontSize: '24px' }}
            />
          </IconButton>
        </Tooltip>
      ) : null}
    </Page>
  )
}

export default EdrPage
