import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { Plot } from '@int/geotoolkit/plot/Plot'
// import { WellBoreData } from '@int/geotoolkit/schematics/data/WellBoreData'
import { SchematicsWidget } from '@int/geotoolkit/schematics/widgets/SchematicsWidget'
import { useRecoilValue } from 'recoil'
import { edrSchematicDataAtom } from 'atoms'
import { appColors } from 'utils'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  geotoolkitTooltipContainer: {
    padding: '2px 12px 3px 7px',
    marginLeft: '5px',
    background: appColors.modalBackColor, // '#f6f6f6',
    border: `solid 1px ${appColors.modalBackColor}`,
    opacity: '0.8',
    textAlign: 'left',
    color: appColors.headerTextColor,
  },
}))

// const wbData = [
//   {
//     'name': 'casing',
//     'data': {
//       'description': 'Drive Pipe',
//       'geometry': {
//         'depth': {
//           'from': 0,
//           'to': 341,
//         },
//         'diameter': {
//           'outer': 26,
//           'inner': 25,
//         },
//       },
//     },
//   },
// ]

const GtkWellSchematic = forwardRef(({ id, width, height }, ref) => {
  const classes = useStyles()
  const _isMounted = useRef(false)
  const canvasRef = useRef(null)
  const [schematic, setSchematic] = useState(null)
  const [plot, setPlot] = useState(null)
  const casingData = useRecoilValue(edrSchematicDataAtom)
  const [wellboreData, setWellboreData] = useState([])

  useEffect(() => {
    _isMounted.current = true
    const newSchematic = createSchematic(options)
    setSchematic(newSchematic)
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      setPlot(
        new Plot({
          'canvasElement': canvasRef.current,
          'root': schematic,
        }),
      )
    }
  }, [schematic])

  useEffect(() => {
    // console.log('casingdata ue', { casingData, schematic, flag: Array.isArray(casingData) })
    if (_isMounted.current && schematic && canvasRef.current) {
      if (casingData) {
        let wellboreData = []
        let lastOuterDiam = 0

        //casingData.forEach((casingSection) => {
        for (let i = 0; i < casingData.length; i++) {
          // check for cement section before first casing section
          if (lastOuterDiam === 0) {
            let cementThickness = casingData[i].HoleSize - casingData[i].OD
            if (cementThickness > 0.5) {
              wellboreData.push({
                'name': 'cement',
                'data': {
                  'geometry': {
                    'depth': {
                      'from': 0,
                      'to': casingData[i].MD,
                    },
                    'diameter': {
                      'outer': casingData[i].HoleSize,
                      'inner': casingData[i].OD,
                    },
                  },
                },
              })
            }
            lastOuterDiam = casingData[i].OD
          }
          // check for cement between this section and prior section
          wellboreData.push({
            'name': 'casing',
            'data': {
              'description': casingData[i].Type,
              'geometry': {
                'depth': {
                  'from': 0,
                  'to': casingData[i].MD,
                },
                'diameter': {
                  'outer': casingData[i].OD,
                  'inner': casingData[i].OD - 0.5,
                },
              },
            },
          })
        }
        setWellboreData(wellboreData)
      }
    }
  }, [casingData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && canvasRef.current) {
      if (plot) {
        plot.dispose()
      }
      if (schematic) {
        schematic.dispose()
      }
      let newOptions = options
      newOptions.data = wellboreData
      const newSchematic = createSchematic(newOptions)
      setSchematic(newSchematic)
    }
  }, [wellboreData]) // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, (width, height) => ({
    redraw(width, height) {
      redrawPlot(width, height)
    },
  }))

  const redrawPlot = (width, height) => {
    plot.setSize(width, height)
    // plot.update()
  }

  useEffect(() => {
    if (_isMounted.current && plot) {
      plot.update()
    }
  })

  const options = {
    'autoSize': true,
    'autoUpdate': true,
    'north': {
      'title': {
        'text': 'Well Schematic',
      },
    },
    'gap': 20,
    'data': casingData,
    'fillstyle': appColors.itemBackColor,
    'linestyle': appColors.itemBackColor,
    'textstyle': {
      'color': appColors.itemTextColor,
    },
    'wellborenode': {
      // 'fillstyle': 'white', // appColors.modalBackColor,
      // 'linestyle': appColors.modalBackColor,
      'renderinghints': {
        // (A) - "Object"-style rendering hints:
        'perforation': {
          'height': 15,
          'cutoff': true,
          'fillstyle': 'rgb(96, 96, 96)',
        },
        'casing': {
          //   'inner': {
          //     'fillstyle': appColors.itemTextColor, // null,
          //   },
          'outer': {
            'fillstyle': 'black',
            'linestyle': 'black',
          },
          'shoe': {
            'fillstyle': 'black',
          },
        },
        // // (B) - Using "StylesRenderingHints" (there: "gray-scale" visualization):
        // '*': new StylesRenderingHints({
        //   'getfillstyle': myGetGrayScaleFillStyle,
        //   'getlinestyle': myGetGrayScaleLineStyle,
        // }),
      },
    },
    'tooltip': {
      'divelement': document.getElementById('tooltip-container'),
    },
  }

  const createSchematic = (options) => {
    return new SchematicsWidget(options)
  }

  return (
    <React.Fragment>
      <canvas
        id={id}
        className='time-canvas'
        ref={canvasRef}
        width={width}
        height={height}
        style={{ width: width, height: height }}></canvas>
      <div id='tooltip-container' className={classes.geotoolkitTooltipContainer}/>
    </React.Fragment>
  )
})

export default GtkWellSchematic
