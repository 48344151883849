import React, { useEffect, useRef, useState } from 'react'

import { ListItem } from '@material-ui/core'
import { Checkbox } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import { makeStyles } from '@material-ui/styles'
import { FixedSizeList } from 'react-window'
import SearchBar from 'material-ui-search-bar'
import { searchFunction } from './searchFunctions'
import AutoSizer from 'react-virtualized-auto-sizer'
import { filter as _filter } from 'lodash'
import { Skeleton } from '@material-ui/lab'

import { appColors } from 'utils'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '600px',
    backgroundColor: theme.palette.itemBackground,
  },
  headerText: {
    fontSize: 16,
    fontWeight: '800',
    color: appColors.borderColor,
    backgroundColor: appColors.itemTextColor,
    padding: '4px 8px 4px 8px',
  },
  listContainer: {
    display: 'flex',
    overflowY: 'hidden',
    overflowX: 'hidden',
    height: '50vh',
    width: 'calc(100% - 4px)',
    borderRadius: '4px',
    border: '1px solid #000',
  },
  fixedListContainer: {
    display: 'flex',
    borderRadius: '4px',
    backgroundColor: theme.palette.itemBackground,
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#136cb9', //'darkgrey', //'rgba(0,0,0,.1)',
      outline: '1px solid #1679d0',
    },
    overflow: 'auto',
  },
  itemMainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '4px',
    borderRadius: 5,
    marginLeft: '8px',
    marginRight: '8px',
    background: 'linear-gradient(to bottom, #2d2d2d 0%, #1c1c1c 100%)',
    marginBottom: '1px',
    width: 'calc(100% - 20px)', // 8px for marginLeft, 2px for left & right border
    border: `1px solid`,
    borderColor: appColors.itemBackColor,
    backgroundColor: appColors.itemBackColor,
    '&:hover': {
      borderColor: appColors.accentColor,
    },
  },
  itemText: {
    fontSize: 18,
    color: appColors.itemTextColor,
    border: '',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  checkBoxContainer: {
    marginLeft: 'auto',
    marginRight: '10px',
  },
  searchContainer: {
    backgroundColor: appColors.itemBackColor,
    padding: '8px',
    marginBottom: '5px',
  },
}))

const PickListVirtualDialog = (props) => {
  const { title, items, open, setOpen, onApply, initSelItems, singleItemSelect, showSearch } = props
  const _isMounted = useRef(false)
  const classes = useStyles()
  const [selectedItems, setSelectedItems] = useState(Array.isArray(initSelItems) === true ? initSelItems : [])
  const [searchText, setSearchText] = useState('')
  const [filteredList, setFilteredList] = useState([])

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      const filtered = handleSearch(searchText)
      setFilteredList(filtered)
    }
  }, [searchText, items]) // eslint-disable-line react-hooks/exhaustive-deps

  const onItemSelect = (event, id) => {
    let newSelectedItems = [...selectedItems]

    let singleSelect = singleItemSelect
    if (singleItemSelect === null || singleItemSelect === undefined) singleSelect = false

    if (getChecked(id) === false) {
      newSelectedItems.push(id)
      if (_isMounted.current === true && singleSelect === false) setSelectedItems(newSelectedItems)
      if (_isMounted.current === true && singleSelect === true) setSelectedItems([id])
    } else {
      if (Array.isArray(newSelectedItems) === true) {
        const index = newSelectedItems.indexOf(id)
        if (index > -1) newSelectedItems.splice(index, 1)
      } else {
        newSelectedItems = []
      }

      if (_isMounted.current === true && !singleSelect === true) setSelectedItems(newSelectedItems)
      if (_isMounted.current === true && singleSelect === true) setSelectedItems([id])
    }
  }

  const getChecked = (id) => {
    if (!selectedItems) return false
    if (Array.isArray(selectedItems) === false) return false
    if (selectedItems.includes(id) === true) return true

    return false
  }

  const handleSearch = (text) => {
    if (text && typeof text === 'string' && text !== '') {
      const filterList = _filter(items, (item) => {
        return searchFunction(item, text, ['desc'])
      })
      return filterList
    } else {
      return items
    }
  }

  const renderVirtualRow = (props) => {
    const { index, style } = props
    const item = filteredList[index]

    return (
      <ListItem style={style} disableGutters={true}>
        <div className={classes.itemMainContainer}>
          <div className={classes.itemText}>{item.desc}</div>
          <div className={classes.checkBoxContainer}>
            <Checkbox
              edge='end'
              onChange={(event) => onItemSelect(event, item.id)}
              checked={getChecked(item.id)}
              inputProps={{ 'aria-labelledby': `${index}` }}
              checkedIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'lime' }} />}
              indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
              icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
            />
          </div>
        </div>
      </ListItem>
    )
  }

  return (
    <Dialog classes={{ paper: classes.paper }} open={open} onClose={() => setOpen(false)}>
      <DialogTitle id='pick-list-dialog'>{title}</DialogTitle>
      <DialogContent style={{ paddingTop: '0px' }}>
        {showSearch === true ? (
          <div className={classes.searchContainer}>
            <SearchBar
              value={searchText}
              onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
              onCancelSearch={() => setSearchText('')}
              style={{
                padding: '1px',
                margin: '1px',
                backgroundColor: 'rgb(17, 17, 17)',
              }}
            />
          </div>
        ) : null}
        {items.length > 0 ? (
          <div className={classes.listContainer}>
            <AutoSizer>
              {({ height, width }) => (
                <FixedSizeList
                  className={classes.fixedListContainer}
                  height={height}
                  width={width}
                  itemCount={filteredList.length}
                  itemSize={50}>
                  {renderVirtualRow}
                </FixedSizeList>
              )}
            </AutoSizer>
          </div>
        ) : null}
        {items.length === 0 ? <Skeleton variant='rect' width={'100%'} height={'50vh'} animation='wave' /> : null}
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => setOpen(false)} color='secondary'>
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            setOpen(false)
            onApply(selectedItems)
          }}
          color='primary'>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PickListVirtualDialog
