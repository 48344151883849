import React, { useEffect, useRef, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

import { makeStyles } from '@material-ui/styles'
import * as yup from 'yup'
import { Field, Form, Formik } from 'formik'
import { Icon as Iconify } from '@iconify/react'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'

import { appColors } from '../../../utils'
import useAxios from '../../common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import { TextField } from 'components/common/formikMUI/TextField'
import { CheckboxWithLabel } from 'components/common/formikMUI/CheckboxWithLabel'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '600px',
    backgroundColor: appColors.modalBackColor,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
  },
  formItem: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'left',
    width: '95%',
    paddingLeft: '8px',
    margin: '5px',
    border: '1px solid',
    borderWidth: '1px',
    overflow: 'hidden',
    borderRadius: 4,
    borderColor: appColors.borderColor,
    backgroundColor: appColors.itemBackColor,
    color: appColors.itemTextColor,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: appColors.itemBackColor,
      borderColor: appColors.accentColor,
    },
    // '&$focused': {
    //   backgroundColor: appColors.itemBackColor,
    //   borderColor: appColors.accentColor,
    // },
    // for select components:
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderColor: appColors.accentColor,
      borderWidth: '1px',
    },
  },
  iconStyle: {
    color: appColors.headerTextColor,
    height: '32px',
  },
  pickerSelect: {
    margin: 5,
    fontSize: 16,
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderWidth: 1,
    height: '32px',
    //    width: '80%',
    color: appColors.itemTextColor,
    borderColor: appColors.borderColor,
    backgroundColor: appColors.itemBackColor,
    borderRadius: 5,
    paddingRight: 10, // to ensure the text is never behind the icon
    '&:before': {
      backgroundColor: appColors.itemBackColor,
      borderColor: appColors.borderColor,
    },
  },
}))

const EditOrgDialog = ({ open, onClose, data, useInputData, orgList }, ...Props) => {
  const _isMounted = useRef(false)
  const classes = useStyles()
  const [alert, setAlert] = useState({ showAlert: false, alertSeverity: '', alertContent: '' })

  const { newCancelToken: createCancelToken, cancelPreviousRequest: cancelCreateOrgReq } = useAxiosCancelToken()
  const [{ error: errorCreate }, createOrg] = useAxios({
    url: '/admin/createOrg',
    manual: true,
    cancelToken: createCancelToken(),
  })

  const { newCancelToken: updateCancelToken, cancelPreviousRequest: cancelUpdateOrgReq } = useAxiosCancelToken()
  const [{ error: errorUpdate }, updateOrg] = useAxios({
    url: '/admin/updateOrg',
    manual: true,
    cancelToken: updateCancelToken(),
  })

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
      cancelCreateOrgReq()
      cancelUpdateOrgReq()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorCreate !== undefined && errorCreate !== null) {
      if (cancelCreateOrgReq(errorCreate)) return
    }
  }, [errorCreate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorUpdate !== undefined && errorUpdate !== null) {
      if (cancelUpdateOrgReq(errorUpdate)) return
    }
  }, [errorUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

  const formValidationSchema = yup.object({
    organization: yup.string().required(),
    databaseAddress: yup.string().required(),
    databasePort: yup.string().matches(/^\d+$/, 'Only numeric values allowed'),
    databaseName: yup.string().required(),
    databaseUser: yup.string().required(),
    databasePass: yup.string().required().min(8, 'Password must be at least 8 characters long'),
    icdsPort: yup.string().matches(/^\d+$/, 'Only numeric values allowed'),
  })

  let formData = [
    {
      tag: 'organization',
      value: useInputData === true ? data.organization : '',
      text: 'Organization name',
      inputType: 'text',
      icon: 'organization',
      iconFamily: 'Octicons',
    },
    {
      tag: 'databaseAddress',
      value: useInputData === true ? data.databaseAddress : '',
      text: 'Ip Address / DNS',
      inputType: 'text',
      icon: 'ip-network',
      iconFamily: 'MaterialCommunityIcons',
    },
    {
      tag: 'databasePort',
      value: useInputData === true ? data.databasePort : '',
      text: 'Database port (1433)',
      inputType: 'text',
      icon: 'git-network',
      iconFamily: 'Ionicons',
      keyboardtype: 'decimal-pad',
    },
    {
      tag: 'databaseName',
      value: useInputData === true ? data.databaseName : '',
      text: 'Database name',
      inputType: 'text',
      icon: 'database',
      iconFamily: 'Entypo',
    },
    {
      tag: 'databaseUser',
      value: useInputData === true ? data.databaseUser : '',
      text: 'User name',
      inputType: 'text',
      icon: 'user',
      iconFamily: 'Entypo',
    },
    {
      tag: 'databasePass',
      value: useInputData === true ? data.databasePass : '',
      text: 'Password',
      inputType: 'text',
      icon: 'security',
      iconFamily: 'MaterialCommunityIcons',
    },
    {
      tag: 'icdsIp',
      value: useInputData === true ? (data.icdsIp ? data.icdsIp : '') : '',
      text: 'ICDS Ip/Dns',
      inputType: 'text',
      icon: 'git-network',
      iconFamily: 'Ionicons',
    },
    {
      tag: 'icdsPort',
      value: useInputData === true ? (data.icdsPort ? data.icdsPort : '') : '',
      text: 'ICDS Port (42000)',
      inputType: 'text',
      icon: 'git-network',
      iconFamily: 'Ionicons',
      keyboardtype: 'decimal-pad',
    },
    {
      tag: 'useIcds',
      value: useInputData === true ? data?.useIcds : true,
      text: 'Use ICDS',
      inputType: 'boolean',
    },
    {
      tag: 'active',
      value: useInputData === true ? data.active : true,
      text: 'Active',
      inputType: 'boolean',
    },
    {
      tag: 'key',
      value: useInputData === true ? data.key : 0,
      inputType: '-',
    },
  ]

  const createInitialValues = (data) => {
    if (!data) return {}
    if (Array.isArray(data) === false) return {}
    if (data.length <= 0) return {}

    // console.log('cIV: rawInitalData ', data)
    const reducedData = data.reduce((output, item) => {
      output[item.tag] = item.value
      return output
    }, {})
    // console.log('cIV: reducedInitialData ', reducedData)
    return reducedData
  }

  const submitFunction = async (isCreate, data, formikActions, onClose) => {
    // console.log('EditRoleDlg submit function', { isCreate, data })
    if (!data) return false

    let saveResponse

    if (isCreate === true) {
      saveResponse = await createOrg({
        ...data,
      })
    } else {
      saveResponse = await updateOrg({
        ...data,
      })
    }

    // console.log('saveOrg response: ', saveResponse)
    if (saveResponse.error) {
      setAlert({ showAlert: true, alertSeverity: 'error', alertContent: 'Create organization failed' })
      formikActions?.setSubmitting(false)
      return false
    } else {
      setAlert({ showAlert: true, alertSeverity: 'success', alertContent: 'Organization created' })
    }
    // console.log('role created')

    // onClose can be used to trigger parent refresh, so
    // don't signal until this function is complete
    formikActions?.setSubmitting(false)
    onClose(null, 'submitted')
    return true
  }

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <Snackbar open={alert?.showAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <MuiAlert
          variant='filled'
          severity={alert.alertSeverity}
          onClose={() => setAlert({ showAlert: false, alertSeverity: 'info', alertContent: '' })}>
          {alert.alertContent}
        </MuiAlert>
      </Snackbar>
      <DialogTitle>{useInputData ? 'Edit ' : 'Add '}Organization</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={createInitialValues(formData)}
          validationSchema={formValidationSchema}
          onSubmit={(values, actions) => {
            // console.log('formik submit, values= ', values)
            formData.forEach((input) => {
              if (input.inputType === 'dropdown') {
                if (input.useLabel === true) {
                  let index = input.dropDownValues.findIndex((element) => element.value === values[input.tag])
                  if (index >= 0) values[input.tag] = input.dropDownValues[index].label
                }
              }
            })
            submitFunction(!useInputData, values, actions, onClose)
          }}>
          {({ values, submitForm, isSubmitting, touched, errors }) => (
            <Form className={classes.formContainer}>
              <InputLabel id='org-name-label-id'>Organization name</InputLabel>
              <Field
                component={TextField}
                name={'organization'}
                type={'text'}
                id={'organization'}
                placeholder={'Organization name'}
                InputProps={{
                  startAdornment: <Iconify icon={'eva:people-fill'} className={classes.iconStyle} />,
                }}
              />
              {errors.organization && touched.organization && <FormHelperText>{errors.organization}</FormHelperText>}

              <InputLabel id='org-db-addr-label-id'>Database address</InputLabel>
              <Field
                component={TextField}
                name={'databaseAddress'}
                type='text'
                placeholder={'Database Address (IP/URL)'}
                InputProps={{
                  startAdornment: <Iconify icon={'mdi:ip-network'} className={classes.iconStyle} />,
                }}
              />
              {errors.databaseAddress && touched.databaseAddress && (
                <FormHelperText>{errors.databaseAddress}</FormHelperText>
              )}

              <InputLabel id='org-db-port-label-id'>Database port</InputLabel>
              <Field
                component={TextField}
                name={'databasePort'}
                type='text'
                placeholder={'Database port (1433)'}
                InputProps={{
                  startAdornment: <Iconify icon={'ion:git-network'} size={48} className={classes.iconStyle} />,
                }}
              />
              {errors.databasePort && touched.databasePort && <FormHelperText>{errors.databasePort}</FormHelperText>}

              <InputLabel id='org-db-name-label-id'>Database name</InputLabel>
              <Field
                component={TextField}
                name={'databaseName'}
                type='text'
                placeholder={'Database name'}
                InputProps={{
                  startAdornment: <Iconify icon={'fa-solid:database'} size={48} className={classes.iconStyle} />,
                }}
              />
              {errors.databaseName && touched.databaseName && <FormHelperText>{errors.databaseName}</FormHelperText>}

              <InputLabel id='org-db-user-label-id'>Database user</InputLabel>
              <Field
                component={TextField}
                name={'databaseUser'}
                type='text'
                placeholder={'Database user'}
                InputProps={{
                  startAdornment: <Iconify icon={'el:user'} size={48} className={classes.iconStyle} />,
                }}
              />
              {errors.databaseUser && touched.databaseUser && <FormHelperText>{errors.databaseUser}</FormHelperText>}

              <InputLabel id='org-db-pass-label-id'>Database password</InputLabel>
              <Field
                component={TextField}
                name={'databasePass'}
                type='text'
                placeholder={'Database password'}
                InputProps={{
                  startAdornment: <Iconify icon={'ic:baseline-security'} size={48} className={classes.iconStyle} />,
                }}
              />
              {errors.databasePass && touched.databasePass && <FormHelperText>{errors.databasePass}</FormHelperText>}

              <InputLabel id='org-icds-addr-label-id'>ICDS IP/URL</InputLabel>
              <Field
                component={TextField}
                name={'icdsIp'}
                type='text'
                placeholder={'ICDS IP/URL'}
                InputProps={{
                  startAdornment: <Iconify icon={'ion:git-network'} size={48} className={classes.iconStyle} />,
                }}
              />
              {errors.icdsIp && touched.icdsIp && <FormHelperText>{errors.icdsIp}</FormHelperText>}

              <InputLabel id='org-icds-port-label-id'>ICDS port</InputLabel>
              <Field
                component={TextField}
                name={'icdsPort'}
                type='text'
                placeholder={'ICDS port (42000)'}
                InputProps={{
                  startAdornment: <Iconify icon={'ion:git-network'} size={48} className={classes.iconStyle} />,
                }}
              />
              {errors.icdsPort && touched.icdsPort && <FormHelperText>{errors.icdsPort}</FormHelperText>}

              <InputLabel id='org-use-icds-label-id'>Use ICDS</InputLabel>
              <div className={classes.formItem}>
                <Field
                  component={CheckboxWithLabel}
                  name={'useIcds'}
                  type='checkbox'
                  Label={{ label: 'Use ICDS', labelPlacement: 'end' }}
                  checkedIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'lime' }} />}
                  indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                  icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                  style={{ color: appColors.itemTextColor }}
                />
              </div>
              <InputLabel id='org-active-label-id'>Is active</InputLabel>
              <div className={classes.formItem}>
                <Field
                  component={CheckboxWithLabel}
                  name={'active'}
                  type='checkbox'
                  Label={{ label: 'Active', labelPlacement: 'end' }}
                  checkedIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'lime' }} />}
                  indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                  icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                />
              </div>
              <DialogActions>
                <Button variant='outlined' onClick={() => onClose(null, 'cancelled')} color='secondary'>
                  Cancel
                </Button>
                <Button variant='contained' color='primary' type='submit'>
                  Submit
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default EditOrgDialog
