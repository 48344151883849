import React from 'react'
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api'
import { Skeleton } from '@material-ui/lab'

const containerStyle = {
  width: 'calc(100% - 16px)',
  height: '100%',
  paddingRight: '8px',
}

const WellMap = ({ latitude, longitude }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLEMAP_API_KEY,
  })
  const position = {
    lat: latitude,
    lng: longitude,
  }

  if (loadError) {
    // TODO: Better error handling
    console.error(loadError)
  }

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={position}
          zoom={10}
          options={{ streetViewControl: false, gestureHandling: 'greedy', disableDefaultUI: true }}>
          <Marker position={position} />
        </GoogleMap>
      ) : (
        <Skeleton variant='rect' width={200} height={200} />
      )}
    </>
  )
}

export default React.memo(WellMap)
