import React from 'react'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import AddIcon from '@material-ui/icons/Add'
import PrintIcon from '@material-ui/icons/Print'
import RefreshIcon from '@material-ui/icons/Refresh'
import EditIcon from '@material-ui/icons/Edit'
import { userUserRoleAtom } from 'atoms'
import { checkPermission } from '../userPermissions'
import { useRecoilValue } from 'recoil'

export default function MenuButton({
  actions = [
    { icon: <AddIcon />, name: 'Add', onClick: () => {} },
    { icon: <RefreshIcon />, name: 'Refresh', onClick: () => {} },
    { icon: <PrintIcon />, name: 'Print', onClick: () => {} },
  ],
}) {
  const [open, setOpen] = React.useState(false)
  const userRole = useRecoilValue(userUserRoleAtom)

  return (
    <SpeedDial
      hidden={false}
      ariaLabel='open menu'
      icon={<EditIcon style={{ color: '#ffffff' }} />}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}>
      {actions.map((action) => {
        if (!open) return null
        if (!checkPermission('canCreate', userRole.roleAttributes?.permissions) && action.name === 'Add') return null

        return (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => action.onClick && action.onClick()}
          />
        )
      })}
    </SpeedDial>
  )
}
