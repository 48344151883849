import React from 'react'
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api'
import { getCompanyMarker } from '../../../utils/companyIcons'
import WellSeekerInfoBox from './WellSeekerInfoBox'
import { Skeleton } from '@material-ui/lab'

const containerStyle = {
  width: '100%',
  height: '100%',
}

const WellSeekersMap = ({ wellSeekerUsers, latitude, longitude }) => {
  const [infoBoxData, setInfoBoxData] = React.useState(false)
  const [selectedUser, setSelectedUser] = React.useState(null)
  const [position, setPosition] = React.useState({
    lat: 31,
    lng: -100,
  })

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLEMAP_API_KEY,
  })

  if (loadError) {
    // TODO: Better error handling
    console.error(loadError)
  }

  const renderMapItem = (item, i, clusterer) => {
    const marker = getCompanyMarker(item.companyID)
    const image = {
      url: marker.default,
      //scale: .005
    }

    const handleClick = (user) => {
      const setUser = user === selectedUser ? null : user
      setPosition({ lat: user.lat, lng: user.lon })
      setSelectedUser(setUser)
      setInfoBoxData(true)
    }

    return (
      <Marker
        key={i}
        position={{ lat: item.lat, lng: item.lon }}
        icon={image}
        title={item.email}
        info={item.companyID}
        onClick={() => {
          handleClick(item)
        }}
        // clusterer={clusterer}
      >
        {selectedUser && selectedUser === item && (
          <WellSeekerInfoBox
            well={{
              actualWell: selectedUser.ipAddress,
              latitude: selectedUser.lat,
              longitude: selectedUser.lon,
              email: selectedUser.email,
              companyID: selectedUser.companyID,
              ip: selectedUser.ipAddress,
              computerCode: selectedUser.computerCode,
              computerName: selectedUser.computerName,
              org: selectedUser.org,
            }}
            showInfoBox={infoBoxData}
          />
        )}
      </Marker>
    )
  }

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={position}
          zoom={3}
          options={{
            streetViewControl: false,
            fullscreenControl: false,
            zoomControl: false,
            gestureHandling: 'greedy',
            mapTypeControlOptions: {
              // the comments below are required because 'google' exists,
              // but eslint can't see it:
              // eslint-disable-next-line no-undef
              style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
              // eslint-disable-next-line no-undef
              position: google.maps.ControlPosition.TOP_CENTER,
            },
          }}>
          {/* <MarkerClusterer averageCenter> */}
          {wellSeekerUsers ? wellSeekerUsers.map((item, i) => renderMapItem(item, i)) : null}
          {/* {(clusterer) => _map(wellSeekerUsers, (item, i) => renderMapItem(item, i, clusterer))} */}
          {/* </MarkerClusterer> */}
        </GoogleMap>
      ) : (
        <Skeleton variant='rect' width={400} height={400} />
      )}
    </>
  )
}

export default React.memo(WellSeekersMap)
