import React, { useEffect } from 'react'
import _map from 'lodash/map'
import { Container, createStyles, makeStyles, Switch } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { useRecoilState } from 'recoil'

import {
  wellsMapRigSelectorAtom,
  wellsMapLayersSelectorAtom,
  STATES,
  COUNTIES,
  SHALE_PLAYS,
  CONVENTIONAL_PLAYS,
} from '../WellsMap/atoms/WellsMapAtoms'

const WellStatuses = ['Active', 'Upcoming', 'Standby']

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingVertical: '16px',
      paddingBottom: '8px',
      paddingLeft: '8px',
      borderTop: '1px solid gray',
      borderBottom: '1px solid gray',
    },
    cardtitle: {
      font: '1em roboto',
      fontWeight: 'bold',
      paddingTop: '8px',
      paddingBottom: '8px',
    },
    text: {
      font: '0.8em roboto',
      paddingTop: '8px',
      paddingBottom: '8px',
    },
    colContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    title: {
      display: 'flex',
      fontSize: '14px',
      marginTop: '3px',
      fontWeight: 'bold',
      justifyContent: 'left',
    },
    subTitle: {
      fontSize: '14px',
      marginTop: '3px',
      marginLeft: '10px',
    },
    mapPrefsRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingVertical: 12,
      paddingHorizontal: 16,
    },
    listItem: {
      padding: '0px 8px 0px 16px',
    },
    listItemText: {
      fontSize: '14px',
    },
  }),
)

const MapPrefs = () => {
  const classes = useStyles()
  const [selectedLayers, setSelectedLayers] = useRecoilState(wellsMapLayersSelectorAtom)
  const [checkedStatus, updateCheckedStatus] = useRecoilState(wellsMapRigSelectorAtom)

  useEffect(() => {
    if (!selectedLayers) return null
    if (!checkedStatus) return null
  }, [selectedLayers, checkedStatus])

  const toggleCheckedRigStatus = (status) => () => {
    const currentStatus = Boolean(checkedStatus[status])
    const newStatus = {
      ...checkedStatus,
      [status]: !currentStatus,
    }
    updateCheckedStatus(newStatus)
  }

  const toggleCheckedLayer = (layer) => () => {
    const currentLayer = Boolean(selectedLayers[layer])
    const newLayer = {
      ...selectedLayers,
      [layer]: !currentLayer,
    }
    setSelectedLayers(newLayer)
  }

  return (
    <React.Fragment>
      <Container className={classes.root}>
        <div className={classes.cardtitle}>Map Preferences</div>
        <div>
          <div className={classes.title}>Layers</div>
          <div className={classes.mapPrefsRow}>
            <div className={classes.subTitle}>Shale Play Layer</div>
            <Switch
              color='primary'
              checked={Boolean(selectedLayers[SHALE_PLAYS])}
              onClick={toggleCheckedLayer(SHALE_PLAYS)}></Switch>
          </div>
          <div className={classes.mapPrefsRow}>
            <div className={classes.subTitle}>Conv Play Layer</div>
            <Switch
              color='primary'
              checked={Boolean(selectedLayers[CONVENTIONAL_PLAYS])}
              onClick={toggleCheckedLayer(CONVENTIONAL_PLAYS)}></Switch>
          </div>
          <div className={classes.mapPrefsRow}>
            <div className={classes.subTitle}>State Layer</div>
            <Switch color='primary' checked={Boolean(selectedLayers[STATES])} onClick={toggleCheckedLayer(STATES)}></Switch>
          </div>
          <div className={classes.mapPrefsRow}>
            <div className={classes.subTitle}>County Layer</div>
            <Switch color='primary' checked={Boolean(selectedLayers[COUNTIES])} onClick={toggleCheckedLayer(COUNTIES)}></Switch>
          </div>
          <div style={{ paddingTop: '8px' }}>
            <div className={classes.title}>Well Status</div>
            <List>
              {_map(WellStatuses, (status) => (
                <ListItem
                  key={status}
                  button
                  onClick={toggleCheckedRigStatus(status)}
                  classes={{ root: classes.listItem }}>
                  <ListItemText classes={{ primary: classes.listItemText }}>{status}</ListItemText>
                  <Switch checked={Boolean(checkedStatus[status])} color='primary' />
                </ListItem>
              ))}
            </List>
          </div>
        </div>
      </Container>
    </React.Fragment>
  )
}

export default MapPrefs
