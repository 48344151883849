import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import { Icon as Iconify } from '@iconify/react'
import * as XLSX from 'xlsx'

import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import Page from '../common/Page'
import Carousel from 'react-material-ui-carousel'
import LogisticsList from './LogisticsList'
import SearchBar from 'material-ui-search-bar'
import { searchFunction } from 'components/common/searchFunctions'
import { appColors } from 'utils'
import { getCompTypeTextFromEnum } from 'components/common/drillStringFunctions'
import LogisticsMap from './LogisticsMap'
import LogisticsChart from './LogisticsChart'
import { round } from 'utils/numberFunctions'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { useSetRecoilState } from 'recoil'
import { currentPageAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'

const useStyles = makeStyles((theme) => ({
  carouselPane: {
    // height: 'calc(30vh + 36px)',
    height: 'calc(calc(100vh - 182px) * 1.00)',
  },
  carouselItemContainer: {
    width: '100%',
    height: 'calc(calc(100vh - 182px) * 1.00 - 32px)',
  },
  listContainer: {
    display: 'flex',
    // flexDirection: 'column',
    // flex: 1,
    overflow: 'hidden',
    height: 'calc(calc(100vh - 182px) * 1.00)',
    maxHeight: 'calc(calc(100vh - 182px) * 1.00)',
    borderRadius: '4px',
  },
  xlsxIcon: {
    position: 'absolute',
    paddingLeft: '0px',
    top: 'calc(calc(100vh - 182px) * 1.00 - 32px + 88px)',
    left: (props) => `calc(${props.leftPos}px + 18px)`, // float the icon relative to the ActionBar, so it appears fixed...
    zIndex: 2,
  },
  tooltip: {
    backgroundColor: 'rgb(19,62,96)',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
}))

const LogisticsPage = () => {
  const _isMounted = useRef(false)
  const classes = useStyles()
  const userUnitsDepth = useUnits(UNITS_FOR.Depth)
  const userUnitsOD = useUnits(UNITS_FOR.Diameter)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const [searchText, setSearchText] = useState('')
  const [logisticsData, setLogisticsData] = useState([])
  const { newCancelToken: logistCancelToken, cancelPreviousRequest: logistCancelRequest } = useAxiosCancelToken()
  const [{ data: logisticsRawData }] = useAxios({
    url: '/logisticsOverview',
    cancelToken: logistCancelToken(),
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.logisticsKey)

    return () => {
      _isMounted.current = false
      logistCancelRequest()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && logisticsRawData) {
      let newData = []
      logisticsRawData.forEach((item) => {
        let newItem = { ...item, compTypeText: getCompTypeTextFromEnum(parseInt(item.compType)) }
        newData.push(newItem)
      })

      setLogisticsData(newData)
    }
  }, [logisticsRawData])

  const reduceInventoryData = (data) => {
    if (!Array.isArray(data)) return []
    return data.reduce((output, invItem) => {
      if (
        searchFunction(invItem, searchText, [
          'actualWell',
          'jobNum',
          'operator',
          'state',
          'county',
          'compTypeText',
          'serialNum',
          'make',
          'model',
          'rig',
          'description',
        ]) === false
      ) {
        return output
      }
      let index = output.findIndex((element) => element.actualWell === invItem.actualWell)
      if (index < 0) {
        output.push({
          header: invItem.actualWell, // intend to obsolete
          actualWell: invItem.actualWell,
          rig: invItem.rig,
          operator: invItem.operator,
          state: invItem.state,
          county: invItem.county,
          jobNum: invItem.jobNum,
          wellStatus: invItem.wellStatus,
          lat: invItem.lat,
          long: invItem.long,
          data: [
            {
              desc: invItem.actualWell, // intend to obsolete
              ...invItem,
            },
          ],
        })
      }
      if (index >= 0) {
        output[index].data.push({
          desc: invItem.actualWell, // intend to obsolete
          ...invItem,
        })
      }
      return output
    }, [])
  }

  const onXlsxExport = () => {
    const exportData = reduceInventoryData(logisticsData)

    if (exportData.length < 1) return

    const wb = XLSX.utils.book_new()

    // Inventory Summary page
    let rigRow = []
    let jobRow = []
    exportData.forEach((item) => {
      rigRow.push(item.rig)
      jobRow.push(item.jobNum)
    })
    let ws = XLSX.utils.aoa_to_sheet([rigRow, jobRow], {
      origin: 'B1',
    })

    let dataRows = exportData.reduce((toolTypes, job) => {
      job.data.forEach((tool) => {
        if (toolTypes.findIndex((element) => element[0] === tool.compTypeText) < 0) {
          toolTypes.push([tool.compTypeText])
        }
      })

      return toolTypes
    }, [])

    let initDataValues = new Array(rigRow.length).fill(0)
    dataRows.forEach((row) => {
      row.push(...initDataValues)
    })

    exportData.forEach((job, i) => {
      job.data.forEach((tool) => {
        let seriesIndex = dataRows.findIndex((row) => row[0] === tool.compTypeText)
        if (seriesIndex >= 0) dataRows[seriesIndex][i + 1]++
      })
    })
    XLSX.utils.sheet_add_aoa(ws, dataRows, { origin: 'A3' })

    let totalRow = ['Total']
    rigRow.forEach((_, i) => {
      let rigTotal = 0
      dataRows.forEach((row) => {
        rigTotal += row[i + 1]
      })
      totalRow.push(rigTotal)
    })
    XLSX.utils.sheet_add_aoa(ws, [totalRow], { origin: `A${3 + dataRows.length + 1}` })

    XLSX.utils.book_append_sheet(wb, ws, 'Inventory Summary')

    // create a full inventory page for each rig/job
    const detailedInvHeaderRow = [
      [
        'Description',
        'Component Type',
        'SN',
        'Make',
        'Model',
        `OD (${userUnitsOD})`,
        'Status',
        'Cnx Top',
        'Cnx Btm',
        `Length (${userUnitsDepth})`,
      ],
    ]

    exportData.forEach((job, i) => {
      let wsDetail = XLSX.utils.aoa_to_sheet(detailedInvHeaderRow, {
        origin: 'A1',
      })
      let invRows = []
      job.data.forEach((item) => {
        let compRow = [
          item.description,
          item.compTypeText,
          item.serialNum,
          item.make,
          item.model,
          item.od,
          item.inHole === true ? 'In Hole' : item.dirty === true ? 'Dirty' : 'Clean',
          item.cnxTop,
          item.cnxBtm,
          round(item.length, 2),
        ]
        invRows.push(compRow)
      })
      XLSX.utils.sheet_add_aoa(wsDetail, invRows, { origin: `A2` })

      XLSX.utils.book_append_sheet(wb, wsDetail, `${job.rig} - ${job.jobNum}`)
    })

    XLSX.writeFile(wb, 'Logistics.xlsx')
  }

  return (
    <Page maxWidth>
      <Carousel autoPlay={false} className={classes.carouselPane}>
        <div className={classes.carouselItemContainer}>
          <LogisticsMap latitude={0} longitude={0} logisticsData={reduceInventoryData(logisticsData)} />
        </div>
        <div className={classes.carouselItemContainer}>
          <LogisticsChart logisticsData={reduceInventoryData(logisticsData)} />
        </div>
      </Carousel>
      <div className={classes.searchContainer}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={{
            padding: '1px',
            margin: '1px',
            backgroundColor: '#111', // appColors.windowBackColor,
            border: '1px solid',
          }}
        />
      </div>
      <div className={classes.listContainer}>
        <LogisticsList invListData={reduceInventoryData(logisticsData)} />
      </div>
      <Tooltip title='Export to XLSX' placement='right' classes={{ tooltip: classes.tooltip }}>
        <IconButton className={classes.xlsxIcon} onClick={() => onXlsxExport()}>
          <Iconify icon='mdi:printer' style={{ color: appColors.headerTextColor, fontSize: '28px' }} />
        </IconButton>
      </Tooltip>
    </Page>
  )
}

export default LogisticsPage
