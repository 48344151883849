import React, { useEffect, useRef, useState } from 'react'

import { makeStyles } from '@material-ui/styles'
import { Icon as Iconify } from '@iconify/react'

import { filter as _filter } from 'lodash'

import { appColors } from 'utils'
import { Avatar, Badge, Fab, IconButton, Snackbar, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import MuiAlert from '@material-ui/lab/Alert'

import SearchBar from 'material-ui-search-bar'

import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import EditOrgDialog from './EditOrgDialog'
import SectionPickListDialog from 'components/common/SectionPickListDialog'
import ConfirmDialog from 'components/common/ConfirmDialog'
import SimpleStyledList from 'components/common/SimpleStyledList'
import { searchFunction } from '../../common/searchFunctions'

import { productKeyMobileApp, productKeyThirdParty, productKeyWebServices } from '../../userPermissions'

const useStyles = makeStyles((theme) => ({
  listContainer: {
    display: 'flex',
    overflow: 'hidden',
    height: 'calc(calc(100vh - 196px) * 1.00)',
    maxHeight: 'calc(calc(100vh - 196px) * 1.00)',
    borderRadius: '4px',
  },
  headerText: {
    fontSize: 14,
    fontWeight: '500',
    color: appColors.headerTextColor,
    marginLeft: 10,
  },
  itemHeaderText: {
    fontSize: 16,
    fontWeight: '400',
    color: appColors.itemTextColor,
  },
  itemLabelText: {
    fontSize: 14,
    fontWeight: '500',
    color: appColors.headerTextColor,
    width: '96px',
    textAlign: 'right',
    paddingRight: '8px',
  },
  itemText: {
    fontSize: 18,
    fontWeight: '600',
    color: appColors.itemTextColor,
    border: '',
  },
  avatarContainer: {
    margin: 5,
    marginRight: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'lightgrey',
    borderRadius: '50%',
  },
  badgeStyleGreen: {
    '&::after': {
      position: 'absolute',
      backgroundColor: '#44b700',
      color: '#44b700',
      bottom: 0,
      right: 0,
      width: '25%',
      height: '25%',
      borderRadius: '50%',
      animation: '1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  badgeStyleRed: {
    '&::after': {
      position: 'absolute',
      backgroundColor: 'red',
      color: 'red',
      bottom: 0,
      right: 0,
      width: '25%',
      height: '25%',
      borderRadius: '50%',
      // animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  listIconStyle: {
    color: '#C0C0C0',
    fontSize: '24px',
  },
  deleteIconStyle: {
    color: '#C00000',
    fontSize: '24px',
  },
  itemMainContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '8px',
    marginBottom: '1px',
    border: `1px solid`,
    borderColor: appColors.itemBackColor,
    backgroundColor: 'inherit',
    '&:hover': {
      borderColor: appColors.accentColor,
    },
  },
  itemTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 4,
  },
  itemTextWrapContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    flex: 3,
  },
  itemIconContainer: {
    flex: 0.3,
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  itemHorizontalLine: {
    alignSelf: 'center',
    height: 1,
    width: '95%',
    backgroundColor: appColors.headerTextColor,
    marginBottom: 5,
  },
  tooltip: {
    backgroundColor: 'rgb(19,62,96)',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
  addIcon: {
    color: 'white',
    backgroundColor: '#192734', //appColors.accentColor,
    margin: '4px',
    padding: '12px',
    '&:hover': {
      backgroundColor: 'rgb(19, 62, 96)',
    },
    position: 'fixed', //'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  searchContainer: {
    backgroundColor: appColors.itemBackColor,
    padding: '8px',
  },
}))

const OrgList = ({ icons }, ...Props) => {
  const _isMounted = useRef(false)
  const editIndex = React.useRef(-1)
  const classes = useStyles()
  const [selectedOrg, setSelectedOrg] = useState({ orgName: '', counter: 0 })
  const [orgList, setOrgList] = useState([])
  const [filteredOrgs, setFilteredOrgs] = useState([])
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [showEditOrg, setShowEditOrg] = useState(false)
  const [showPickFeatures, setShowPickFeatures] = useState(false)
  const [features, setFeatures] = useState([])
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [searchText, setSearchText] = useState('')
  const [inverseSort] = useState(false)
  const [sortParameterPri] = useState('organization')

  const { newCancelToken: newOrgCancelToken, cancelPreviousRequest: cancelPreviousOrgRequest } = useAxiosCancelToken()
  const [{ data: orgData, error: orgError }, refreshOrgs] = useAxios({
    url: '/admin/getOrgList',
    cancelToken: newOrgCancelToken(),
  })

  const { newCancelToken: newPFCancelToken, cancelPreviousRequest: cancelPreviousPFRequest } = useAxiosCancelToken()
  const [{ data: featureData, error: pfError }] = useAxios({
    url: '/admin/getPFList',
    cancelToken: newPFCancelToken(),
  })

  const { newCancelToken: newAccessCancelToken, cancelPreviousRequest: cancelPreviousAccessRequest } =
    useAxiosCancelToken()
  const [{ error: accessError }, saveFeatureAccess] = useAxios({
    url: '/admin/replacePFAccess',
    manual: true,
    cancelToken: newAccessCancelToken(),
  })

  const { newCancelToken: newDeleteCancelToken, cancelPreviousRequest: cancelPreviousDeleteRequest } =
    useAxiosCancelToken()
  const [{ error: deleteError }, deleteOrg] = useAxios({
    url: '/admin/deleteOrg',
    manual: true,
    cancelToken: newDeleteCancelToken(),
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
      cancelPreviousOrgRequest()
      cancelPreviousPFRequest()
      cancelPreviousAccessRequest()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && featureData) {
      let pfList = []
      featureData.forEach((feature) => {
        if (feature.productDescription === 'Website') {
          pfList.push({ header: 'Website', ...feature })
        }
        if (feature.productDescription === 'Mobile App') {
          pfList.push({ header: 'Mobile App', ...feature })
        }
        if (feature.productDescription === 'Client API') {
          pfList.push({ header: 'Client API', ...feature })
        }
      })
      setFeatures(createFeatureList(pfList))
    }
  }, [featureData]) // eslint-disable-line react-hooks/exhaustive-deps

  const createFeatureList = (feats) => {
    let output = [
      {
        header: 'Website',
        icon: 'web',
        iconFamil: 'MaterialCommunityIcons',
        productKey: productKeyWebServices,
        data: [],
      },
      { header: 'Mobile App', icon: 'mobile1', iconFamil: 'AntDesign', productKey: productKeyMobileApp, data: [] },
      {
        header: 'Client API',
        icon: 'API',
        iconFamil: 'AntDesign',
        productKey: productKeyThirdParty,
        data: [],
      },
    ]

    feats.forEach((product) => {
      let productIndex = output.findIndex((element) => product.productKey === element.productKey)
      if (productIndex >= 0) {
        product.data.forEach((feature) => {
          output[productIndex].data.push({ id: feature.id, desc: feature.featureDescription })
        })
      }
    })

    return output
  }

  useEffect(() => {
    if (orgError !== undefined && orgError !== null) {
      if (cancelPreviousOrgRequest(orgError)) return
    }
  }, [orgError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (pfError !== undefined && pfError !== null) {
      if (cancelPreviousPFRequest(pfError)) return
    }
  }, [pfError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (accessError !== undefined && accessError !== null) {
      if (cancelPreviousAccessRequest(accessError)) return
    }
  }, [accessError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleteError !== undefined && deleteError !== null) {
      if (cancelPreviousDeleteRequest(deleteError)) return
    }
  }, [deleteError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && orgData) {
      let sortData = [...orgData]
      sortData.sort(
        (firstEl, secondEl) =>
          firstEl[sortParameterPri].localeCompare(secondEl[sortParameterPri]) * (inverseSort ? -1 : 1),
      )
      const keyedData = sortData.map((org, index) => ({
        key: index.toString(),
        ...org,
      }))
      setOrgList(keyedData)
    }
  }, [orgData, sortParameterPri, inverseSort])

  useEffect(() => {
    if (_isMounted.current) {
      const filtered = handleSearch(searchText)
      setFilteredOrgs(filtered)
    }
  }, [orgList]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      const filtered = handleSearch(searchText)
      setFilteredOrgs(filtered)
    }
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = (text) => {
    if (text) {
      const data = _filter(orgList, (item) => {
        return searchFunction(item, text, ['organization'])
      })
      return data
    } else {
      return orgList
    }
  }

  const onClickAddOrg = () => {
    editIndex.current = -1
    setShowEditOrg(true)
  }

  const onClickEditOrg = (orgItem) => {
    // console.log('onClickEdit item:', orgItem)
    editIndex.current = parseInt(orgItem.key)
    // console.log('onClickEdit index:', editIndex.current)
    setShowEditOrg(true)
  }

  const onClickProdFeats = async (org) => {
    setSelectedOrg({ orgName: org?.organization, counter: selectedOrg.counter + 1 })
    editIndex.current = parseInt(org?.key)
    setShowPickFeatures(true)
  }

  const applyPickFeatures = async (selectedFeatures) => {
    // console.log('applyPF ', selectedFeatures, selectedOrg)
    if (!selectedOrg?.orgName) return false
    if (selectedOrg?.orgName === '') return false
    if (!selectedFeatures) return false
    if (Array.isArray(selectedFeatures) === false) return false

    let strArray = ''
    selectedFeatures.forEach((feature) => {
      if (feature === '') return
      if (strArray !== '') strArray += ','
      strArray += feature
    })
    // console.log('picked features: ', selectedFeatures, strArray)
    await saveFeatureAccess({
      organization: selectedOrg.orgName,
      features: strArray,
    })

    await refreshOrgs()

    setShowPickFeatures(false)
  }

  const onClickDeleteOrg = (org) => {
    // console.log('onClick delete', org)
    editIndex.current = parseInt(org?.key)
    setConfirm({
      show: true,
      title: 'Delete Organization',
      text: `Are you sure you want to delete the organization ${org?.organization}?`,
      itemIndex: editIndex.current,
    })
  }

  const confirmDelete = async () => {
    if (confirm?.itemIndex > 0) {
      //Stops last org being deleted
      const deleteResponse = await deleteOrg({
        organization: orgList[confirm?.itemIndex].organization,
      })
      if (!deleteResponse.error) {
        // console.log('org deleted: ', orgList[confirm.itemIndex].organization)
        setStatus({ show: true, severity: 'success', message: 'Organization deleted successfully' })
        await refreshOrgs()
      } else {
        setStatus({ show: true, severity: 'error', message: 'Delete organization failed' })
        // console.log(deleteResponse.error)
      }
    }
  }

  const handleCloseStatus = (event, reason) => {
    if (reason === 'clickaway') {
      // return
    }
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const handleCloseEditOrg = async (event, reason) => {
    if (reason === 'submitted') {
      refreshOrgs()
    }
    setShowEditOrg(false)
  }

  const getCompanyIcon = (orgName) => {
    if (icons) {
      const org = icons.find((item) => item.filename === orgName.toLowerCase())
      if (org) {
        let base64ImageString = 'data:image/*;base64,' + org.data
        return base64ImageString
      }
    } else {
      return ''
    }
  }

  const renderOrgItem = (orgItem, idx) => {
    return (
      <div key={idx} className={classes.itemMainContainer}>
        <div className={classes.itemTextContainer} onClick={() => onClickEditOrg(orgItem)}>
          <div className={classes.avatarContainer}>
            {orgItem.active === false ? (
              <Badge className={classes.badgeStyleRed}>
                <Avatar alt={orgItem.organization} src={getCompanyIcon(orgItem.organization)}></Avatar>
              </Badge>
            ) : (
              <Badge className={classes.badgeStyleGreen}>
                <Avatar alt={orgItem.organization} src={getCompanyIcon(orgItem.organization)}></Avatar>
              </Badge>
            )}
          </div>
          <div className={classes.itemText}>{orgItem.organization}</div>
        </div>
        <Tooltip title='Products & Features' placement='bottom' classes={{ tooltip: classes.tooltip }}>
          <IconButton className={classes.itemIconContainer} onClick={() => onClickProdFeats(orgItem)}>
            <Iconify icon='el:list' className={classes.listIconStyle} />
          </IconButton>
        </Tooltip>
        <Tooltip title='Delete Organization' placement='bottom' classes={{ tooltip: classes.tooltip }}>
          <IconButton className={classes.itemIconContainer} onClick={() => onClickDeleteOrg(orgItem)}>
            <Iconify icon='fa-regular:trash-alt' className={classes.deleteIconStyle} />
          </IconButton>
        </Tooltip>
        {status?.show ? (
          <Snackbar open={status?.show} autoHideDuration={2000} onClose={handleCloseStatus}>
            <MuiAlert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
              {status.message}
            </MuiAlert>
          </Snackbar>
        ) : null}
      </div>
    )
  }

  return (
    <React.Fragment>
      {showEditOrg ? (
        <EditOrgDialog
          open={showEditOrg}
          onClose={handleCloseEditOrg}
          data={editIndex.current > -1 ? orgList[editIndex.current] : {}}
          useInputData={editIndex.current > -1}
        />
      ) : null}
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => confirmDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      {showPickFeatures ? (
        <SectionPickListDialog
          title='Select Features'
          open={showPickFeatures}
          setOpen={() => setShowPickFeatures(false)}
          onApply={applyPickFeatures}
          items={features}
          initSelItems={orgList[editIndex.current].features}
        />
      ) : null}
      <div className={classes.searchContainer}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={{
            padding: '1px',
            margin: '1px',
            backgroundColor: '#111',
          }}
        />
      </div>
      <div className={classes.listContainer}>
        {/* {filteredOrgs ? filteredOrgs.map((item, idx) => renderOrgItem(item, idx)) : null} */}
        <SimpleStyledList listItems={filteredOrgs} renderItem={renderOrgItem}></SimpleStyledList>
      </div>
      <Tooltip title='add org' placement='left' classes={{ tooltip: classes.tooltip }}>
        <Fab aria-label='add org' className={classes.addIcon} onClick={onClickAddOrg}>
          <AddIcon></AddIcon>
        </Fab>
      </Tooltip>
    </React.Fragment>
  )
}

export default OrgList
