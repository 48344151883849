import React from 'react'
import { RecoilRoot } from 'recoil'

import './App.css'
import NavBar from './NavBar'
import LandingPage from './LandingPage'
import ActionBar from './ActionBar'
import RecoilData from './WellsData'

import { useAuth0 } from '@auth0/auth0-react'
import UserProfile from 'components/UserProfile'
import { WellListPage } from './WellList'
import WellDetailsPage from 'components/WellDetailsPage'
import ThreeDeeViewWrapper from './ThreeDeeView'
import LogisticsPage from 'components/Logistics'
import { WellSeekerAdminPage } from 'components/Admin'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './theme'

import MainRouter from './router'
import DrillingDashboard from './DrillingDashboard'
import {
  DailyActivityPage,
  DepthTimePage,
  InventoryPage,
  SurveysPage,
} from './WellPages'
import SlideSheetHeaderPage from './WellPages/SlideSheetPages/SlideSheetHeaderPage'
import SlideSheetPage from './WellPages/SlideSheetPages/SlideSheetPage'
import DrillStringHeaderPage from './WellPages/DrillStringPages/DrillStringHeaderPage'
import DrillStringPage from './WellPages/DrillStringPages/DrillStringPage'
import DepthVsDaysPage from './Analytics/DepthVsDaysPage'
import SlideRotateFootagePage from './Analytics/SlideRotateFootagePage'
import SlideRotateRopPage from './Analytics/SlideRotateRopPage'
import SlideRotateDrillingHoursPage from './Analytics/SlideRotateDrillingHoursPage'
import CostPerWellPage from './Analytics/CostPerWellPage'
import CostPerDayPage from './Analytics/CostPerDayPage'
import CostPerOperatorPage from './Analytics/CostPerOperator'
import RigDaysPage from './Analytics/RigDaysPage'
import EdrPage from './EDR/EdrPage'
import { RoleAdminPage, UserAdminPage } from './Admin/UserMgmt'
import { DatabaseAdminPage, DrillLinkAdminPage, IcdsAdminPage, OrgAdminPage } from './Admin/Innova'
import DailyCostHeaderPage from './WellPages/DailyCostPage/DailyCostHeaderPage'
import DailyCostPage from './WellPages/DailyCostPage/DailyCostPage'
import ErrorModelsPage from './ErrorModels/ErrorModelsPage'
import ErrorTermsPage from './ErrorModels/ErrorTermsPage'
import ToolOrdersPage from './ToolOrders/ToolOrdersPage'
import ToolOrderDetailsPage from './ToolOrders/ToolOrderDetailsPage'
import WellComponentsPage from './WellComponentsPage/WellComponentsPage'
import DataAcquisitionPage from './DataAcquisition/DataAcquisitionPage'
import DataStorePage from './DataAcquisition/DataStorePage'
import WitsmlServerPage from './DataAcquisition/WitsmlServerPage'

const publicRoutes = [
  {
    path: '/',
    exact: true,
    component: LandingPage,
  },
]

const privateRoutes = [
  {
    path: '/user-profile',
    component: UserProfile,
  },
  {
    path: '/well-list',
    component: WellListPage,
  },
  {
    path: '/well-pages/well-page',
    component: WellDetailsPage,
  },
  {
    path: '/well-pages/comps',
    component: WellComponentsPage,
  },
  {
    path: '/well-pages/daily-activity',
    component: DailyActivityPage,
  },
  {
    path: '/well-pages/depth-time',
    component: DepthTimePage,
  },
  {
    path: '/well-pages/inventory',
    component: InventoryPage,
  },
  {
    path: '/well-pages/surveys',
    component: SurveysPage,
  },
  {
    path: '/well-pages/slide-sheets',
    component: SlideSheetHeaderPage,
  },
  {
    path: '/well-pages/slide-details',
    component: SlideSheetPage,
  },
  {
    path: '/well-pages/costs/dailyCostHeaders',
    component: DailyCostHeaderPage,
  },
  {
    path: '/well-pages/costs/dailyCosts',
    component: DailyCostPage,
  },
  {
    path: '/well-pages/drill-string',
    component: DrillStringHeaderPage,
  },
  {
    path: '/well-pages/bha-summary',
    component: DrillStringPage,
  },
  {
    path: '/well-pages/drilling-dashboard',
    component: DrillingDashboard,
  },
  {
    path: '/well-pages/well-3d-viewer',
    component: ThreeDeeViewWrapper,
  },
  {
    path: '/edr',
    component: EdrPage,
  },
  {
    path: '/logistics',
    component: LogisticsPage,
  },
  {
    path: '/analytics/depthVDays',
    component: DepthVsDaysPage,
  },
  {
    path: '/analytics/slideRotateFootage',
    component: SlideRotateFootagePage,
  },
  {
    path: '/analytics/slideRotateRop',
    component: SlideRotateRopPage,
  },
  {
    path: '/analytics/slideRotateHours',
    component: SlideRotateDrillingHoursPage,
  },
  {
    path: '/analytics/costPerWell',
    component: CostPerWellPage,
  },
  {
    path: '/analytics/costPerDay',
    component: CostPerDayPage,
  },
  {
    path: '/analytics/costPerOperator',
    component: CostPerOperatorPage,
  },
  {
    path: '/analytics/rigDays',
    component: RigDaysPage,
  },
  {
    path: '/admin/userAdmin/users',
    component: UserAdminPage,
  },
  {
    path: '/admin/userAdmin/roles',
    component: RoleAdminPage,
  },
  {
    path: '/admin/innovaAdmin/orgs',
    component: OrgAdminPage,
  },
  {
    path: '/admin/innovaAdmin/databases',
    component: DatabaseAdminPage,
  },
  {
    path: '/admin/innovaAdmin/icds',
    component: IcdsAdminPage,
  },
  {
    path: '/admin/innovaAdmin/drillLink',
    component: DrillLinkAdminPage,
  },
  {
    path: '/admin/wellSeekerAdmin',
    component: WellSeekerAdminPage,
  },
  {
    path: '/errorModels',
    component: ErrorModelsPage,
  },
  {
    path: '/errorTerms',
    component: ErrorTermsPage,
  },
  {
    path: '/toolOrders',
    component: ToolOrdersPage,
  },
  {
    path: '/toolOrderDetails',
    component: ToolOrderDetailsPage,
  },
  {
    path: '/dataAcquisition',
    component: DataAcquisitionPage,
  },
  {
    path: '/dataStores',
    component: DataStorePage,
  },
  {
    path: '/witsmlServers',
    component: WitsmlServerPage,
  },
]

const App = () => {
  const { loading } = useAuth0()

  if (loading) return <div>Loading...</div>

  return (
    <RecoilRoot>
      <div className='App'>
        <ThemeProvider theme={theme}>
          <MainRouter publicRoutes={publicRoutes} privateRoutes={privateRoutes}>
            <NavBar />
            <ActionBar />
            <RecoilData />
          </MainRouter>
        </ThemeProvider>
      </div>
    </RecoilRoot>
  )
}

export default App
