import React from 'react'

import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/styles'

import SurveyList from './SurveysList'

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: '#192734',
    height: '100%',
    overflowX: 'hidden',
  },
}))

const SurveysCard = () => {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <SurveyList />
    </Card>
  )
}

export default SurveysCard
