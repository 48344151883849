import React, { useRef, useState, useEffect } from 'react'

import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/styles'
import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import Skeleton from '@material-ui/lab/Skeleton'

import multiParse from 'utils/multiParse'

import DrillLinkList from './DrillLinkList'

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: '#192734',
  },
}))

const DrillLinkAdminCard = () => {
  const classes = useStyles()

  const _isMounted = useRef(false)
  const [orgIconList, setOrgIconList] = useState({})

  const { newCancelToken, cancelPreviousRequest } = useAxiosCancelToken()
  const [{ data: dataOrgIcons, loading }] = useAxios({
    url: '/admin/getOrgIcons',
    cancelToken: newCancelToken(),
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
      cancelPreviousRequest()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && dataOrgIcons) {
      const mfpBoundary = dataOrgIcons.substring(2, dataOrgIcons.search('Content')).trim()
      let parts = multiParse(Buffer.from(dataOrgIcons), mfpBoundary)
      setOrgIconList(parts)
    }
  }, [dataOrgIcons])

  return (
    <Card className={classes.card}>
      {loading ? <Skeleton variant='text' /> : <DrillLinkList icons={orgIconList} />}
    </Card>
  )
}

export default DrillLinkAdminCard
