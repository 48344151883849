import { Checkbox, FormControlLabel, FormGroup, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useMemo, useRef, useState } from 'react'

import { Box3 } from 'three'
import { Canvas } from '@react-three/fiber'
import { Controls } from './Controls'
import GridLines from './Grid'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
import { Rig } from './Rig'
import { SurveyLabels } from './SurveyLabels'
import { Text } from '@react-three/drei'
import { Well3DHeader } from './Well3DHeader'
import { WellHead } from './WellHead'
import { WellNames } from './WellNames'
import { WellSpheres } from './WellSpheres'
import { Wellbore } from './Wellbore'
import WorkSight from './WorkSight'
import { createChartData } from './createChartDataFuncs'
import { fonts } from './fonts'
import { makeStyles } from '@material-ui/core/styles'
import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import { Annotations } from './Annotations'
import { Casing } from './Casing'
import { Formations } from './Formations'
import { Targets } from './Targets'
import Skeleton from '@material-ui/lab/Skeleton'
import { useRecoilValue } from 'recoil'
import { show3DControlsAtom } from 'atoms'

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%', // 'calc(100vh - 200px)',
    display: 'flex',
    flexDirection: 'column',
  },
  canvasContainer: {
    display: 'flex',
    flexGrow: 1,
    flexShrink: 1,
    overflow: 'hidden',
  },
  controlsContainer: {
    display: 'flex',
    flexGrow: 0,
  },
}))

const initialCamOffset = 30
export const camSpeed = 1
export const scale = 0.1
export const surveyTrajectoryColor = '#FF5733'
export const planTrajectoryColor = '#0096FF'
export const labelColor = '#909090'

export const landRig = 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/models/landRigSmall.obj'
export const offshoreRig = 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/models/offshoreRigSmall.obj'
export const pumpJack = 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/models/pumpJackSmall.obj'

const OffsetWellbores = ({ offsetData, display }) => {
  if (!display) return null
  if (!Array.isArray(offsetData)) return null
  if (offsetData.length === 0) return null

  return offsetData.map((osWell, index) => <Wellbore wellData={osWell} key={`${osWell.name}wellbore${index}`} />)
}

const OffsetWellboreSpheres = ({ offsetData, offsetsOn, display }) => {
  if (!display) return null
  if (!offsetsOn) return null
  if (!Array.isArray(offsetData)) return null
  if (offsetData.length === 0) return null
  return offsetData.map((osWell, index) => (
    <WellSpheres wellData={osWell} key={`${osWell.name}sphere${index}`} display={true} />
  ))
}

export function validateIndexValue(wellData, index) {
  if (!Array.isArray(wellData)) return -1
  if (wellData.length === 0) return -1
  if (!wellData[0].hasOwnProperty('data')) return -1
  if (!Array.isArray(wellData[0].data)) return -1
  if (wellData[0].data.length === 0) return -1
  if (index >= 0 && index < wellData[0].data.length) return index
  if (index >= wellData[0].data.length) return 0

  return wellData[0].data.length - 1
}

export function ObjToPrimitive({ url, position, scale, offset, rotation }) {
  const [obj, setObj] = useState()
  useMemo(() => new OBJLoader().load(url, setObj), [url])
  if (obj) {
    if (rotation && obj.rotation.x === 0) {
      obj.rotateX(rotation.x)
      obj.rotateY(rotation.y)
      obj.rotateZ(rotation.z)
    }

    obj.scale.set(scale, scale, scale)
    obj.matrixAutoUpdate = false
    obj.updateMatrix()

    if (!offset.hasOwnProperty('x')) {
      offset = { x: 1, y: 1, z: 1 }
    }

    let boundingBox = new Box3().setFromObject(obj)
    obj.position.set(
      ((boundingBox.max.x - boundingBox.min.x) / 2) * offset.x + position.x,
      ((boundingBox.max.y - boundingBox.min.y) / 2) * offset.y + position.y,
      ((boundingBox.max.z - boundingBox.min.z) / 2) * offset.z + position.z,
    )

    obj.updateMatrix()

    return <primitive object={obj} />
  }

  return null
}

export const TextLabel = ({ position, label, rotation, color, size }) => {
  if (typeof label !== 'string') return null
  if (label === '') return null

  return (
    <Text
      position-x={position.x}
      position-y={position.y}
      position-z={position.z}
      text={label}
      font={fonts.Roboto}
      fontSize={size ? `${size}` : '1'}
      anchorX='center'
      anchorY='middle'
      color={color}
      rotation={rotation ? [rotation.x, rotation.y, rotation.z] : [0, 0, 0]}
    />
  )
}

const OffsetWellheads = ({ offsetData, display, offsetsOn }) => {
  if (!display) return null
  if (!offsetsOn) return null
  if (!Array.isArray(offsetData)) return null
  if (offsetData.length === 0) return null

  return offsetData.map((osWell, index) => <WellHead wellData={osWell} key={`${osWell.name}wellhead${index}`} />)
}

function ControlsCheckBox(label, value, propName, updateDisplaySettings) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={value}
          onChange={() => {
            updateDisplaySettings(propName)
          }}
          name='rig'
          style={{ color: '#A0A0A0' }}
        />
      }
      label={<Typography style={{ color: '#A0A0A0' }}>{label}</Typography>}></FormControlLabel>
  )
}

const ThreeDeeView = ({ well, onCard = false }) => {
  const classes = useStyles()
  const indexRef = useRef(0)
  const show3DControls = useRecoilValue(show3DControlsAtom)
  const [index, setIndex] = useState(0)
  const [displaySettings, setDisplaySettings] = useState({
    surveyLabels: false,
    annotations: false,
    formations: false,
    targets: true,
    casing: false,
    showWellLabels: true,
    showOffsets: true,
    showWellHeads: true,
    showRig: true,
    showSpheres: true,
    workSightRadius: 50,
  })
  const { newCancelToken, isCancel } = useAxiosCancelToken()
  const [{ loading, data: wellData, error }] = useAxios({
    url: '/well/getWellData',
    data: { wellName: well, wellPlan: true, surveys: true, offsetWells: true, targets: true, actualWellData: true },
    cancelToken: newCancelToken(),
  })

  const [chartData, setChartData] = useState({
    refData: [],
    offsetData: [],
    formations: [],
    annotations: [],
    casing: [],
    targets: [],
  })

  useEffect(() => {
    if (error !== undefined && error !== null) {
      if (isCancel(error)) return
    }
  }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (loading || !wellData) return
    setChartData(createChartData(wellData, well))
  }, [loading, wellData]) // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return null

  function updateDisplaySettings(property, newValue) {
    let newSettings = { ...displaySettings }
    if (!newSettings.hasOwnProperty(property)) return

    if (property !== 'workSightRadius') newSettings[property] = !newSettings[property]
    if (property === 'workSightRadius') newSettings[property] = newValue
    setDisplaySettings(newSettings)
  }

  if (loading) {
    return (
      <React.Fragment>
        <div className={classes.container}>
          <Skeleton height={'80vh'} variant='rect' animation='wave' />
        </div>
      </React.Fragment>
    )
  }

  if (!loading) {
    return (
      <React.Fragment>
        <div className={classes.container}>
          <div className={classes.canvasContainer}>
            <Canvas
              style={{ width: '100%', height: '100%', background: '#202020' }}
              camera={{ position: [-initialCamOffset, initialCamOffset / 2, 5], fov: 24, near: 0.1, far: 5000 }}
              onCreated={({ camera }) => {
                camera.lookAt(0, 0, 0)
              }}>
              <Controls chartData={chartData} indexRef={indexRef} setIndex={setIndex} />
              <ambientLight color='#ffffff' intensity='0.25' />
              <pointLight position={[100, 200, 100]} target={[0, 0, 0]} />
              <GridLines refData={chartData.refData} offsetData={chartData.offsetData} scale={scale} />
              <Wellbore wellData={chartData.refData[0]} />
              <WellSpheres wellData={chartData.refData[0]} display={displaySettings.showSpheres} />
              <Rig wellData={chartData.refData[0]} isOffshore={false} display={displaySettings.showRig} />
              <OffsetWellbores offsetData={chartData.offsetData} display={displaySettings.showOffsets} />
              <OffsetWellboreSpheres
                offsetData={chartData.offsetData}
                offsetsOn={displaySettings.showOffsets}
                display={displaySettings.showSpheres}
              />
              <OffsetWellheads
                offsetData={chartData.offsetData}
                offsetsOn={displaySettings.showOffsets}
                display={displaySettings.showWellHeads}
              />
              <WorkSight
                radius={displaySettings.workSightRadius * scale}
                refData={chartData.refData}
                offsetsOn={displaySettings.showOffsets}
              />
              <WellNames
                refData={chartData.refData}
                offsetData={chartData.offsetData}
                display={displaySettings.showWellLabels}
                offsetsOn={displaySettings.showOffsets}
              />
              <SurveyLabels refData={chartData.refData} display={displaySettings.surveyLabels} />
              <Annotations annotations={chartData.annotations} display={displaySettings.annotations} />
              <Casing casing={chartData.casing} display={displaySettings.casing} />
              <Formations
                formations={chartData.formations}
                display={displaySettings.formations}
                refData={chartData.refData}
                offsetData={chartData.offsetData}
              />
              <Targets targets={chartData.targets} display={displaySettings.targets} />
            </Canvas>
          </div>
          <div className={classes.controlsContainer}>
            <Well3DHeader well={well} refData={chartData.refData} index={index} onCard={onCard} />
            {!onCard || (onCard && show3DControls) ? (
              <FormGroup style={{ flexDirection: 'row' }}>
                {ControlsCheckBox('Offset Wells', displaySettings.showOffsets, 'showOffsets', updateDisplaySettings)}
                {ControlsCheckBox('Rig', displaySettings.showRig, 'showRig', updateDisplaySettings)}
                {ControlsCheckBox('Wellheads', displaySettings.showWellHeads, 'showWellHeads', updateDisplaySettings)}
                {ControlsCheckBox('Survey Stations', displaySettings.showSpheres, 'showSpheres', updateDisplaySettings)}
                {ControlsCheckBox('Casing', displaySettings.casing, 'casing', updateDisplaySettings)}
                {ControlsCheckBox('Annotations', displaySettings.annotations, 'annotations', updateDisplaySettings)}
                {ControlsCheckBox('Formations', displaySettings.formations, 'formations', updateDisplaySettings)}
                {ControlsCheckBox('Targets', displaySettings.targets, 'targets', updateDisplaySettings)}
                {ControlsCheckBox(
                  'Well Names',
                  displaySettings.showWellLabels,
                  'showWellLabels',
                  updateDisplaySettings,
                )}
                {ControlsCheckBox('Survey Labels', displaySettings.surveyLabels, 'surveyLabels', updateDisplaySettings)}

                <FormControlLabel
                  control={
                    <TextField
                      value={displaySettings.workSightRadius}
                      onChange={(e) => {
                        let newVal = e.target.value
                        updateDisplaySettings('workSightRadius', newVal)
                      }}
                      name='workSightRadius'
                      style={{ width: 30, height: 15 }}
                      inputProps={{ style: { textAlign: 'center', padding: 0 } }}
                    />
                  }
                  label={<Typography style={{ color: '#A0A0A0' }}>WorkSight Radius</Typography>}></FormControlLabel>
              </FormGroup>
            ) : null}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ThreeDeeView
