import React, { useEffect, useRef, useState } from 'react'

import PrintIcon from '@material-ui/icons/Print'
import { makeStyles } from '@material-ui/styles'
import { useSetRecoilState } from 'recoil'
// import { currentErrorModelAtom } from 'atoms'
import { currentBhaAtom } from 'atoms'
import { IconButton, Tooltip, Snackbar, ListItem } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import MuiAlert from '@material-ui/lab/Alert'
import AddIcon from '@material-ui/icons/Add'
import { Icon as Iconify } from '@iconify/react'
import ConfirmDialog from 'components/common/ConfirmDialog'
import InputModal from 'components/common/InputModal'
import SearchBar from 'material-ui-search-bar'
import { filter as _filter } from 'lodash'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'

import { unescapeHtml } from 'utils/htmlSymbolHandling'

import { getDrillStringIcon } from 'components/common/drillStringFunctions'
import { isoStringToDateString, isoStringToTimeString } from 'utils/dateTimeFunctions'
import { numberWithCommas } from 'utils/stringFunctions'

import { round } from '../../../utils/numberFunctions'
import { useRecoilValue } from 'recoil'
import { currentWellAtom } from 'atoms'
import MenuButton from 'components/common/MenuButton'
import RefreshIcon from '@material-ui/icons/Refresh'

import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import { appColors } from 'utils'
import * as XLSX from 'xlsx'
import * as yup from 'yup'

const useStyles = makeStyles((theme) => ({
  listContainer: {
    display: 'flex',
    overflowY: 'hidden',
    overflowX: 'hidden',
    height: 'calc(calc(100vh - 200px) * 1.00)',
    maxHeight: 'calc(calc(100vh - 200px) * 1.00)',
    borderRadius: '4px',
    border: '1px solid #000',
    backgroundColor: theme.palette.itemBackground,
    // width: '100%',
  },
  headerText: {
    fontSize: 18,
    fontWeight: '500',
    color: appColors.headerTextColor,
    textAlign: 'right',
    paddingRight: '8px',
    alignItems: 'center',
  },
  itemText: {
    fontSize: 18,
    color: appColors.itemTextColor,
    border: '',
    alignItems: 'center',
  },
  deleteIconStyle: {
    color: '#C00000',
    fontSize: '24px',
  },
  navigateIconStyle: {
    color: appColors.headerTextColor,
    fontSize: '24px',
  },
  fixedListContainer: {
    display: 'flex',
    borderRadius: '4px',
    backgroundColor: theme.palette.itemBackground,
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#136cb9', //'darkgrey', //'rgba(0,0,0,.1)',
      outline: '1px solid #1679d0',
    },
    overflow: 'auto',
  },
  itemMainContainer: {
    flexDirection: 'column',
    padding: '0px',
    borderRadius: 5,
    marginLeft: '4px',
    background: 'linear-gradient(to bottom, #2d2d2d 0%, #1c1c1c 100%)',
    marginBottom: '4px',
    width: 'calc(100% - 12px)', // 8px for marginLeft, 2px for left & right border
    border: `1px solid #000`,
    backgroundColor: appColors.itemBackColor,
    '&:hover': {
      borderColor: appColors.accentColor,
    },
  },
  dataContentsContainer: {
    border: '1px solid green',
    display: 'flex',
    width: '100%',
  },
  itemHeaderContainer: {
    display: 'flex',
    color: '#FFF',
    width: '100%',
  },
  itemHeaderDescriptionBox: {
    color: '#FFF',
    display: 'flex',
    flex: '2 1 auto',
    alignItems: 'center',
  },
  itemHeaderBhaNum: {
    fontSize: 18,
    fontWeight: '500',
    color: appColors.headerTextColor,
    textAlign: 'right',
    paddingRight: '8px',
    alignItems: 'center',
  },
  itemHeaderBhaDescr: {
    border: '1px solid purple',
  },
  motorReportIconContainer: {
    color: appColors.itemTextColor,
    border: '1px solid',
    width: 40,
    height: 40,
    margin: 5,
  },
  motorReportIcon: {
    color: appColors.itemTextColor,
    width: 40,
    height: 40,
  },
  itemTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    flex: 1,
  },
  itemTextWrapContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
  },
  itemTextWrapContainerWide: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1.5,
  },
  itemIconContainer: {
    width: 100,
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltip: {
    backgroundColor: 'rgb(19,62,96)',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
  rowContainer: {
    flex: 1,
    flexDirection: 'row',
    display: 'flex',
  },
  colContainer: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    display: 'inline-flex',
    width: '5%',
  },
  subHeaderText: {
    fontSize: 14,
    fontWeight: '500',
    color: appColors.headerTextColor,
    margin: 4,
  },
  colContainerMarginTop: {
    flex: 1,
    marginBottom: 10,
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  drillStringIconContainer: {
    marginRight: 10,
  },
  menuIcon: {
    backgroundColor: 'transparent',
    margin: '4px',
    padding: '12px',
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  searchContainer: {
    backgroundColor: appColors.itemBackColor,
    padding: '8px',
  },
}))

const DrillStringHeaderList = ({ history }) => {
  const _isMounted = useRef(false)
  const currentWell = useRecoilValue(currentWellAtom)
  const classes = useStyles()
  const [searchText, setSearchText] = useState('')
  const setCurrentBha = useSetRecoilState(currentBhaAtom)
  const editIndex = useRef(-1)
  const [sortedData, setSortedData] = useState([])
  const [filteredList, setFilteredList] = useState([])
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [showEditor, setShowEditor] = useState(false)
  const listRef = useRef()
  const unitsFt = useUnits(UNITS_FOR.Depth)
  const unitsInch = useUnits(UNITS_FOR.Diameter)

  const { newCancelToken: newDsHeaderCancelToken, cancelPreviousRequest: cancelPreviousDsHeaderDataRequest } =
    useAxiosCancelToken()
  const [{ data: dsHeaderData, error: dsHeaderDataError }, getDsHeaderData] = useAxios({
    url: '/well/drillString/getBhaHeaders',
    data: {
      wellName: currentWell,
    },
    cancelToken: newDsHeaderCancelToken(),
  })

  const { newCancelToken: newErrorModelAllCancelToken, cancelPreviousRequest: cancelPreviousErrorModelAllRequest } =
    useAxiosCancelToken()
  const [{ data: errorModelsAll, error: errModelsAllError }, getErrorModelsAll] = useAxios({
    url: '/errorModels/getErrorModels',
    cancelToken: newErrorModelAllCancelToken(),
  })

  const { newCancelToken: newDeleteCancelToken, cancelPreviousRequest: cancelPreviousDeleteRequest } =
    useAxiosCancelToken()
  const [{ error: deleteError }, deleteErrorModel] = useAxios({
    url: '/errorModels/deleteErrorModel',
    manual: true,
    cancelToken: newDeleteCancelToken(),
  })

  const { newCancelToken: createCancelToken, cancelPreviousRequest: cancelCreateReq } = useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorCreate }, addErrorModel] = useAxios({
    url: '/errorModels/addErrorModelHeader',
    manual: true,
    cancelToken: createCancelToken(),
  })

  const { newCancelToken: updateCancelToken, cancelPreviousRequest: cancelUpdateReq } = useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorUpdate }, updateErrorModel] = useAxios({
    url: '/errorModels/updateErrorModelHeader',
    manual: true,
    cancelToken: updateCancelToken(),
  })

  useEffect(() => {
    if (dsHeaderData) {
      const data = [...dsHeaderData]
      if (data.length > 1) {
        const sortParameter = 'bhaNumRep'
        const inverseSort = true
        data.sort(
          (firstEl, secondEl) => (firstEl[sortParameter] > secondEl[sortParameter] ? 1 : -1) * (inverseSort ? -1 : 1),
        )
        setSortedData(data)
      }
    }
  }, [dsHeaderData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sortedData) {
      const filtered = handleSearch(searchText)
      setFilteredList(filtered)
    }
  }, [sortedData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      const filtered = handleSearch(searchText)
      setFilteredList(filtered)
    }
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (errModelsAllError !== undefined && errModelsAllError !== null) {
      if (cancelPreviousErrorModelAllRequest(errModelsAllError)) return
    }
  }, [errModelsAllError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (dsHeaderDataError !== undefined && dsHeaderDataError !== null) {
      if (cancelPreviousDsHeaderDataRequest(dsHeaderDataError)) return
    }
  }, [dsHeaderDataError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleteError !== undefined && deleteError !== null) {
      if (cancelPreviousDeleteRequest(deleteError)) return
    }
  }, [deleteError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorUpdate !== undefined && errorUpdate !== null) {
      if (cancelUpdateReq(errorUpdate)) return
    }
  }, [errorUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorCreate !== undefined && errorCreate !== null) {
      if (cancelCreateReq(errorCreate)) return
    }
  }, [errorCreate]) // eslint-disable-line react-hooks/exhaustive-deps

  // const showTerms = (index) => {
  //   setCurrentErrorModel({
  //     name: filteredList[index].toolName,
  //     toolId: filteredList[index].toolId,
  //   })
  //   history.push('/errorTerms')
  // }

  const showTerms = (index) => {
    setCurrentBha({
      bhaNum: filteredList[index].bhaNum,
    })
    history.push('/well-pages/bha-summary')
  }

  // const renderTextField = (text, value) => {
  //   return (
  //     <div className={classes.itemTextContainer}>
  //       <div className={classes.headerText}>{text} </div>
  //       <div className={classes.itemText}>{value}</div>
  //     </div>
  //   )
  // }

  const onClickDelete = (item) => {
    setConfirm({
      show: true,
      title: 'Delete drill string',
      text: `Are you sure you want to delete ${item?.toolName}?`,
      itemIndex: parseInt(item?.toolId),
    })
  }

  const confirmDelete = async () => {
    if (confirm?.itemIndex >= 0) {
      // const deleteResponse = await deleteErrorModel({
      //   toolId: confirm?.itemIndex,
      // })

      // if (!deleteResponse.error) {
      //   setStatus({ show: true, severity: 'success', message: 'Error model deleted successfully' })
      //   await getDsHeaderData()
      //   getErrorModelsAll()
      // } else {
      //   setStatus({ show: true, severity: 'error', message: 'Delete error model failed' })
      // }
    }
  }

  const handleNewErrorModel = () => {
    setShowEditor(true)
  }

  const handleCloseStatus = (event, reason) => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const handleClose = () => {
    setShowEditor(false)
  }

  const renderInOutData = (dateTime, depth, title) => (
    <div className={classes.rowContainer}>
      <div className={classes.subHeaderText}>{title}</div>
      <div className={classes.itemText}>{isoStringToDateString(dateTime)}</div>
      <div className={classes.subHeaderText}> - </div>
      <div className={classes.itemText}>{isoStringToTimeString(dateTime)}</div>
      <div className={classes.subHeaderText}> - </div>
      <div className={classes.itemText}>
        {round(depth, 2)} {unitsFt}
      </div>
    </div>
  )

  const renderDateInDateOut = ({ dateIn, dateOut, depthIn, depthOut }) => (
    <div style={{ maxWidth: 400 }}>
      {renderInOutData(dateIn, depthIn, 'BHA PU: ')}
      {renderInOutData(dateOut, depthOut, 'BHA LD: ')}
    </div>
  )

  const renderSlideRotateProperty = (slide, rotate, total, title) => (
    <div className={classes.colContainer}>
      <div className={classes.subHeaderText}>{title}</div>
      <div className={classes.itemText}>{numberWithCommas(round(slide, 2))}</div>
      <div className={classes.itemText}>{numberWithCommas(round(rotate, 2))}</div>
      <div className={classes.itemText}>{numberWithCommas(round(total, 2))}</div>
    </div>
  )

  const renderSlideRotateData = (item) => (
    <div className={classes.rowContainer}>
      <div className={classes.colContainer} style={{ alignItems: 'flex-end', margin: '15px 5px 0 0' }}>
        <div className={classes.subHeaderText}> </div>
        <div className={classes.subHeaderText}>Slide: </div>
        <div className={classes.subHeaderText}>Rotate: </div>
        <div className={classes.subHeaderText}>Total: </div>
      </div>
      {renderSlideRotateProperty(item.slideDrilled, item.rotateDrilled, item.totalDrilled, 'Footage')}
      {renderSlideRotateProperty(item.slideHours, item.rotateHours, item.totalDrillingCircHours, 'Hours')}
      {renderSlideRotateProperty(item.slideRop, item.rotateRop, item.avgRop, 'ROP')}
    </div>
  )

  const renderPhases = ({ phases }) => {
    return (
      <div style={{ display: 'flex' }}>
        <div className={classes.subHeaderText}>Phases: </div>
        <div className={classes.itemText}>{phases}</div>
      </div>
    )
  }

  const renderDrillStringComp = (data) => (
    <div className={classes.colContainerMarginTop}>
      <div className={classes.rowContainer}>
        <div className={classes.drillStringIconContainer}>
          <Avatar
            size={30}
            src={getDrillStringIcon(data.hasOwnProperty('tfa') ? 'Drill Bit' : 'Motor')}
            style={{ backgroundColor: 'lightgrey' }}
          />
        </div>

        <div className={classes.itemText}>{unescapeHtml(data.description)}</div>
        <div className={classes.subHeaderText}> - </div>
        <div className={classes.itemText}>
          {round(data.Od, 2)} {unitsInch}
        </div>
      </div>
    </div>
  )

  const renderBhaItem = (props) => {
    const { index, style } = props
    const item = filteredList[index]
    return (
      <ListItem style={style} disableGutters={true} dense>
        <div className={classes.itemMainContainer}>
          <div className={classes.itemHeaderContainer}>
            <div className={classes.itemHeaderDescriptionBox}>
              <div className={classes.headerText} style={{ marginLeft: 10 }}>
                BHA# {item.bhaNumRep}:
              </div>
              <div className={classes.itemText}>{item.bhaDescription}</div>
            </div>
            <div className={classes.motorReportIconContainer}>
              <Iconify icon='mdi:comment-edit-outline' className={classes.motorReportIcon} />
            </div>
            <div className={classes.motorReportIconContainer}>
              <Iconify icon='mdi:information-circle' className={classes.motorReportIcon} />
            </div>
            <div className={classes.motorReportIconContainer} style={{ marginRight: 7 }}>
              <Iconify icon='mdi:pencil' className={classes.motorReportIcon} />
            </div>
          </div>
          <div className={classes.rowContainer}>
            {renderSlideRotateData(item)}
            <div className={classes.colContainer}>
              {renderDateInDateOut(item)}
              {renderPhases(item)}
            </div>
            <div className={classes.colContainer}>
              {item.bitData && renderDrillStringComp(item.bitData)}
              {item.motorData && renderDrillStringComp(item.motorData)}
            </div>
            {/* pie chart placeholder */}
            <div
              style={{
                visibility: 'hidden',
                background: 'green',
                borderRadius: '50%',
                width: 50,
                height: 50,
                marginRight: 20,
              }}></div>
            {/* <div
              className={classes.itemTextContainer}
              onClick={() => {
                editIndex.current = index
                setShowEditor(true)
              }}></div> */}
            <Tooltip title='Delete BHA' placement='bottom' classes={{ tooltip: classes.tooltip }}>
              <IconButton className={classes.itemIconContainer} onClick={() => onClickDelete(item)}>
                <Iconify icon='fa-regular:trash-alt' className={classes.deleteIconStyle} />
              </IconButton>
            </Tooltip>
            <Tooltip title='BHA summary' placement='bottom' classes={{ tooltip: classes.tooltip }}>
              <IconButton className={classes.itemIconContainer} onClick={() => showTerms(index)}>
                <Iconify icon='akar-icons:chevron-right' className={classes.navigateIconStyle} />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </ListItem>
    )
  }

  const RenderCreateUpdateModal = ({ data, submitFunction, cancelFunction }) => {
    let useInputData = true
    if (!data) useInputData = false
    if (useInputData === true) {
      if (data.hasOwnProperty('toolId') === false) useInputData = false
    }

    const formValidationSchema = yup.object({
      toolName: yup.string().required(),
    })

    let formData = [
      {
        tag: 'toolId',
        value: useInputData === true ? data.toolId : '',
        inputType: '-',
      },
      {
        tag: 'toolName',
        value: useInputData === true ? data.toolName : '',
        text: 'Tool Name',
        inputType: 'text',
        icon: 'text',
        iconFamily: 'Entypo',
      },
    ]

    const title = (useInputData ? 'Edit' : 'Add') + ' error model'

    return (
      <InputModal
        open={showEditor}
        onClose={cancelFunction}
        title={title}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={cancelFunction}
        validationSchema={formValidationSchema}
      />
    )
  }

  const submitFunction = async (data, formikActions) => {
    setShowEditor(false)
    if (!data) return false
    let isAdd = false
    if (editIndex.current < 0) {
      isAdd = true
    }
    let payload = { ...data }

    let saveResponse
    if (isAdd) {
      saveResponse = await addErrorModel(payload, createCancelToken())
    } else {
      saveResponse = await updateErrorModel(payload, updateCancelToken())
    }

    if (saveResponse.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: isAdd ? 'Error model failed to add' : 'Error model update failed',
      })
      formikActions?.setSubmitting(false)
      return false
    } else {
      setStatus({ show: true, severity: 'success', message: isAdd ? 'Error model added' : 'Error model updated' })
    }

    await getDsHeaderData()
    return true
  }

  const onXlsxExport = () => {
    if (!errorModelsAll) return
    if (!Array.isArray(errorModelsAll)) return
    if (errorModelsAll.length === 0) return

    let headers = []
    headers.push('#')
    headers.push('Tool Name')
    headers.push('Uploaded By')
    headers.push('Uploaded Date')
    headers.push('Term Name')
    headers.push('Vector')
    headers.push('Tie On')
    headers.push('Magnitude')
    headers.push('Units')
    headers.push('Formula')
    headers.push('Min Inc')
    headers.push('Max Inc')

    let ws = XLSX.utils.aoa_to_sheet([headers], {
      origin: 'A1',
    })

    let xlExport = []
    for (let i = 0; i < errorModelsAll.length; i++) {
      if (!errorModelsAll[i].hasOwnProperty('toolTerms')) continue
      if (!Array.isArray(errorModelsAll[i].toolTerms)) continue
      for (let j = 0; j < errorModelsAll[i].toolTerms.length; j++) {
        let newRow = []
        newRow.push(i + 1)
        newRow.push(errorModelsAll[i].toolName)
        newRow.push(errorModelsAll[i].uploadedBy)
        newRow.push(errorModelsAll[i].uploadedDate)
        newRow.push(errorModelsAll[i].toolTerms[j].termName)
        newRow.push(errorModelsAll[i].toolTerms[j].vector)
        newRow.push(errorModelsAll[i].toolTerms[j].tieOn)
        newRow.push(errorModelsAll[i].toolTerms[j].termValue)
        newRow.push(errorModelsAll[i].toolTerms[j].unit)
        newRow.push(errorModelsAll[i].toolTerms[j].formula)
        newRow.push(errorModelsAll[i].toolTerms[j].minInc)
        newRow.push(errorModelsAll[i].toolTerms[j].maxInc)
        xlExport.push(newRow)
      }
    }

    XLSX.utils.sheet_add_aoa(ws, xlExport, { origin: 'A2' })

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Error Models')

    XLSX.writeFile(wb, 'ErrorModels.xlsx')
  }

  const handleSearch = (text) => {
    if (text && typeof text === 'string' && text !== '') {
      const formattedQuery = text.toLowerCase()
      const filterList = _filter(sortedData, (item) => {
        return searchFunction(item, formattedQuery)
      })
      return filterList
    } else {
      return sortedData
    }
  }

  function searchFunction(item, searchStr) {
    if (item) {
      if (item === null) return false
      let bhaNumberRep = ''
      let bhaDesc = ''

      try {
        if (item.hasOwnProperty('bhaNumRep') === true) bhaNumberRep = item.bhaNumRep.toString().toLowerCase()
        if (item.hasOwnProperty('bhaDescription') === true) bhaDesc = item.bhaDescription.toString().toLowerCase()
      } catch (error) {
        return false
      }

      if (bhaNumberRep.includes(searchStr) || bhaDesc.includes(searchStr)) {
        return true
      }
    }
    return false
  }

  return (
    <React.Fragment>
      {showEditor ? (
        <RenderCreateUpdateModal
          data={editIndex.current >= 0 ? filteredList[editIndex.current] : {}}
          submitFunction={submitFunction}
          cancelFunction={handleClose}
        />
      ) : null}
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => confirmDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <div className={classes.searchContainer}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={{
            padding: '1px',
            margin: '1px',
            backgroundColor: 'rgb(17, 17, 17)',
          }}
        />
      </div>
      <div className={classes.listContainer} ref={listRef}>
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              className={classes.fixedListContainer}
              height={height}
              width={width}
              itemCount={!Array.isArray(filteredList) ? 0 : filteredList.length}
              itemSize={163}>
              {renderBhaItem}
            </FixedSizeList>
          )}
        </AutoSizer>
      </div>
      <div className={classes.menuIcon}>
        <MenuButton
          actions={[
            {
              icon: <AddIcon />,
              name: 'Add',
              onClick: () => {
                editIndex.current = -1
                // handleNewErrorModel(true)
              },
            },
            { icon: <RefreshIcon />, name: 'Refresh', onClick: () => getDsHeaderData() },
            { icon: <PrintIcon />, name: 'Print', onClick: onXlsxExport },
          ]}
        />
      </div>
      {status?.show ? (
        <Snackbar open={status?.show} autoHideDuration={2000} onClose={handleCloseStatus}>
          <MuiAlert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </MuiAlert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default DrillStringHeaderList
