import React, { useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { makeStyles } from '@material-ui/styles'
import { Skeleton } from '@material-ui/lab'

import ThreeDeeView from './ThreeDeeView'

import { currentPageAtom, currentWellAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    height: 'calc(100vh - 128px)',
  }
}))

const ThreeDeeCard = () => {
  const classes = useStyles()
  const currentWell = useRecoilValue(currentWellAtom)
  const setActivePage = useSetRecoilState(currentPageAtom)

  useEffect(() => {
    setActivePage(PAGE_KEYS.threeDViewKey)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.card}>
      {currentWell ? <ThreeDeeView well={currentWell} /> : <Skeleton height={'80vh'} variant='rect' animation='wave' />}
    </div>
  )
}

export default ThreeDeeCard