import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import Carousel from 'react-material-ui-carousel'
import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import { Skeleton } from '@material-ui/lab'
import RefreshIcon from '@material-ui/icons/Refresh'
import { Fab, Tooltip } from '@material-ui/core'
import DbCpuChart from './DatabaseHealthCpuChart'
import DbConnectionsList from './DatabaseConnectionsList'
import TopQueryList from './TopQueryList'
import { appColors } from 'utils'

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: 'rgb(19,62,96)',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
  mainContainer: {
    display: 'flex',
    overflow: 'hidden',
    height: 'calc(100vh - 130px)',
    maxHeight: 'calc(100vh - 130px)',
    border: '1px solid #000',
    borderRadius: '4px',
  },
  refreshIcon: {
    color: 'white',
    backgroundColor: '#192734', //appColors.accentColor,
    margin: '4px',
    padding: '12px',
    '&:hover': {
      backgroundColor: 'rgb(19, 62, 96)',
    },
    position: 'fixed', //'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  carouselItemContainer: {
    width: '100%',
    height: 'calc(80vh - 130px)',
  },
  headerText: {
    fontSize: 14,
    fontWeight: '500',
    color: appColors.headerTextColor,
    paddingRight: '8px',
  },
  itemText: {
    fontSize: 14,
    color: appColors.itemTextColor,
    border: '',
  },
  infoContainer: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    background: 'linear-gradient(to bottom, #2d2d2d 0%, #1c1c1c 100%)',
    borderRadius: 5,
    border: '1px solid',
  },
  itemTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '5px',
    flex: 1,
  },
  topContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    width: '100%',
  },
  itemColContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
  },
}))

const DatabaseHealthCard = () => {
  const _isMounted = useRef(false)
  const classes = useStyles()

  const { newCancelToken: dbCancelToken, cancelPreviousRequest: dbCancelReq } = useAxiosCancelToken()
  const [{ data: dbData, error: dbError }, getDbData] = useAxios({
    url: '/admin/diag/aws',
    cancelToken: dbCancelToken(),
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (dbError !== undefined && dbError !== null) {
      if (dbCancelReq(dbError)) return
    }
  }, [dbError]) // eslint-disable-line react-hooks/exhaustive-deps

  const refreshData = async () => {
    await getDbData()
  }

  const renderTextField = (text, value) => {
    return (
      <div className={classes.itemTextContainer}>
        <div className={classes.headerText}>{text} </div>
        <div className={classes.itemText}>{value}</div>
      </div>
    )
  }

  function getTotalConnections(connInfo) {
    if (!connInfo) return 0
    if (Array.isArray(connInfo) === false) return 0
    if (connInfo.length === 0) return 0

    let totalConnections = 0
    for (let i = 0; i < connInfo.length; i++) {
      totalConnections += connInfo[i].connectionCount
    }

    return totalConnections
  }

  return (
    <div className='mainContainer'>
      <Carousel autoPlay={false}>
        <div className={classes.carouselItemContainer}>
          {dbData ? (
            <DbCpuChart data={dbData.hasOwnProperty('cpuUtilization') ? dbData.cpuUtilization : []} />
          ) : (
            <Skeleton variant='rect' width='100%' height='100%' />
          )}
        </div>
        <div className={classes.carouselItemContainer}>
          {dbData ? (
            <DbConnectionsList data={dbData.hasOwnProperty('connectionInfo') ? dbData.connectionInfo : []} />
          ) : (
            <Skeleton variant='rect' width='100%' height='100%' />
          )}
        </div>
        <div className={classes.carouselItemContainer}>
          {dbData ? (
            <TopQueryList data={dbData.hasOwnProperty('topQueryStats') ? dbData.topQueryStats : []} />
          ) : (
            <Skeleton variant='rect' width='100%' height='100%' />
          )}
        </div>
      </Carousel>
      {dbData && (
        <div className={classes.infoContainer}>
          <div className={classes.topContainer}>
            <div className={classes.itemColContainer}>
              {renderTextField('Availible(mb): ', dbData.memoryUtilization.availableMB)}
              {renderTextField('Physical(mb): ', dbData.memoryUtilization.physicalMB)}
              {renderTextField('Mem State: ', dbData.memoryUtilization.systemMemoryState)}
            </div>
            <div className={classes.itemColContainer}>
              {renderTextField('Page File Size: ', dbData.memoryUtilization.pageFilePhysicalSizeMB)}
              {renderTextField('Page File Limit: ', dbData.memoryUtilization.pageFileCommitLimitMB)}
              {renderTextField(' ', ' ')}
            </div>
            <div className={classes.itemColContainer}>
              {renderTextField('Page Avail: ', dbData.memoryUtilization.pageFileAvailableMB)}
              {renderTextField('System Cache: ', dbData.memoryUtilization.systemCacheMB)}
              {renderTextField(' ', ' ')}
            </div>
            <div className={classes.itemColContainer}>
              {renderTextField('# Connections: ', getTotalConnections(dbData.connectionInfo))}
              {renderTextField(
                '# Applications: ',
                Array.isArray(dbData.connectionInfo) ? dbData.connectionInfo.length : 0,
              )}
              {renderTextField(' ', ' ')}
            </div>
          </div>
        </div>
      )}

      <Tooltip title='Refresh data' placement='left' classes={{ tooltip: classes.tooltip }}>
        <Fab aria-label='refresh data' className={classes.refreshIcon} onClick={() => refreshData()}>
          <RefreshIcon />
        </Fab>
      </Tooltip>
    </div>
  )
}

export default DatabaseHealthCard
