import merge from 'lodash/merge'
import React from 'react'
import axios from 'axios'
import qs from 'qs'
import { useRecoilState, atomFamily } from 'recoil'
import { useAuth0 } from '@auth0/auth0-react'
import fileDownload from 'js-file-download'

import { isDev } from '../env'

const logRequest = (options, accessToken, inputData, data, response) => {
  if (!isDev) {
    return
  }
  console.groupCollapsed('Request: ', options.url)
  console.groupCollapsed('Token')
  console.log(accessToken)
  console.groupEnd()

  if (Boolean(inputData)) {
    console.groupCollapsed('InputData: ')
    console.log(inputData)
    console.groupEnd()
  } else {
    console.groupCollapsed('Encoded Data: ')
    console.log(data)
    console.groupEnd()
  }

  if (response.headers['content-type'] !== 'application/zip') {
    console.groupCollapsed('Response: ')
    console.log(response.data)
    console.groupEnd()
  }

  console.groupEnd()
}

const initialState = {
  data: null,
  error: null,
  fetching: false,
  loading: true,
}

const request = async (options, dispatch, getAccessTokenSilently, userName, state) => {
  if (state.fetching) return
  if (userName === '') return

  const accessToken = await getAccessTokenSilently({
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    scope: 'all',
  })

  try {
    dispatch((oldState) => ({
      ...oldState,
      data: null,
      fetching: true,
      loading: true,
      error: null,
    }))

    const inputData = options.data
    const data = qs.stringify(
      { ...inputData, userName, productKey: process.env.REACT_APP_ICP_PRODUCT_KEY },
      { allowDots: true, arrayFormat: 'comma' },
    )

    const response = await axios({
      method: 'post',
      baseURL: process.env.REACT_APP_ICP_API,
      timeout: 60000,
      ...options,
      data,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })

    if (response.headers['content-type'] === 'application/zip') {
      const fileName = response.headers['content-disposition'].replace('attachment; filename=', '').replaceAll('"', '')
      fileDownload(response.data, fileName)
    }

    logRequest(options, accessToken, inputData, data, response)
    dispatch((oldState) => ({
      ...oldState,
      loading: false,
      fetching: false,
      data: response.data,
      error: null,
    }))
    return {
      loading: false,
      fetching: false,
      data: response.data,
      error: null,
    }
  } catch (error) {
    dispatch((oldState) => ({
      ...oldState,
      loading: false,
      fetching: false,
      data: null,
      error,
    }))
    return {
      loading: false,
      fetching: false,
      data: null,
      error,
    }
  }
}

const useAxiosAtomFamily = atomFamily({
  key: 'useAxiosAtomFamilyKey',
  default: initialState,
})

const useAxios = (options) => {
  options = React.useMemo(() => ({ ...options }), [JSON.stringify(options)]) // eslint-disable-line react-hooks/exhaustive-deps

  const useAxiosAtom = useAxiosAtomFamily(options.url)
  const [state, dispatch] = useRecoilState(useAxiosAtom)
  const { getAccessTokenSilently, user } = useAuth0()

  React.useEffect(() => {
    if (!options.manual) {
      request(options, dispatch, getAccessTokenSilently, user?.name || '', state)
    }
  }, [options]) // eslint-disable-line react-hooks/exhaustive-deps

  const customFetch = (data, cancelToken) => {
    const newOptions = merge(options, { data, cancelToken })
    return request(newOptions, dispatch, getAccessTokenSilently, user?.name || '', state)
  }

  return [state, customFetch]
}

export default useAxios
