import React from 'react'
import { Container, createStyles, makeStyles, Switch } from '@material-ui/core'
import { useRecoilState } from 'recoil'

import { allWellsAtom } from 'atoms'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingVertical: '16px',
      paddingBottom: '8px',
      paddingLeft: '8px',
      borderTop: '1px solid gray',
      borderBottom: '1px solid gray',
    },
    cardtitle: {
      font: '1em roboto',
      fontWeight: 'bold',
      paddingTop: '8px',
      paddingBottom: '8px',
    },
    text: {
      font: '0.8em roboto',
      paddingTop: '8px',
      paddingBottom: '8px',
    },
    colContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    title: {
      display: 'flex',
      fontSize: '14px',
      marginTop: '3px',
      fontWeight: 'bold',
      justifyContent: 'left',
    },
    subTitle: {
      fontSize: '14px',
      marginTop: '3px',
      marginLeft: '10px',
    },
    prefsRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingVertical: 12,
      paddingHorizontal: 16,
    },
    listItem: {
      padding: '0px 8px 0px 16px',
    },
    listItemText: {
      fontSize: '14px',
    },
  }),
)

const GlobalPrefs = () => {
  const classes = useStyles()
  const [allWells, setAllWells] = useRecoilState(allWellsAtom)

  return (
    <React.Fragment>
      <Container className={classes.root}>
        <div className={classes.cardtitle}>Global Preferences</div>
        <div>
          <div className={classes.title}>Wells</div>
          <div className={classes.prefsRow}>
            <div className={classes.subTitle}>Show All Wells</div>
            <Switch
              color='primary'
              checked={allWells}
              onClick={() => setAllWells(!allWells)}></Switch>
          </div>
        </div>
      </Container>
    </React.Fragment>
  )
}

export default GlobalPrefs
