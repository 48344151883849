import React from 'react'

import { GoogleMap, Marker, useLoadScript, InfoBox } from '@react-google-maps/api'
import { getMapIcon } from 'components/common/activitySelector'
import { MarkerClusterer } from '@react-google-maps/api'
import _map from 'lodash/map'
import { makeStyles } from '@material-ui/styles'
import { round } from '../../utils/numberFunctions'

const containerStyle = {
  width: '100%',
  height: '100%',
}

type Props = {
  latitude: string,
  longitude: string,
}

const useStyles = makeStyles((theme) => ({
  infoBox: {
    background: `white`,
    border: `1px solid #ccc`,
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: ' flex-start',
    position: 'relative',
  },
  callOutStyle: {
    display: 'flex',
    position: 'relative',
  },
  toolTipMainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  toolTipCol1Container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  toolTipCol2Container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: '6px',
  },
  markerHeaderText: {
    fontSize: 10,
    fontWeight: 'bold',
  },
  markerItemText: {
    fontSize: 10,
  },
}))

const LogisticsInfoBox = ({ showInfoBox, item }) => {
  const classes = useStyles()
  const position = {
    lat: parseFloat(item.lat),
    lng: parseFloat(item.long),
  }

  if (!showInfoBox) {
    return null
  }

  return (
    <InfoBox
      position={position}
      options={{
        closeBoxURL: '',
        disableAutoPan: true,
      }}>
      <div className={classes.infoBox}>
        <div className={classes.toolTipMainContainer}>
          <div className={classes.toolTipCol1Container}>
            <div className={classes.markerHeaderText}>Operator:</div>
            <div className={classes.markerHeaderText}>Well:</div>
            <div className={classes.markerHeaderText}>Job #:</div>
            <div className={classes.markerHeaderText}>Rig:</div>
            <div className={classes.markerHeaderText}>Region:</div>
            <div className={classes.markerHeaderText}>Status:</div>
            <div className={classes.markerHeaderText}>Lat:</div>
            <div className={classes.markerHeaderText}>Lng:</div>
          </div>
          <div className={classes.toolTipCol2Container}>
            <div className={classes.markerItemText}>{item.operator}</div>
            <div className={classes.markerItemText}>{item.actualWell}</div>
            <div className={classes.markerItemText}>{item.jobNum}</div>
            <div className={classes.markerItemText}>{item.rig}</div>
            <div className={classes.markerItemText}>
              {item.state} / {item.county}
            </div>
            <div className={classes.markerItemText}>{item.wellStatus}</div>
            <div className={classes.markerItemText}>{round(item.lat, 3)}</div>
            <div className={classes.markerItemText}>{round(item.long, 3)}</div>
          </div>
        </div>
      </div>
    </InfoBox>
  )
}

const LogisticsMarker = ({ clusterer, item, i }) => {
  const [infoBoxData, setInfoBoxData] = React.useState(null)

  const handleMarkerMouseOver = (location) => (event) => {
    setInfoBoxData(location)
  }

  const handleClick = () => {
  }

  return (
    <Marker
      key={i}
      tracksViewChanges={true}
      onMouseOver={handleMarkerMouseOver(item)}
      onMouseOut={handleMarkerMouseOver(null)}
      position={{ lat: parseFloat(item.lat), lng: parseFloat(item.long) }}
      icon={getMapIcon(item.wellStatus)}
      onClick={handleClick}
      clusterer={clusterer}>
      <LogisticsInfoBox item={item} showInfoBox={infoBoxData} />
    </Marker>
  )
}

const LogisticsMap = ({ latitude, longitude, logisticsData }: Props) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLEMAP_API_KEY,
  })

  const center = { lat: 29.7604, lng: -95.3698 }
  if (loadError) {
    // TODO: Better error handling
    console.error(loadError)
  }

  return (
    <>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={5}
          options={{
            streetViewControl: false,
            fullscreenControl: false,
            zoomControl: false,
            gestureHandling: 'greedy',
            mapTypeControlOptions: {
              // the comments below are required because 'google' exists,
              // but eslint can't see it:
              // eslint-disable-next-line no-undef
              style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
              // eslint-disable-next-line no-undef
              position: google.maps.ControlPosition.TOP_CENTER,
            },
          }}>
          <MarkerClusterer averageCenter maxZoom={10}>
            {(clusterer) =>
              logisticsData && Array.isArray(logisticsData)
                ? _map(logisticsData, (marker, i) => <LogisticsMarker item={marker} clusterer={clusterer} i={i} />)
                : null
            }
          </MarkerClusterer>
        </GoogleMap>
      ) : (
        <div>Loading Map</div>
      )}
    </>
  )
}

export default React.memo(LogisticsMap)
