import React from 'react'

import Avatar from '@material-ui/core/Avatar'
import { makeStyles } from '@material-ui/styles'

import { appColors } from 'utils'
import { GetActivityColor } from 'components/common/activitySelector'
import SectionList from 'components/common/SectionList'
import RigIcon from '../../assets/rigIcon.png'
import { getComponentColor, getDrillStringIcon } from 'components/common/drillStringFunctions'
import { round } from 'utils/numberFunctions'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  sectionList: {
    flex: '1 1 auto',
    display: 'flex',
    border: '1px solid #000',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
    paddingRight: '4px',
    paddingLeft: '4px',
    listStyleType: 'none !important',
  },
  itemHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: '5px',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '4px 0px 4px 0px',
  },
  itemRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0px 0px 0px 0px',
    padding: '4px 0px 4px 0px',
    width: '100%',
    // background: 'linear-gradient(to bottom, #2d2d2d 0%, #1c1c1c 100%)',
  },
  colContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  headerText: {
    fontSize: 14,
    fontWeight: '800',
    color: '#000',
    // color: appColors.headerTextColor,
    display: 'flex',
    lineHeight: '24px',
    marginRight: '32px',
  },
  itemText: {
    color: '#346578',
  },
  itemDescText: {
    color: appColors.itemTextColor,
    fontWeight: '700',
  },
  itemLabelText: {
    color: appColors.headerTextColor,
    height: '22px',
  },
  itemValueText: {
    color: appColors.itemTextColor,
    height: '22px',
  },
  avatarContainer: {
    margin: 5,
    marginRight: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'lightgrey',
    borderRadius: '50%',
  },
}))

const LogisticsList = ({ invListData }) => {
  const classes = useStyles()
  const userUnitsDepth = useUnits(UNITS_FOR.Depth)
  const userUnitsOD = useUnits(UNITS_FOR.Diameter)

  const renderListItem = (item, index) => {
    return (
      <React.Fragment>
        <div className={classes.itemRowContainer}>
          <div className={classes.avatarContainer}>
            <Avatar alt={item.compTypeText} src={getDrillStringIcon(item.compTypeText)}></Avatar>
          </div>
          <div className={classes.rowContainer} style={{ width: '35%' }}>
            <div className={classes.itemDescText}>{item.description}</div>
            <div className={classes.itemText}>{' - '}</div>
            <div className={classes.itemText} style={{ color: getComponentColor(item.compTypeText) }}>
              {item.compTypeText}
            </div>
          </div>
          <div className={classes.colContainer} style={{ width: '10%', marginLeft: '8px' }}>
            <div className={classes.itemLabelText}>SN:</div>
            <div className={classes.itemLabelText}>Make:</div>
            <div className={classes.itemLabelText}>Model:</div>
          </div>
          <div className={classes.colContainer} style={{ width: '20%' }}>
            <div className={classes.itemValueText}>{item.serialNum}</div>
            <div className={classes.itemValueText}>{item.make}</div>
            <div className={classes.itemValueText}>{item.model}</div>
          </div>
          <div className={classes.colContainer} style={{ width: '10%' }}>
            <div className={classes.itemLabelText}>OD:</div>
            <div className={classes.itemLabelText}>Status:</div>
            <div className={classes.itemLabelText}>Cnx Top:</div>
          </div>
          <div className={classes.colContainer} style={{ width: '15%' }}>
            <div className={classes.itemValueText}>
              {item.od} {` ${userUnitsOD}`}
            </div>
            <div style={{ color: item.dirty === false ? appColors.rotateColor : 'tomato' }}>
              {item.inHole === true ? 'In Hole' : item.dirty === true ? 'Dirty' : 'Clean'}
            </div>
            <div className={classes.itemValueText}>{item.cnxTop}</div>
          </div>
          <div className={classes.colContainer} style={{ width: '10%' }}>
            <div className={classes.itemLabelText}>Cnx Btm:</div>
            <div className={classes.itemLabelText}>Length:</div>
          </div>
          <div className={classes.colContainer} style={{ width: '15%' }}>
            <div className={classes.itemValueText}>{item.cnxBtm}</div>
            <div className={classes.itemValueText}>
              {round(item.length, 2)} {` ${userUnitsDepth}`}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  const renderListSectionHeader = (section) => {
    return (
      <div className={classes.itemHeaderContainer} style={{ backgroundColor: GetActivityColor(section.wellStatus) }}>
        <div className={classes.rowContainer}>
          <div className={classes.avatarContainer}>
            <Avatar alt={section.actualWell} src={RigIcon}></Avatar>
          </div>
          <div className={classes.rowContainer}>
            <div className={classes.headerText}>{`${section.rig} - ${section.jobNum}`}</div>
            <div className={classes.headerText}>{section.actualWell}</div>
            <div className={classes.headerText}>{section.operator}</div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.sectionList}>
      <SectionList
        sectionList={invListData}
        renderItem={renderListItem}
        renderSectionHeader={renderListSectionHeader}
      />
    </div>
  )
}

export default LogisticsList
