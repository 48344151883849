import { useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { createChartDataMultiWell } from 'components/common/depthVtimeFunctions'
import { currentWellAtom, currentPageAtom } from 'atoms'
import WellViewPage from './WellViewPage'
import { WellDepthTimeChart } from './WellDepthChart'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import useAxiosWithUnitFetch from 'components/common/hooks/useAxiosWithUnitFetch'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import { Icon as Iconify } from '@iconify/react'
import { makeStyles } from '@material-ui/styles'
import {
  Tooltip,
  IconButton,
} from '@material-ui/core'
import * as XLSX from 'xlsx'
import { getXlsxColumnLetter } from 'utils'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: '#192734',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '80%',
    minHeight: '80px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 128px)',
    maxHeight: 'calc(100vh - 128px)',
  },
  xlsxIcon: {
    minWidth: 24,
    position: 'fixed',
    top: '96px',
    // left: '175px',
    left: (props) => `calc(${props.leftPos}px + 24px)`, // float the icon relative to the ActionBar, so it appears fixed...
    zIndex: 2,
  },
  tooltip: {
    backgroundColor: 'rgb(19,62,96)',
    fontSize: '12px',
    fontFamily: 'Roboto',
    zIndex: 2,
  },
}))

const DepthTimePage = () => {
  const classes = useStyles()
  const [data, setData] = useState([])
  const currentWell = useRecoilValue(currentWellAtom)
  const userUnits = useUnits(UNITS_FOR.Depth)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const { newCancelToken: kpiCancelToken } = useAxiosCancelToken()
  const [, getKpiData] = useAxiosWithUnitFetch({
    url: '/kpi/depthVdays',
    cancelToken: kpiCancelToken(),
    manual: true,
  })

  useEffect(() => {
    setActivePage(PAGE_KEYS.depthTimeKey)
    getChartData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getChartData()
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  const getChartData = async () => {
    if (currentWell.length < 1) {
      await setData([])
      return
    }

    let payload = {
      wellList: currentWell,
    }

    payload.phaseFilter = ''

    const dataResponse = await getKpiData(payload)
    //console.log('getDepthVDays: ', dataResponse)
    if (dataResponse?.data) {
      setData(dataResponse.data)
    } else {
      // console.log('no DvD data available')
      setData([])
    }
  }

  const onXlsxExport = () => {
    // console.log('export raw ', data)
    let ws = undefined
    let colIndex = 0
    data.forEach((well) => {
      if (!ws) {
        ws = XLSX.utils.aoa_to_sheet([[well.actualWell]], { origin: 'A1' })
      } else {
        XLSX.utils.sheet_add_aoa(ws, [[well.actualWell]], { origin: `${getXlsxColumnLetter(colIndex)}1` })
      }
      XLSX.utils.sheet_add_aoa(ws, [['Days', `Depth (${userUnits})`, 'BHA', 'Phase', 'Activity']], {
        origin: `${getXlsxColumnLetter(colIndex)}2`,
      })
      XLSX.utils.sheet_add_aoa(
        ws,
        well.depthVDays.map((item) => [item.days, item.depth, item.bha > 0 ? item.bha : '', item.phase, item.activity]),
        { origin: `${getXlsxColumnLetter(colIndex)}3` },
      )
      colIndex += 5
    })

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Depth v Days')
    XLSX.writeFile(wb, 'depthVsDays.xlsx')
  }

  if (data.length < 1) return null

  return (
    <WellViewPage>
      <div className={classes.container}>
        <WellDepthTimeChart
          // title='Depth / Time'
          xAxisTitle={'Days'}
          yAxisTitle={`Depth (${userUnits})`}
          wellData={createChartDataMultiWell(data, null, null)}
          rawData={data}
        />
        <Tooltip title='Export to XLSX' placement='bottom' classes={{ tooltip: classes.tooltip }}>
          <IconButton className={classes.xlsxIcon} onClick={() => onXlsxExport()}>
            <Iconify icon='mdi:printer' style={{ color: 'white', fontSize: '24px' }} />
          </IconButton>
        </Tooltip>
      </div>
    </WellViewPage>
  )
}

export default DepthTimePage
