import React, { useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import DashboardContext from '../dashboardContext'

const useStyles = makeStyles((theme) => ({
  widgetMain: {
    display: 'grid',
    margin: '20px 0 20px 0',
    height: 'auto',
    minHeight: '94px',
  },
  widgetCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    backgroundColor: 'rgba(32,32,32,1.0)',
    border: '1px solid black',
    boxShadow: '0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);',
    padding: '8px',
  },
  widgethdr: {
    color: 'rgba(192,192,192,1.0)',
  },
  widgetbody: {
    color: 'rgba(52,201,235,1.0)',
    fontSize: '2.5em',
    fontWeight: 'bold',
    height: 'auto',
  },
  widgetunit: {
    color: 'rgba(192,192,192,1.0)',
    fontSize: '2em',
    marginLeft: '0.5em',
  },
}))

const Directive = (props) => {
  const classes = useStyles()
  const { data: rtData } = useContext(DashboardContext)
  const { unitsInfo } = rtData || {}

  const getDirective = () => {
    let directive = ''
    if (rtData) {
      const tf = (slideTF, svyInc) => {
        let tfVal = parseFloat(slideTF)
        let tfMod = ''
        const inc = parseFloat(svyInc)

        if (tfVal < 0.0) {
          tfVal += 360.0
        }
        if (tfVal > 360.0) {
          tfVal -= 360.0
        }
        if (inc <= 5.0) {
          tfMod = '° M'
        } else {
          if (tfVal > 180.0) {
            tfVal = 360.0 - tfVal
            tfMod = '° L'
          } else {
            tfMod = '° R'
          }
        }
        return tfVal.toFixed(0).toString() + tfMod
      }
      const slideLen = parseFloat(rtData.slideLength)
      const slideTF = parseFloat(rtData.slideTF)
      const distLeft = parseFloat(rtData.distLeftToSlide)
      if (
        !isNaN(slideLen) &&
        slideLen > 0 &&
        !isNaN(slideTF) &&
        slideTF >= -361.0 &&
        !isNaN(distLeft) &&
        distLeft > 0.0
      ) {
        if (unitsInfo.Depth === 'Usft' || unitsInfo.Depth === 'ft') {
          directive =
            'Slide ' +
            slideLen.toFixed(0).toString() +
            ' ft @ ' +
            tf(rtData.slideTF, rtData.surveyInc) +
            ' — Distance left to slide ' +
            rtData.distLeftToSlide +
            ' ft'
        } else {
          directive =
            'Slide ' +
            slideLen.toFixed(2).toString() +
            ' m @ ' +
            tf(rtData.slideTF, rtData.surveyInc) +
            ' — Distance left to slide ' +
            rtData.distLeftToSlide +
            ' m'
        }
      } else {
        directive = 'Drill Ahead'
      }
    }
    return directive
  }

  return (
    <div className={classes.widgetMain}>
      <div className={classes.widgetCard}>
        <div className={classes.widgethdr}>Drilling Directive</div>
        <div className={classes.widgetbody}>{getDirective()}</div>
      </div>
    </div>
  )
}

export default Directive
