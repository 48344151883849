import React, { useEffect, useRef, useState } from 'react'

import Tooltip from '@material-ui/core/Tooltip'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import AddIcon from '@material-ui/icons/Add'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import IconButton from '@material-ui/core/IconButton'
import RefreshIcon from '@material-ui/icons/Refresh'
import { Icon as Iconify } from '@iconify/react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import * as yup from 'yup'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { checkPermission } from '../userPermissions'

import { currentErrorModelAtom, userUserRoleAtom } from 'atoms'
import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import SimpleStyledList from 'components/common/SimpleStyledList'
import { appColors } from 'utils'
import InputModal from 'components/common/InputModal'
import ConfirmDialog from 'components/common/ConfirmDialog'
import MenuButton from 'components/common/MenuButton'

const useStyles = makeStyles((theme) => ({
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: 'calc(100vh - 130px)',
    maxHeight: 'calc(100vh - 130px)',
    border: '1px solid #000',
    borderRadius: '4px',
  },
  headerText: {
    fontSize: 14,
    fontWeight: '500',
    color: appColors.headerTextColor,
    width: '150px',
    textAlign: 'right',
    paddingRight: '8px',
  },
  itemText: {
    fontSize: 18,
    color: appColors.itemTextColor,
    border: '',
    width: '150px',
  },
  itemTextFormula: {
    fontSize: 18,
    color: appColors.itemTextColor,
    border: '',
    width: '150px',
  },
  titleCode: {
    fontSize: 18,
    fontWeight: '500',
    color: appColors.itemTextColor,
    textAlign: 'right',
  },
  titleDesc: {
    fontSize: 18,
    fontWeight: '500',
    color: appColors.headerTextColor,
    textAlign: 'left',
    paddingLeft: '8px',
  },
  deleteIconStyle: {
    color: '#C00000',
    fontSize: '24px',
  },
  itemMainContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '8px',
    marginBottom: '1px',
    border: `1px solid`,
    borderColor: appColors.itemBackColor,
    backgroundColor: 'inherit',
    '&:hover': {
      borderColor: appColors.accentColor,
    },
  },
  itemRowContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  itemRowContainerFixed: {
    display: 'flex',
    flexDirection: 'row',
    width: '150px',
  },
  itemColContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  itemRowContainerBottom: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    marginRight: 'auto',
  },
  itemIconContainer: {
    width: 100,
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 'auto',
    zIndex: 1,
  },
  tooltip: {
    backgroundColor: 'rgb(19,62,96)',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
  menuIcon: {
    backgroundColor: 'transparent',
    margin: '4px',
    padding: '12px',
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2) - 20,
  },
  returnButton: {
    display: 'flex',
  },
  itemTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  itemMainTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    flex: 1,
  },
  itemTextWrapContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
  },
}))

const ErrorTermsList = ({ history }) => {
  const _isMounted = useRef(false)
  const classes = useStyles()
  const editIndex = useRef(-1)
  const userRole = useRecoilValue(userUserRoleAtom)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [showEditor, setShowEditor] = useState(false)
  const currentErrorModel = useRecoilValue(currentErrorModelAtom)
  const setCurrentErrorModel = useSetRecoilState(currentErrorModelAtom)

  const { newCancelToken: newErrorTermCancelToken, cancelPreviousRequest: cancelPreviousErrorTermRequest } =
    useAxiosCancelToken()
  const [{ data: errorTerms, error: errorTermsError }, getErrorTerms] = useAxios({
    url: '/errorModels/getErrorModelTerms',
    data: {
      toolId: currentErrorModel.toolId,
    },
    cancelToken: newErrorTermCancelToken(),
  })

  const { newCancelToken: createCancelToken, cancelPreviousRequest: cancelCreateReq } = useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorCreate }, addErrorTerm] = useAxios({
    url: '/errorModels/addErrorModelTerm',
    manual: true,
    cancelToken: createCancelToken(),
  })

  const { newCancelToken: updateCancelToken, cancelPreviousRequest: cancelUpdateReq } = useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorUpdate }, updateErrorTerm] = useAxios({
    url: '/errorModels/updateErrorModelTerm',
    manual: true,
    cancelToken: updateCancelToken(),
  })

  const { newCancelToken: newDeleteCancelToken, cancelPreviousRequest: cancelDeleteReq } = useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorDelete }, deleteErrorTerm] = useAxios({
    url: '/errorModels/deleteErrorModelTerm',
    manual: true,
    cancelToken: newDeleteCancelToken(),
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
      cancelPreviousErrorTermRequest()
      cancelCreateReq()
      cancelUpdateReq()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorCreate !== undefined && errorCreate !== null) {
      if (cancelCreateReq(errorCreate)) return
    }
  }, [errorCreate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorUpdate !== undefined && errorUpdate !== null) {
      if (cancelUpdateReq(errorUpdate)) return
    }
  }, [errorUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorDelete !== undefined && errorDelete !== null) {
      if (cancelDeleteReq(errorDelete)) return
    }
  }, [errorDelete]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorTermsError !== undefined && errorTermsError !== null) {
      if (cancelPreviousErrorTermRequest(errorTermsError)) return
    }
  }, [errorTermsError]) // eslint-disable-line react-hooks/exhaustive-deps

  const onClickDelete = (item, index) => {
    editIndex.current = parseInt(index)
    setConfirm({
      show: true,
      title: 'Delete error term',
      text: `Are you sure you want to delete ${item?.termName}?`,
      itemIndex: editIndex.current,
    })
  }

  const renderItemHeader = (text, value) => {
    return (
      <div className={classes.itemRowContainer}>
        <div className={classes.titleCode}>{text} : </div>
        <div className={classes.titleDesc}>{value}</div>
      </div>
    )
  }

  const renderTextField = (text, value) => {
    return (
      <div className={classes.itemTextContainer}>
        <div className={classes.headerText}>{text} </div>
        <div className={classes.itemText}>{value}</div>
      </div>
    )
  }

  const renderItem = (item, index) => {
    return (
      <div
        className={classes.itemMainContainer}
        onClick={() => {
          editIndex.current = index
          setShowEditor(true)
        }}>
        <div className={classes.itemRowContainerFixed}>{renderItemHeader(index + 1, item.termName)}</div>

        <div className={classes.itemColContainer}>
          <div className={classes.itemMainTextContainer}>
            <div className={classes.itemTextWrapContainer}>
              {renderTextField('Vector: ', item.vector)}
              {renderTextField('Tie On: ', item.tieOn)}
            </div>
            <div className={classes.itemTextWrapContainer}>
              {renderTextField('Magnitude: ', item.termValue)}
              {renderTextField('Units: ', item.unit)}
            </div>
            <div className={classes.itemTextWrapContainer}>
              {renderTextField('Min Inc: ', item.minInc)}
              {renderTextField('Max Inc: ', item.maxInc)}
            </div>
          </div>
          <div className={classes.itemRowContainerBottom}>
            <div className={classes.itemTextContainer}>
              <div className={classes.headerText}>Formula: </div>
              <div className={classes.itemTextFormula}>{item.formula}</div>
            </div>
          </div>
        </div>
        {checkPermission('canDelete', userRole.roleAttributes?.permissions) ? (
          <Tooltip title='Delete term' placement='bottom' classes={{ tooltip: classes.tooltip }}>
            <IconButton
              className={classes.itemIconContainer}
              onClick={(event) => {
                event.stopPropagation()
                onClickDelete(item, index)
              }}>
              <Iconify icon='fa-regular:trash-alt' className={classes.deleteIconStyle} />
            </IconButton>
          </Tooltip>
        ) : null}
      </div>
    )
  }

  const RenderCreateUpdateModal = ({ data, submitFunction, cancelFunction }) => {
    let useInputData = true
    if (!data) useInputData = false
    if (useInputData === true) {
      if (data.hasOwnProperty('termName') === false) useInputData = false
    }

    const formValidationSchema = yup.object({
      termName: yup.string().required(),
      tieOn: yup.string().required(),
      vector: yup.string().required(),
      unit: yup.string().required(),
      formula: yup.string().required(),
      minInc: yup
        .string()
        .matches(/^[-+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed')
        .nullable(),
      maxInc: yup
        .string()
        .matches(/^[-+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed')
        .nullable(),
      termValue: yup
        .string()
        .matches(/^[-+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed')
        .required(),
    })

    let formData = [
      {
        tag: 'toolId',
        value: currentErrorModel.toolId,
        inputType: '-',
      },
      {
        tag: 'fileName',
        value: currentErrorModel.name,
        inputType: '-',
      },
      {
        tag: 'toolName',
        value: currentErrorModel.name,
        inputType: '-',
      },
      {
        tag: 'termId',
        value: useInputData === true ? data.termId : '',
        inputType: '-',
      },
      {
        tag: 'termName',
        value: useInputData === true ? data.termName : '',
        text: 'Name',
        inputType: 'text',
        icon: 'text',
        iconFamily: 'Entypo',
      },
      {
        tag: 'tieOn',
        value: useInputData === true ? data.tieOn : 's',
        text: 'Tie On',
        inputType: 'text',
        icon: 'text',
        iconFamily: 'Entypo',
      },
      {
        tag: 'vector',
        value: useInputData === true ? data.vector : 'i',
        text: 'Vector',
        inputType: 'text',
        icon: 'vector-line',
        iconFamily: 'MaterialCommunityIcons',
      },
      {
        tag: 'unit',
        value: useInputData === true ? data.unit : '-',
        text: 'Units',
        inputType: 'text',
        icon: 'ruler',
        iconFamily: 'Entypo',
      },
      {
        tag: 'termValue',
        value: useInputData === true ? data.termValue : '1',
        text: 'Value',
        inputType: 'text',
      },
      {
        tag: 'formula',
        value: useInputData === true ? data.formula : '1.0',
        text: 'Formula',
        inputType: 'text',
        icon: 'code-not-equal-variant',
        iconFamily: 'MaterialCommunityIcons',
      },
      {
        tag: 'minInc',
        value: useInputData === true ? data.minInc : '',
        text: 'Min Inc',
        inputType: 'text',
      },
      {
        tag: 'maxInc',
        value: useInputData === true ? data.maxInc : '',
        text: 'Max Inc',
        inputType: 'text',
      },
    ]

    const title = (useInputData ? 'Edit' : 'Add') + ' term'

    return (
      <InputModal
        open={showEditor}
        onClose={handleCloseEdit}
        title={title}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={cancelFunction}
        validationSchema={formValidationSchema}
      />
    )
  }

  const handleNew = () => {
    editIndex.current = -1
    setShowEditor(true)
  }

  const handleClose = () => {
    setShowEditor(false)
  }

  const handleCloseStatus = (event, reason) => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const handleCloseEdit = async (event, reason) => {
    setShowEditor(false)
  }

  const submitFunction = async (data, formikActions) => {
    setShowEditor(false)

    if (!data) return false

    let isAdd = false
    if (editIndex.current < 0) {
      isAdd = true
    }
    let payload = { ...data }

    let saveResponse
    if (isAdd) {
      saveResponse = await addErrorTerm(payload, createCancelToken())
    } else {
      saveResponse = await updateErrorTerm(payload, updateCancelToken())
    }

    if (saveResponse.error) {
      setStatus({ show: true, severity: 'error', message: 'Create term failed' })
      formikActions?.setSubmitting(false)
      return false
    } else {
      setStatus({ show: true, severity: 'success', message: isAdd ? 'Term created' : 'Term updated' })
    }

    await getErrorTerms()

    return true
  }

  const confirmDelete = async () => {
    if (confirm?.itemIndex >= 0) {
      const deleteResponse = await deleteErrorTerm({
        toolId: currentErrorModel.toolId,
        termId: errorTerms[confirm?.itemIndex].termId,
      })

      if (!deleteResponse.error) {
        setStatus({ show: true, severity: 'success', message: 'Term deleted successfully' })
        await getErrorTerms()
      } else {
        setStatus({ show: true, severity: 'error', message: 'Delete term failed' })
      }
    }
  }

  return (
    <React.Fragment>
      {showEditor ? (
        <RenderCreateUpdateModal
          data={editIndex.current >= 0 ? errorTerms[editIndex.current] : {}}
          submitFunction={submitFunction}
          cancelFunction={handleClose}
        />
      ) : null}
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => confirmDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <div className={classes.listContainer}>
        <div className={classes.returnButton}>
          <Button
            variant='outlined'
            color='primary'
            startIcon={<ArrowBackIcon />}
            onClick={() => {
              setCurrentErrorModel({
                name: '',
                toolId: -1,
              })
              editIndex.current = -1
              history.goBack()
            }}>
            Back to error models
          </Button>
        </div>
        <SimpleStyledList listItems={errorTerms} renderItem={renderItem}></SimpleStyledList>
      </div>

      <div className={classes.menuIcon}>
        <MenuButton
          actions={[
            {
              icon: <AddIcon />,
              name: 'Add',
              onClick: () => {
                editIndex.current = -1
                handleNew(true)
              },
            },
            { icon: <RefreshIcon />, name: 'Refresh', onClick: getErrorTerms },
          ]}
        />
      </div>
      {status?.show ? (
        <Snackbar open={status?.show} autoHideDuration={2000} onClose={handleCloseStatus}>
          <MuiAlert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </MuiAlert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default ErrorTermsList
