import React from 'react'
import { useRecoilValue } from 'recoil'

import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'

import { currentWellAtom } from 'atoms'
import ThreeDeeView from 'components/ThreeDeeView/ThreeDeeView'


const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%',
    width: '100%',
    backgroundColor: '#192734',
  },
}))

const ThreeDeeSummaryCard = ({ history }) => {
  const classes = useStyles()
  const currentWell = useRecoilValue(currentWellAtom)

  return (
    <div className={classes.card}>
      {currentWell ? <ThreeDeeView well={currentWell} onCard={true}/> : <Skeleton height={'80vh'} variant='rect' animation='wave' />}
    </div>
  )
}

export default ThreeDeeSummaryCard
