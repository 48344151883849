import React from 'react'

import { makeStyles } from '@material-ui/styles'
import BarChart from 'components/Analytics/BarChartCjs'
import { chartSeriesColors } from 'utils'

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
}))

const LogisticsChart = ({ logisticsData }) => {
  const classes = useStyles()
  const chartOptions = {
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (content) => {
            if (!logisticsData) return content.label
            return [content[0].label, logisticsData[content[0].dataIndex].jobNum]
          }
        }
      }
    }
  }

  const createChartData = (data) => {
    let output = {
      labels: [],
      datasets: [],
    }

    if (!data) {
      return output
    }

    if (logisticsData?.length > 0) {
      logisticsData.forEach((job) => {
        output.labels.push(job.rig)
      })

      output.datasets = logisticsData.reduce((distinctToolTypes, job) => {
        job.data.forEach((tool) => {
          if (distinctToolTypes.findIndex((element) => element.label === tool.compTypeText) < 0) {
            distinctToolTypes.push({
              label: tool.compTypeText,
              backgroundColor: chartSeriesColors[distinctToolTypes.length],
              maxBarThickness: 24,
              data: [],
            })
          }
        })

        return distinctToolTypes
      }, [])

      let initDataValues = new Array(output.labels.length).fill(0)
      output.datasets.forEach((dataset) => {
        dataset.data.push(...initDataValues)
      })

      logisticsData.forEach((job, i) => {
        job.data.forEach((tool) => {
          let seriesIndex = output.datasets.findIndex((dataset) => dataset.label === tool.compTypeText)
          if (seriesIndex >= 0) output.datasets[seriesIndex].data[i]++
        })
      })
    }

    return output
  }

  return (
    <div className={classes.chartContainer}>
      <BarChart
        wellData={createChartData(logisticsData)}
        title=''
        xAxisTitle='Rigs'
        yAxisTitle={``}
        chartOptions={chartOptions}
      />
    </div>
  )
}

export default LogisticsChart
