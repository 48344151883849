import React from 'react'
// import { makeStyles } from '@material-ui/styles'

import Page from '../common/Page'
import Space from '../common/Space'

// const useStyles = makeStyles((theme) => ({
//   title: {
//     margin: 'auto',
//     font: '24px Roboto',
//     color: 'rgb(52,201,235)',
//   },
// }))

type Props = {
  children: React.ReactNode,
}

const AnalyticsPage = ({ children }: Props) => {
  // const classes = useStyles()
  return (
    <Page maxWidth>
      <Space>
        {React.Children.map(children, (child) => {
          return child
        })}
      </Space>
    </Page>
  )
}

export default AnalyticsPage