import React, { useEffect } from 'react'

import ErrorTermsCard from './ErrorTermsCard'
import Page from '../common/Page'
import { currentPageAtom } from '../../atoms'
import { useSetRecoilState } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'

const ErrorTermsPage = ({ history }) => {
  const setActivePage = useSetRecoilState(currentPageAtom)

  useEffect(() => {
    setActivePage(PAGE_KEYS.errorTermsKey)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page maxWidth>
      <ErrorTermsCard history={history} />
    </Page>
  )
}

export default ErrorTermsPage
