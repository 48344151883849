const flashDiv = (id, highlightColor, normalColor) => {
  let element = document.getElementById(id)
  if (element) {
    // an alternate method:
    // setTimeout(() => {
    //   element.style.color = highlightColor
    //   console.log('t1')
    //   setTimeout(() => {
    //     element.style.color = normalColor
    //     console.log('t2')
    //   }, 500)
    // }, 750)

    // selected method:
    element.style.color = highlightColor
    setTimeout(() => {
      element.style.color = normalColor
    }, 1000)
  }
}

export { flashDiv }
