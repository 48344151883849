import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Scatter } from 'react-chartjs-2'
import zoomPlugin from 'chartjs-plugin-zoom'
import annotationPlugin from 'chartjs-plugin-annotation'

import { appColors, isoStringToNumeric } from 'utils'

ChartJS.register(LinearScale, CategoryScale, PointElement, LineElement, Tooltip, Legend, Title)
ChartJS.register(zoomPlugin, annotationPlugin)

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: appColors.itemBackColor,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
  },
  chartContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    paddingBottom: '16px',
  },
  title: {
    color: appColors.itemTextColor,
    fontSize: '20px',
    fontWeight: 600,
  },
  legend: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '32px',
  },
}))

const GradientPlugIn = {
  id: 'GradientPlugIn',
  beforeDraw: (chart, args, options) => {
    const ctx = chart.ctx
    const canvas = chart.canvas
    const chartArea = chart.chartArea
    if (chartArea) {
      const chartWidth = chartArea.right - chartArea.left
      const chartHeight = chartArea.bottom - chartArea.top

      var gradient = canvas
        .getContext('2d')
        .createLinearGradient(chartWidth / 2, chartArea.top, chartWidth / 2, chartArea.bottom)
      gradient.addColorStop(0, '#141414')
      gradient.addColorStop(1, '#282828')

      ctx.fillStyle = gradient
      ctx.fillRect(chartArea.left, chartArea.top, chartWidth, chartHeight)
    }
  },
}

ChartJS.register(GradientPlugIn)

export const DbCpuChart = ({ data }) => {
  const classes = useStyles()
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      annotation: {},
      legend: {
        position: 'bottom',
        display: true,
      },
      title: {
        display: true,
        text: 'CPU Usage',
        color: appColors.itemTextColor,
        font: {
          size: 20,
        },
      },
      tooltip: {
        mode: 'nearest',
        intersect: false,
        callbacks: {
          title: function (tooltipItem) {
            return tooltipItem[0].dataset.label
          },
          label: function (context) {
            return [
              `${new Date(context.parsed.x).toLocaleTimeString('en-US')} Date/Time`,
              ` ${context.parsed.y} CPU(%)`,
            ]
          },
        },
        // filter the tooltip list to only show one point when they are very close together
        filter: function (tooltipItem, index) {
          if (index > 0) return false
          return true
        },
      },
      hover: {
        mode: 'nearest',
        intersect: false,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy',
        },
        limits: {
          x: {
            min: 'original',
            max: 'original',
          },
          y: {
            min: 'original',
            max: 'original',
          },
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
        },
      },
    },
    scales: {
      x: {
        beginAtZero: false,
        title: {
          display: false,
        },
        grid: {
          color: '#404040',
        },
        ticks: {
          callback: function (value) {
            return new Date(value).toLocaleTimeString('en-US')
          },
        },
      },
      y: {
        beginAtZero: true,
        reverse: false,
        title: {
          display: true,
          text: 'CPU (%)',
          color: appColors.headerTextColor,
        },
        grid: {
          color: '#404040',
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
  }

  const segColor = (ctx, defaultColor) => {
    return ctx.p0.raw.color ? ctx.p0.raw.color : defaultColor
  }

  function getLineColor(inputVal, baseColor) {
    if (inputVal > 80) return 'tomato'
    if (inputVal > 50) return 'orange'

    return baseColor
  }

  const chartJsData = {
    datasets: [
      {
        type: 'line',
        label: 'Other',
        data: [],
        borderColor: 'cyan',
        backgroundColor: 'cyan',
        borderWidth: 3,
        pointRadius: 0,
        pointHoverRadius: 0,
        segment: {
          borderColor: (ctx) => segColor(ctx, 'cyan'),
          borderWidth: 3,
        },
      },
      {
        type: 'line',
        label: 'SQL',
        data: [],
        borderColor: '#59FF00CF',
        backgroundColor: '#59FF00CF',
        borderWidth: 3,
        pointRadius: 0,
        pointHoverRadius: 0,
        segment: {
          borderColor: (ctx) => segColor(ctx, '#59FF00CF'),
          borderWidth: 3,
        },
      },
    ],
  }

  if (Array.isArray(data)) {
    for (let i = data.length - 1; i >= 0; i--) {
      const element = data[i]
      chartJsData.datasets[0].data.push({
        x: parseFloat(isoStringToNumeric(element.eventTime)),
        y: element.other,
        color: getLineColor(element.other, 'cyan'),
      })

      chartJsData.datasets[1].data.push({
        x: parseFloat(isoStringToNumeric(element.eventTime)),
        y: element.sql,
        color: getLineColor(element.sql, '#59FF00CF'),
      })
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.chartContainer}>
        <Scatter options={options} data={chartJsData} plugins={[GradientPlugIn]} />
      </div>
    </div>
  )
}

export default DbCpuChart
