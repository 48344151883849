import React, { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'

import Page from '../../common/Page'
import RoleAdminCard from './RoleAdminCard'
import { currentPageAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'

const RoleAdminPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)

  useEffect(() => {
    setActivePage(PAGE_KEYS.adminRolesKey)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page maxWidth style={{ display: 'flex', flexGrow: 1, height: '100%' }}>
      <div>
        <RoleAdminCard />
      </div>
    </Page>
  )
}

export default RoleAdminPage
