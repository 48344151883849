import React, { useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import { useAuth0 } from '@auth0/auth0-react'
import { useSetRecoilState } from 'recoil'
import { currentPageAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.subtleBackground,
    minWidth: theme.spacing(30),
    maxWidth: theme.spacing(100),
    marginTop: '32px',
  },
  container: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
}))

const Profile = () => {
  const { loading, user } = useAuth0()
  const classes = useStyles()
  const setActivePage = useSetRecoilState(currentPageAtom)

  useEffect(() => {
    setActivePage(PAGE_KEYS.userProfileKey)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (loading || !user) {
    return <div>Loading...</div>
  }

  return (
    <Container className={classes.container}>
      <Card className={classes.card}>
        <CardContent className={classes.container}>
          <Avatar className={classes.large} alt={user.name} src={user.picture} />
          <Container className={classes.infoContainer}>
            <Typography>Nickname: {user.nickname}</Typography>
            <Typography>UserName: {user.name}</Typography>
            <Typography>Email: {user.email}</Typography>
          </Container>
        </CardContent>
      </Card>
    </Container>
  )
}

export default Profile
