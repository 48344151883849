import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Avatar, Badge, Tooltip, IconButton, ListItem } from '@material-ui/core'
import { Icon as Iconify } from '@iconify/react'
import { appColors } from '../../theme'
import SearchBar from 'material-ui-search-bar'
import Carousel from 'react-material-ui-carousel'
import multiParse from 'utils/multiParse'
import ConfirmDialog from 'components/common/ConfirmDialog'
import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import WellSeekersMap from './WellSeekersMap'
import { unescapeHtml } from '../../../utils/htmlSymbolHandling'
import WellSeekersStackedChart from './WellSeekersStackedChart'
import { filter as _filter } from 'lodash'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { Skeleton } from '@material-ui/lab'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns' // choose your lib

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: 'rgb(19,62,96)',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
  itemMainContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '4px 0px 4px 0px ',
    borderRadius: 5,
    marginLeft: '8px',
    background: 'linear-gradient(to bottom, #2d2d2d 0%, #1c1c1c 100%)',
    marginBottom: '4px',
    width: 'calc(100% - 10px)', // 8px for marginLeft, 2px for left & right border
    border: `1px solid`,
    borderColor: appColors.itemBackColor,
    backgroundColor: appColors.itemBackColor,
    '&:hover': {
      borderColor: appColors.accentColor,
    },
  },
  itemTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 4,
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
  itemTextWrapContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    flex: 3,
  },
  itemLabelText: {
    fontSize: 14,
    fontWeight: '500',
    color: appColors.headerTextColor,
    width: '150px',
    textAlign: 'right',
    paddingRight: '8px',
  },
  itemText: {
    fontSize: 14,
    fontWeight: '400',
    color: appColors.itemTextColor,
    border: '',
  },
  avatarContainer: {
    margin: 5,
    marginRight: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'lightgrey',
    borderRadius: '50%',
  },
  badgeStyleGreen: {
    '&::after': {
      position: 'absolute',
      backgroundColor: '#44b700',
      color: '#44b700',
      bottom: 0,
      right: 0,
      width: '25%',
      height: '25%',
      borderRadius: '50%',
      animation: '1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  badgeStyleRed: {
    '&::after': {
      position: 'absolute',
      backgroundColor: 'red',
      color: 'red',
      bottom: 0,
      right: 0,
      width: '25%',
      height: '25%',
      borderRadius: '50%',
      // animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  searchContainer: {
    backgroundColor: appColors.itemBackColor,
    padding: '8px',
  },
  carouselPane: {
    height: 'calc(70vh + 36px)',
  },
  carouselItemContainer: {
    width: '100%',
    height: '70vh',
  },
  mapContainer: {
    display: 'flex',
    width: '100%',
    height: '69vh',
  },
  listContainer: {
    display: 'flex',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(60vh - 128px)',
    maxHeight: 'calc(60vh - 128px)',
    borderRadius: '4px',
    backgroundColor: theme.palette.itemBackground,
    width: '100%',
  },
  fixedListContainer: {
    display: 'flex',
    borderRadius: '4px',
    backgroundColor: theme.palette.itemBackground,
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#136cb9', //'darkgrey', //'rgba(0,0,0,.1)',
      outline: '1px solid #1679d0',
    },
    overflow: 'auto',
  },
  listIconStyle: {
    color: '#C0C0C0',
    fontSize: '24px',
  },
}))

const getDate = () => {
  let today = new Date()
  let dd = String(today.getDate()).padStart(2, '0')
  let mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
  let yyyy = today.getFullYear()
  let todayDate = yyyy + '-' + mm + '-' + dd

  let priorDate = new Date(new Date().setDate(today.getDate() - 30))
  let p_dd = String(priorDate.getDate()).padStart(2, '0')
  let p_mm = String(priorDate.getMonth() + 1).padStart(2, '0') //January is 0!
  let p_yyyy = priorDate.getFullYear()
  priorDate = p_yyyy + '-' + p_mm + '-' + p_dd
  return { todayDate, priorDate }
}

const WellSeekerAdminCard = () => {
  const _isMounted = useRef(false)
  const classes = useStyles()
  const [dateTo, setDateTo] = useState(() => {
    const { todayDate } = getDate()
    return todayDate
  })
  const [dateFrom, setDateFrom] = useState(() => {
    const { priorDate } = getDate()
    return priorDate
  })
  const [searchText, setSearchText] = useState('')
  const [filteredList, setFilteredList] = useState([])
  const [orgIconList, setOrgIconList] = useState({})
  const [defaultIcon, setDefaultIcon] = useState('')
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const wellUsersforMap = useRef([])
  const sortedData = useRef([])
  const listRef = useRef()

  //cancel token for orgs
  const { newCancelToken: iconCancelToken, cancelPreviousRequest: iconCancelRequest } = useAxiosCancelToken()
  const [{ data: dataOrgIcons }] = useAxios({
    url: '/admin/getOrgIcons',
    cancelToken: iconCancelToken(),
  })

  const { newCancelToken: CancelToken, cancelPreviousRequest } = useAxiosCancelToken()
  const [{ data }, getWellSeekerUsers] = useAxios({
    url: '/wellSeekerUsers/usageData',
    // manual: true,
    cancelToken: CancelToken(),
  })

  const { newCancelToken: activateCancelToken, cancelPreviousRequest: activateCancelRequest } = useAxiosCancelToken()
  const [{ error: activationError }, activateLicense] = useAxios({
    url: '/wellSeekerUsers/licenseActivation',
    cancelToken: activateCancelToken(),
    manual: true,
  })

  const { newCancelToken: dataUsageCancelToken } = useAxiosCancelToken()
  const [{ data: dataUsageStats }, getWellSeekerStats] = useAxios({
    url: '/wellSeekerUsers/usageStats',
    manual: true,
    cancelToken: dataUsageCancelToken(),
  })

  useEffect(() => {
    const initialFetch = async () => {
      await getWellSeekerStats({
        dateTo: dateTo,
        dateFrom: dateFrom,
      })
    }
    initialFetch()

    _isMounted.current = true

    return () => {
      _isMounted.current = false
      cancelPreviousRequest()
      activateCancelRequest()
      iconCancelRequest()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const getNewStats = async () => {
      await getWellSeekerStats({
        dateTo: dateTo,
        dateFrom: dateFrom,
      })
    }
    getNewStats()
  }, [dateFrom, dateTo]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data) {
      const usersWithLocation = _filter(data, (item) => {
        return item.lat !== 0 && item.lon !== 0
      })
      wellUsersforMap.current = [...usersWithLocation]
      sortedData.current = [...data] // can't .sort a state variable...
      if (sortedData.current.length > 0) {
        sortedData.current.sort((firstEl, secondEl) =>
          firstEl.companyID !== secondEl.companyID
            ? firstEl.companyID > secondEl.companyID
              ? 1
              : -1
            : firstEl.daysSinceActive > secondEl.daysSinceActive
            ? 1
            : -1,
        )
      }
      const filtered = handleSearch(searchText)
      setFilteredList(filtered)
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (dataOrgIcons) {
      const mfpBoundary = dataOrgIcons.substring(2, dataOrgIcons.search('Content')).trim()
      let parts = multiParse(Buffer.from(dataOrgIcons), mfpBoundary)
      setOrgIconList(parts)

      if (!defaultIcon && orgIconList) {
        for (let i = 0; i < orgIconList.length; i++) {
          if (orgIconList[i].filename === 'innova') {
            let base64ImageString = 'data:image/*;base64,' + orgIconList[i].data
            setDefaultIcon(base64ImageString)
          }
        }
      }
    }
  }, [dataOrgIcons]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      const filtered = handleSearch(searchText)
      setFilteredList(filtered)
    }
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activationError !== undefined && activationError !== null) {
      if (activateCancelRequest(activationError)) return
    }
  }, [activationError]) // eslint-disable-line react-hooks/exhaustive-deps

  function searchFunction(user, searchStr) {
    //Needs to be old style search function due to deactivated field
    if (user) {
      if (user === null) return false
      let companyIDLwr = ''
      let companyNameLwr = ''
      let compCodeLwr = ''
      let compNameLwr = ''
      let versionLwr = ''
      let userLwr = ''
      let emailLwr = ''

      try {
        if (user.hasOwnProperty('companyID') === true) companyIDLwr = user.companyID.toString().toLowerCase()
        if (user.hasOwnProperty('companyName') === true) companyNameLwr = user.companyName.toString().toLowerCase()
        if (user.hasOwnProperty('computerCode') === true) compCodeLwr = user.computerCode.toString().toLowerCase()
        if (user.hasOwnProperty('computerName') === true) compNameLwr = user.computerName.toString().toLowerCase()
        if (user.hasOwnProperty('wsVersion') === true) versionLwr = user.wsVersion.toString().toLowerCase()
        if (user.hasOwnProperty('userName') === true) userLwr = user.userName.toString().toLowerCase()
        if (user.hasOwnProperty('email') === true) emailLwr = user.email.toString().toLowerCase()
      } catch (error) {
        return false
      }

      if (
        companyIDLwr.includes(searchStr) ||
        companyNameLwr.includes(searchStr) ||
        compCodeLwr.includes(searchStr) ||
        compCodeLwr.includes(searchStr) ||
        compNameLwr.includes(searchStr) ||
        versionLwr.includes(searchStr) ||
        userLwr.includes(searchStr) ||
        emailLwr.includes(searchStr) ||
        user.ipAddress.includes(searchStr)
      ) {
        return true
      }

      if (user.hasOwnProperty('active') === true) {
        if (searchStr === 'deactivated' && user.active === 'FALSE') return true
        if (searchStr === 'active' && user.active === '') return true
      }
    }

    return false
  }

  const handleSearch = (text) => {
    if (text) {
      const formattedQuery = text.toLowerCase()
      const filterList = _filter(sortedData.current, (item) => {
        return searchFunction(item, formattedQuery)
      })
      return filterList
    } else {
      return sortedData.current
    }
  }

  const onClickActivate = (item, toDeactivate) => {
    setConfirm({
      show: true,
      title: 'Change User Status',
      text: `Are you sure you want to change ${item?.userName}?`,
      item: item,
      toDeactivate: toDeactivate,
    })
  }

  const onClickChangeStatus = async () => {
    let item = confirm?.item

    if (item) {
      const activateResponse = await activateLicense({
        computerName: item.computerName,
        computerCode: item.computerCode,
        companyId: item.companyID,
        deactivate: confirm?.toDeactivate,
      })

      if (!activateResponse.error) {
        await getWellSeekerUsers()
        // setStatus({ show: true, severity: 'success', message: 'User activated successfully' })
      } else {
        // setStatus({ show: true, severity: 'error', message: 'activate user failed' })
      }
    }
  }

  const renderTextField = (text, value) => {
    return (
      <div className={classes.itemTextContainer}>
        <div className={classes.itemLabelText}>{text} </div>
        <div className={classes.itemText}>{value}</div>
      </div>
    )
  }

  const getCompanyIcon = (orgName) => {
    if (orgIconList.length > 0 && orgName) {
      const org = orgIconList.find((item) => item.filename === orgName.toLowerCase())
      if (org) {
        let base64ImageString = 'data:image/*;base64,' + org.data
        return base64ImageString
      } else {
        return defaultIcon
      }
    } else {
      return ''
    }
  }

  const renderWellSeekerRow = (props) => {
    const { index, style } = props
    const item = filteredList[index]
    return (
      <ListItem style={style} disableGutters={true}>
        <div className={classes.itemMainContainer}>
          <div className={classes.itemTextContainer}>
            <div className={classes.avatarContainer}>
              {item.active === 'FALSE' ? (
                <Badge className={classes.badgeStyleRed}>
                  <Avatar alt={item.userName} src={getCompanyIcon(item.companyID)} />
                </Badge>
              ) : (
                <Badge className={classes.badgeStyleGreen}>
                  <Avatar alt={item.userName} src={getCompanyIcon(item.companyID)} />
                </Badge>
              )}
            </div>

            <div className={classes.itemTextWrapContainer}>
              {renderTextField('Username: ', item.email)}
              {renderTextField('IP Address:: ', item.ipAddress)}
              {renderTextField('Version: ', item.wsVersion)}
              {renderTextField('Company: ', unescapeHtml(item.companyName))}
            </div>
            <div className={classes.itemTextWrapContainer}>
              {renderTextField('Company ID: ', item.companyID)}
              {renderTextField('Computer Code: ', item.computerCode)}
              {renderTextField('Computer Name: ', item.computerName)}
              {renderTextField('Date: ', item.dateTime)}
            </div>
          </div>
          {item.active === 'FALSE' ? (
            <Tooltip title='Toggle Active Status' placement='bottom' classes={{ tooltip: classes.tooltip }}>
              <IconButton className={classes.itemIconContainer} onClick={() => onClickActivate(item, false)}>
                <Iconify icon='ant-design:check-circle-outlined' color='red' className={classes.listIconStyle} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title='Toggle Active Status' placement='bottom' classes={{ tooltip: classes.tooltip }}>
              <IconButton className={classes.itemIconContainer} onClick={() => onClickActivate(item, true)}>
                <Iconify icon='ant-design:check-circle-outlined' color='green' className={classes.listIconStyle} />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </ListItem>
    )
  }

  return (
    <div>
      <Carousel autoPlay={false} className={classes.carouselPane}>
        <div className={classes.carouselItemContainer}>
          {dataUsageStats ? (
            <WellSeekersStackedChart userData={dataUsageStats} />
          ) : (
            <Skeleton variant='rect' width='100%' height='100%' />
          )}
        </div>
        <div className={classes.carouselItemContainer}>
          {wellUsersforMap.current[0] ? (
            <div className={classes.mapContainer}>
              <WellSeekersMap
                wellSeekerUsers={wellUsersforMap.current}
                latitude={wellUsersforMap.current[0].lat}
                longitude={wellUsersforMap.current[0].lon}
              />
            </div>
          ) : null}
        </div>
      </Carousel>

      <ConfirmDialog
        title={confirm?.title}
        open={confirm?.show}
        setOpen={() => setConfirm({ show: false })}
        onConfirm={() => onClickChangeStatus()}>
        {confirm?.text}
      </ConfirmDialog>

      <div className={classes.searchContainer}>
        <div className={classes.rowContainer}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              value={dateFrom}
              onChange={(date) => {
                let dt = new Date(Date.parse(date))
                setDateFrom(dt.toISOString().substring(0, 10))
              }}
              format='MM/dd/yyyy'
              style={{ flex: 1 }}
            />
            <KeyboardDatePicker
              value={dateTo}
              onChange={(date) => {
                let dt = new Date(Date.parse(date))
                setDateTo(dt.toISOString().substring(0, 10))
              }}
              format='MM/dd/yyyy'
              style={{ flex: 1 }}
            />
          </MuiPickersUtilsProvider>
          <SearchBar
            value={searchText}
            onChange={(searchText) => setSearchText(searchText)}
            onCancelSearch={() => setSearchText('')}
            style={{
              padding: '1px',
              margin: '1px 1px 10px 1px',
              backgroundColor: '#111',
              borderColor: 'black',
              flex: 3,
            }}
          />
        </div>
        <div className={classes.listContainer} ref={listRef}>
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList
                className={classes.fixedListContainer}
                height={height}
                width={width}
                itemCount={filteredList.length}
                itemSize={90}>
                {renderWellSeekerRow}
              </FixedSizeList>
            )}
          </AutoSizer>
        </div>
      </div>
    </div>
  )
}

export default WellSeekerAdminCard
