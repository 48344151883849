import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  paper: {
    //width: '600px',
    backgroundColor: theme.palette.itemBackground,
  },
      
}))

const ConfirmDialog = (props) => {
  const { title, children, open, setOpen, onConfirm } = props
  const classes = useStyles()
  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby='confirm-dialog'>
      <DialogTitle id='confirm-dialog'>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => setOpen(false)} color='secondary'>
          No
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            setOpen(false)
            onConfirm()
          }}
          color='primary'>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default ConfirmDialog
