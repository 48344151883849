import React, { useEffect, useRef } from 'react'
import { Vector3, CatmullRomCurve3, DoubleSide, Shape, ExtrudeGeometry } from 'three'
import { ConvexGeometry } from 'three/examples/jsm/geometries/ConvexGeometry'
import { Sphere, Box, Cylinder } from '@react-three/drei'
import { rotateAroundWorldAxis } from './WorkSight'
import { TextLabel, labelColor } from './ThreeDeeView'

export const Targets = ({ targets, display }) => {
  if (!display) return null
  if (!Array.isArray(targets)) return null
  if (targets.length === 0) return null

  return targets.map((tgt, i) => {
    return (
      <group key={`tgt${i}`}>
        <TextLabel
          key={`tgtLabel${i}`}
          label={`${tgt.text}`}
          size={1}
          color={labelColor}
          position={new Vector3(tgt.x + 2, tgt.y, tgt.z + 2)}
        />
        <Target tgt={tgt} key={`tgtGeo${i}`} />
      </group>
    )
  })
}
const RectangleTarget = ({ tgt }) => {
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current === null) return

    rotateTarget(ref.current, tgt)
  }, [ref, tgt])

  return (
    <group ref={ref} key={`rectTarget${tgt.name}`}>
      <Box args={[tgt.length, tgt.height, tgt.width]}>
        <meshBasicMaterial attach='material' color={tgt.color} transparent={true} opacity={0.5} />
      </Box>
    </group>
  )
}
const CircleTarget = ({ tgt }) => {
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current === null) return

    rotateTarget(ref.current, tgt)
  }, [ref, tgt])

  return (
    <group ref={ref} key={`circleTarget${tgt.name}`}>
      <Cylinder args={[tgt.radius, tgt.radius, tgt.thickness, 32, 1, false, tgt.arcStart, tgt.arcLength]}>
        <meshBasicMaterial attach='material' color={tgt.color} transparent={true} opacity={0.5} />
      </Cylinder>
    </group>
  )
}
const EllipseTarget = ({ tgt }) => {
  const ref = useRef(null)

  useEffect(() => {
    if (ref.current === null) return
    rotateAroundWorldAxis(ref.current, 'x', new Vector3(Math.PI / 2.0, 0, 0))
    rotateAroundWorldAxis(ref.current, 'y', new Vector3(0, Math.PI / 2.0, 0))
    rotateTarget(ref.current, tgt)
  }, [ref, tgt])

  function CalcGeo() {
    const shape = new Shape()
    let numPoints = 100
    let angleInterval = Math.abs(tgt.arcLength) / numPoints
    for (let i = 0; i < numPoints; i++) {
      let segmentAngle = tgt.arcStart + angleInterval * i
      let cosAngle = Math.cos(segmentAngle)
      let sinAngle = Math.sin(segmentAngle)
      let signCos = 1
      let signSin = 1
      if (cosAngle < 0) signCos = -1
      if (sinAngle < 0) signSin = -1

      let x = signSin * Math.sqrt(Math.pow(tgt.semiMajor, 2) * Math.pow(sinAngle, 2))
      let y = signCos * Math.sqrt(Math.pow(tgt.semiMinor, 2) * Math.pow(cosAngle, 2))

      if (i === 0) shape.moveTo(x, y)
      if (i > 0) shape.lineTo(x, y)
    }

    const extrudeSettings = {
      steps: 2,
      depth: tgt.thickness,
      bevelEnabled: false,
    }

    const geo = new ExtrudeGeometry(shape, extrudeSettings)
    return geo
  }

  return (
    <group ref={ref} key={`ellipseTarget${tgt.name}`}>
      <mesh visible geometry={CalcGeo()}>
        <meshBasicMaterial attach='material' color={tgt.color} transparent={true} opacity={0.5} />
      </mesh>
    </group>
  )
}
const Target = ({ tgt }) => {
  if (tgt.geometry === 'point') {
    return (
      <group position={[tgt.x, tgt.y, tgt.z]} key={`pointTgt${tgt.name}`}>
        <Sphere args={[2, 16, 16]}>
          <meshBasicMaterial attach='material' color={tgt.color} transparent={true} opacity={0.75} />
        </Sphere>
      </group>
    )
  }

  if (tgt.geometry === 'rectangle') {
    return (
      <group position={[tgt.x, tgt.y, tgt.z]} key={`recTgtGroup${tgt.name}`}>
        <RectangleTarget tgt={tgt} />
      </group>
    )
  }

  if (tgt.geometry === 'circle') {
    return (
      <group position={new Vector3(tgt.x, tgt.y, tgt.z)} key={`circTgtGroup${tgt.name}`}>
        <CircleTarget tgt={tgt} />
      </group>
    )
  }

  if (tgt.geometry === 'polygon' && tgt.isLeaseLine && Array.isArray(tgt.points) && tgt.points.length > 1) {
    let pts = []
    tgt.points.forEach((pt) => pts.push(new Vector3(pt.top.x, pt.top.y, pt.top.z)))

    return pts.map((pt, i) => {
      if (i === 0) return null
      const path = new CatmullRomCurve3([pt, pts[i - 1]])
      return (
        <mesh visible key={`polyPoint${tgt.name}${i}`}>
          <tubeGeometry args={[path, 64, 1, 16, false]} />
          <meshStandardMaterial color={tgt.color} side={DoubleSide} />
        </mesh>
      )
    })
  }

  if (tgt.geometry === 'polygon' && !tgt.isLeaseLine && Array.isArray(tgt.points) && tgt.points.length > 1) {
    let pts = []
    tgt.points.forEach((pt) => {
      pts.push(new Vector3(pt.top.x, pt.top.y, pt.top.z))
      pts.push(new Vector3(pt.bottom.x, pt.bottom.y, pt.bottom.z))
    })

    try {
      const geo = new ConvexGeometry(pts)
      return (
        <mesh visible geometry={geo} key={`polyTgtGroup${tgt.name}`}>
          <meshBasicMaterial attach='material' color={tgt.color} transparent={true} opacity={0.5} />
        </mesh>
      )
    } catch (err) {
      console.log({ err })
      return null
    }
  }

  if (tgt.geometry === 'ellipse') {
    return (
      <group position={new Vector3(tgt.x, tgt.y, tgt.z)} key={`ellipseTgtGroup${tgt.name}`}>
        <EllipseTarget tgt={tgt} />
      </group>
    )
  }

  return null
}
function rotateTarget(tgtObj, tgt) {
  let rotation = new Vector3(0, tgt.rotation, 0)
  rotateAroundWorldAxis(tgtObj, 'y', rotation)

  rotation = new Vector3(0, 0, tgt.dipAngle)
  rotateAroundWorldAxis(tgtObj, 'z', rotation)

  rotation = new Vector3(0, tgt.dipAzi, 0)
  rotateAroundWorldAxis(tgtObj, 'y', rotation)
}
