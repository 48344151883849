import React, { useEffect, useState } from 'react'
import { Container, createStyles, makeStyles } from '@material-ui/core'
import { mdiBarrel } from '@mdi/js'
import { Icon } from '@mdi/react'

import { GetOilPrice } from 'components/common/apiCalls'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingVertical: '16px',
      paddingBottom: '8px',
      borderTop: '1px solid gray',
      borderBottom: '1px solid gray',
    },
    cardtitle: {
      font: '1em roboto',
      fontWeight: 'bold',
      paddingTop: '8px',
      paddingBottom: '8px',
    },
    text: {
      font: '0.8em roboto',
      paddingTop: '8px',
      paddingBottom: '8px',
    },
    priceIcon: {
      marginRight: '8px',
    },
    priceInfo: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    priceContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    colContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    colFirst: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'right',
    },
    title: {
      display: 'flex',
      fontSize: '14px',
      marginTop: '3px',
      fontWeight: 'bold',
      justifyContent: 'right',
    },
    subTitle: {
      fontSize: '14px',
      marginTop: '3px',
      marginLeft: '10px',
    },
  }),
)

const OilPriceCard = () => {
  const classes = useStyles()
  const [oilPrices, setOilPrices] = useState({})

  const getOilPrices = async () => {
    let brent = await GetOilPrice('BRENT_CRUDE_USD')
    let wti = await GetOilPrice('WTI_USD')
    if (brent && wti && brent.data && wti.data) {
      let brentDate = new Date(brent.data?.created_at)
      let wtiDate = new Date(wti.data?.created_at)
      brent.data.localTime = brentDate?.toLocaleTimeString()
      wti.data.localTime = wtiDate?.toLocaleTimeString()
      setOilPrices({ brent, wti })
    }
  }

  useEffect(() => {
    getOilPrices()
  }, [])

  return (
    <React.Fragment>
      <Container className={classes.root}>
        <div className={classes.cardtitle}>Oil Price</div>
        <div className={classes.priceInfo}>
          <Icon path={mdiBarrel} size={1} color='black' className={classes.priceIcon}></Icon>
          <div className={classes.priceContainer}>
            <div className={classes.colFirst}>
              <div className={classes.title}>Brent:</div>
              <div className={classes.title}>WTI:</div>
            </div>
            <div className={classes.colContainer}>
              <div className={classes.subTitle}>{oilPrices?.brent?.data?.formatted}</div>
              <div className={classes.subTitle}>{oilPrices?.wti?.data?.formatted}</div>
            </div>

            <div className={classes.colContainer}>
              <div className={classes.subTitle}>{oilPrices?.brent?.data?.localTime}</div>
              <div className={classes.subTitle}>{oilPrices?.wti?.data?.localTime}</div>
            </div>
          </div>
        </div>
      </Container>
    </React.Fragment>
  )
}

export default OilPriceCard
