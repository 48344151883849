import Page from 'components/common/Page'
import React from 'react'
import ThreeDeeCard from './ThreeDeeCard'

const ThreeDeeViewWrapper = () => {

  return (
    <Page maxWidth>
      <ThreeDeeCard />
    </Page>
  )
}

export default ThreeDeeViewWrapper
 