import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import Page from 'components/common/Page'
import Space from 'components/common/Space'

import { useRecoilValue } from 'recoil'
import { currentWellAtom } from 'atoms'

const useStyles = makeStyles((theme) => ({
  container: {
    //paddingTop: theme.spacing(1),
  },
}))

const WellViewPage = ({ children, className }) => {
  const classes = useStyles()
  const currentWell = useRecoilValue(currentWellAtom)

  return (
    <Page className={classes.container} maxWidth>
      <Space>
        {currentWell ? (
          React.Children.map(children, (child) => {
            return child
          })
        ) : (
          <div />
        )}
      </Space>
    </Page>
  )
}

export default WellViewPage
