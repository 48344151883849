import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DashboardContext from '../dashboardContext'

const useStyles = makeStyles((theme) => ({
  widgetMain: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gridAutoRows: '94px',
    gridGap: '20px',
    margin: '20px 0 20px 0',
  },
  widgetCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    backgroundColor: 'rgba(32,32,32,1.0)',
    border: '1px solid black',
    boxShadow: '0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);',
    padding: '8px',
  },
  widgetHeader: {
    color: 'rgba(192,192,192,1.0)',
  },
  widgetValue: {
    color: 'rgba(52,201,235,1.0)',
    fontSize: '2.5em',
    fontWeight: 'bold',
  },
  widgetUnit: {
    color: 'rgba(192,192,192,1.0)',
    fontSize: '2em',
    marginLeft: '0.5em',
  },
}))

const SteeringStatus = (props) => {
  const classes = useStyles()
  const { data: rtData } = useContext(DashboardContext)
  const { unitsInfo } = rtData || {}

  return (
    <div className={classes.widgetMain}>
      <div className={classes.widgetCard}>
        <div className={classes.widgetHeader}>Bit MD</div>
        <div>
          <span className={classes.widgetValue}>{rtData?.bitMD}</span>
          <span className={classes.widgetUnit}>{unitsInfo?.Depth}</span>
        </div>
      </div>
      <div className={classes.widgetCard}>
        <div className={classes.widgetHeader}>Hole MD</div>
        <div>
          <span className={classes.widgetValue}>{rtData?.holeMD}</span>
          <span className={classes.widgetUnit}>{unitsInfo?.Depth}</span>
        </div>
      </div>
      <div className={classes.widgetCard}>
        <div className={classes.widgetHeader}>Rig State</div>
        <div className={classes.widgetValue}>{rtData?.rigState}</div>
      </div>
      <div className={classes.widgetCard}>
        <div className={classes.widgetHeader}>DIFFP</div>
        <div>
          <span className={classes.widgetValue}>{rtData?.diffP}</span>
          <span className={classes.widgetUnit}>{unitsInfo?.Press}</span>
        </div>
      </div>
      <div className={classes.widgetCard}>
        <div className={classes.widgetHeader}>WOB</div>
        <div>
          <span className={classes.widgetValue}>{rtData?.wobAvg}</span>
          <span className={classes.widgetUnit}>{unitsInfo?.Wt}</span>
        </div>
      </div>
      <div className={classes.widgetCard}>
        <div className={classes.widgetHeader}>ROP</div>
        <div>
          <span className={classes.widgetValue}>{rtData?.ropAvg}</span>
          <span className={classes.widgetUnit}>
            {unitsInfo?.Depth}
            {unitsInfo ? '/hr' : ''}
          </span>
        </div>
      </div>
    </div>
  )
}

export default SteeringStatus
