import { userPrefsAtom } from 'atoms'
import React from 'react'
import { useRecoilValue } from 'recoil'

import useAxios from './useAxios'

const useAxiosWithUnitFetch = (options) => {
  const userPrefs = useRecoilValue(userPrefsAtom)
  const [lastUnit, updateUnit] = React.useState(userPrefs)
  const [axiosState, axiosRefetch] = useAxios(options)

  React.useEffect(() => {
    if (lastUnit === userPrefs || userPrefs === '') {
      return
    }

    axiosRefetch()
    updateUnit(userPrefs)
  }, [lastUnit, updateUnit, axiosRefetch, userPrefs])

  return [axiosState, axiosRefetch]
}

export default useAxiosWithUnitFetch
