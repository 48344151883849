import React, { useEffect, useRef } from 'react'
import _map from 'lodash/map'

import { withRouter } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import { GoogleMap, useLoadScript, MarkerClusterer } from '@react-google-maps/api'
import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import WellsMapMarker from './WellsMapMarker'

import { setLayer } from './utils'
import { wellsMapLayersSelectorAtom, STATES, COUNTIES, SHALE_PLAYS, CONVENTIONAL_PLAYS } from './atoms/WellsMapAtoms'
import { useRecoilValue } from 'recoil'
import { Skeleton } from '@material-ui/lab'
import { currentWellDetailsAtom } from 'atoms'

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    display: 'flex',
    width: '100%',
  },
}))

const containerStyle = {
  width: '100%',
}

const WellsMap = ({ history, wells }) => {
  const _isMounted = useRef(false)
  const classes = useStyles()

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLEMAP_API_KEY,
  })
  const [gMap, setMap] = React.useState(null)
  const selLayers = useRecoilValue(wellsMapLayersSelectorAtom)

  const stateLayer = useRef({})
  const countyLayer = useRef({})
  const shalePlayLayer = useRef({})
  const conventionalPlayLayer = useRef({})
  const currentWell = useRecoilValue(currentWellDetailsAtom)

  const { newCancelToken: newCancelStates, cancelPreviousRequest: cancelStatesReq } = useAxiosCancelToken()
  const [{ data: states }] = useAxios({
    url: '/maps/geoJSON/states',
    cancelToken: newCancelStates(),
  })
  const { newCancelToken: newCancelCounties, cancelPreviousRequest: cancelCountiesReq } = useAxiosCancelToken()
  const [{ data: counties }] = useAxios({
    url: '/maps/geoJSON/counties',
    cancelToken: newCancelCounties(),
  })
  const { newCancelToken: newCancelShale, cancelPreviousRequest: cancelShaleReq } = useAxiosCancelToken()
  const [{ data: shalePlays }] = useAxios({
    url: '/maps/geoJSON/shalePlays',
    cancelToken: newCancelShale(),
  })
  const { newCancelToken: newCancelConv, cancelPreviousRequest: cancelConvReq } = useAxiosCancelToken()
  const [{ data: conventionalPlays }] = useAxios({
    url: '/maps/geoJSON/conventionalPlays',
    cancelToken: newCancelConv(),
  })

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
      cancelStatesReq()
      cancelCountiesReq()
      cancelShaleReq()
      cancelConvReq()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      setLayer(stateLayer, STATES, selLayers, gMap, states, 'red', 'dark red')
    }
  }, [states, gMap, selLayers])

  useEffect(() => {
    if (_isMounted.current) {
      setLayer(countyLayer, COUNTIES, selLayers, gMap, counties, 'blue', 'aqua')
    }
  }, [counties, gMap, selLayers])

  useEffect(() => {
    if (_isMounted.current) {
      setLayer(shalePlayLayer, SHALE_PLAYS, selLayers, gMap, shalePlays, 'black', 'gray')
    }
  }, [shalePlays, gMap, selLayers])

  useEffect(() => {
    if (_isMounted.current) {
      setLayer(
        conventionalPlayLayer,
        CONVENTIONAL_PLAYS,
        selLayers,
        gMap,
        conventionalPlays,
        'darkgreen',
        'darkolivegreen',
      )
    }
  }, [conventionalPlays, gMap, selLayers])

  useEffect(() => {
    if (gMap) {
      if (currentWell.actualWell.length > 0) {
        // eslint-disable-next-line no-undef
        gMap.setCenter({ lat: currentWell.lat, lng: currentWell.lng })
        gMap.setZoom(10)
      } else {
        gMap.setZoom(5)
      }
    }
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  // const handleChangeMultiple = (event) => {
  //   const { value } = event.target
  //   const values = []

  //   _each(LayerNames, (layerName) => {
  //     if (value.includes(layerName)) {
  //       values.push(layerName)
  //     }
  //   })

  //   setSelectedLayers(values)
  // }

  //-------------
  const onLoad = React.useCallback((gMap) => {
    setMap(gMap)
  }, [])

  const onZoom = () => {
    if (gMap) {
      if (gMap.getZoom() >= 12) {
        // eslint-disable-next-line no-undef
        gMap.setMapTypeId(google.maps.MapTypeId.HYBRID)
      } else {
        // eslint-disable-next-line no-undef
        gMap.setMapTypeId(google.maps.MapTypeId.ROADMAP)
      }
    }
  }

  if (loadError) {
    // TODO: Better error handling
    console.error(loadError)
    return null
  }

  const center = { lat: 29.7604, lng: -95.3698 }

  return (
    <>
      {isLoaded ? (
        <div className={classes.mapContainer}>
          <GoogleMap
            onLoad={onLoad}
            onZoomChanged={onZoom}
            mapContainerStyle={containerStyle}
            zoom={5}
            center={center}
            options={{
              streetViewControl: false,
              gestureHandling: 'greedy',
              fullscreenControl: false,
              zoomControl: false,
            }}>
            <MarkerClusterer averageCenter maxZoom={10}>
              {(clusterer) =>
                _map(wells, (well) => <WellsMapMarker key={JSON.stringify(well)} well={well} clusterer={clusterer} />)
              }
            </MarkerClusterer>
          </GoogleMap>
        </div>
      ) : (
        <Skeleton width={350} height={250} variant='rect' />
      )}
    </>
  )
}

export default React.memo(withRouter(WellsMap))
