export function numberWithCommas(x) {
  if (typeof x !== 'number') return ''
  let parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export function replaceEscapeSymbols(str) {
  str = str.replace('&dq;', '"')
  str = str.replace('&sq;', "'")
  str = str.replace('&amp;', '&')
  str = str.replace('&gt;', '>')
  str = str.replace('&lt;', '<')
  str = str.replace('?', '')
  return str
}

export function splitDateString(inputStr, delimiter) {
  let splitStr = inputStr.split(delimiter)

  let output = { day: -1, month: -1, year: -1 }
  splitStr.forEach((val, i) => {
    if (val.substring(0, 1) === '0') val = val.substring(1)

    if (i === 0) output.year = val
    if (i === 1) output.month = val
    if (i === 2) output.day = val
  })

  return output
}

export function createShortDate(inputDate, delimiter, usFormat) {
  let output = ''
  if (inputDate !== '') {
    let dateByComponents = splitDateString(inputDate, delimiter)
    if (dateByComponents.day > 0 && dateByComponents.month > 0) {
      if (usFormat === true) {
        output = dateByComponents.month + '/' + dateByComponents.day
      } else {
        output = dateByComponents.year + '/' + dateByComponents.month
      }
    }
  }

  return output
}

export function toQueryString(params) {
  return (
    '?' +
    Object.entries(params)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&')
  )
}
