import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import omit from 'lodash/omit'

import each from 'lodash/each'
import merge from 'lodash/merge'
import React, { useState, useEffect } from 'react'
import MaterialTable from 'material-table'

import tableIcons from './tableIcons'

const Table = ({ columns, data, loading, rowStyle, options, ...rest }) => {
  const [tableData, setTableData] = React.useState([])

  React.useEffect(() => {
    const cleanedTableData = map(tableData, (entry) => omit(entry, ['tableData']))
    if (isEqual(data, cleanedTableData) || !Boolean(data)) {
      return
    }
    const clonedData = cloneDeep(data)

    setTableData(clonedData)
  }, [data, tableData])

  const defaultRowStyle = (row, index) => {
    if (rowStyle) {
      return rowStyle(row, index)
    }

    if (index % 2 === 0) {
      return {}
    } else {
      return {
        backgroundColor: '#EEE',
      }
    }
  }

  const defaultOptions = {
    filtering: true,
    pageSize: 25,
    pageSizeOptions: [25, 50, 100],
    rowStyle: defaultRowStyle,
  }

  const useOptions = merge(defaultOptions, options)

  const [statefulColumns, setStatefulColumns] = useState([])

  useEffect(() => {
    const createRealColumns = (cols) => {
      const realColumns = []
      each(cols, (column) => {
        const lookup = {}

        each(data, (d) => {
          const value = d[column.field]
          lookup[value] = value
        })

        realColumns.push({ ...column, lookup })
      })
      return realColumns
    }

    setStatefulColumns(createRealColumns(columns))
  }, [columns, data])

  return (
    <MaterialTable
      icons={tableIcons}
      columns={statefulColumns}
      isLoading={loading}
      data={tableData}
      options={useOptions}
      {...rest}
    />
  )
}

export default Table
