import React from 'react'
import map from 'lodash/map'
import { Router, Route, Switch, useHistory } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'

import { useAuth0 } from '@auth0/auth0-react'

const AUTH_HOME_ROUTE = '/well-list'

const redirectToHome = (isAuthenticated, pathname, history) => {
  if (isAuthenticated && pathname === '/') {
    history.push(AUTH_HOME_ROUTE)
  }
}

const MainRouter = ({ children, publicRoutes, privateRoutes }) => {
  const unlisten = React.useRef(null)
  const { isAuthenticated } = useAuth0()
  const history = useHistory()

  React.useEffect(() => {
    unlisten.current = history.listen(({ pathname }) => {
      redirectToHome(isAuthenticated, pathname, history)
    })

    redirectToHome(isAuthenticated, history.location.pathname, history)

    return () => {
      unlisten.current()
    }
  })

  return (
    <Router history={history}>
      {children}
      <Switch>
        {map(publicRoutes, (props) => (
          <Route key={props.path} {...props} />
        ))}
        {map(privateRoutes, (props) => (
          <PrivateRoute key={props.path} {...props} />
        ))}
      </Switch>
    </Router>
  )
}

export default MainRouter
