import React from 'react'

import { Card } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import ErrorTermsList from './ErrorTermsList'

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: '#192734',
  },
}))

const ErrorTermsCard = ({ history }) => {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <ErrorTermsList history={history} />
    </Card>
  )
}

export default ErrorTermsCard
