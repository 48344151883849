import { ObjToPrimitive, landRig, offshoreRig, scale } from './ThreeDeeView'

export function Rig({ wellData, isOffshore, display }) {
  if (!display) return null
  if (!wellData?.hasOwnProperty('data')) return null
  if (!Array.isArray(wellData.data)) return null
  if (wellData.data.length === 0) return null

  return ObjToPrimitive({
    url: isOffshore ? offshoreRig : landRig,
    position: { x: wellData.data[0].x, y: 0, z: wellData.data[0].z },
    scale: isOffshore ? 2 * scale : 0.5 * scale,
    offset: { x: -1, y: 0, z: 1 },
    rotation: { x: -Math.PI / 2, y: 0, z: 0 },
  })
}
