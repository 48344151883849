import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Bar } from 'react-chartjs-2'
import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import { createShortDate } from 'utils/stringFunctions'
import { appColors } from 'utils'
import { getDailyStats } from './dailyActivityFunctions'
import Skeleton from '@material-ui/lab/Skeleton'
import useUnits from '../../common/hooks/useUnits'

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    padding: '8px 8px 8px 16px',
  },
}))

const FootagePerDayChart = ({ currentWell }) => {
  const _isMounted = useRef(false)
  const classes = useStyles()
  const units = useUnits('DEPTH')
  const [data, setData] = useState([])

  const { newCancelToken: CancelToken, cancelPreviousRequest } = useAxiosCancelToken()
  const [{ error }, getWellData] = useAxios({
    url: '/well/getWellData',
    data: { wellName: currentWell, dailyActivity: true },
    cancelToken: CancelToken(),
    manual: true,
  })

  useEffect(() => {
    if (error !== undefined && error !== null) {
      if (cancelPreviousRequest(error)) return
    }
  }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      const dataFetch = async () => {
        const payload = {
          wellName: currentWell,
          dailyActivity: true,
        }
        const response = await getWellData(payload)
        if (response?.data) {
          setData(response.data)
        }
      }
      dataFetch()
    }
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  const createChartData = () => {
    if (!data) return { labels: [], datasets: [] }

    const dailyData = getDailyStats(data.dailyActivity)
    const chartData = {
      labels: dailyData.map((day) =>
        Array.isArray(day.dates)
          ? createShortDate(day.dates.length > 0 ? day.dates[0] : '1900-01-01', '-', true)
          : 'null',
      ),
      datasets: [
        {
          data: dailyData.map((day) => day.rotateDistance),
          backgroundColor: appColors.rotateColor,
          label: `Rotate (${units})`,
          maxBarThickness: 24,
        },
        {
          data: dailyData.map((day) => day.slideDistance),
          backgroundColor: appColors.slideColor,
          label: `Slide (${units})`,
          maxBarThickness: 24,
        },
      ],
    }

    return chartData
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scaleShowLabels: false,
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  }

  return data ? (
    <div className={classes.chartContainer}>
      <Bar type='bar' options={chartOptions} data={createChartData()} />
    </div>
  ) : (
    <React.Fragment>
      <div className={classes.container}>
        <Skeleton height={'80vh'} variant='rect' animation='wave' />
      </div>
    </React.Fragment>
  )
}

export default FootagePerDayChart
