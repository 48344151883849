

type Options = {
  baseColor: String,
  altColor: String,
  baseFont: String,
  altFont: String,
}

class FlashCanvasText {
  constructor(options: Options) {
    this.altColor = options.altColor
    this.baseColor = options.baseColor
    this.altFont = options.altFont
    this.baseFont = options.baseFont
    this.color = this.baseColor
    this.font = this.baseFont
    this.flashing = false
    this.toggle = true
    this.time = 0
    this.interval = 500
  }

  startFlash(time) {
    if (this.flashing === false) {
      this.time = time
      this.flashing = true
    }
    // this.interval = setInterval(() => {
    //   this.color === this.baseColor ? (this.color = this.altColor) : (this.color = this.baseColor)
    // }, 500)
    //this.color = this.altColor
  }

  stopFlash() {
    this.flashing = false
    this.toggle = true
    this.color = this.baseColor
    this.font = this.baseFont
  }

  // eslint-disable-next-line no-unused-vars
  render(ctx, text, x, y) {
    this.ctx = ctx
    if (this.flashing) {
      if (this.toggle) {
        this.color = this.baseColor
        this.font = this.baseFont
      } else {
        this.color = this.altColor
        this.font = this.altFont
      }
      const time = performance.now()
      if ((time - this.time) >= this.interval) {
        this.time = time
        this.toggle = !this.toggle
      }
    } else {
      this.color = this.baseColor
      this.font = this.baseFont
    }
    this.ctx.font = this.font
    this.ctx.fillStyle = this.color
    this.ctx.fillText(text, x, y)
    // console.log('fT render') // enable this if you want to see 85 frames/sec...
  }
}

export default FlashCanvasText
