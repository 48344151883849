import { TextLabel, labelColor } from './ThreeDeeView'

import React from 'react'
import { Vector3 } from 'three'

export const WellNames = ({ refData, offsetData, display, offsetsOn }) => {
  if (!display) return null
  if (!Array.isArray(refData)) return null
  if (refData.length === 0) return null

  let output = []
  if (Array.isArray(refData[0].data) && refData[0].data.length > 0) {
    let finalPos = refData[0].data[refData[0].data.length - 1]
    let aziRad = refData[0].svyData[refData[0].data.length - 1].azi * (Math.PI / 180)
    output.push(
      <TextLabel
        label={refData[0].name}
        size={2}
        color={labelColor}
        key={`refNameLabel`}
        position={new Vector3(finalPos.x, finalPos.y - 2, finalPos.z)}
        rotation={new Vector3(0, Math.PI * 2.0 - aziRad, 0)}
      />,
    )
  }

  if (!Array.isArray(offsetData)) return output.length > 0 ? output : null
  if (offsetData.length === 0) return output.length > 0 ? output : null
  if (!offsetsOn) return output.length > 0 ? output : null

  offsetData.forEach((osWell, index) => {
    if (Array.isArray(osWell.data) && osWell.data.length > 0) {
      let finalPos = osWell.data[osWell.data.length - 1]
      let aziRad = osWell.svyData[osWell.data.length - 1].azi * (Math.PI / 180)
      output.push(
        <TextLabel
          label={osWell.name}
          size={2}
          color={labelColor}
          key={osWell.name}
          position={new Vector3(finalPos.x, finalPos.y - 2, finalPos.z)}
          rotation={new Vector3(0, Math.PI * 2.0 - aziRad, 0)}
        />,
      )
    }
  })

  return output
}
