import CostIcon from '../assets/CostIcons/cost.png'
import RepairIcon from '../assets/CostIcons/repair.png'
import DiscountIcon from '../assets/CostIcons/discount.png'

export function getCostIcon(item) {
  let sn = ''
  let value = 0

  if (item.hasOwnProperty('sn')) sn = item.sn
  if (item.hasOwnProperty('serialNum')) sn = item.serialNum
  if (item.hasOwnProperty('value')) value = item.value

  if (sn !== '') return RepairIcon
  if (value < 0) return DiscountIcon

  return CostIcon
}

export function testInputValidArray(input) {
  if (!input) return false
  if (!Array.isArray(input)) return false
  if (input.length <= 0) return false

  return true
}

export function getTotalCost(costData) {
  if (!testInputValidArray(costData)) return 0

  return costData.reduce((totalCost, costItem) => {
    return totalCost + costItem.qty * costItem.value
  }, 0)
}

export function getDistinctDates(costData) {
  if (!testInputValidArray(costData)) return []

  return costData.reduce((output, costItem) => {
    if (output.findIndex((element) => element.label === costItem.date) < 0) {
      output.push({ label: costItem.date, value: output.length })
    }

    return output
  }, [])
}

export function getDistinctCodes(costData) {
  if (!testInputValidArray(costData)) return []

  return costData.reduce((output, costItem) => {
    if (output.findIndex((element) => element.label === costItem.costCode) < 0) {
      output.push({ label: costItem.costCode, value: output.length })
    }

    return output
  }, [])
}

export function getCostsByCode(data) {
  let codes = getDistinctCodes(data)

  let output = []
  codes.forEach((c) => output.push(getCostTotals(data, c.label, '')))
  return output
}

export function getCostsByDate(data) {
  let dt = getDistinctDates(data)
  let output = []
  dt.forEach((d) => output.push(getCostTotals(data, '', d.label)))
  return output
}

export function getCostTotals(data, codeFilter, dateFilter) {
  let output = {
    code: [],
    date: [],
    total: 0,
  }

  if (!testInputValidArray(data)) return output

  data.forEach((cost) => {
    let passFilter = true
    if (codeFilter !== '' && cost.costCode !== codeFilter) passFilter = false
    if (dateFilter !== '' && cost.date !== dateFilter) passFilter = false

    if (passFilter) {
      output.total += cost.qty * cost.value

      let costValid = cost.costCode !== '' && cost.qty > 0 && cost.value > 0 ? true : false

      if (!output.code.includes(cost.costCode)) {
        if (costValid) output.code.push(cost.costCode + ':' + cost.description)
      }

      if (!output.date.includes(cost.date)) {
        if (costValid) output.date.push(cost.date)
      }
    }
  })

  return output
}
