// import { loadUnitsFromStore } from './secureStore'

//--------------------------INNOVA DATABASE BASE UNIT DEFINITIONS---------------------
//All unit conversions are a multiplication factor to go from the database unit set
//to the desired display units
//
//depth - ft, geographic coordinates - m, diameter - inches, dogleg - 100, flow - gpm
//pressure - psi, volumne - bbls, mud - ppg, weight - klbs, unitWeight - ppf,
//torque - kftlbs, temp - degF, cost - $, jets - sqin
//------------------------------------------------------------------------------------

//--------------------------ADDING NEW UNIT DEFINITIONS-------------------------------
//Add additional units (name and conversion) to the object below under the appropriate
//category
//------------------------------------------------------------------------------------

//-------------------------ADDING NEW UNIT SETS---------------------------------------
//  1. Select a unique name for the unit set and add it in to the unitSetList onject
//  2. Create a new const object with the selections for all the units
//  3. Add a new if statement to the getUnitConversions function mapping selection to the object
//------------------------------------------------------------------------------------

//Each unit set has an object with its individual unit definitions
const usUnitSet = {
  name: 'us',
  depth: 'usft',
  diameter: 'inches',
  dogleg: 100,
  flow: 'gpm',
  pressure: 'psi',
  volume: 'bbls',
  mud: 'ppg',
  weight: 'klbs',
  unitWeight: 'ppf',
  torque: 'kftlbs',
  temp: 'degf',
  cost: '$',
  jets: 'sqin',
}

const metricUnitSet = {
  name: 'metric',
  depth: 'meters',
  diameter: 'inches',
  dogleg: 30,
  flow: 'lpm',
  pressure: 'bar',
  volume: 'bbls',
  mud: 'sg',
  weight: 'tons',
  unitWeight: 'kgm',
  torque: 'kftlbs',
  temp: 'degc',
  cost: '$',
  jets: 'sqin',
}

const apiUnitSet = {
  name: 'api',
  depth: 'ft',
  diameter: 'inches',
  dogleg: 100,
  flow: 'gpm',
  pressure: 'psi',
  volume: 'bbls',
  mud: 'ppg',
  weight: 'klbs',
  unitWeight: 'ppf',
  torque: 'kftlbs',
  temp: 'degf',
  cost: '$',
  jets: 'sqin',
}

const mixedUnitSet = {
  name: 'mixed',
  depth: 'meters',
  diameter: 'inches',
  dogleg: 30,
  flow: 'gpm',
  pressure: 'psi',
  volume: 'bbls',
  mud: 'ppg',
  weight: 'klbs',
  unitWeight: 'ppf',
  torque: 'kftlbs',
  temp: 'degC',
  cost: '$',
  jets: 'sqin',
}

const canUnitSet = {
  name: 'canda',
  depth: 'meters',
  diameter: 'mm',
  dogleg: 30,
  flow: 'm3',
  pressure: 'kpa',
  volume: 'm3',
  mud: 'sg',
  weight: 'kdan',
  unitWeight: 'kgm',
  torque: 'kftlbs',
  temp: 'degC',
  cost: '$',
  jets: 'sqmm',
}

//This is the master defintion for all unit conversions
const unitConversions = {
  depth: [
    { name: 'ft', conv: 1.0, text: 'ft' },
    { name: 'usft', conv: 0.999998, text: 'usft' }, //1 / 1.00000200000400
    { name: 'meters', conv: 0.3048, text: 'm' },
  ],
  diameter: [
    { name: 'inches', conv: 1.0, text: 'in' },
    { name: 'mm', conv: 25.4, text: 'mm' },
  ],
  flow: [
    { name: 'gpm', conv: 1.0, text: 'gpm' },
    { name: 'lpm', conv: 3.7878787878788, text: 'lpm' }, //1 / 0.264
    { name: 'm3', conv: 0.0037854117891, text: 'm3/min' }, //1 / 264.172052
  ],
  pressure: [
    { name: 'psi', conv: 1.0, text: 'psi' },
    { name: 'bar', conv: 0.0680457267284, text: 'bar' }, //1 / 14.696
    { name: 'kpa', conv: 6.8947599899918, text: 'kpa' }, //1 / 0.145037681
  ],
  volume: [
    { name: 'bbls', conv: 1.0, text: 'bbls' },
    { name: 'm3', conv: 0.158987, text: 'm3' },
  ],
  mud: [
    { name: 'ppg', conv: 1.0, text: 'ppg' },
    { name: 'sg', conv: 0.1198264912407, text: 'sg' }, //1 / 8.3454
    { name: 'psift3', conv: 7.4804946103036, text: 'psi/ft^3' }, //1 / 0.133681
    { name: 'lbsft3', conv: 0.01925, text: 'lbs/ft^3' },
    { name: 'kgm3', conv: 119.826491240683, text: 'kg/m^3' }, //1 / (8.3454 / 1000)
    { name: 'kpam', conv: 1.1756666, text: 'kpa/m' },
  ],
  weight: [
    { name: 'klbs', conv: 1.0, text: 'klbs' },
    { name: 'tons', conv: 0.453592, text: 'tons' },
    { name: 'kdan', conv: 444.8222, text: 'kDan' },
  ],
  unitWeight: [
    { name: 'ppf', conv: 1.0, text: 'lbs/ft' },
    { name: 'kgm', conv: 0.1382548416, text: 'kg/m' },
  ],
  torque: [
    { name: 'kftlbs', conv: 1.0, text: 'kftlbs' },
    { name: 'knm', conv: 4.448222, text: 'KNm' },
    { name: 'ftlbs', conv: 1000.0, text: 'ftlbs' },
  ],
  temp: [
    { name: 'degF', conv: 1.0, text: 'deg F' },
    { name: 'degC', conv: -1.0, text: 'deg C' },
  ],
  cost: [{ name: '$', conv: 1.0, text: '$' }],
  jets: [
    { name: 'sqin', conv: 1.0, text: 'in^2' },
    { name: 'sqmm', conv: 645.16, text: 'mm^2' },
  ],
}

//This object is the list of availible unit sets presented to the user
export const unitSetList = [
  { label: 'API', value: '1' },
  { label: 'Metric-Mixed', value: '2' },
  { label: 'Metric', value: '3' },
  { label: 'US', value: '4' } /*,
{ label: "Canada", value: "5" }*/,
]

//Function to take the selected unit set input and get the conversions
// export async function getUnitsConvsFromStore() {
//   const units = await loadUnitsFromStore()

//   if (units) return getUnitConversions(units.unitName)
// }

export function getUnitConversions(unitSet) {
  let unitSelection = usUnitSet
  if (unitSet === 'US') unitSelection = usUnitSet
  if (unitSet === 'API') unitSelection = apiUnitSet
  if (unitSet === 'Metric') unitSelection = metricUnitSet
  if (unitSet === 'Mixed') unitSelection = mixedUnitSet
  if (unitSet === 'Canada') unitSelection = canUnitSet

  let output = {
    name: unitSet,
    depth: {
      name: unitSelection.depth,
      conv: getConvValue(unitConversions.depth, unitSelection.depth),
      text: getTextValue(unitConversions.depth, unitSelection.depth),
    },
    diameter: {
      name: unitSelection.diameter,
      conv: getConvValue(unitConversions.diameter, unitSelection.diameter),
      text: getTextValue(unitConversions.diameter, unitSelection.diameter),
    },
    dogleg: unitSelection.dogleg,
    flow: {
      name: unitSelection.flow,
      conv: getConvValue(unitConversions.flow, unitSelection.flow),
      text: getTextValue(unitConversions.flow, unitSelection.flow),
    },
    pressure: {
      name: unitSelection.pressure,
      conv: getConvValue(unitConversions.pressure, unitSelection.pressure),
      text: getTextValue(unitConversions.pressure, unitSelection.pressure),
    },
    volume: {
      name: unitSelection.volume,
      conv: getConvValue(unitConversions.volume, unitSelection.volume),
      text: getTextValue(unitConversions.volume, unitSelection.volume),
    },
    mud: {
      name: unitSelection.mud,
      conv: getConvValue(unitConversions.mud, unitSelection.mud),
      text: getTextValue(unitConversions.mud, unitSelection.mud),
    },
    weight: {
      name: unitSelection.weight,
      conv: getConvValue(unitConversions.weight, unitSelection.weight),
      text: getTextValue(unitConversions.weight, unitSelection.weight),
    },
    unitWeight: {
      name: unitSelection.unitWeight,
      conv: getConvValue(unitConversions.unitWeight, unitSelection.unitWeight),
      text: getTextValue(unitConversions.unitWeight, unitSelection.unitWeight),
    },
    torque: {
      name: unitSelection.torque,
      conv: getConvValue(unitConversions.torque, unitSelection.torque),
      text: getTextValue(unitConversions.torque, unitSelection.torque),
    },
    temp: {
      name: unitSelection.temp,
      conv: getConvValue(unitConversions.temp, unitSelection.temp),
      text: getTextValue(unitConversions.temp, unitSelection.temp),
    },
    cost: {
      name: unitSelection.cost,
      conv: getConvValue(unitConversions.cost, unitSelection.cost),
      text: getTextValue(unitConversions.cost, unitSelection.cost),
    },
    jets: {
      name: unitSelection.jets,
      conv: getConvValue(unitConversions.jets, unitSelection.jets),
      text: getTextValue(unitConversions.jets, unitSelection.jets),
    },
  }

  return output
}

function getConvValue(conversionArray, convName) {
  let selectedConv = conversionArray.find(({ name }) => name === convName)
  if (selectedConv) return selectedConv.conv

  return 1
}

function getTextValue(conversionArray, convName) {
  let selectedConv = conversionArray.find(({ name }) => name === convName)
  if (selectedConv) return selectedConv.text

  return ''
}
