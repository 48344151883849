import { appColors, chartSeriesColors } from '../theme'

export function getDistinctPhases(data) {
  if (!data) return []
  if (data.length <= 0) return []

  let output = []

  data.forEach((pt) => {
    let found = output.find((element) => element.phase === pt.phase)
    if (found === undefined) {
      if (pt.phase !== '' && pt.phase !== null) {
        output.push({
          phase: pt.phase,
          color: chartSeriesColors[output.length],
        })
      }
    }
  })

  return output
}

export function getDistinctBHA(data) {
  if (!data) return []
  if (data.length <= 0) return []

  let output = []
  data.forEach((pt) => {
    let found = output.find((element) => element.label === pt.bha)
    if (found === undefined) {
      if (pt.bha !== '' && pt.bha !== null) {
        output.push({
          label: pt.bha,
          color: chartSeriesColors[output.length],
        })
      }
    }
  })

  return output
}

export function createBHALegend(data) {
  let disinctBHA = getDistinctBHA(data)
  if (!disinctBHA) return []
  if (disinctBHA.length <= 0) return []

  let output = []
  disinctBHA.forEach((pt, i) => {
    output.push({ label: pt.label < 0 ? 'No BHA' : 'BHA#' + i, color: chartSeriesColors[output.length] })
  })

  return output
}

export function createPhaseLegend(data) {
  let disinctPhase = getDistinctPhases(data)
  if (!disinctPhase) return []
  if (disinctPhase.length <= 0) return []

  let output = []
  disinctPhase.forEach((pt, i) => {
    output.push({ label: pt.phase.length < 0 ? 'No Phase' : pt.phase, color: chartSeriesColors[output.length] })
  })

  return output
}

export function getPhaseChanges(data) {
  if (!data) return []
  if (data.length <= 0) return []

  let output = []

  for (let i = 0; i < data.length; i++) {
    if (i <= 0 || data[i - 1].phase === data[i].phase || data[i - 1].phase === null || data[i - 1].phase === '')
      continue

    output.push({
      label: data[i - 1].phase,
      color: appColors.itemTextColor,
      x: data[i - 1].days,
      strokeWidth: 1,
      textSize: 10,
      labelColor: appColors.itemTextColor,
      labelX: output.length > 0 ? output[output.length - 1].x : 0,
    })
  }

  return output
}

export function createChartData(data) {
  if (!data) return []

  const chartData = [
    {
      name: 'Depth v Days',
      color: 'tomato',
      strokeWidth: 2,
      strokeOpacity: 1,
      data: [],
    },
  ]

  chartData[0].data = createChartDataIndividualWell(data, 'label', 'bha')

  return chartData
}

export function removeFlatTime(data, removeFlatTime) {
  if (!Array.isArray(data)) return []
  if (data.length <= 0) return []
  if (removeFlatTime === null || removeFlatTime === undefined) return data
  if (removeFlatTime === false) return data

  let output = []
  let cumDays = 0
  for (let i = 0; i < data.length; i++) {
    if (i === 0) continue
    if (data[i].depth !== data[i - 1].depth) {
      cumDays += data[i].days - data[i - 1].days

      if (output.length === 0) {
        output.push({ ...data[i - 1] })
        output[output.length - 1].days = 0
      }
      output.push({ ...data[i] })
      output[output.length - 1].days = cumDays
    }
  }

  return output
}

export function createChartDataIndividualWell(data, searchProp, colorByProp) {
  if (!Array.isArray(data)) return []

  const cols = colorByProp === 'phase' ? getDistinctPhases(data) : getDistinctBHA(data)
  let chartData = []

  if (data.length > 0) {
    data.forEach((item) => {
      let found = cols.find((element) => element[searchProp] === item[colorByProp])
      let segmentCol = 'tomato'
      if (found === undefined) {
        //Not found
      } else {
        segmentCol = found.color
      }

      if (colorByProp !== '') chartData.push({ x: item.days, y: item.depth, color: segmentCol })
      if (colorByProp === '') chartData.push({ x: item.days, y: item.depth })
    })
  } else {
    chartData.push({ x: 0, y: 0 })
    chartData.push({ x: 0, y: 0 })
  }

  return chartData
}

// export function createChartDataMultiWellMobile(data, noFlatTime, phaseFilter) {
//   if (!Array.isArray(data)) return []
//   const chartData = []

//   data.forEach((well, index) => {
//     let seriesCol = 'tomato'
//     let colorBySegment = true
//     if (phaseFilter && phaseFilter !== 'All' && phaseFilter !== '') {
//       seriesCol = index < chartSeriesColors.length ? chartSeriesColors[index] : 'tomato'
//       colorBySegment = false
//     }

//     const newChartData = {
//       name: well.actualWell,
//       color: seriesCol,
//       strokeWidth: 2,
//       strokeOpacity: 1,
//       data: createChartDataIndividualWell(
//         removeFlatTime(well.depthVDays, noFlatTime),
//         'phase',
//         colorBySegment ? 'phase' : '',
//       ),
//     }

//     if (newChartData.data.length > 0) chartData.push(newChartData)
//   })

//   return chartData
// }

export function createChartDataMultiWell(data, noFlatTime, phaseFilter) {
  if (!Array.isArray(data)) return []
  const chartData = []

  data.forEach((well, index) => {
    let seriesCol = 'tomato'
    let colorBySegment = true
    if (phaseFilter && phaseFilter !== '0' && phaseFilter !== '') {
      seriesCol = index < chartSeriesColors.length ? chartSeriesColors[index] : 'tomato'
      colorBySegment = false
    }

    const newChartData = {
      name: well.actualWell,
      color: seriesCol,
      strokeWidth: 2,
      strokeOpacity: 1,
      dataVals: createChartDataIndividualWell(
        removeFlatTime(well.depthVDays, noFlatTime),
        'phase',
        colorBySegment ? 'phase' : '',
      ),
    }

    if (newChartData.dataVals.length > 0) chartData.push(newChartData)
  })

  // console.log('createChartDataMW: ', chartData)
  return chartData
}
