import WellViewPage from '../WellViewPage'
import InventoryCard from './InventoryCard'

const InventoryPage = () => {

  return (
    <WellViewPage maxWidth>
      <InventoryCard />
    </WellViewPage>
  )
}

export default InventoryPage
