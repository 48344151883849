import { UNITS_FOR } from 'components/common/hooks/useUnits'
const columns = {
  wellInfo: [
    { title: 'Job #', field: 'job_num' },
    { title: 'Rig', field: 'rig' },
    { title: 'State', field: 'state' },
    { title: 'County', field: 'county' },
    { title: 'Operator', field: 'operator' },
    { title: 'Well', field: 'actualWell' },
    { title: 'Status', field: 'well_status' },
    { title: 'Formation', field: 'formation' },
  ],
  dailyActivity: [
    {
      title: 'Date',
      field: 'date',
      cellStyle: { width: 120, minWidth: 120, maxWidth: 120 },
      headerStyle: { width: 120, minWidth: 120, maxWidth: 120 },
    },
    {
      title: 'Start Time',
      field: 'startTime',
      cellStyle: { width: 80, minWidth: 80, maxWidth: 80 },
      headerStyle: { width: 80, minWidth: 80, maxWidth: 80 },
    },
    {
      title: 'End Time',
      field: 'endTime',
      cellStyle: { width: 80, minWidth: 80, maxWidth: 80 },
      headerStyle: { width: 80, minWidth: 80, maxWidth: 80 },
    },
    {
      title: 'Hours',
      field: 'hours',
      cellStyle: { width: 80, minWidth: 80, maxWidth: 80 },
      headerStyle: { width: 80, minWidth: 80, maxWidth: 80 },
    },
    { title: 'Start Depth', field: 'startDepth', unit: UNITS_FOR.Depth },
    { title: 'End Depth', field: 'endDepth', unit: UNITS_FOR.Depth },
    { title: 'BHA', field: 'bha' },
    { title: 'Phase', field: 'phase' },
    { title: 'Activity', field: 'activity' },
    {
      title: 'Description',
      field: 'description',
      cellStyle: { width: 350, minWidth: 350 },
      headerStyle: { width: 350, minWidth: 350 },
    },
  ],
  surveys: [
    { title: 'MD', field: 'md', unit: UNITS_FOR.Depth },
    { title: 'Inc', field: 'inc' },
    { title: 'Azi', field: 'azi' },
    { title: 'TVD', field: 'tvd', unit: UNITS_FOR.Depth },
    { title: 'NS', field: 'ns', unit: UNITS_FOR.Depth },
    { title: 'EW', field: 'ew', unit: UNITS_FOR.Depth },
    { title: 'DLS', field: 'dls', unit: UNITS_FOR.Dogleg },
    { title: 'BR', field: 'br' },
    { title: 'TR', field: 'tr' },
    { title: 'TFO', field: 'tfo' },
  ],
  depthTime: [
    { title: 'Days', field: 'days' },
    { title: 'Depth', field: 'depth' },
  ],
  inventory: [
    {
      title: 'Serial Number',
      field: 'serialNum',
    },
    {
      title: 'Description',
      field: 'description',
    },
    {
      title: 'Tool Type',
      field: 'toolType',
    },
    {
      title: 'OD',
      field: 'od',
      unit: UNITS_FOR.Diameter,
    },
    {
      title: 'Length',
      field: 'length',
      unit: UNITS_FOR.Depth,
    },
    {
      title: 'CNX Top',
      field: 'cnxTop',
    },
    {
      title: 'CNX Bottom',
      field: 'cnxBtm',
    },
    {
      title: 'Make',
      field: 'make',
    },
    {
      title: 'Model',
      field: 'model',
    },
    {
      title: 'Dirty',
      field: 'dirty',
    },
    {
      title: 'In Hole',
      field: 'inHole',
    },
    {
      title: 'Date On',
      field: 'dateOn',
    },
    {
      title: 'Date Off',
      field: 'dateOff',
    },
    {
      title: 'Comments',
      field: 'comments',
    },
  ],
  slideSheet: [
    {
      title: 'Date',
      field: 'date',
      cellStyle: { width: 100, minWidth: 100 },
      headerStyle: { width: 100, minWidth: 100 },
    },
    { title: 'Start Time', field: 'startTime' },
    { title: 'End Time', field: 'endTime' },
    {
      title: 'Start Depth',
      field: 'startDepth',
      align: 'right',
      type: 'numeric',
      unit: UNITS_FOR.Depth,
      render: (rowData) =>
        rowData.startDepth && rowData.startDepth !== '' && rowData.startDepth !== null
          ? parseFloat(rowData.startDepth).toFixed(2)
          : '',
    },
    {
      title: 'Stop Depth',
      field: 'stopDepth',
      align: 'right',
      type: 'numeric',
      unit: UNITS_FOR.Depth,
      render: (rowData) =>
        rowData.stopDepth && rowData.stopDepth !== '' ? parseFloat(rowData.stopDepth).toFixed(2) : '',
    },
    { title: 'CL', field: 'cl', align: 'right' },
    { title: 'State', field: 'state' },
    { title: 'ETF', field: 'etf', align: 'right' },
    { title: 'WOB', field: 'wob', align: 'right', unit: UNITS_FOR.Weight },
    { title: 'Flow Rate', field: 'flowRate', align: 'right', unit: UNITS_FOR.FlowRate },
    { title: 'RPM', field: 'rpm', align: 'right' },
    { title: 'Torque', field: 'tq', align: 'right', unit: UNITS_FOR.Torque },
    { title: 'ROP', field: 'rop', align: 'right' },
    { title: 'Pickup Wt', field: 'puWt', align: 'right', unit: UNITS_FOR.Weight },
    { title: 'Slack Off Wt', field: 'soWt', align: 'right', unit: UNITS_FOR.Weight },
    { title: 'Rotating Wt', field: 'roWt', align: 'right', unit: UNITS_FOR.Weight },
    { title: 'Off Btm Press', field: 'offBtmPress', align: 'right', unit: UNITS_FOR.Pressure },
    { title: 'On Btm Press', field: 'onBtmPress', align: 'right', unit: UNITS_FOR.Pressure },
    { title: 'DH Temp', field: 'dhTemp', align: 'right', unit: UNITS_FOR.Temperature },
    { title: 'Grade', field: 'slideGrade', align: 'right' },
    { title: 'Comments', field: 'comments' },
  ],
  fieldCost: [
    {
      title: 'Date',
      field: 'date',
      cellStyle: { width: 100, minWidth: 100, maxWidth: 100 },
      headerStyle: { width: 100, minWidth: 100, maxWidth: 100 },
    },
    {
      title: 'CostCode',
      field: 'costCode',
      cellStyle: { width: 120, maxWidth: 120 },
      headerStyle: { width: 120, maxWidth: 120 },
    },
    {
      title: 'Description',
      field: 'description',
      cellStyle: { width: 240, minWidth: 240 },
      headerStyle: { width: 240, minWidth: 240 },
    },
    { title: 'Quantity', field: 'qty', align: 'right' },
    { title: 'Value', field: 'value', align: 'right', type: 'currency' },
    { title: 'Line Total', field: 'lineTotal', align: 'right', type: 'currency' },
    { title: 'S/N', field: 'sn' },
  ],
  drillString: [
    {
      title: 'Description',
      field: 'desc',
    },
    {
      title: 'Type',
      field: 'type',
    },
    {
      title: 'OD',
      field: 'od',
      unit: UNITS_FOR.Diameter,
    },
    {
      title: 'ID',
      field: 'id',
      unit: UNITS_FOR.Diameter,
    },
    {
      title: 'TJOD',
      field: 'tjod',
    },
    {
      title: 'TJID',
      field: 'tjid',
    },
    {
      title: 'Length',
      field: 'length',
      unit: UNITS_FOR.Depth,
    },
    {
      title: 'Cumulative Length',
      field: 'cumulativeLength',
    },
    {
      title: 'Cnx Top',
      field: 'cnxTop',
    },
    {
      title: 'Cnx Btm',
      field: 'cnxBtm',
    },
    {
      title: 'S/N',
      field: 'sn',
    },
  ],
}

export default columns
