import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Bar } from 'react-chartjs-2'
import { appColors } from 'utils'
import { getPhaseStats } from '../dailyActivityFunctions'
import Skeleton from '@material-ui/lab/Skeleton'
import useUnits from '../../../common/hooks/useUnits'
import ChartDataLabels from 'chartjs-plugin-datalabels'

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
}))

const PhaseStatisticsChartHours = ({ data }) => {
  const classes = useStyles()
  const units = useUnits('DEPTH')

  const createChartData = () => {
    if (!data) return { lebels: [], datasets: [] }

    let dailyData = getPhaseStats(data.dailyActivity)

    console.log('pase stats data ', dailyData)

    const chartData = {
      labels: dailyData.map((day) => (Array.isArray(day.phases) ? day.phases : 'null')),
      datasets: [
        {
          data: dailyData.map((day) => Math.round(day.rotateHours, 2)),
          backgroundColor: appColors.rotateColor,
          label: `Rotate (hrs)`,
          maxBarThickness: 24,
        },
        {
          data: dailyData.map((day) => Math.round(day.slideHours)),
          backgroundColor: appColors.slideColor,
          label: `Slide (hrs)`,
          maxBarThickness: 24,
        },
      ],
    }

    return chartData
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scaleShowLabels: false,
    indexAxis: 'y',
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  }

  return data ? (
    <div className={classes.chartContainer}>
      <Bar type='bar' options={chartOptions} data={createChartData()} plugins={[ChartDataLabels]} />
    </div>
  ) : (
    <React.Fragment>
      <div className={classes.container}>
        <Skeleton height={'80vh'} variant='rect' animation='wave' />
      </div>
    </React.Fragment>
  )
}

export default PhaseStatisticsChartHours
