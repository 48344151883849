import replace from 'lodash'
import lowerCase from 'lodash'

export const setLayer = (layerRef, layerName, selectedLayers, gMap, geoJSON, color1, color2) => {
  const { geoLayer, infoLayer } = layerRef.current
  if (!Boolean(gMap) || !Boolean(geoJSON)) {
    return
  }

  if (!Boolean(selectedLayers[layerName])) {
    if (Boolean(geoLayer)) {
      geoLayer.setMap(null)
    }

    return
  }

  if (Boolean(geoLayer)) {
    geoLayer.setMap(gMap)
    return
  }

  const newGeoLayer = new window.google.maps.Data({ map: gMap })
  layerRef.current = { geoLayer: newGeoLayer, infoLayer }
  newGeoLayer.addGeoJson(geoJSON)
  newGeoLayer.setStyle((feature) => {
    let color = color1

    if (feature.getProperty('isColorful')) {
      color = color2
    }

    return {
      fillColor: color,
      strokeColor: color,
      strokeWeight: 0.25,
      fillOpacity: 0.05,
    }
  })
  newGeoLayer.addListener('click', (event) => {
    const currentValue = Boolean(event.feature.getProperty('isColorful'))

    newGeoLayer.setStyle((feature) => {
      const color = currentValue ? color1 : color2

      return {
        fillColor: color,
        strokeColor: color,
        strokeWeight: 0.25,
        fillOpacity: 0.05,
      }
    })

    event.feature.setProperty('isColorful', !currentValue)
  })

  newGeoLayer.addListener('mouseover', (event) => {
    const { geoLayer, infoLayer } = layerRef.current
    geoLayer.revertStyle()
    geoLayer.overrideStyle(event.feature, { strokeWeight: 4 })
    if (Boolean(infoLayer)) {
      infoLayer.close()
    }
    const position = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    }
    const newInfoLayer = new window.google.maps.InfoWindow({ position })
    const name = event.feature.getProperty('Name') || event.feature.getProperty('NAME')
    newInfoLayer.setContent(name)
    newInfoLayer.open(gMap)
    layerRef.current = { geoLayer, infoLayer: newInfoLayer }
  })

  newGeoLayer.addListener('mouseout', () => {
    const { geoLayer, infoLayer } = layerRef.current
    if (Boolean(geoLayer)) {
      geoLayer.revertStyle()
    }
    if (Boolean(infoLayer)) {
      infoLayer.close()
    }
  })
}

const formatRigContractor = (operator) => {
  let lowerOperator = lowerCase(operator)
  let stripOperator = replace(lowerOperator, ' ', '')

  return stripOperator
}

const findRigOperatorIcon = (operator) => {
  const rigContractor = formatRigContractor(operator)

  switch (rigContractor) {
    case 'patterson':
      return 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/icons/patterson.png'
    case 'precision':
      return 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/icons/precisionDrilling.png'
    case 'nabors':
      return 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/icons/nabors.png'
    case 'h&p':
      return 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/icons/h&p.png'
    case 'akita':
      return 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/icons/akita.png'
    case 'bonanza':
      return 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/icons/bonanza.png'
    case 'ironhand':
      return 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/icons/cwc.png'
    case 'cyclone':
      return 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/icons/cyclone.png'
    case 'ensign':
      return 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/icons/ensign.png'
    case 'excalibur':
      return 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/icons/excalibur.png'
    case 'horizon':
      return 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/icons/horizon.png'
    case 'lasso':
      return 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/icons/lasso.png'
    case 'savanna':
      return 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/icons/savanna.png'
    case 'independencecontractdrilling':
      return 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/icons/icd.png'
    case 'basindrilling':
      return 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/icons/basin.png'
    case 'truedrilling':
      return 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/icons/true.png'
    case 'bison':
      return 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/icons/BisonDrilling.png'
    case 'scandrill':
      return 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/icons/scanDrill.png'
    case 'cactus':
      return 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/icons/cactus.png'
    case 'latshaw':
      return 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/icons/latshaw.png'
    case 'energydrilling':
      return 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/icons/energyDrilling.png'
    case 'noram':
      return 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/icons/noram.png'
    default:
      return null
  }
}

export const findRigIcon = ({ wellStatus, operator }) => {
  // TODO: These are wrong. These are the status's in Dan's code but are different in the wells endpoint
  if (wellStatus === 'Upcoming') {
    return 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/icons/rigMoving.png'
  }

  // TODO: These are wrong. These are the status's in Dan's code but are different in the wells endpoint
  if (wellStatus === 'Standby') {
    return 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/icons/rigStacked.png'
  }

  if (wellStatus === 'Active') {
    const iconLink = findRigOperatorIcon(operator)

    if (Boolean(iconLink)) {
      return iconLink
    }

    return 'https://wellseekerfiles.s3.us-east-2.amazonaws.com/icons/rigActive.png'
  }

  // TODO: Do we have a default rig icon that could be inserted here?
  return null
}
