import { TextLabel, labelColor } from './ThreeDeeView'

import React from 'react'
import { Vector3 } from 'three'

export const SurveyLabels = ({ refData, display }) => {
  if (!display) return null
  if (!Array.isArray(refData)) return null
  if (refData.length === 0) return null
  if (!Array.isArray(refData[0].svyData)) return null
  if (refData[0].svyData.length === 0) return null

  return refData[0].data.map((svy, index) => {
    let svyLabel = refData[0].svyData[index]

    return (
      <TextLabel
        label={`MD: ${svyLabel.md}\nINC: ${svyLabel.inc}\nAZI: ${svyLabel.azi}\nTVD: ${svyLabel.tvd}\nDLS: ${svyLabel.dls}`}
        size={1}
        color={labelColor}
        position={new Vector3(svy.x + 5, svy.y, svy.z)}
      />
    )
  })
}
