import React, { useEffect, useRef } from 'react'

import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/styles'
import ICDSList from './ICDSList'

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: '#192734',
  },
}))

const IcdsAdminCard = () => {
  const _isMounted = useRef(false)
  const classes = useStyles()

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card className={classes.card}>
      <ICDSList />
    </Card>
  )
}

export default IcdsAdminCard
