import React, { useEffect } from 'react'
import map from 'lodash/map'

import { Grid, List, ListItem, ListItemText, makeStyles, createStyles } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'

import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import WellMap from './WellMap'

const MAPPING = [
  [
    { label: 'Operator', field: 'operator' },
    { label: 'Well Name', field: 'actualWell' },
    { label: 'State', field: 'state' },
    { label: 'County', field: 'county' },
    { label: 'Job Number', field: 'jobNum' },
    { label: 'Rig', field: 'rig' },
  ],
  [
    { label: 'Coordinator ', field: 'Coordinator ' },
    { label: 'Day DD', field: 'dayDD' },
    { label: 'Night DD', field: 'nightDD' },
    { label: 'Day MWD', field: 'dayMwd' },
    { label: 'Night MWD', field: 'nightMwd' },
  ],
  [
    { label: 'Status', field: 'wellStatus' },
    { label: 'Hole Depth', field: 'currentDepth', units: true },
    { label: 'Phase', field: 'currentPhase' },
    { label: 'BHA #', field: 'currentBHA' },
    { label: '24 Hr Summary', field: 'summary' },
    { label: '24 Hr LookAhead', field: 'lookAhead' },
  ],
]

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '100%',
      flexGrow: 1,
      background: 'linear-gradient(rgb(45, 45, 45) 0%, rgb(28, 28, 28) 100%)',
    },
    item: {
      padding: '0px 8px',
    },
    labelContent: {
      color: 'grey',
    },
    fieldContent: {
      display: 'inline-block',
      color: '#34C9EB',
    },
  }),
)

const WellInfo = ({ well }) => {
  const classes = useStyles()
  const { newCancelToken, isCancel } = useAxiosCancelToken()
  const [{ loading, data, error }] = useAxios({
    url: '/well/summary',
    data: { wellName: well },
    cancelToken: newCancelToken(),
  })

  useEffect(() => {
    if (error !== undefined && error !== null) {
      if (isCancel(error)) return
    }
  }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

  const units = useUnits(UNITS_FOR.Depth)

  return (
    <div className={classes.root}>
      <Grid container justify='space-around' spacing={2}>
        {map(MAPPING, (columns) => {
          const outerKey = `${JSON.stringify(columns)}-${well}`
          return (
            <Grid item key={outerKey} xs={12} sm={6} md={3}>
              <List>
                {map(columns, (column, idx) => {
                  const key = `${JSON.stringify(column)}-${outerKey}-${idx}`
                  return (
                    <ListItem key={key} classes={{ root: classes.item }}>
                      {loading ? (
                        <Skeleton variant='text' width={200} />
                      ) : (
                        <ListItemText
                          primary={
                            <div>
                              <div className={classes.labelContent}>{column.label}: </div>
                              <div className={classes.fieldContent}>
                                {data[column.field]}
                                {column.units ? ` ${units}` : null}
                              </div>
                            </div>
                          }
                        />
                      )}
                    </ListItem>
                  )
                })}
              </List>
            </Grid>
          )
        })}
        <Grid item xs={12} sm={6} md={3}>
          {loading ? (
            <Skeleton variant='text' width={200} />
          ) : (
            <WellMap latitude={data.latitude} longitude={data.longitude} />
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default WellInfo
