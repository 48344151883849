import React, { useContext, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DashboardContext from '../dashboardContext'

const useStyles = makeStyles((theme) => ({
  widgetMain: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gridAutoRows: '94px',
    gridGap: '20px',
    margin: '20px 0 20px 0',
  },
  widgetCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    backgroundColor: 'rgba(32,32,32,1.0)',
    border: '1px solid black',
    boxShadow: '0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);',
    padding: '8px',
  },
  widgetHeader: {
    color: 'rgba(192,192,192,1.0)',
  },
  widgetValue: {
    color: 'rgba(52,201,235,1.0)',
    fontSize: '2.5em',
    fontWeight: 'bold',
  },
  widgetUnit: {
    color: 'rgba(192,192,192,1.0)',
    fontSize: '2em',
    marginLeft: '0.5em',
  },
}))

const ToolfaceStatus = (props) => {
  const classes = useStyles()
  const { data: rtData } = useContext(DashboardContext)
  const [currentTF, setCurrentTF] = useState('')
  const [effectiveTF, setETF] = useState('')
  const crossoverInc = 5.0

  useEffect(() => {
    if (rtData) {
      let tf = 0.0

      if (rtData.surveyInc === '') {
        setCurrentTF('')
        setETF('')
        return
      }

      if (rtData && rtData.rigState === 'SLIDING') {
        setETF(getFormattedTF(parseFloat(rtData.etf), rtData.surveyInc))
      } else {
        setETF('')
      }

      if (parseFloat(rtData.surveyInc) <= crossoverInc) {
        if (rtData.mtf !== '') {
          tf = parseFloat(rtData.mtf)
          setCurrentTF(getFormattedTF(tf, rtData.surveyInc))
        } else {
          setCurrentTF('')
        }
      } else {
        if (rtData.gtf !== '') {
          tf = parseFloat(rtData.gtf)
          setCurrentTF(getFormattedTF(tf, rtData.surveyInc))
        } else {
          setCurrentTF('')
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rtData])

  const processTF = (slideTF, svyInc) => {
    let tfVal = slideTF
    let tfMod = ''
    const inc = parseFloat(svyInc)

    if (tfVal < 0.0) {
      tfVal += 360.0
    }
    if (tfVal > 360.0) {
      tfVal -= 360.0
    }
    if (inc <= crossoverInc) {
      tfMod = '° M'
    } else {
      if (tfVal > 180.0) {
        tfVal = 360.0 - tfVal
        tfMod = '°L'
      } else {
        tfMod = '°R'
      }
    }
    return { tf: tfVal, tfMod: tfMod }
  }

  const getFormattedTF = (rawTF, surveyInc) => {
    let tfText = ''
    const { tf, tfMod } = processTF(rawTF, surveyInc)
    tfText = tf.toFixed(0) + (parseFloat(surveyInc) > crossoverInc ? tfMod : '°')
    return tfText
  }

  const getSlideGrade = () => {
    let slideText = ''
    if (rtData) {
      if (rtData.rigState === 'SLIDING') {
        return rtData.slideGrade
      }
    }
    return slideText
  }

  return (
    <div className={classes.widgetMain}>
      <div className={classes.widgetCard}>
        <div className={classes.widgetHeader}>Current TF</div>
        <div>
          <span className={classes.widgetValue}>
            {currentTF} {rtData ? (rtData.surveyInc <= crossoverInc ? '(Magnetic)' : '(Gravity)') : ''}
          </span>
        </div>
      </div>
      <div className={classes.widgetCard}>
        <div className={classes.widgetHeader}>Slide Grade</div>
        <div>
          <span className={classes.widgetValue}>{getSlideGrade()}</span>
        </div>
      </div>
      <div className={classes.widgetCard}>
        <div className={classes.widgetHeader}>Effective TF</div>
        <div>
          <span className={classes.widgetValue}>{effectiveTF}</span>
        </div>
      </div>
    </div>
  )
}

export default ToolfaceStatus
