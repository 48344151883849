import React, { useEffect, useRef } from 'react'

import { List, ListItem, ListSubheader } from '@material-ui/core'

import { makeStyles } from '@material-ui/styles'

const useListStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.itemBackground,
    overflow: 'auto',
    position: 'relative',
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#136cb9', //'darkgrey', //'rgba(0,0,0,.1)',
      outline: '1px solid #1679d0',
    },
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
    listStyleType: 'none !important',
  },
  headerText: {
    borderBottom: '1px solid',
  },
}))

const SectionList = ({ sectionList, selItems, setSelItems, renderItem, renderSectionHeader }) => {
  const _isMounted = useRef(false)
  const classes = useListStyles()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <List dense className={classes.root} subheader={<li />}>
      {sectionList?.map((section, index) => {
        return (
          <li key={`section-${section.header}-${index}`} className={classes.listSection} style={{ zIndex: 2000 }}>
            <ul className={classes.ul}>
              <ListSubheader disableSticky={false} disableGutters={true} classes={{ root: classes.headerText }}>
                {renderSectionHeader(section)}
              </ListSubheader>
              {section?.data?.map((item, shIdx) => (
                <ListItem
                  dense
                  disableGutters={true}
                  key={`${index}-${shIdx}`}
                  style={{
                    padding: '0px',
                    borderRadius: 5,
                    border: '1px solid',
                    marginLeft: '8px',
                    background: 'linear-gradient(to bottom, #2d2d2d 0%, #1c1c1c 100%)',
                    marginBottom: '4px',
                    width: 'calc(100% - 10px)', // 8px for marginLeft, 2px for left & right border
                  }}>
                  {renderItem(item, shIdx)}
                </ListItem>
              ))}
            </ul>
          </li>
        )
      })}
    </List>
  )
}

export default SectionList
