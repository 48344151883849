import React, { useRef } from 'react'
import { camSpeed, validateIndexValue } from './ThreeDeeView'
import { useFrame, useThree } from '@react-three/fiber'

import { OrbitControls } from '@react-three/drei'
import { Vector3 } from 'three'
import useCodes from 'components/common/hooks/useCodes'

export function Controls({ chartData, indexRef, setIndex }) {
  const { camera } = useThree()
  const code = useCodes()
  const ref = useRef()
  const changeValRef = useRef(0)

  const changeCamPostion = (change) => {
    changeValRef.current += change
    if (Math.abs(changeValRef.current) < 1) return
    if (changeValRef.current >= 1) change = 1
    if (changeValRef.current <= -1) change = -1
    changeValRef.current = 0

    let newTargetIndex = validateIndexValue(chartData?.refData, indexRef.current + change)

    if (newTargetIndex < 0) return
    let newCamTarget = new Vector3(
      chartData?.refData[0].data[newTargetIndex].x,
      chartData?.refData[0].data[newTargetIndex].y,
      chartData?.refData[0].data[newTargetIndex].z,
    )

    let oldCamTarget = new Vector3(
      chartData?.refData[0].data[indexRef.current].x,
      chartData?.refData[0].data[indexRef.current].y,
      chartData?.refData[0].data[indexRef.current].z,
    )

    indexRef.current = newTargetIndex
    setIndex(indexRef.current)

    let camOffset = camera.position.clone().sub(oldCamTarget)
    let newCamPosition = camOffset.clone().add(newCamTarget)

    camera.lookAt(newCamTarget)
    camera.position.x = newCamPosition.x
    camera.position.y = newCamPosition.y
    camera.position.z = newCamPosition.z
    ref.current.target = newCamTarget
    ref.current.target0 = newCamTarget
    ref.current.position0 = newCamPosition
    ref.current.update()
  }

  useFrame(() => {
    if (code.current.has('ArrowUp')) changeCamPostion(-camSpeed)
    if (code.current.has('ArrowDown')) changeCamPostion(camSpeed)
  })

  return <OrbitControls ref={ref} args={[camera]} />
}
