import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import * as XLSX from 'xlsx'

import AnalyticsPage from '.'
import AnalyticsCard from './AnalyticsCard'
import BarChart from './BarChartCjs'
import { appColors, array2pipestr } from 'utils'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import useAxiosWithUnitFetch from 'components/common/hooks/useAxiosWithUnitFetch'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import { analyticsPhaseFilterAtom, analyticsSearchParamsAtom, analyticsSelectedWells } from 'atoms'

const CostPerOperatorPage = () => {
  const _isMounted = useRef(false)

  const [data, setData] = useState([])
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const phaseFilter = useRecoilValue(analyticsPhaseFilterAtom)
  const userUnits = useUnits(UNITS_FOR.Depth)
  const searchParams = useRecoilValue(analyticsSearchParamsAtom)
  const { newCancelToken: kpiCancelToken, cancelPreviousRequest: kpiCancelRequest } = useAxiosCancelToken()
  const [{ error: kpiError }, getKpiData] = useAxiosWithUnitFetch({
    url: '/kpi/getKpis',
    cancelToken: kpiCancelToken(),
    manual: true,
  })

  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
      kpiCancelRequest()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (kpiError !== undefined && kpiError !== null) {
      if (kpiCancelRequest(kpiError)) return
    }
  }, [kpiError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      getChartData()
    }
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      getChartData()
    }
  }, [phaseFilter]) // eslint-disable-line react-hooks/exhaustive-deps

  const createChartData = (data) => {
    let output = {
      labels: [],
      datasets: [
        {
          backgroundColor: appColors.rotateColor,
          maxBarThickness: 24,
          data: [],
        },
      ],
    }

    if (!data || !data.costByWell) {
      return output
    }

    data.costByWell.forEach((well) => {
      if (output.labels.findIndex((element) => element === well.operator) < 0) {
        output.labels.push(well.operator)
        output.datasets[0].data.push(0)
      }
    })
    data.costByWell.forEach((well) => {
      if (typeof well.wellCost === 'number') {
        let index = output.labels.findIndex((element) => element === well.operator)
        if (index >= 0) {
          output.datasets[0].data[index] += well.wellCost
        }
      }
    })

    return output
  }

  const getChartData = async () => {
    if (selectedWells.length < 1) {
      await setData([])
      return
    }

    let payload = {
      wellList: array2pipestr(selectedWells),
      costByWell: 'true',
    }
    let { dateFrom, dateTo } = searchParams
    if (dateFrom === '' || dateTo === '') {
      dateTo = new Date(Date.now()).toISOString()
      dateFrom = new Date(Date.now() - 1200 * 24 * 60 * 60 * 1000).toISOString()
    }
    payload.dateTo = dateTo
    payload.dateFrom = dateFrom

    // add phaseFilter to the payload if it's not empty or All
    if (phaseFilter !== 'All' && phaseFilter !== '' && phaseFilter !== '0') {
      payload.phase = phaseFilter
    } else {
      payload.phase = ''
    }
    const dataResponse = await getKpiData(payload)
    if (dataResponse?.data) {
      setData(dataResponse.data)
    } else {
      setData([])
    }
  }

  const onXlsxExport = () => {
    let ws = XLSX.utils.aoa_to_sheet([['Operator', 'Total Cost']], {
      origin: 'A1',
    })

    if (data && data.costByWell) {
      let exportData = {
        operators: [],
        totalCost: [],
      }

      data.costByWell.forEach((well) => {
        if (exportData.operators.findIndex((element) => element === well.operator) < 0) {
          exportData.operators.push(well.operator)
          exportData.totalCost.push(0)
        }
      })

      data.costByWell.forEach((well) => {
        if (typeof well.wellCost === 'number') {
          let index = exportData.operators.findIndex((element) => element === well.operator)
          if (index >= 0) {
            exportData.totalCost[index] += well.wellCost
          }
        }
      })

      let costPerOperatorExport = []
      if (exportData.operators.length > 0) {
        for (let i = 0; i < exportData.operators.length; i++) {
          let opRow = []
          opRow.push(exportData.operators[i])
          opRow.push(exportData.totalCost[i])
          costPerOperatorExport.push(opRow)
        }
      }

      XLSX.utils.sheet_add_aoa(ws, costPerOperatorExport, { origin: `A2` })
    }

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Operator Cost')
    XLSX.writeFile(wb, 'Cost by Operator.xlsx')
  }

  return (
    <AnalyticsPage>
      <AnalyticsCard exportXlsxCB={onXlsxExport}>
        <BarChart
          wellData={createChartData(data)}
          units={userUnits}
          title='Cost Per Operator'
          xAxisTitle='Operator'
          yAxisTitle={`Cost`}
          chartOptions={chartOptions}
        />
      </AnalyticsCard>
    </AnalyticsPage>
  )
}

export default CostPerOperatorPage
