import React, { useEffect } from 'react'

import WitsmlServerCard from './WitsmlServerCard'
import Page from '../common/Page'
import { currentPageAtom } from '../../atoms'
import { useSetRecoilState } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'

const WitsmlServerPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)

  useEffect(() => {
    setActivePage(PAGE_KEYS.witsmlServersKey)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page maxWidth>
      <WitsmlServerCard />
    </Page>
  )
}

export default WitsmlServerPage
