export function round(input, decimalPlaces) {
  if (input === null || input === undefined) return 0
  if (decimalPlaces === null || decimalPlaces === undefined) decimalPlaces = 2
  if (typeof input === 'string') input = parseFloat(input)
  if (typeof decimalPlaces === 'string') decimalPlaces = parseFloat(decimalPlaces)
  const factorOfTen = Math.pow(10, decimalPlaces)
  return Math.round(typeof input === 'string' ? parseFloat(input) : input * factorOfTen) / factorOfTen
}

export function roundUp(numToRound, multiple) {
  let sign = 1
  if (multiple === null || multiple === undefined) multiple = 1.0
  if (numToRound === null || numToRound === undefined) return 0
  if (typeof multiple === 'string') multiple = parseFloat(multiple)
  if (typeof numToRound === 'string') numToRound = parseFloat(numToRound)
  if (numToRound === 0) return numToRound
  if (numToRound < 0) sign = -1

  let x = Math.abs(numToRound) / multiple
  x = Math.ceil(x + 0.5) * multiple

  return x * sign
}

export function ptInRect(r, p) {
  if (p === null || p === undefined) return false
  if (r === null || r === undefined) return false
  if (!p.hasOwnProperty('x')) return false
  if (!r.hasOwnProperty('x')) return false

  if (p.x < r.x) return false
  if (p.x > r.x + r.w) return false
  if (p.y < r.y) return false
  if (p.y > r.y + r.h) return false

  return true
}

export function generateShortUUID() {
  return Math.random().toString(36).substring(2, 15)
}

export function integerRoundUp(input) {
  if (input === null || input === undefined) return 0
  if (typeof input === 'string') input = parseFloat(input)

  if (Math.round(input) < input) return Math.round(input) + 1

  return Math.round(input)
}
