export function checkDateRange(jobStart, jobEnd, filterStart, filterEnd) {
  if (jobEnd < filterStart || jobStart > filterEnd) return 0
  if (jobStart <= filterStart && jobEnd >= filterEnd) return filterEnd - filterStart
  if (jobStart >= filterStart && jobEnd <= filterEnd) return jobEnd - jobStart
  if (jobStart <= filterStart && jobEnd >= filterStart && jobEnd <= filterEnd) return jobEnd - filterStart
  if (jobStart >= filterStart && jobStart <= filterEnd && jobEnd >= filterEnd) return filterEnd - jobStart

  return 0
}

export function isoStringToNumeric(isoDateTime) {
  if (!isoDateTime) return 0
  let dt = new Date(isoDateTime)
  return dt.getTime()
}

export function numberToTimeString(timeMilisecs) {
  if (!timeMilisecs) return '00:00'
  const dt = new Date(timeMilisecs)
  return dt.toLocaleTimeString()
}

export function isoStringToDateString(isoDateTime) {
  let dt = new Date(isoDateTime)
  let output = dt.toLocaleDateString(undefined, {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })

  return output
}

export function isoStringToTimeString(isoDateTime) {
  if (typeof isoDateTime !== 'string' && typeof isoDateTime !== 'object') return null

  let dt = new Date(Date.parse(isoDateTime.replace('Z', '')))
  return getTimeString(dt.getHours()) + ':' + getTimeString(dt.getMinutes())
}

export function getDeltaIsoTime(t1, t2, returnDecimal) {
  let d1 = new Date(Date.parse(t1))
  let d2 = new Date(Date.parse(t2))

  if (d1 && d2) {
    let d3 = new Date(d2 - d1)
    let d0 = new Date(0)

    let deltaHours = 0
    let deltaMins = 0
    let deltaSecs = 0

    if (d0 && d3) {
      deltaHours = d3.getHours() - d0.getHours()
      deltaMins = d3.getMinutes() - d0.getMinutes()
      deltaSecs = d3.getSeconds() - d0.getSeconds()

      if (deltaHours < 0) {
        deltaHours = 24 + (deltaHours + deltaMins / 60)
        deltaMins = (deltaHours - Math.floor(deltaHours)) * 60
        deltaHours = Math.floor(deltaHours)
      }
    }

    if (returnDecimal === true) return deltaHours + deltaMins / 60 + deltaSecs / 3600

    return (
      getTimeString(Math.round(deltaHours)) +
      ':' +
      getTimeString(Math.round(deltaMins) + ':' + getTimeString(Math.round(deltaSecs)))
    )
  }

  if (returnDecimal === true) return 0

  return '00:00'
}

export function getDeltaIsoDays(t1, t2) {
  let d1 = new Date(t1)
  let d2 = new Date(t2)

  if (d1 && d2) {
    let diffTime = Math.abs(d2 - d1)
    let diffDays = diffTime / (1000 * 60 * 60 * 24)
    return diffDays
  }

  return 0
}

export function getDeltaTime(t1, t2, returnDecimal) {
  let d1 = new Date(Date.parse('2020-01-02T' + t1))
  let d2 = new Date(Date.parse('2020-01-02T' + t2))

  if (d1 && d2) {
    let d3 = new Date(d2 - d1)
    let d0 = new Date(0)

    let deltaHours = 0
    let deltaMins = 0

    if (d0 && d3) {
      deltaHours = d3.getHours() - d0.getHours()
      deltaMins = d3.getMinutes() - d0.getMinutes()

      if (deltaHours < 0) {
        deltaHours = 24 + (deltaHours + deltaMins / 60)
        deltaMins = (deltaHours - Math.floor(deltaHours)) * 60
        deltaHours = Math.floor(deltaHours)
      }
    }

    if (returnDecimal === true) return deltaHours + deltaMins / 60

    return getTimeString(Math.round(deltaHours)) + ':' + getTimeString(Math.round(deltaMins))
  } else {
    if (returnDecimal === true) return 0

    return '00:00'
  }
}

function getTimeString(timeIn) {
  if (timeIn < 10) return '0' + timeIn

  return timeIn
}

export function getCurrentIsoDateString(deltaDays, dateOnly) {
  let dt = new Date()
  if (deltaDays) {
    dt = new Date(Date.now() + parseFloat(deltaDays) * 24 * 60 * 60 * 1000)
  }

  if (dateOnly === true) return dt.toISOString().substring(0, 10)
  return dt.toISOString()
}

export function timeSince(dateMs) {
  var seconds = Math.floor((new Date() - dateMs) / 1000)

  var interval = Math.floor(seconds / 31536000)

  if (interval > 1) {
    return interval + 'Y'
  }
  interval = Math.floor(seconds / 2592000)
  if (interval > 1) {
    return interval + 'M'
  }
  interval = Math.floor(seconds / 86400)
  if (interval > 1) {
    return interval + 'D'
  }
  interval = Math.floor(seconds / 3600)
  if (interval > 1) {
    return interval + 'h'
  }
  interval = Math.floor(seconds / 60)
  if (interval > 1) {
    return interval + 'm'
  }
  return Math.floor(seconds) + 's'
}

export function isoDateFromUnkn(inputStr, units) {
  let splitStr = inputStr.split('/')

  let output = { day: -1, month: -1, year: -1 }
  let dayIndex = 0
  let monthIndex = 1

  if (units === 'US') {
    dayIndex = 1
    monthIndex = 0
  }

  splitStr.forEach((val, i) => {
    if (i === dayIndex) output.day = parseInt(val)
    if (i === monthIndex) output.month = parseInt(val)
    if (i === 2) output.year = parseInt(val)
  })

  return `${output.year}-${getTimeString(output.month)}-${getTimeString(output.day)}`
}

export function getBHAdeltaHours(dateIn, dateOut, timeIn, timeOut, units) {
  let output = { dateIn: '', dateOut: '', deltaHrs: 0 }
  if (!dateIn || !dateOut) return output
  if (dateIn.indexOf('/') < 0) return output
  if (dateOut.indexOf('/') < 0) return output
  if (!timeIn) timeIn = '00:00'
  if (!timeOut) timeOut = '00:00'

  let dateInStr = `${isoDateFromUnkn(dateIn, units)}T${timeIn}`
  let dateOutStr = `${isoDateFromUnkn(dateOut, units)}T${timeOut}`

  let d1 = new Date(Date.parse(dateInStr))
  let d2 = new Date(Date.parse(dateOutStr))

  output.dateIn = `${d1.toLocaleDateString()}-${timeIn}`
  output.dateOut = `${d2.toLocaleDateString()}-${timeOut}`
  output.deltaHrs = getDeltaIsoDays(dateInStr, dateOutStr) * 24
  return output
}

export function daysSinceActive(dateIn) {
  let d = new Date()
  let dtOffset = d.getTimezoneOffset() * 60 * 1000
  let curTimeTicks = d.getTime() - dtOffset
  d = new Date(curTimeTicks)
  d.setUTCMinutes(0, 0)
  d.setUTCHours(0)

  let dateNow = d.toUTCString()
  let deltaHours = getDeltaIsoDays(dateIn, dateNow, true)
  return Math.round(deltaHours)
}

export function getDaysText(daysIn) {
  let weeks = daysIn / 7
  let years = daysIn / 365

  if (weeks < 1) {
    if (daysIn === 0) return '(Today)'
    if (daysIn === 1) return '(Yesterday)'
    if (daysIn > 1) return '(' + daysIn + ' days ago)'
  }

  if (weeks >= 1 && years < 1) {
    if (weeks < 2) return '(last week)'
    if (weeks >= 2) return '(' + Math.round(weeks) + ' weeks ago)'
  }

  if (years >= 1) {
    if (years < 2) return '(1 year ago)'
    if (years >= 2) return '(' + Math.round(years) + ' years ago)'
  }

  if (daysIn > 7 && daysIn < 365) return '(' + Math.round(daysIn / 7) + ' weeks ago)'
  if (daysIn > 365) return
}

export function createDateArray(minDate, maxDate) {
  if (typeof minDate !== 'string') return []
  if (typeof maxDate !== 'string') return []
  let d1 = new Date(Date.parse(minDate + 'T00:00:00'))
  let d2 = new Date(Date.parse(maxDate + 'T00:00:00'))
  if (d1 > d2) {
    let d = d1
    d1 = d2
    d2 = d
  }

  let dateArray = []
  for (let d = d1; d <= d2; d.setDate(d.getDate() + 1)) {
    let newDateString = new Date(d).toISOString()
    dateArray.push(newDateString.substring(0, 10))
  }

  return dateArray
}

export function dateAdd(date, interval, units) {
  if (!(date instanceof Date)) return undefined
  var ret = new Date(date) //don't change original date
  var checkRollover = function () {
    if (ret.getDate() !== date.getDate()) ret.setDate(0)
  }
  switch (String(interval).toLowerCase()) {
    case 'year':
      ret.setFullYear(ret.getFullYear() + units)
      checkRollover()
      break
    case 'quarter':
      ret.setMonth(ret.getMonth() + 3 * units)
      checkRollover()
      break
    case 'month':
      ret.setMonth(ret.getMonth() + units)
      checkRollover()
      break
    case 'week':
      ret.setDate(ret.getDate() + 7 * units)
      break
    case 'day':
      ret.setDate(ret.getDate() + units)
      break
    case 'hour':
      ret.setTime(ret.getTime() + units * 3600000)
      break
    case 'minute':
      ret.setTime(ret.getTime() + units * 60000)
      break
    case 'second':
      ret.setTime(ret.getTime() + units * 1000)
      break
    default:
      ret = undefined
      break
  }
  return ret
}