import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { Plot } from '@int/geotoolkit/plot/Plot'
import { FillStyle } from '@int/geotoolkit/attributes/FillStyle'

import { edrDataQueryAtom } from 'atoms'
import { LineStyle } from '@int/geotoolkit/attributes/LineStyle'
import { appColors } from 'utils'

import { Point } from '@int/geotoolkit/util/Point'
import { Regions } from '@int/geotoolkit/gauges/layout/Regions'
import { Group } from '@int/geotoolkit/scene/Group'
import { Ellipse } from '@int/geotoolkit/scene/shapes/Ellipse'
import { Polygon } from '@int/geotoolkit/scene/shapes/Polygon'
import { Rect } from '@int/geotoolkit/util/Rect'
import { CircularGauge } from '@int/geotoolkit/gauges/CircularGauge'
import { Range } from '@int/geotoolkit/util/Range'
import { Functions as EasingFunctions } from '@int/geotoolkit/animation/Easing'
import { TickPositions } from '@int/geotoolkit/gauges/axis/TickPositions'
import { ValueDisplayStrategies } from '@int/geotoolkit/gauges/ValueDisplayStrategies'
import { AnchorType } from '@int/geotoolkit/util/AnchorType'
import { Text } from '@int/geotoolkit/scene/shapes/Text'
import { Layers } from '@int/geotoolkit/gauges/layout/Layers'
import { makeStyles } from '@material-ui/styles'
import { useResizeDetector } from 'react-resize-detector'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: '1 1 auto',
    minWidth: 0,
    width: 'calc(100% * 0.33)',
    height: '100%',
  },
}))

const GtkHalfCircularGauge = ({ id, curveDef }) => {
  const classes = useStyles()
  const _isMounted = useRef(false)
  const canvasRef = useRef(null)
  const data = useRecoilValue(edrDataQueryAtom)
  const [gauge, setGauge] = useState(null)
  const [plot, setPlot] = useState(null)
  const [skipAnimation] = useState(false)
  const [size, setSize] = useState({ width: 0, height: 0 })

  const onResize = useCallback((width, height) => {
    setSize({ width: width, height: height })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const { ref: rszRef } = useResizeDetector({ onResize: onResize, refreshMode: 'debounce', refreshRate: 500 })

  useEffect(() => {
    _isMounted.current = true
    const newGauge = createHalfCircleGauge()
    setGauge(newGauge)
    return () => {
      _isMounted.current = false
      if (plot) {
        plot.dispose()
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && gauge) {
      gauge.update()
    }
  })

  useEffect(() => {
    plot?.setSize(size.width, size.height)
    gauge?.setBounds(new Rect(0, 0, size.width, size.height))
    gauge?.update()
  }, [size]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && gauge) {
      let newPlot = new Plot({
        'canvasElement': canvasRef.current,
        'root': gauge,
      })
      setPlot(newPlot)
    }
  }, [gauge])

  useEffect(() => {
    if (_isMounted.current && gauge) {
      if (data && data.length > 0) {
        let newValue = data[data.length - 1][curveDef.curveName]
        gauge.getAxis('mainaxis').setValue(newValue, skipAnimation)
      }
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  const createNeedle = () => {
    const needleGeometry = new Group()
    const b = new Rect(0, 0, 60, 200)
    needleGeometry.setBounds(b)
    needleGeometry.setModelLimits(b)
    const ellipse = new Ellipse({
      'centerx': 30,
      'centery': 120,
      'radiusx': 10,
      'radiusy': 10,
      'linestyle': appColors.itemTextColorMobile,
      'fillstyle': appColors.itemTextColorMobile,
    })
    const needle = new Polygon({
      'x': [25, 35, 30],
      'y': [120, 120, 0],
      'linestyle': appColors.itemTextColorMobile,
      'fillstyle': appColors.itemTextColorMobile,
    })
    const ellipse1 = new Ellipse({
      'centerx': 30,
      'centery': 120,
      'radiusx': 3,
      'radiusy': 3,
      'linestyle': '#1d1d1d',
      'fillstyle': '#1d1d1d',
    })
    needleGeometry.addChild(ellipse)
    needleGeometry.addChild(needle)
    needleGeometry.addChild(ellipse1)
    const center = new Point(30, 120)

    return {
      'needle': needleGeometry,
      'center': center,
    }
  }

  const createHalfCircleGauge = () => {
    const gauge = createAxisGauge()
    const needle = createNeedle()
    const axis = gauge.getAxis('mainaxis')
    axis.setNeedle({
      // Needle is a geotoolkit geometry with a center point - the anchor for rotation.
      'geometry': needle.needle,
      'center': needle.center,
    })
    // Many easing functions are registered in the gauge by default.
    // all you have to tell the axis which function to use
    axis.setAnimation(EasingFunctions.EaseInOutQuad)
    return gauge
  }

  const createAxisGauge = () => {
    // Create a circular gauge with 90deg sweep angle
    const gauge = new CircularGauge({
      'startangle': Math.PI,
      'sweepangle': Math.PI,
      'fillstyle': new FillStyle('#202020'),
    })

    // Add the text
    gauge.setRegionSize(Regions.North, '20%')
    gauge.setRegionSize(Regions.South, '20%')
    gauge.addCustomComponent(
      new Text({
        'text': curveDef.name,
        'textstyle': {
          'font': '16px sans-serif',
          'color': appColors.itemTextColorMobile,
        },
        'sizeIsInDeviceSpace': false,
        'alignment': AnchorType.BottomCenter,
      }),
      Regions.North,
      Layers.CustomComponent,
    )

    // Add an axis which by default will sweep to whole sweep of the gauge
    gauge.addAxis({
      'name': 'mainaxis',
      'namestyle': { 'visible': false },
      'range': new Range(curveDef.scaleMin, curveDef.scaleMax),
      'value': 0,
      'valuestyle': {
        'format': '#',
        'font': '16px Arial',
        'position': Regions.InnerNorth,
        'color': appColors.itemTextColorMobile,
        'displaystrategy': ValueDisplayStrategies.FromFont,
      },
      'fillstyle': new FillStyle(appColors.itemTextColorDark3),
      'linestyle': new LineStyle(appColors.itemTextColorDark3),
      'shadow': {
        'color': appColors.itemTextColorDark3, // no effect
      },
      'thickness': '10px,',
      'ticks': {
        'major': {
          'visible': false,
        },
        'minor': {
          'visible': false,
        },
        'edge': {
          'visible': true,
          'tickposition': TickPositions.Center,
          'fillstyle': new FillStyle(appColors.itemTextColorDark3),
          'linestyle': new LineStyle(appColors.itemTextColorDark3),
          'labels': {
            'visible': true,
            'textstyle': {
              'color': appColors.itemTextColorDark3,
            },
            'position': TickPositions.Inside,
          },
        },
      },
    })

    return gauge
  }

  return (
    <div ref={rszRef} className={classes.container}>
      <canvas
        id={id}
        className='time-canvas'
        ref={canvasRef}
        width={size.width}
        height={size.height}
        style={{ width: size.width, height: size.height }}></canvas>
    </div>
  )
}

export default GtkHalfCircularGauge
