import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Line } from 'react-chartjs-2'
import { appColors } from 'utils'
import { getPhaseStats } from '../dailyActivityFunctions'
import Skeleton from '@material-ui/lab/Skeleton'
import useUnits from '../../../common/hooks/useUnits'

const useStyles = makeStyles((theme) => ({
  chartContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
}))

const PhaseStatisticsChartRop = ({ data }) => {
  const classes = useStyles()
  const units = useUnits('DEPTH')

  const createChartData = () => {
    if (!data) return { lebels: [], datasets: [] }

    let dailyData = getPhaseStats(data.dailyActivity)

    console.log('phase stats data ', dailyData)

    const chartData = {
      labels: dailyData.map((day) => (Array.isArray(day.phases) ? day.phases : 'null')),
      datasets: [
        {
          data: dailyData.map((day) => day.rotateROP),
          borderColor: appColors.rotateColor,
          label: `Rotate (${units}/hr)`,
          maxBarThickness: 24,
        },
        {
          data: dailyData.map((day) => day.slideROP),
          borderColor: appColors.slideColor,
          label: `Slide (${units}/hr)`,
          maxBarThickness: 24,
        },
        {
          data: dailyData.map((day) => day.avgROP),
          borderColor: appColors.avgColor,
          label: `Avg (${units}/hr)`,
          maxBarThickness: 24,
        },
      ],
    }

    return chartData
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scaleShowLabels: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          boxHeight: 1, // change label from box to line
        },
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: false,
      },
    },
  }

  return data ? (
    <div className={classes.chartContainer}>
      <Line type='line' options={chartOptions} data={createChartData()} />
    </div>
  ) : (
    <React.Fragment>
      <div className={classes.container}>
        <Skeleton height={'80vh'} variant='rect' animation='wave' />
      </div>
    </React.Fragment>
  )
}

export default PhaseStatisticsChartRop
