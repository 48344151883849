import { useEffect, useState, useRef } from 'react'

import { makeStyles } from '@material-ui/styles'
import SearchBar from 'material-ui-search-bar'
import Avatar from '@material-ui/core/Avatar'
import { IconButton, Tooltip, Snackbar } from '@material-ui/core'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { Icon as Iconify } from '@iconify/react'

import { filter as _filter } from 'lodash'
import { round } from 'utils/numberFunctions'
import { appColors } from 'utils'
import { useRecoilValue } from 'recoil'
import { currentWellAtom } from 'atoms'
import InputModal from 'components/common/InputModal'
import * as yup from 'yup'
import AddIcon from '@material-ui/icons/Add'
import PrintIcon from '@material-ui/icons/Print'
import RefreshIcon from '@material-ui/icons/Refresh'
import * as XLSX from 'xlsx'
import MuiAlert from '@material-ui/lab/Alert'
import { checkPermission } from '../../userPermissions'
import { userUserRoleAtom } from 'atoms'
import { searchFunction } from '../../common/searchFunctions'

import { unescapeHtml } from '../../../utils/htmlSymbolHandling'
import { getComponentColor, getDrillStringIcon } from 'components/common/drillStringFunctions'

import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'

import SimpleStyledList from 'components/common/SimpleStyledList'
import MenuButton from 'components/common/MenuButton'

const useStyles = makeStyles((theme) => ({
  motorStatusCountContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid black',
    borderRadius: 5,
    height: 30,
    background: 'linear-gradient(to bottom, #2d2d2d 0%, #1c1c1c 100%)',
    color: '#C0C0C0',
    fontSize: 20,
    margin: 5,
  },
  motorSumContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 5px 0 5px',
  },
  listContainer: {
    display: 'flex',
    overflow: 'hidden',
    height: 'calc(calc(100vh - 100px) * 1.00)',
    maxHeight: 'calc(calc(100vh - 210px) * 1.00)',
    borderRadius: '4px',
  },
  itemTextWrapContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    flex: 3,
  },
  avatarContainer: {
    margin: 5,
    marginRight: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'lightgrey',
    borderRadius: '50%',
  },
  itemMainContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: '8px',
    marginBottom: '4px',
    border: `1px solid`,
    borderColor: appColors.itemBackColor,
    backgroundColor: 'inherit',
    '&:hover': {
      borderColor: appColors.accentColor,
    },
  },
  itemTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  itemLabelText: {
    fontSize: 14,
    fontWeight: '500',
    color: appColors.headerTextColor,
    width: '64px',
    textAlign: 'right',
    paddingRight: '8px',
  },
  itemText: {
    fontSize: 14,
    fontWeight: '400',
    color: appColors.itemTextColorMobile,
    border: '',
  },
  title: {
    marginTop: 2,
    font: '20px Roboto',
    color: appColors.itemTextColorMobile,
    textAlign: 'left',
    width: '100%',
  },
  deleteIconStyle: {
    color: '#C00000',
    fontSize: '24px',
  },
  menuIcon: {
    backgroundColor: 'transparent',
    margin: '4px',
    padding: '12px',
    position: 'fixed',
    bottom: theme.spacing(2) - 20,
    right: theme.spacing(2) - 20,
  },
}))

const InventoryList = () => {
  const classes = useStyles()
  const currentWell = useRecoilValue(currentWellAtom)
  const _isMounted = useRef(true)
  const userRole = useRecoilValue(userUserRoleAtom)
  const [searchText, setSearchText] = useState('')
  const [filteredList, setFilteredList] = useState([])
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [showEditor, setShowEditor] = useState(false)
  const editIndex = useRef(-1)
  const [dropDownLists, setDropDownLists] = useState({ toolTypes: [], connectionTypes: [] })

  const { newCancelToken, isCancel } = useAxiosCancelToken()
  const [{ data, error }, getInventory] = useAxios({
    url: '/well/toolInventory/getInventory',
    data: {
      wellName: currentWell,
    },
    cancelToken: newCancelToken(),
  })

  const { newCancelToken: newCancelTokenDropDowns, cancelPreviousRequest: cancelPreviousDropDownRequest } =
    useAxiosCancelToken()
  const [{ data: dropDownDataRaw, error: dropDownError }] = useAxios({
    url: '/well/toolInventory/getInventoryDropDowns',
    cancelToken: newCancelTokenDropDowns(),
  })

  const { newCancelToken: newDeleteCancelToken, cancelPreviousRequest: cancelPreviousDeleteRequest } =
    useAxiosCancelToken()
  const [{ error: deleteError }, deleteInvItem] = useAxios({
    url: '/well/toolInventory/deleteInventoryItem',
    manual: true,
    cancelToken: newDeleteCancelToken(),
  })

  const { newCancelToken: createCancelToken, cancelPreviousRequest: cancelCreateReq } = useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorCreate }, addItem] = useAxios({
    url: '/well/toolInventory/addInventoryItem',
    manual: true,
    cancelToken: createCancelToken(),
  })

  const { newCancelToken: updateCancelToken, cancelPreviousRequest: cancelUpdateReq } = useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorUpdate }, updateItem] = useAxios({
    url: '/well/toolInventory/updateInventoryItem',
    manual: true,
    cancelToken: updateCancelToken(),
  })

  async function getDropDownLists() {
    if (!dropDownDataRaw) return
    if (!dropDownDataRaw.hasOwnProperty('toolTypes')) return
    if (!dropDownDataRaw.hasOwnProperty('connectionTypes')) return
    if (!Array.isArray(dropDownDataRaw.connectionTypes)) return
    if (!Array.isArray(dropDownDataRaw.toolTypes)) return

    let newListData = { toolTypes: [], connectionTypes: [] }
    dropDownDataRaw.connectionTypes.forEach((conn) => {
      let index = newListData.connectionTypes.findIndex((element) => element.value === conn)

      if (index < 0) {
        newListData.connectionTypes.push({ label: conn, value: `${conn}` })
      }
    })

    dropDownDataRaw.toolTypes.forEach((tool) => {
      newListData.toolTypes.push({ label: tool.description, value: `${tool.description}` })
    })

    if (_isMounted.current === true) setDropDownLists(newListData)
  }

  useEffect(() => {
    if (errorUpdate !== undefined && errorUpdate !== null) {
      if (cancelUpdateReq(errorUpdate)) return
    }
  }, [errorUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorCreate !== undefined && errorCreate !== null) {
      if (cancelCreateReq(errorCreate)) return
    }
  }, [errorCreate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getDropDownLists()
  }, [dropDownDataRaw]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (dropDownError !== undefined && dropDownError !== null) {
      if (cancelPreviousDropDownRequest(dropDownError)) return
    }
  }, [dropDownError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error !== undefined && error !== null) {
      if (isCancel(error)) return
    }
  }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data) {
      const filtered = handleSearch(searchText)
      setFilteredList(filtered)
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      const filtered = handleSearch(searchText)
      setFilteredList(filtered)
    }
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleteError !== undefined && deleteError !== null) {
      if (cancelPreviousDeleteRequest(deleteError)) return
    }
  }, [deleteError]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = (text) => {
    if (text) {
      const filterList = _filter(data, (item) => {
        return searchFunction(item, text, [
          'serialNum',
          'make',
          'model',
          'toolType',
          'description',
          'connectionTop',
          'connectionBottom',
          'comments',
        ])
      })
      return filterList
    } else {
      return data
    }
  }

  const renderTextField = (text, value) => {
    if (text === 'D&C Hrs:' || text === 'Total Hrs:') {
      return (
        <div className={classes.itemTextContainer}>
          <div className={classes.itemLabelText}>{text}</div>
          <div className={classes.itemText}>{value}</div>
        </div>
      )
    } else {
      return (
        <div className={classes.itemTextContainer}>
          <div className={classes.itemLabelText}>{text}</div>
          <div className={classes.itemText}>{renderTextColor(text, value)}</div>
        </div>
      )
    }
  }

  const renderTextColor = (text, value, color) => {
    return (
      <div className={classes.itemTextContainer}>
        <div className={classes.itemLabelText}>{text}</div>
        <div className={classes.itemText}>
          <span style={{ color: color ? color : appColors.itemTextColorMobile }}>{value}</span>
        </div>
      </div>
    )
  }

  const sumMotorConditions = (data) => {
    if (!Array.isArray(data)) return
    let sumClean = 0
    let sumDirty = 0
    data.forEach((item) => {
      if (item.compType === 6 && item.dirty) sumDirty++
      if (item.compType === 6 && !item.dirty) sumClean++
    })
    return (
      <>
        <div className={classes.motorSumContainer}>
          #Motors - Clean:<span style={{ color: appColors.itemTextColorMobile, marginLeft: 5 }}>{sumClean}</span>
        </div>
        <div className={classes.motorSumContainer}>
          #Motors - Dirty:<span style={{ color: appColors.itemTextColorMobile, marginLeft: 5 }}>{sumDirty}</span>
        </div>
      </>
    )
  }

  const onClickDeleteItem = (item) => {
    setConfirm({
      show: true,
      title: 'Delete Inventory Item',
      text: `Are you sure you want to delete ${item?.description}?`,
      itemIndex: parseInt(item?.uid),
    })
  }

  const confirmDelete = async () => {
    if (confirm?.itemIndex >= 0) {
      const deleteResponse = await deleteInvItem({
        uid: confirm?.itemIndex,
        wellName: currentWell,
      })

      if (!deleteResponse.error) {
        setStatus({ show: true, severity: 'success', message: 'Item deleted successfully' })
        await getInventory()
      } else {
        setStatus({ show: true, severity: 'error', message: 'Delete item failed' })
      }
    }
  }

  const renderDataItem = (item, idx) => {
    return (
      <div className={classes.title}>
        <span style={{ marginLeft: 5, marginTop: 8 }}>{unescapeHtml(item.description)} - </span>
        <span style={{ color: getComponentColor(item.toolType) }}> {item.toolType}</span>
        <div className={classes.itemMainContainer}>
          <div
            className={classes.itemTextContainer}
            onClick={() => {
              editIndex.current = idx
              setShowEditor(true)
            }}>
            <div className={classes.avatarContainer}>
              <Avatar
                alt={item.toolType}
                src={getDrillStringIcon(item.toolType)}
                style={{ minWidth: 50, height: 50 }}
              />
            </div>
            <div className={classes.itemTextWrapContainer}>
              {renderTextField('Make:', item.make)}
              {renderTextField('Model:', item.model)}
            </div>
            <div className={classes.itemTextWrapContainer}>
              {renderTextField('SN:', item.serialNum)}
              {renderTextColor('Status:', item.status, item.status === 'Clean' ? '#66FF00' : '#ff0000')}
            </div>
            <div className={classes.itemTextWrapContainer}>
              {renderTextField('OD:', round(item.od, 2))}
              {renderTextField('Length:', round(item.length, 2))}
            </div>
            <div className={classes.itemTextWrapContainer}>
              {renderTextField('In Hole:', item.inHole)}
              {renderTextField('D&C Hrs:', round(item.toolHoursWell, 2))}
            </div>
            <div className={classes.itemTextWrapContainer}>
              {renderTextField('Arrived:', item.dateOn)}
              {renderTextField('Left:', item.dateOff)}
            </div>
            <div className={classes.itemTextWrapContainer}>
              {renderTextField('CNX Top:', item.connectionTop)}
              {renderTextField('CNX Btm:', item.connectionBottom)}
            </div>
            <div className={classes.itemTextWrapContainer}>
              {renderTextColor(
                'Failed:',
                item.failed ? 'Yes' : '',
                item.failed ? '#ff0000' : appColors.itemTextColorMobile,
              )}
              {renderTextField('Total Hrs:', round(item.totalToolHours, 2))}
            </div>
          </div>
          {checkPermission('canDelete', userRole.roleAttributes?.permissions) ? (
            <Tooltip title='Delete item' placement='bottom' classes={{ tooltip: classes.tooltip }}>
              <IconButton className={classes.itemIconContainer} onClick={() => onClickDeleteItem(item)}>
                <Iconify icon='fa-regular:trash-alt' className={classes.deleteIconStyle} />
              </IconButton>
            </Tooltip>
          ) : null}
        </div>
      </div>
    )
  }

  const onXlsxExport = () => {
    let headers = []
    headers.push('#')
    headers.push('Tool Type')
    headers.push('Description')
    headers.push('Make')
    headers.push('Model')
    headers.push('Serial #')
    headers.push('Status')
    headers.push('OD')
    headers.push('Length')
    headers.push('In Hole')
    headers.push('Tool Hrs (Well)')
    headers.push('Date On')
    headers.push('Date Off')
    headers.push('Cnx Top')
    headers.push('Cnx Btm')
    headers.push('Failed')
    headers.push('Total Hrs')

    let ws = XLSX.utils.aoa_to_sheet([headers], {
      origin: 'A1',
    })

    if (Array.isArray(data) && data.length > 0) {
      let xlExport = []
      for (let i = 0; i < data.length; i++) {
        let newRow = []
        newRow.push(i + 1) //#
        newRow.push(data[i].toolType)
        newRow.push(data[i].description)
        newRow.push(data[i].make)
        newRow.push(data[i].model)
        newRow.push(data[i].serialNum)
        newRow.push(data[i].status)
        newRow.push(data[i].od)
        newRow.push(data[i].length)
        newRow.push(data[i].inHole)
        newRow.push(data[i].toolHoursWell)
        newRow.push(data[i].dateOn)
        newRow.push(data[i].dateOff)
        newRow.push(data[i].connectionTop)
        newRow.push(data[i].connectionBottom)
        newRow.push(data[i].failed)
        newRow.push(data[i].totalToolHours)
        xlExport.push(newRow)
      }

      XLSX.utils.sheet_add_aoa(ws, xlExport, { origin: 'A2' })
    }
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Inventory')
    XLSX.writeFile(wb, 'Inventory.xlsx')
  }

  const handleCloseStatus = (event, reason) => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const handleNewItem = () => {
    setShowEditor(true)
  }

  const handleClose = () => {
    setShowEditor(false)
  }

  const submitFunction = async (data, formikActions) => {
    setShowEditor(false)
    if (!data) return false
    let isAdd = false
    if (editIndex.current < 0) {
      isAdd = true
    }
    let payload = { ...data }

    let saveResponse
    if (isAdd) {
      saveResponse = await addItem(payload, createCancelToken())
    } else {
      saveResponse = await updateItem(payload, updateCancelToken())
    }

    if (saveResponse.error) {
      setStatus({ show: true, severity: 'error', message: isAdd ? 'Item failed to add' : 'Item update failed' })
      formikActions?.setSubmitting(false)
      return false
    } else {
      setStatus({ show: true, severity: 'success', message: isAdd ? 'Item added' : 'Item updated' })
    }

    await getInventory()
    return true
  }

  const RenderCreateUpdateModal = ({ data, submitFunction, cancelFunction }) => {
    let useInputData = true
    if (!data) useInputData = false
    if (useInputData) if (data.hasOwnProperty('uid') === false) useInputData = false

    const formValidationSchema = yup.object({
      toolType: yup.string().required(),
      description: yup.string(),
      dateOn: yup
        .string()
        .matches(
          /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
          'Incorrect format dd/mm/yyyy or mm/dd/yyyy',
        ),
      dateOff: yup
        .string()
        .matches(
          /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
          'Incorrect format dd/mm/yyyy or mm/dd/yyyy',
        ),
      od: yup.string().matches(/^[+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed'),
      id: yup.string().matches(/^[+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed'),
      length: yup.string().matches(/^[+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed'),
      weight: yup.string().matches(/^[+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed'),
      prevToolHours: yup.string().matches(/^[+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed'),
    })

    const title = (useInputData ? 'Edit' : 'Add') + ' inventory item'

    let formData = [
      {
        tag: 'wellName',
        value: currentWell,
        inputType: '-',
      },
      {
        tag: 'uid',
        value: useInputData === true ? (typeof data.uid === 'number' ? data.uid.toString() : '') : '',
        inputType: '-',
      },
      {
        tag: 'fieldStatus',
        value: useInputData === true ? data.fieldStatus : '',
        inputType: '-',
      },
      {
        tag: 'shopStatus',
        value: useInputData === true ? data.shopStatus : '',
        inputType: '-',
      },
      {
        tag: 'toolType',
        value: useInputData === true ? data.toolType : '',
        text: 'Tool Type',
        inputType: 'dropdown',
        dropDownValues: dropDownLists.toolTypes,
      },
      {
        tag: 'description',
        value: useInputData === true ? data.description : '',
        text: 'Description',
        inputType: 'text',
      },
      {
        tag: 'serialNum',
        value: useInputData === true ? data.serialNum : '',
        text: 'Serial#',
        inputType: 'text',
      },
      {
        tag: 'dateOn',
        value: useInputData === true ? data.dateOn : '',
        text: 'Date Arrived',
        inputType: 'text',
      },
      {
        tag: 'dateOff',
        value: useInputData === true ? data.dateOff : '',
        text: 'Date Left',
        inputType: 'text',
      },
      {
        tag: 'od',
        value: useInputData === true ? (typeof data.od === 'number' ? data.od.toString() : '') : '',
        text: 'OD',
        inputType: 'text',
      },
      {
        tag: 'id',
        value: useInputData === true ? (typeof data.id === 'number' ? data.id.toString() : '') : '',
        text: 'ID',
        inputType: 'text',
      },
      {
        tag: 'length',
        value: useInputData === true ? (typeof data.length === 'number' ? data.length.toString() : '') : '',
        text: 'Length',
        inputType: 'text',
      },
      {
        tag: 'weight',
        value: useInputData === true ? (typeof data.weight === 'number' ? data.weight.toString() : '') : '',
        text: 'Weight',
        inputType: 'text',
      },
      {
        tag: 'connectionTop',
        value: useInputData === true ? data.connectionTop : '',
        text: 'Connection Top',
        inputType: 'dropdown',
        dropDownValues: dropDownLists.connectionTypes,
        useLabel: true,
      },
      {
        tag: 'connectionBottom',
        value: useInputData === true ? data.connectionBottom : '',
        text: 'Connection Bottom',
        inputType: 'dropdown',
        dropDownValues: dropDownLists.connectionTypes,
        useLabel: true,
      },
      {
        tag: 'location',
        value: useInputData === true ? data.location : '',
        text: 'Location',
        inputType: 'text',
      },
      {
        tag: 'basket',
        value: useInputData === true ? data.basket : '',
        text: 'Basket',
        inputType: 'text',
      },
      {
        tag: 'comments',
        value: useInputData === true ? data.comments : '',
        text: 'Comments',
        inputType: 'text',
      },
      {
        tag: 'make',
        value: useInputData === true ? data.make : '',
        text: 'Make',
        inputType: 'text',
      },
      {
        tag: 'model',
        value: useInputData === true ? data.model : '',
        text: 'Model',
        inputType: 'text',
      },
      {
        tag: 'prevToolHours',
        value:
          useInputData === true ? (typeof data.prevToolHours === 'number' ? data.prevToolHours.toString() : '') : '',
        text: 'Previous Hours',
        inputType: 'text',
      },
      {
        tag: 'readyForBackload',
        value: useInputData === true ? data.readyForBackload : false,
        text: 'Ready for Backload',
        inputType: 'boolean',
      },
      {
        tag: 'failed',
        value: useInputData === true ? data.failed : false,
        text: 'Failed',
        inputType: 'boolean',
      },
    ]

    return (
      <InputModal
        open={showEditor}
        onClose={cancelFunction}
        title={title}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={cancelFunction}
        validationSchema={formValidationSchema}
      />
    )
  }

  //main render
  return (
    <>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => confirmDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      {showEditor ? (
        <RenderCreateUpdateModal
          data={editIndex.current >= 0 ? data[editIndex.current] : {}}
          submitFunction={submitFunction}
          cancelFunction={handleClose}
        />
      ) : null}
      <div className={classes.motorStatusCountContainer}>{sumMotorConditions(data)}</div>
      <SearchBar
        value={searchText}
        onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
        onCancelSearch={() => setSearchText('')}
        style={{
          padding: '1px',
          margin: '1px',
          backgroundColor: 'rgb(17, 17, 17)',
        }}
      />
      <div className={classes.listContainer}>
        <SimpleStyledList listItems={filteredList} renderItem={renderDataItem}></SimpleStyledList>
      </div>
      <div className={classes.menuIcon}>
        <MenuButton
          actions={[
            {
              icon: <AddIcon />,
              name: 'Add',
              onClick: () => {
                editIndex.current = -1
                handleNewItem(true)
              },
            },
            { icon: <RefreshIcon />, name: 'Refresh', getInventory },
            { icon: <PrintIcon />, name: 'Print', onClick: onXlsxExport },
          ]}
        />
      </div>
      {status?.show ? (
        <Snackbar open={status?.show} autoHideDuration={2000} onClose={handleCloseStatus}>
          <MuiAlert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </MuiAlert>
        </Snackbar>
      ) : null}
    </>
  )
}

export default InventoryList
