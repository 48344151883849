import React, { useEffect } from 'react'

import ErrorModelsCard from './ErrorModelsCard'
import Page from '../common/Page'
import { currentPageAtom } from '../../atoms'
import { useSetRecoilState } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'

const ErrorModelsPage = ({ history }) => {
  const setActivePage = useSetRecoilState(currentPageAtom)

  useEffect(() => {
    setActivePage(PAGE_KEYS.errorModelsKey)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page maxWidth>
      <ErrorModelsCard history={history} />
    </Page>
  )
}

export default ErrorModelsPage
