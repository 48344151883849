import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Bar } from 'react-chartjs-2'
import { Icon as Iconify } from '@iconify/react'
import { chartSeriesColors } from 'utils'
import { Tooltip, IconButton } from '@material-ui/core'
import * as XLSX from 'xlsx'
import { useRecoilValue } from 'recoil'
import { actionBarWidthAtom } from 'atoms'

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: '#192734',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '80%',
    minHeight: '80px',
  },
  chartContainer: {
    display: 'flex',
    width: '100%',
    height: '69vh',
  },
  xlsxIcon: {
    minWidth: 24,
    position: 'fixed',
    top: '56px',
    left: (props) => `calc(${props.leftPos}px + 24px)`, // float the icon relative to the ActionBar, so it appears fixed...
    zIndex: 2,
  },
  tooltip: {
    backgroundColor: 'rgb(19,62,96)',
    fontSize: '12px',
    fontFamily: 'Roboto',
    zIndex: 2,
  },
}))

const chartOptions = {
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'right',
      labels: {
        boxWidth: 5,
      },
    },
  },
}

const WellSeekersStackedChart = ({ userData }) => {
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const classes = useStyles({ leftPos })

  const createChartData2 = () => {
    const summarized = summarizeData()
    const chartData = {
      labels: summarized.barLabels,
      datasets: [],
    }

    for (let i = 0; i < summarized.seriesLabels.length; i++) {
      let datasetItem = {}
      datasetItem.label = summarized.seriesLabels[i]

      let companyData = []
      for (let j = 0; j < summarized.values.length; j++) {
        companyData.push(summarized.values[j][i])
      }
      datasetItem.data = companyData
      datasetItem.backgroundColor = summarized.colors[i]
      chartData.datasets.push(datasetItem)
    }

    return chartData
  }

  const getCompanyValues = (summarized) => {
    let companyDataArray = []
    for (let i = 0; i < summarized.seriesLabels.length; i++) {
      let companyData = []
      for (let j = 0; j < summarized.values.length; j++) {
        companyData.push(summarized.values[j][i])
      }
      companyDataArray.push(companyData)
    }
    return companyDataArray
  }

  const summarizeData = () => {
    let output = {
      title: '',
      colors: [],
      values: [],
      companyValues: [],
      barLabels: [],
      seriesLabels: [],
      plottingMethods: [],
      excelLabels: [],
      minMaxAvgLabels: [],
      minMaxAvg: {},
    }

    if (userData?.length > 0) {
      output.seriesLabels = userData.reduce((distinctCompanys, dataPoint) => {
        if (distinctCompanys.findIndex((element) => element === dataPoint.companyID) < 0) {
          distinctCompanys.push(dataPoint.companyID)
        }

        return distinctCompanys
      }, [])

      output.barLabels = userData.reduce((distinctDates, dataPoint) => {
        if (distinctDates.findIndex((element) => element === dataPoint.dateTime) < 0) {
          distinctDates.push(dataPoint.dateTime)
        }

        return distinctDates
      }, [])

      output.seriesLabels.forEach((_, i) => {
        output.colors.push(chartSeriesColors[i])
      })

      let newDataValue = new Array(output.seriesLabels.length).fill(0)
      output.barLabels.forEach((_, i) => {
        output.values.push([...newDataValue])
      })

      output.barLabels.reverse()

      userData.forEach((company) => {
        let barIndex = output.barLabels.findIndex((element) => element === company.dateTime)
        let seriesIndex = output.seriesLabels.findIndex((element) => element === company.companyID)

        if (barIndex >= 0 && seriesIndex >= 0) output.values[barIndex][seriesIndex] = parseFloat(company.count)
      })

      output.barLabels.forEach((label, i) => {
        output.excelLabels.push(output.barLabels[i])
        const splitDate = label.split('-')
        if (Array.isArray(splitDate) === true) {
          if (splitDate.length > 2) {
            output.barLabels[i] = parseInt(splitDate[1]).toString() + '/' + parseInt(splitDate[2]).toString()
          }
        }
      })

      let minMaxAvg = {
        min: 0,
        avg: 0,
        max: 0,
        numDays: 0,
        dayTotals: [],
      }

      output.values.forEach((dayValues) => {
        minMaxAvg.numDays++
        minMaxAvg.dayTotals.push(dayValues.reduce((accumulator, currentValue) => accumulator + currentValue))
      })

      let itemArray = []
      let companyArray = getCompanyValues(output)
      output.companyValues = companyArray

      companyArray.forEach((companyValues) => {
        let minMaxAvgItem = {
          empty: '',
          min: 0,
          avg: 0,
          max: 0,
          numDays: 0,
        }
        minMaxAvgItem.min = Math.min(...companyValues)
        minMaxAvgItem.max = Math.max(...companyValues)
        minMaxAvgItem.avg = Math.round(companyValues.reduce((a, b) => a + b, 0) / companyValues.length)
        itemArray.push(minMaxAvgItem)
      })
      output.minMaxAvgLabels = itemArray

      minMaxAvg.min = Math.min(...minMaxAvg.dayTotals)
      minMaxAvg.max = Math.max(...minMaxAvg.dayTotals)
      minMaxAvg.avg = minMaxAvg.dayTotals.reduce((a, b) => a + b, 0)
      minMaxAvg.avg = minMaxAvg.numDays > 0 && minMaxAvg.avg > 0 && Math.round(minMaxAvg.avg / minMaxAvg.numDays)
      output.minMaxAvg = minMaxAvg
      output.title = `Min: ${minMaxAvg.min}, Avg: ${minMaxAvg.avg}, Max: ${minMaxAvg.max}`
    }

    return output
  }

  const onXlsxExport = () => {
    const data = summarizeData()
    let headers = []
    headers.push('#')
    headers.push('COMPANY')
    headers.push('MIN')
    headers.push('MAX')
    headers.push('AVG')
    headers.push('')
    data.excelLabels.forEach((element) => {
      headers.push(element)
    })

    let ws = XLSX.utils.aoa_to_sheet([headers], {
      origin: 'A1',
    })
    if (data && data.values) {
      let usageExport = []
      for (let i = 0; i < data.barLabels.length; i++) {
        let cpdRow = []

        if (data.minMaxAvgLabels[i]) {
          cpdRow.push(i + 1) //#
          cpdRow.push(data.seriesLabels[i]) //Company
          cpdRow.push(data.minMaxAvgLabels[i].min)
          cpdRow.push(data.minMaxAvgLabels[i].max)
          cpdRow.push(data.minMaxAvgLabels[i].avg)
          cpdRow.push(data.minMaxAvgLabels[i].empty)
        }
        //push empty, min, max, avg
        cpdRow = cpdRow.concat(data.companyValues[i])
        usageExport.push(cpdRow)
      }

      usageExport.push([])
      const totalRow = ['', 'TOTAL', data.minMaxAvg.min, data.minMaxAvg.max, data.minMaxAvg.avg, ''].concat(
        data.minMaxAvg.dayTotals,
      )

      usageExport.push(totalRow)
      //push an empty row before displaying totals
      XLSX.utils.sheet_add_aoa(ws, usageExport, { origin: 'A2' })
    }
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Usage Tracking')
    XLSX.writeFile(wb, 'Usage Tracking.xlsx')
  }

  return (
    <React.Fragment>
      <div className={classes.chartContainer}>
        <Bar type='bar' options={chartOptions} data={createChartData2()} />
      </div>
      <Tooltip title='Export to XLSX' placement='bottom' classes={{ tooltip: classes.tooltip }}>
        <IconButton className={classes.xlsxIcon} onClick={() => onXlsxExport()}>
          <Iconify icon='mdi:printer' style={{ color: 'white', fontSize: '24px' }} />
        </IconButton>
      </Tooltip>
    </React.Fragment>
  )
}

export default WellSeekersStackedChart
