import React, { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'

import Page from '../../common/Page'
import { currentPageAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import DrillLinkAdminCard from './DrillLinkAdminCard'

const DrillLinkAdminPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)

  useEffect(() => {
    setActivePage(PAGE_KEYS.adminInnovaDrillLinkKey)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page maxWidth>
      <DrillLinkAdminCard />
    </Page>
  )
}

export default DrillLinkAdminPage
