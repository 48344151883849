import axios from 'axios'
import { isDev } from './env'

export async function GetOilPrice(code) {
  let apiData = {}

  // 06-Jan-2022 - skip this api call when in dev mode:
  if (isDev) {
    const wtiData = {
      'status': 'success',
      'data': {
        'price': 79.73,
        'formatted': '$79.73',
        'currency': 'USD',
        'code': 'WTI_USD',
        'created_at': '2022-01-06T15:53:20.689Z',
        'type': 'spot_price',
      },
    }

    const brentData = {
      'status': 'success',
      'data': {
        'price': 82.15,
        'formatted': '$82.15',
        'currency': 'USD',
        'code': 'BRENT_CRUDE_USD',
        'created_at': '2022-01-06T15:54:20.868Z',
        'type': 'spot_price',
      },
    }
    console.log('oil price api dev mode', code)
    if (code === 'BRENT_CRUDE_USD') return brentData
    if (code === 'WTI_USD') return wtiData
    return null
  }

  try {
    const axiosParams = {
      method: 'get',
      baseURL: process.env.REACT_APP_OILPRICE_API_URL,
      timeout: 30000,
      url: '/v1/prices/latest?by_code=' + code,
      headers: {
        'Authorization': `Token ${process.env.REACT_APP_OILPRICE_API_KEY}`,
        'Content-Type': 'application/json',
      },
    }

    let response = await axios(axiosParams)
    apiData = await response.data
  } catch (err) {
    if (isDev === true) console.log(err.response)

    // Alert.alert('Oil Price API Error:' + err.message)
  }

  return apiData
}

export function createPostDataObject(data) {
  if (!data) return []

  let output = []
  for (const property in data) {
    let propName = property
    output.push({ tagName: propName, value: data[property] })
  }

  return output
}
