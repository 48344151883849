import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Avatar, Container, Tooltip } from '@material-ui/core'
import { useAuth0 } from '@auth0/auth0-react'

import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import useUserRole from 'components/common/hooks/useUserRole'

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  orgContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  orgAvatar: {
    width: '50px',
    height: '50px',
    backgroundColor: 'white',
    marginRight: 10,
  },
  orgStatus: {
    font: '1em roboto',
    fontWeight: 'bold',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  orgCaption: {
    font: '0.8em roboto',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  tooltip: {
    backgroundColor: 'rgb(19,62,96)',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
}))

const UserProfileCard = () => {
  const classes = useStyles()
  const [avatarImg, setAvatarImg] = useState('')
  const { user } = useAuth0()
  const { newCancelToken, isCancel } = useAxiosCancelToken()
  const [{ data, error }] = useAxios({
    url: 'user/orgIcon',
    responseType: 'arraybuffer', // need this for the bin to base64 conv to work...
    cancelToken: newCancelToken(),
  })
  const [userRole] = useUserRole()

  useEffect(() => {
    if (error !== undefined && error !== null) {
      if (isCancel(error)) return
    }
  }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data) {
      let base64ImageString = 'data:image/*;base64,' + Buffer.from(data, 'binary').toString('base64')
      setAvatarImg(base64ImageString)
    }
  }, [data])

  return (
    <React.Fragment>
      <Container className={classes.root}>
        <Tooltip title={'User profile'} placement='bottom' classes={{ tooltip: classes.tooltip }}>
          <div className={classes.orgContainer}>
            <Avatar alt='user' src={avatarImg} className={classes.orgAvatar}></Avatar>
            <div>
              <div className={classes.orgStatus}>Logged In</div>
              <div className={classes.orgCaption}>{user?.name}</div>
              <div className={classes.orgCaption}>{userRole?.organization}</div>
            </div>
          </div>
        </Tooltip>
      </Container>
    </React.Fragment>
  )
}

export default UserProfileCard
