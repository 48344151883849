import React, { useEffect, useRef } from 'react'

import PrintIcon from '@material-ui/icons/Print'
import RefreshIcon from '@material-ui/icons/Refresh'
import { makeStyles } from '@material-ui/styles'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { currentWellAtom, currentWellFieldCostAtom } from 'atoms'

import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import SimpleStyledList from 'components/common/SimpleStyledList'
import { appColors, isoStringToDateString } from 'utils'
import { numberWithCommas } from 'utils/stringFunctions'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { round } from 'utils/numberFunctions'
import * as XLSX from 'xlsx'
import MenuButton from 'components/common/MenuButton'

const useStyles = makeStyles((theme) => ({
  listContainer: {
    display: 'flex',
    overflow: 'hidden',
    height: 'calc(100vh - 130px)',
    maxHeight: 'calc(100vh - 130px)',
    border: '1px solid #000',
    borderRadius: '4px',
  },
  headerText: {
    fontSize: 18,
    fontWeight: '500',
    color: appColors.headerTextColor,
    textAlign: 'right',
    paddingRight: '8px',
    alignItems: 'center',
  },
  itemText: {
    fontSize: 18,
    color: appColors.itemTextColor,
    border: '',
    alignItems: 'center',
  },
  itemMainContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '8px',
    marginBottom: '1px',
    border: `1px solid`,
    borderColor: appColors.itemBackColor,
    backgroundColor: 'inherit',
    '&:hover': {
      borderColor: appColors.accentColor,
    },
    cursor: 'pointer',
  },
  itemTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    flex: 1,
  },
  itemTextWrapContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
  },
  itemIconContainer: {
    width: 100,
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltip: {
    backgroundColor: 'rgb(19,62,96)',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
  menuIcon: {
    backgroundColor: 'transparent',
    margin: '4px',
    padding: '12px',
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}))

const DailyCostHeaderList = ({ history }) => {
  const _isMounted = useRef(false)
  const classes = useStyles()
  const currentWell = useRecoilValue(currentWellAtom)
  const setCurrentFieldCost = useSetRecoilState(currentWellFieldCostAtom)
  const costUnit = useUnits(UNITS_FOR.Cost)

  const { newCancelToken: newDailyCostsCancelToken, cancelPreviousRequest: cancelPreviousDailyCostsRequest } =
    useAxiosCancelToken()
  const [{ data: costHeaders, error: costsError }, getCostHeaders] = useAxios({
    url: '/well/wellCost/getCostHeaders',
    data: {
      wellName: currentWell,
    },
    // manual: true,
    cancelToken: newDailyCostsCancelToken(),
  })

  const { newCancelToken: newDailyCostsAllCancelToken, cancelPreviousRequest: cancelPreviousDailyCostsAllRequest } =
    useAxiosCancelToken()
  const [{ data: costsAll, error: costsAllError }] = useAxios({
    url: '/well/wellCost/getCosts',
    data: {
      wellName: currentWell,
    },
    // manual: true,
    cancelToken: newDailyCostsAllCancelToken(),
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (costsError !== undefined && costsError !== null) {
      if (cancelPreviousDailyCostsRequest(costsError)) return
    }
  }, [costsError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (costsAllError !== undefined && costsAllError !== null) {
      if (cancelPreviousDailyCostsAllRequest(costsAllError)) return
    }
  }, [costsAllError]) // eslint-disable-line react-hooks/exhaustive-deps

  const showCosts = (index) => {
    setCurrentFieldCost({
      date: costHeaders[index].date,
      dailyReportId: costHeaders[index].dailyReportId,
    })
    history.push('/well-pages/costs/dailyCosts')
  }

  const renderTextField = (text, value) => {
    return (
      <div className={classes.itemTextContainer}>
        <div className={classes.headerText}>{text} </div>
        <div className={classes.itemText}>{value}</div>
      </div>
    )
  }

  const renderCostItem = (item, idx) => {
    return (
      <div className={classes.itemMainContainer}>
        <div className={classes.itemTextContainer} onClick={() => showCosts(idx)}>
          <div className={classes.itemTextWrapContainer}>
            {renderTextField('Report #: ', item.dailyReportNumber.toString())}
          </div>
          <div className={classes.itemTextWrapContainer}>
            {renderTextField('Date: ', isoStringToDateString(item.date))}
          </div>
          <div className={classes.itemTextWrapContainer}>
            {renderTextField(`Daily Cost: ${costUnit}`, numberWithCommas(round(item.totalCost, 2)))}
          </div>
        </div>
      </div>
    )
  }

  const onXlsxExport = () => {
    if (!costsAll) return
    if (!costsAll.hasOwnProperty('dailyCosts')) return
    if (!Array.isArray(costsAll.dailyCosts)) return
    if (costsAll.dailyCosts.length === 0) return

    let headers = []
    headers.push('Date')
    headers.push('Cost Code')
    headers.push('Description')
    headers.push('Quantity')
    headers.push('Cost Per Unit')
    headers.push('Total Cost')
    headers.push('BHA#')
    headers.push('SN')

    let ws = XLSX.utils.aoa_to_sheet([headers], {
      origin: 'A1',
    })

    let xlExport = []
    for (let i = 0; i < costsAll.dailyCosts.length; i++) {
      if (!costsAll.dailyCosts[i].hasOwnProperty('costs')) continue
      if (!Array.isArray(costsAll.dailyCosts[i].costs)) continue
      for (let j = 0; j < costsAll.dailyCosts[i].costs.length; j++) {
        let newRow = []
        newRow.push(costsAll.dailyCosts[i].date)
        newRow.push(costsAll.dailyCosts[i].costs[j].costCode)
        newRow.push(costsAll.dailyCosts[i].costs[j].description)
        newRow.push(costsAll.dailyCosts[i].costs[j].quantity)
        newRow.push(costsAll.dailyCosts[i].costs[j].value)
        newRow.push(costsAll.dailyCosts[i].costs[j].totalValue)
        newRow.push(costsAll.dailyCosts[i].costs[j].bha)
        newRow.push(costsAll.dailyCosts[i].costs[j].sn)
        xlExport.push(newRow)
      }
    }

    XLSX.utils.sheet_add_aoa(ws, xlExport, { origin: 'A2' })

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Costs')

    headers = []
    headers.push('Report#')
    headers.push('Date')
    headers.push('Total Cost')

    let ws2 = XLSX.utils.aoa_to_sheet([headers], {
      origin: 'A1',
    })

    xlExport = []
    for (let i = 0; i < costsAll.dailyCosts.length; i++) {
      let newRow = []
      newRow.push(costsAll.dailyCosts[i].dailyReportNumber)
      newRow.push(costsAll.dailyCosts[i].date)
      newRow.push(costsAll.dailyCosts[i].totalCost)
      xlExport.push(newRow)
    }

    XLSX.utils.sheet_add_aoa(ws2, xlExport, { origin: 'A2' })

    XLSX.utils.book_append_sheet(wb, ws2, 'Costs per day')

    XLSX.writeFile(wb, 'Costs.xlsx')
  }

  return (
    <React.Fragment>
      <div className={classes.listContainer}>
        <SimpleStyledList listItems={costHeaders} renderItem={renderCostItem}></SimpleStyledList>
      </div>

      <div className={classes.menuIcon}>
        <MenuButton
          actions={[
            {
              icon: <PrintIcon />,
              name: 'Print',
              onClick: onXlsxExport,
            },
            { icon: <RefreshIcon />, name: 'Refresh', onClick: getCostHeaders },
          ]}
        />
      </div>
    </React.Fragment>
  )
}

export default DailyCostHeaderList
