import React, { useEffect, useState, useRef } from 'react'

import { makeStyles } from '@material-ui/styles'
import SearchBar from 'material-ui-search-bar'
import Avatar from '@material-ui/core/Avatar'
import { Collapse, IconButton, Tooltip, Snackbar, Typography } from '@material-ui/core'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { Icon as Iconify } from '@iconify/react'

import { filter as _filter } from 'lodash'
import { appColors } from 'utils'
import { useRecoilValue } from 'recoil'
import InputModal from 'components/common/InputModal'
import * as yup from 'yup'
import AddIcon from '@material-ui/icons/Add'
import RefreshIcon from '@material-ui/icons/Refresh'
import MuiAlert from '@material-ui/lab/Alert'
import { checkPermission } from '../userPermissions'
import { userUserRoleAtom } from 'atoms'
import { searchFunction } from '../common/searchFunctions'
import multiParse from 'utils/multiParse'
import PickListVirtualDialog from '../common/PickListVirtualDialog'

import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'

import SimpleStyledList from 'components/common/SimpleStyledList'
import MenuButton from 'components/common/MenuButton'
import wellImage from '../../assets/wellScreenIcons/wellHeadBlue.png'
import wellBoreImage from '../../assets/wellScreenIcons/schematicBlue.png'
import logImage from '../../assets/wellScreenIcons/wellLogBlue.png'
import surveysImage from '../../assets/wellScreenIcons/surveys-blue.png'
import curveMapImage from '../../assets/wellScreenIcons/analyticsblue.png'

const useStyles = makeStyles((theme) => ({
  avatarContainer: {
    margin: 5,
    marginRight: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'lightgrey',
    borderRadius: '50%',
  },
  listContainer: {
    display: 'flex',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(calc(100vh - 100px) * 1.00)',
    maxHeight: 'calc(calc(100vh - 210px) * 1.00)',
    borderRadius: '4px',
    backgroundColor: theme.palette.itemBackground,
    width: '100%',
  },
  headerText: {
    fontSize: 14,
    fontWeight: '500',
    color: appColors.headerTextColor,
    paddingRight: '8px',
  },
  itemText: {
    maxWidth: '200px',
    fontSize: 14,
    color: appColors.itemTextColor,
    border: '',
    alignItems: 'center',
  },
  title: {
    marginTop: 2,
    font: '18px Roboto',
    color: appColors.itemTextColorMobile,
    textAlign: 'left',
    width: '98%',
  },
  redIconStyle: {
    color: '#C00000',
    fontSize: '24px',
  },
  greenIconStyle: {
    color: '#00C000',
    fontSize: '24px',
  },
  greyIconStyle: {
    color: appColors.headerTextColor,
    fontSize: '24px',
  },
  navigateIconStyle: {
    color: appColors.headerTextColor,
    fontSize: '24px',
  },
  fixedListContainer: {
    display: 'flex',
    borderRadius: '4px',
    backgroundColor: theme.palette.itemBackground,
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#136cb9', //'darkgrey', //'rgba(0,0,0,.1)',
      outline: '1px solid #1679d0',
    },
    overflow: 'auto',
  },
  itemMainContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'left',
    width: 'calc(100% - 10px)',
  },
  witsmlContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mainColContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '4px',
    borderRadius: 5,
    marginLeft: '8px',
    background: 'linear-gradient(to bottom, #2d2d2d 0%, #1c1c1c 100%)',
    marginBottom: '4px',
    width: 'calc(100% - 10px)', // 8px for marginLeft, 2px for left & right border
    backgroundColor: appColors.itemBackColor,
  },
  itemSubContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '8px',
    marginBottom: '1px',
    border: '1px solid transparent',
    backgroundColor: 'transparent',
    '&:hover': {
      borderColor: appColors.accentColor,
      border: `1px solid`,
    },
  },
  itemTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    marginRight: 'auto',
  },
  itemTextWrapContainer: {
    flexDirection: 'column',
    flex: 3,
    marginLeft: '5px',
    marginRight: '5px',
  },
  itemIconContainer: {
    width: 100,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginHorizontal: 25,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltip: {
    backgroundColor: 'rgb(19,62,96)',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
  menuIcon: {
    backgroundColor: 'transparent',
    margin: '4px',
    padding: '12px',
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2) - 20,
  },
  image: {
    height: '50px',
    width: '50px',
  },
}))

const DataAcquisitionList = () => {
  const classes = useStyles()
  const _isMounted = useRef(true)
  const [openIndex, setOpenIndex] = useState(-1)
  const [orgIconList, setOrgIconList] = useState({})
  const [defaultIcon, setDefaultIcon] = useState('')
  const userRole = useRecoilValue(userUserRoleAtom)
  const [searchText, setSearchText] = useState('')
  const [filteredList, setFilteredList] = useState([])
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [showEditor, setShowEditor] = useState(false)
  const [showWmlWellModal, setShowWmlWellModal] = useState(false)
  const [showWmlWellBoreModal, setShowWmlWellBoreModal] = useState(false)
  const [showWmlLogModal, setShowWmlLogModal] = useState(false)
  const [showWmlTrajModal, setShowWmlTrajModal] = useState(false)
  const [showWmlCrvMapModal, setShowWmlCrvMapModal] = useState(false)
  const currentWmlParams = useRef(null)
  const currentWmlItem = useRef(null)
  const [witsmlWells, setWitsmlWell] = useState([])
  const [witsmlWellBores, setWitsmlWellbore] = useState([])
  const [witsmlLogs, setWitsmlLogs] = useState([])
  const [witsmlTraj, setWitsmlTraj] = useState([])
  const [showEditWellModal, setShowEditWellModal] = useState(false)
  const [witsmlCrvMap, setWitsmlCrvMap] = useState([])
  const editIndex = useRef(-1)
  const [dropDownLists, setDropDownLists] = useState({
    witsmlServers: [],
    dataStores: [],
    wellNames: [],
    dataSources: [],
    dataStoreSources: [],
    wellNamesSectionList: [],
  })

  const { newCancelToken: iconCancelToken, cancelPreviousRequest: cancelIconRequest } = useAxiosCancelToken()
  const [{ data: dataOrgIcons, error: iconError }] = useAxios({
    url: '/admin/getOrgIcons',
    cancelToken: iconCancelToken(),
  })

  const { newCancelToken, isCancel } = useAxiosCancelToken()
  const [{ data, error }, getDataAcq] = useAxios({
    url: '/dataAcq/getConfigs',
    cancelToken: newCancelToken(),
  })

  const { newCancelToken: newCancelTokenDropDowns, cancelPreviousRequest: cancelPreviousDropDownRequest } =
    useAxiosCancelToken()
  const [{ data: dropDownDataRaw, error: dropDownError }] = useAxios({
    url: '/dataAcq/getDropDownLists',
    cancelToken: newCancelTokenDropDowns(),
  })

  const { newCancelToken: newDeleteCancelToken, cancelPreviousRequest: cancelPreviousDeleteRequest } =
    useAxiosCancelToken()
  const [{ error: deleteError }, deleteItem] = useAxios({
    url: '/dataAcq/deleteConfig',
    manual: true,
    cancelToken: newDeleteCancelToken(),
  })

  const { newCancelToken: createCancelToken, cancelPreviousRequest: cancelCreateReq } = useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorCreate }, addItem] = useAxios({
    url: '/dataAcq/addConfigs',
    manual: true,
    cancelToken: createCancelToken(),
  })

  const { newCancelToken: updateCancelToken, cancelPreviousRequest: cancelUpdateReq } = useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorUpdate }, updateItem] = useAxios({
    url: '/dataAcq/updateConfigs',
    manual: true,
    cancelToken: updateCancelToken(),
  })

  const { newCancelToken: stopCancelToken, cancelPreviousRequest: cancelStopRequest } = useAxiosCancelToken()
  const [{ error: stopError }, stopRead] = useAxios({
    url: '/dataAcq/stopRead',
    manual: true,
    cancelToken: stopCancelToken(),
  })

  const { newCancelToken: startCancelToken, cancelPreviousRequest: cancelStartRequest } = useAxiosCancelToken()
  const [{ error: startError }, startRead] = useAxios({
    url: '/dataAcq/startRead',
    manual: true,
    cancelToken: startCancelToken(),
  })

  const { newCancelToken: newWmlWellCancelToken, cancelPreviousRequest: cancelPreviousWmlWellRequest } =
    useAxiosCancelToken()
  const [{ data: wmlWellsRaw, error: wmlWellError }, getWmlWells] = useAxios({
    url: '/dataAcq/witsml/getWells',
    manual: true,
    cancelToken: newWmlWellCancelToken(),
  })

  const { newCancelToken: newWmlWellBoreCancelToken, cancelPreviousRequest: cancelPreviousWmlWellBoreRequest } =
    useAxiosCancelToken()
  const [{ data: wmlWellBoresRaw, error: wmlWellBoreError }, getWmlWellBore] = useAxios({
    url: '/dataAcq/witsml/getWellBores',
    manual: true,
    cancelToken: newWmlWellBoreCancelToken(),
  })

  const { newCancelToken: newWmlLogCancelToken, cancelPreviousRequest: cancelPreviousWmlLogRequest } =
    useAxiosCancelToken()
  const [{ data: wmlLogsRaw, error: wmlLogError }, getWmlLogs] = useAxios({
    url: '/dataAcq/witsml/getLogs',
    manual: true,
    cancelToken: newWmlLogCancelToken(),
  })

  const { newCancelToken: newWmlTrajCancelToken, cancelPreviousRequest: cancelPreviousWmlTrajRequest } =
    useAxiosCancelToken()
  const [{ data: wmlTrajRaw, error: wmlTrajError }, getWmlTraj] = useAxios({
    url: '/dataAcq/witsml/getTrajectorys',
    manual: true,
    cancelToken: newWmlTrajCancelToken(),
  })

  const { newCancelToken: updateCancelWmlWellToken, cancelPreviousRequest: cancelUpdateWmlWellReq } =
    useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorUpdateWmlWell }, updateWmlWellSel] = useAxios({
    url: '/dataAcq/witsml/updateWitsmlWell',
    manual: true,
    cancelToken: updateCancelWmlWellToken(),
  })

  const { newCancelToken: updateCancelWmlWellBoreToken, cancelPreviousRequest: cancelUpdateWmlWellBoreReq } =
    useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorUpdateWmlWellBore }, updateWmlWellBoreSel] = useAxios({
    url: '/dataAcq/witsml/updateWitsmlWellBore',
    manual: true,
    cancelToken: updateCancelWmlWellBoreToken(),
  })

  const { newCancelToken: updateCancelWmlLogToken, cancelPreviousRequest: cancelUpdateWmlLogReq } =
    useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorUpdateWmlLog }, updateWmlLogSel] = useAxios({
    url: '/dataAcq/witsml/updateWitsmlLog',
    manual: true,
    cancelToken: updateCancelWmlLogToken(),
  })

  const { newCancelToken: updateCancelWmlTrajToken, cancelPreviousRequest: cancelUpdateWmlTrajReq } =
    useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorUpdateWmlTraj }, updateWmlTrajSel] = useAxios({
    url: '/dataAcq/witsml/updateWitsmlTrajectory',
    manual: true,
    cancelToken: updateCancelWmlTrajToken(),
  })

  const { newCancelToken: updateCancelCrvMapToken, cancelPreviousRequest: cancelUpdateCrvMapReq } =
    useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorUpdateCrvMap }, updateCrvMap] = useAxios({
    url: '/dataAcq/witsml/updateWitsmlCurveMapping',
    manual: true,
    cancelToken: updateCancelCrvMapToken(),
  })

  const { newCancelToken: updateWellNameCancelToken, cancelPreviousRequest: updateWellNameCancelReq } =
    useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorUpdateWellName }, updateWellName] = useAxios({
    url: '/dataAcq/updateConfigWellName',
    manual: true,
    cancelToken: updateWellNameCancelToken(),
  })

  function getDropDownLists() {
    if (!dropDownDataRaw) return
    if (!dropDownDataRaw.hasOwnProperty('witsmlServers')) return

    let newList = {
      wellNamesSectionList: [],
      witsmlServers: [],
      dataStores: [],
      wellNames: [],
      dataSources: [],
      dataStoreSources: [],
    }

    if (Array.isArray(dropDownDataRaw.dataStoreSources)) {
      dropDownDataRaw.dataStoreSources.forEach((item, index) => {
        newList.dataStoreSources.push({ label: item, value: `${index}` })
      })
    }

    if (Array.isArray(dropDownDataRaw.dataSources)) {
      dropDownDataRaw.dataSources.forEach((item, index) => {
        newList.dataSources.push({ label: item, value: `${index}` })
      })
    }

    if (Array.isArray(dropDownDataRaw.dataStores)) {
      dropDownDataRaw.dataStores.forEach((item) => {
        let index = newList.dataStores.findIndex((element) => element.value === item.uid)
        if (index < 0) newList.dataStores.push({ label: item.storeName, value: `${item.uid}` })
      })
    }

    if (Array.isArray(dropDownDataRaw.witsmlServers)) {
      dropDownDataRaw.witsmlServers.forEach((item) => {
        let index = newList.witsmlServers.findIndex((element) => element.value === item.uid)
        if (index < 0) newList.witsmlServers.push({ label: item.serverName, value: `${item.uid}` })
      })
    }

    if (Array.isArray(dropDownDataRaw.wellNames)) {
      dropDownDataRaw.wellNames.forEach((item) => {
        let index = newList.wellNames.findIndex((element) => element.value === item.actualWell)
        if (index < 0) newList.wellNames.push({ label: item.actualWell, value: `${item.actualWell}` })
      })

      newList.wellNamesList = creatWellSectionList(dropDownDataRaw.wellNames)
    }

    if (_isMounted.current === true) setDropDownLists(newList)
  }

  function creatWellSectionList(wellNames) {
    let outputList = []
    if (!Array.isArray(wellNames)) return outputList
    if (wellNames.length === 0) return outputList

    wellNames.forEach((well) => {
      outputList.push({ desc: well.actualWell, id: well.actualWell })
    })

    return outputList
  }

  useEffect(() => {
    if (dataOrgIcons) {
      const mfpBoundary = dataOrgIcons.substring(2, dataOrgIcons.search('Content')).trim()
      let parts = multiParse(Buffer.from(dataOrgIcons), mfpBoundary)
      setOrgIconList(parts)

      if (!defaultIcon && orgIconList) {
        for (let i = 0; i < orgIconList.length; i++) {
          if (orgIconList[i].filename === 'innova') {
            let base64ImageString = 'data:image/*;base64,' + orgIconList[i].data
            setDefaultIcon(base64ImageString)
          }
        }
      }
    }
  }, [dataOrgIcons]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorUpdateWellName !== undefined && errorUpdateWellName !== null) {
      if (updateWellNameCancelReq(errorUpdateWellName)) return
    }
  }, [errorUpdateWellName]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorUpdateCrvMap !== undefined && errorUpdateCrvMap !== null) {
      if (cancelUpdateCrvMapReq(errorUpdateCrvMap)) return
    }
  }, [errorUpdateCrvMap]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorUpdateWmlTraj !== undefined && errorUpdateWmlTraj !== null) {
      if (cancelUpdateWmlTrajReq(errorUpdateWmlTraj)) return
    }
  }, [errorUpdateWmlTraj]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorUpdateWmlLog !== undefined && errorUpdateWmlLog !== null) {
      if (cancelUpdateWmlLogReq(errorUpdateWmlLog)) return
    }
  }, [errorUpdateWmlLog]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorUpdateWmlWell !== undefined && errorUpdateWmlWell !== null) {
      if (cancelUpdateWmlWellReq(errorUpdateWmlWell)) return
    }
  }, [errorUpdateWmlWell]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorUpdateWmlWellBore !== undefined && errorUpdateWmlWellBore !== null) {
      if (cancelUpdateWmlWellBoreReq(errorUpdateWmlWellBore)) return
    }
  }, [errorUpdateWmlWellBore]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (wmlTrajError !== undefined && wmlTrajError !== null) {
      if (cancelPreviousWmlTrajRequest(wmlTrajError)) return
    }
  }, [wmlTrajError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (wmlLogError !== undefined && wmlLogError !== null) {
      if (cancelPreviousWmlLogRequest(wmlLogError)) return
    }
  }, [wmlLogError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (wmlWellBoreError !== undefined && wmlWellBoreError !== null) {
      if (cancelPreviousWmlWellBoreRequest(wmlWellBoreError)) return
    }
  }, [wmlWellBoreError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (wmlWellError !== undefined && wmlWellError !== null) {
      if (cancelPreviousWmlWellRequest(wmlWellError)) return
    }
  }, [wmlWellError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (startError !== undefined && startError !== null) {
      if (cancelStartRequest(startError)) return
    }
  }, [startError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (stopError !== undefined && stopError !== null) {
      if (cancelStopRequest(stopError)) return
    }
  }, [stopError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (iconError !== undefined && iconError !== null) {
      if (cancelIconRequest(iconError)) return
    }
  }, [iconError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorUpdate !== undefined && errorUpdate !== null) {
      if (cancelUpdateReq(errorUpdate)) return
    }
  }, [errorUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorCreate !== undefined && errorCreate !== null) {
      if (cancelCreateReq(errorCreate)) return
    }
  }, [errorCreate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getDropDownLists()
  }, [dropDownDataRaw]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (dropDownError !== undefined && dropDownError !== null) {
      if (cancelPreviousDropDownRequest(dropDownError)) return
    }
  }, [dropDownError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error !== undefined && error !== null) {
      if (isCancel(error)) return
    }
  }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data) {
      const filtered = handleSearch(searchText)
      setFilteredList(filtered)
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      const filtered = handleSearch(searchText)
      setFilteredList(filtered)
    }
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleteError !== undefined && deleteError !== null) {
      if (cancelPreviousDeleteRequest(deleteError)) return
    }
  }, [deleteError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getWitsmlWells()
  }, [wmlWellsRaw]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getWitsmlWellBores()
  }, [wmlWellBoresRaw]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getWitsmlTraj()
  }, [wmlTrajRaw]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getWitsmlLogs()
  }, [wmlLogsRaw]) // eslint-disable-line react-hooks/exhaustive-deps

  function getWitsmlLogs() {
    if (!getWitsmlSuccess(wmlLogsRaw, 'logs')) {
      setWitsmlLogs([])
      return
    }

    let newLogData = []
    wmlLogsRaw.data.logs.forEach((log) => newLogData.push({ desc: log.logName, id: log.uuid }))

    let newCrvMapData = []
    if (currentWmlParams.current) {
      let logIndex = wmlLogsRaw.data.logs.findIndex((element) => element.uuid === currentWmlParams.current.logUuid)
      if (logIndex >= 0) {
        if (Array.isArray(wmlLogsRaw.data.logs[logIndex].curveData)) {
          wmlLogsRaw.data.logs[logIndex].curveData.forEach((crv) =>
            newCrvMapData.push({ label: crv.mnemonic, value: crv.mnemonic }),
          )
        }
      }
    }

    if (_isMounted.current === true) {
      setWitsmlCrvMap(newCrvMapData)
      setWitsmlLogs(newLogData)
    }
  }

  function getWitsmlWells() {
    if (!getWitsmlSuccess(wmlWellsRaw, 'wells')) {
      setWitsmlWell([])
      return
    }

    let newWellData = []
    wmlWellsRaw.data.wells.forEach((well) => newWellData.push({ desc: well.wellName, id: well.uuid }))
    if (_isMounted.current === true) setWitsmlWell(newWellData)
  }

  function getWitsmlWellBores() {
    if (!getWitsmlSuccess(wmlWellBoresRaw, 'wellBores')) {
      setWitsmlWellbore([])
      return
    }

    let newWellBoreData = []
    wmlWellBoresRaw.data.wellBores.forEach((wellBore) =>
      newWellBoreData.push({ desc: wellBore.wellBoreName, id: wellBore.uuid }),
    )

    if (_isMounted.current === true) setWitsmlWellbore(newWellBoreData)
  }

  function getWitsmlTraj() {
    if (!getWitsmlSuccess(wmlTrajRaw, 'trajectories')) {
      setWitsmlTraj([])
      return
    }

    let newTrajData = []
    wmlTrajRaw.data.trajectories.forEach((traj, index) => newTrajData.push({ desc: traj.name, id: traj.uuid }))

    if (_isMounted.current === true) setWitsmlTraj(newTrajData)
  }

  function getWitsmlSuccess(data, param) {
    if (!data) return false
    if (!data.hasOwnProperty('data')) return false
    if (!data.data.hasOwnProperty('success')) return false
    if (!data.data.success) return false
    if (!data.data.hasOwnProperty(param)) return false
    if (!Array.isArray(data.data[param])) return false
    if (data.data[param].length === 0) return false

    return true
  }

  const handleSearch = (text) => {
    if (text) {
      const filterList = _filter(data, (item) => {
        return searchFunction(item, text, ['actualWell', 'organization', 'readStatus'])
      })
      return filterList
    } else {
      return data
    }
  }

  const onClickDeleteItem = (item) => {
    setConfirm({
      show: true,
      title: 'Delete data acquisition config',
      text: `Are you sure you want to delete config for ${item?.actualWell}?`,
      itemIndex: parseInt(item?.uid),
    })
  }

  const confirmDelete = async () => {
    if (confirm?.itemIndex >= 0) {
      const deleteResponse = await deleteItem({
        uid: confirm?.itemIndex,
      })

      if (!deleteResponse.error) {
        setStatus({ show: true, severity: 'success', message: 'Item deleted successfully' })
        await getDataAcq()
      } else {
        setStatus({ show: true, severity: 'error', message: 'Delete item failed' })
      }
    }
  }

  const getCompanyIcon = (orgName) => {
    if (orgIconList.length > 0 && orgName) {
      const org = orgIconList.find((item) => item.filename === orgName.toLowerCase())
      if (org) {
        let base64ImageString = 'data:image/*;base64,' + org.data
        return base64ImageString
      } else {
        return defaultIcon
      }
    } else {
      return ''
    }
  }

  const renderTextFieldNoWrap = (text, value, color) => {
    return (
      <div className={classes.itemTextContainer}>
        <Typography noWrap style={{ color: appColors.headerTextColor, paddingRight: '5px' }}>
          {text}
        </Typography>
        <Typography noWrap style={{ color: color ? color : appColors.itemTextColor }}>
          {value}
        </Typography>
      </div>
    )
  }

  const getDataSourceText = (input) => {
    if (!input) return 'Unknown'
    if (typeof input === 'string') input = parseInt(input)
    if (input < 0) return 'Unknown'
    if (!dropDownLists) return 'Unknown'
    if (!dropDownLists.hasOwnProperty('dataSources')) return 'Unknown'
    if (!Array.isArray(dropDownLists.dataSources)) return 'Unknown'
    if (input >= dropDownLists.dataSources.length) return 'Unknown'
    return dropDownLists.dataSources[input].label
  }

  const getDataStoreText = (input) => {
    if (!input) return 'Default'
    if (typeof input === 'string') input = parseInt(input)
    if (input < 0) return 'Default'
    if (!dropDownLists) return 'Default'
    if (!dropDownLists.hasOwnProperty('dataStores')) return 'Default'
    if (!Array.isArray(dropDownLists.dataStores)) return 'Default'
    let index = dropDownLists.dataStores.findIndex((element) => element.uid === input)
    return index >= 0 ? dropDownLists.dataStores[index].label : 'Unknown'
  }

  const onClickStopStart = async (item) => {
    if (!item) return
    if (!item.hasOwnProperty('readStatus')) return

    if (item.readStatus !== 'STOPPED') await stopRead({ uid: item.uid })
    if (item.readStatus === 'STOPPED') await startRead({ uid: item.uid })
    await getDataAcq()
  }

  const openPickListModal = (selection, item) => {
    if (!item || !selection) return
    currentWmlItem.current = item
    currentWmlParams.current = getWitsmlServerParams(item)
    if (currentWmlParams.current === null) return
    if (selection === 'well') {
      getWmlWells(currentWmlParams.current)
      setShowWmlWellModal(true)
    }

    if (selection === 'wellbore') {
      if (typeof item.witsmlWell.uuid !== 'string' || item.witsmlWell.uuid === '') {
        setStatus({ show: true, severity: 'error', message: 'No WITSML Well selected' })
        return
      }

      getWmlWellBore(currentWmlParams.current)
      setShowWmlWellBoreModal(true)
    }

    if (selection === 'log') {
      if (typeof item.witsmlWellBore.uuid !== 'string' || item.witsmlWellBore.uuid === '') {
        setStatus({ show: true, severity: 'error', message: 'No WITSML Wellbore selected' })
        return
      }

      getWmlLogs(currentWmlParams.current)
      setShowWmlLogModal(true)
    }

    if (selection === 'trajectory') {
      if (typeof item.witsmlWellBore.uuid !== 'string' || item.witsmlWellBore.uuid === '') {
        setStatus({ show: true, severity: 'error', message: 'No WITSML Wellbore selected' })
        return
      }

      getWmlTraj(currentWmlParams.current)
      setShowWmlTrajModal(true)
    }

    if (selection === 'curvemap') {
      if (typeof item.witsmlWellBore.uuid !== 'string' || item.witsmlWellBore.uuid === '') {
        setStatus({ show: true, severity: 'error', message: 'No WITSML Wellbore selected' })
        return
      }

      getWmlLogs(currentWmlParams.current)
      setShowWmlCrvMapModal(true)
    }
  }

  const renderDataItem = (item, idx) => {
    return (
      <div className={classes.mainColContainer}>
        <div className={classes.itemMainContainer}>
          <div className={classes.title}>
            <span style={{ marginLeft: 5, marginTop: 8 }}>Well: </span>
            <span style={{ color: appColors.itemTextColor }}> {item.actualWell}</span>
            <div className={classes.itemSubContainer}>
              <div
                className={classes.itemTextContainer}
                onClick={() => {
                  editIndex.current = idx
                  setShowEditor(true)
                }}>
                <div className={classes.avatarContainer}>
                  <Avatar src={getCompanyIcon(item.organization)} style={{ minWidth: 50, height: 50 }}></Avatar>
                </div>
                <div className={classes.itemTextWrapContainer}>
                  {renderTextFieldNoWrap('Update (s):', item.readInterval)}
                  {renderTextFieldNoWrap('Status:', item.readStatus, item.readStatus === 'STOPPED' ? 'red' : 'green')}
                </div>
                <div className={classes.itemTextWrapContainer}>
                  {renderTextFieldNoWrap('Data Source:', getDataSourceText(item.dataInput))}
                  {renderTextFieldNoWrap('Data Store:', getDataStoreText(item.dataStore))}
                </div>
                <div className={classes.itemTextWrapContainer}>
                  {renderTextFieldNoWrap(
                    item.dataInput === 2 ? 'WML Well:' : '',
                    item.dataInput === 2 ? item.witsmlWell.wellName : '',
                  )}
                  {renderTextFieldNoWrap(
                    item.dataInput === 2 ? 'WML Wellbore:' : '',
                    item.dataInput === 2 ? item.witsmlWellBore.wellBoreName : '',
                  )}
                </div>
                <div className={classes.itemTextWrapContainer}>
                  {renderTextFieldNoWrap(
                    item.dataInput === 2 ? 'WML Log:' : '',
                    item.dataInput === 2 ? item.witsmlLogs.logName : '',
                  )}
                  {renderTextFieldNoWrap(
                    item.dataInput === 2 ? 'WML Traj:' : '',
                    item.dataInput === 2 ? item.witsmlTrajectory.name : '',
                  )}
                </div>
              </div>
              <Tooltip title={'Witsml Controls'} placement='bottom' classes={{ tooltip: classes.tooltip }}>
                <IconButton
                  className={classes.itemIconContainer}
                  onClick={() => {
                    if (item.dataInput === 2) {
                      setOpenIndex((prevVal) => (prevVal === idx ? -1 : idx))
                    }

                    if (item.dataInput !== 2) {
                      setStatus({ show: true, severity: 'info', message: 'Data source not set to WITSML' })
                    }
                  }}>
                  <Iconify
                    icon={openIndex === idx ? 'akar-icons:chevron-up' : 'akar-icons:chevron-down'}
                    className={classes.greyIconStyle}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title='Edit well' placement='bottom' classes={{ tooltip: classes.tooltip }}>
                <IconButton
                  className={classes.itemIconContainer}
                  onClick={() => {
                    currentWmlItem.current = item
                    setShowEditWellModal(true)
                  }}>
                  <Iconify icon='clarity:edit-solid' className={classes.greyIconStyle} />
                </IconButton>
              </Tooltip>
              <Tooltip
                title={item.readStatus === 'STOPPED' ? 'Start' : 'Stop'}
                placement='bottom'
                classes={{ tooltip: classes.tooltip }}>
                <IconButton className={classes.itemIconContainer} onClick={() => onClickStopStart(item)}>
                  <Iconify
                    icon={item.readStatus === 'STOPPED' ? 'bi:stop-circle-fill' : 'ant-design:play-circle-filled'}
                    className={item.readStatus === 'STOPPED' ? classes.redIconStyle : classes.greenIconStyle}
                  />
                </IconButton>
              </Tooltip>
              {checkPermission('canDelete', userRole.roleAttributes?.permissions) ? (
                <Tooltip title='Delete config model' placement='bottom' classes={{ tooltip: classes.tooltip }}>
                  <IconButton className={classes.itemIconContainer} onClick={() => onClickDeleteItem(item)}>
                    <Iconify icon='fa-regular:trash-alt' className={classes.redIconStyle} />
                  </IconButton>
                </Tooltip>
              ) : null}
            </div>
          </div>
        </div>
        <Collapse in={openIndex === idx} timeout='auto' unmountOnExit>
          {item.dataInput === 2 ? (
            <div className={classes.witsmlContainer}>
              <Tooltip title='Select WITSML well' placement='bottom' classes={{ tooltip: classes.tooltip }}>
                <IconButton className={classes.itemIconContainer} onClick={() => openPickListModal('well', item)}>
                  <img className={classes.image} src={wellImage} alt='WITSML Well' />
                </IconButton>
              </Tooltip>
              <Tooltip title='Select WITSML wellbore' placement='bottom' classes={{ tooltip: classes.tooltip }}>
                <IconButton className={classes.itemIconContainer} onClick={() => openPickListModal('wellbore', item)}>
                  <img className={classes.image} src={wellBoreImage} alt='WITSML Wellbore' />
                </IconButton>
              </Tooltip>
              <Tooltip title='Select WITSML log' placement='bottom' classes={{ tooltip: classes.tooltip }}>
                <IconButton className={classes.itemIconContainer} onClick={() => openPickListModal('log', item)}>
                  <img className={classes.image} src={logImage} alt='WITSML Log' />
                </IconButton>
              </Tooltip>
              <Tooltip title='Select WITSML trajectory' placement='bottom' classes={{ tooltip: classes.tooltip }}>
                <IconButton className={classes.itemIconContainer} onClick={() => openPickListModal('trajectory', item)}>
                  <img className={classes.image} src={surveysImage} alt='WITSML Trajectory' />
                </IconButton>
              </Tooltip>
              <Tooltip title='WITSML curve mapping' placement='bottom' classes={{ tooltip: classes.tooltip }}>
                <IconButton
                  className={classes.itemIconContainer}
                  onClick={() => {
                    editIndex.current = idx
                    openPickListModal('curvemap', item)
                  }}>
                  <img className={classes.image} src={curveMapImage} alt='WITSML Curve mapping' />
                </IconButton>
              </Tooltip>
            </div>
          ) : null}
        </Collapse>
      </div>
    )
  }

  const handleCloseStatus = (event, reason) => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const handleNewItem = () => {
    setShowEditor(true)
  }

  const handleClose = () => {
    setShowEditor(false)
  }

  const handleCloseCrvMap = () => {
    setShowWmlCrvMapModal(false)
  }

  const submitUpdateWell = async (data) => {
    if (!data) return false
    if (!Array.isArray(data)) return false
    if (data.length === 0) return false
    if (!Array.isArray(dropDownLists.wellNamesList)) return false

    let index = -1
    for (let i = 0; i < dropDownLists.wellNamesList.length; i++) {
      if (dropDownLists.wellNamesList[i].id === data[0]) {
        index = i
        break
      }
    }

    if (index < 0) return false

    let payload = {
      wellName: dropDownLists.wellNamesList[index].desc,
      uid: currentWmlItem.current?.uid,
    }

    let saveResponse = await updateWellName(payload, updateWellNameCancelToken())

    if (saveResponse.error) {
      setStatus({ show: true, severity: 'error', message: 'Well name update failed' })
      return false
    } else {
      setStatus({ show: true, severity: 'success', message: 'Well name updated' })
    }

    currentWmlItem.current = null
    await getDataAcq()
    return true
  }

  const submitFunction = async (data, formikActions) => {
    setShowEditor(false)
    if (!data) return false
    let isAdd = false
    if (editIndex.current < 0) {
      isAdd = true
    }
    let payload = { ...data }

    let saveResponse
    if (isAdd) {
      saveResponse = await addItem(payload, createCancelToken())
    } else {
      saveResponse = await updateItem(payload, updateCancelToken())
    }

    if (saveResponse.error) {
      setStatus({ show: true, severity: 'error', message: isAdd ? 'Item failed to add' : 'Item update failed' })
      formikActions?.setSubmitting(false)
      return false
    } else {
      setStatus({ show: true, severity: 'success', message: isAdd ? 'Item added' : 'Item updated' })
    }

    await getDataAcq()
    return true
  }

  const RenderCreateUpdateModal = ({ data, submitFunction, cancelFunction }) => {
    let useInputData = true
    if (!data) useInputData = false
    if (useInputData === true) {
      if (data.hasOwnProperty('uid') === false) useInputData = false
    }

    const formValidationSchema = yup.object({
      actualWell: yup.string().required(),
      dataInput: yup.string().nullable(),
      dataStore: yup.string().nullable(),
      witsmlServer: yup.string().nullable(),
      readInterval: yup
        .string()
        .matches(/^[+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed')
        .required('Read interval cannot be blank'),
      rpm: yup
        .string()
        .matches(/^[+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed')
        .required('Cannot be blank'),
      pressure: yup
        .string()
        .matches(/^[+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed')
        .required('Cannot be blank'),
      flow: yup
        .string()
        .matches(/^[+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed')
        .required('Cannot be blank'),
      hkld: yup
        .string()
        .matches(/^[+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed')
        .required('Cannot be blank'),
      onBtmDelta: yup
        .string()
        .matches(/^[+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed')
        .required('Cannot be blank'),
      wob: yup
        .string()
        .matches(/^[+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed')
        .required('Cannot be blank'),
      rpmBufferSize: yup
        .string()
        .matches(/^[+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed')
        .required('Cannot be blank'),
      rpmBufferThreshold: yup
        .string()
        .matches(/^[+]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed')
        .required('Cannot be blank'),
    })

    let formData = [
      {
        tag: 'uid',
        value: useInputData === true ? (typeof data.uid === 'number' ? data.uid.toString() : '') : '',
        inputType: '-',
      },
      {
        tag: 'actualWell',
        value: useInputData === true ? data.actualWell : '',
        text: 'Well Name',
        inputType: 'dropdown',
        useLabel: true,
        dropDownValues: dropDownLists.wellNames,
      },
      {
        tag: 'dataInput',
        value: useInputData === true ? data.dataInput.toString() : '0',
        text: 'Data Source',
        inputType: 'dropdown',
        useLabel: true,
        dropDownValues: dropDownLists.dataSources,
      },
      {
        tag: 'dataStoreSource',
        value: useInputData === true ? (data.dataStore >= 0 ? '1' : '0') : '0',
        text: 'Data Store Location',
        inputType: 'dropdown',
        useLabel: true,
        dropDownValues: dropDownLists.dataStoreSources,
      },
      {
        tag: 'dataStore',
        value: useInputData === true ? (data.dataStore >= 0 ? data.dataStore.toString() : '') : '',
        text: 'Data Store',
        inputType: 'dropdown',
        useLabel: true,
        dropDownValues: dropDownLists.dataStores,
        filterProperty: 'dataStoreSource',
        filterValue: '1',
      },
      {
        tag: 'witsmlServer',
        value: useInputData === true ? (data.witsmlServer >= 0 ? data.witsmlServer.toString() : '') : '',
        text: 'WITSML Server',
        inputType: 'dropdown',
        dropDownValues: dropDownLists.witsmlServers,
        useLabel: true,
        filterProperty: 'dataInput',
        filterValue: '2',
      },
      {
        tag: 'readInterval',
        value: useInputData === true ? data.readInterval : '5',
        text: 'Update Interval',
        inputType: 'text',
      },
      {
        tag: 'rpm',
        value: useInputData === true ? data.drillingThresholds.rpm : '50',
        text: 'RPM Threshold',
        inputType: 'text',
      },
      {
        tag: 'pressure',
        value: useInputData === true ? data.drillingThresholds.pressure : '100',
        text: 'Pressure Threshold',
        inputType: 'text',
      },
      {
        tag: 'flow',
        value: useInputData === true ? data.drillingThresholds.flow : '50',
        text: 'Flow Threshold',
        inputType: 'text',
      },
      {
        tag: 'hkld',
        value: useInputData === true ? data.drillingThresholds.hkld : '50',
        text: 'Hookload Threshold',
        inputType: 'text',
      },
      {
        tag: 'onBtmDelta',
        value: useInputData === true ? data.drillingThresholds.onBtmDelta : '0.5',
        text: 'On Btm Delta Threshold',
        inputType: 'text',
      },
      {
        tag: 'wob',
        value: useInputData === true ? data.drillingThresholds.wob : '1',
        text: 'WOB Threshold',
        inputType: 'text',
      },
      {
        tag: 'rpmBufferSize',
        value: useInputData === true ? data.drillingThresholds.rpmBufferSize : '10',
        text: 'RPM Buffer Size',
        inputType: 'text',
      },
      {
        tag: 'rpmBufferThreshold',
        value: useInputData === true ? data.drillingThresholds.rpmBufferThreshold : '3',
        text: 'RPM Buffer Threshold',
        inputType: 'text',
      },
    ]

    const title = (useInputData ? 'Edit' : 'Add') + ' data config'

    return (
      <InputModal
        open={showEditor}
        onClose={cancelFunction}
        title={title}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={cancelFunction}
        validationSchema={formValidationSchema}
      />
    )
  }

  const findCrvMapValue = (inputValue) => {
    if (!inputValue || !witsmlCrvMap) return ''
    if (typeof inputValue !== 'string') return ''
    if (!Array.isArray(witsmlCrvMap)) return ''
    let index = witsmlCrvMap.findIndex((element) => element.label === inputValue)
    if (index < 0) return ''
    return inputValue
  }

  const RenderCreateUpdateModalCrvMap = ({ data, submitFunction, cancelFunction }) => {
    let useInputData = true
    if (!data) useInputData = false
    if (useInputData === true) {
      if (data.hasOwnProperty('witsmlMapping') === false) useInputData = false
    }

    const formValidationSchema = yup.object({
      uid: yup.string().required(),
      bitDepth: yup.string().nullable(),
      holeDepth: yup.string().nullable(),
      wob: yup.string().nullable(),
      torque: yup.string().nullable(),
      rpm: yup.string().nullable(),
      hkld: yup.string().nullable(),
      pressure: yup.string().nullable(),
      diffPressure: yup.string().nullable(),
      flow: yup.string().nullable(),
      blockPosition: yup.string().nullable(),
      rop: yup.string().nullable(),
      mtf: yup.string().nullable(),
      gtf: yup.string().nullable(),
      contInc: yup.string().nullable(),
      contAzi: yup.string().nullable(),
      gamma: yup.string().nullable(),
      temperature: yup.string().nullable(),
    })

    let formData = [
      {
        tag: 'uid',
        value: typeof data.uid === 'number' ? data.uid.toString() : '',
        inputType: '-',
      },
      {
        tag: 'bitDepth',
        value: findCrvMapValue(data.witsmlMapping.bitDepth),
        text: 'Bit Depth',
        inputType: 'dropdown',
        dropDownValues: witsmlCrvMap,
        useLabel: true,
      },
      {
        tag: 'holeDepth',
        value: findCrvMapValue(data.witsmlMapping.holeDepth),
        text: 'Hole Depth',
        inputType: 'dropdown',
        dropDownValues: witsmlCrvMap,
        useLabel: true,
      },
      {
        tag: 'wob',
        value: findCrvMapValue(data.witsmlMapping.wob),
        text: 'WOB',
        inputType: 'dropdown',
        dropDownValues: witsmlCrvMap,
        useLabel: true,
      },
      {
        tag: 'torque',
        value: findCrvMapValue(data.witsmlMapping.torque),
        text: 'Torque',
        inputType: 'dropdown',
        dropDownValues: witsmlCrvMap,
        useLabel: true,
      },
      {
        tag: 'rpm',
        value: findCrvMapValue(data.witsmlMapping.rpm),
        text: 'RPM',
        inputType: 'dropdown',
        dropDownValues: witsmlCrvMap,
        useLabel: true,
      },
      {
        tag: 'hkld',
        value: findCrvMapValue(data.witsmlMapping.hkld),
        text: 'Hookload',
        inputType: 'dropdown',
        dropDownValues: witsmlCrvMap,
        useLabel: true,
      },
      {
        tag: 'pressure',
        value: findCrvMapValue(data.witsmlMapping.pressure),
        text: 'Pressure',
        inputType: 'dropdown',
        dropDownValues: witsmlCrvMap,
        useLabel: true,
      },
      {
        tag: 'diffPressure',
        value: findCrvMapValue(data.witsmlMapping.diffPressure),
        text: 'Diff Pressure',
        inputType: 'dropdown',
        dropDownValues: witsmlCrvMap,
        useLabel: true,
      },
      {
        tag: 'flow',
        value: findCrvMapValue(data.witsmlMapping.flow),
        text: 'Flow',
        inputType: 'dropdown',
        dropDownValues: witsmlCrvMap,
        useLabel: true,
      },
      {
        tag: 'blockPosition',
        value: findCrvMapValue(data.witsmlMapping.blockPosition),
        text: 'Block Position',
        inputType: 'dropdown',
        dropDownValues: witsmlCrvMap,
        useLabel: true,
      },
      {
        tag: 'rop',
        value: findCrvMapValue(data.witsmlMapping.rop),
        text: 'ROP',
        inputType: 'dropdown',
        dropDownValues: witsmlCrvMap,
        useLabel: true,
      },
      {
        tag: 'mtf',
        value: findCrvMapValue(data.witsmlMapping.mtf),
        text: 'MTF',
        inputType: 'dropdown',
        dropDownValues: witsmlCrvMap,
        useLabel: true,
      },
      {
        tag: 'gtf',
        value: findCrvMapValue(data.witsmlMapping.gtf),
        text: 'GTF',
        inputType: 'dropdown',
        dropDownValues: witsmlCrvMap,
        useLabel: true,
      },
      {
        tag: 'contInc',
        value: findCrvMapValue(data.witsmlMapping.contInc),
        text: 'Cont Inc',
        inputType: 'dropdown',
        dropDownValues: witsmlCrvMap,
        useLabel: true,
      },
      {
        tag: 'contAzi',
        value: findCrvMapValue(data.witsmlMapping.contAzi),
        text: 'Cont Azi',
        inputType: 'dropdown',
        dropDownValues: witsmlCrvMap,
        useLabel: true,
      },
      {
        tag: 'gamma',
        value: findCrvMapValue(data.witsmlMapping.gamma),
        text: 'Gamma',
        inputType: 'dropdown',
        dropDownValues: witsmlCrvMap,
        useLabel: true,
      },
      {
        tag: 'temperature',
        value: findCrvMapValue(data.witsmlMapping.temperature),
        text: 'Temperature',
        inputType: 'dropdown',
        dropDownValues: witsmlCrvMap,
        useLabel: true,
      },
    ]

    return (
      <InputModal
        open={showWmlCrvMapModal}
        onClose={cancelFunction}
        title={'Update curve mapping'}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={cancelFunction}
        validationSchema={formValidationSchema}
      />
    )
  }

  function getWitsmlServerParams(item) {
    if (!item) return null
    if (!item.hasOwnProperty('witsmlServerConfig')) return null
    let params = {
      witsmlServer: item.witsmlServerConfig.witsmlServer,
      witsmlUser: item.witsmlServerConfig.witsmlUser,
      witsmlPass: item.witsmlServerConfig.witsmlPass,
      witsmlVer: item.witsmlServerConfig.witsmlVer,
    }

    const { witsmlWell, witsmlWellBore, witsmlLogs, witsmlTrajectory } = item

    if (typeof witsmlWell.uuid === 'string' && witsmlWell.uuid !== '') {
      params.wellUuid = witsmlWell.uuid
    }

    if (typeof witsmlWellBore.uuid === 'string' && witsmlWellBore.uuid !== '') {
      params.wellBoreUuid = witsmlWellBore.uuid
    }

    if (typeof witsmlLogs.uuid === 'string' && witsmlLogs.uuid !== '') {
      params.logUuid = witsmlLogs.uuid
    }

    if (typeof witsmlTrajectory.uuid === 'string' && witsmlTrajectory.uuid !== '') {
      params.trajectoryUuid = witsmlTrajectory.uuid
    }

    return params
  }

  const updateWmlWell = async (data) => {
    if (!data) return false
    if (!Array.isArray(data)) return false
    if (data.length === 0) return false
    if (!Array.isArray(witsmlWells)) return false

    let index = -1
    for (let i = 0; i < witsmlWells.length; i++) {
      if (witsmlWells[i].id === data[0]) {
        index = i
        break
      }
    }

    if (index < 0) return false

    let payload = {
      uuid: witsmlWells[index].id,
      wellName: witsmlWells[index].desc,
      uid: currentWmlItem.current?.uid,
    }

    let saveResponse = await updateWmlWellSel(payload, updateCancelWmlWellToken())

    if (saveResponse.error) {
      setStatus({ show: true, severity: 'error', message: 'WITSML well update failed' })
      return false
    } else {
      setStatus({ show: true, severity: 'success', message: 'WITSML well updated' })
    }

    currentWmlItem.current = null
    await getDataAcq()
    return true
  }

  const updateWmlWellBore = async (data) => {
    if (!data) return false
    if (!Array.isArray(data)) return false
    if (data.length === 0) return false
    if (!Array.isArray(witsmlWellBores)) return false

    let index = -1
    for (let i = 0; i < witsmlWellBores.length; i++) {
      if (witsmlWellBores[i].id === data[0]) {
        index = i
        break
      }
    }

    if (index < 0) return false

    let payload = {
      uuid: witsmlWellBores[index].id,
      wellBoreName: witsmlWellBores[index].desc,
      uid: currentWmlItem.current?.uid,
    }

    let saveResponse = await updateWmlWellBoreSel(payload, updateCancelWmlWellBoreToken())

    if (saveResponse.error) {
      setStatus({ show: true, severity: 'error', message: 'WITSML wellbore update failed' })
      return false
    } else {
      setStatus({ show: true, severity: 'success', message: 'WITSML wellbore updated' })
    }

    currentWmlItem.current = null
    await getDataAcq()
    return true
  }

  const updateWmlLog = async (data) => {
    if (!data) return false
    if (!Array.isArray(data)) return false
    if (data.length === 0) return false
    if (!Array.isArray(witsmlLogs)) return false

    let index = -1
    for (let i = 0; i < witsmlLogs.length; i++) {
      if (witsmlLogs[i].id === data[0]) {
        index = i
        break
      }
    }

    if (index < 0) return false

    let payload = {
      uuid: witsmlLogs[index].id,
      logName: witsmlLogs[index].desc,
      uid: currentWmlItem.current?.uid,
    }

    let saveResponse = await updateWmlLogSel(payload, updateCancelWmlLogToken())

    if (saveResponse.error) {
      setStatus({ show: true, severity: 'error', message: 'WITSML log update failed' })
      return false
    } else {
      setStatus({ show: true, severity: 'success', message: 'WITSML log updated' })
    }

    currentWmlItem.current = null
    await getDataAcq()
    return true
  }

  const updateWmlTraj = async (data) => {
    if (!data) return false
    if (!Array.isArray(data)) return false
    if (data.length === 0) return false
    if (!Array.isArray(witsmlTraj)) return false

    let index = -1
    for (let i = 0; i < witsmlTraj.length; i++) {
      if (witsmlTraj[i].id === data[0]) {
        index = i
        break
      }
    }

    if (index < 0) return false

    let payload = {
      uuid: witsmlTraj[index].id,
      name: witsmlTraj[index].desc,
      uid: currentWmlItem.current?.uid,
    }

    let saveResponse = await updateWmlTrajSel(payload, updateCancelWmlTrajToken())

    if (saveResponse.error) {
      setStatus({ show: true, severity: 'error', message: 'WITSML trajectory update failed' })
      return false
    } else {
      setStatus({ show: true, severity: 'success', message: 'WITSML trajectory updated' })
    }

    currentWmlItem.current = null
    await getDataAcq()
    return true
  }

  const submitCurveMap = async (data, formikActions) => {
    setShowWmlCrvMapModal(false)
    if (!data) return false
    let payload = { ...data }
    let saveResponse = await updateCrvMap(payload, cancelUpdateCrvMapReq())

    if (saveResponse.error) {
      setStatus({ show: true, severity: 'error', message: 'Curve mapping failed to update' })
      formikActions?.setSubmitting(false)
      return false
    } else {
      setStatus({ show: true, severity: 'success', message: 'Curve mapping updated' })
    }

    await getDataAcq()
    return true
  }

  return (
    <React.Fragment>
      {showEditWellModal ? (
        <PickListVirtualDialog
          title='Select well'
          open={showEditWellModal}
          setOpen={() => setShowEditWellModal(false)}
          onApply={submitUpdateWell}
          singleItemSelect={true}
          items={Array.isArray(dropDownLists.wellNamesList) ? dropDownLists.wellNamesList : []}
          showSearch={true}
          initSelItems={[currentWmlItem.current?.actualWell]}
        />
      ) : null}
      {showWmlWellModal ? (
        <PickListVirtualDialog
          title='Select well'
          open={showWmlWellModal}
          setOpen={() => setShowWmlWellModal(false)}
          onApply={updateWmlWell}
          singleItemSelect={true}
          items={witsmlWells}
          showSearch={true}
          initSelItems={[currentWmlItem.current?.witsmlWell.uuid]}
        />
      ) : null}
      {showWmlWellBoreModal ? (
        <PickListVirtualDialog
          title='Select wellbore'
          open={showWmlWellBoreModal}
          setOpen={() => setShowWmlWellBoreModal(false)}
          onApply={updateWmlWellBore}
          singleItemSelect={true}
          items={witsmlWellBores}
          showSearch={true}
          initSelItems={[currentWmlItem.current?.witsmlWellBore.uuid]}
        />
      ) : null}
      {showWmlLogModal ? (
        <PickListVirtualDialog
          title='Select log'
          open={showWmlLogModal}
          setOpen={() => setShowWmlLogModal(false)}
          onApply={updateWmlLog}
          singleItemSelect={true}
          items={witsmlLogs}
          initSelItems={[currentWmlItem.current?.witsmlLogs.uuid]}
        />
      ) : null}
      {showWmlTrajModal ? (
        <PickListVirtualDialog
          title='Select trajectory'
          open={showWmlTrajModal}
          setOpen={() => setShowWmlTrajModal(false)}
          onApply={updateWmlTraj}
          singleItemSelect={true}
          items={witsmlTraj}
          showSearch={true}
          initSelItems={[currentWmlItem.current?.witsmlTrajectory.uuid]}
        />
      ) : null}
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => confirmDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      {showEditor ? (
        <RenderCreateUpdateModal
          data={editIndex.current >= 0 ? data[editIndex.current] : {}}
          submitFunction={submitFunction}
          cancelFunction={handleClose}
        />
      ) : null}
      {showWmlCrvMapModal ? (
        <RenderCreateUpdateModalCrvMap
          data={editIndex.current >= 0 ? data[editIndex.current] : {}}
          submitFunction={submitCurveMap}
          cancelFunction={handleCloseCrvMap}
        />
      ) : null}
      <SearchBar
        value={searchText}
        onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
        onCancelSearch={() => setSearchText('')}
        style={{
          padding: '1px',
          margin: '1px',
          backgroundColor: 'rgb(17, 17, 17)',
        }}
      />
      <div className={classes.listContainer}>
        <SimpleStyledList listItems={filteredList} renderItem={renderDataItem}></SimpleStyledList>
      </div>
      <div className={classes.menuIcon}>
        <MenuButton
          actions={[
            {
              icon: <AddIcon />,
              name: 'Add',
              onClick: () => {
                editIndex.current = -1
                handleNewItem(true)
              },
            },
            { icon: <RefreshIcon />, name: 'Refresh', onClick: getDataAcq },
          ]}
        />
      </div>
      {status?.show ? (
        <Snackbar open={status?.show} autoHideDuration={2000} onClose={handleCloseStatus}>
          <MuiAlert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </MuiAlert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default DataAcquisitionList
