import React from 'react'

import { InfoBox } from '@react-google-maps/api'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  infoBox: {
    background: `white`,
    border: `1px solid #ccc`,
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
    alignItems: ' flex-start',
  },
}))

const WellSeekerInfoBox = ({ showInfoBox, well }) => {
  const classes = useStyles()
  const position = {
    lat: well.latitude,
    lng: well.longitude,
  }

  if (!showInfoBox) {
    return null
  }

  return (
    <InfoBox
      position={position}
      options={{
        closeBoxURL: '',
        disableAutoPan: true,
      }}>
      <div className={classes.infoBox}>
        <h1>{well.companyID}</h1>

        <p>IP Address: {well.ip}</p>
        {well.wellStatus ? <p>Status: {well.wellStatus}</p> : null}
        {false ? <p>Status Date: m_mapData.rigLocations[i].statusDate</p> : null}

        <p>Company ID: {well.companyID ? well.companyID : 'None'}</p>
        <p>Computer Code: {well.computerCode ? well.computerCode : 'None'}</p>
        <p>Computer Name: {well.computerName ? well.computerName : 'None'}</p>
        <p>Email: {well.email ? well.email : 'None'}</p>
        <p>Org: {well.org ? well.org : 'None'}</p>

        <p>Latitude: {well.latitude}</p>
        <p>Longitude: {well.longitude}</p>
      </div>
    </InfoBox>
  )
}

export default React.memo(WellSeekerInfoBox)
