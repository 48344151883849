// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef } from 'react'

import { useSetRecoilState, useRecoilValue } from 'recoil'
import { allWellsAtom, wellListAtom } from 'atoms'
import useAxiosWithUnitFetch from './common/hooks/useAxiosWithUnitFetch'
import useAxiosCancelToken from './common/hooks/useAxiosCancelToken'

const RecoilData = () => {
  const _isMounted = useRef()
  const setWellList = useSetRecoilState(wellListAtom)

  const allWells = useRecoilValue(allWellsAtom)

  const { newCancelToken: wellCancelToken, cancelPreviousRequest: wellCancelRequest } = useAxiosCancelToken()
  const [{ data: wellData, error: wellError }, fetchWells] = useAxiosWithUnitFetch({
    url: '/wells',
    data: { allWells: allWells },
    cancelToken: wellCancelToken(),
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    setWellList(wellData)
  }, [wellData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getWellData()
  }, [allWells]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (wellError !== undefined && wellError !== null) {
      if (wellCancelRequest(wellError)) return
    }
  }, [wellError]) // eslint-disable-line react-hooks/exhaustive-deps

  const getWellData = async () => {
    const payload = {
      allWells: allWells,
    }
    await fetchWells(payload)
  }

  return null
}

export default RecoilData