import React, { useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import Space from 'components/common/Space'
import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import Page from 'components/common/Page'
import WellInfo from 'components/WellInfo'
import { WellsMap } from 'components/WellsMap'
import WellsActivityCharts from 'components/WellActivityCharts'

import WellList from './WellList'

import {
  wellListAtom,
  currentWellAtom,
  currentPageAtom,
  currentWellDetailsAtom,
  defaultWellDetails,
  allWellsAtom,
} from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    backgroundColor: '#2D2D2D',
  },
  cardMap: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      height: '35vh',
      marginBottom: '16px',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '80%',
      maxWidth: '80%',
      marginRight: '4px',
    },
    backgroundColor: '#2D2D2D',
  },
  topWrapper: {
    width: '100%',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  cardGraphs: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      midWidth: '270px',
    },
    [theme.breakpoints.up('md')]: {
      width: '20%',
      midWidth: '270px',
      marginLeft: '4px',
    },
  },
  cardcontent: {
    padding: '4px',
    '&:last-child': {
      paddingBottom: '4px',
    },
  },
  cardmapflex: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  cardflex: {
    display: 'flex',
    backgroundColor: '#2d2d2d',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'column',
    },
  },
}))

const WellListPage = ({ history }) => {
  const _isMounted = useRef(false)
  const classes = useStyles()
  const allWells = useRecoilValue(allWellsAtom)
  const setCurrentWell = useSetRecoilState(currentWellAtom)
  const setCurrentWellDetails = useSetRecoilState(currentWellDetailsAtom)
  const [wellList, setWellList] = useRecoilState(wellListAtom)
  const setActivePage = useSetRecoilState(currentPageAtom)
  const { newCancelToken, cancelPreviousRequest } = useAxiosCancelToken()
  const [{ data, loading, error }] = useAxios({
    url: '/wells',
    data: { allWells: allWells },
    cancelToken: newCancelToken(),
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.wellListKey)
    setCurrentWell('') // reset current well when the user returns to the wellList
    setCurrentWellDetails(defaultWellDetails)
    return () => {
      _isMounted.current = false
      cancelPreviousRequest()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && data) {
      setWellList(data)
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error !== undefined && error !== null) {
      return undefined
    }
  }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page maxWidth>
      <Space center>
        <div className={classes.topWrapper}>
          <Card className={classes.cardMap}>
            <CardContent className={`${classes.cardcontent} ${classes.cardmapflex}`}>
              <WellsMap wells={wellList} />
            </CardContent>
          </Card>
          <Card className={classes.cardGraphs}>
            <CardContent className={`${classes.cardcontent} ${classes.cardflex}`}>
              {loading ? null : <WellsActivityCharts wells={wellList} />}
            </CardContent>
          </Card>
        </div>
        <Card className={classes.card}>
          <CardContent className={classes.cardcontent}>
            <WellList
              loading={loading}
              history={history}
              data={wellList}
              detailPanel={(rowData) => {
                return <WellInfo well={rowData.actualWell}></WellInfo>
              }}
            />
          </CardContent>
        </Card>
      </Space>
    </Page>
  )
}

export default withRouter(WellListPage)
