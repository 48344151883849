import React from 'react'

import { Card } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import ErrorModelsList from './ErrorModelsList'

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: '#192734',
  },
}))

const ErrorModelsCard = ({ history }) => {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <ErrorModelsList history={history} />
    </Card>
  )
}

export default ErrorModelsCard
