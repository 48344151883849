import React from 'react'
import { Card } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Scatter } from 'react-chartjs-2'
import zoomPlugin from 'chartjs-plugin-zoom'
import annotationPlugin from 'chartjs-plugin-annotation'

import { appColors, chartSeriesColors } from 'utils'
import { createPhaseLegend } from 'components/common/depthVtimeFunctions'

ChartJS.register(LinearScale, CategoryScale, PointElement, LineElement, Tooltip, Legend, Title)
ChartJS.register(zoomPlugin, annotationPlugin)

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: appColors.itemBackColor,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden', // provided by <Card/>, but needed if container is a <div/>
  },
  chartContainer: {
    display: 'flex',
    width: '100%',
    height: 'calc(100% - 32px)',
    paddingBottom: '16px',
  },
  title: {
    color: appColors.itemTextColor,
    fontSize: '20px',
    fontWeight: 600,
  },
  legend: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '32px',
  },
}))

const GradientPlugIn = {
  id: 'GradientPlugIn',
  beforeDraw: (chart, args, options) => {
    const ctx = chart.ctx
    const canvas = chart.canvas
    const chartArea = chart.chartArea
    if (chartArea) {
      const chartWidth = chartArea.right - chartArea.left
      const chartHeight = chartArea.bottom - chartArea.top

      var gradient = canvas
        .getContext('2d')
        .createLinearGradient(chartWidth / 2, chartArea.top, chartWidth / 2, chartArea.bottom)
      gradient.addColorStop(0, '#141414')
      gradient.addColorStop(1, '#282828')

      ctx.fillStyle = gradient
      ctx.fillRect(chartArea.left, chartArea.top, chartWidth, chartHeight)
    }
  },
}

const getOrCreateLegendList = (chart, id) => {
  const legendContainer = document.getElementById(id)
  let listContainer = legendContainer.querySelector('ul')

  if (!listContainer) {
    listContainer = document.createElement('ul')
    listContainer.style.display = 'flex'
    listContainer.style.flexDirection = 'row'
    listContainer.style.margin = 0
    listContainer.style.padding = 0

    legendContainer.appendChild(listContainer)
  }

  return listContainer
}

ChartJS.register(GradientPlugIn)

export const WellDepthTimeChart = ({ title, wellData, rawData, yAxisTitle, xAxisTitle }) => {
  const classes = useStyles()
  const segColor = (ctx, defaultColor) => {
    return ctx.p0.raw.color ? ctx.p0.raw.color : defaultColor
  }

  // may rework and add back later to work with the custom legend
  // const legendHover = (event, item, legend) => {
  //   legend.chart.data.datasets.forEach((dataset, index) => {
  //     if (item.datasetIndex === index) {
  //       dataset.borderWidth = 5
  //       dataset.segment.borderWidth = 5
  //     } else {
  //       dataset.borderWidth = 1
  //       dataset.segment.borderWidth = 1
  //     }
  //   })
  //   legend.chart.update()
  // }

  // const legendLeave = (event, item, legend) => {
  //   legend.chart.data.datasets.forEach((dataset, index) => {
  //     dataset.borderWidth = 3
  //     dataset.segment.borderWidth = 3
  //   })
  //   legend.chart.update()
  // }

  const legendPlugIn = {
    id: 'htmlLegend',
    afterUpdate: (chart, args, options) => {
      const ul = getOrCreateLegendList(chart, options.containerID)

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove()
      }

      options.legendItems.forEach((item) => {
        const li = document.createElement('li')
        li.style.alignItems = 'center'
        // li.style.cursor = 'pointer'
        li.style.display = 'flex'
        li.style.flexDirection = 'row'
        li.style.marginLeft = '10px'
        li.style.justifyContent = 'center'

        // uncomment onclick handler for future use:
        // li.onclick = () => {
        //   const { type } = chart.config
        //   if (type === 'pie' || type === 'doughnut') {
        //     // Pie and doughnut charts only have a single dataset and visibility is per item
        //     chart.toggleDataVisibility(item.index)
        //   } else {
        //     chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex))
        //   }
        //   chart.update()
        // }

        // Color box
        const boxSpan = document.createElement('span')
        boxSpan.style.background = item.color
        boxSpan.style.borderColor = item.color
        boxSpan.style.borderWidth = '1px'
        boxSpan.style.display = 'inline-block'
        boxSpan.style.height = '20px'
        boxSpan.style.marginRight = '10px'
        boxSpan.style.width = '20px'

        // Text
        const textContainer = document.createElement('p')
        textContainer.style.color = appColors.headerTextColor
        textContainer.style.margin = 0
        textContainer.style.padding = 0
        textContainer.style.textDecoration = item.hidden ? 'line-through' : ''

        const text = document.createTextNode(item.label)
        textContainer.appendChild(text)

        li.appendChild(boxSpan)
        li.appendChild(textContainer)
        ul.appendChild(li)
      })
    },
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      annotation: {},
      legend: {
        position: 'bottom',
        display: false,
      },
      title: {
        display: title?.length > 0,
        text: title,
        color: appColors.itemTextColor,
        font: {
          size: 20,
        },
      },
      tooltip: {
        mode: 'nearest',
        intersect: false,
        callbacks: {
          title: function (tooltipItem) {
            return tooltipItem[0].dataset.label
          },
          label: function (context) {
            if (rawData[0].depthVDays[context.dataIndex]) {
              return [
                ` ${+context.parsed.x.toFixed(2)} ${xAxisTitle}`,
                ` ${context.parsed.y} ${yAxisTitle}`,
                ` BHA# ${
                  rawData[0].depthVDays[context.dataIndex].bha >= 0
                    ? rawData[0].depthVDays[context.dataIndex].bha
                    : 'None'
                }`,
                ` Phase: ${rawData[0].depthVDays[context.dataIndex].phase}`,
                ` Activity: ${rawData[0].depthVDays[context.dataIndex].activity}`,
              ]
            } else {
              return [` ${+context.parsed.x.toFixed(2)} ${xAxisTitle}`, ` ${context.parsed.y} ${yAxisTitle}`]
            }
          },
        },
        // filter the tooltip list to only show one point when they are very close together
        filter: function (tooltipItem, index) {
          if (index > 0) return false
          return true
        },
      },
      hover: {
        mode: 'nearest',
        intersect: false,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy',
        },
        limits: {
          x: {
            min: 'original',
            max: 'original',
          },
          y: {
            min: 'original',
            max: 'original',
          },
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
        },
      },
      htmlLegend: {
        containerID: 'chart-legend',
        legendItems: createPhaseLegend(rawData[0].depthVDays),
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: xAxisTitle,
          color: appColors.headerTextColor,
        },
        grid: {
          color: '#404040',
        },
      },
      y: {
        beginAtZero: true,
        reverse: true,
        title: {
          display: true,
          text: yAxisTitle,
          color: appColors.headerTextColor,
        },
        grid: {
          color: '#404040',
        },
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
      //mode: 'nearest',
    },
  }

  const chartJsData = {
    datasets: wellData.map((series, index) => {
      return {
        type: 'line',
        label: series.name,
        data: series.dataVals,
        borderColor: chartSeriesColors[index],
        backgroundColor: chartSeriesColors[index],
        borderWidth: 3,
        pointRadius: 0,
        pointHoverRadius: 0,
        segment: {
          borderColor: (ctx) => segColor(ctx, chartSeriesColors[index]),
          borderWidth: 3,
        },
      }
    }),
  }

  let annotations = {}
  for (let i = 0; i < rawData[0].depthVDays.length; i++) {
    if (!annotations[rawData[0].depthVDays[i].bha]) {
      annotations[rawData[0].depthVDays[i].bha] = {
        type: 'line',
        mode: 'vertical',
        xMin: rawData[0].depthVDays[i].days,
        xMax: rawData[0].depthVDays[i].days,
        borderWidth: 2,
        borderColor: appColors.chartNeonBlue,
        label: {
          content: `BHA ${rawData[0].depthVDays[i].bha}`,
          enabled: true,
          position: 'end',
          textAlign: 'end',
          color: appColors.chartNeonBlue,
          backgroundColor: 'transparent',
          xAdjust: 15,
          rotation: -90,
          font: {
            size: 14,
          },
        },
      }
    }
  }

  options.plugins.annotation.annotations = annotations

  return (
    <div className={classes.container}>
      <div className={classes.chartContainer}>
        <Scatter options={options} data={chartJsData} plugins={[GradientPlugIn, legendPlugIn]} />
      </div>
      <div id='chart-legend' className={classes.legend} />
    </div>
  )
}

const WellDepthChart = ({ wellData, units }) => {
  const classes = useStyles()
  return <Card className={classes.chartContainer}></Card>
}

export default WellDepthChart
