import React from 'react'

import { Badge } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const badgeStyles = (props) =>
  makeStyles({
    badge: {
      backgroundColor: props.badgeColor,
      color: 'black',
      fontWeight: 'bold',
      zIndex: 0,
    },
  })

const StyledBadge = (props) => {
  const { badgeColor, ...rest } = props
  const classes = badgeStyles({ badgeColor })()
  return <Badge classes={{ badge: classes.badge }} {...rest} />
}

export default StyledBadge
