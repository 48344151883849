import React, { useState } from 'react'

import SectionList from 'components/common/SectionList'
import { Avatar, Switch } from '@material-ui/core'
import StyledBadge from './StyledBadge'
import { GetActivityColor, GetActivityText, getStatusAvatar } from 'components/common/activitySelector'
import { makeStyles } from '@material-ui/styles'
import { appColors } from 'utils'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { allWellsAtom, currentWellAtom, currentWellDetailsAtom, wellListAtom } from 'atoms'
import { reduceWellData } from 'components/common/wellDataFunctions'
import SearchBar from 'material-ui-search-bar'

const useStyles = makeStyles((theme) => ({
  avatarContainer: {
    margin: 5,
    marginRight: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'lightgrey',
    borderRadius: '50%',
  },
  wellListItemContentDetails: {
    fontWeight: 'bold',
    color: 'rgb(192,192,192)',
    maxWidth: '200px',
  },
  wellListItemContent: {
    color: appColors.itemTextColorMobile,
    fontSize: '12px',
    marginLeft: '5%',
    marginTop: '4px',
    marginBottom: '4px',
  },
  itemHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: '5px',
    background:
      'rgba(19,62,108,1) linear-gradient(90deg, rgba(19,62,108,1) 0%, rgba(19,62,108,1) 50%, rgba(132,209,223,1) 100%)',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '4px 0px 4px 0px',
  },
  headerText: {
    fontSize: 14,
    fontWeight: '800',
    color: appColors.headerTextColor,
    display: 'flex',
    lineHeight: '24px',
    marginLeft: '16px',
  },
  listItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: `1px solid`,
    width: 'calc(100% - 10px)',
    borderColor: appColors.itemBackColor,
    '&:hover': {
      borderColor: appColors.accentColor,
    },
    cursor: 'pointer',
  },
  allWellsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    margin: '8px',
  },
  allWellsText: {
    color: appColors.itemTextColorMobile,
    margin: 'auto',
  },
}))

const SimpleWellList = () => {
  const classes = useStyles()
  const wellList = useRecoilValue(wellListAtom)
  const setCurrentWell = useSetRecoilState(currentWellAtom)
  const setCurrentWellDetails = useSetRecoilState(currentWellDetailsAtom)
  const [searchText, setSearchText] = useState('')
  const [allWells, setAllWells] = useRecoilState(allWellsAtom)

  const selectWell = (event, item) => {
    event.stopPropagation()
    setCurrentWell(item.actualWell)
    setCurrentWellDetails({
      actualWell: item.actualWell,
      wellStatus: item.wellStatus,
      operator: item.operator,
      rig: item.rig,
      jobNum: item.jobNum,
      lat: item.latitude,
      lng: item.longitude,
    })
  }

  const renderWellItem = (item, index) => {
    return (
      <React.Fragment>
        <div className={classes.listItemContainer} onClick={(event) => selectWell(event, item)}>
          <div className={classes.avatarContainer}>
            <StyledBadge
              badgeColor={GetActivityColor(item.wellStatus)}
              badgeContent={GetActivityText(item.wellStatus)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}>
              <Avatar alt='Well Icon' src={getStatusAvatar(item.wellStatus)}></Avatar>
            </StyledBadge>
          </div>
          <dl className={classes.wellListItemContent}>
            <dt className={classes.wellListItemContentDetails}>{`${item.actualWell}`}</dt>
            <dt>{`${item.operator}`}</dt>
            <dt>{`${item.jobNum} - ${item.state}/${item.county}`}</dt>
          </dl>
        </div>
      </React.Fragment>
    )
  }

  const renderSectionHeader = (section) => {
    return (
      <div
        className={classes.itemHeaderContainer}
        style={{
          background:
            'rgba(19,62,108,1) linear-gradient(90deg, rgba(19,62,108,1) 0%, rgba(19,62,108,1) 50%, rgba(132,209,223,1) 100%)',
        }}>
        <div className={classes.rowContainer}>
          <div className={classes.headerText}>{section.operator}</div>
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <SearchBar
        autoFocus={true}
        value={searchText}
        onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
        onCancelSearch={() => setSearchText('')}
        style={{
          padding: '1px',
          margin: '1px',
          backgroundColor: '#111',
          border: '1px solid',
        }}
      />
      <div className={classes.allWellsContainer}>
        <div className={classes.allWellsText}>All Wells</div>
        <Switch checked={allWells} onChange={() => setAllWells(!allWells)} />
      </div>
      <SectionList
        sectionList={reduceWellData(wellList, searchText)}
        renderItem={renderWellItem}
        renderSectionHeader={renderSectionHeader}
      />
    </React.Fragment>
  )
}

export default SimpleWellList
