import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import * as XLSX from 'xlsx'

import AnalyticsPage from '.'
import AnalyticsCard from './AnalyticsCard'
import BarChart from './BarChartCjs'
import { appColors, array2pipestr, createDateArray } from 'utils'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import useAxiosWithUnitFetch from 'components/common/hooks/useAxiosWithUnitFetch'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import { analyticsPhaseFilterAtom, analyticsSearchParamsAtom, analyticsSelectedWells } from 'atoms'

const CostPerDayPage = () => {
  const _isMounted = useRef(false)

  const [data, setData] = useState([])
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const phaseFilter = useRecoilValue(analyticsPhaseFilterAtom)
  const userUnits = useUnits(UNITS_FOR.Depth)
  const searchParams = useRecoilValue(analyticsSearchParamsAtom)
  const { newCancelToken: kpiCancelToken, cancelPreviousRequest: kpiCancelRequest } = useAxiosCancelToken()
  const [{ error: kpiError }, getKpiData] = useAxiosWithUnitFetch({
    url: '/kpi/getKpis',
    cancelToken: kpiCancelToken(),
    manual: true,
  })

  const chartOptions = {
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
        },
        limits: {
          x: {
            min: 'original',
            max: 'original',
          },
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        },
      },
    },
  }

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
      kpiCancelRequest()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (kpiError !== undefined && kpiError !== null) {
      if (kpiCancelRequest(kpiError)) return
    }
  }, [kpiError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      getChartData()
    }
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      getChartData()
    }
  }, [phaseFilter]) // eslint-disable-line react-hooks/exhaustive-deps

  const sortDate = (a, b) => {
    if (typeof a.date !== 'string') return -1
    if (typeof b.date !== 'string') return -1
    let d1 = new Date(Date.parse(a.date + 'T00:00:00'))
    let d2 = new Date(Date.parse(b.date + 'T00:00:00'))
    if (d1 < d2) return -1
    if (d1 > d2) return 1

    return 0
  }

  const createChartData = ({ costByDay }) => {
    let output = {
      labels: [],
      datasets: [
        {
          label: 'Day',
          backgroundColor: appColors.rotateColor,
          categoryPercentage: 0.5,
          maxBarThickness: 24,
          data: [],
        },
      ],
    }

    if (!costByDay) {
      return output
    }

    const cbd = [...costByDay]

    cbd.sort(sortDate)

    output.labels = createDateArray(cbd[0].date, cbd[cbd.length-1].date)
    output.labels.forEach(() => {
      output.datasets[0].data.push([0])
    })

    cbd.forEach((day) => {
      let dateIndex = output.labels.findIndex((element) => element === day.date)
      if (dateIndex >= 0 && typeof day.totalCost === 'number') {
        output.datasets[0].data[dateIndex] = [day.totalCost]
      }
    })

    return output
  }

  const getChartData = async () => {
    if (selectedWells.length < 1) {
      await setData([])
      return
    }

    let payload = {
      wellList: array2pipestr(selectedWells),
      costByDay: 'true',
    }
    let { dateFrom, dateTo } = searchParams
    if (dateFrom === '' || dateTo === '') {
      dateTo = new Date(Date.now()).toISOString()
      dateFrom = new Date(Date.now() - 1200 * 24 * 60 * 60 * 1000).toISOString()
    }
    payload.dateTo = dateTo
    payload.dateFrom = dateFrom

    // add phaseFilter to the payload if it's not empty or All
    if (phaseFilter !== 'All' && phaseFilter !== '' && phaseFilter !== '0') {
      payload.phase = phaseFilter
    } else {
      payload.phase = ''
    }
    const dataResponse = await getKpiData(payload)
    if (dataResponse?.data) {
      setData(dataResponse.data)
    } else {
      setData([])
    }
  }

  const onXlsxExport = () => {
    let ws = XLSX.utils.aoa_to_sheet([['Date', 'Total Cost', 'Wells']], {
      origin: 'A1',
    })

    if (data && data.costByDay) {
      const cbd = [...data.costByDay] //need to create non-stateful copy to sort
      cbd.sort(sortDate)

      let costPerDayExport = []
      cbd.forEach((day) => {
        let cpdRow = []
        cpdRow.push(day.date)
        cpdRow.push(day.totalCost)
        day.wellCosts.forEach((well) => {
          cpdRow.push(well.actualWell)
        })
        costPerDayExport.push(cpdRow)
      })

      XLSX.utils.sheet_add_aoa(ws, costPerDayExport, { origin: 'A2' })      
    }

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Detailed Well Cost-Day')
    XLSX.writeFile(wb, 'Cost by Day.xlsx')
  }

  return (
    <AnalyticsPage>
      <AnalyticsCard exportXlsxCB={onXlsxExport}>
        <BarChart
          wellData={createChartData(data)}
          units={userUnits}
          title='Cost Per Day'
          xAxisTitle='Day'
          yAxisTitle={`Cost`}
          chartOptions={chartOptions}
        />
      </AnalyticsCard>
    </AnalyticsPage>
  )
}

export default CostPerDayPage
