import React, { useEffect } from 'react'

// import Card from '@material-ui/core/Card'
// import CardContent from '@material-ui/core/CardContent'
import { Skeleton } from '@material-ui/lab'

import Page from 'components/common/Page'
// import Space from 'components/common/Space'
import WellInfo from 'components/WellInfo'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import { currentPageAtom, currentWellAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import SummaryDashboard from './components/SummaryDashboard'

const WellDetailsPage = () => {
  const currentWell = useRecoilValue(currentWellAtom)
  const setActivePage = useSetRecoilState(currentPageAtom)

  useEffect(() => {
    setActivePage(PAGE_KEYS.wellDetailsKey)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page maxWidth>
      <div style={{ display: 'flex', backgroundColor: '#0d0d0d', marginBottom: '16px',border: '1px solid #000' }}>
        {currentWell ? (
          <WellInfo well={currentWell} />
        ) : (
          <Skeleton variant='rect' width={'100%'} height={'25vh'} animation='wave' />
        )}
      </div>
      <div style={{ display: 'flex', backgroundColor: '#0d0d0d', border: '1px solid #000' }}>
        {currentWell ? (
          <SummaryDashboard />
        ) : (
          <Skeleton variant='rect' width={'100%'} height={'25vh'} animation='wave' />
        )}
      </div>
    </Page>
  )
}

export default WellDetailsPage
