import React from 'react'
import { withRouter } from 'react-router-dom'
import { useSetRecoilState, useRecoilValue } from 'recoil'

import { Marker } from '@react-google-maps/api'
import { currentWellAtom } from 'atoms'

import { wellsMapRigSelectorAtom } from './atoms/WellsMapAtoms'
import WellsMapMarkerInfoBox from './WellsMapMarkerInfoBox'
import { getMapIcon } from 'components/common/activitySelector'

const WellsMapMarker = ({ clusterer, well, history }) => {
  const checkedStatus = useRecoilValue(wellsMapRigSelectorAtom)
  const setCurrentWell = useSetRecoilState(currentWellAtom)
  const [infoBoxData, setInfoBoxData] = React.useState(null)
  const showWell = Boolean(checkedStatus[well.wellStatus])

  if (!showWell) {
    return null
  }

  const position = {
    lat: well.latitude,
    lng: well.longitude,
  }

  const markerIcon = getMapIcon(well.wellStatus)

  const handleClick = () => {
    setCurrentWell(well.actualWell)
    history.push('/well-pages/well-page')
  }

  const handleMarkerMouseOver = (location) => (event) => {
    setInfoBoxData(location)
  }

  return (
    <Marker
      onMouseOver={handleMarkerMouseOver(well)}
      onMouseOut={handleMarkerMouseOver(null)}
      position={position}
      icon={markerIcon}
      onClick={handleClick}
      clusterer={clusterer}>
      <WellsMapMarkerInfoBox well={well} showInfoBox={infoBoxData} />
    </Marker>
  )
}

export default React.memo(withRouter(WellsMapMarker))
