import React, { useEffect, useRef, useState } from 'react'

import { makeStyles } from '@material-ui/styles'
import { Icon as Iconify } from '@iconify/react'

import { appColors } from 'utils'
import { Avatar, Badge, Fab, IconButton, Tooltip, Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import AddIcon from '@material-ui/icons/Add'
import { Skeleton } from '@material-ui/lab'
import { currentWellAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import ConfirmDialog from 'components/common/ConfirmDialog'
import InputModal from 'components/common/InputModal'
import * as yup from 'yup'

import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import SimpleStyledList from 'components/common/SimpleStyledList'

const useStyles = makeStyles((theme) => ({
  listContainer: {
    display: 'flex',
    overflow: 'hidden',
    height: 'calc(100vh - 130px)',
    maxHeight: 'calc(100vh - 130px)',
    border: '1px solid #000',
    borderRadius: '4px',
  },
  headerText: {
    fontSize: 14,
    fontWeight: '500',
    color: appColors.headerTextColor,
    width: '96px',
    textAlign: 'right',
    paddingRight: '8px',
  },
  itemText: {
    fontSize: 18,
    color: appColors.itemTextColor,
    border: '',
  },
  deleteIconStyle: {
    color: '#C00000',
    fontSize: '24px',
  },
  itemMainContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '8px',
    marginBottom: '1px',
    border: `1px solid`,
    borderColor: appColors.itemBackColor,
    backgroundColor: 'inherit',
    '&:hover': {
      borderColor: appColors.accentColor,
    },
  },
  itemTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  itemTextWrapContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
  },
  itemIconContainer: {
    width: 100,
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltip: {
    backgroundColor: 'rgb(19,62,96)',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
  addIcon: {
    color: 'white',
    backgroundColor: '#192734', //appColors.accentColor,
    margin: '4px',
    padding: '12px',
    '&:hover': {
      backgroundColor: 'rgb(19, 62, 96)',
    },
    position: 'fixed', //'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  printIcon: {
    color: 'white',
    backgroundColor: '#192734', //appColors.accentColor,
    margin: '4px',
    padding: '12px',
    '&:hover': {
      backgroundColor: 'rgb(19, 62, 96)',
    },
    position: 'fixed', //'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2) + 100,
  },
  avatarContainer: {
    margin: 5,
    marginRight: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'lightgrey',
    borderRadius: '50%',
  },
  badgeStyleGreen: {
    '&::after': {
      position: 'absolute',
      backgroundColor: '#44b700',
      color: '#44b700',
      bottom: 0,
      right: 0,
      width: '25%',
      height: '25%',
      borderRadius: '50%',
      animation: '1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  badgeStyleRed: {
    '&::after': {
      position: 'absolute',
      backgroundColor: 'red',
      color: 'red',
      bottom: 0,
      right: 0,
      width: '25%',
      height: '25%',
      borderRadius: '50%',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  badgeStyleGreenRelative: {
    '&::after': {
      backgroundColor: '#44b700',
      color: '#44b700',
      width: 10,
      height: 10,
      borderRadius: 5,
      animation: '1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  badgeStyleRedRelative: {
    '&::after': {
      backgroundColor: 'red',
      color: 'red',
      width: 10,
      height: 10,
      borderRadius: 5,
      border: '1px solid currentColor',
      content: '""',
    },
  },
}))

const DrillLinkList = ({ icons }) => {
  const _isMounted = useRef(false)
  const classes = useStyles()
  const editIndex = useRef(-1)
  const currentWell = useRecoilValue(currentWellAtom)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [showEditor, setShowEditor] = useState(false)
  const [orgListDropDown, setOrgListDropDown] = useState([])

  const { newCancelToken: drillLinkCancelToken, cancelPreviousRequest: drillLinkCancelReq } = useAxiosCancelToken()
  const [{ data: drillLinkData, error: drillLinkError, loading }, getDrillLink] = useAxios({
    url: '/drilllink/getDrillLinkRigs',
    data: { wellName: currentWell },
    cancelToken: drillLinkCancelToken(),
  })

  const { newCancelToken: orgListCancelToken, cancelPreviousRequest: cancelOrgListReq } = useAxiosCancelToken()
  const [{ data: orgList, error: orgListError }, getOrgList] = useAxios({
    url: '/admin/getOrgListByUser',
    data: { wellName: currentWell },
    cancelToken: orgListCancelToken(),
  })

  const { newCancelToken: newDeleteCancelToken, cancelPreviousRequest: cancelPreviousDeleteRequest } =
    useAxiosCancelToken()
  const [{ error: deleteError }, deleteDrillLink] = useAxios({
    url: '/drilllink/deleteRig',
    manual: true,
    cancelToken: newDeleteCancelToken(),
  })

  const { newCancelToken: createCancelToken, cancelPreviousRequest: cancelCreateReq } = useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorCreate }, addSurvey] = useAxios({
    url: '/drilllink/addRig',
    manual: true,
    cancelToken: createCancelToken(),
  })

  const { newCancelToken: updateCancelToken, cancelPreviousRequest: cancelUpdateReq } = useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorUpdate }, updateSurvey] = useAxios({
    url: '/drilllink/updateRig',
    manual: true,
    cancelToken: updateCancelToken(),
  })

  useEffect(() => {
    if (_isMounted.current) {
      getDrillLink()
      getOrgList()
    }
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      orgList.forEach((org) => {
        orgListDropDown.push({ label: org, value: `${org}` })
      })

      setOrgListDropDown(orgListDropDown)
    }
  }, [orgList]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (drillLinkError !== undefined && drillLinkError !== null) {
      if (drillLinkCancelReq(drillLinkError)) return
    }
  }, [drillLinkError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (orgListError !== undefined && orgListError !== null) {
      if (cancelOrgListReq(orgListError)) return
    }
  }, [orgListError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleteError !== undefined && deleteError !== null) {
      if (cancelPreviousDeleteRequest(deleteError)) return
    }
  }, [deleteError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorUpdate !== undefined && errorUpdate !== null) {
      if (cancelUpdateReq(errorUpdate)) return
    }
  }, [errorUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorCreate !== undefined && errorCreate !== null) {
      if (cancelCreateReq(errorCreate)) return
    }
  }, [errorCreate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const getCompanyIcon = (orgName) => {
    if (icons) {
      const org = icons.find((item) => item.filename === orgName.toLowerCase())
      if (org) {
        let base64ImageString = 'data:image/*;base64,' + org.data
        return base64ImageString
      }
    } else {
      return ''
    }
  }

  const confirmDelete = async () => {
    if (confirm?.itemIndex >= 0) {
      const deleteResponse = await deleteDrillLink({
        recordId: confirm?.itemIndex,
      })

      if (!deleteResponse.error) {
        setStatus({ show: true, severity: 'success', message: 'Drill Link Rig deleted successfully' })
        await getDrillLink()
      } else {
        setStatus({ show: true, severity: 'error', message: 'Delete Drill Link failed' })
      }
    }
  }

  const onClickDelete = (item) => {
    setConfirm({
      show: true,
      title: 'Delete Drill Link Rig',
      text: `Are you sure you want to delete ${item.rigName}?`,
      itemIndex: parseInt(item?.recordId),
    })
  }

  const handleCloseStatus = (event, reason) => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const renderTextField = (text, value) => {
    return (
      <div className={classes.itemTextContainer}>
        <div className={classes.headerText}>{text} </div>
        <div className={classes.itemText}>{value}</div>
      </div>
    )
  }

  const RenderCreateUpdateModal = ({ data, submitFunction, cancelFunction }) => {
    let useInputData = true
    if (!data) useInputData = false
    if (useInputData === true) {
      if (data.hasOwnProperty('organization') === false) useInputData = false
    }

    const formValidationSchema = yup.object({
      rigGuuid: yup.string().required().min(8, 'guuid must be at least 8 characters long'),
      rigName: yup.string().required(),
      organization: yup.string(),
    })

    let formData = [
      {
        tag: 'rigName',
        value: useInputData === true ? data.rigName : '',
        text: 'Rig name',
        inputType: 'text',
      },
      {
        tag: 'rigGuuid',
        value: useInputData === true ? data.rigGuuid : '',
        text: 'GUUID',
        inputType: 'text',
      },
      {
        tag: 'organization',
        value: useInputData === true ? data.organization : '',
        text: 'Organization',
        inputType: 'dropdown',
        dropDownValues: orgListDropDown,
      },
      {
        tag: 'active',
        value: useInputData === true ? data.active : true,
        text: 'Active',
        inputType: 'boolean',
      },
      {
        tag: 'recordId',
        value: useInputData === true ? data.recordId : true,
        inputType: '-',
      },
    ]

    const title = (useInputData ? 'Edit' : 'Add') + ' Drill Link'
    return (
      <InputModal
        open={showEditor}
        onClose={cancelFunction}
        title={title}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={cancelFunction}
        validationSchema={formValidationSchema}
      />
    )
  }

  const renderBadgeField = (text, value) => {
    return (
      <div className={classes.itemTextContainer}>
        <div className={classes.headerText}>{text} </div>
        {value === false ? (
          <Badge className={classes.badgeStyleRedRelative} />
        ) : (
          <Badge className={classes.badgeStyleGreenRelative} />
        )}
      </div>
    )
  }

  const renderItem = (drillLinkRig, idx) => {
    return (
      <div key={idx} className={classes.itemMainContainer}>
        <div
          className={classes.itemTextContainer}
          onClick={() => {
            editIndex.current = idx
            setShowEditor(true)
          }}>
          <div className={classes.avatarContainer}>
            {drillLinkRig.active === false ? (
              <Badge className={classes.badgeStyleRed}>
                <Avatar alt={drillLinkRig.organization} src={getCompanyIcon(drillLinkRig.organization)}></Avatar>
              </Badge>
            ) : (
              <Badge className={classes.badgeStyleGreen}>
                <Avatar alt={drillLinkRig.organization} src={getCompanyIcon(drillLinkRig.organization)}></Avatar>
              </Badge>
            )}
          </div>
          <div className={classes.itemTextWrapContainer}>
            {renderTextField('Rig: ', drillLinkRig.rigName)}
            {renderTextField('Organization: ', drillLinkRig.organization)}
            {renderTextField('Status: ', drillLinkRig.statusMessage)}
          </div>
          <div className={classes.itemTextWrapContainer}>
            {renderBadgeField('Online: ', drillLinkRig.isOnline)}
            {renderBadgeField('Switch: ', drillLinkRig.switchStatus)}
            {renderBadgeField('Connected: ', drillLinkRig.isConnected)}
          </div>
        </div>
        <Tooltip title='Delete Drill Link rig' placement='bottom' classes={{ tooltip: classes.tooltip }}>
          <IconButton className={classes.itemIconContainer} onClick={() => onClickDelete(drillLinkRig)}>
            <Iconify icon='fa-regular:trash-alt' className={classes.deleteIconStyle} />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  const submitFunction = async (data, formikActions) => {
    setShowEditor(false)
    if (!data) return false
    let isAdd = false
    if (editIndex.current < 0) {
      isAdd = true
    }
    let payload = { ...data }

    let saveResponse
    if (isAdd) {
      saveResponse = await addSurvey(payload, createCancelToken())
    } else {
      saveResponse = await updateSurvey(payload, updateCancelToken())
    }

    if (saveResponse.error) {
      setStatus({ show: true, severity: 'error', message: isAdd ? 'Survey failed to add' : 'Survey update failed' })
      formikActions?.setSubmitting(false)
      return false
    } else {
      setStatus({ show: true, severity: 'success', message: isAdd ? 'Survey added' : 'Survey updated' })
    }

    await getDrillLink()
    return true
  }

  const handleNew = () => {
    setShowEditor(true)
  }

  const handleClose = () => {
    setShowEditor(false)
  }

  if (loading) {
    return (
      <React.Fragment>
        <div className={classes.container}>
          <Skeleton height={'80vh'} variant='rect' animation='wave' />
        </div>
      </React.Fragment>
    )
  }

  if (!loading) {
    return (
      <React.Fragment>
        {showEditor ? (
          <RenderCreateUpdateModal
            data={editIndex.current >= 0 ? drillLinkData[editIndex.current] : {}}
            submitFunction={submitFunction}
            cancelFunction={handleClose}
          />
        ) : null}
        {confirm.show ? (
          <ConfirmDialog
            title={confirm?.title}
            open={confirm?.show}
            setOpen={() => setConfirm({ show: false })}
            onConfirm={() => confirmDelete()}>
            {confirm?.text}
          </ConfirmDialog>
        ) : null}
        <div className={classes.listContainer}>
          <SimpleStyledList listItems={drillLinkData} renderItem={renderItem}></SimpleStyledList>
        </div>
        <Tooltip title='Add Drill Link rig' placement='left' classes={{ tooltip: classes.tooltip }}>
          <Fab
            aria-label='add Drill Link rig'
            className={classes.addIcon}
            onClick={() => {
              editIndex.current = -1
              handleNew(true)
            }}>
            <AddIcon />
          </Fab>
        </Tooltip>
        {status?.show ? (
          <Snackbar open={status?.show} autoHideDuration={2000} onClose={handleCloseStatus}>
            <MuiAlert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
              {status.message}
            </MuiAlert>
          </Snackbar>
        ) : null}
      </React.Fragment>
    )
  }
}

export default DrillLinkList
