import React, { useEffect, useRef, useState } from 'react'

import { List, ListItem, ListItemText, ListItemSecondaryAction, ListSubheader } from '@material-ui/core'
import { Checkbox } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import { makeStyles } from '@material-ui/styles'

import { appColors } from 'utils'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.itemBackground,
    minWidth: '50vh',
  },
  headerText: {
    fontSize: 16,
    fontWeight: '800',
    color: appColors.borderColor,
    backgroundColor: appColors.itemTextColor,
    padding: '4px 8px 4px 8px',
  },
  dialogContent: {
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#136cb9',
      outline: '1px solid #1679d0',
    },
  },
}))

const useListStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '50vh',
    backgroundColor: theme.palette.itemBackground,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
    listStyleType: 'none !important',
  },
  headerText: {
    fontSize: 16,
    fontWeight: '800',
    color: appColors.borderColor,
    backgroundColor: appColors.itemTextColor,
    padding: '0px 8px 0px 8px',
    borderBottom: '1px solid',
  },
}))

const PickListSection = ({ sectionList, selItems, setSelItems, singleItemSelect }) => {
  const _isMounted = useRef(false)
  const classes = useListStyles()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onItemSelect = (event, id) => {
    let newSelectedItems = [...selItems]

    if (getChecked(id) === false) {
      newSelectedItems.push(id)
      if (_isMounted.current === true && singleItemSelect === false) setSelItems(newSelectedItems)
      if (_isMounted.current === true && singleItemSelect === true) setSelItems([])
    } else {
      if (Array.isArray(newSelectedItems) === true) {
        const index = newSelectedItems.indexOf(id)
        if (index > -1) newSelectedItems.splice(index, 1)
      } else {
        newSelectedItems = []
      }

      if (_isMounted.current === true && !singleItemSelect === true) setSelItems(newSelectedItems)
      if (_isMounted.current === true && singleItemSelect === true) setSelItems([id])
    }
  }

  const getChecked = (id) => {
    if (!selItems) return false
    if (Array.isArray(selItems) === false) return false
    if (selItems.includes(id) === true) return true

    return false
  }

  return (
    <React.Fragment>
      <List dense className={classes.root} subheader={<li />}>
        {sectionList?.map((section, index) => {
          const labelId = `checkbox-list-secondary-label-${section.header}-${index}`
          return (
            <li key={`section-${section.header}-${index}`} className={classes.listSection}>
              <ul className={classes.ul}>
                <ListSubheader classes={{ root: classes.headerText }}>{section.header}</ListSubheader>
                {section?.data?.map((item) => (
                  <ListItem key={item.id} button>
                    <ListItemText id={labelId} primary={`${item.desc}`} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge='end'
                        onChange={(event) => onItemSelect(event, item.id)}
                        checked={getChecked(item.id)}
                        inputProps={{ 'aria-labelledby': labelId }}
                        checkedIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'lime' }} />}
                        indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                        icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </ul>
            </li>
          )
        })}
      </List>
    </React.Fragment>
  )
}

const SectionPickListDialog = (props) => {
  const { title, items, open, setOpen, onApply, initSelItems, singleItemSelect } = props
  const classes = useStyles()

  const [selectedItems, setSelectedItems] = useState(Array.isArray(initSelItems) === true ? initSelItems : [])

  let singleSelect = singleItemSelect
  if (singleItemSelect === null || singleItemSelect === undefined) singleSelect = false

  return (
    <Dialog classes={{ paper: classes.paper }} open={open} onClose={() => setOpen(false)}>
      <DialogTitle id='pick-list-dialog'>{title}</DialogTitle>
      <DialogContent style={{ paddingTop: '0px' }} className={classes.dialogContent}>
        <PickListSection
          singleItemSelect={singleSelect}
          sectionList={items}
          selItems={selectedItems}
          setSelItems={setSelectedItems}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => setOpen(false)} color='secondary'>
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            setOpen(false)
            onApply(selectedItems)
          }}
          color='primary'>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SectionPickListDialog
