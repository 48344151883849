import React, { useEffect, useRef, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import { filter as _filter } from 'lodash'
import { makeStyles } from '@material-ui/styles'
import { Icon as Iconify } from '@iconify/react'

import { appColors } from 'utils'
import { Avatar, Fab, IconButton, Snackbar, Tooltip, ListItem } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import SearchBar from 'material-ui-search-bar'

import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import MuiAlert from '@material-ui/lab/Alert'

import EditRoleDialog from './EditRoleDialog'
import ConfirmDialog from 'components/common/ConfirmDialog'
import SimplePickListDialog from './SimplePickListDialog'
import SectionPickListDialog from '../../common/SectionPickListDialog'
import { searchFunction } from '../../common/searchFunctions'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

import {
  defProductFeatures,
  productKeyMobileApp,
  productKeyThirdParty,
  productKeyWebServices,
  baseFeatureLabelArrToValueArr,
  baseFeatureValueArrToLabelArr,
  getBaseProductFeaturesList,
} from '../../userPermissions'

const useStyles = makeStyles((theme) => ({
  listContainer: {
    display: 'flex',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(calc(100vh - 100px) * 1.00)',
    maxHeight: 'calc(calc(100vh - 210px) * 1.00)',
    borderRadius: '4px',
    backgroundColor: theme.palette.itemBackground,
    width: '100%',
  },
  headerText: {
    fontSize: 14,
    fontWeight: '500',
    color: appColors.headerTextColor,
    marginLeft: 10,
  },
  itemHeaderText: {
    fontSize: 20,
    fontWeight: '600',
    color: appColors.itemTextColor,
    textAlign: 'left',
  },
  itemHeaderHelper: {
    fontSize: 14,
    fontWeight: '600',
    color: appColors.headerTextColor,
    textAlign: 'left',
  },
  itemLabelText: {
    fontSize: 14,
    fontWeight: '500',
    color: appColors.headerTextColor,
    width: '96px',
    textAlign: 'right',
    paddingRight: '8px',
  },
  itemText: {
    fontSize: 14,
    fontWeight: '400',
    color: appColors.itemTextColor,
    border: '',
  },
  avatarContainer: {
    margin: 5,
    marginRight: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'lightgrey',
    borderRadius: '50%',
  },
  listIconStyle: {
    color: '#C0C0C0',
    fontSize: '24px',
  },
  deleteIconStyle: {
    color: '#C00000',
    fontSize: '24px',
  },
  itemMainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '4px',
    borderRadius: 5,
    marginLeft: '8px',
    marginRight: '8px',
    background: 'linear-gradient(to bottom, #2d2d2d 0%, #1c1c1c 100%)',
    marginBottom: '4px',
    width: 'calc(100% - 10px)', // 8px for marginLeft, 2px for left & right border
    border: `1px solid`,
    borderColor: appColors.itemBackColor,
    backgroundColor: appColors.itemBackColor,
    '&:hover': {
      borderColor: appColors.accentColor,
    },
  },
  itemTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 5,
    cursor: 'pointer',
  },
  itemTextWrapContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    flex: 3,
  },
  itemTextPrimaryContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    flex: 2,
  },
  itemIconContainer: {
    flex: 0.3,
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  tooltip: {
    backgroundColor: 'rgb(19,62,96)',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
  addIcon: {
    color: 'white',
    backgroundColor: '#192734', //appColors.accentColor,
    margin: '4px',
    padding: '12px',
    '&:hover': {
      backgroundColor: 'rgb(19, 62, 96)',
    },
    position: 'fixed', //'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  searchContainer: {
    backgroundColor: appColors.itemBackColor,
    padding: '8px',
  },
  fixedListContainer: {
    display: 'flex',
    borderRadius: '4px',
    backgroundColor: theme.palette.itemBackground,
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#136cb9', //'darkgrey', //'rgba(0,0,0,.1)',
      outline: '1px solid #1679d0',
    },
    overflow: 'auto',
  },
}))

const RoleList = ({ orgs }) => {
  const _isMounted = useRef(false)
  const editIndex = React.useRef(-1)
  const classes = useStyles()
  const { user } = useAuth0()
  const [selectedRole, setSelectedRole] = useState({ roleId: '', counter: 0 })
  const [orgList, setOrgList] = useState([]) // eslint-disable-line no-unused-vars
  const [roleList, setRoleList] = useState([])
  const [featList, setFeatList] = useState([]) // eslint-disable-line no-unused-vars
  const [status, setStatus] = useState(false)

  const [showEditRole, setShowEditRole] = useState(false)
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [showPickBaseFeatures, setShowPickBaseFeatures] = useState(false)
  const [showPickOperators, setShowPickOperators] = useState(false)
  const [showPickFeatures, setShowPickFeatures] = useState(false)
  const [operatorList, setOperatorList] = useState([])
  const [operatorAccessList, setOperatorAccessList] = useState([])
  const [featureList, setFeatureList] = useState([])
  const [featureAccessList, setFeatureAccessList] = useState([])
  const [searchText, setSearchText] = useState('')
  const [filteredRoles, setFilteredRoles] = useState([])
  const [inverseSort] = useState(false)
  const [sortParameterPri] = useState('name')
  const [sortParameterSec] = useState('organization')

  const { newCancelToken: orgCancelToken, cancelPreviousRequest: cancelOrgReq } = useAxiosCancelToken()
  const { newCancelToken: roleCancelToken, cancelPreviousRequest: cancelRoleReq } = useAxiosCancelToken()
  const { newCancelToken: featCancelToken, cancelPreviousRequest: cancelFeatReq } = useAxiosCancelToken()

  const [{ data: orgData }] = useAxios({
    url: '/admin/getOrgListByUser',
    cancelToken: orgCancelToken(),
  })

  const [{ data: roleData }, refreshRoles] = useAxios({
    url: '/admin/user/roles',
    cancelToken: roleCancelToken(),
  })

  const [{ error: featError }, getFeatures] = useAxios({
    url: '/admin/getFeaturesByOrg',
    cancelToken: featCancelToken(),
    manual: true,
  })

  const { newCancelToken: deleteRoleCancelToken, cancelPreviousRequest: deleteRoleCancelRequest } =
    useAxiosCancelToken()
  const [{ error: deleteRoleError }, deleteRole] = useAxios({
    url: '/admin/user/role/delete',
    cancelToken: deleteRoleCancelToken(),
    manual: true,
  })

  const { newCancelToken: opAccessCancelToken, cancelPreviousRequest: opAccessCancelRequest } = useAxiosCancelToken()
  const [{ error: opAccessError }, getOperatorAccess] = useAxios({
    url: '/admin/role/getOperatorAccess',
    cancelToken: opAccessCancelToken(),
    manual: true,
  })

  const { newCancelToken: updateOpAccessCancelToken, cancelPreviousRequest: updateOpAccessCancelRequest } =
    useAxiosCancelToken()
  const [{ error: updateOpAccessError }, updateOperatorAccess] = useAxios({
    url: '/admin/role/updateOperatorAccess',
    cancelToken: updateOpAccessCancelToken(),
    manual: true,
  })

  const { newCancelToken: operatorCancelToken, cancelPreviousRequest: operatorCancelRequest } = useAxiosCancelToken()
  const [{ error: opError }, getOperators] = useAxios({
    url: '/admin/getOperatorList',
    cancelToken: operatorCancelToken(),
    manual: true,
  })

  const { newCancelToken: saveFeatCancelToken, cancelPreviousRequest: saveFeatureCancelRequest } = useAxiosCancelToken()
  const [{ error: saveFeatError }, saveFeatures] = useAxios({
    url: '/admin/user/role/saveFeatures',
    cancelToken: saveFeatCancelToken(),
    manual: true,
  })

  const { newCancelToken: updateBaseFeatureCancelToken, cancelPreviousRequest: updateBaseFeatureCancelRequest } =
    useAxiosCancelToken()
  const [{ error: updateBaseFeatureError }, updateBaseFeatures] = useAxios({
    url: '/admin/user/role/updateBaseFeaturePerms',
    cancelToken: updateBaseFeatureCancelToken(),
    manual: true,
  })

  useEffect(() => {
    if (updateBaseFeatureError !== undefined && updateBaseFeatureError !== null) {
      if (updateBaseFeatureCancelRequest(updateBaseFeatureError)) return
    }
  }, [updateBaseFeatureError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
      cancelOrgReq()
      cancelRoleReq()
      cancelFeatReq()
      opAccessCancelRequest()
      saveFeatureCancelRequest()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && orgData) {
      let newOrgData = []
      orgData.forEach((i) => {
        newOrgData.push({ label: i, value: `${i}` })
      })
      setOrgList(newOrgData)
    }
  }, [orgData])

  useEffect(() => {
    if (_isMounted.current && roleData) {
      let sortData = [...roleData]
      sortData.sort(
        (firstEl, secondEl) =>
          firstEl[sortParameterSec].localeCompare(secondEl[sortParameterSec]) * (inverseSort ? -1 : 1) ||
          firstEl[sortParameterPri].localeCompare(secondEl[sortParameterPri]) * (inverseSort ? -1 : 1),
      )
      const rolesWithKey = sortData.map((item, index) => {
        return {
          key: index.toString(),
          ...item,
        }
      })
      // console.log('roleList: ', rolesWithKey)
      setRoleList(rolesWithKey)
    }
  }, [roleData, sortParameterPri, sortParameterSec, inverseSort])

  useEffect(() => {
    if (_isMounted.current) {
      const filtered = handleSearch(searchText)
      setFilteredRoles(filtered)
    }
  }, [roleList]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      const filtered = handleSearch(searchText)
      setFilteredRoles(filtered)
    }
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleteRoleError !== undefined && deleteRoleError !== null) {
      if (deleteRoleCancelRequest(deleteRoleError)) return
    }
  }, [deleteRoleError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (opAccessError !== undefined && opAccessError !== null) {
      if (opAccessCancelRequest(opAccessError)) return
    }
  }, [opAccessError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (updateOpAccessError !== undefined && updateOpAccessError !== null) {
      if (updateOpAccessCancelRequest(updateOpAccessError)) return
    }
  }, [updateOpAccessError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (opError !== undefined && opError !== null) {
      if (operatorCancelRequest(opError)) return
    }
  }, [opError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (featError !== undefined && featError !== null) {
      if (cancelFeatReq(featError)) return
    }
  }, [featError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (saveFeatError !== undefined && saveFeatError !== null) {
      if (saveFeatureCancelRequest(saveFeatError)) return
    }
  }, [saveFeatError]) // eslint-disable-line react-hooks/exhaustive-deps

  const getCompanyIcon = (orgName) => {
    if (orgs) {
      const org = orgs.find((item) => item.filename === orgName.toLowerCase())
      if (org) {
        let base64ImageString = 'data:image/*;base64,' + org.data
        return base64ImageString
      }
    } else {
      return ''
    }
  }

  const handleSearch = (text) => {
    if (text) {
      const data = _filter(roleList, (item) => {
        return searchFunction(item, text, ['organization', 'name', 'description'])
      })
      return data
    } else {
      return roleList
    }
  }

  const refreshOperators = async (roleId) => {
    if (editIndex?.current >= 0) {
      const opResponse = await getOperators({
        accessUser: user.name,
      })
      if (opResponse?.data) {
        setOperatorList(opResponse?.data)
      }
      const getOpResponse = await getOperatorAccess({
        accessRoleId: roleId,
      })
      if (getOpResponse?.data) {
        setOperatorAccessList(getOpResponse?.data)
      }
    }
  }

  const refreshFeatures = async (roleId, org, featureId) => {
    if (editIndex?.current >= 0) {
      setFeatureAccessList(featureId)
      const featResponse = await getFeatures()
      if (featResponse?.data) {
        setFeatureList(createOrgFeatureList(org, featResponse?.data))
      }
    }
  }

  const onClickEditRole = (item) => {
    editIndex.current = parseInt(item.key)
    setShowEditRole(true)
  }

  const onClickAddRole = () => {
    editIndex.current = -1
    setShowEditRole(true)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const onClickBaseFeatures = (item) => {
    setSelectedRole({ roleId: item.id, counter: selectedRole.counter + 1 })
    editIndex.current = parseInt(item?.key)
    setShowPickBaseFeatures(true)
  }

  const onClickOperators = async (item) => {
    setSelectedRole({ roleId: item.id, counter: selectedRole.counter + 1 })
    editIndex.current = parseInt(item?.key)
    await refreshOperators(item?.id)
    setShowPickOperators(true)
  }

  const onClickFeatures = async (item) => {
    setSelectedRole({ roleId: item?.id, counter: selectedRole.counter + 1 })
    editIndex.current = parseInt(item?.key)
    await refreshFeatures(item?.id, item?.organization, item?.roleAttributes.featureId)
    setShowPickFeatures(true)
  }

  const onClickDelete = (item) => {
    editIndex.current = parseInt(item?.key)
    setConfirm({
      show: true,
      title: 'Delete Role',
      text: `Are you sure you want to delete the role ${item?.userName}?`,
      itemIndex: editIndex.current,
    })
  }

  const confirmDelete = async () => {
    if (confirm?.itemIndex > 0) {
      //Stops last role being deleted
      const deleteResponse = await deleteRole({
        id: roleList[confirm?.itemIndex].id,
      })
      if (!deleteResponse.error) {
        setStatus({ show: true, severity: 'success', message: 'Role deleted successfully' })
        await refreshRoles()
      } else {
        setStatus({ show: true, severity: 'error', message: 'Delete role failed' })
      }
    }
  }

  function createOrgFeatureList(organization, featureList) {
    if (!organization) return []
    if (Array.isArray(featureList) === false) return []

    let index = featureList.findIndex((element) => element.organization === organization)
    if (index < 0) return []

    let output = [
      {
        header: 'Website',
        productKey: productKeyWebServices,
        data: [],
      },
      { header: 'Mobile App', productKey: productKeyMobileApp, data: [] },
      {
        header: 'Client API',
        productKey: productKeyThirdParty,
        data: [],
      },
    ]

    featureList[index].features.forEach((feature) => {
      let productIndex = output.findIndex((element) => feature.includes(element.productKey))
      if (productIndex >= 0) output[productIndex].data.push({ id: feature, desc: getFeatureName(feature) })
    })

    return output
  }

  const applyPickOperator = async (selectedOperators) => {
    if (!selectedRole?.roleId) return false
    if (selectedRole?.roleId === '') return false
    if (!selectedOperators) return false
    if (Array.isArray(selectedOperators) === false) return false

    let strArray = ''
    selectedOperators.forEach((op) => {
      if (strArray !== '') strArray += '|'
      strArray += op
    })

    let updateResponse = await updateOperatorAccess({
      accessRoleId: selectedRole.roleId,
      operatorList: strArray,
    })

    if (!updateResponse.error) {
      setStatus({ show: true, severity: 'success', message: 'Operator accesss updated successfully' })
    } else {
      setStatus({ show: true, severity: 'error', message: 'Operator access update failed' })
    }

    await refreshRoles()
    setShowPickOperators(false)
  }

  const applyBaseFeatures = async (selectedFeatures) => {
    if (!selectedRole?.roleId) return false
    if (selectedRole?.roleId === '') return false
    if (!selectedFeatures) return false
    if (Array.isArray(selectedFeatures) === false) return false
    let selectedFeaturesValues = baseFeatureLabelArrToValueArr(selectedFeatures)

    let strArray = ''
    selectedFeaturesValues.forEach((feature) => {
      if (strArray !== '') strArray += '|'
      strArray += feature
    })

    console.log({
      id: selectedRole.roleId,
      baseFeaturePerm: strArray,
    })

    let updateResponse = await updateBaseFeatures({
      id: selectedRole.roleId,
      baseFeaturePerm: strArray,
    })

    if (!updateResponse.error) {
      setStatus({ show: true, severity: 'success', message: 'Base feature permissions updated successfully' })
    } else {
      setStatus({ show: true, severity: 'error', message: 'Base feature permissions update failed' })
    }

    await refreshRoles()
    setShowPickBaseFeatures(false)
  }

  const getSelFeatures = () => {
    if (!selectedRole?.roleId) return []
    if (typeof selectedRole?.roleId !== 'number') return []
    if (selectedRole.roleId < 0) return []
    if (!filteredRoles) return []
    if (!Array.isArray(filteredRoles)) return []

    let index = filteredRoles.findIndex((role) => role.id === selectedRole.roleId)
    if (index < 0) return []

    console.log(filteredRoles[index].roleAttributes.baseFeaturePerms)
    console.log(baseFeatureValueArrToLabelArr(filteredRoles[index].roleAttributes.baseFeaturePerms))

    return baseFeatureValueArrToLabelArr(filteredRoles[index].roleAttributes.baseFeaturePerms)
  }

  const applyPickFeatures = async (selectedFeatures) => {
    if (!selectedRole?.roleId) return false
    if (typeof selectedRole?.roleId !== 'number') return false
    if (selectedRole.roleId < 0) return false
    if (!selectedFeatures) return false
    if (Array.isArray(selectedFeatures) === false) return false

    let strArray = ''
    selectedFeatures.forEach((feature) => {
      if (feature === '') return
      if (strArray !== '') strArray += ','
      strArray += feature
    })

    let updateResponse = await saveFeatures({
      id: selectedRole.roleId,
      featureIds: strArray,
    })

    if (!updateResponse.error) {
      setStatus({ show: true, severity: 'success', message: 'Feature access updated successfully' })
    } else {
      setStatus({ show: true, severity: 'error', message: 'Feature access update failed' })
    }

    await refreshRoles()
    setShowPickFeatures(false)
  }

  function getFeatureName(featureId) {
    if (!Array.isArray(defProductFeatures)) return ''
    if (!featureId) return ''

    featureId = featureId.replace(`${productKeyMobileApp}-`, '')
    featureId = featureId.replace(`${productKeyWebServices}-`, '')
    featureId = featureId.replace(`${productKeyThirdParty}-`, '')

    let index = defProductFeatures.findIndex((feature) => feature.id === parseInt(featureId))
    if (index >= 0) return defProductFeatures[index].description

    return ''
  }

  const handleCloseEditRole = async (event, reason) => {
    if (reason === 'submitted') {
      refreshRoles()
    }
    setShowEditRole(false)
  }

  const renderRoleItem = (props) => {
    const { index, style } = props
    const roleItem = filteredRoles[index]

    return (
      <ListItem style={style} disableGutters={true}>
        <div key={roleItem.key} className={classes.itemMainContainer}>
          <div className={classes.itemTextContainer} onClick={() => onClickEditRole(roleItem)}>
            <div className={classes.avatarContainer}>
              <Avatar alt={roleItem.organization} src={getCompanyIcon(roleItem.organization)}></Avatar>
            </div>
            <div className={classes.itemTextPrimaryContainer}>
              <div className={classes.itemHeaderText}>{roleItem.name}</div>
              <div className={classes.itemHeaderHelper}>{roleItem.organization}</div>
            </div>
            <div className={classes.itemTextWrapContainer}>
              <div className={classes.itemTextContainer}>
                <div className={classes.itemLabelText}>Description: </div>
                <div className={classes.itemText}>{roleItem.roleAttributes.description}</div>
              </div>
            </div>
          </div>
          <Tooltip title='Base feature permissions' placement='bottom' classes={{ tooltip: classes.tooltip }}>
            <IconButton className={classes.itemIconContainer} onClick={() => onClickBaseFeatures(roleItem)}>
              <Iconify icon='ic:baseline-perm-contact-calendar' className={classes.listIconStyle} />
            </IconButton>
          </Tooltip>
          <Tooltip title='Operators' placement='bottom' classes={{ tooltip: classes.tooltip }}>
            <IconButton className={classes.itemIconContainer} onClick={() => onClickOperators(roleItem)}>
              <Iconify icon='fa-solid:user' className={classes.listIconStyle} />
            </IconButton>
          </Tooltip>
          <Tooltip title='Products & Features' placement='bottom' classes={{ tooltip: classes.tooltip }}>
            <IconButton className={classes.itemIconContainer} onClick={() => onClickFeatures(roleItem)}>
              <Iconify icon='el:list' className={classes.listIconStyle} />
            </IconButton>
          </Tooltip>
          <Tooltip title='Delete Role' placement='bottom' classes={{ tooltip: classes.tooltip }}>
            <IconButton className={classes.itemIconContainer} onClick={() => onClickDelete(roleItem)}>
              <Iconify icon='fa-regular:trash-alt' className={classes.deleteIconStyle} />
            </IconButton>
          </Tooltip>
        </div>
      </ListItem>
    )
  }

  return (
    <React.Fragment>
      <ConfirmDialog
        title={confirm?.title}
        open={confirm?.show}
        setOpen={() => setConfirm({ show: false })}
        onConfirm={() => confirmDelete()}>
        {confirm?.text}
      </ConfirmDialog>
      {showEditRole ? (
        <EditRoleDialog
          open={showEditRole}
          onClose={handleCloseEditRole}
          data={editIndex?.current > -1 ? roleList[editIndex.current] : {}}
          useInputData={editIndex?.current > -1}
          orgList={orgList}
        />
      ) : null}
      {showPickBaseFeatures ? (
        <SimplePickListDialog
          title='Base feature permissions'
          sectionHeader='Features'
          open={showPickBaseFeatures}
          setOpen={() => setShowPickBaseFeatures(false)}
          onApply={applyBaseFeatures}
          items={getBaseProductFeaturesList()}
          initSelItems={getSelFeatures()}
        />
      ) : null}
      {showPickOperators ? (
        <SimplePickListDialog
          title='Select Operators'
          sectionHeader='Operator Names'
          open={showPickOperators}
          setOpen={() => setShowPickOperators(false)}
          onApply={applyPickOperator}
          items={operatorList}
          initSelItems={operatorAccessList}
        />
      ) : null}
      {showPickFeatures ? (
        <SectionPickListDialog
          title='Select Features'
          open={showPickFeatures}
          setOpen={() => setShowPickFeatures(false)}
          onApply={applyPickFeatures}
          items={featureList}
          initSelItems={featureAccessList}
        />
      ) : null}
      <div className={classes.searchContainer}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={{
            padding: '1px',
            margin: '1px',
            backgroundColor: '#111',
          }}
        />
      </div>
      <div className={classes.listContainer}>
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              className={classes.fixedListContainer}
              height={height}
              width={width}
              itemCount={!Array.isArray(filteredRoles) ? 0 : filteredRoles.length}
              itemSize={65}>
              {renderRoleItem}
            </FixedSizeList>
          )}
        </AutoSizer>
      </div>
      <Tooltip title='add role' placement='left' classes={{ tooltip: classes.tooltip }}>
        <Fab aria-label='add role' className={classes.addIcon} onClick={onClickAddRole}>
          <AddIcon></AddIcon>
        </Fab>
      </Tooltip>
      {status?.show ? (
        <Snackbar open={status?.show} autoHideDuration={2000} onClose={handleClose}>
          <MuiAlert onClose={handleClose} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </MuiAlert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default RoleList
