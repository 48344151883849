import React, { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Container from '@material-ui/core/Container'
import Toolbar from '@material-ui/core/Toolbar'

import { useAuth0 } from '@auth0/auth0-react'
import InnovaLogo from 'images/Innova-black-white-logo.png'

import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import useUserRole from 'components/common/hooks/useUserRole'
import {
  currentPageAtom,
  currentWellAtom,
  currentWellDetailsAtom,
  userPrefsAtom,
  currentWellFieldCostAtom,
  currentErrorModelAtom,
  currentToolOrderAtom,
} from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'

import NavMenu from './NavMenu'
import NavBarUserProfile from './NavBarUserProfile'
import { appColors } from 'utils'
import WellSelector from './WellSelector'

const useStyles = makeStyles((theme) => ({
  bar: {
    zIndex: theme.zIndex.drawer + 2,
    background:
      'rgb(19,62,108) linear-gradient(90deg, rgba(19,62,108,1) 0%, rgba(132,209,223,1) 50%, rgba(19,62,108,1) 100%)',
  },
  container: {
    padding: 0,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '100%',
  },
  rowContainer: {
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '100%',
  },
  rightButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  image: {
    height: theme.spacing(6),
  },
  toolBar: {
    justifyContent: 'flex-start',
    maxWidth: '100%',
  },
  typography: {
    paddingLeft: theme.spacing(4),
  },
  spacer: {
    display: 'flex',
    flex: '1',
    maxWidth: '250px',
  },
  centerTitle: {
    display: 'flex',
    flexDirection: 'row',
  },
  singleTitle: {
    display: 'inline-block', // 'flex',
    height: '64px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  singleTitleText: {
    display: 'flex',
    color: appColors.modalBackColor,
    fontSize: 36,
    fontWeight: '700',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleText: {
    display: 'flex',
    color: appColors.modalBackColor,
    fontSize: 36,
    fontWeight: '700',
    justifyContent: 'center',
    alignSelf: 'center',
    marginLeft: '16px',
  },
  detailsTitle: {
    display: 'flex', // 'inline-block', // 'flex',
    height: '64px',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  detailsTitleText: {
    display: 'flex',
    color: appColors.modalBackColor,
    fontSize: 36,
    fontWeight: '700',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  detailsCol: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '16px',
  },
  detailsText: {
    display: 'flex',
    color: appColors.modalBackColor,
    fontSize: 14,
    fontWeight: '700',
    justifyContent: 'left',
  },
}))

const NavBar = () => {
  const classes = useStyles()
  const [userPrefs, setUserPrefs] = useRecoilState(userPrefsAtom)
  const { isAuthenticated, logout } = useAuth0()
  const { newCancelToken, isCancel } = useAxiosCancelToken()
  const [{ data, loading, error }, fetch] = useAxios({
    url: '/user/getPrefs',
    manual: true,
    cancelToken: newCancelToken(),
  })
  const currentWell = useRecoilValue(currentWellAtom)
  const currentWellDetails = useRecoilValue(currentWellDetailsAtom)
  const currentPage = useRecoilValue(currentPageAtom)
  const currentFieldCost = useRecoilValue(currentWellFieldCostAtom)
  const currentErrorModel = useRecoilValue(currentErrorModelAtom)
  const currentToolOrder = useRecoilValue(currentToolOrderAtom)

  // eslint-disable-next-line no-unused-vars
  const [userRole, loadUserRole] = useUserRole()

  useEffect(() => {
    if (!isAuthenticated) return
    if (!userRole) {
      loadUserRole()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRole, isAuthenticated])

  useEffect(() => {
    if (!loading && data) {
      setUserPrefs(data.unitPrefs)
    }
  }, [data, loading, setUserPrefs])

  useEffect(() => {
    if (error !== undefined && error !== null) {
      if (isCancel(error)) return
    }
  }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // console.log('NavBar isAuth', {isAuthenticated, userPrefs, data, loading})
    if (!isAuthenticated) return
    if (!userPrefs && !data && loading) {
      fetch()
    }
  }, [data, loading, isAuthenticated]) // eslint-disable-line react-hooks/exhaustive-deps

  const renderPageWithTitle = (title) => {
    return (
      <React.Fragment>
        <Container className={classes.rowContainer}>
          <NavMenu isAuthenticated={isAuthenticated} />
          <img className={classes.image} src={InnovaLogo} alt='Innova Logo' />
          <div className={classes.singleTitle}>
            <div className={classes.singleTitleText}>{title}</div>
          </div>
          <NavBarUserProfile isAuthenticated={isAuthenticated} logout={logout} />
        </Container>
      </React.Fragment>
    )
  }

  const renderPageWithTitleDetails = (title, well) => {
    return (
      <React.Fragment>
        <Container className={classes.rowContainer}>
          <NavMenu isAuthenticated={isAuthenticated} />
          <img className={classes.image} src={InnovaLogo} alt='Innova Logo' />
          <div className={classes.detailsCol}>
            <div className={classes.detailsText}>{currentWellDetails.actualWell}</div>
            <div className={classes.detailsText}>{currentWellDetails.operator}</div>
            <div className={classes.detailsText}>
              {currentWellDetails.rig} - {currentWellDetails.jobNum}
            </div>
          </div>
          <div className={classes.detailsTitle}>
            <div className={classes.detailsTitleText}>{title}</div>
          </div>
          <div className={classes.spacer} />
          <div className={classes.rightButtonContainer}>
            <WellSelector />
            <NavBarUserProfile isAuthenticated={isAuthenticated} logout={logout} />
          </div>
        </Container>
      </React.Fragment>
    )
  }

  const RenderNavBar = ({ currentPage }) => {
    switch (currentPage) {
      case PAGE_KEYS.witsmlServersKey:
        return renderPageWithTitle('WITSML Servers')
      case PAGE_KEYS.dataStoreKey:
        return renderPageWithTitle('Data Stores')
      case PAGE_KEYS.dataAcquisitionKey:
        return renderPageWithTitle('Data Acquisition')
      case PAGE_KEYS.wellDetailsKey:
        return renderPageWithTitleDetails('Well Details', currentWell)
      case PAGE_KEYS.dailyActivityKey:
        return renderPageWithTitleDetails('Daily Activity', currentWell)
      case PAGE_KEYS.depthTimeKey:
        return renderPageWithTitleDetails('Depth/Time Plot', currentWell)
      case PAGE_KEYS.inventoryKey:
        return renderPageWithTitleDetails('Inventory', currentWell)
      case PAGE_KEYS.surveyKey:
        return renderPageWithTitleDetails('Surveys', currentWell)
      case PAGE_KEYS.slideSheetKey:
        return renderPageWithTitleDetails('Slide Sheet', currentWell)
        case PAGE_KEYS.slideDetailsKey:
          return renderPageWithTitleDetails('Slide Details', currentWell)
      case PAGE_KEYS.costsHeadersKey:
        return renderPageWithTitleDetails('Daily Costs', currentWell)
      case PAGE_KEYS.costsKey:
        return renderPageWithTitleDetails(`Daily Costs ${currentFieldCost.date}`, currentWell)
      case PAGE_KEYS.drillStringKey:
        return renderPageWithTitleDetails('Drill String', currentWell)
      case PAGE_KEYS.bhaDetailsKey:
        return renderPageWithTitleDetails('Drill String Details')
      case PAGE_KEYS.drillingDashboardKey:
        return renderPageWithTitleDetails('Dashboard', currentWell)
      case PAGE_KEYS.threeDViewKey:
        return renderPageWithTitleDetails('3D Well View', currentWell)
      case PAGE_KEYS.logisticsKey:
        return renderPageWithTitle('Logistics')
      case PAGE_KEYS.depthVDaysKey:
      case PAGE_KEYS.slideRotateFootageKey:
      case PAGE_KEYS.slideRotateRopKey:
      case PAGE_KEYS.slideRotateHoursKey:
      case PAGE_KEYS.costPerWellKey:
      case PAGE_KEYS.costPerDayKey:
      case PAGE_KEYS.costPerOperatorKey:
      case PAGE_KEYS.rigDaysKey:
        return renderPageWithTitle('Analytics')
      case PAGE_KEYS.adminInnovaOrgsKey:
        return renderPageWithTitle('Organization Admin')
      case PAGE_KEYS.adminInnovaDatabasesKey:
        return renderPageWithTitle('Database Admin')
      case PAGE_KEYS.adminInnovaIcdsKey:
        return renderPageWithTitle('ICDS Admin')
      case PAGE_KEYS.adminInnovaDrillLinkKey:
        return renderPageWithTitle('DrillLink Admin')
      case PAGE_KEYS.adminWellSeekerKey:
        return renderPageWithTitle('WellSeeker Pro Admin')
      case PAGE_KEYS.adminUsersKey:
        return renderPageWithTitle('User Admin')
      case PAGE_KEYS.adminRolesKey:
        return renderPageWithTitle('Role Admin')
      case PAGE_KEYS.userProfileKey:
        return renderPageWithTitle('User Profile')
      case PAGE_KEYS.edrPageKey:
        return renderPageWithTitleDetails('EDR')
      case PAGE_KEYS.errorModelsKey:
        return renderPageWithTitleDetails('Error Models')
      case PAGE_KEYS.errorTermsKey:
        return renderPageWithTitleDetails(currentErrorModel.name ? currentErrorModel.name : 'Error Terms')
      case PAGE_KEYS.toolOrdersKey:
        return renderPageWithTitleDetails('Tool Orders')
      case PAGE_KEYS.toolOrderDetailsKey:
        return renderPageWithTitleDetails(
          currentToolOrder.toolOrderId
            ? `Tool Order - Job#: ${
                typeof currentToolOrder.jobNumber === 'string' ? currentToolOrder.jobNumber : ''
              }, Rig: ${currentToolOrder.rig}`
            : 'Tool Order Details',
        )
      case PAGE_KEYS.wellComponentsKey:
        return renderPageWithTitleDetails('Well Components')  
      default:
        return (
          <Container className={classes.container}>
            <NavMenu isAuthenticated={isAuthenticated} />
            <div className={classes.centerTitle}>
              <img className={classes.image} src={InnovaLogo} alt='Innova Logo' />
            </div>
            <NavBarUserProfile isAuthenticated={isAuthenticated} logout={logout} />
          </Container>
        )
    }
  }

  return (
    <React.Fragment>
      <AppBar className={classes.bar} position='fixed'>
        <Toolbar className={classes.toolBar}>
          <RenderNavBar currentPage={currentPage} />
        </Toolbar>
      </AppBar>
      <Toolbar />
    </React.Fragment>
  )
}

export default NavBar
