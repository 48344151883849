import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { ViewCache } from '@int/geotoolkit/scene/ViewCache'
import { LogData } from '@int/geotoolkit/welllog/data/LogData'
import { LogCurve } from '@int/geotoolkit/welllog/LogCurve'
import { Plot } from '@int/geotoolkit/plot/Plot'
import { WellLogWidget } from '@int/geotoolkit/welllog/widgets/WellLogWidget'
import { HeaderType } from '@int/geotoolkit/welllog/header/LogAxisVisualHeader'
import { TrackType } from '@int/geotoolkit/welllog/TrackType'
import { CssStyle } from '@int/geotoolkit/css/CssStyle'
import { ScrollToLocation } from '@int/geotoolkit/welllog/TrackContainer'
import { MathUtil } from '@int/geotoolkit/util/MathUtil'

import { dateAdd } from 'utils/dateTimeFunctions'
import { appColors } from 'utils'
import { edrDataQueryAtom } from 'atoms'

const GtkEdrChart = forwardRef(({ width, height, plotDef }, ref) => {
  const _isMounted = useRef(false)
  const canvasRef = useRef(null)
  const [timeAutoScroll, setTimeAutoScroll] = useState(true)
  const [timeData] = useState(new LogData('CALI'))
  const [widget, setWidget] = useState(null)
  const [plot, setPlot] = useState(null)
  const data = useRecoilValue(edrDataQueryAtom)
  const [curveData, setCurveData] = useState([])

  useEffect(() => {
    _isMounted.current = true
    let newWidget = createWidget()
    setWidget(newWidget)
    setTimeAutoScroll(true)

    return () => {
      _isMounted.current = false
      if (plot) {
        plot.dispose()
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && widget && canvasRef.current) {
      let newplot = new Plot({
        'canvasElement': canvasRef.current,
        'root': widget,
        'autoSize': false, // true,
      })
      setPlot(newplot)
    }
  }, [widget]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && plot) {
      // runData()
    }
  }, [plot]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && widget) {
      //  curveData objects are bound to LogCurves, so update the curveData array objects
      let startDepth = Number.MAX_VALUE
      let endDepth = Number.MIN_VALUE
      curveData.forEach((logDataObj) => {
        data.forEach((row) => {
          logDataObj.addValue(Date.parse(row['timeStamp']), row[logDataObj.getName()])
        })
        // check depth limits for each logData object
        if (logDataObj.getMinDepth() < startDepth) startDepth = logDataObj.getMinDepth()
        if (logDataObj.getMaxDepth() > endDepth) endDepth = logDataObj.getMaxDepth()
        widget.setDepthLimits(startDepth, endDepth)
      })
      // apply depth limits if they expand the range
      const limits = widget.getDepthLimits()
      if (startDepth < limits.getLow() || endDepth > limits.getHigh()) {
        widget.setDepthLimits(
          startDepth < limits.getLow() ? startDepth : limits.getLow(),
          endDepth > limits.getHigh() ? endDepth : limits.getHigh(),
        )
      }
      if (timeAutoScroll) {
        widget.scrollToIndex(endDepth, ScrollToLocation.BOTTOM, false)
      }
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  // allows parent component to set a ref that will be used to tell us
  // what the new canvas dimensions need to be after a resize.
  useImperativeHandle(ref, (width, height) => ({
    redraw(width, height) {
      redrawPlot(width, height)
    },
  }))

  const redrawPlot = (width, height) => {
    plot.setSize(width, height)
    widget.fitToWidth()
  }

  useEffect(() => {
    if (_isMounted.current && plot) {
      plot.update()
    }
  })

  const onLimitsChange = (type, src, args) => {
    if (args['new'].getHigh() < timeData.getMaxDepth()) {
      setTimeAutoScroll(false)
    } else {
      setTimeAutoScroll(true)
    }
  }

  const findData = (curveName, data) => {
    const curveData = getData(curveName, data)
    const logdata = new LogData(curveData.depths, curveData.values).setName(curveName)
    return logdata
  }

  const getData = (curveName, data) => {
    let depths = []
    let values = []
    data.forEach((row, index) => {
      depths.push(Date.parse(row['timeStamp']))
      values.push(row[curveName])
    })
    return {
      name: curveName,
      depths: depths,
      values: values,
    }
  }

  const createCurve = (dataSource, name, limits, color) => {
    const newCurve = new LogCurve(dataSource, true).setName(name).setLineStyle({
      'color': color,
      'width': 2,
    })
    //.setNormalizationLimits(limits)
    return newCurve
  }

  const createWidget = () => {
    let startDepth = Number.MAX_VALUE
    let endDepth = Number.MIN_VALUE
    if (data && data.length > 0) {
      data.forEach((row) => {
        let depth = Date.parse(row['timeStamp'])
        if (depth < startDepth) startDepth = depth
        if (depth > endDepth) endDepth = depth
      })
    } else {
      startDepth = Date.now()
      endDepth = Date.parse(dateAdd(new Date(startDepth), 'minute', 30))
    }

    const widget = new WellLogWidget({
      'horizontalscrollable': false,
      'verticalscrollable': 'floating',
      'viewcache': new ViewCache().setKeepAlive(true),
      'indexUnit': 'ms',
      'indexType': 'time',
      'trackcontainer': {
        'border': {
          'visible': true,
          'color': '#202020',
        },
      },
      'border': {
        'visible': true,
        'color': '#202020',
      },
      'header': {
        // 'visible': true,
        'height': 135,
      },
      'fillStyle': '#202020',
    })
      .setAxisHeaderType(HeaderType.Simple)
      .setDepthLimits(startDepth, endDepth) // .setDepthLimits(startDepth, startDepth)
      .scale(0.001)

    const css = [
      '* {',
      '   textstyle-font : 12px Roboto;',
      '   textstyle-color: #C0C0C0;',
      '   backgroundcolor-color: #202020;',
      '}',
      '.geotoolkit.welllog.headercontainer {',
      '   fillstyle-color: #202020;', // not quite working the way I want it, yet...
      '}',
      '.geotoolkit.axis.Axis[cssclass="indexTrack"] {',
      ' tickgenerator-labelformat-type : number;',
      ' tickgenerator-labelformat-grouplength : 0;',
      '}',
      '*[cssclass="horizontalGrid"] {',
      '   tickgenerator-major-tickstyle-color: #404040;',
      '   tickgenerator-minor-tickstyle-color: #404040;',
      '}',
      '*[cssclass="verticalGrid"] {',
      '   linestyle-color: #404040;',
      '}',
      '*[cssclass="indexTrack"] {',
      `   tickgenerator-major-labelstyle-color: ${appColors.itemTextColor};`,
      `   tickgenerator-edge-labelstyle-color: ${appColors.itemTextColor};`,
      '}',
    ].join('\n')
    widget.setCss(new CssStyle({ 'css': css }))

    widget.addTrack(TrackType.IndexTrack)

    plotDef.forEach((trackDef) => {
      const newTrack = widget.addTrack(TrackType.LinearTrack)
      trackDef.curves.forEach((curveDef) => {
        const curveLogData = findData(curveDef.curveName, data)
        const limits = MathUtil.calculateNeatLimits(
          curveLogData.getMinValue(),
          curveLogData.getMaxValue(),
          false,
          false,
        )
        newTrack.addChild([createCurve(curveLogData, curveDef.name, limits, curveDef.color)])
        setCurveData((curveData) => [...curveData, curveLogData])
      })
    })

    widget.on('visibleDepthLimitsChanged', onLimitsChange)

    return widget
  }

  // expect to enable the following 3 functions post-initial-deployment
  // const zoomIn = () => {
  //   widget.scale(defaults.zoomInScale)
  // }

  // const zoomOut = () => {
  //   widget.scale(defaults.zoomOutScale)
  // }

  // const fitToHeight = () => {
  //   widget.fitToHeight()
  // }

  return (
    <canvas
      id='canvas'
      className='time-canvas'
      ref={canvasRef}
      width={width}
      height={height}
      style={{ width: width, height: height }}></canvas>
  )
})

export default GtkEdrChart