import React, { useEffect } from 'react'

import DrillStringCard from './DrillStringCard'
import Page from '../../common/Page'
import { currentPageAtom } from '../../../atoms'
import { useSetRecoilState } from 'recoil'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'

const DrillStringPage = ({ history }) => {
  const setActivePage = useSetRecoilState(currentPageAtom)

  useEffect(() => {
    setActivePage(PAGE_KEYS.bhaDetailsKey)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page maxWidth>
      <DrillStringCard history={history} />
    </Page>
  )
}

export default DrillStringPage
