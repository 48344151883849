import React from 'react'
import { Sphere } from '@react-three/drei'

export const WellSpheres = ({ wellData, display }) => {
  if (!display) return null
  if (!wellData?.hasOwnProperty('data')) return null
  if (!Array.isArray(wellData.data)) return null

  return wellData.data.map((pt, index) =>
    index > 0 ? (
      <Sphere args={[0.7, 16, 16]} position={pt} key={`${index}${wellData.name}`}>
        <meshBasicMaterial attach='material' color={0x00ff00} transparent={true} opacity={0.75} />
      </Sphere>
    ) : null,
  )
}
