import React from 'react'
import { Vector3, CatmullRomCurve3, DoubleSide } from 'three'
import { TextLabel, labelColor } from './ThreeDeeView'

export const Casing = ({ casing, display }) => {
  if (!display) return null
  if (!Array.isArray(casing)) return null
  if (casing.length === 0) return null

  return casing.map((csg, i) => {
    let p1 = new Vector3(csg.x, csg.y, csg.z)
    let p2 = new Vector3(csg.x2, csg.y2, csg.z2)
    const path = new CatmullRomCurve3([p1, p2])

    return (
      <group key={`casing${i}`}>
        <TextLabel
          label={`${csg.name}`}
          size={1}
          color={labelColor}
          position={new Vector3(csg.x + 2, csg.y, csg.z + 2)}
        />
        <mesh visible>
          <tubeGeometry args={[path, 64, 0.75, 16, false]} />
          <meshStandardMaterial color={0x999999} side={DoubleSide} />
        </mesh>
      </group>
    )
  })
}
