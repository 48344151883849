import React, { useEffect, useRef, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

import { makeStyles } from '@material-ui/styles'
import * as yup from 'yup'
import { Field, Form, Formik } from 'formik'
import { Icon as Iconify } from '@iconify/react'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'

import { appColors } from '../../../utils'
import useAxios from '../../common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import { Select } from 'components/common/formikMUI/Select'
import { TextField } from 'components/common/formikMUI/TextField'
import { CheckboxWithLabel } from 'components/common/formikMUI/CheckboxWithLabel'
import { each as _each } from 'lodash'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '720px',
  },
  paper: {
    width: '600px',
    backgroundColor: appColors.modalBackColor,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'left',
  },
  formItem: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'left',
    width: '95%',
    paddingLeft: '8px',
    margin: '5px',
    border: '1px solid',
    borderWidth: '1px',
    overflow: 'hidden',
    borderRadius: 4,
    borderColor: appColors.borderColor,
    backgroundColor: appColors.itemBackColor,
    color: appColors.itemTextColor,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: appColors.itemBackColor,
      borderColor: appColors.accentColor,
    },
    '&$focused': {
      backgroundColor: appColors.itemBackColor,
      borderColor: appColors.accentColor,
    },
    // for select components:
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderColor: appColors.accentColor,
      borderWidth: '1px',
    },
  },
  iconStyle: {
    color: appColors.headerTextColor,
    height: '32px',
  },
  pickerSelect: {
    margin: 5,
    fontSize: 16,
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderWidth: 1,
    height: '32px',
    //    width: '80%',
    color: appColors.itemTextColor,
    borderColor: appColors.borderColor,
    backgroundColor: appColors.itemBackColor,
    borderRadius: 5,
    paddingRight: 10, // to ensure the text is never behind the icon
    '&:before': {
      backgroundColor: appColors.itemBackColor,
      borderColor: appColors.borderColor,
    },
  },
}))

const useMobileSelectStyles = makeStyles((theme) => ({
  root: {
    margin: 5,
    fontSize: 16,
    padding: '5px 10px 5px 10px',
    borderWidth: 1,
    height: '32px',
    width: '90%',
    color: appColors.itemTextColor,
    borderColor: appColors.borderColor,
    backgroundColor: appColors.itemBackColor,
    borderRadius: '5px',
    '&:before': {
      backgroundColor: appColors.itemBackColor,
      borderColor: appColors.borderColor,
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    display: 'flex',
    alignItems: 'center',
  },
  selectMenu: {
    backgroundColor: '#202020',
    '& ul': {
      backgroundColor: '#404040',
    },
    '& li': {
      fontSize: 12,
    },
  },
}))

const createOptions = (list, classes) => {
  const options = []

  _each(list, (item) => {
    options.push(
      <MenuItem value={item.value} key={item.value} className={classes}>
        {item.label}
      </MenuItem>,
    )
  })

  return options
}

const EditRoleDialog = ({ open, onClose, data, useInputData, orgList }, ...Props) => {
  const _isMounted = useRef(false)
  const classes = useStyles()
  const selectClasses = useMobileSelectStyles()
  const [alert, setAlert] = useState({ showAlert: false, alertSeverity: '', alertContent: '' })

  const { newCancelToken: saveCancelToken, cancelPreviousRequest: cancelSaveReq } = useAxiosCancelToken()
  const [{ error: errorSave }, saveRole] = useAxios({
    url: '/admin/user/role/save',
    manual: true,
    cancelToken: saveCancelToken(),
  })

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
      cancelSaveReq()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorSave !== undefined && errorSave !== null) {
      if (cancelSaveReq(errorSave)) return
    }
  }, [errorSave]) // eslint-disable-line react-hooks/exhaustive-deps

  const formValidationSchema = yup.object({
    organization: yup.string().required(),
    name: yup.string().required(),
    description: yup.string().required(),
  })

  let strArray = ''
  if (useInputData) {
    data.roleAttributes.featureId.forEach((feature) => {
      if (strArray !== '') strArray += ','
      strArray += feature
    })
  }

  let formData = [
    {
      tag: 'organization',
      value: useInputData === true ? data.organization : '',
      text: 'Organization',
      inputType: 'dropdown',
      dropDownValues: orgList,
      useLabel: true,
    },
    {
      tag: 'name',
      value: useInputData === true ? data.name : '',
      text: 'Role Name',
      inputType: 'text',
      icon: 'account',
      iconFamily: 'MaterialCommunityIcons',
    },
    {
      tag: 'description',
      value: useInputData === true ? data.roleAttributes.description : '',
      text: 'Description',
      inputType: 'text',
      icon: 'account-details',
      iconFamily: 'MaterialCommunityIcons',
    },
    {
      tag: 'admin',
      value: useInputData === true ? data.roleAttributes.permissions.admin : false,
      text: 'Is Admin',
      inputType: 'boolean',
    },
    {
      tag: 'canEdit',
      value: useInputData === true ? data.roleAttributes.permissions.canEdit : false,
      text: 'Can Edit',
      inputType: 'boolean',
    },
    {
      tag: 'canDelete',
      value: useInputData === true ? data.roleAttributes.permissions.canDelete : false,
      text: 'Delete',
      inputType: 'boolean',
    },
    {
      tag: 'canApprove',
      value: useInputData === true ? data.roleAttributes.permissions.canApprove : false,
      text: 'Approver',
      inputType: 'boolean',
    },
    {
      tag: 'canCreate',
      value: useInputData === true ? data.roleAttributes.permissions.canCreate : false,
      text: 'Create',
      inputType: 'boolean',
    },
    {
      tag: 'featureId',
      value: useInputData === true ? strArray : '',
      inputType: '-',
    },
    {
      tag: 'id',
      value: useInputData === true ? data.id : 0,
      inputType: '-',
    },
  ]

  const createInitialValues = (data) => {
    if (!data) return {}
    if (Array.isArray(data) === false) return {}
    if (data.length <= 0) return {}

    // console.log('cIV: rawInitalData ', data)
    const reducedData = data.reduce((output, item) => {
      output[item.tag] = item.value
      return output
    }, {})
    // console.log('cIV: reducedInitialData ', reducedData)
    return reducedData
  }

  const submitFunction = async (isCreate, data, formikActions, onClose) => {
    // console.log('EditRoleDlg submit function', { isCreate, data })
    if (!data) return false

    let postData = []

    postData['organization'] = data.organization
    postData['id'] = data.id
    postData['name'] = data.name
    postData['roleAttributes.description'] = data.description
    postData['roleAttributes.featureId'] = data.featureId
    postData['roleAttributes.permissions.admin'] = data.admin
    postData['roleAttributes.permissions.canEdit'] = data.canEdit
    postData['roleAttributes.permissions.canDelete'] = data.canDelete
    postData['roleAttributes.permissions.canApprove'] = data.canApprove
    postData['roleAttributes.permissions.canCreate'] = data.canCreate

    const saveResponse = await saveRole({
      ...postData,
    })

    // console.log('saveRole response: ', saveResponse)
    if (saveResponse.error) {
      setAlert({ showAlert: true, alertSeverity: 'error', alertContent: 'Create role failed' })
      formikActions?.setSubmitting(false)
      return false
    } else {
      setAlert({ showAlert: true, alertSeverity: 'success', alertContent: 'Role created' })
    }
    // console.log('role created')

    // onClose can be used to trigger parent refresh, so
    // don't signal until this function is complete
    formikActions?.setSubmitting(false)
    onClose(null, 'submitted')
    return true
  }

  const handleEnter = async () => {
    // console.log('edit role dialog enter', data?.organization, useInputData)
    if (useInputData) {
    }
  }

  const handleEntering = () => {
    // console.log('edit role dialog entering', data)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
      TransitionProps={{
        onEnter: handleEnter,
        onEntering: handleEntering,
        onEntered: () => {
          // console.log('edit dialog entered')
        },
      }}>
      <Snackbar open={alert?.showAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <MuiAlert
          variant='filled'
          severity={alert.alertSeverity}
          onClose={() => setAlert({ showAlert: false, alertSeverity: 'info', alertContent: '' })}>
          {alert.alertContent}
        </MuiAlert>
      </Snackbar>
      <DialogTitle>{useInputData ? 'Edit ' : 'Add '}Role</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={createInitialValues(formData)}
          validationSchema={formValidationSchema}
          onSubmit={(values, actions) => {
            // console.log('formik submit, values= ', values)
            formData.forEach((input) => {
              if (input.inputType === 'dropdown') {
                if (input.useLabel === true) {
                  let index = input.dropDownValues.findIndex((element) => element.value === values[input.tag])
                  if (index >= 0) values[input.tag] = input.dropDownValues[index].label
                }
              }
            })
            submitFunction(!useInputData, values, actions, onClose)
          }}>
          {({ values, submitForm, isSubmitting, touched, errors }) => (
            <Form className={classes.formContainer}>
              <InputLabel id='org-select-label-id'>Organization</InputLabel>
              <Field
                component={Select}
                name={'organization'}
                id={'organization'}
                labelId='org-select-label-id'
                classes={selectClasses}
                MenuProps={{ classes: { paper: selectClasses.selectMenu } }}
                variant='outlined'>
                {createOptions(orgList, classes.pickerSelect)}
              </Field>

              <InputLabel id='role-name-label-id'>Role Name</InputLabel>
              <Field
                component={TextField}
                name={'name'}
                placeholder={'Role Name'}
                type='text'
                InputProps={{
                  startAdornment: <Iconify icon={'bi:person-fill'} className={classes.iconStyle} />,
                }}
              />
              {errors.name && touched.name && <FormHelperText>{errors.name}</FormHelperText>}

              <InputLabel id='role-desc-label-id'>Role description</InputLabel>
              <Field
                component={TextField}
                name={'description'}
                placeholder={'Role Description'}
                type='description'
                InputProps={{
                  startAdornment: <Iconify icon={'mdi:account-details'} size={48} className={classes.iconStyle} />,
                }}
              />
              {errors.description && touched.description && <FormHelperText>{errors.description}</FormHelperText>}

              <InputLabel id='role-is-admin-label-id'>Role Attributes:</InputLabel>
              <div className={classes.formItem}>
                <Field
                  component={CheckboxWithLabel}
                  name={'admin'}
                  type='checkbox'
                  Label={{ label: 'Is Admin', labelPlacement: 'end' }}
                  checkedIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'lime' }} />}
                  indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                  icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                  style={{ color: appColors.itemTextColor }}
                />
              </div>
              <div className={classes.formItem}>
                <Field
                  component={CheckboxWithLabel}
                  name={'canEdit'}
                  type='checkbox'
                  Label={{ label: 'Can Edit', labelPlacement: 'end' }}
                  checkedIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'lime' }} />}
                  indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                  icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                />
              </div>
              <div className={classes.formItem}>
                <Field
                  component={CheckboxWithLabel}
                  name={'canDelete'}
                  type='checkbox'
                  Label={{ label: 'Can Delete', labelPlacement: 'end' }}
                  checkedIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'lime' }} />}
                  indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                  icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                />
              </div>
              <div className={classes.formItem}>
                <Field
                  component={CheckboxWithLabel}
                  name={'canApprove'}
                  type='checkbox'
                  Label={{ label: 'Can Approve', labelPlacement: 'end' }}
                  checkedIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'lime' }} />}
                  indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                  icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                />
              </div>
              <div className={classes.formItem}>
                <Field
                  component={CheckboxWithLabel}
                  name={'canCreate'}
                  type='checkbox'
                  Label={{ label: 'Can Create', labelPlacement: 'end' }}
                  checkedIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'lime' }} />}
                  indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                  icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                />
              </div>
              <DialogActions>
                <Button variant='outlined' onClick={() => onClose(null, 'cancelled')} color='secondary'>
                  Cancel
                </Button>
                <Button variant='contained' color='primary' type='submit'>
                  Submit
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default EditRoleDialog
