import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { GaugeRegistry } from '@int/geotoolkit/gauges/registry/GaugeRegistry'
import { Templates } from '@int/geotoolkit/gauges/defaults/Templates'
import { Plot } from '@int/geotoolkit/plot/Plot'
import { FillStyle } from '@int/geotoolkit/attributes/FillStyle'

import { edrDataQueryAtom } from 'atoms'
import { LineStyle } from '@int/geotoolkit/attributes/LineStyle'
import { appColors } from 'utils'
import { AlignmentStyle } from '@int/geotoolkit/attributes/TextStyle'
import { ValueDisplayStrategies } from '@int/geotoolkit/gauges/ValueDisplayStrategies'
import { Regions } from '@int/geotoolkit/gauges/layout/Regions'
import { Rect } from '@int/geotoolkit/util/Rect'
import { makeStyles } from '@material-ui/styles'
import { useResizeDetector } from 'react-resize-detector'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: '1 1 auto',
    minWidth: 0,
    width: 'calc(100% * 0.33)',
    height: '100%',
  },
}))

const GtkBandCircularGauge = ({ id, curveDef }) => {
  const classes = useStyles()
  const _isMounted = useRef(false)
  const canvasRef = useRef(null)
  const data = useRecoilValue(edrDataQueryAtom)
  const [gauge, setGauge] = useState(null)
  const [plot, setPlot] = useState(null)
  const [skipAnimation] = useState(false)
  const [size, setSize] = useState({ width: 0, height: 0 })

  const onResize = useCallback((width, height) => {
    setSize({ width: width, height: height })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const {
    ref: rszRef,
  } = useResizeDetector({ onResize: onResize, refreshMode: 'debounce', refreshRate: 500 })

  useEffect(() => {
    _isMounted.current = true
    const newGauge = createGauge(Templates.BandCircular)
    setGauge(newGauge)
    return () => {
      _isMounted.current = false
      if (plot) {
        plot.dispose()
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && gauge) {
      gauge.update()
    }
  })

  useEffect(() => {
    plot?.setSize(size.width, size.height)
    gauge?.setBounds(new Rect(0, 0, size.width, size.height))
    gauge?.update()
  }, [size]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      let newPlot = new Plot({
        'canvasElement': canvasRef.current,
        'root': gauge,
      })
      setPlot(newPlot)
    }
  }, [gauge])

  useEffect(() => {
    if (_isMounted.current && gauge) {
      if (data && data.length > 0) {
        let newValue = data[data.length - 1][curveDef.curveName]
        gauge.getAxis('mainaxis').setValue(newValue, skipAnimation)
      }
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  const createGauge = (Type) => {
    const gaugeRegistry = GaugeRegistry.getDefaultInstance()
    // Get the gauge you want from the registry. Here we are specifying bounds to apply to the gauge
    // Apply any additional parameters that you wish. Here we pass some custom bounds.
    const type = Templates.hasOwnProperty(Type) ? Templates[Type] : Templates.BandCircular
    // Get the gauge you want from the registry. Gauge will fit its parent's model limits or the plot size if
    // no bounds are specified
    const gauge = gaugeRegistry.createGauge(type)
    gauge.setOptions({
      'fillstyle': new FillStyle('#202020'),
      'border': {
        'visible': true,
      },
    })

    const ax = gauge.getAxis('mainaxis')
    ax.setOptions({
      'fillstyle': new FillStyle('#1d1d1d'),
      'linestyle': new LineStyle(appColors.itemTextColorMobile),
      'shadow': {
        'color': appColors.itemTextColorMobile,
      },
      'valuestyle': {
        'trim': false,
        'displaystrategy': ValueDisplayStrategies.FromFont,
        'position': Regions.Center,
        'textstyle': {
          'alignment': AlignmentStyle.Center,
          'font': '16px roboto',
          'color': appColors.itemTextColorMobile,
        },
        'format': '#',
      },
      'units': `<br>(${curveDef.units})<br>${curveDef.name}`,
    })
    ax.setRange({ min: curveDef.scaleMin, max: curveDef.scaleMax })
    ax.setValue(curveDef.scaleMin)

    return gauge
  }

  return (
    <div ref={rszRef} className={classes.container}>
      <canvas
        id={id}
        ref={canvasRef}
        width={size.width}
        height={size.height}
        style={{ width: size.width, height: size.height }}></canvas>
    </div>
  )
}

export default GtkBandCircularGauge
