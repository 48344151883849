import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import * as XLSX from 'xlsx'

import AnalyticsPage from '.'
import AnalyticsCard from './AnalyticsCard'
import BarChart from './BarChartCjs'
import { appColors, array2pipestr, createDateArray } from 'utils'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import useAxiosWithUnitFetch from 'components/common/hooks/useAxiosWithUnitFetch'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import { analyticsPhaseFilterAtom, analyticsSearchParamsAtom, analyticsSelectedWells } from 'atoms'

const RigDaysPage = () => {
  const _isMounted = useRef(false)

  const [data, setData] = useState([])
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const phaseFilter = useRecoilValue(analyticsPhaseFilterAtom)
  const userUnits = useUnits(UNITS_FOR.Depth)
  const searchParams = useRecoilValue(analyticsSearchParamsAtom)
  const { newCancelToken: kpiCancelToken, cancelPreviousRequest: kpiCancelRequest } = useAxiosCancelToken()
  const [{ error: kpiError }, getKpiData] = useAxiosWithUnitFetch({
    url: '/kpi/getKpis',
    cancelToken: kpiCancelToken(),
    manual: true,
  })

  const chartOptions = {
    plugins: {
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
        },
        limits: {
          x: {
            min: 'original',
            max: 'original',
          },
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        },
      },
    },
  }

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
      kpiCancelRequest()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (kpiError !== undefined && kpiError !== null) {
      if (kpiCancelRequest(kpiError)) return
    }
  }, [kpiError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      getChartData()
    }
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      getChartData()
    }
  }, [phaseFilter]) // eslint-disable-line react-hooks/exhaustive-deps

  const createChartData = ({ rigDays }) => {
    let output = {
      labels: [],
      datasets: [
        {
          label: '#Rigs',
          backgroundColor: appColors.rotateColor,
          categoryPercentage: 0.5,
          maxBarThickness: 24,
          data: [],
        },
      ],
    }

    if (!rigDays) {
      return output
    }

    output.labels = createDateArray(rigDays[0].date, rigDays[rigDays.length - 1].date)
    output.labels.forEach(() => {
      output.datasets[0].data.push([0])
    })

    rigDays.forEach((day) => {
      let dateIndex = output.labels.findIndex((element) => element === day.date)
      if (dateIndex >= 0 && typeof day.numRigs === 'number') {
        output.datasets[0].data[dateIndex] = [day.numRigs]
      }
    })

    return output
  }

  const getChartData = async () => {
    if (selectedWells.length < 1) {
      await setData([])
      return
    }

    let payload = {
      wellList: array2pipestr(selectedWells),
      rigDays: 'true',
    }
    let { dateFrom, dateTo } = searchParams
    if (dateFrom === '' || dateTo === '') {
      dateTo = new Date(Date.now()).toISOString()
      dateFrom = new Date(Date.now() - 1200 * 24 * 60 * 60 * 1000).toISOString()
    }
    payload.dateTo = dateTo
    payload.dateFrom = dateFrom

    // add phaseFilter to the payload if it's not empty or All
    if (phaseFilter !== 'All' && phaseFilter !== '' && phaseFilter !== '0') {
      payload.phase = phaseFilter
    } else {
      payload.phase = ''
    }
    const dataResponse = await getKpiData(payload)
    if (dataResponse?.data) {
      setData(dataResponse.data)
    } else {
      setData([])
    }
  }

  const onXlsxExport = () => {
    let ws = XLSX.utils.aoa_to_sheet([['Date', 'Rig Days']], {
      origin: 'A1',
    })

    if (data && data.rigDays) {
      XLSX.utils.sheet_add_aoa(
        ws, 
        data.rigDays.map((item) => [item.date, item.numRigs]), { origin: 'A2' })
    }

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Rig Days')
    XLSX.writeFile(wb, 'Rig Days.xlsx')
  }

  return (
    <AnalyticsPage>
      <AnalyticsCard exportXlsxCB={onXlsxExport}>
        <BarChart
          wellData={createChartData(data)}
          units={userUnits}
          title='Rig Days'
          xAxisTitle='Day'
          yAxisTitle={`Rig Count`}
          chartOptions={chartOptions}
        />
      </AnalyticsCard>
    </AnalyticsPage>
  )
}

export default RigDaysPage
