import React, { useEffect, useRef, useState } from 'react'

import Tooltip from '@material-ui/core/Tooltip'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import AddIcon from '@material-ui/icons/Add'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import IconButton from '@material-ui/core/IconButton'
import RefreshIcon from '@material-ui/icons/Refresh'
import { Icon as Iconify } from '@iconify/react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import * as yup from 'yup'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { checkPermission } from '../userPermissions'
import cloneDeep from 'lodash/cloneDeep'

import { currentToolOrderAtom, defaultToolOrder, userUserRoleAtom } from 'atoms'
import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import SimpleStyledList from 'components/common/SimpleStyledList'
import { appColors } from 'utils'
import InputModal from 'components/common/InputModal'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { unescapeHtml } from '../../utils/htmlSymbolHandling'
import MenuButton from 'components/common/MenuButton'

const useStyles = makeStyles((theme) => ({
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: 'calc(100vh - 130px)',
    maxHeight: 'calc(100vh - 130px)',
    border: '1px solid #000',
    borderRadius: '4px',
  },
  headerText: {
    fontSize: 14,
    fontWeight: '500',
    color: appColors.headerTextColor,
    width: '96px',
    textAlign: 'right',
    paddingRight: '8px',
  },
  itemText: {
    fontSize: 18,
    color: appColors.itemTextColor,
    border: '',
  },
  itemTextFormula: {
    fontSize: 18,
    color: appColors.itemTextColor,
    border: '',
    width: '150px',
  },
  titleCode: {
    fontSize: 18,
    fontWeight: '500',
    color: appColors.itemTextColor,
    textAlign: 'right',
  },
  titleDesc: {
    fontSize: 18,
    fontWeight: '500',
    color: appColors.headerTextColor,
    textAlign: 'left',
    paddingLeft: '8px',
  },
  deleteIconStyle: {
    color: '#C00000',
    fontSize: '24px',
  },
  itemMainContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '8px',
    marginBottom: '1px',
    border: `1px solid`,
    borderColor: appColors.itemBackColor,
    backgroundColor: 'inherit',
    '&:hover': {
      borderColor: appColors.accentColor,
    },
  },
  itemTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  itemRowContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  itemRowContainerFixed: {
    display: 'flex',
    flexDirection: 'row',
    width: '150px',
  },
  itemColContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  itemRowContainerBottom: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    marginRight: 'auto',
  },
  itemIconContainer: {
    width: 100,
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
  tooltip: {
    backgroundColor: 'rgb(19,62,96)',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
  menuIcon: {
    backgroundColor: 'transparent',
    margin: '4px',
    padding: '12px',
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2) - 20,
  },
  returnButton: {
    display: 'flex',
  },
  itemTextWrapContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
  },
  title: {
    marginVertical: 2,
    font: '20px Roboto',
    color: appColors.itemTextColorMobile,
    textAlign: 'left',
  },
}))

const ToolOrderDetailsList = ({ history }) => {
  const _isMounted = useRef(false)
  const classes = useStyles()
  const editIndex = useRef(-1)
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [showEditor, setShowEditor] = useState(false)
  const currentToolOrder = useRecoilValue(currentToolOrderAtom)
  const setCurrentToolOrder = useSetRecoilState(currentToolOrderAtom)
  const userRole = useRecoilValue(userUserRoleAtom)
  const [dropDownLists, setDropDownLists] = React.useState({
    connectionType: { tag: 'CONNECTION_TYPE', list: [] },
    lobes: { tag: 'LOBES', list: [] },
    stages: { tag: 'STAGES', list: [] },
    housingType: { tag: 'HOUSING_TYPE', list: [] },
    rubberType: { tag: 'RUBBER_TYPE', list: [] },
    rotorType: { tag: 'ROTOR_TYPE', list: [] },
    bearingType: { tag: 'BEARING_TYPE', list: [] },
    bendType: { tag: 'BEND_TYPE', list: [] },
    bendAngle: { tag: 'BEND_ANGLE', list: [] },
    bitToBend: { tag: 'BIT_TO_BEND', list: [] },
    thHillRequired: { tag: 'TH_HILL_REQUIRED', list: [] },
    dynoRequired: { tag: 'DYNO_REQUIRED', list: [] },
    vendors: { tag: 'VENDORS', list: [] },
    toolType: { tag: 'TOOL_TYPE', list: [] },
    boredForFloat: { tag: 'BORED_FOR_FLOAT', list: [] },
  })

  const { newCancelToken: newDropDownListsRawToken, cancelPreviousRequest: cancelPreviousDropDownListsRawRequest } =
    useAxiosCancelToken()
  const [{ data: dropDownListsRaw, error: dropDownListsRawError }] = useAxios({
    url: '/toolOrders/getDropDownLists',
    cancelToken: newDropDownListsRawToken(),
  })

  const { newCancelToken: newToolsCancelToken, cancelPreviousRequest: cancelPreviousToolsRequest } =
    useAxiosCancelToken()
  const [{ data: tools, error: toolsError }, getTools] = useAxios({
    url: '/toolOrders/getTools',
    data: {
      toolOrderId: currentToolOrder.toolOrderId,
    },
    cancelToken: newToolsCancelToken(),
  })

  const { newCancelToken: createCancelToken, cancelPreviousRequest: cancelCreateReq } = useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorCreate }, addTool] = useAxios({
    url: '/toolOrders/addTool',
    manual: true,
    cancelToken: createCancelToken(),
  })

  const { newCancelToken: updateCancelToken, cancelPreviousRequest: cancelUpdateReq } = useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorUpdate }, updateTool] = useAxios({
    url: '/toolOrders/updateTool',
    manual: true,
    cancelToken: updateCancelToken(),
  })

  const { newCancelToken: newDeleteCancelToken, cancelPreviousRequest: cancelDeleteReq } = useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorDelete }, deleteTool] = useAxios({
    url: '/toolOrders/deleteTool',
    manual: true,
    cancelToken: newDeleteCancelToken(),
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
      cancelPreviousDropDownListsRawRequest()
      cancelPreviousToolsRequest()
      cancelCreateReq()
      cancelUpdateReq()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (dropDownListsRawError !== undefined && dropDownListsRawError !== null) {
      if (cancelPreviousDropDownListsRawRequest(dropDownListsRawError)) return
    }
  }, [dropDownListsRawError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorCreate !== undefined && errorCreate !== null) {
      if (cancelCreateReq(errorCreate)) return
    }
  }, [errorCreate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorUpdate !== undefined && errorUpdate !== null) {
      if (cancelUpdateReq(errorUpdate)) return
    }
  }, [errorUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorDelete !== undefined && errorDelete !== null) {
      if (cancelDeleteReq(errorDelete)) return
    }
  }, [errorDelete]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (toolsError !== undefined && toolsError !== null) {
      if (cancelPreviousToolsRequest(toolsError)) return
    }
  }, [toolsError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      getDropDownLists()
    }
  }, [dropDownListsRaw]) // eslint-disable-line react-hooks/exhaustive-deps

  async function getDropDownLists() {
    if (!Array.isArray(dropDownListsRaw)) return
    let prevDropDownLists = cloneDeep(dropDownLists)

    dropDownListsRaw.forEach((list, _) => {
      for (const property in prevDropDownLists) {
        if (prevDropDownLists[property].tag === list.tagName && Array.isArray(list.menuItems)) {
          prevDropDownLists[property].list = []

          list.menuItems.forEach((listItem) => {
            prevDropDownLists[property].list.push({ label: unescapeHtml(listItem), value: `${unescapeHtml(listItem)}` })
          })
        }
      }
    })

    if (_isMounted.current === true) setDropDownLists(prevDropDownLists)
  }

  const onClickDelete = (item, index) => {
    editIndex.current = parseInt(index)
    setConfirm({
      show: true,
      title: 'Delete tool',
      text: `Are you sure you want to delete ${item?.description}?`,
      itemIndex: editIndex.current,
    })
  }

  const renderTextField = (text, value) => {
    return (
      <div className={classes.itemTextContainer}>
        <div className={classes.headerText}>{text} </div>
        <div className={classes.itemText}>{value}</div>
      </div>
    )
  }

  const renderItem = (item, index) => {
    return (
      <div className={classes.itemMainContainer}>
        <div className={classes.title}>
          <span style={{ marginLeft: 5, marginTop: 8 }}>{unescapeHtml(item.description)} - </span>
          <span> {item.quantity}</span>
        </div>
        <div
          className={classes.itemTextContainer}
          onClick={() => {
            editIndex.current = index
            setShowEditor(true)
          }}>
          <div className={classes.itemTextWrapContainer}>
            {renderTextField('Type:', item.toolType)}
            {renderTextField('OD:', item.od)}
            {renderTextField('ID:', item.id)}
            {renderTextField('CNX Top:', unescapeHtml(item.topConnection))}
            {renderTextField('CNX Bottom:', unescapeHtml(item.bottomConnection))}
          </div>
          <div className={classes.itemTextWrapContainer}>
            {renderTextField('Lobe:', item.lobe)}
            {renderTextField('Stage:', item.stage)}
            {renderTextField('Housing:', item.housingType)}
            {renderTextField('Rubber:', item.rubberType)}
            {renderTextField('Rotor:', item.rotorType)}
          </div>
          <div className={classes.itemTextWrapContainer}>
            {renderTextField('Bend:', item.bendAngle)}
            {renderTextField('Stab:', item.stabOd)}
            {renderTextField('BFF:', item.boredForFloat)}
            {renderTextField('Fit:', item.fit)}
            {renderTextField('SN:', item.serialNum)}
          </div>
          <div className={classes.itemTextWrapContainer}>
            {renderTextField('Length:', item.length)}
            {renderTextField('TH Hill:', item.thHillRequired)}
            {renderTextField('Dyno Req:', item.dynoRequired)}
            {renderTextField('Temp Range:', item.tempRange)}
            {renderTextField('Vendor:', item.primaryVendor)}
          </div>
          {checkPermission('canDelete', userRole.roleAttributes?.permissions) ? (
            <Tooltip title='Delete tool' placement='bottom' classes={{ tooltip: classes.tooltip }}>
              <IconButton
                className={classes.itemIconContainer}
                onClick={(event) => {
                  event.stopPropagation()
                  onClickDelete(item, index)
                }}>
                <Iconify icon='fa-regular:trash-alt' className={classes.deleteIconStyle} />
              </IconButton>
            </Tooltip>
          ) : null}
        </div>
      </div>
    )
  }

  const RenderCreateUpdateModal = ({ data, submitFunction, cancelFunction }) => {
    let useInputData = true
    if (!data) useInputData = false
    if (useInputData === true) {
      if (data.hasOwnProperty('toolOrderId') === false) useInputData = false
    }

    const formValidationSchema = yup.object({
      toolOrderId: yup.string().matches(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed'),
      toolId: yup.string().nullable(),
      description: yup.string().nullable(),
      toolType: yup.string().nullable(),
      primaryVendor: yup.string().nullable(),
      secondaryVendor: yup.string().nullable(),
      topConnection: yup.string().nullable(),
      bottomConnection: yup.string().nullable(),
      quantity: yup.string().matches(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed'),
      od: yup.string().matches(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed'),
      id: yup.string().matches(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed'),
      length: yup.string().matches(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed'),
      stabOd: yup.string().matches(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, 'Only numeric values allowed'),
      lobe: yup.string().nullable(),
      stage: yup.string().nullable(),
      housingType: yup.string().nullable(),
      rubberType: yup.string().nullable(),
      rotorType: yup.string().nullable(),
      bendType: yup.string().nullable(),
      bearingType: yup.string().nullable(),
      bendAngle: yup.string().nullable(),
      bitToBend: yup.string().nullable(),
      thHillRequired: yup.string().nullable(),
      dynoRequired: yup.string().nullable(),
      boredForFloat: yup.string().nullable(),
      fit: yup.string().nullable(),
      serialNum: yup.string().nullable(),
      tempRange: yup.string().nullable(),
    })

    let formData = [
      {
        tag: 'toolOrderId',
        value: typeof currentToolOrder.toolOrderId === 'number' && currentToolOrder.toolOrderId.toString(),
        inputType: '-',
      },
      {
        tag: 'toolId',
        value: useInputData === true ? (typeof data.toolId === 'number' ? data.toolId.toString() : '') : '',
        inputType: '-',
      },
      {
        tag: 'description',
        value: useInputData === true ? data.description : '',
        text: 'Description',
        inputType: 'text',
      },
      {
        tag: 'toolType',
        value: useInputData === true ? data.toolType : '',
        text: 'Tool Type',
        inputType: dropDownLists.toolType.list.length > 0 ? 'dropdown' : 'text',
        dropDownValues: dropDownLists.toolType.list,
      },
      {
        tag: 'quantity',
        value: useInputData === true ? (typeof data.quantity === 'number' ? data.quantity.toString() : '') : '',
        text: 'Quantity',
        inputType: 'text',
      },
      {
        tag: 'od',
        value: useInputData === true ? (typeof data.od === 'number' ? data.od.toString() : '') : '',
        text: 'OD',
        inputType: 'text',
      },
      {
        tag: 'id',
        value: useInputData === true ? (typeof data.id === 'number' ? data.id.toString() : '') : '',
        text: 'ID',
        inputType: 'text',
      },
      {
        tag: 'length',
        value: useInputData === true ? (typeof data.length === 'number' ? data.length.toString() : '') : '',
        text: 'Length',
        inputType: 'text',
      },
      {
        tag: 'primaryVendor',
        value: useInputData === true ? data.primaryVendor : '',
        text: 'Vendor',
        inputType: dropDownLists.vendors.list.length > 0 ? 'dropdown' : 'text',
        dropDownValues: dropDownLists.vendors.list,
      },
      {
        tag: 'secondaryVendor',
        value: useInputData === true ? data.secondaryVendor : '',
        text: 'Alt Vendor',
        inputType: dropDownLists.vendors.list.length > 0 ? 'dropdown' : 'text',
        dropDownValues: dropDownLists.vendors.list,
      },
      {
        tag: 'topConnection',
        value: useInputData === true ? data.topConnection : '',
        text: 'Top Connection',
        inputType: dropDownLists.connectionType.list.length > 0 ? 'dropdown' : 'text',
        dropDownValues: dropDownLists.connectionType.list,
      },
      {
        tag: 'bottomConnection',
        value: useInputData === true ? data.bottomConnection : '',
        text: 'Bottom Connection',
        inputType: dropDownLists.connectionType.list.length > 0 ? 'dropdown' : 'text',
        dropDownValues: dropDownLists.connectionType.list,
      },
      {
        tag: 'lobe',
        value: useInputData === true ? data.lobe : '',
        text: 'Lobes',
        inputType: dropDownLists.lobes.list.length > 0 ? 'dropdown' : 'text',
        dropDownValues: dropDownLists.lobes.list,
        filterProperty: 'toolType',
        filterValue: 'motor',
      },
      {
        tag: 'stage',
        value: useInputData === true ? data.stage : '',
        text: 'Stages',
        inputType: dropDownLists.stages.list.length > 0 ? 'dropdown' : 'text',
        dropDownValues: dropDownLists.stages.list,
        filterProperty: 'toolType',
        filterValue: 'motor',
      },
      {
        tag: 'housingType',
        value: useInputData === true ? data.housingType : '',
        text: 'Housing Type',
        inputType: dropDownLists.housingType.list.length > 0 ? 'dropdown' : 'text',
        dropDownValues: dropDownLists.housingType.list,
        filterProperty: 'toolType',
        filterValue: 'motor',
      },
      {
        tag: 'rubberType',
        value: useInputData === true ? data.rubberType : '',
        text: 'Rubber Type',
        inputType: dropDownLists.rubberType.list.length > 0 ? 'dropdown' : 'text',
        dropDownValues: dropDownLists.rubberType.list,
        filterProperty: 'toolType',
        filterValue: 'motor',
      },
      {
        tag: 'rotorType',
        value: useInputData === true ? data.rotorType : '',
        text: 'Rotor Type',
        inputType: dropDownLists.rotorType.list.length > 0 ? 'dropdown' : 'text',
        dropDownValues: dropDownLists.rotorType.list,
        filterProperty: 'toolType',
        filterValue: 'motor',
      },
      {
        tag: 'bearingType',
        value: useInputData === true ? data.bearingType : '',
        text: 'Bearing Type',
        inputType: dropDownLists.bearingType.list.length > 0 ? 'dropdown' : 'text',
        dropDownValues: dropDownLists.bearingType.list,
        filterProperty: 'toolType',
        filterValue: 'motor',
      },
      {
        tag: 'bendType',
        value: useInputData === true ? data.bendType : '',
        text: 'Bend Type',
        inputType: dropDownLists.bendType.list.length > 0 ? 'dropdown' : 'text',
        dropDownValues: dropDownLists.bendType.list,
        filterProperty: 'toolType',
        filterValue: 'motor',
      },
      {
        tag: 'bendAngle',
        value: useInputData === true ? data.bendAngle : '',
        text: 'Bend Angle',
        inputType: dropDownLists.bendAngle.list.length > 0 ? 'dropdown' : 'text',
        dropDownValues: dropDownLists.bendAngle.list,
        filterProperty: 'toolType',
        filterValue: 'motor',
      },
      {
        tag: 'bitToBend',
        value: useInputData === true ? data.bitToBend : '',
        text: 'Bit To Bend',
        inputType: dropDownLists.bitToBend.list.length > 0 ? 'dropdown' : 'text',
        dropDownValues: dropDownLists.bitToBend.list,
        filterProperty: 'toolType',
        filterValue: 'motor',
      },
      {
        tag: 'stabOd',
        value: useInputData === true ? (typeof data.stabOd === 'number' ? data.stabOd.toString() : '') : '',
        text: 'Stab OD',
        inputType: 'text',
      },
      {
        tag: 'thHillRequired',
        value: useInputData === true ? data.thHillRequired : '',
        text: 'TH Hill Required',
        inputType: dropDownLists.thHillRequired.list.length > 0 ? 'dropdown' : 'text',
        dropDownValues: dropDownLists.thHillRequired.list,
        filterProperty: 'toolType',
        filterValue: 'motor',
      },
      {
        tag: 'dynoRequired',
        value: useInputData === true ? data.dynoRequired : '',
        text: 'Dyno Required',
        inputType: dropDownLists.dynoRequired.list.length > 0 ? 'dropdown' : 'text',
        dropDownValues: dropDownLists.dynoRequired.list,
        filterProperty: 'toolType',
        filterValue: 'motor',
      },
      {
        tag: 'boredForFloat',
        value: useInputData === true ? data.boredForFloat : '',
        text: 'Bored For Float',
        inputType: dropDownLists.boredForFloat.list.length > 0 ? 'dropdown' : 'text',
        dropDownValues: dropDownLists.boredForFloat.list,
        filterProperty: 'toolType',
        filterValue: 'motor',
      },
      {
        tag: 'tempRange',
        value: useInputData === true ? data.tempRange : '',
        text: 'Temp Range',
        inputType: 'text',
      },
      {
        tag: 'serialNum',
        value: useInputData === true ? data.serialNum : '',
        text: 'Serial #',
        inputType: 'text',
        icon: 'numeric',
        iconFamily: 'MaterialCommunityIcons',
      },
      {
        tag: 'fit',
        value: useInputData === true ? data.fit : '',
        text: 'Fit',
        inputType: 'text',
        filterProperty: 'toolType',
        filterValue: 'motor',
      },
    ]

    const title = (useInputData ? 'Edit' : 'Add') + ' tool'

    return (
      <InputModal
        open={showEditor}
        onClose={handleCloseEdit}
        title={title}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={cancelFunction}
        validationSchema={formValidationSchema}
      />
    )
  }

  const handleNew = () => {
    editIndex.current = -1
    setShowEditor(true)
  }

  const handleClose = () => {
    setShowEditor(false)
  }

  const handleCloseStatus = (event, reason) => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const handleCloseEdit = async (event, reason) => {
    setShowEditor(false)
  }

  const submitFunction = async (data, formikActions) => {
    setShowEditor(false)

    if (!data) return false

    let isAdd = false
    if (editIndex.current < 0) {
      isAdd = true
    }
    let payload = { ...data }

    let saveResponse
    if (isAdd) {
      saveResponse = await addTool(payload, createCancelToken())
    } else {
      saveResponse = await updateTool(payload, updateCancelToken())
    }

    if (saveResponse.error) {
      setStatus({ show: true, severity: 'error', message: 'Add tool failed' })
      formikActions?.setSubmitting(false)
      return false
    } else {
      setStatus({ show: true, severity: 'success', message: isAdd ? 'Tool added' : 'Tool updated' })
    }

    await getTools()

    return true
  }

  const confirmDelete = async () => {
    if (confirm?.itemIndex >= 0) {
      const deleteResponse = await deleteTool({
        toolOrderId: currentToolOrder.toolOrderId,
        toolId: tools[confirm?.itemIndex].toolId,
      })

      if (!deleteResponse.error) {
        setStatus({ show: true, severity: 'success', message: 'Tool deleted successfully' })
        await getTools()
      } else {
        setStatus({ show: true, severity: 'error', message: 'Delete tool failed' })
      }
    }
  }

  return (
    <React.Fragment>
      {showEditor ? (
        <RenderCreateUpdateModal
          data={editIndex.current >= 0 ? tools[editIndex.current] : {}}
          submitFunction={submitFunction}
          cancelFunction={handleClose}
        />
      ) : null}
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => confirmDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <div className={classes.listContainer}>
        <div className={classes.returnButton}>
          <Button
            variant='outlined'
            color='primary'
            startIcon={<ArrowBackIcon />}
            onClick={() => {
              setCurrentToolOrder(defaultToolOrder)
              editIndex.current = -1
              history.goBack()
            }}>
            Back to tool orders
          </Button>
        </div>
        <SimpleStyledList listItems={tools} renderItem={renderItem}></SimpleStyledList>
      </div>
      <div className={classes.menuIcon}>
        <MenuButton
          actions={[
            {
              icon: <AddIcon />,
              name: 'Add',
              onClick: () => {
                editIndex.current = -1
                handleNew(true)
              },
            },
            { icon: <RefreshIcon />, name: 'Refresh', onClick: getTools },
          ]}
        />
      </div>
      {status?.show ? (
        <Snackbar open={status?.show} autoHideDuration={2000} onClose={handleCloseStatus}>
          <MuiAlert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </MuiAlert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default ToolOrderDetailsList
