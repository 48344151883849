import React, { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'

import Page from '../../common/Page'
import Space from '../../common/Space'
import { UserAdminCard } from '..'
import { currentPageAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'

const UserAdminPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)

  useEffect(() => {
    setActivePage(PAGE_KEYS.adminUsersKey)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page maxWidth style={{ display: 'flex', flexGrow: 1, height: '100%' }}>
      <Space style={{ width: '50%', display: 'flex' }}>
        <UserAdminCard />
      </Space>
    </Page>
  )
}

export default UserAdminPage
