import * as THREE from 'three'

import { CatmullRomCurve3, DoubleSide } from 'three'
import React, { useCallback, useRef, useState } from 'react'
import { Ring, Sphere, Text } from '@react-three/drei'
import { camSpeed, scale, validateIndexValue } from './ThreeDeeView'

import { fonts } from './fonts'
import useCodes from 'components/common/hooks/useCodes'
import { useFrame } from '@react-three/fiber'

const sphereColor = 0x800080
const ringColor1 = 0x0000ff
const ringColor2 = 0xffffff
const compassCol = 0x339933
const closestApproachCol = 0x339933

function Circle({ color, radius, innerRadius }) {
  return (
    <Ring args={[innerRadius, radius, 32]} rotation={[Math.PI / 2, 0, 0]}>
      <meshBasicMaterial attach='material' color={color} transparent={true} opacity={0.6} side={THREE.DoubleSide} />
    </Ring>
  )
}

function ClosestApproach({ svy }) {
  if (!svy.hasOwnProperty('acScan')) return null
  let p1 = new THREE.Vector3(svy.ns * scale, -svy.tvd * scale, svy.ew * scale)
  let p2 = new THREE.Vector3(svy.acScan.offNS * scale, -svy.acScan.offTVD * scale, svy.acScan.offEW * scale)

  const path = new CatmullRomCurve3([p1, p2])

  return (
    <group>
      <Sphere args={[0.7, 16, 16]} position={p2} key={`closeApproachSphere`}>
        <meshBasicMaterial attach='material' color={closestApproachCol} transparent={true} opacity={0.75} />
      </Sphere>

      <mesh visible>
        <tubeGeometry args={[path, 64, 0.2, 16, false]} />
        <meshStandardMaterial color={closestApproachCol} side={DoubleSide} />
      </mesh>
    </group>
  )
}

function WorkSightText({ radius }) {
  let maxRad = Math.max(radius, 1)
  let increment = Math.max(maxRad / 5, 1)
  return (
    <group>
      <Text
        key={`N`}
        position-x={maxRad + increment / 2}
        position-y={0}
        position-z={0}
        text={'N'}
        font={fonts.Roboto}
        fontSize='1'
        anchorX='center'
        anchorY='middle'
        color={compassCol}
        rotation={[0, -Math.PI / 2, 0]}
      />
      <Text
        key={`E`}
        position-x={0}
        position-y={0}
        position-z={maxRad + increment / 2}
        text={'E'}
        font={fonts.Roboto}
        fontSize='1'
        anchorX='center'
        anchorY='middle'
        color={compassCol}
        rotation={[0, -Math.PI / 2, 0]}
      />
      <Text
        key={`S`}
        position-x={-(maxRad + increment / 2)}
        position-y={0}
        position-z={0}
        text={'S'}
        font={fonts.Roboto}
        fontSize='1'
        anchorX='center'
        anchorY='middle'
        color={compassCol}
        rotation={[0, -Math.PI / 2, 0]}
      />
      <Text
        key={`W`}
        position-x={0}
        position-y={0}
        position-z={-(maxRad + increment / 2)}
        text={'W'}
        font={fonts.Roboto}
        fontSize='1'
        anchorX='center'
        anchorY='middle'
        color={compassCol}
        rotation={[0, -Math.PI / 2, 0]}
      />
    </group>
  )
}

function WorkSightRings({ radius }) {
  let maxRad = Math.max(radius, 1)
  let increment = Math.max(maxRad / 5, 1)
  let curCol = ringColor1

  let output = []

  for (let i = maxRad; i > 0; i = i - increment) {
    output.push(<Circle color={curCol} radius={i} innerRadius={i - increment} key={`${i}workSightRing`} />)

    if (curCol === ringColor1) {
      curCol = ringColor2
    } else {
      curCol = ringColor1
    }
  }

  return output
}

function CompassRing({ radius }) {
  let maxRad = Math.max(radius, 1)
  let increment = Math.max(maxRad / 5, 1)
  return <Circle color={compassCol} radius={maxRad + increment / 2} innerRadius={maxRad} />
}

function HighSideLine({ radius }) {
  const ref = useRef()

  const onUpdate = useCallback(
    (self) => {
      let points = [new THREE.Vector3(0, 0, 0), new THREE.Vector3(Math.max(radius, 1), 0, 0)]
      self.setFromPoints(points)
      self.verticesNeedUpdate = true
      self.computeBoundingSphere()
    },
    [radius],
  )

  return (
    <lineSegments ref={ref}>
      <bufferGeometry attach='geometry' onUpdate={onUpdate} />
      <lineBasicMaterial attach='material' color={0xff0000} linewidth={20} />
    </lineSegments>
  )
}

export function rotateAroundWorldAxis(object, axis, rotationVec) {
  let rotAxis = new THREE.Vector3(axis === 'x' ? 1 : 0, axis === 'y' ? 1 : 0, axis === 'z' ? 1 : 0)
  let radians = 0
  if (axis === 'x') radians = rotationVec.x
  if (axis === 'y') radians = rotationVec.y
  if (axis === 'z') radians = rotationVec.z

  let rotWorldMatrix = new THREE.Matrix4()
  rotWorldMatrix.makeRotationAxis(rotAxis.normalize(), radians)
  rotWorldMatrix.multiply(object.matrix)
  object.matrix = rotWorldMatrix
  object.rotation.setFromRotationMatrix(object.matrix)
  object.updateMatrix()
}

const WorkSight = ({ radius, refData, offsetsOn }) => {
  const code = useCodes()
  const ref = useRef()
  const compassRef = useRef()
  const workSightRef = useRef()
  const indexRef = useRef(0)
  const changeValRef = useRef(0)
  const [workSightPos, setWorkSightPos] = useState(null)

  const moveWorkSight = (change) => {
    changeValRef.current += change
    if (Math.abs(changeValRef.current) < 1) return
    if (changeValRef.current >= 1) change = 1
    if (changeValRef.current <= -1) change = -1
    changeValRef.current = 0

    let newTargetIndex = validateIndexValue(refData, indexRef.current + change)
    if (newTargetIndex < 0) return
    let newPosition = new THREE.Vector3(
      refData[0].data[newTargetIndex].x,
      refData[0].data[newTargetIndex].y,
      refData[0].data[newTargetIndex].z,
    )

    indexRef.current = newTargetIndex

    workSightRef.current.position.set(newPosition.x, newPosition.y, newPosition.z)
    compassRef.current.position.set(newPosition.x, newPosition.y, newPosition.z)

    let incRad = refData[0].svyData[newTargetIndex].inc * (Math.PI / 180)
    let aziRad = refData[0].svyData[newTargetIndex].azi * (Math.PI / 180)

    let newRot = new THREE.Vector3(0, Math.PI * 2.0 - aziRad, incRad)
    let rotOrder = ['z', 'y']

    workSightRef.current.rotation.set(0, 0, 0)
    workSightRef.current.updateMatrix()
    rotOrder.forEach((ax) => rotateAroundWorldAxis(workSightRef.current, ax, newRot))

    //This section is a bit of a hack
    //The tube geometry has to get calculated each frame as the co-ordinates change
    //This can be done in the animation loop so it has to be done with state
    //While animating the closest approach is always one index ahead or behind the work sight
    //This code artificially moves it ahead or behind one index
    //Block in the useFrame hook resets it to the correct location when the user stops pressing the arrow keys
    let closestApproachIndex = validateIndexValue(refData, newTargetIndex + change)
    if (closestApproachIndex < 0) return
    if (refData[0].svyData[closestApproachIndex].hasOwnProperty('acScan'))
      setWorkSightPos(refData[0].svyData[closestApproachIndex])
  }

  useFrame(() => {
    if (code.current.has('ArrowUp')) moveWorkSight(-camSpeed)
    if (code.current.has('ArrowDown')) moveWorkSight(camSpeed)

    if (!code.current.has('ArrowUp') && !code.current.has('ArrowDown')) {
      if (refData && refData[0] && refData[0].svyData) {
        if (refData[0].svyData[indexRef.current].hasOwnProperty('acScan'))
          setWorkSightPos(refData[0].svyData[indexRef.current])
      }
    }
  })

  return (
    <group ref={ref} position={[0, 0, 0]}>
      <group ref={workSightRef}>
        <Sphere args={[Math.max(radius / 100, 0.8), 16, 16]}>
          <meshBasicMaterial color={sphereColor} transparent={true} opacity={0.75} />
        </Sphere>
        <WorkSightRings radius={radius} />
        <HighSideLine radius={radius} />
      </group>
      <group ref={compassRef}>
        <CompassRing radius={radius} />
        <WorkSightText radius={radius} />
      </group>
      {workSightPos && offsetsOn && (
        <group>
          <ClosestApproach svy={workSightPos} />
        </group>
      )}
    </group>
  )
}

export default WorkSight
