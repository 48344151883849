import { ObjToPrimitive, pumpJack, scale } from './ThreeDeeView'

export function WellHead({ wellData }) {
  if (!wellData.hasOwnProperty('data')) return null
  if (!Array.isArray(wellData.data)) return null
  if (wellData.data.length === 0) return null

  return ObjToPrimitive({
    url: pumpJack,
    position: { x: wellData.data[0].x, y: wellData.data[0].y < 0 ? wellData.data[0].y : 0, z: wellData.data[0].z },
    scale,
    offset: { x: -1, y: 0, z: -1 },
  })
}
