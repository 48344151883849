import React, { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'

import WellViewPage from '../WellViewPage'
import DailyCostCard from './DailyCostCard'
import { currentPageAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'

const DailyCostPage = ({ history }) => {
  const setActivePage = useSetRecoilState(currentPageAtom)

  useEffect(() => {
    setActivePage(PAGE_KEYS.costsKey)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <WellViewPage>
      <DailyCostCard history={history} />
    </WellViewPage>
  )
}

export default DailyCostPage
