import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  space: {
    padding: 0,
    '&:not(:first-child)': {
      paddingTop: theme.spacing(4),
    },
    maxWidth: '100%',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

const Space = ({ children, center }) => {
  const classes = useStyles()
  let classNames = `${classes.space}`

  if (center) {
    classNames = `${classNames} ${classes.center}`
  }

  return React.Children.map(children, (child) => {
    return <Container className={classNames}>{child}</Container>
  })
}

export default Space
