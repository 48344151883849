import InventoryList from './InventoryList'

import { appColors } from 'utils'

import { makeStyles } from '@material-ui/styles'

import { Card } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: appColors.itemBackColor,
    maxWidth: '100%',
    height: '90vh'
  },
}))
const InventoryCard = () => {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <InventoryList />
    </Card>
  )
}

export default InventoryCard
