export const productKeyMobileApp = '59723604-0e2d-4e65-8c5b-cc7a917f31a4'
export const productKeyWebServices = '02c041de-9058-443e-ad5d-76475b3e7a74'
export const productKeyThirdParty = '83202a65-02cf-49f6-8306-16f904f2b7b2'

export const defProductFeatures = [
  { id: 1, description: 'Base features', enabled: true },
  { id: 2, description: 'Analytics', enabled: false },
  { id: 3, description: 'Anti-Collision', enabled: false },
  { id: 4, description: 'Real Time', enabled: false },
  { id: 5, description: 'Drill Link', enabled: false },
  { id: 6, description: 'Tool Orders', enabled: false },
  { id: 7, description: 'Chat', enabled: false },
  { id: 8, description: 'Data Acq', enabled: false },
  { id: 9, description: 'Hydraulics', enabled: false },
  { id: 10, description: 'Torque and Drag', enabled: false },
]

export const baseProductFeatures = [
  { label: 'Surveys', value: 'surveys' },
  { label: 'Daily Reports', value: 'dailyReports' },
  { label: 'Costs', value: 'costs' },
  { label: 'BHA', value: 'drillString' },
  { label: 'Slide Sheets', value: 'slideSheets' },
  { label: 'Tool Inventory', value: 'toolInventory' },
  { label: 'Well Statistics', value: 'wellStatistics' },
  { label: 'Depth V Days', value: 'depthVdays' },
  { label: 'Logistics Dashboard', value: 'logisticsDashboard' },
  { label: 'Show all wells', value: 'showAllWells' },
  { label: 'Well summary statistics', value: 'wellStatistics' },
  { label: 'Well summary job info', value: 'wellJobInfo' },
]

export const defPermissions = {
  isInnovaAdmin: false,
  isAdmin: false,
  canEdit: false,
  canDelete: false,
  canApprove: false,
  canCreate: false,
}

export function checkPermission(requestedPermission, currentUserPermissions) {
  if (!currentUserPermissions) return false
  if (!currentUserPermissions.hasOwnProperty(requestedPermission)) return false
  if (currentUserPermissions[requestedPermission]) return true
  return false
}

export function checkFeature(featureId, currentUserFeatures) {
  if (!currentUserFeatures) return false
  if (!Array.isArray(currentUserFeatures)) return false
  if (currentUserFeatures.length === 0) return false

  let featureIdFull = `${productKeyWebServices}-${parseInt(featureId)}`
  let featureEnabled = currentUserFeatures.findIndex((feature) => feature === featureIdFull)
  return featureEnabled >= 0
}

export function getBaseProductFeaturesList() {
  let baseFeaturesList = []

  baseProductFeatures.forEach((feature) => baseFeaturesList.push(feature.label))
  return baseFeaturesList
}

export function getBaseFeatureLabelFromValue(value) {
  if (typeof value !== 'string') return ''
  let index = baseProductFeatures.findIndex((feature) => feature.value === value)
  return index >= 0 ? baseProductFeatures[index].label : ''
}

export function getBaseFeatureValueFromLabel(label) {
  if (typeof label !== 'string') return ''
  let index = baseProductFeatures.findIndex((feature) => feature.label === label)
  return index >= 0 ? baseProductFeatures[index].value : ''
}

export function baseFeatureValueArrToLabelArr(valueArr) {
  if (!Array.isArray(valueArr)) return []
  let labelArr = []
  valueArr.forEach((value) => {
    let label = getBaseFeatureLabelFromValue(value)
    if (label !== '') labelArr.push(label)
  })
  return labelArr
}

export function baseFeatureLabelArrToValueArr(labelArr) {
  if (!Array.isArray(labelArr)) return []
  let valueArr = []
  labelArr.forEach((label) => {
    let value = getBaseFeatureValueFromLabel(label)
    if (value !== '') valueArr.push(value)
  })
  return valueArr
}

export function checkBaseFeature(featureValue, enabledBaseFeatures) {
  if (!Array.isArray(enabledBaseFeatures)) return true
  if (enabledBaseFeatures.length === 0) return true

  let blankArray = true
  enabledBaseFeatures.forEach((item) => {
    if (item !== '') blankArray = false
  })

  if (blankArray) return true

  let index = enabledBaseFeatures.findIndex((feature) => feature === featureValue)
  return index >= 0
}

export function baseFeaturesEnabled(currentBaseFeatures) {
  if (!Array.isArray(currentBaseFeatures)) return false
  if (currentBaseFeatures.length === 0) return false
  return true
}
