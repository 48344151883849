import React, { useState } from 'react'

import { Avatar, Drawer, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { GetActivityColor, GetActivityText, getStatusAvatar } from '../common/activitySelector'
import { useRecoilValue } from 'recoil'
import { currentWellDetailsAtom } from 'atoms'
import StyledBadge from './StyledBadge'
import SimpleWellList from './SimpleWellList'

const useStyles = makeStyles((theme) => ({
  avatarContainer: {
    margin: 10,
    marginRight: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'lightgrey',
    borderRadius: '50%',
    zIndex: 10,
    cursor: 'pointer',
  },
  paper: {
    backgroundColor: theme.palette.itemBackground,
  },
  tooltip: {
    backgroundColor: 'rgb(19,62,96)',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
}))

const useDrawerStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.itemBackground,
  },
}))

const WellDrawer = ({ selectorToggle, setSelectorToggle }) => {
  const classes = useDrawerStyles()

  const onCloseDrawer = (event) => {
    setSelectorToggle(false)
  }

  return (
    <React.Fragment>
      <Drawer
        anchor='right'
        disableEnforceFocus
        open={selectorToggle}
        onClose={onCloseDrawer}
        classes={{ paper: classes.paper }}>
        <SimpleWellList />
      </Drawer>
    </React.Fragment>
  )
}

const WellSelector = () => {
  const classes = useStyles()
  const currentWellDetails = useRecoilValue(currentWellDetailsAtom)
  const [selectorToggle, setSelectorToggle] = useState(false)

  const onSelectWell = () => {
    setSelectorToggle(!selectorToggle)
  }

  return (
    <React.Fragment>
      <Tooltip title={'Select well'} placement='bottom' classes={{ tooltip: classes.tooltip }}>
        <div className={classes.avatarContainer} onClick={() => onSelectWell()}>
          <StyledBadge
            badgeColor={GetActivityColor(currentWellDetails.wellStatus)}
            badgeContent={GetActivityText(currentWellDetails.wellStatus)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}>
            <Avatar
              alt='Well Icon'
              src={getStatusAvatar(currentWellDetails.wellStatus)}
              style={{ height: '30px', width: '30px' }}></Avatar>
          </StyledBadge>
        </div>
      </Tooltip>
      <WellDrawer selectorToggle={selectorToggle} setSelectorToggle={setSelectorToggle} />
    </React.Fragment>
  )
}

export default WellSelector
