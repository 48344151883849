import React, { useContext, useEffect, useReducer, useRef, useState } from 'react'

import Canvas from 'components/common/Canvas'

import { makeStyles } from '@material-ui/core/styles'
import DashboardContext from '../dashboardContext'
import FlashCanvasText from '../../common/flashCanvasText'
import { calcTimeDiff } from '../../common/timeUtils'
import useInterval from 'components/common/hooks/useInterval'

const useStyles = makeStyles((theme) => ({
  toolface: {
    display: 'flex',
    width: '100%',
    boxShadow: '0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);',
  },
  wrap: {
    maxWidth: '100%',
    margin: '0 auto',
    '&>*+*': { marginTop: '1em' },
  },
  aspect: {
    display: 'flex',
    justifyContent: 'center',
    '&::before': {
      content: '""', // this is what makes the aspect ratio stuff work.
      display: 'block',
      height: '0',
      width: '0',
      paddingBottom: '100%',
      [theme.breakpoints.up('sm')]: {
        paddingBottom: '50%',
      },
    },
  },
}))

const Rads2Deg = 180.0 / Math.PI
const responsiveWidthThreshold = 750

// from: https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript
const getTextWidth = (text, font) => {
  // if given, use cached canvas for better performance
  // else, create new canvas
  var canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement('canvas'))
  var context = canvas.getContext('2d')
  context.font = font
  var metrics = context.measureText(text)
  return Math.ceil(metrics.width)
}

const tfTimerText = new FlashCanvasText({
  baseColor: 'rgba(56, 219, 255, 1.0)',
  altColor: 'rgba(255, 255, 0, 1.0)', // 'rgba(255, 0, 0, 1.0)',
  baseFont: 'bold 24px sans-serif',
  altFont: 'bold 24px sans-serif', //'bold 22px sans-serif'
})

const tfTimerReducer = (state, action) => {
  switch (action.type) {
    case 'START_TFTIMER':
      return {
        ...state,
        isTFCurrent: false,
        lastTFTime: action.payload,
      }
    case 'TICK_TFTIMER':
      return {
        ...state,
        lastTFTime: state.lastTFTime + 1,
      }
    case 'STOP_TFTIMER':
      return {
        ...state,
        isTFCurrent: true,
        lastTFTime: 0,
      }
    default:
      return state
  }
}

const initialTFTimerState = {
  isTFCurrent: true,
  lastTFTime: 0,
}

const ToolfaceControl = (props) => {
  const classes = useStyles()
  const { data: rtData } = useContext(DashboardContext)

  let width = props.width
  let height = props.height
  // const { width, height } = props
  let maxExtent = width > height ? height : width
  let centerPtX = width / 2
  let centerPtY = height / 2
  let margin = getTextWidth('WWW', '1em sans-serif') * 2
  let maxRadius = maxExtent / 2 - margin
  let numCircles = 10 // 16 in the desktop app, should drop by pow2 depending upon scale
  let distanceBetweenCircles = maxRadius / numCircles
  const crossoverInc = 5.0
  const [currentTF, setCurrentTF] = useState(0)
  const [effectiveTF, setETF] = useState(0)
  const [tfArray, setTFArray] = useState([])

  const [canvasHeight, setCanvasHeight] = useState(0)
  const [canvasWidth, setCanvasWidth] = useState(0)

  const [stateTFTimer, dispatchTFTimer] = useReducer(tfTimerReducer, initialTFTimerState)

  const thisref = useRef(null)
  useEffect(() => {
    if (thisref.current) {
      setCanvasWidth(thisref.current.clientWidth)
      if (thisref.current.clientWidth < 600) {
        setCanvasHeight(thisref.current.clientWidth)
      } else {
        setCanvasHeight(thisref.current.clientHeight)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thisref?.current?.clientWidth])

  useEffect(() => {
    if (rtData) {
      let tf = -999.25
      let lastTFReported = calcTimeDiff(rtData.curTime, new Date().toISOString())
      // console.log('lastTFReported: ', lastTFReported)
      if (parseFloat(rtData.surveyInc) <= crossoverInc) {
        if (lastTFReported <= 15) {
          if (!stateTFTimer.isTFCurrent) {
            //if tfActive was false, now it's active. turn off TF timer
            dispatchTFTimer({ type: 'STOP_TFTIMER' })
          }
          tf = parseFloat(rtData.mtf)
        } else {
          if (stateTFTimer.isTFCurrent) {
            dispatchTFTimer({ type: 'START_TFTIMER', payload: lastTFReported })
          }
        }
      } else {
        if (lastTFReported <= 15) {
          if (!stateTFTimer.isTFCurrent) {
            //if tfActive was false, now it's active. turn off TF timer
            dispatchTFTimer({ type: 'STOP_TFTIMER' })
          }
          tf = parseFloat(rtData.gtf)
        } else {
          if (stateTFTimer.isTFCurrent) {
            dispatchTFTimer({ type: 'START_TFTIMER', payload: lastTFReported })
          }
        }
      }
      if (tfArray.length >= 7) {
        tfArray.length = 6
      }
      setETF(rtData.etf)
      setCurrentTF(tf)
      let prevArray = tfArray
      setTFArray([tf, ...prevArray])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rtData])

  useInterval(
    () => {
      dispatchTFTimer({ type: 'TICK_TFTIMER' })
    },
    !stateTFTimer.isTFCurrent ? 1000 : null,
  )

  if (!rtData) return null

  const processTF = (slideTF, svyInc) => {
    if (slideTF === -999.25) {
      return { tf: -999.25, tfMod: '' }
    }

    let tfVal = parseFloat(slideTF)
    let tfMod = ''
    const inc = parseFloat(svyInc)

    if (tfVal < 0.0) {
      tfVal += 360.0
    }
    if (tfVal > 360.0) {
      tfVal -= 360.0
    }
    if (inc <= crossoverInc) {
      tfMod = '° M'
    } else {
      if (tfVal > 180.0) {
        tfVal = 360.0 - tfVal
        tfMod = '°L'
      } else {
        tfMod = '°R'
      }
    }
    return { tf: tfVal, tfMod: tfMod }
  }

  const translateTF = (slideTF, svyInc) => {
    let tfVal = slideTF
    let tfMod = ''
    if (slideTF === -999.25) {
      return { tf: -999.25, tfMod: '' }
    }
    const inc = parseFloat(svyInc)

    tfVal -= 90 // rotate 90 deg

    if (tfVal < 0.0) {
      tfVal += 360.0
    }

    if (tfVal > 360.0) {
      tfVal -= 360.0
    }
    if (inc <= crossoverInc) {
      tfMod = '° M'
    } else {
      if (tfVal > 180.0) {
        //tfVal = 360.0 - tfVal // disable this last transform, tfMod may have no use in translate func--prepare to remove
        tfMod = '° L'
      } else {
        tfMod = '° R'
      }
    }
    return { tf: tfVal, tfMod: tfMod }
  }

  const timeFormatter = (secs) => {
    let timestr = ''
    let date = new Date(0)
    if (secs < 3599) {
      date.setSeconds(secs)
      timestr = date.toISOString().substr(14, 5)
    } else {
      timestr = '> 1hr'
    }
    return timestr
  }

  const drawScale = (context) => {
    context.lineWidth = 1
    for (let i = 1; i <= numCircles; i++) {
      if (i % 4 === 0) {
        context.strokeStyle = '#B0B0B0'
      } else {
        context.strokeStyle = '#606060'
      }
      context.beginPath()
      context.arc(width / 2, height / 2, distanceBetweenCircles * i, 0, 2 * Math.PI)
      context.stroke()
    }
  }

  const drawRadialLines = (context) => {
    const radialLength = maxRadius
    let xStart, yStart, xEnd, yEnd
    context.strokeStyle = '#606060'
    context.lineWidth = 1
    for (let angle = 0; angle < 360; angle += 30) {
      context.beginPath()
      xStart = distanceBetweenCircles * 2 * Math.sin((angle / 180) * Math.PI)
      yStart = distanceBetweenCircles * 2 * Math.cos((angle / 180) * Math.PI)
      xEnd = radialLength * Math.sin((angle / 180) * Math.PI)
      yEnd = radialLength * Math.cos((angle / 180) * Math.PI)
      context.moveTo(centerPtX + xStart, centerPtY + yStart)
      context.lineTo(centerPtX + xEnd, centerPtY + yEnd)
      context.stroke()
    }
  }

  const drawRadialLabels = (context) => {
    const radialLength = maxRadius + 20
    let xEnd, yEnd
    let angleOutput = ''
    context.fillStyle = '#B0B0B0'
    context.font = '1em sans-serif'
    context.textAlign = 'center'
    context.textBaseline = 'middle'
    for (let angle = 0; angle < 360; angle += 30) {
      xEnd = radialLength * Math.sin((-(angle - 180) / 180) * Math.PI)
      yEnd = radialLength * Math.cos((-(angle - 180) / 180) * Math.PI)
      const { tf: angleNew, tfMod } = processTF(angle, rtData.surveyInc)
      angleOutput = angleNew.toString()
      // add toolface label modifier to 90° on left and right side
      if (parseFloat(rtData.surveyInc) > crossoverInc && angleNew === 90.0) {
        angleOutput += tfMod
      }
      context.fillText(angleOutput, centerPtX + xEnd, centerPtY + yEnd)
    }
  }

  const getETFColor = (slideGrade) => {
    const grade = parseFloat(slideGrade)
    if (grade > 90) return 'rgba(105, 179, 76, 0.50)'
    if (grade > 80) return 'rgba(172, 179, 52, 0.50)'
    if (grade > 70) return 'rgba(250, 183, 51, 0.50)'
    if (grade > 60) return 'rgba(255, 142, 21, 0.50)'
    if (grade > 50) return 'rgba(255, 78, 17, 0.50)'
    return 'rgba(255, 13, 13, 0.50)'
  }

  const drawEffectiveTF = (context) => {
    if (rtData.rigState === 'SLIDING') {
      const { tf: tfStart } = translateTF(effectiveTF - 3, rtData.surveyInc)
      const { tf: tfEnd } = translateTF(effectiveTF + 3, rtData.surveyInc)
      const slideGrade = rtData ? rtData.slideGrade : '0.0'
      context.fillStyle = getETFColor(slideGrade)
      context.beginPath()
      context.moveTo(centerPtX, centerPtY)
      context.arc(centerPtX, centerPtY, maxRadius, (tfStart / 180.0) * Math.PI, (tfEnd / 180.0) * Math.PI)
      context.moveTo(centerPtX, centerPtY)
      context.fill()
    }
  }

  const drawSlideTargetTF = (context) => {
    if (
      rtData.rigState === 'SLIDING' &&
      parseFloat(rtData.slideLength) > 0.0 &&
      parseFloat(rtData.distLeftToSlide) > 0.0
    ) {
      const { tf: tfStart } = translateTF(parseFloat(rtData.slideTF) - 2, rtData.surveyInc)
      const { tf: tfEnd } = translateTF(parseFloat(rtData.slideTF) + 2, rtData.surveyInc)
      context.fillStyle = 'rgba(0, 255, 0, 0.50)'
      context.beginPath()
      context.moveTo(centerPtX, centerPtY)
      context.arc(centerPtX, centerPtY, maxRadius, (tfStart / 180.0) * Math.PI, (tfEnd / 180.0) * Math.PI)
      context.moveTo(centerPtX, centerPtY)
      context.fill()
    }
  }

  // the function below has been superceded by drawTFDashes as an experiment
  // eslint-disable-next-line no-unused-vars
  const drawTFCrumbs = (context) => {
    const radialLength = maxRadius
    const dotRadius = maxExtent / 48
    let xEnd, yEnd
    context.fillStyle = '#A0D0FF'
    for (let i = 0; i < tfArray.length; i++) {
      xEnd = (radialLength - distanceBetweenCircles * i) * Math.sin((tfArray[i] / 180) * Math.PI)
      yEnd = (radialLength - distanceBetweenCircles * i) * Math.cos((tfArray[i] / 180) * Math.PI)
      context.beginPath()
      context.arc(centerPtX + xEnd, centerPtY - yEnd, dotRadius, 0, 2 * Math.PI)
      context.fill()
    }
  }

  const drawTFDashes = (context) => {
    const radialLength = maxRadius
    let xStart, yStart, xEnd, yEnd
    context.strokeStyle = 'rgba(52, 201, 235, 1.0)'
    context.lineWidth = 5
    for (let i = 0; i < tfArray.length; i++) {
      if (tfArray[i] === -999.25) continue
      xStart =
        (radialLength - distanceBetweenCircles * i - distanceBetweenCircles / 1.25) *
        Math.sin((tfArray[i] / 180) * Math.PI)
      yStart =
        (radialLength - distanceBetweenCircles * i - distanceBetweenCircles / 1.25) *
        Math.cos((tfArray[i] / 180) * Math.PI)
      xEnd = (radialLength - distanceBetweenCircles * i) * Math.sin((tfArray[i] / 180) * Math.PI)
      yEnd = (radialLength - distanceBetweenCircles * i) * Math.cos((tfArray[i] / 180) * Math.PI)
      context.beginPath()
      context.moveTo(centerPtX + xStart, centerPtY - yStart)
      context.lineTo(centerPtX + xEnd, centerPtY - yEnd)
      context.stroke()
    }
    context.lineWidth = 1 // reset
  }

  // draw the bit and survey projections
  const drawProjections = (context) => {
    const radialLength = maxRadius
    const dotRadius = maxExtent / 48
    const bitProj = calcDistAndAngle(parseFloat(rtData.bitLR), parseFloat(rtData.bitUD))
    const svyProj = calcDistAndAngle(parseFloat(rtData.surveyLR), parseFloat(rtData.surveyUD))
    // scale will be set by the largest distance to display
    const distance = bitProj.distance > svyProj.distance ? bitProj.distance : svyProj.distance
    const minDistanceScale = 50
    const scale = (Math.floor(distance / minDistanceScale) + 1) * minDistanceScale
    let x, y
    context.strokeStyle = '#2D2D2D'
    context.fillStyle = '#00FF00'
    x = (bitProj.distance / scale) * radialLength * Math.sin((bitProj.angle / 180) * Math.PI)
    y = (bitProj.distance / scale) * radialLength * Math.cos((bitProj.angle / 180) * Math.PI)
    context.beginPath()
    context.arc(centerPtX + x, centerPtY - y, dotRadius, 0, 2 * Math.PI)
    context.fill()
    context.stroke()
    context.fillStyle = '#009691'
    x = (svyProj.distance / scale) * radialLength * Math.sin((svyProj.angle / 180) * Math.PI)
    y = (svyProj.distance / scale) * radialLength * Math.cos((svyProj.angle / 180) * Math.PI)
    context.beginPath()
    context.arc(centerPtX + x, centerPtY - y, dotRadius, 0, 2 * Math.PI)
    context.fill()
    context.stroke()

    context.fillStyle = '#B0B0B0'
    context.font = '14px sans-serif'
    context.textAlign = 'right'
    context.textBaseline = 'middle'
    x = radialLength
    y = 12
    let angleOutput = scale.toFixed(0)
    context.fillText(angleOutput, centerPtX + x, centerPtY + y)
    x = radialLength / 2
    angleOutput = (scale / 2).toFixed(0)
    context.fillText(angleOutput, centerPtX + x, centerPtY + y)
  }

  const calcDistAndAngle = (x, y) => {
    let dist = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2))
    let ang = Math.atan2(x, y) * Rads2Deg
    return { angle: ang, distance: dist }
  }

  const drawTFLegend = (context) => {
    let x, y
    const dotRadius = 6
    context.fillStyle = '#B0B0B0'
    context.font = '14px sans-serif'
    context.textAlign = 'right'
    context.textBaseline = 'middle'
    // draw in bottom right quadrant outside of control
    x = width - margin / 2
    y = height - margin / 2
    context.fillText('Bit Proj', x - 4, y)
    context.fillStyle = '#00FF00'
    context.beginPath()
    context.arc(x + dotRadius, y, dotRadius, 0, 2 * Math.PI)
    context.fill()
    context.stroke()
    y -= 16
    context.fillStyle = '#B0B0B0'
    context.fillText('Survey', x - 4, y)
    context.fillStyle = '#009691'
    context.beginPath()
    context.arc(x + dotRadius, y, dotRadius, 0, 2 * Math.PI)
    context.fill()
    context.stroke()
  }

  const drawTFTimer = (context) => {
    if (!stateTFTimer.isTFCurrent) {
      let x, y
      let label = ''
      const fontLabel = '14px sans-serif'
      context.fillStyle = '#B0B0B0'
      context.font = fontLabel
      context.textAlign = 'left'
      context.textBaseline = 'middle'
      // draw in bottom right quadrant outside of control
      if (width >= responsiveWidthThreshold) {
        x = margin / 2
      } else {
        x = margin / 2 - 16
      }
      y = height - margin / 2

      if (width >= responsiveWidthThreshold) {
        label = `Time since TF:`
      } else {
        label = `TF:`
      }
      let labelWidth = getTextWidth(label, fontLabel)

      context.fillText(label, x, y)
      if (stateTFTimer.lastTFTime > 60) {
        tfTimerText.startFlash(performance.now())
      } else {
        tfTimerText.stopFlash()
      }
      tfTimerText.render(context, timeFormatter(stateTFTimer.lastTFTime), x + labelWidth + 4, y)
    }
  }

  const drawDirectiveText = (context) => {
    const { tf: angleNew, tfMod } = processTF(currentTF, rtData.surveyInc)
    if (angleNew === -999.25) {
      return
    }
    const directive = angleNew.toFixed(0) + tfMod
    context.strokeStyle = '#2D2D2D'
    context.fillStyle = 'rgba(56, 219, 255, 1.0)'
    context.font = 'bold 2em sans-serif'
    context.textAlign = 'center'
    context.textBaseline = 'middle'
    context.fillText(directive, centerPtX, centerPtY)
    context.strokeText(directive, centerPtX, centerPtY)
  }

  const draw = (context) => {
    width = context && context.canvas ? context.canvas.width : 0
    height = context && context.canvas ? context.canvas.height : 0
    maxExtent = width > height ? height : width
    centerPtX = width / 2
    centerPtY = height / 2
    margin = getTextWidth('90°R', '1em sans-serif') * 2
    maxRadius = maxExtent / 2 - margin
    numCircles = 8 // 16 in the desktop app, should drop by pow2 depending upon scale
    distanceBetweenCircles = maxRadius / numCircles

    if (maxRadius < 0) return
    context.clearRect(0, 0, context.canvas.width, context.canvas.height)
    context.fillStyle = 'rgba(32,32,32,1.0)'
    context.fillRect(0, 0, context.canvas.width, context.canvas.height)
    context.strokeStyle = 'rgba(0,0,0,1)'
    context.strokeRect(0, 0, context.canvas.width, context.canvas.height)

    var gradient = context.createRadialGradient(centerPtX, centerPtY, 0, centerPtX, centerPtY, maxRadius)
    gradient.addColorStop(0, '#404040')
    gradient.addColorStop(1, '#101010')
    context.fillStyle = gradient
    context.beginPath()
    context.arc(centerPtX, centerPtY, maxRadius, 0, 2 * Math.PI)
    context.fill()
    drawScale(context)
    drawRadialLines(context)
    // drawTFCrumbs(context) // possibly will replace with drawTFdashes, as below
    drawProjections(context)
    drawTFDashes(context)
    drawSlideTargetTF(context)
    drawEffectiveTF(context)
    drawRadialLabels(context)
    drawDirectiveText(context)
    drawTFLegend(context)
    drawTFTimer(context)
  }

  // eslint-disable-next-line no-unused-vars
  const animate = (context, frameCount) => {
    // test rendering
    context.clearRect(0, 0, context.canvas.width, context.canvas.height)
    context.fillStyle = '#404040'
    context.fillRect(0, 0, context.canvas.width, context.canvas.height)
    context.fillStyle = '#F0F0F0'
    context.beginPath()
    context.arc(50, 100, 20 * Math.sin(frameCount * 0.05) ** 2, 0, 2 * Math.PI)
    context.fill()
  }

  return (
    <div>
      <div className={classes.aspect} ref={thisref}>
        <Canvas draw={draw} square={true} width={canvasWidth} height={canvasHeight} />
      </div>
    </div>
  )
}

export default ToolfaceControl
