import { useRecoilValue } from 'recoil'
import { userPrefsAtom } from 'atoms'

export const UNITS_FOR = {
  Depth: 'DEPTH',
  Dogleg: 'DOGLEG',
  Diameter: 'DIAMETER',
  MudWeight: 'MUD_WEIGHT',
  FlowRate: 'FLOW_RATE',
  Pressure: 'PRESSURE',
  Temperature: 'TEMPERATURE',
  Torque: 'TORQUE',
  Weight: 'WEIGHT',
  Cost: 'COST',
}

const UNITS = {
  API: 'API',
  US: 'US',
  Metric: 'METRIC',
  MetricMixed: 'METRIC_MIXED',
  'Metric-Mixed': 'METRIC_MIXED',
}

const UNITS_MAP = {
  [UNITS.API]: {
    [UNITS_FOR.Depth]: 'ft',
    [UNITS_FOR.Diameter]: 'in',
    [UNITS_FOR.Dogleg]: 'deg/100ft',
    [UNITS_FOR.MudWeight]: 'ppg',
    [UNITS_FOR.FlowRate]: 'gpm',
    [UNITS_FOR.Pressure]: 'psi',
    [UNITS_FOR.Temperature]: 'degF',
    [UNITS_FOR.Torque]: 'kftlb',
    [UNITS_FOR.Weight]: 'klb',
    [UNITS_FOR.Cost]: '$',
  },
  [UNITS.US]: {
    [UNITS_FOR.Depth]: 'Usft',
    [UNITS_FOR.Diameter]: 'in',
    [UNITS_FOR.Dogleg]: 'deg/100ft',
    [UNITS_FOR.MudWeight]: 'ppg',
    [UNITS_FOR.FlowRate]: 'gpm',
    [UNITS_FOR.Pressure]: 'psi',
    [UNITS_FOR.Temperature]: 'degF',
    [UNITS_FOR.Torque]: 'kftlb',
    [UNITS_FOR.Weight]: 'klb',
    [UNITS_FOR.Cost]: '$',
  },
  [UNITS.Metric]: {
    [UNITS_FOR.Depth]: 'm',
    [UNITS_FOR.Diameter]: 'mm',
    [UNITS_FOR.Dogleg]: 'deg/30m',
    [UNITS_FOR.MudWeight]: 'sg',
    [UNITS_FOR.FlowRate]: 'lpm',
    [UNITS_FOR.Pressure]: 'bar',
    [UNITS_FOR.Temperature]: 'degC',
    [UNITS_FOR.Torque]: 'kN',
    [UNITS_FOR.Weight]: 'mt',
    [UNITS_FOR.Cost]: '$',
  },
  [UNITS.MetricMixed]: {
    [UNITS_FOR.Depth]: 'm',
    [UNITS_FOR.Diameter]: 'in',
    [UNITS_FOR.Dogleg]: 'deg/30m',
    [UNITS_FOR.MudWeight]: 'ppg',
    [UNITS_FOR.FlowRate]: 'gpm',
    [UNITS_FOR.Pressure]: 'psi',
    [UNITS_FOR.Temperature]: 'degC',
    [UNITS_FOR.Torque]: 'kN',
    [UNITS_FOR.Weight]: 'mt',
    [UNITS_FOR.Cost]: '$',
  },
}

const useUnits = (type) => {
  const unitPref = useRecoilValue(userPrefsAtom)
  const units = UNITS[unitPref !== '' ? unitPref : 'API']

  return UNITS_MAP[units][type]
}

export default useUnits
