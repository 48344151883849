import * as THREE from 'three'

import React, { useCallback, useRef } from 'react'

import { Text, Box } from '@react-three/drei'
import { fonts } from './fonts'

const AXIS_PADDING = 0.1
const GridBox = ({ minMaxValues }) => {
  return (
    <React.Fragment>
      <Box
        args={[minMaxValues.xLen, minMaxValues.yLen, 0]}
        position={
          new THREE.Vector3(
            minMaxValues.xOffsets.offset,
            minMaxValues.yOffsets.offset,
            minMaxValues.zLen / 2 + minMaxValues.zOffsets.offset,
          )
        }>
        <meshBasicMaterial attach='material' color={0x6495ed} transparent={true} opacity={0.25} />
      </Box>
      <Box
        args={[0, minMaxValues.yLen, minMaxValues.zLen]}
        position={
          new THREE.Vector3(
            minMaxValues.xLen / 2 + minMaxValues.xOffsets.offset,
            minMaxValues.yOffsets.offset,
            minMaxValues.zOffsets.offset,
          )
        }>
        <meshBasicMaterial attach='material' color={0x6495ed} transparent={true} opacity={0.25} />
      </Box>
      <Box
        args={[minMaxValues.xLen, 0, minMaxValues.zLen]}
        position={new THREE.Vector3(minMaxValues.xOffsets.offset, minMaxValues.minY, minMaxValues.zOffsets.offset)}>
        <meshBasicMaterial attach='material' color={0x3cb382} transparent={true} opacity={0.25} />
      </Box>
    </React.Fragment>
  )
}

export function getMinMaxData(minMaxValues, refData, offsetData) {
  resetMinMaxValues(minMaxValues)

  if (refData?.length > 0) getMinMaxValues(refData[0].data, minMaxValues)
  if (offsetData?.length > 0) offsetData.forEach((offsetWell) => getMinMaxValues(offsetWell.data, minMaxValues))

  if (minMaxValues.maxX === 0) minMaxValues.maxX = 500
  if (minMaxValues.minX === 0) minMaxValues.minX = -500

  if (minMaxValues.maxZ === 0) minMaxValues.maxZ = 500
  if (minMaxValues.minZ === 0) minMaxValues.minZ = -500

  minMaxValues.maxX = roundUp(minMaxValues.maxX * (1 + AXIS_PADDING), 500)
  minMaxValues.minX = roundUp(minMaxValues.minX * (1 + AXIS_PADDING), 500)

  minMaxValues.maxY = 0
  minMaxValues.minY = roundUp(minMaxValues.minY * (1 + AXIS_PADDING), 500)

  minMaxValues.maxZ = roundUp(minMaxValues.maxZ * (1 + AXIS_PADDING), 500)
  minMaxValues.minZ = roundUp(minMaxValues.minZ * (1 + AXIS_PADDING), 500)

  minMaxValues.xLen = minMaxValues.maxX - minMaxValues.minX
  minMaxValues.yLen = minMaxValues.maxY - minMaxValues.minY
  minMaxValues.zLen = minMaxValues.maxZ - minMaxValues.minZ

  if (minMaxValues.xLen < 500) {
    minMaxValues.xLen = 500
    minMaxValues.maxX = 250
    minMaxValues.minX = -250
  }

  if (minMaxValues.yLen < 500) {
    minMaxValues.yLen = 500
    minMaxValues.maxY = 500
    minMaxValues.minY = 0
  }

  if (minMaxValues.zLen < 500) {
    minMaxValues.zLen = 500
    minMaxValues.maxZ = 250
    minMaxValues.minZ = -250
  }

  calcAllOffsets(minMaxValues)
}

export function roundUp(numToRound, multiple) {
  let sign = 1
  if (multiple === null || multiple === undefined) multiple = 1.0
  if (numToRound === null || numToRound === undefined) return 0
  if (typeof multiple === 'string') multiple = parseFloat(multiple)
  if (typeof numToRound === 'string') numToRound = parseFloat(numToRound)
  if (numToRound === 0) return numToRound
  if (numToRound < 0) sign = -1

  let x = Math.abs(numToRound) / multiple
  x = Math.ceil(x + 0.5) * multiple

  return x * sign
}

export function resetMinMaxValues(minMaxValues) {
  minMaxValues.minX = 999999
  minMaxValues.maxX = -999999
  minMaxValues.minY = 999999
  minMaxValues.maxY = -999999
  minMaxValues.minZ = 999999
  minMaxValues.maxZ = -999999
}

export function getMinMaxValues(data, minMaxValues) {
  if (data) {
    data.forEach((point) => {
      if (point.x < minMaxValues.minX) minMaxValues.minX = point.x
      if (point.x > minMaxValues.maxX) minMaxValues.maxX = point.x
      if (point.y < minMaxValues.minY) minMaxValues.minY = point.y
      if (point.y > minMaxValues.maxY) minMaxValues.maxY = point.y
      if (point.z < minMaxValues.minZ) minMaxValues.minZ = point.z
      if (point.z > minMaxValues.maxZ) minMaxValues.maxZ = point.z
    })
  }
}

function calcAxisOffset(min, max) {
  let len = max - min
  let midPoint = len / 2
  let offset = (Math.abs(max) - Math.abs(min)) / 2
  return { len: len, offset: offset, center: midPoint + offset }
}

function calcAllOffsets(minMaxData) {
  minMaxData.xOffsets = calcAxisOffset(minMaxData.minX, minMaxData.maxX)
  minMaxData.yOffsets = calcAxisOffset(minMaxData.minY, minMaxData.maxY)
  minMaxData.zOffsets = calcAxisOffset(minMaxData.minZ, minMaxData.maxZ)
}

const GridLines = ({ refData, offsetData, scale }) => {
  const labelColor = 0x909090
  const linesX = []

  const ref = useRef()

  const minMaxValues = {
    minX: 0,
    maxX: 100,
    minY: 0,
    maxY: 100,
    minZ: 0,
    maxZ: 100,
    xLen: 100,
    yLen: 100,
    zLen: 100,
  }

  getMinMaxData(minMaxValues, refData, offsetData)

  const axisLabelsNS = []
  const axisLabelsEW = []
  const axisLabelsTVDX = []
  const axisLabelsTVDY = []
  const axisLabelsTVDZ = []

  let halfX = minMaxValues.xLen / 2
  let halfZ = minMaxValues.zLen / 2

  let step = minMaxValues.xLen / 20

  // bottom grid
  for (let i = -halfX + minMaxValues.xOffsets.offset; i <= halfX + minMaxValues.xOffsets.offset; i += step) {
    linesX.push(new THREE.Vector3(i, minMaxValues.minY, minMaxValues.zOffsets.offset + halfZ))
    linesX.push(new THREE.Vector3(i, minMaxValues.minY, minMaxValues.zOffsets.offset - halfZ))

    axisLabelsEW.push({
      label: -(i / scale).toString(),
      point: new THREE.Vector3(i, minMaxValues.minY, minMaxValues.zOffsets.offset + halfZ + 20),
    })

    axisLabelsEW.push({
      label: -(i / scale).toString(),
      point: new THREE.Vector3(i, minMaxValues.minY, minMaxValues.zOffsets.offset - halfZ - 20),
    })
  }

  step = minMaxValues.zLen / 20
  for (let i = -halfZ + minMaxValues.zOffsets.offset; i <= halfZ + minMaxValues.zOffsets.offset; i += step) {
    linesX.push(new THREE.Vector3(minMaxValues.xOffsets.offset + halfX, minMaxValues.minY, i))
    linesX.push(new THREE.Vector3(minMaxValues.xOffsets.offset - halfX, minMaxValues.minY, i))

    axisLabelsNS.push({
      label: -(i / scale).toString(),
      point: new THREE.Vector3(minMaxValues.xOffsets.offset + halfX + 20, minMaxValues.minY, i),
    })

    axisLabelsNS.push({
      label: -(i / scale).toString(),
      point: new THREE.Vector3(minMaxValues.xOffsets.offset - halfX - 20, minMaxValues.minY, i),
    })
  }

  //Vertical Grids
  step = minMaxValues.yLen / 20
  for (let i = minMaxValues.minY; i <= 0; i += step) {
    linesX.push(new THREE.Vector3(minMaxValues.xOffsets.offset + halfX, i, minMaxValues.zOffsets.offset + halfZ))
    linesX.push(new THREE.Vector3(minMaxValues.xOffsets.offset + halfX, i, minMaxValues.zOffsets.offset - halfZ))

    axisLabelsTVDZ.push({
      label: (-i / scale).toString(),
      point: new THREE.Vector3(minMaxValues.xOffsets.offset - halfX - 20, i, minMaxValues.zOffsets.offset + halfZ),
    })

    axisLabelsTVDY.push({
      label: (-i / scale).toString(),
      point: new THREE.Vector3(minMaxValues.xOffsets.offset + halfX + 20, i, minMaxValues.zOffsets.offset + halfZ),
    })

    axisLabelsTVDX.push({
      label: (-i / scale).toString(),
      point: new THREE.Vector3(minMaxValues.xOffsets.offset + halfX + 20, i, minMaxValues.zOffsets.offset - halfZ),
    })
  }

  for (let i = minMaxValues.minY; i <= 0; i += step) {
    linesX.push(new THREE.Vector3(minMaxValues.xOffsets.offset + halfX, i, minMaxValues.zOffsets.offset + halfZ))
    linesX.push(new THREE.Vector3(minMaxValues.xOffsets.offset - halfX, i, minMaxValues.zOffsets.offset + halfZ))
  }

  step = minMaxValues.xLen / 20
  for (let i = -halfX + minMaxValues.xOffsets.offset; i <= halfX + minMaxValues.xOffsets.offset; i += step) {
    linesX.push(new THREE.Vector3(i, minMaxValues.minY, minMaxValues.zOffsets.offset + halfZ))
    linesX.push(new THREE.Vector3(i, 0, minMaxValues.zOffsets.offset + halfZ))
  }

  step = minMaxValues.zLen / 20
  for (let i = -halfZ + minMaxValues.zOffsets.offset; i <= halfZ + minMaxValues.zOffsets.offset; i += step) {
    linesX.push(new THREE.Vector3(minMaxValues.xOffsets.offset + halfX, minMaxValues.minY, i))
    linesX.push(new THREE.Vector3(minMaxValues.xOffsets.offset + halfX, 0, i))
  }

  const points = linesX
  const onUpdate = useCallback(
    (self) => {
      self.setFromPoints(points)
      self.verticesNeedUpdate = true
      self.computeBoundingSphere()
    },
    [points],
  )

  return (
    <React.Fragment>
      <GridBox minMaxValues={minMaxValues} />
      <lineSegments ref={ref}>
        <bufferGeometry attach='geometry' onUpdate={onUpdate} />
        <lineBasicMaterial attach='material' color={0x909090} />
      </lineSegments>
      {axisLabelsNS.map((item, index) => {
        return (
          <Text
            key={`${index}NS`}
            position-x={item.point.x}
            position-y={item.point.y}
            position-z={item.point.z}
            text={item.label}
            font={fonts.Roboto}
            fontSize='8'
            anchorX='center'
            anchorY='middle'
            color={labelColor}
            orientation='+x-z'></Text>
        )
      })}
      {axisLabelsEW.map((item, index) => {
        return (
          <Text
            key={`${index}EW`}
            position-x={item.point.x}
            position-y={item.point.y}
            position-z={item.point.z}
            text={item.label}
            font={fonts.Roboto}
            fontSize='8'
            anchorX='center'
            anchorY='middle'
            color={labelColor}
            orientation='-z-x'
            fontScale={0.5}></Text>
        )
      })}
      {axisLabelsTVDX.map((item, index) => {
        return (
          <Text
            key={`${index}TVDZ`}
            position-x={item.point.x}
            position-y={item.point.y}
            position-z={item.point.z}
            text={item.label}
            font={fonts.Roboto}
            fontSize='8'
            anchorX='center'
            anchorY='middle'
            color={labelColor}
            orientation='+x+y'
            fontScale={0.5}></Text>
        )
      })}
      {axisLabelsTVDY.map((item, index) => {
        return (
          <Text
            key={`${index}TVDY`}
            position-x={item.point.x}
            position-y={item.point.y}
            position-z={item.point.z}
            text={item.label}
            font={fonts.Roboto}
            fontSize='8'
            anchorX='center'
            anchorY='middle'
            color={labelColor}
            orientation='+x+y'
            fontScale={0.5}></Text>
        )
      })}
      {axisLabelsTVDZ.map((item, index) => {
        return (
          <Text
            key={`${index}TVDX`}
            position-x={item.point.x}
            position-y={item.point.y}
            position-z={item.point.z}
            text={item.label}
            font={fonts.Roboto}
            fontSize='8'
            anchorX='center'
            anchorY='middle'
            color={labelColor}
            orientation='-z+y'
            fontScale={0.5}></Text>
        )
      })}
      <Text
        position-x={minMaxValues.xOffsets.offset}
        position-y={minMaxValues.minY}
        position-z={minMaxValues.zOffsets.offset + halfZ + 40}
        text={'North / South'}
        font={fonts.Roboto}
        fontSize='20'
        anchorX='center'
        anchorY='middle'
        orientation='+x-z'
        color={labelColor}></Text>
      <Text
        position-x={minMaxValues.xOffsets.offset + halfX + 40}
        position-y={minMaxValues.minY}
        position-z={minMaxValues.zOffsets.offset}
        text={'East / West'}
        font={fonts.Roboto}
        fontSize='20'
        anchorX='center'
        anchorY='middle'
        orientation='-z-x'
        color={labelColor}></Text>
    </React.Fragment>
  )
}

export default GridLines
