export const WELL_LIST_KEY = 'well-list-key'
export const WELL_DETAILS_KEY = 'well-details-key'
export const WELL_COMPONENTS_KEY = 'well-components-key'
export const DAILY_ACITIVITY_KEY = 'daily-activity-key'
export const DEPTH_TIME_KEY = 'depth-time-key'
export const INVENTORY_KEY = 'inventory-key'
export const SURVEY_KEY = 'survey-key'
export const SLIDE_SHEET_KEY = 'slide-sheet-key'
export const SLIDE_DETAILS_KEY = 'slide-details-key'
export const COSTS_HEADERS_KEY = 'costs-headers-key'
export const COSTS_KEY = 'costs-key'
export const DRILL_STRING_KEY = 'drill-string-key'
export const BHA_DETAILS_KEY = 'bha-details-key'
export const DRILLING_DASHBOARD_KEY = 'drilling-dashboard-key'
export const THREE_D_VIEW_KEY = 'three-d-view-key'
export const WELL_SHEET_SUMMARY_KEY = 'well-sheet-summary-key'
export const ANALYTICS_KEY = 'analytics-key'
export const LOGISTICS_KEY = 'logistics-key'
export const ADMIN_USERS_KEY = 'admin-users-key'
export const ADMIN_ROLES_KEY = 'admin-roles-key'
export const ADMIN_INNOVA_ORGS_KEY = 'admin-innova-orgs-key'
export const ADMIN_INNOVA_DATABASES_KEY = 'admin-innova-databases-key'
export const ADMIN_INNOVA_ICDS_KEY = 'admin-innova-icds-key'
export const ADMIN_INNOVA_DRILLLINK_KEY = 'admin-innova-drilllink-key'
export const ADMIN_WELLSEEKER_KEY = 'admin-wellseeker-key'
export const ANALYTICS_DEPTHVDAYS_KEY = 'analytics-depthvdays-key'
export const ANALYTICS_SLIDE_ROTATE_FOOTAGE_KEY = 'analytics-slide-rotate-footage-key'
export const ANALYTICS_SLIDE_ROTATE_ROP_KEY = 'analytics-slide-rotate-rop-key'
export const ANALYTICS_SLIDE_ROTATE_HOURS_KEY = 'analytics-slide-rotate-hours-key'
export const ANALYTICS_COST_PER_WELL_KEY = 'analytics-cost-per-well-key'
export const ANALYTICS_COST_PER_DAY_KEY = 'analytics-cost-per-day-key'
export const ANALYTICS_COST_PER_OPERATOR_KEY = 'analytics-cost-per-operator-key'
export const ANALYTICS_RIG_DAYS_KEY = 'analytics-rig-days-key'
export const EDR_PAGE_KEY = 'edr-page-key'
export const USER_PROFILE_PAGE_KEY = 'user-profile-page-key'
export const ERROR_MODELS_PAGE_KEY = 'error-models-page-key'
export const ERROR_TERMS_PAGE_KEY = 'error-terms-page-key'
export const TOOL_ORDERS_PAGE_KEY = 'tool-orders-page-key'
export const TOOL_ORDER_DETAILS_PAGE_KEY = 'tool-order-details-page-key'
export const DATA_ACQUISITION_PAGE_KEY = 'data-acquisition-page-key'
export const DATA_STORE_PAGE_KEY = 'data-store-page-key'
export const WITSML_SERVER_PAGE_KEY = 'witsml-server-page-key'

export const PAGE_KEYS = {
  wellListKey: WELL_LIST_KEY,
  wellDetailsKey: WELL_DETAILS_KEY,
  wellComponentsKey: WELL_COMPONENTS_KEY,
  dailyActivityKey: DAILY_ACITIVITY_KEY,
  depthTimeKey: DEPTH_TIME_KEY,
  inventoryKey: INVENTORY_KEY,
  surveyKey: SURVEY_KEY,
  slideSheetKey: SLIDE_SHEET_KEY,
  slideDetailsKey: SLIDE_DETAILS_KEY,
  costsHeadersKey: COSTS_HEADERS_KEY,
  costsKey: COSTS_KEY,
  drillStringKey: DRILL_STRING_KEY,
  bhaDetailsKey: BHA_DETAILS_KEY,
  drillingDashboardKey: DRILLING_DASHBOARD_KEY,
  threeDViewKey: THREE_D_VIEW_KEY,
  wellSheetSummaryKey: WELL_SHEET_SUMMARY_KEY,
  analyticsKey: ANALYTICS_KEY,
  logisticsKey: LOGISTICS_KEY,
  adminUsersKey: ADMIN_USERS_KEY,
  adminRolesKey: ADMIN_ROLES_KEY,
  adminInnovaOrgsKey: ADMIN_INNOVA_ORGS_KEY,
  adminInnovaDatabasesKey: ADMIN_INNOVA_DATABASES_KEY,
  adminInnovaIcdsKey: ADMIN_INNOVA_ICDS_KEY,
  adminInnovaDrillLinkKey: ADMIN_INNOVA_DRILLLINK_KEY,
  adminWellSeekerKey: ADMIN_WELLSEEKER_KEY,
  depthVDaysKey: ANALYTICS_DEPTHVDAYS_KEY,
  slideRotateFootageKey: ANALYTICS_SLIDE_ROTATE_FOOTAGE_KEY,
  slideRotateRopKey: ANALYTICS_SLIDE_ROTATE_ROP_KEY,
  slideRotateHoursKey: ANALYTICS_SLIDE_ROTATE_HOURS_KEY,
  costPerWellKey: ANALYTICS_COST_PER_WELL_KEY,
  costPerDayKey: ANALYTICS_COST_PER_DAY_KEY,
  costPerOperatorKey: ANALYTICS_COST_PER_OPERATOR_KEY,
  rigDaysKey: ANALYTICS_RIG_DAYS_KEY,
  edrPageKey: EDR_PAGE_KEY,
  userProfileKey: USER_PROFILE_PAGE_KEY,
  errorModelsKey: ERROR_MODELS_PAGE_KEY,
  errorTermsKey: ERROR_TERMS_PAGE_KEY,
  toolOrdersKey: TOOL_ORDERS_PAGE_KEY,
  toolOrderDetailsKey: TOOL_ORDER_DETAILS_PAGE_KEY,
  dataAcquisitionKey: DATA_ACQUISITION_PAGE_KEY,
  dataStoreKey: DATA_STORE_PAGE_KEY,
  witsmlServersKey: WITSML_SERVER_PAGE_KEY,
}
