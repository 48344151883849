export function getCompanyMarker(companyID) {
  if (companyID) {
    let companyIDlwr = companyID.toString().toLowerCase()
    if (companyIDlwr === 'aep') return require('../assets/CompanyMarkers/altitude.png')
    if (companyIDlwr === 'aim') return require('../assets/CompanyMarkers/aim.png')
    if (companyIDlwr === 'anadrill') return require('../assets/CompanyMarkers/anadrill.png')
    if (companyIDlwr === 'dtech') return require('../assets/CompanyMarkers/dtech.png')
    if (companyIDlwr === 'gasway') return require('../assets/CompanyMarkers/gasway.png')
    if (companyIDlwr === 'geoglide') return require('../assets/CompanyMarkers/geoglide.png')
    if (companyIDlwr === 'geoguidance') return require('../assets/CompanyMarkers/geoguidance.png')
    if (companyIDlwr === 'innova') return require('../assets/CompanyMarkers/innova.png')
    if (companyIDlwr === 'intrepid') return require('../assets/CompanyMarkers/intrepid.png')
    if (companyIDlwr === 'legacy') return require('../assets/CompanyMarkers/legacy.png')
    if (companyIDlwr === 'liberty') return require('../assets/CompanyMarkers/liberty.png')
    if (companyIDlwr === 'msenergy') return require('../assets/CompanyMarkers/msdirectional.png')
    if (companyIDlwr === 'nabors') return require('../assets/CompanyMarkers/naborsdir.png')
    if (companyIDlwr === 'oilserv') return require('../assets/CompanyMarkers/oilserv.png')
    if (companyIDlwr === 'phoenix') return require('../assets/CompanyMarkers/phoenix.png')
    if (companyIDlwr === 'premier') return require('../assets/CompanyMarkers/premier.png')
    if (companyIDlwr === 'prodirectional') return require('../assets/CompanyMarkers/prodirectional.png')
    if (companyIDlwr === 'qes') return require('../assets/CompanyMarkers/klx.png')
    if (companyIDlwr === 'klxenergy') return require('../assets/CompanyMarkers/klx.png')
    if (companyIDlwr === 'rpmconsulting') return require('../assets/CompanyMarkers/rpm.png')
    if (companyIDlwr === 'sdc') return require('../assets/CompanyMarkers/sdc.png')
    if (companyIDlwr === 'stryker') return require('../assets/CompanyMarkers/stryker.png')
    if (companyIDlwr === 'truedirectional') return require('../assets/CompanyMarkers/truedirectional.png')
    if (companyIDlwr === 'wellbenders') return require('../assets/CompanyMarkers/wellbenders.png')
    if (companyIDlwr === 'ull') return require('../assets/CompanyMarkers/ull.png')
    if (companyIDlwr === 'rgu') return require('../assets/CompanyMarkers/rgu.png')
    if (companyIDlwr === 'heriotwatt') return require('../assets/CompanyMarkers/heriotwatt.png')
    if (companyIDlwr === 'standarddir') return require('../assets/CompanyMarkers/standarddir.png')
    if (companyIDlwr === 'precision') return require('../assets/CompanyMarkers/precisiondrilling.png')
    if (companyIDlwr === 'newtech') return require('../assets/CompanyMarkers/newtech.png')
    if (companyIDlwr === 'micon') return require('../assets/CompanyMarkers/micon.png')
    if (companyIDlwr === 'scout') return require('../assets/CompanyMarkers/scout.png')
    if (companyIDlwr === 'ameredev') return require('../assets/CompanyMarkers/ameredev.png')
    if (companyIDlwr === 'johnmphilips') return require('../assets/CompanyMarkers/johnmphilips.png')
    if (companyIDlwr === 'discovery') return require('../assets/CompanyMarkers/discovery.png')
    if (companyIDlwr === 'patriot') return require('../assets/CompanyMarkers/patriot.png')
    if (companyIDlwr === 'prime') return require('../assets/CompanyMarkers/prime.png')
    if (companyIDlwr === 'capital') return require('../assets/CompanyMarkers/capital.png')
    if (companyIDlwr === 'leam') return require('../assets/CompanyMarkers/leam.png')
    if (companyIDlwr.includes('engineering') === true) return require('../assets/CompanyMarkers/innovaengineering.png')
  }

  return require('../assets/CompanyMarkers/innova.png')
}
