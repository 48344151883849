import React from 'react'
import PropTypes from 'prop-types'

import Dialog from '@material-ui/core/Dialog'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { makeStyles } from '@material-ui/styles'
import { appColors } from 'utils'
import InputForm from './InputForm'
import { DialogContent, DialogTitle, DialogActions, Button } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '600px',
    backgroundColor: appColors.modalBackColor,
  },
  dialogContent: {
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#136cb9',
      outline: '1px solid #1679d0',
    },
  },
}))

const InputModal = ({ open, onClose, title, formData, submitForm, cancelFunction, validationSchema }) => {
  const classes = useStyles()

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <InputForm
            formId='inputForm'
            inputData={formData}
            submitForm={submitForm}
            validationSchema={validationSchema}
          />
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={() => cancelFunction()} color='secondary'>
            Cancel
          </Button>
          <Button variant='contained' color='primary' type='submit' form='inputForm'>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </MuiPickersUtilsProvider>
  )
}

InputModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  formData: PropTypes.array.isRequired,
  submitForm: PropTypes.func.isRequired,
  cancelFunction: PropTypes.func.isRequired,
  validationSchema: PropTypes.object,
}

export default InputModal
