import React, { useContext, useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import DashboardContext from '../dashboardContext'
import { flashDiv } from '../../common/divUtils'

const HIGHLIGHT_TEXT_COLOR = 'rgba(255,255,0,1.0)'
const MESSAGE_BODY_TEXT_COLOR = 'rgba(52,201,235,1.0)'

const useStyles = makeStyles((theme) => ({
  widgetMain: {
    display: 'grid',
    margin: '20px 0 20px 0',
    height: 'auto',
  },
  widgetCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    backgroundColor: 'rgba(32,32,32,1.0)',
    border: '1px solid black',
    boxShadow: '0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);',
    padding: '8px',
  },
  messagehdr: {
    color: 'rgba(192,192,192,1.0)',
  },
  messagebody: {
    color: MESSAGE_BODY_TEXT_COLOR,
    fontSize: '1.25em',
    fontWeight: 'bold',
  },
}))

const RemoteMessage = (props) => {
  const classes = useStyles()
  const { data: rtData } = useContext(DashboardContext)
  const [lastMessage, setLastMessage] = useState('')

  useEffect(() => {
    if (rtData) {
      if (lastMessage !== rtData.message) {
        setLastMessage(rtData.message)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rtData])

  useEffect(() => {
    flashDiv('remoteMessageBody', HIGHLIGHT_TEXT_COLOR, MESSAGE_BODY_TEXT_COLOR)
  }, [lastMessage])

  if (!rtData || !rtData.message || rtData.message?.length < 2) return null

  return (
    <div className={classes.widgetMain}>
      <div className={classes.widgetCard}>
        <div className={classes.messagehdr}>Remote Message</div>
        <div
          id='remoteMessageBody'
          className={classes.messagebody}
          dangerouslySetInnerHTML={{ __html: rtData?.message }}></div>
      </div>
    </div>
  )
}

export default RemoteMessage
