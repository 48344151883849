import { atom } from 'recoil'

export const defaultToolOrder = {
  toolOrderId: -1,
  jobNumber: '',
  rig: '',
}

export const currentToolOrderAtom = atom({
  key: 'currentToolOrderAtomKey',
  default: defaultToolOrder,
})

export const wellListAtom = atom({
  key: 'wellListAtomKey',
  default: [],
})

export const currentWellAtom = atom({
  key: 'currentWellAtomKey',
  default: '',
})

export const defaultWellDetails = {
  actualWell: '',
  wellStatus: '',
  operator: '',
  rig: '',
  jobNum: '',
  lat: 29.7604,
  lng: -95.3698,
}
export const currentWellDetailsAtom = atom({
  key: 'currentWellDetailsAtomKey',
  default: defaultWellDetails,
})

export const currentWellFieldCostAtom = atom({
  key: 'currentWellFieldCostKey',
  default: {
    date: null,
    dailyReportId: null,
  },
})

export const currentErrorModelAtom = atom({
  key: 'currentErrorModelKey',
  default: {
    name: null,
    toolId: null,
  },
})

export const currentBhaAtom = atom({
  key: 'currentBhaKey',
  default: '',
})

export const userPrefsAtom = atom({
  key: 'userPrefsAtomKey',
  default: '',
})

export const userUserRoleAtom = atom({
  key: 'userRoleAtomKey',
  default: {},
})

export const actionBarWidthAtom = atom({
  key: 'actionBarWidthAtomKey',
  default: 160,
})

export const currentPageAtom = atom({
  key: 'currentPageAtomKey',
  default: '',
})

export const analyticsSearchParamsAtom = atom({
  key: 'analyticsSearchParamsAtomKey',
  default: {
    operator: '',
    state: '',
    county: '',
    rig: '',
    wellStatus: '',
    jobNum: '',
    directionalCompany: '',
    formation: '',
    holeSize: '',
    isLih: '',
    phase: '',
    dateFrom: '',
    dateTo: '',
  },
})

export const analyticsPhaseFilterAtom = atom({
  key: 'analyticsPhaseFilterAtomKey',
  default: '',
})

export const analyticsFlatTimeAtom = atom({
  key: 'analyticsFlatTimeAtomKey',
  default: false,
})

export const analyticsSelectedWells = atom({
  key: 'analyticsSelectedWellsKey',
  default: [],
})

export const edrDataQueryAtom = atom({
  key: 'edrDataQueryKey',
  default: [],
})

export const edrSchematicDataAtom = atom({
  key: 'edrWellSchematicDataKey',
  default: [],
})

export const curWitsAtom = atom({
  key: 'curWitsAtomKey',
  default: {},
})

export const allWellsAtom = atom({
  key: 'allWellsActomKey',
  default: false,
})

export const show3DControlsAtom = atom({
  key: 'threeDeeSettingsAtomKey',
  default: false,
})
