import React, { useEffect, useRef } from 'react'

import { List, ListSubheader } from '@material-ui/core'

import { makeStyles } from '@material-ui/styles'
import { appColors } from 'utils'

const useListStyles = makeStyles((theme) => ({
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.itemBackground,
    overflow: 'auto',
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
    paddingRight: '4px',
    paddingLeft: '4px',
    listStyleType: 'none !important',
  },
  headerText: {
    fontSize: 16,
    fontWeight: '800',
    color: appColors.borderColor,
    backgroundColor: appColors.itemTextColor,
    padding: '0px 8px 0px 8px',
    borderBottom: '1px solid',
  },
}))

const SectionPickList = ({ sectionList, selItems, setSelItems, renderItem }) => {
  const _isMounted = useRef(false)
  const classes = useListStyles()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // const onItemSelect = (event, id) => {
  //   let newSelectedItems = [...selItems]

  //   if (getChecked(id) === false) {
  //     newSelectedItems.push(id)
  //     if (_isMounted.current === true) setSelItems(newSelectedItems)
  //   } else {
  //     if (Array.isArray(newSelectedItems) === true) {
  //       const index = newSelectedItems.indexOf(id)
  //       if (index > -1) newSelectedItems.splice(index, 1)
  //     } else {
  //       newSelectedItems = []
  //     }

  //     if (_isMounted.current === true) setSelItems(newSelectedItems)
  //   }
  // }

  // const getChecked = (id) => {
  //   if (!selItems) return false
  //   if (Array.isArray(selItems) === false) return false
  //   if (selItems.includes(id) === true) return true

  //   return false
  // }

  // code before using renderItem
  /*
                {section?.data?.map((item) => (
                  <ListItem key={item.id} button>
                    <ListItemText id={labelId} primary={`${item.desc}`} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge='end'
                        onChange={(event) => onItemSelect(event, item.id)}
                        checked={getChecked(item.id)}
                        inputProps={{ 'aria-labelledby': labelId }}
                        checkedIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'lime' }} />}
                        indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                        icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
*/
  return (
    <div className={classes.sectionContainer}>
      <List dense className={classes.root} subheader={<li />}>
        {sectionList?.map((section, index) => {
          // const labelId = `checkbox-list-secondary-label-${section.header}-${index}`
          return (
            <li key={`section-${section.header}-${index}`} className={classes.listSection}>
              <ul className={classes.ul}>
                <ListSubheader classes={{ root: classes.headerText }}>{section.header}</ListSubheader>
                {section?.data?.map((item) => (
                  renderItem(item)
                ))}
              </ul>
            </li>
          )
        })}
      </List>
    </div>
  )
}

export default SectionPickList