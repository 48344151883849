import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import * as XLSX from 'xlsx'

import AnalyticsPage from '.'
import AnalyticsCard from './AnalyticsCard'
import BarChart from './BarChartCjs'
import { appColors, array2pipestr, getXlsxColumnLetter } from 'utils'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import useAxiosWithUnitFetch from 'components/common/hooks/useAxiosWithUnitFetch'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import { analyticsPhaseFilterAtom, analyticsSearchParamsAtom, analyticsSelectedWells } from 'atoms'

const SlideRotateFootagePage = () => {
  const _isMounted = useRef(false)

  const [data, setData] = useState([])
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const phaseFilter = useRecoilValue(analyticsPhaseFilterAtom)
  const userUnits = useUnits(UNITS_FOR.Depth)
  const searchParams = useRecoilValue(analyticsSearchParamsAtom)
  const { newCancelToken: kpiCancelToken, cancelPreviousRequest: kpiCancelRequest } = useAxiosCancelToken()
  const [{ error: kpiError }, getKpiData] = useAxiosWithUnitFetch({
    url: '/kpi/getKpis',
    cancelToken: kpiCancelToken(),
    manual: true,
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
      kpiCancelRequest()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (kpiError !== undefined && kpiError !== null) {
      if (kpiCancelRequest(kpiError)) return
    }
  }, [kpiError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      //getChartData()
      getChartData()
    }
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      // console.log('phaseFilter: ', phaseFilter)
      getChartData()
    }
  }, [phaseFilter]) // eslint-disable-line react-hooks/exhaustive-deps

  const createChartData = (data) => {
    let output = {
      labels: [],
      datasets: [
        {
          label: 'Slide',
          backgroundColor: appColors.slideColor,
          categoryPercentage: 0.5,
          maxBarThickness: 24,
          data: [],
        },
        {
          label: 'Rotate',
          backgroundColor: appColors.rotateColor,
          categoryPercentage: 0.5,
          maxBarThickness: 24,
          data: [],
        },
      ],
    }

    if (!data || !data.footage) {
      return output
    }

    for (let i = 0; i < data.footage.length; i++) {
      output.labels.push(data.footage[i].actualWell)
      output.datasets[0].data.push(parseInt(data.footage[i].slideFootage))
      output.datasets[1].data.push(parseInt(data.footage[i].rotateFootage))
    }

    return output
  }

  const getChartData = async () => {
    if (selectedWells.length < 1) {
      await setData([])
      return
    }

    let payload = {
      wellList: array2pipestr(selectedWells),
      footage: 'true',
    }
    let { dateFrom, dateTo } = searchParams
    if (dateFrom === '' || dateTo === '') {
      dateTo = new Date(Date.now()).toISOString()
      dateFrom = new Date(Date.now() - 1200 * 24 * 60 * 60 * 1000).toISOString()
    }
    payload.dateTo = dateTo
    payload.dateFrom = dateFrom

    // add phaseFilter to the payload if it's not empty or All
    if (phaseFilter !== 'All' && phaseFilter !== '' && phaseFilter !== '0') {
      payload.phase = phaseFilter
    } else {
      payload.phase = ''
    }
    const dataResponse = await getKpiData(payload)
    if (dataResponse?.data) {
      setData(dataResponse.data)
    } else {
      setData([])
    }
  }

  const onXlsxExport = () => {
    // console.log('export raw ', data)
    let colIndex = 1
    let totalDrilled = 0.0
    let totalSlide = 0.0
    let totalRotate = 0.0
    let slidePct = 0.0
    let rotatePct = 0.0

    let ws = XLSX.utils.aoa_to_sheet(
      [
        [`Slide Footage (${userUnits})`],
        [`Rotate Footage (${userUnits})`],
        [`Total Footage (${userUnits})`],
        [`Rotate ROP (${userUnits}/hr)`],
        [`Slide ROP (${userUnits}/hr)`],
        [`ROP (${userUnits}/hr)`],
        [`Phase ROP (${userUnits}/hr)`],
        [`# BHA`],
      ],
      {
        origin: 'A2',
      },
    )

    XLSX.utils.sheet_add_aoa(
      ws,
      [
        [`Total Drilled (${userUnits})`],
        [`Total Slide (${userUnits})`],
        [`Total Rotate (${userUnits})`],
        ['Slide %'],
        ['Rotate %'],
        [''],
        ['Phase'],
      ],
      { origin: 'A11' },
    )
    if (data && data.footage) {
      data.footage.forEach((well) => {
        let phaseRop = 0
        let phaseHours = parseFloat(well.rotateHours) + parseFloat(well.slideHours)
        let phaseFootage = parseFloat(well.rotateFootage) + parseFloat(well.slideFootage)
        if (phaseHours > 0.01 && phaseFilter !== 'All' && phaseFilter !== '' && phaseFilter !== '0') {
          phaseRop = phaseFootage / phaseHours
        }
        XLSX.utils.sheet_add_aoa(
          ws,
          [
            [`${well.rig} - ${well.actualWell}`],
            [parseFloat(well.slideFootage)],
            [parseFloat(well.rotateFootage)],
            [parseFloat(well.totalFootage)],
            [parseFloat(well.rotateRop)],
            [parseFloat(well.slideRop)],
            [parseFloat(well.avgRop)],
            [+phaseRop.toFixed(2)],
          ],
          { origin: `${getXlsxColumnLetter(colIndex)}1` },
        )

        totalSlide += parseFloat(well.slideFootage)
        totalRotate += parseFloat(well.rotateFootage)
        colIndex += 1
      })
      totalDrilled = totalSlide + totalRotate
      if (totalDrilled > 0.01) {
        slidePct = totalSlide / totalDrilled
        rotatePct = totalRotate / totalDrilled
      }

      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [+totalDrilled.toFixed(2)],
          [+totalSlide.toFixed(2)],
          [+totalRotate.toFixed(2)],
          [+slidePct.toFixed(2)],
          [+rotatePct.toFixed(2)],
        ],
        { origin: 'B11' },
      )
    }
    if (phaseFilter !== 'All' && phaseFilter !== '' && phaseFilter !== '0') {
      XLSX.utils.sheet_add_aoa(ws, [[phaseFilter]], { origin: 'B17' })
    } else {
      XLSX.utils.sheet_add_aoa(ws, [['Well']], { origin: 'B17' })
    }

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Slide Rotate Footage-Well')
    XLSX.writeFile(wb, 'Footage and ROP.xlsx')
  }

  return (
    <AnalyticsPage>
      <AnalyticsCard exportXlsxCB={onXlsxExport}>
        <BarChart
          wellData={createChartData(data)}
          units={userUnits}
          title='Slide / Rotate Footage'
          xAxisTitle='Wells'
          yAxisTitle={`Footage (${userUnits})`}
        />
      </AnalyticsCard>
    </AnalyticsPage>
  )
}

export default SlideRotateFootagePage
