import React from 'react';

import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'

import { useAuth0 } from '@auth0/auth0-react'

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.subtleBackground,
    padding: theme.spacing(4),
    minWidth: theme.spacing(30),
    maxWidth: theme.spacing(100),
  },
  container: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
}))

const LandingPage = () => {
  const classes = useStyles()
  const { loginWithRedirect } = useAuth0()

  const login = () =>
    loginWithRedirect({
      appState: { targetUrl: `/well-list` },
    })

  return (
    <Container className={classes.container}>
      <Card className={classes.card}>
        <CardContent>
          <h1>Welcome to Innova Drilling's</h1>
          <h2>Web Portal</h2>
          <p></p>
          <h4>please login to continue...</h4>
          <Button variant="contained" color="primary" onClick={login}>Login</Button>
        </CardContent>
      </Card>
    </Container>
  )
}

export default LandingPage;
