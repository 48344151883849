import React, { useEffect, useRef, useState } from 'react'

import PrintIcon from '@material-ui/icons/Print'
import { makeStyles } from '@material-ui/styles'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { currentErrorModelAtom, userUserRoleAtom } from 'atoms'
import { IconButton, Tooltip, Snackbar, ListItem } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import AddIcon from '@material-ui/icons/Add'
import RefreshIcon from '@material-ui/icons/Refresh'
import { Icon as Iconify } from '@iconify/react'
import ConfirmDialog from 'components/common/ConfirmDialog'
import InputModal from 'components/common/InputModal'
import SearchBar from 'material-ui-search-bar'
import { filter as _filter } from 'lodash'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { searchFunction } from '../common/searchFunctions'
import { checkPermission } from '../userPermissions'

import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import { appColors, isoStringToDateString } from 'utils'
import * as XLSX from 'xlsx'
import * as yup from 'yup'
import MenuButton from 'components/common/MenuButton'

const useStyles = makeStyles((theme) => ({
  listContainer: {
    display: 'flex',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(calc(100vh - 100px) * 1.00)',
    maxHeight: 'calc(calc(100vh - 210px) * 1.00)',
    borderRadius: '4px',
    backgroundColor: theme.palette.itemBackground,
    width: '100%',
  },
  headerText: {
    fontSize: 18,
    fontWeight: '500',
    color: appColors.headerTextColor,
    textAlign: 'right',
    paddingRight: '8px',
    alignItems: 'center',
  },
  itemText: {
    fontSize: 18,
    color: appColors.itemTextColor,
    border: '',
    alignItems: 'center',
  },
  deleteIconStyle: {
    color: '#C00000',
    fontSize: '24px',
  },
  navigateIconStyle: {
    color: appColors.headerTextColor,
    fontSize: '24px',
  },
  fixedListContainer: {
    display: 'flex',
    borderRadius: '4px',
    backgroundColor: theme.palette.itemBackground,
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#136cb9', //'darkgrey', //'rgba(0,0,0,.1)',
      outline: '1px solid #1679d0',
    },
    overflow: 'auto',
  },
  itemMainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '4px',
    borderRadius: 5,
    marginLeft: '8px',
    background: 'linear-gradient(to bottom, #2d2d2d 0%, #1c1c1c 100%)',
    marginBottom: '4px',
    width: 'calc(100% - 10px)', // 8px for marginLeft, 2px for left & right border
    border: `1px solid`,
    borderColor: appColors.itemBackColor,
    backgroundColor: appColors.itemBackColor,
    '&:hover': {
      borderColor: appColors.accentColor,
    },
  },
  itemTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'left',
    flex: 1,
  },
  itemTextWrapContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
  },
  itemTextWrapContainerWide: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1.5,
  },
  itemIconContainer: {
    width: 100,
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltip: {
    backgroundColor: 'rgb(19,62,96)',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
  menuIcon: {
    backgroundColor: 'transparent',
    margin: '4px',
    padding: '12px',
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2) - 20,
  },
  searchContainer: {
    backgroundColor: appColors.itemBackColor,
    padding: '8px',
  },
}))

const ErrorModelsList = ({ history }) => {
  const _isMounted = useRef(false)
  const classes = useStyles()
  const [searchText, setSearchText] = useState('')
  const setCurrentErrorModel = useSetRecoilState(currentErrorModelAtom)
  const editIndex = useRef(-1)
  const [filteredList, setFilteredList] = useState([])
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [showEditor, setShowEditor] = useState(false)
  const listRef = useRef()
  const userRole = useRecoilValue(userUserRoleAtom)

  const { newCancelToken: newErrorModelCancelToken, cancelPreviousRequest: cancelPreviousErrorModelRequest } =
    useAxiosCancelToken()
  const [{ data: errorModels, error: errModelsError }, getErrorModels] = useAxios({
    url: '/errorModels/getErrorModelHeaders',
    cancelToken: newErrorModelCancelToken(),
  })

  const { newCancelToken: newErrorModelAllCancelToken, cancelPreviousRequest: cancelPreviousErrorModelAllRequest } =
    useAxiosCancelToken()
  const [{ data: errorModelsAll, error: errModelsAllError }, getErrorModelsAll] = useAxios({
    url: '/errorModels/getErrorModels',
    cancelToken: newErrorModelAllCancelToken(),
  })

  const { newCancelToken: newDeleteCancelToken, cancelPreviousRequest: cancelPreviousDeleteRequest } =
    useAxiosCancelToken()
  const [{ error: deleteError }, deleteErrorModel] = useAxios({
    url: '/errorModels/deleteErrorModel',
    manual: true,
    cancelToken: newDeleteCancelToken(),
  })

  const { newCancelToken: createCancelToken, cancelPreviousRequest: cancelCreateReq } = useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorCreate }, addErrorModel] = useAxios({
    url: '/errorModels/addErrorModelHeader',
    manual: true,
    cancelToken: createCancelToken(),
  })

  const { newCancelToken: updateCancelToken, cancelPreviousRequest: cancelUpdateReq } = useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorUpdate }, updateErrorModel] = useAxios({
    url: '/errorModels/updateErrorModelHeader',
    manual: true,
    cancelToken: updateCancelToken(),
  })

  useEffect(() => {
    if (errorModels) {
      const filtered = handleSearch(searchText)
      setFilteredList(filtered)
    }
  }, [errorModels]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      const filtered = handleSearch(searchText)
      setFilteredList(filtered)
    }
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (errModelsAllError !== undefined && errModelsAllError !== null) {
      if (cancelPreviousErrorModelAllRequest(errModelsAllError)) return
    }
  }, [errModelsAllError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errModelsError !== undefined && errModelsError !== null) {
      if (cancelPreviousErrorModelRequest(errModelsError)) return
    }
  }, [errModelsError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleteError !== undefined && deleteError !== null) {
      if (cancelPreviousDeleteRequest(deleteError)) return
    }
  }, [deleteError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorUpdate !== undefined && errorUpdate !== null) {
      if (cancelUpdateReq(errorUpdate)) return
    }
  }, [errorUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorCreate !== undefined && errorCreate !== null) {
      if (cancelCreateReq(errorCreate)) return
    }
  }, [errorCreate]) // eslint-disable-line react-hooks/exhaustive-deps

  const showTerms = (index) => {
    setCurrentErrorModel({
      name: filteredList[index].toolName,
      toolId: filteredList[index].toolId,
    })
    history.push('/errorTerms')
  }

  const renderTextField = (text, value) => {
    return (
      <div className={classes.itemTextContainer}>
        <div className={classes.headerText}>{text} </div>
        <div className={classes.itemText}>{value}</div>
      </div>
    )
  }

  const onClickDelete = (errModel) => {
    setConfirm({
      show: true,
      title: 'Delete error model',
      text: `Are you sure you want to delete ${errModel?.toolName}?`,
      itemIndex: parseInt(errModel?.toolId),
    })
  }

  const confirmDelete = async () => {
    if (confirm?.itemIndex >= 0) {
      const deleteResponse = await deleteErrorModel({
        toolId: confirm?.itemIndex,
      })

      if (!deleteResponse.error) {
        setStatus({ show: true, severity: 'success', message: 'Error model deleted successfully' })
        await getErrorModels()
        getErrorModelsAll()
      } else {
        setStatus({ show: true, severity: 'error', message: 'Delete error model failed' })
      }
    }
  }

  const handleNewErrorModel = () => {
    setShowEditor(true)
  }

  const handleCloseStatus = (event, reason) => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const handleClose = () => {
    setShowEditor(false)
  }

  const renderErrorModel = (props) => {
    const { index, style } = props
    const item = filteredList[index]
    return (
      <ListItem style={style} disableGutters={true}>
        <div className={classes.itemMainContainer}>
          <div
            className={classes.itemTextContainer}
            onClick={() => {
              editIndex.current = index
              setShowEditor(true)
            }}>
            <div className={classes.itemTextWrapContainer}>{renderTextField('Tool Name: ', item.fileName)}</div>
            <div className={classes.itemTextWrapContainer}>{renderTextField('Modified: ', item.uploadedBy)}</div>
            <div className={classes.itemTextWrapContainer}>
              {renderTextField(`Date: `, isoStringToDateString(item.uploadedDate))}
            </div>
          </div>
          {checkPermission('canDelete', userRole.roleAttributes?.permissions) ? (
            <Tooltip title='Delete error model' placement='bottom' classes={{ tooltip: classes.tooltip }}>
              <IconButton className={classes.itemIconContainer} onClick={() => onClickDelete(item)}>
                <Iconify icon='fa-regular:trash-alt' className={classes.deleteIconStyle} />
              </IconButton>
            </Tooltip>
          ) : null}
          <Tooltip title='Edit tool terms' placement='bottom' classes={{ tooltip: classes.tooltip }}>
            <IconButton className={classes.itemIconContainer} onClick={() => showTerms(index)}>
              <Iconify icon='akar-icons:chevron-right' className={classes.navigateIconStyle} />
            </IconButton>
          </Tooltip>
        </div>
      </ListItem>
    )
  }

  const RenderCreateUpdateModal = ({ data, submitFunction, cancelFunction }) => {
    let useInputData = true
    if (!data) useInputData = false
    if (useInputData === true) {
      if (data.hasOwnProperty('toolId') === false) useInputData = false
    }

    const formValidationSchema = yup.object({
      toolName: yup.string().required(),
    })

    let formData = [
      {
        tag: 'toolId',
        value: useInputData === true ? data.toolId : '',
        inputType: '-',
      },
      {
        tag: 'toolName',
        value: useInputData === true ? data.toolName : '',
        text: 'Tool Name',
        inputType: 'text',
        icon: 'text',
        iconFamily: 'Entypo',
      },
    ]

    const title = (useInputData ? 'Edit' : 'Add') + ' error model'

    return (
      <InputModal
        open={showEditor}
        onClose={cancelFunction}
        title={title}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={cancelFunction}
        validationSchema={formValidationSchema}
      />
    )
  }

  const submitFunction = async (data, formikActions) => {
    setShowEditor(false)
    if (!data) return false
    let isAdd = false
    if (editIndex.current < 0) {
      isAdd = true
    }
    let payload = { ...data }

    let saveResponse
    if (isAdd) {
      saveResponse = await addErrorModel(payload, createCancelToken())
    } else {
      saveResponse = await updateErrorModel(payload, updateCancelToken())
    }

    if (saveResponse.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: isAdd ? 'Error model failed to add' : 'Error model update failed',
      })
      formikActions?.setSubmitting(false)
      return false
    } else {
      setStatus({ show: true, severity: 'success', message: isAdd ? 'Error model added' : 'Error model updated' })
    }

    await getErrorModels()
    getErrorModelsAll()
    return true
  }

  const onXlsxExport = () => {
    if (!errorModelsAll) return
    if (!Array.isArray(errorModelsAll)) return
    if (errorModelsAll.length === 0) return

    let headers = []
    headers.push('#')
    headers.push('Tool Name')
    headers.push('Uploaded By')
    headers.push('Uploaded Date')
    headers.push('Term Name')
    headers.push('Vector')
    headers.push('Tie On')
    headers.push('Magnitude')
    headers.push('Units')
    headers.push('Formula')
    headers.push('Min Inc')
    headers.push('Max Inc')

    let ws = XLSX.utils.aoa_to_sheet([headers], {
      origin: 'A1',
    })

    let xlExport = []
    for (let i = 0; i < errorModelsAll.length; i++) {
      if (!errorModelsAll[i].hasOwnProperty('toolTerms')) continue
      if (!Array.isArray(errorModelsAll[i].toolTerms)) continue
      for (let j = 0; j < errorModelsAll[i].toolTerms.length; j++) {
        let newRow = []
        newRow.push(i + 1)
        newRow.push(errorModelsAll[i].toolName)
        newRow.push(errorModelsAll[i].uploadedBy)
        newRow.push(errorModelsAll[i].uploadedDate)
        newRow.push(errorModelsAll[i].toolTerms[j].termName)
        newRow.push(errorModelsAll[i].toolTerms[j].vector)
        newRow.push(errorModelsAll[i].toolTerms[j].tieOn)
        newRow.push(errorModelsAll[i].toolTerms[j].termValue)
        newRow.push(errorModelsAll[i].toolTerms[j].unit)
        newRow.push(errorModelsAll[i].toolTerms[j].formula)
        newRow.push(errorModelsAll[i].toolTerms[j].minInc)
        newRow.push(errorModelsAll[i].toolTerms[j].maxInc)
        xlExport.push(newRow)
      }
    }

    XLSX.utils.sheet_add_aoa(ws, xlExport, { origin: 'A2' })

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Error Models')

    XLSX.writeFile(wb, 'ErrorModels.xlsx')
  }

  const handleSearch = (text) => {
    if (text && typeof text === 'string' && text !== '') {
      const filterList = _filter(errorModels, (item) => {
        return searchFunction(item, text, ['toolName', 'uploadedBy'])
      })
      return filterList
    } else {
      return errorModels
    }
  }

  return (
    <React.Fragment>
      {showEditor ? (
        <RenderCreateUpdateModal
          data={editIndex.current >= 0 ? filteredList[editIndex.current] : {}}
          submitFunction={submitFunction}
          cancelFunction={handleClose}
        />
      ) : null}
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => confirmDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      <div className={classes.searchContainer}>
        <SearchBar
          value={searchText}
          onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
          onCancelSearch={() => setSearchText('')}
          style={{
            padding: '1px',
            margin: '1px',
            backgroundColor: 'rgb(17, 17, 17)',
          }}
        />
      </div>
      <div className={classes.listContainer} ref={listRef}>
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              className={classes.fixedListContainer}
              height={height}
              width={width}
              itemCount={!Array.isArray(filteredList) ? 0 : filteredList.length}
              itemSize={60}>
              {renderErrorModel}
            </FixedSizeList>
          )}
        </AutoSizer>
      </div>
      <div className={classes.menuIcon}>
        <MenuButton
          actions={[
            {
              icon: <AddIcon />,
              name: 'Add',
              onClick: () => {
                editIndex.current = -1
                handleNewErrorModel(true)
              },
            },
            { icon: <RefreshIcon />, name: 'Refresh', getErrorModels },
            { icon: <PrintIcon />, name: 'Print', onClick: onXlsxExport },
          ]}
        />
      </div>
      {status?.show ? (
        <Snackbar open={status?.show} autoHideDuration={2000} onClose={handleCloseStatus}>
          <MuiAlert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </MuiAlert>
        </Snackbar>
      ) : null}
    </React.Fragment>
  )
}

export default ErrorModelsList
