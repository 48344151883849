import React, { useEffect, useState } from 'react'

import Page from 'components/common/Page'

import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useInterval from 'components/common/hooks/useInterval'
import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import DashboardContext from './dashboardContext'
import SteeringStatus from './components/SteeringStatus'
import RemoteMessage from './components/RemoteMessage'
import ToolfaceControl from './components/ToolfaceControl'
import DashBitData from './components/DashBitData'
import DashSurveyData from './components/DashSurveyData'
import DashWitsData from './components/DashWitsData'
import Directive from './components/Directive'
import ToolfaceStatus from './components/ToolfaceStatus'
import { useRecoilValue } from 'recoil'
import { currentWellAtom } from 'atoms'

const useStyles = makeStyles((theme) => ({
  divbody: {
    minHeight: '100vh',
    background: '#2d2d2d',
  },
  selectLoading: {
    width: theme.spacing(32),
    height: theme.spacing(6),
    background: 'rgba(32,32,32,1.0)',
  },
  wellLabel: {
    color: 'rgba(192,192,192,1.0)',
    marginRight: '0.5em',
  },
  aspect: {
    display: 'flex',
    justifyContent: 'center',
    '&:before': {
      content: '',
      display: 'block',
      height: '0',
      width: '0',
      paddingBottom: 'percentage(1/2)',
    },
  },
  wrap: {
    maxWidth: '100%',
    margin: '0 auto',
    '&>*+*': { marginTop: '1em' },
  },
}))

const DrillingDashboard = () => {
  const classes = useStyles()
  const currentWell = useRecoilValue(currentWellAtom)

  const [dbData, setDBData] = useState({})
  const [delay, setDelay] = useState(5000)
  const [isRunning, setRunning] = useState(true)
  const { newCancelToken, isCancel } = useAxiosCancelToken()
  const [{ data, loading, error }, axiosRefetch] = useAxios({
    url: '/well/curwits',
    data: {
      wellName: currentWell,
    },
    cancelToken: newCancelToken(),
  })

  useEffect(() => {
    if (error !== undefined && error !== null) {
      if (isCancel(error)) return
    }
  }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDBData({ data: data, loading: loading })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isRunning) {
      setDelay(5000)
    } else {
      setDelay(null)
    }
  }, [isRunning])

  const getDDBData = () => {
    axiosRefetch()
    setDBData({ data: data, loading: loading })
    // console.log('dbData', dbData)
  }

  useInterval(() => {
    getDDBData()
  }, delay)

  const onPauseResume = () => {
    setRunning(!isRunning)
  }

  return (
    <DashboardContext.Provider value={dbData}>
      <Page>
        <div className={classes.divbody}>
          <SteeringStatus />
          <Directive />
          <div className={classes.wrap}>
            <ToolfaceControl className={classes.aspect} />
          </div>
          <ToolfaceStatus />
          <RemoteMessage />
          <DashBitData />
          <DashSurveyData />
          <DashWitsData />
          <Button variant='contained' color='primary' onClick={onPauseResume} style={{ margin: '20px' }}>
            {isRunning ? 'Pause' : 'Resume'}
          </Button>
        </div>
      </Page>
    </DashboardContext.Provider>
  )
}

export default DrillingDashboard
