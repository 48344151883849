import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useRecoilValue } from 'recoil'

import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import MenuIcon from '@material-ui/icons/Menu'
import Tooltip from '@material-ui/core/Tooltip'
import { Icon as Iconify } from '@iconify/react'
import UserProfileCard from './UserProfileCard'
import MapPrefs from './MapPrefs'
import NovDefaultIcon from 'assets/wellScreenIcons/novGrey.png'
import { checkFeature } from '../userPermissions'

import { userUserRoleAtom } from 'atoms'
import OilPriceCard from './OilPriceCard'
import { Collapse } from '@material-ui/core'
import GlobalPrefs from './GlobalPrefs'

const useStyles = makeStyles((theme) => ({
  list: {
    width: theme.spacing(32),
  },
  menuButton: {
    marginRight: '16px',
    paddingLeft: '0px',
  },
  item: {
    padding: '0px 4px',
  },
  subitem: {
    paddingLeft: '32px',
  },
  tooltip: {
    backgroundColor: 'rgb(19,62,96)',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
}))

const NavMenu = ({ isAuthenticated, history }) => {
  const classes = useStyles()
  const [showDrawer, toggleDrawer] = useState(false)
  const [userAdminOpen, setUserAdminOpen] = useState(false)
  const [innovaAdminOpen, setInnovaAdminOpen] = useState(false)
  const [dataAcqOpen, setDataAcqOpen] = useState(false)
  const userRole = useRecoilValue(userUserRoleAtom)
  const isAdmin = userRole.roleAttributes?.permissions?.admin || userRole.innovaAdmin
  const isInnovaAdmin = userRole.innovaAdmin

  if (!isAuthenticated) return null

  const openDrawer = () => toggleDrawer(true)
  const closeDrawer = () => toggleDrawer(false)
  const navigate = (route) => {
    history.push(route)
    closeDrawer()
  }

  return (
    <React.Fragment>
      <Tooltip title={'Main menu'} placement='bottom' classes={{ tooltip: classes.tooltip }}>
        <IconButton className={classes.menuButton} aria-label='menu' onClick={openDrawer}>
          <MenuIcon />
        </IconButton>
      </Tooltip>
      <Drawer anchor='left' open={showDrawer} onClose={closeDrawer}>
        <UserProfileCard />
        <OilPriceCard />
        <List className={classes.list}>
          <ListItem key={'Home-/'} button onClick={() => navigate('/')}>
            <Iconify icon='ic:baseline-home' style={{ fontSize: '24px' }} />
            <ListItemText className={classes.item}>Home</ListItemText>
          </ListItem>
          <ListItem key={'Profile-/user-profile'} button onClick={() => navigate('/user-profile')}>
            <Iconify icon='ion:person-sharp' style={{ fontSize: '24px' }} />
            <ListItemText className={classes.item}>Profile</ListItemText>
          </ListItem>
          <ListItem key={'Well List-/well-list'} button onClick={() => navigate('/well-list')}>
            <Iconify icon='fa-solid:th-list' style={{ fontSize: '24px' }} />
            <ListItemText className={classes.item}>Wells</ListItemText>
          </ListItem>
          {isInnovaAdmin ? (
            <React.Fragment>
              <ListItem key={`InnovaAdmin-/page`} button onClick={() => setInnovaAdminOpen(!innovaAdminOpen)}>
                <Iconify icon='ic:baseline-verified-user' style={{ fontSize: '24px' }} />
                <ListItemText className={classes.item}>Innova Admin</ListItemText>
              </ListItem>
              <Collapse in={innovaAdminOpen} timeout='auto' unmountOnExit>
                <List component='div' className={classes.list}>
                  <ListItem
                    key={`/admin/orgdmin-/orgs`}
                    button
                    className={classes.subitem}
                    onClick={() => navigate('/admin/innovaAdmin/orgs')}>
                    <Iconify icon='clarity:users-solid' style={{ fontSize: '24px' }} />
                    <ListItemText className={classes.item}>Organizations</ListItemText>
                  </ListItem>
                  <ListItem
                    key={`/admin/databaseAdmin-/page`}
                    button
                    className={classes.subitem}
                    onClick={() => navigate('/admin/innovaAdmin/databases')}>
                    <Iconify icon='fa-solid:database' style={{ fontSize: '24px' }} />
                    <ListItemText className={classes.item}>Databases Health</ListItemText>
                  </ListItem>
                  <ListItem
                    key={`/admin/icdsAdmin-/page`}
                    button
                    className={classes.subitem}
                    onClick={() => navigate('/admin/innovaAdmin/icds')}>
                    <Iconify icon='fa-solid:server' style={{ fontSize: '24px' }} />
                    <ListItemText className={classes.item}>ICDS Servers</ListItemText>
                  </ListItem>
                  <ListItem
                    key={`/admin/drillLinkAdmin-/page`}
                    button
                    className={classes.subitem}
                    onClick={() => navigate('/admin/innovaAdmin/drillLink')}>
                    <img alt={'DrillLink'} src={NovDefaultIcon} style={{ height: 27, width: 27 }} />
                    <ListItemText className={classes.item}>DrillLink</ListItemText>
                  </ListItem>
                </List>
              </Collapse>
            </React.Fragment>
          ) : null}
          {checkFeature(6, userRole.roleAttributes?.featureId) ? (
            <ListItem key={`ToolOrders-/page`} button onClick={() => navigate('/toolOrders')}>
              <Iconify icon='emojione-monotone:hammer-and-wrench' style={{ fontSize: '24px' }} />
              <ListItemText className={classes.item}>Tool Orders</ListItemText>
            </ListItem>
          ) : null}
          {checkFeature(8, userRole.roleAttributes?.featureId) ? (
            <React.Fragment>
              <ListItem key={`DataAcquisition-/page`} button onClick={() => setDataAcqOpen(!dataAcqOpen)}>
                <Iconify icon='mdi:access-point' style={{ fontSize: '24px' }} />
                <ListItemText className={classes.item}>Data Acquisition</ListItemText>
              </ListItem>
              <Collapse in={dataAcqOpen} timeout='auto' unmountOnExit>
                <List component='div' className={classes.list}>
                  <ListItem
                    key={`DataAcquisitionConfig-/page`}
                    button
                    className={classes.subitem}
                    onClick={() => navigate('/dataAcquisition')}>
                    <Iconify icon='bytesize:settings' style={{ fontSize: '24px' }} />
                    <ListItemText className={classes.item}>Data Configurations</ListItemText>
                  </ListItem>
                  <ListItem
                    key={`DataStore-/page`}
                    button
                    className={classes.subitem}
                    onClick={() => navigate('/dataStores')}>
                    <Iconify icon='eos-icons:database' style={{ fontSize: '24px' }} />
                    <ListItemText className={classes.item}>Data stores</ListItemText>
                  </ListItem>
                  <ListItem
                    key={`WitsmlServer-/page`}
                    button
                    className={classes.subitem}
                    onClick={() => navigate('/witsmlServers')}>
                    <Iconify icon='ant-design:database-filled' style={{ fontSize: '24px' }} />
                    <ListItemText className={classes.item}>WITSML Servers</ListItemText>
                  </ListItem>
                </List>
              </Collapse>
            </React.Fragment>
          ) : null}
          {isAdmin ? (
            <ListItem key={`WellSeekerAdmin-/page`} button onClick={() => navigate('/admin/wellSeekerAdmin')}>
              <Iconify icon='ic:baseline-supervised-user-circle' style={{ fontSize: '24px' }} />
              <ListItemText className={classes.item}>WellSeeker Admin</ListItemText>
            </ListItem>
          ) : null}
          {isAdmin ? (
            <ListItem key={`errorModels-/page`} button onClick={() => navigate('/errorModels')}>
              <Iconify icon='ant-design:warning-filled' style={{ fontSize: '24px' }} />
              <ListItemText className={classes.item}>Error Models</ListItemText>
            </ListItem>
          ) : null}
          {isAdmin ? (
            <React.Fragment>
              <ListItem key={`/admin/userAdmin/menu`} button onClick={() => setUserAdminOpen(!userAdminOpen)}>
                <Iconify icon='ic:round-admin-panel-settings' style={{ fontSize: '24px' }} />
                <ListItemText className={classes.item}>User Management</ListItemText>
              </ListItem>
              <Collapse in={userAdminOpen} timeout='auto' unmountOnExit>
                <List component='div' className={classes.list}>
                  <ListItem
                    key={`/admin/userAdmin-/page`}
                    button
                    className={classes.subitem}
                    onClick={() => navigate('/admin/userAdmin/users')}>
                    <Iconify icon='clarity:users-solid' style={{ fontSize: '24px' }} />
                    <ListItemText className={classes.item}>Users</ListItemText>
                  </ListItem>
                  <ListItem
                    key={`/admin/roleAdmin-/page`}
                    button
                    className={classes.subitem}
                    onClick={() => navigate('/admin/userAdmin/roles')}>
                    <Iconify icon='ci:settings-filled' style={{ fontSize: '24px' }} />
                    <ListItemText className={classes.item}>Roles</ListItemText>
                  </ListItem>
                </List>
              </Collapse>
            </React.Fragment>
          ) : null}
        </List>
        <MapPrefs />
        <GlobalPrefs />
      </Drawer>
    </React.Fragment>
  )
}

export default withRouter(NavMenu)
