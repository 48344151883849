import React, { useEffect, useRef, useState } from 'react' 
import { useRecoilValue, useSetRecoilState } from 'recoil'
import * as XLSX from 'xlsx'

import { analyticsPhaseFilterAtom, analyticsSelectedWells, currentPageAtom } from 'atoms'
import AnalyticsPage from '.'
import AnalyticsCard from './AnalyticsCard'
import { LineChartCjs } from './DepthVsDaysChart'
import { getXlsxColumnLetter, array2pipestr } from 'utils'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import useAxiosWithUnitFetch from 'components/common/hooks/useAxiosWithUnitFetch'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import { createChartDataMultiWell } from 'components/common/depthVtimeFunctions'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'


const DepthVsDaysPage = () => {
  const _isMounted = useRef(false)

  const [data, setData] = useState([])
  const setActivePage = useSetRecoilState(currentPageAtom)
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const phaseFilter = useRecoilValue(analyticsPhaseFilterAtom)
  const userUnits = useUnits(UNITS_FOR.Depth)
  // const searchParams = useRecoilValue(analyticsSearchParamsAtom)
  const [removeFlatTime, setRemoveFlatTime] = useState(false)
  const { newCancelToken: kpiCancelToken, cancelPreviousRequest: kpiCancelRequest } = useAxiosCancelToken()
  const [{ error: kpiError }, getKpiData] = useAxiosWithUnitFetch({
    url: '/kpi/depthVdays',
    cancelToken: kpiCancelToken(),
    manual: true,
  })

  useEffect(() => {
    _isMounted.current = true
    setActivePage(PAGE_KEYS.depthVDaysKey)

    return () => {
      _isMounted.current = false
      kpiCancelRequest()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (kpiError !== undefined && kpiError !== null) {
      if (kpiCancelRequest(kpiError)) return
    }
  }, [kpiError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      getChartData()
    }
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      getChartData()
    }
  }, [phaseFilter]) // eslint-disable-line react-hooks/exhaustive-deps

  const getChartData = async () => {
    if (selectedWells.length < 1) {
      // console.log('getChartData: no wells selected')
      await setData([])
      return
    }

    let payload = {
      wellList: array2pipestr(selectedWells),
    }

    // add phaseFilter to the payload if it's not empty or All
    if (phaseFilter !== 'All' && phaseFilter !== '' && phaseFilter !== '0') {
      payload.phaseFilter = phaseFilter
    } else {
      payload.phaseFilter = ''
    }
    // console.log('getDvDdata payload', { phaseFilter, payload })
    const dataResponse = await getKpiData(payload)
    // console.log('getDepthVDays: ', dataResponse)
    if (dataResponse?.data) {
      setData(dataResponse.data)
    } else {
      // console.log('no DvD data available')
      setData([])
    }
  }

  // flat time:
  const onRemoveFlatTime = (removeFT) => {
    setRemoveFlatTime(removeFT)
  }

  const onXlsxExport = () => {
    // console.log('export raw ', data)
    let ws = undefined
    let colIndex = 0
    data.forEach((well) => {
      if (!ws) {
        ws = XLSX.utils.aoa_to_sheet([[well.actualWell]], { origin: 'A1' })
      } else {
        XLSX.utils.sheet_add_aoa(ws, [[well.actualWell]], { origin: `${getXlsxColumnLetter(colIndex)}1` })
      }
      XLSX.utils.sheet_add_aoa(ws, [['Days', `Depth (${userUnits})`, 'BHA', 'Phase', 'Activity']], {
        origin: `${getXlsxColumnLetter(colIndex)}2`,
      })
      XLSX.utils.sheet_add_aoa(
        ws,
        well.depthVDays.map((item) => [item.days, item.depth, item.bha > 0 ? item.bha : '', item.phase, item.activity]),
        { origin: `${getXlsxColumnLetter(colIndex)}3` },
      )
      colIndex += 5
    })

    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Depth v Days')
    XLSX.writeFile(wb, 'depthVsDays.xlsx')
  }

  // main render:
  return (
    <AnalyticsPage>
      <AnalyticsCard
        exportXlsxCB={onXlsxExport}
        showFlatTime={true}
        flatTimeCB={onRemoveFlatTime}>
        <LineChartCjs
          title='Depth Vs Days'
          xAxisTitle={'Days'}
          yAxisTitle={`Depth (${userUnits})`}
          wellData={createChartDataMultiWell(data, removeFlatTime, phaseFilter)}
        />
      </AnalyticsCard>
    </AnalyticsPage>
  )
}

export default DepthVsDaysPage