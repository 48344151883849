import React from 'react'
import { Vector3 } from 'three'
import { TextLabel, labelColor } from './ThreeDeeView'

export const Annotations = ({ annotations, display }) => {
  if (!display) return null
  if (!Array.isArray(annotations)) return null
  if (annotations.length === 0) return null

  return annotations.map((annot, i) => (
    <TextLabel
      label={`${annot.name}`}
      size={1}
      color={labelColor}
      position={new Vector3(annot.x + 5, annot.y, annot.z)}
      key={`annotation${i}`}
    />
  ))
}
