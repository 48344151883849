import React, { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'

import WellViewPage from '../WellViewPage'
import DailyCostHeaderCard from './DailyCostHeaderCard'
import { currentPageAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'

const DailyCostHeaderPage = ({ history }) => {
  const setActivePage = useSetRecoilState(currentPageAtom)

  useEffect(() => {
    setActivePage(PAGE_KEYS.costsHeadersKey)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <WellViewPage>
      <DailyCostHeaderCard history={history} />
    </WellViewPage>
  )
}

export default DailyCostHeaderPage
