export function unescapeHtml(inputStr) {
  if (typeof inputStr !== 'string') return inputStr

  inputStr = inputStr.replace('&dq;', '"')
  inputStr = inputStr.replace('&sq;', "'")
  inputStr = inputStr.replace('&gt;', '>')
  inputStr = inputStr.replace('&lt;', '<')
  inputStr = inputStr.replace('&amp;', '&')
  inputStr = inputStr.replace('?', '°')
  return inputStr
}

export function escapeHtml(inputStr) {
  if (typeof inputStr !== 'string') return inputStr

  inputStr = inputStr.replace('&', '&amp;')
  inputStr = inputStr.replace('“', '&dq;')
  inputStr = inputStr.replace('”', '&dq;')
  inputStr = inputStr.replace('"', '&dq;')
  inputStr = inputStr.replace("'", '&sq;')
  inputStr = inputStr.replace('>', '&gt;')
  inputStr = inputStr.replace('<', '&lt;')
  inputStr = inputStr.replace('¾', '.75')
  inputStr = inputStr.replace('¼', '.25')
  inputStr = inputStr.replace('½', '.5')
  inputStr = inputStr.replace('⅝', '.625')
  inputStr = inputStr.replace('°', 'deg')
  inputStr = inputStr.replace('⅞', '.875')
  inputStr = inputStr.replace('⅛', '.125')
  inputStr = inputStr.replace('’’', '&dq;')
  return inputStr
}

export function escapeHtmlObj(input) {
  if (input === null || input === undefined) return
  if (typeof input !== 'object') return

  for (const property in input) {
    if (typeof input[property] === 'string') input[property] = escapeHtml(input[property])
  }
}

export function unescapeHtmlObj(input) {
  if (input === null || input === undefined) return
  if (typeof input !== 'object') return

  for (const property in input) {
    if (typeof input[property] === 'string') input[property] = unescapeHtml(input[property])
  }
}
