function getSearchParam(inputObject, parameter) {
  let outputParam = ''
  if (inputObject.hasOwnProperty(parameter)) {
    outputParam = inputObject[parameter]
    if (typeof outputParam === 'number') outputParam = outputParam.toString()
    if (typeof outputParam !== 'string') return ''
    outputParam = outputParam.toLowerCase()
    outputParam = outputParam.trim()
  }

  return outputParam
}

export function searchFunction(obj, searchStr, searchParams) {
  if (obj === null || obj === undefined) return false
  if (typeof searchStr !== 'string') return true
  if (searchStr === '') return true

  let found = false
  searchParams.forEach((param) => {
    let paramLwr = getSearchParam(obj, param)
    if (paramLwr !== '' && paramLwr.includes(searchStr.toLowerCase().trim())) found = true
  })

  return found
}

export default function functionSearchObjectArray(objArry, searchStr, searchParams) {
  if (!Array.isArray(objArry)) return []
  if (objArry.length <= 0) return []
  if (typeof searchStr !== 'string') return objArry
  if (searchStr === '') return objArry
  if (!Array.isArray(searchParams)) return objArry
  if (searchParams.length <= 0) return []

  let filteredObjectArray = []

  objArry.forEach((obj) => {
    if (searchFunction(searchStr, obj)) filteredObjectArray.push(obj)
  })

  return filteredObjectArray
}
