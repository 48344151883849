import React, { useEffect, useState, useRef } from 'react'

import { makeStyles } from '@material-ui/styles'
import SearchBar from 'material-ui-search-bar'
import { IconButton, Tooltip, Snackbar, Typography } from '@material-ui/core'
import ConfirmDialog from 'components/common/ConfirmDialog'
import { Icon as Iconify } from '@iconify/react'

import { filter as _filter } from 'lodash'
import { appColors } from 'utils'
import { useRecoilValue } from 'recoil'
import InputModal from 'components/common/InputModal'
import * as yup from 'yup'
import AddIcon from '@material-ui/icons/Add'
import RefreshIcon from '@material-ui/icons/Refresh'
import MuiAlert from '@material-ui/lab/Alert'
import { checkPermission } from '../userPermissions'
import { userUserRoleAtom } from 'atoms'
import { searchFunction } from '../common/searchFunctions'

import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'

import SimpleStyledList from 'components/common/SimpleStyledList'
import MenuButton from 'components/common/MenuButton'

const useStyles = makeStyles((theme) => ({
  listContainer: {
    display: 'flex',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(calc(100vh - 100px) * 1.00)',
    maxHeight: 'calc(calc(100vh - 210px) * 1.00)',
    borderRadius: '4px',
    backgroundColor: theme.palette.itemBackground,
    width: '100%',
  },
  headerText: {
    fontSize: 14,
    fontWeight: '500',
    color: appColors.headerTextColor,
    paddingRight: '8px',
  },
  itemText: {
    maxWidth: '200px',
    fontSize: 14,
    color: appColors.itemTextColor,
    border: '',
    alignItems: 'center',
  },
  deleteIconStyle: {
    color: '#C00000',
    fontSize: '24px',
  },
  fixedListContainer: {
    display: 'flex',
    borderRadius: '4px',
    backgroundColor: theme.palette.itemBackground,
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#136cb9', //'darkgrey', //'rgba(0,0,0,.1)',
      outline: '1px solid #1679d0',
    },
    overflow: 'auto',
  },
  itemMainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '4px',
    borderRadius: 5,
    marginLeft: '8px',
    background: 'linear-gradient(to bottom, #2d2d2d 0%, #1c1c1c 100%)',
    marginBottom: '4px',
    width: 'calc(100% - 10px)', // 8px for marginLeft, 2px for left & right border
    borderColor: appColors.itemBackColor,
    backgroundColor: appColors.itemBackColor,
    '&:hover': {
      borderColor: appColors.accentColor,
      border: `1px solid`,
    },
  },
  itemSubContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    paddingTop: '8px',
    paddingBottom: '8px',
    paddingLeft: '8px',
    marginBottom: '1px',
    backgroundColor: 'transparent',
  },
  itemTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  itemTextWrapContainer: {
    flexDirection: 'column',
    flex: 3,
    marginLeft: '5px',
  },
  itemIconContainer: {
    width: 100,
    paddingHorizontal: 10,
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tooltip: {
    backgroundColor: 'rgb(19,62,96)',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
  menuIcon: {
    backgroundColor: 'transparent',
    margin: '4px',
    padding: '12px',
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2) - 20,
  },
}))

const DataStoreList = () => {
  const classes = useStyles()
  const _isMounted = useRef(true)
  const userRole = useRecoilValue(userUserRoleAtom)
  const [searchText, setSearchText] = useState('')
  const [filteredList, setFilteredList] = useState([])
  const [status, setStatus] = useState({ show: false, severity: 'info', message: '' })
  const [confirm, setConfirm] = useState({ show: false, title: '' })
  const [showEditor, setShowEditor] = useState(false)
  const editIndex = useRef(-1)

  const { newCancelToken, isCancel } = useAxiosCancelToken()
  const [{ data, error }, getDataStores] = useAxios({
    url: '/dataAcq/dataStore/getDataStores',
    cancelToken: newCancelToken(),
  })

  const { newCancelToken: newDeleteCancelToken, cancelPreviousRequest: cancelPreviousDeleteRequest } =
    useAxiosCancelToken()
  const [{ error: deleteError }, deleteItem] = useAxios({
    url: '/dataAcq/dataStore/deleteDataStore',
    manual: true,
    cancelToken: newDeleteCancelToken(),
  })

  const { newCancelToken: createCancelToken, cancelPreviousRequest: cancelCreateReq } = useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorCreate }, addItem] = useAxios({
    url: '/dataAcq/dataStore/addDataStore',
    manual: true,
    cancelToken: createCancelToken(),
  })

  const { newCancelToken: updateCancelToken, cancelPreviousRequest: cancelUpdateReq } = useAxiosCancelToken()
  // eslint-disable-next-line no-unused-vars
  const [{ error: errorUpdate }, updateItem] = useAxios({
    url: '/dataAcq/dataStore/updateDataStore',
    manual: true,
    cancelToken: updateCancelToken(),
  })

  useEffect(() => {
    if (errorUpdate !== undefined && errorUpdate !== null) {
      if (cancelUpdateReq(errorUpdate)) return
    }
  }, [errorUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (errorCreate !== undefined && errorCreate !== null) {
      if (cancelCreateReq(errorCreate)) return
    }
  }, [errorCreate]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error !== undefined && error !== null) {
      if (isCancel(error)) return
    }
  }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data) {
      const filtered = handleSearch(searchText)
      setFilteredList(filtered)
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      const filtered = handleSearch(searchText)
      setFilteredList(filtered)
    }
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (deleteError !== undefined && deleteError !== null) {
      if (cancelPreviousDeleteRequest(deleteError)) return
    }
  }, [deleteError]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = (text) => {
    if (text) {
      const filterList = _filter(data, (item) => {
        return searchFunction(item, text, [
          'databaseServer',
          'databaseUser',
          'databasePass',
          'databaseName',
          'storeName',
        ])
      })
      return filterList
    } else {
      return data
    }
  }

  const onClickDeleteItem = (item) => {
    setConfirm({
      show: true,
      title: 'Delete data store',
      text: `Are you sure you want to delete ${item?.storeName}?`,
      itemIndex: parseInt(item?.uid),
    })
  }

  const confirmDelete = async () => {
    if (confirm?.itemIndex >= 0) {
      const deleteResponse = await deleteItem({
        uid: confirm?.itemIndex,
      })

      if (!deleteResponse.error) {
        setStatus({ show: true, severity: 'success', message: 'Data store deleted successfully' })
        await getDataStores()
      } else {
        setStatus({ show: true, severity: 'error', message: 'Delete data store failed' })
      }
    }
  }

  const renderTextFieldNoWrap = (text, value, color) => {
    return (
      <div className={classes.itemTextContainer}>
        <Typography noWrap style={{ color: appColors.headerTextColor, paddingRight: '5px' }}>
          {text}
        </Typography>
        <Typography noWrap style={{ color: color ? color : appColors.itemTextColor }}>
          {value}
        </Typography>
      </div>
    )
  }

  const renderDataItem = (item, idx) => {
    return (
      <div className={classes.itemMainContainer}>
        <div className={classes.itemSubContainer}>
          <div
            className={classes.itemTextContainer}
            onClick={() => {
              editIndex.current = idx
              setShowEditor(true)
            }}>
            <div className={classes.itemTextWrapContainer}>
              {renderTextFieldNoWrap('Name:', item.storeName)}
              {renderTextFieldNoWrap('Server:', item.databaseServer)}
            </div>
            <div className={classes.itemTextWrapContainer}>
              {renderTextFieldNoWrap('Username:', item.databaseUser)}
              {renderTextFieldNoWrap('Password:', item.databasePass)}
            </div>
            <div className={classes.itemTextWrapContainer}>
              {renderTextFieldNoWrap('DB Name:', item.databaseName)}
              {renderTextFieldNoWrap('DB Port:', item.databasePort)}
            </div>
          </div>
          {checkPermission('canDelete', userRole.roleAttributes?.permissions) ? (
            <Tooltip title='Delete data store' placement='bottom' classes={{ tooltip: classes.tooltip }}>
              <IconButton className={classes.itemIconContainer} onClick={() => onClickDeleteItem(item)}>
                <Iconify icon='fa-regular:trash-alt' className={classes.deleteIconStyle} />
              </IconButton>
            </Tooltip>
          ) : null}
        </div>
      </div>
    )
  }

  const handleCloseStatus = (event, reason) => {
    setStatus({ show: false, severity: 'info', message: '' })
  }

  const handleNewItem = () => {
    setShowEditor(true)
  }

  const handleClose = () => {
    setShowEditor(false)
  }

  const submitFunction = async (data, formikActions) => {
    setShowEditor(false)
    if (!data) return false
    let isAdd = false
    if (editIndex.current < 0) {
      isAdd = true
    }
    let payload = { ...data }

    let saveResponse
    if (isAdd) {
      saveResponse = await addItem(payload, createCancelToken())
    } else {
      saveResponse = await updateItem(payload, updateCancelToken())
    }

    if (saveResponse.error) {
      setStatus({
        show: true,
        severity: 'error',
        message: isAdd ? 'Data store failed to add' : 'Data store update failed',
      })
      formikActions?.setSubmitting(false)
      return false
    } else {
      setStatus({ show: true, severity: 'success', message: isAdd ? 'Data store added' : 'Data store updated' })
    }

    await getDataStores()
    return true
  }

  const RenderCreateUpdateModal = ({ data, submitFunction, cancelFunction }) => {
    let useInputData = true
    if (!data) useInputData = false
    if (useInputData) if (data.hasOwnProperty('uid') === false) useInputData = false

    const formValidationSchema = yup.object({
      storeName: yup.string().required(),
      databaseServer: yup.string().required(),
      databasePort: yup.string().nullable(),
      databaseUser: yup.string().required(),
      databasePass: yup.string().required(),
      databaseName: yup.string().required(),
    })

    let formData = [
      {
        tag: 'uid',
        value: useInputData ? data.uid : '',
        inputType: '-',
      },
      {
        tag: 'storeName',
        value: useInputData === true ? data.storeName : '',
        text: 'Name',
        inputType: 'text',
        icon: 'text',
        iconFamily: 'Entypo',
      },
      {
        tag: 'databaseServer',
        value: useInputData === true ? data.databaseServer : '',
        text: 'Database Server (IP/URL)',
        inputType: 'text',
        icon: 'ip-network-outline',
        iconFamily: 'MaterialCommunityIcons',
      },
      {
        tag: 'databaseName',
        value: useInputData === true ? data.databaseName : '',
        text: 'Database Name',
        inputType: 'text',
        icon: 'database',
        iconFamily: 'Entypo',
      },
      {
        tag: 'databasePort',
        value: useInputData === true ? data.databasePort : '',
        text: 'Database Port',
        inputType: 'text',
      },
      {
        tag: 'databaseUser',
        value: useInputData === true ? data.databaseUser : '',
        text: 'Username',
        inputType: 'text',
        icon: 'user',
        iconFamily: 'AntDesign',
      },
      {
        tag: 'databasePass',
        value: useInputData === true ? data.databasePass : '',
        text: 'Password',
        inputType: 'text',
        icon: 'form-textbox-password',
        iconFamily: 'MaterialCommunityIcons',
      },
    ]

    const title = (useInputData ? 'Edit' : 'Add') + ' data store'

    return (
      <InputModal
        open={showEditor}
        onClose={cancelFunction}
        title={title}
        formData={formData}
        submitForm={submitFunction}
        cancelFunction={cancelFunction}
        validationSchema={formValidationSchema}
      />
    )
  }

  return (
    <>
      {confirm.show ? (
        <ConfirmDialog
          title={confirm?.title}
          open={confirm?.show}
          setOpen={() => setConfirm({ show: false })}
          onConfirm={() => confirmDelete()}>
          {confirm?.text}
        </ConfirmDialog>
      ) : null}
      {showEditor ? (
        <RenderCreateUpdateModal
          data={editIndex.current >= 0 ? data[editIndex.current] : {}}
          submitFunction={submitFunction}
          cancelFunction={handleClose}
        />
      ) : null}
      <SearchBar
        value={searchText}
        onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
        onCancelSearch={() => setSearchText('')}
        style={{
          padding: '1px',
          margin: '1px',
          backgroundColor: 'rgb(17, 17, 17)',
        }}
      />
      <div className={classes.listContainer}>
        <SimpleStyledList listItems={filteredList} renderItem={renderDataItem}></SimpleStyledList>
      </div>
      <div className={classes.menuIcon}>
        <MenuButton
          actions={[
            {
              icon: <AddIcon />,
              name: 'Add',
              onClick: () => {
                editIndex.current = -1
                handleNewItem(true)
              },
            },
            { icon: <RefreshIcon />, name: 'Refresh', onClick: getDataStores },
          ]}
        />
      </div>
      {status?.show ? (
        <Snackbar open={status?.show} autoHideDuration={2000} onClose={handleCloseStatus}>
          <MuiAlert onClose={handleCloseStatus} severity={status.severity} elevation={4} variant='filled'>
            {status.message}
          </MuiAlert>
        </Snackbar>
      ) : null}
    </>
  )
}

export default DataStoreList
