import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/styles'
import { useEffect, useRef } from 'react'
import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import { currentWellAtom } from 'atoms'
import { useRecoilValue } from 'recoil'
import PhaseStatisticsChartDrilled from './PhaseStatisticsChartDrilled'
import PhaseStatisticsChartHours from './PhaseStatisticsChartHours'
import PhaseStatisticsChartRop from './PhaseStatisticsChartRop'
import Carousel from 'react-material-ui-carousel'
import { useResizeDetector } from 'react-resize-detector'

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: '#192734',
    height: '69vh',
  },
  carousel: {
    height: '100%',
  },
}))

const PhaseStatisticsCard = () => {
  const currentWell = useRecoilValue(currentWellAtom)
  const classes = useStyles()
  const _isMounted = useRef(false)

  const { newCancelToken: CancelToken, cancelPreviousRequest } = useAxiosCancelToken()
  const [{ error, data }, getWellData] = useAxios({
    url: '/well/getWellData',
    data: { wellName: currentWell, dailyActivity: true },
    cancelToken: CancelToken(),
  })

  const { width: cWidth, height: cHeight, ref: cRef } = useResizeDetector({ refreshMode: 'debounce', refreshRate: 250 })

  useEffect(() => {
    if (error !== undefined && error !== null) {
      if (cancelPreviousRequest(error)) return
    }
  }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) getWellData()
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card className={classes.card} ref={cRef}>
      <Carousel autoPlay={false} className={classes.carousel}>
        <div style={{ height: cHeight, width: cWidth }}>
          <PhaseStatisticsChartDrilled data={data} />
        </div>
        <div style={{ height: cHeight, width: cWidth }}>
          <PhaseStatisticsChartHours data={data} />
        </div>
        <div style={{ height: cHeight, width: cWidth }}>
          <PhaseStatisticsChartRop data={data} />
        </div>
      </Carousel>
    </Card>
  )
}

export default PhaseStatisticsCard
