import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import DashboardContext from '../dashboardContext'

const useStyles = makeStyles((theme) => ({
  widgetMain: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20px 0 0 0',
    padding: '8px',
    backgroundColor: 'rgba(32,32,32,1.0)',
    border: '1px solid black',
    boxShadow: '0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);',
  },
  widgetBundleGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gridAutoRows: '2em',
    gridGap: '10px',
    margin: '20px 0 0 0',
  },
  widgetBundleCard: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '8px',
  },
  widgetBundle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  widgetBundleHdr: {
    color: 'rgba(192,192,192,1.0)',
    marginRight: '0.25em',
  },
  widgetLabel: {
    color: 'rgba(192,192,192,1.0)',
    textAlign: 'right',
    // marginRight: '0.5em',
    minWidth: '84px',
  },
  widgetValue: {
    color: 'rgba(52,201,235,1.0)',
    fontSize: '1.5em',
    minWidth: '120px',
    textAlign: 'right',
    fontWeight: 'bold',
  },
  widgetUnit: {
    color: 'rgba(192,192,192,1.0)',
    fontSize: '1em',
    marginLeft: '0.5em',
    textAlign: 'left',
    minWidth: '50px',
  },
  widgetValueState: {
    color: 'rgba(52,201,235,1.0)',
    fontSize: '1.5em',
    minWidth: '170px', //combined minWidth of value + unit
    textAlign: 'center',
    fontWeight: 'bold',
  },
}))

const DashWitsData = (props) => {
  const classes = useStyles()
  const { data: rtData } = useContext(DashboardContext)
  const { unitsInfo } = rtData || {}

  return (
    <div className={classes.widgetMain}>
      <div className={classes.widgetBundleHdr}>Drilling Data</div>
      <div className={classes.widgetBundleGrid}>
        <div className={classes.widgetBundle}>
          <span className={classes.widgetLabel}>Hole Depth</span>
          <span className={classes.widgetValue}>{rtData?.holeMD}</span>
          <span className={classes.widgetUnit}>{unitsInfo?.Depth}</span>
        </div>
        <div className={classes.widgetBundle}>
          <span className={classes.widgetLabel}>Bit Depth</span>
          <span className={classes.widgetValue}>{rtData?.bitMD}</span>
          <span className={classes.widgetUnit}>{unitsInfo?.Depth}</span>
        </div>
        <div className={classes.widgetBundle}>
          <span className={classes.widgetLabel}>WOB</span>
          <span className={classes.widgetValue}>{rtData?.wobAvg}</span>
          <span className={classes.widgetUnit}>{unitsInfo?.Wt}</span>
        </div>
        <div className={classes.widgetBundle}>
          <span className={classes.widgetLabel}>RPM</span>
          <span className={classes.widgetValue}>{rtData?.rpmAvg}</span>
          <span className={classes.widgetUnit}> </span>
        </div>
        <div className={classes.widgetBundle}>
          <span className={classes.widgetLabel}>Flow</span>
          <span className={classes.widgetValue}>{rtData?.totalGpm}</span>
          <span className={classes.widgetUnit}>{unitsInfo?.Flow}</span>
        </div>
        <div className={classes.widgetBundle}>
          <span className={classes.widgetLabel}>Pressure</span>
          <span className={classes.widgetValue}>{rtData?.sppAvg}</span>
          <span className={classes.widgetUnit}>{unitsInfo?.Press}</span>
        </div>
        <div className={classes.widgetBundle}>
          <span className={classes.widgetLabel}>Torque</span>
          <span className={classes.widgetValue}>{rtData?.torqueAvg}</span>
          <span className={classes.widgetUnit}>{unitsInfo?.Tq}</span>
        </div>
        <div className={classes.widgetBundle}>
          <span className={classes.widgetLabel}>Hookload</span>
          <span className={classes.widgetValue}>{rtData?.hookloadAvg}</span>
          <span className={classes.widgetUnit}>{unitsInfo?.Wt}</span>
        </div>
        <div className={classes.widgetBundle}>
          <span className={classes.widgetLabel}>RigState</span>
          <span className={classes.widgetValueState}>{rtData?.rigState}</span>
        </div>
        <div className={classes.widgetBundle}>
          <span className={classes.widgetLabel}>ROP</span>
          <span className={classes.widgetValue}>{rtData?.ropAvg}</span>
          <span className={classes.widgetUnit}>{unitsInfo?.Depth}/hr</span>
        </div>
        <div className={classes.widgetBundle}>
          <span className={classes.widgetLabel}>TF</span>
          <span className={classes.widgetValue}>{rtData?.etf}</span>
          <span className={classes.widgetUnit}>{unitsInfo ? '°' : ''}</span>
        </div>
        <div className={classes.widgetBundle}>
          <span className={classes.widgetLabel}>BHT</span>
          <span className={classes.widgetValue}>{rtData?.bht}</span>
          <span className={classes.widgetUnit}>{unitsInfo?.Temp}</span>
        </div>
      </div>
    </div>
  )
}

export default DashWitsData
