// @flow
import React, { useEffect } from 'react'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { Skeleton } from '@material-ui/lab'

import Page from 'components/common/Page'
import Space from 'components/common/Space'
import WellInfo from 'components/WellInfo'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import { currentPageAtom, currentWellAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import FootagePerDayChart from 'components/WellPage/components/FootagePerDayChart'
import PhaseStatisticsCard from '../WellPage/components/PhaseStatistics/PhaseStatisticsCard'

//http://localhost:3000/well-pages/comps

const WellComponentsPage = () => {
  const currentWell = useRecoilValue(currentWellAtom)
  const setActivePage = useSetRecoilState(currentPageAtom)

  useEffect(() => {
    setActivePage(PAGE_KEYS.wellComponentsKey)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page maxWidth>
      <Space>
        <Card>
          <CardContent
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              backgroundColor: '#192734',
              padding: '8px',
              border: '1px solid #000',
            }}>
            {currentWell ? (
              <React.Fragment>
                <WellInfo well={currentWell} />
                <FootagePerDayChart currentWell={currentWell} />
                {/* Add new well components after this line... */}
                <PhaseStatisticsCard currentWell={currentWell} />
              </React.Fragment>
            ) : (
              <Skeleton variant='rect' width={'100%'} height={'100%'} animation='wave' />
            )}
          </CardContent>
        </Card>
      </Space>
    </Page>
  )
}

export default WellComponentsPage
