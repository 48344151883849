import { createMuiTheme } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'

export const appColors = {
  modalBackColor: '#192734', // Very Dark Blue-Grey
  componentBackColor: '#133f60', // Dark Blue-Grey
  windowBackColor: '#2d2d2d', //Dark Grey
  itemBackColor: '#202020', //Very Dark Grey
  headerBackgroundColor: '#2d2d2d',
  borderColor: '#000000', //Black
  headerTextColor: '#C0C0C0', //Light Grey
  itemTextColor: '#429CEB', //Neon Blue
  itemTextColorMobile: '#34C9EB', //Neon Blue
  accentColor: '#4BB2F9',
  rotateColor: '#59FF00CF',
  slideColor: '#F59B00EF',
  nptColor: '#FF6363EF',
  bhaHandlingColor: '#FFEF00EF',
  dirNptColor: '#FFB35CEF',
  motorColor: '#F59B00',
  mwdColor: '#59FF00CF',
  bitColor: '#FF6363EF',
  surveyTrajectoryColor: '#FF5733',
  planTrajectoryColor: '#0096FF',
  mapClusterColor: '#0782f5',
  mapClusterTextColor: '#FFFFFF',
  itemTextColorDark: '#136cb9',
  itemTextColorDark2: '#115ea2',
  itemTextColorDark3: '#0f518a',
  chartNeonBlue: '#34C9EB',
  avgColor: '#FF00FF',
}

const theme = createMuiTheme({
  palette: {
    //primary: indigo,
    primary: {
      main: '#429CEB', //itemTextColor
      contrastText: '#fff',
    },
    // TODO: What should the secondary color be?
    secondary: {
      main: '#fff',
      contrastText: '#202020',
    },
    subtleBackground: grey[100],
    itemBackground: appColors.modalBackColor, // '#192734',
    item: {
      main: '#202020', // itemBackColor
      dark: '#202020', //Very Dark Grey
      contrastText: '#fff',
    },
  },
})

theme.props = {
  MuiInput: {
    disableUnderline: true,
  },
}

theme.overrides = {
  MuiDialogTitle: {
    root: {
      color: appColors.headerTextColor,
    },
  },
  MuiDialogContent: {
    root: {
      color: '#fff',
    },
  },
  MuiListItem: {
    container: {
      color: appColors.itemTextColor,
      backgroundColor: appColors.itemBackColor,
      marginTop: '4px',
      border: '1px solid',
      borderColor: appColors.borderColor,
    },
  },
  MuiTextField: {
    root: {
      border: '1px solid',
      borderWidth: '1px',
      overflow: 'hidden',
      borderRadius: 4,
      width: '95%',
      margin: 5,
      fontSize: 16,
      height: '32px',
      padding: 5,
      paddingLeft: 5,
      borderColor: appColors.borderColor,
      backgroundColor: appColors.itemBackColor,
      color: appColors.itemTextColor,
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:hover': {
        backgroundColor: appColors.itemBackColor,
        borderColor: appColors.accentColor,
      },
      '&$focused': {
        backgroundColor: appColors.itemBackColor,
        borderColor: appColors.accentColor,
      },
    },
    focused: {},
    input: {},
  },
  MuiInputBase: {
    input: {
      paddingLeft: '4px',
      color: appColors.itemTextColor,
    },
  },
  MuiFormHelperText: {
    root: {
      fontSize: 12,
      color: 'yellow',
      margin: '0px 5px 5px 5px',
      padding: '0px 5px 5px 5px',
      '&$error': {
        color: 'yellow',
      },
    },
    error: {},
  },
  MuiOutlinedInput: {
    root: {
      marginBottom: '6px', // adds spacing below an outlined Select component...
    },
  },
  MuiSvgIcon: {
    root: {
      color: appColors.itemTextColor,
    },
  },
  MuiInputLabel: {
    root: {
      color: appColors.headerTextColor,
      textTransform: 'uppercase',
      fontSize: '12px',
      marginBottom: '1px',
      marginTop: '4px',
      paddingLeft: '16px',
      paddingTop: '4px',
    },
  },
  MuiPickersToolbar: {
    toolbar: {
      backgroundColor: appColors.componentBackColor,
    },
  },
  // MuiPickersBasePicker: {
  //   container: {
  //     backgroundColor: '#959595',
  //   },
  // },
  MuiPickersCalendarHeader: {
    iconButton: {
      backgroundColor: '#959595',
    },
  },
  MuiCardContent: {
    root: {
      backgroundColor: '2d2d2d', //appColors.itemBackColor,
      overflow: 'auto',
    },
  },

  // TODO: finish new pages with dark theme before using global select styling
  // MuiSelect: {
  //   root: {
  //     display: 'flex',
  //     margin: 5,
  //     fontSize: 16,
  //     padding: '5px 10px 5px 10px',
  //     height: '32px',
  //     width: '100%',
  //     color: appColors.itemTextColor,
  //     // borderColor: appColors.borderColor,
  //     borderColor: appColors.accentColor,
  //     backgroundColor: appColors.itemBackColor,
  //     borderRadius: '5px',
  //     '&:before': {
  //       backgroundColor: appColors.itemBackColor,
  //       border: '2px solid',
  //       borderColor: appColors.borderColor,
  //     },
  //     '& .MuiFormControl-root': {
  //       width: '100%',
  //     },
  //     // alignItems: 'center',
  //   },
  //   selectMenu: {
  //     backgroundColor: '#202020',
  //     '& ul': {
  //       backgroundColor: '#404040',
  //     },
  //     '& li': {
  //       fontSize: 12,
  //     },
  //   },
  //   outlined: {
  //     '&:hover': {
  //       backgroundColor: appColors.itemBackColor,
  //       borderColor: appColors.accentColor,
  //     },
  //     '&$focused': {
  //       backgroundColor: appColors.itemBackColor,
  //       borderColor: appColors.accentColor,
  //     },
  //   },
  // },
  //trying to change scrollbars, meh, not working yet:
  // MuiCssBaseline: {
  //   '@global': {
  //     body: {
  //       scrollbarColor: '#6b6b6b #2b2b2b',
  //       '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
  //         backgroundColor: '#2b2b2b',
  //       },
  //       '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
  //         borderRadius: 8,
  //         backgroundColor: '#6b6b6b',
  //         minHeight: 24,
  //         border: '3px solid #2b2b2b',
  //       },
  //       '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
  //         backgroundColor: '#959595',
  //       },
  //       '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
  //         backgroundColor: '#959595',
  //       },
  //       '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
  //         backgroundColor: '#959595',
  //       },
  //       '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
  //         backgroundColor: '#2b2b2b',
  //       },
  //     },
  //   },
  // },
}

export const chartSeriesColors = [
  '#5A87C6',
  '#DB3C94',
  '#3B21D9',
  '#B0ED27',
  '#A30A73',
  '#3EE371',
  '#2852D7',
  '#0BF629',
  '#E241DC',
  '#26EB52',
  '#491BE2',
  '#19C9F9',
  '#124408',
  '#0EB9AF',
  '#0B9649',
  '#518726',
  '#E3C340',
  '#9D9563',
  '#4FDC62',
  '#543851',
  '#1D5C19',
  '#707EDB',
  '#22AEDF',
  '#4DF59E',
  '#70BB7C',
  '#4C60B3',
  '#6C41B1',
  '#FB33EB',
  '#9DB592',
  '#1CE760',
  '#9B6CB2',
  '#DBCFDB',
  '#B8D635',
  '#8A23FA',
  '#51747C',
  '#CE83B3',
]

export default theme

export const datePickerTheme = createMuiTheme({
  palette: {
    //primary: indigo,
    primary: {
      main: '#133f60', //'#429CEB', //itemTextColor
      contrastText: '#fff',
    },
    // TODO: What should the secondary color be?
    secondary: {
      main: '#fff',
      contrastText: '#202020',
    },
  },
})

datePickerTheme.props = {
  MuiInput: {
    disableUnderline: true,
  },
}

datePickerTheme.overrides = {
  MuiTextField: {
    root: {
      border: '1px solid',
      borderWidth: '1px',
      overflow: 'hidden',
      borderRadius: 4,
      width: '95%',
      margin: 5,
      fontSize: 16,
      height: '32px',
      padding: 5,
      paddingLeft: 5,
      borderColor: appColors.borderColor,
      backgroundColor: appColors.itemBackColor,
      color: appColors.itemTextColor,
      transition: datePickerTheme.transitions.create(['border-color', 'box-shadow']),
      '&:hover': {
        backgroundColor: appColors.itemBackColor,
        borderColor: appColors.accentColor,
      },
      '&$focused': {
        backgroundColor: appColors.itemBackColor,
        borderColor: appColors.accentColor,
      },
    },
    focused: {},
    input: {},
  },
  MuiInputBase: {
    input: {
      paddingLeft: '4px',
      color: appColors.itemTextColor,
    },
  },
  MuiFormHelperText: {
    root: {
      fontSize: 12,
      color: 'yellow',
      margin: '0px 5px 5px 5px',
      padding: '0px 5px 5px 5px',
      '&$error': {
        color: 'yellow',
      },
    },
    error: {},
  },
  MuiOutlinedInput: {
    root: {
      marginBottom: '6px', // adds spacing below an outlined Select component...
    },
  },
  MuiSvgIcon: {
    root: {
      color: appColors.itemTextColor,
    },
  },
}
