import React, { useEffect, useRef, useState } from 'react'

import { Paper, Avatar, Accordion, AccordionSummary, AccordionDetails, Tooltip, ListItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Icon as Iconify } from '@iconify/react'
import SearchBar from 'material-ui-search-bar'
import TableChartIcon from '@material-ui/icons/TableChart'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

import { appColors } from 'utils'

import { useRecoilValue, useSetRecoilState } from 'recoil'
import { currentWellAtom, currentWellDetailsAtom, wellListAtom } from 'atoms'
import { actionBarWidthAtom } from '../../atoms'

import { GetActivityColor, GetActivityText, getStatusAvatar } from '../common/activitySelector'
import { ExpandMore } from '@material-ui/icons'
import WellInfo from '../WellInfo'
import SectionList from '../common/SectionList'
import StyledBadge from 'components/NavBar/StyledBadge'

import { reduceWellData, reduceVirtualizedWellData } from 'components/common/wellDataFunctions'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: appColors.itemBackColor,
    maxWidth: '100%',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
    paddingRight: '4px',
    paddingLeft: '4px',
    listStyleType: 'none !important',
  },
  itemRowContainer: {
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0px 0px 0px 0px',
    width: '100%',
  },
  itemVirtualRowContainer: {
    display: 'flex',
    padding: '0px',
    borderRadius: 5,
    border: '1px solid',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '4px',
    background: 'linear-gradient(to bottom, #2d2d2d 0%, #1c1c1c 100%)',
    marginBottom: '4px',
    height: '100%',
    width: 'calc(100% - 10px)', // 8px for marginLeft, 2px for left & right border
  },
  avatarContainer: {
    margin: 5,
    marginRight: 20,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'lightgrey',
    borderRadius: '50%',
    maxHeight: 40,
    '&:hover': {
      backgroundColor: '#FFF',
    },
  },
  avatarHeaderContainer: {
    margin: 5,
    marginRight: 20,
    alignItems: 'center',
    backgroundColor: 'lightgrey',
    borderRadius: '50%',
    maxHeight: 40,
  },
  edrIcon: {
    color: appColors.headerTextColor,
    '&:hover': {
      color: appColors.accentColor,
    },
  },
  headerText: {
    color: '#FFF',
    width: '11.43%',
    textAlign: 'center',
    fontWeight: 600,
    fontSize: 15,
  },
  itemWellText: {
    color: appColors.headerTextColor,
    fontWeight: '700',
    width: '20%',
    textAlign: 'center',
  },
  itemText: {
    color: '#34C9EB',
    width: '11.43%',
    textAlign: 'center',
    fontWeight: 500,
  },

  //BEGIN SectionList implementations
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0px 0px 4px 0px',
    padding: '4px 0px 4px 0px',
    width: '100%',
    background:
      'rgba(19,62,108,1) linear-gradient(90deg, rgba(19,62,108,1) 0%, rgba(19,62,108,1) 50%, rgba(132,209,223,1) 100%)',
    maxHeight: 55,
  },
  headerContainerVirtual: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0px 0px 4px 0px',
    padding: '4px 0px 4px 0px',
    width: '100%',
    background:
      'rgba(19,62,108,1) linear-gradient(90deg, rgba(19,62,108,1) 0%, rgba(19,62,108,1) 50%, rgba(132,209,223,1) 100%)',
    height: '73px',
    maxHeight: '73px',
  },
  rowContainer: {
    display: 'flex',
    height: 55,
    alignItems: 'center',
    width: '95%',
  },
  headerOperatorText: {
    fontSize: 17,
    fontWeight: '800',
    color: '#FFF',
    lineHeight: 1,
    textAlign: 'left',
    width: '18%',
    textShadow: '1px 0px #404040',
  },
  accordionBackground: {
    background: 'linear-gradient(rgb(45, 45, 45) 0%, rgb(28, 28, 28) 100%)',
    width: '100%',
    borderRadius: 5,
  },

  // BEGIN WellList MUI overrides
  wellListContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflow: 'hidden',
    height: 'calc(100vh - 128px)',
    maxHeight: 'calc(100vh - 128px)',
  },
  statusUpdateFlexWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '4%',
    marginRight: 15,
    alignSelf: 'center',
  },
  statusUpdateContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusUpdateDesc: {
    width: '22px',
    padding: '2px',
    fontSize: '12px',
    fontWeight: 'bold',
    display: 'flex',
    color: '#346578',
  },
  statusUpdateIndicator: {
    fontWeight: 'bold',
    borderRadius: '50%',
    width: '8px',
    height: '8px',
    padding: '2px',
  },

  sectionList: {
    display: 'flex',
    overflow: 'hidden',
    height: 'calc(calc(100vh - 128px) * 1.00)',
    maxHeight: 'calc(calc(100vh - 128px) * 1.00)',
    borderRadius: '4px',
    border: '1px solid #000',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#136cb9',
    outline: '1px solid #1679d0',
  },
  tooltip: {
    backgroundColor: 'rgb(19,62,96)',
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
  locationIcon: {
    color: appColors.headerTextColor,
    '&:hover': {
      color: appColors.accentColor,
    },
  },
  listContainer: {
    display: 'flex',
    overflowY: 'hidden',
    overflowX: 'hidden',
    height: 'calc(calc(100vh - 128px) * 1.00)',
    maxHeight: 'calc(calc(100vh - 128px) * 1.00)',
    borderRadius: '4px',
    border: '1px solid #000',
    // backgroundColor: theme.palette.itemBackground,
    width: '100%',
  },
  fixedListContainer: {
    display: 'flex',
    borderRadius: '4px',
    backgroundColor: theme.palette.itemBackground,
    '&::-webkit-scrollbar': {
      width: '10px',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#136cb9', //'darkgrey', //'rgba(0,0,0,.1)',
      outline: '1px solid #1679d0',
    },
    overflow: 'auto',
  },
}))

const WellList = ({ children, showFlatTime, flatTimeCB, exportXlsxCB, history }) => {
  const _isMounted = useRef(false)
  const listRef = useRef()
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const classes = useStyles({ leftPos })
  const virtualRenderThreshhold = 100

  const [searchText, setSearchText] = useState('')
  const wells = useRecoilValue(wellListAtom)
  const setCurrentWell = useSetRecoilState(currentWellAtom)
  const setCurrentWellDetails = useSetRecoilState(currentWellDetailsAtom)
  const [reducedWellList, setReducedWellList] = useState([])

  useEffect(() => {
    _isMounted.current = true
    setReducedWellList(
      wells?.length < virtualRenderThreshhold
        ? reduceWellData(wells, searchText)
        : reduceVirtualizedWellData(wells, searchText),
    )

    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setReducedWellList(
      wells?.length < virtualRenderThreshhold
        ? reduceWellData(wells, searchText)
        : reduceVirtualizedWellData(wells, searchText),
    )
  }, [searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setReducedWellList(
      wells?.length < virtualRenderThreshhold
        ? reduceWellData(wells, searchText)
        : reduceVirtualizedWellData(wells, searchText),
    )
  }, [wells]) // eslint-disable-line react-hooks/exhaustive-deps

  const connectionStatusColor = (updateValue) => {
    const updateVal = parseFloat(updateValue)
    // alert("made it this far")
    if (updateVal < 5) {
      return 'green'
    } else if (updateVal < 30) {
      return 'orange'
    } else {
      return 'red'
    }
  }
  const backgroundColorAccordion = () => {
    return classes.accordionBackground
  }

  const renderWellSectionListItem = (item) => {
    return (
      <React.Fragment>
        <div className={classes.itemRowContainer}>
          <Accordion className={classes.accordionBackground} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary style={{ padding: '0px 16px 0px 0px' }} expandIcon={<ExpandMore />}>
              <div
                className={classes.avatarContainer}
                onClick={(event) => {
                  event.stopPropagation()
                  setCurrentWell(item.actualWell)
                  setCurrentWellDetails({
                    actualWell: item.actualWell,
                    wellStatus: item.wellStatus,
                    operator: item.operator,
                    rig: item.rig,
                    jobNum: item.jobNum,
                    lat: item.latitude,
                    lng: item.longitude,
                  })
                  history.push('/well-pages/well-page')
                }}>
                <StyledBadge
                  badgeColor={GetActivityColor(item.wellStatus)}
                  badgeContent={GetActivityText(item.wellStatus)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}>
                  <Tooltip title='Go to well' placement='right' classes={{ tooltip: classes.tooltip }}>
                    <Avatar alt='Well Icon' src={getStatusAvatar(item.wellStatus)}></Avatar>
                  </Tooltip>
                </StyledBadge>
              </div>
              <div style={{ margin: '0 15px 0 10px', alignSelf: 'center' }}>
                <Tooltip title='Go to EDR' placement='right' classes={{ tooltip: classes.tooltip }}>
                  <TableChartIcon
                    fontSize={'large'}
                    className={classes.edrIcon}
                    onClick={(event) => {
                      event.stopPropagation()
                      setCurrentWell(item.actualWell)
                      setCurrentWellDetails({
                        actualWell: item.actualWell,
                        wellStatus: item.wellStatus,
                        operator: item.operator,
                        rig: item.rig,
                        jobNum: item.jobNum,
                      })
                      history.push('/edr')
                    }}></TableChartIcon>
                </Tooltip>
              </div>
              <div className={classes.statusUpdateFlexWrapper}>
                <div className={classes.statusUpdateContainer}>
                  <div className={classes.statusUpdateDesc}>WT</div>
                  <div
                    className={classes.statusUpdateIndicator}
                    style={{
                      color: connectionStatusColor(item.witsMinsSinceUpdate),
                      backgroundColor: connectionStatusColor(item.witsMinsSinceUpdate),
                    }}
                  />
                </div>
                <div className={classes.statusUpdateContainer}>
                  <div className={classes.statusUpdateDesc}>DX</div>
                  <div
                    className={classes.statusUpdateIndicator}
                    style={{
                      color: connectionStatusColor(item.icdsMinsSinceUpdate),
                      backgroundColor: connectionStatusColor(item.icdsMinsSinceUpdate),
                    }}
                  />
                </div>
              </div>

              <div className={classes.rowContainer}>
                <div className={classes.itemWellText}>{item.actualWell}</div>
                <div className={classes.itemText}>{item.jobNum}</div>
                <div className={classes.itemText}>{item.rig}</div>
                <div className={classes.itemText}>{item.state}</div>
                <div className={classes.itemText}>{item.county}</div>
                <div className={classes.itemText}>{item.operator}</div>
                <div className={classes.itemText}>{item.wellStatus}</div>
                <div className={classes.itemText} style={{ width: '8%' }}>
                  {item.formation}
                </div>
              </div>
              <div
                className={classes.locationIcon}
                style={{ margin: 'auto' }}
                onClick={(event) => {
                  event.stopPropagation()
                  setCurrentWell(item.actualWell)
                  setCurrentWellDetails({
                    actualWell: item.actualWell,
                    wellStatus: item.wellStatus,
                    operator: item.operator,
                    rig: item.rig,
                    jobNum: item.jobNum,
                    lat: item.latitude,
                    lng: item.longitude,
                  })
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth', //can also use 'auto'
                  })
                }}>
                <Iconify icon='ion:locate-sharp' width='30' height='30' className={classes.locationIcon} />
              </div>
            </AccordionSummary>
            <AccordionDetails classes={{ root: backgroundColorAccordion() }}>
              <WellInfo well={item.actualWell} />
            </AccordionDetails>
          </Accordion>
        </div>
      </React.Fragment>
    )
  }

  const renderVirtualWellSectionListItem = (item, index, style) => {
    return (
      <ListItem dense disableGutters={true} style={style}>
        <div className={classes.itemVirtualRowContainer}>
          <div
            className={classes.avatarContainer}
            onClick={(event) => {
              event.stopPropagation()
              setCurrentWell(item.actualWell)
              setCurrentWellDetails({
                actualWell: item.actualWell,
                wellStatus: item.wellStatus,
                operator: item.operator,
                rig: item.rig,
                jobNum: item.jobNum,
                lat: item.latitude,
                lng: item.longitude,
              })
              history.push('/well-pages/well-page')
            }}>
            <StyledBadge
              badgeColor={GetActivityColor(item.wellStatus)}
              badgeContent={GetActivityText(item.wellStatus)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}>
              <Tooltip title='Go to well' placement='right' classes={{ tooltip: classes.tooltip }}>
                <Avatar alt='Well Icon' src={getStatusAvatar(item.wellStatus)}></Avatar>
              </Tooltip>
            </StyledBadge>
          </div>
          <div style={{ margin: '0 15px 0 10px', alignSelf: 'center' }}>
            <Tooltip title='Go to EDR' placement='right' classes={{ tooltip: classes.tooltip }}>
              <TableChartIcon
                fontSize={'large'}
                className={classes.edrIcon}
                onClick={(event) => {
                  event.stopPropagation()
                  setCurrentWell(item.actualWell)
                  setCurrentWellDetails({
                    actualWell: item.actualWell,
                    wellStatus: item.wellStatus,
                    operator: item.operator,
                    rig: item.rig,
                    jobNum: item.jobNum,
                  })
                  history.push('/edr')
                }}></TableChartIcon>
            </Tooltip>
          </div>
          <div className={classes.statusUpdateFlexWrapper}>
            <div className={classes.statusUpdateContainer}>
              <div className={classes.statusUpdateDesc}>WT</div>
              <div
                className={classes.statusUpdateIndicator}
                style={{
                  color: connectionStatusColor(item.witsMinsSinceUpdate),
                  backgroundColor: connectionStatusColor(item.witsMinsSinceUpdate),
                }}
              />
            </div>
            <div className={classes.statusUpdateContainer}>
              <div className={classes.statusUpdateDesc}>DX</div>
              <div
                className={classes.statusUpdateIndicator}
                style={{
                  color: connectionStatusColor(item.icdsMinsSinceUpdate),
                  backgroundColor: connectionStatusColor(item.icdsMinsSinceUpdate),
                }}
              />
            </div>
          </div>

          <div className={classes.rowContainer}>
            <div className={classes.itemWellText}>{item.actualWell}</div>
            <div className={classes.itemText}>{item.jobNum}</div>
            <div className={classes.itemText}>{item.rig}</div>
            <div className={classes.itemText}>{item.state}</div>
            <div className={classes.itemText}>{item.county}</div>
            <div className={classes.itemText}>{item.operator}</div>
            <div className={classes.itemText}>{item.wellStatus}</div>
            <div className={classes.itemText} style={{ width: '8%' }}>
              {item.formation}
            </div>
            <div
              className={classes.locationIcon}
              style={{ margin: 'auto' }}
              onClick={(event) => {
                event.stopPropagation()
                setCurrentWell(item.actualWell)
                setCurrentWellDetails({
                  actualWell: item.actualWell,
                  wellStatus: item.wellStatus,
                  operator: item.operator,
                  rig: item.rig,
                  jobNum: item.jobNum,
                  lat: item.latitude,
                  lng: item.longitude,
                })
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth', //can also use 'auto'
                })
              }}>
              <Iconify icon='ion:locate-sharp' width='30' height='30' className={classes.locationIcon} />
            </div>
          </div>
        </div>
      </ListItem>
    )
  }

  const renderWellListHeader = (section) => {
    return (
      <div className={classes.headerContainer}>
        <div className={classes.avatarHeaderContainer}>
          <Iconify icon='ic:baseline-oil-barrel' width='40' height='40' color='black' />
        </div>
        <div className={classes.headerOperatorText}>{section.operator}</div>
        <div className={classes.rowContainer}>
          <div className={classes.headerText} style={{ width: '13%', textAlign: 'left' }}>
            Well
          </div>
          <div className={classes.headerText}>Job</div>
          <div className={classes.headerText}>Rig</div>
          <div className={classes.headerText}>State</div>
          <div className={classes.headerText}>County</div>
          <div className={classes.headerText}>Operator</div>
          <div className={classes.headerText} style={{ width: '16%' }}>
            Status
          </div>
          <div className={classes.headerText} style={{ width: '5%' }}>
            Formation
          </div>
        </div>
      </div>
    )
  }

  const renderVirtualWellListHeader = (operator, index, style) => {
    return (
      <ListItem dense disableGutters={true} style={style}>
        <div className={classes.headerContainerVirtual}>
          <div className={classes.avatarHeaderContainer}>
            <Iconify icon='ic:baseline-oil-barrel' width='40' height='40' color='black' />
          </div>
          <div className={classes.headerOperatorText}>{operator}</div>
          <div className={classes.rowContainer}>
            <div className={classes.headerText} style={{ width: '13%', textAlign: 'left' }}>
              Well
            </div>
            <div className={classes.headerText}>Job</div>
            <div className={classes.headerText}>Rig</div>
            <div className={classes.headerText}>State</div>
            <div className={classes.headerText}>County</div>
            <div className={classes.headerText}>Operator</div>
            <div className={classes.headerText} style={{ width: '16%' }}>
              Status
            </div>
            <div className={classes.headerText} style={{ width: '5%' }}>
              Formation
            </div>
          </div>
        </div>
      </ListItem>
    )
  }

  const renderVirtualWellRow = (props) => {
    const { index, style } = props
    const item = reducedWellList[index]
    if (item.hasOwnProperty('header')) {
      return renderVirtualWellListHeader(item.operator, index, style)
    } else {
      return renderVirtualWellSectionListItem(item, index, style)
    }
  }

  // main render:
  return (
    <Paper className={classes.paper}>
      <div className={classes.wellListContainer}>
        <div className={classes.searchContainer}>
          <SearchBar
            value={searchText}
            onChange={(newSearchTerm) => setSearchText(newSearchTerm)}
            onCancelSearch={() => setSearchText('')}
            style={{
              padding: '1px',
              margin: '1px',
              backgroundColor: 'rgb(17, 17, 17)',
            }}
          />
        </div>
        {reducedWellList?.length < 100 ? (
          <div className={classes.sectionList}>
            <SectionList
              sectionList={reducedWellList}
              renderItem={renderWellSectionListItem}
              renderSectionHeader={renderWellListHeader}
            />
          </div>
        ) : (
          <div className={classes.listContainer} ref={listRef}>
            <AutoSizer>
              {({ height, width }) => (
                <FixedSizeList
                  className={classes.fixedListContainer}
                  height={height}
                  width={width}
                  itemCount={reducedWellList.length}
                  itemSize={81}>
                  {renderVirtualWellRow}
                </FixedSizeList>
              )}
            </AutoSizer>
          </div>
        )}
      </div>
    </Paper>
  )
}

export default WellList
