import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/styles'
import { useRecoilValue } from 'recoil'

import { currentWellAtom } from 'atoms'
import { WellDepthTimeChart } from 'components/WellPages/WellDepthChart'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import { createChartDataMultiWell } from 'components/common/depthVtimeFunctions'
import useAxiosWithUnitFetch from 'components/common/hooks/useAxiosWithUnitFetch'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import { Skeleton } from '@material-ui/lab'

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: '#192734',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    minHeight: '80px',
  },
}))

const DepthTimeCard = () => {
  const classes = useStyles()
  const [data, setData] = useState([])
  const currentWell = useRecoilValue(currentWellAtom)
  const userUnits = useUnits(UNITS_FOR.Depth)

  const { newCancelToken: kpiCancelToken } = useAxiosCancelToken()
  const [, getKpiData] = useAxiosWithUnitFetch({
    url: '/kpi/depthVdays',
    cancelToken: kpiCancelToken(),
    manual: true,
  })

  useEffect(() => {
    getChartData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getChartData()
  }, [currentWell]) // eslint-disable-line react-hooks/exhaustive-deps

  const getChartData = async () => {
    if (currentWell.length < 1) {
      await setData([])
      return
    }

    let payload = {
      wellList: currentWell,
    }

    payload.phaseFilter = ''

    const dataResponse = await getKpiData(payload)
    //console.log('getDepthVDays: ', dataResponse)
    if (dataResponse?.data) {
      setData(dataResponse.data)
    } else {
      // console.log('no DvD data available')
      setData([])
    }
  }

  return (
    <div className={classes.card}>
      {data && data.length > 0 ? (
        <WellDepthTimeChart
          // title='Depth / Time'
          xAxisTitle={'Days'}
          yAxisTitle={`Depth (${userUnits})`}
          wellData={createChartDataMultiWell(data, null, null)}
          rawData={data}
        />
      ) : (
        <Skeleton variant='rect' width='100%' height='100%' animation='wave' />
      )}
    </div>
  )
}

export default DepthTimeCard
