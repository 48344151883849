import React from 'react'
import useAxios from 'components/common/hooks/useAxios'
import { useSetRecoilState } from 'recoil'

import { userUserRoleAtom } from 'atoms'

const useUserRole = () => {
  const [{ data }, fetch] = useAxios({
    url: '/user/role',
    manual: true,
  })
  const setUserRole = useSetRecoilState(userUserRoleAtom)

  React.useEffect(() => {
    if (!data) return
    setUserRole(data)
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  return [data, fetch]
}

export default useUserRole
