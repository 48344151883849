import React, { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'

import Page from '../../common/Page'
import Space from '../../common/Space'
import { currentPageAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'

import { WellSeekerAdminCard } from '..'

const WellSeekerAdminPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)

  useEffect(() => {
    setActivePage(PAGE_KEYS.adminWellSeekerKey)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page maxWidth>
      <Space style={{ width: '50%' }}>
        <WellSeekerAdminCard />
      </Space>
    </Page>
  )
}

export default WellSeekerAdminPage
