import Container from '@material-ui/core/Container'
import React from 'react'
import { actionBarWidthAtom } from '../../atoms'
import { makeStyles } from '@material-ui/core/styles'
import { useRecoilValue } from 'recoil'

const useStyles = makeStyles((theme) => ({
  container: {
    background: '#2d2d2d',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    marginLeft: (props) => props.leftPos,
    width: (props) => `calc(100% - ${props.leftPos}px)`,
    height: '100%',
  },
  maxWidth: {
    maxWidth: '100%',
  },
}))

const Page = ({ children, maxWidth, className }) => {
  const leftPos = useRecoilValue(actionBarWidthAtom)
  const classes = useStyles({ leftPos })

  let pageClassName = classes.container

  if (maxWidth) {
    pageClassName = `${pageClassName} ${classes.maxWidth}`
  }

  if (className) {
    pageClassName = ` ${className} ${pageClassName}`
  }

  return <Container className={pageClassName}>{children}</Container>
}

export default Page
