import { atom } from 'recoil'

export const wellsMapRigSelectorAtom = atom({
  key: 'wellsMapRigSelectorAtomKey',
  default: { 'Active': true, 'Upcoming': false, 'Standby': false },
})

export const STATES = 'States'
export const COUNTIES = 'Counties'
export const SHALE_PLAYS = 'Shale Plays'
export const CONVENTIONAL_PLAYS = 'Conventional Plays'

export const wellsMapLayersSelectorAtom = atom({
  key: 'wellsMapLayerSelectorAtomKey',
  default: { 'Shale Plays': true, 'Conventional Plays': false, 'States': false, 'Counties': false },
})
