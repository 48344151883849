import React, { useEffect, useRef, useState } from 'react'

import { List, ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { Checkbox } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import { makeStyles } from '@material-ui/styles'

import { appColors } from 'utils'

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.itemBackground,
  },
  headerText: {
    fontSize: 16,
    fontWeight: '800',
    color: appColors.borderColor,
    backgroundColor: appColors.itemTextColor,
    padding: '4px 8px 4px 8px',
  },
}))

const useListStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.itemBackground,
  },
}))

const PickListSection = ({ itemList, selItems, setSelItems }) => {
  const _isMounted = useRef(false)
  const classes = useListStyles()

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onItemSelect = (event, id) => {
    let newSelectedItems = [...selItems]

    if (getChecked(id) === false) {
      newSelectedItems.push(id)
      if (_isMounted.current === true) setSelItems(newSelectedItems)
    } else {
      if (Array.isArray(newSelectedItems) === true) {
        const index = newSelectedItems.indexOf(id)
        if (index > -1) newSelectedItems.splice(index, 1)
      } else {
        newSelectedItems = []
      }

      if (_isMounted.current === true) setSelItems(newSelectedItems)
    }
  }

  const getChecked = (id) => {
    if (!selItems) return false
    if (Array.isArray(selItems) === false) return false
    if (selItems.includes(id) === true) return true

    return false
  }

  return (
    <React.Fragment>
      <List dense className={classes.root}>
        {itemList?.map((value) => {
          const labelId = `checkbox-list-secondary-label-${value}`
          return (
            <ListItem key={value} button>
              <ListItemText id={labelId} primary={`${value}`} />
              <ListItemSecondaryAction>
                <Checkbox
                  edge='end'
                  onChange={(event) => onItemSelect(event, value)}
                  checked={getChecked(value)}
                  inputProps={{ 'aria-labelledby': labelId }}
                  checkedIcon={<RadioButtonCheckedIcon fontSize='small' style={{ color: 'lime' }} />}
                  indeterminateIcon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                  icon={<RadioButtonUncheckedIcon fontSize='small' style={{ color: 'red' }} />}
                />
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
      </List>
    </React.Fragment>
  )
}

const SimplePickListDialog = (props) => {
  const { title, sectionHeader, items, open, setOpen, onApply, initSelItems } = props
  const classes = useStyles()

  const [selectedItems, setSelectedItems] = useState(Array.isArray(initSelItems) === true ? initSelItems : [])

  return (
    <Dialog classes={{ paper: classes.paper }} open={open} onClose={() => setOpen(false)}>
      <DialogTitle id='pick-list-dialog'>{title}</DialogTitle>
      <DialogContent>
        {sectionHeader !== '' ? <Typography classes={{ root: classes.headerText }}>{sectionHeader}</Typography> : null}
        <PickListSection itemList={items} selItems={selectedItems} setSelItems={setSelectedItems} />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={() => setOpen(false)} color='secondary'>
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            setOpen(false)
            onApply(selectedItems)
          }}
          color='primary'>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SimplePickListDialog
