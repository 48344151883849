import { makeStyles } from '@material-ui/styles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  widgetCard: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    backgroundColor: 'rgba(32,32,32,1.0)',
    border: '1px solid black',
    boxShadow: '0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);',
    padding: '8px',
  },
  widgetHeader: {
    color: 'rgba(192,192,192,1.0)',
  },
  widgetValue: {
    color: 'rgba(52,201,235,1.0)',
    fontSize: '2em',
    fontWeight: 'bold',
  },
  widgetUnit: {
    color: 'rgba(192,192,192,1.0)',
    fontSize: '1em',
    marginLeft: '0.5em',
  },
}))

const TextWidget = ({title, value, unit}) => {
const classes = useStyles()

  return (
    <div className={classes.widgetCard}>
      <div className={classes.widgetHeader}>{title}</div>
      <div>
        <span className={classes.widgetValue}>{value}</span>
        <span className={classes.widgetUnit}>{unit}</span>
      </div>
    </div>
  )
}

export default TextWidget