import React, { useEffect, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import * as XLSX from 'xlsx'

import AnalyticsPage from '.'
import AnalyticsCard from './AnalyticsCard'
import BarChart from './BarChartCjs'
import { array2pipestr, chartSeriesColors, getXlsxColumnLetter } from 'utils'
import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'
import useAxiosWithUnitFetch from 'components/common/hooks/useAxiosWithUnitFetch'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import { analyticsPhaseFilterAtom, analyticsSearchParamsAtom, analyticsSelectedWells } from 'atoms'

const CostPerWellPage = () => {
  const _isMounted = useRef(false)

  const [data, setData] = useState([])
  const selectedWells = useRecoilValue(analyticsSelectedWells)
  const phaseFilter = useRecoilValue(analyticsPhaseFilterAtom)
  const userUnits = useUnits(UNITS_FOR.Depth)
  const searchParams = useRecoilValue(analyticsSearchParamsAtom)
  const { newCancelToken: kpiCancelToken, cancelPreviousRequest: kpiCancelRequest } = useAxiosCancelToken()
  const [{ error: kpiError }, getKpiData] = useAxiosWithUnitFetch({
    url: '/kpi/getKpis',
    cancelToken: kpiCancelToken(),
    manual: true,
  })

  const chartOptions = {}

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
      kpiCancelRequest()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (kpiError !== undefined && kpiError !== null) {
      if (kpiCancelRequest(kpiError)) return
    }
  }, [kpiError]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      //getChartData()
      getChartData()
    }
  }, [selectedWells]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current) {
      // console.log('phaseFilter: ', phaseFilter)
      getChartData()
    }
  }, [phaseFilter]) // eslint-disable-line react-hooks/exhaustive-deps

  const createChartData = (data) => {
    let output = {
      labels: [],
      datasets: [],
    }

    if (!data || !data.costByPhase) {
      return output
    }

    data.costByPhase.forEach((well) => {
      if (well.wellCost > 0) {
        output.labels.push(well.actualWell)
        if (Array.isArray(well.phases)) {
          well.phases.forEach((phase) => {
            if (output.datasets.findIndex((element) => element.label === phase.phase) < 0) {
              output.datasets.push({
                label: phase.phase,
                backgroundColor: chartSeriesColors[output.datasets.length],
                maxBarThickness: 24,
                data: [],
              })
            }
          })
        }
      }
    })

    data.costByPhase.forEach((well) => {
      if (well.wellCost > 0) {
        // let blankData = Array.from({ length: output.seriesLabels.length }, (v, i) => 0)
        if (Array.isArray(well.phases)) {
          // well.phases.forEach((phase) => {
          //   let index = output.datasets.findIndex((element) => element.label = phase.phase)
          //   if (index >= 0 && index < blankData.length) {
          //     blankData[index] = phase.phaseCost
          //   }
          // })

          output.datasets.forEach((dataset) => {
            let index = well.phases.findIndex((element) => element.phase === dataset.label)
            if (index >= 0 && index < well.phases.length) {
              dataset.data.push(well.phases[index].phaseCost)
            } else {
              dataset.data.push(0)
            }
          })
        }
      }
    })

    return output
  }

  const getChartData = async () => {
    if (selectedWells.length < 1) {
      await setData([])
      return
    }

    let payload = {
      wellList: array2pipestr(selectedWells),
      costByPhase: 'true',
    }
    let { dateFrom, dateTo } = searchParams
    if (dateFrom === '' || dateTo === '') {
      dateTo = new Date(Date.now()).toISOString()
      dateFrom = new Date(Date.now() - 1200 * 24 * 60 * 60 * 1000).toISOString()
    }
    payload.dateTo = dateTo
    payload.dateFrom = dateFrom

    // add phaseFilter to the payload if it's not empty or All
    if (phaseFilter !== 'All' && phaseFilter !== '' && phaseFilter !== '0') {
      payload.phase = phaseFilter
    } else {
      payload.phase = ''
    }
    const dataResponse = await getKpiData(payload)
    if (dataResponse?.data) {
      setData(dataResponse.data)
    } else {
      setData([])
    }
  }

  const getPhasesList = (data) => {
    let phases = []

    if (!data || !data.costByPhase) {
      return phases
    }

    data.costByPhase.forEach((well) => {
      if (well.wellCost > 0) {
        if (Array.isArray(well.phases)) {
          well.phases.forEach((phase) => {
            if (phases.findIndex((element) => element === phase.phase) < 0) {
              phases.push(phase.phase)
            }
          })
        }
      }
    })
    return phases
  }

  const onXlsxExport = () => {
    // console.log('export raw ', data)
    let colIndex = 1

    // get all phases for all wells, similar to createChartData
    const phases = getPhasesList(data)
    const phasesArr = phases.map((phase) => [phase])

    let ws = XLSX.utils.aoa_to_sheet(phasesArr, {
      origin: 'A2',
    })

    XLSX.utils.sheet_add_aoa(ws, [[`Total Cost`]], { origin: `A${phases.length + 3}` })

    if (data && data.costByPhase) {
      data.costByPhase.forEach((well) => {
        // get phase cost array for each well, note: well.phases can be null
        let phaseCosts = []
        let totalWellCost = 0.0
        phases.forEach((phase) => {
          if (well.phases) {
            let index = well.phases.findIndex((element) => element.phase === phase)
            if (index >= 0 && index < well.phases.length) {
              phaseCosts.push(well.phases[index].phaseCost)
              totalWellCost += well.phases[index].phaseCost
            } else {
              phaseCosts.push(0)
            }
          } else {
            phaseCosts.push(0)
          }
        })

        // create column of data
        let wellData = [[`${well.actualWell}`]]
        phaseCosts.forEach((phaseCost) => wellData.push([Math.round(phaseCost * 100) / 100]))
        wellData.push([''])
        wellData.push([Math.round(totalWellCost * 100) / 100])

        // add column data to sheet
        XLSX.utils.sheet_add_aoa(ws, wellData, { origin: `${getXlsxColumnLetter(colIndex)}1` })
        colIndex += 1
      })
    }
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Detailed Well Cost-Well')
    XLSX.writeFile(wb, 'Cost by Well.xlsx')
  }

  return (
    <AnalyticsPage>
      <AnalyticsCard exportXlsxCB={onXlsxExport}>
        <BarChart
          wellData={createChartData(data)}
          units={userUnits}
          title='Cost Per Well'
          xAxisTitle='Wells'
          yAxisTitle={`Cost`}
          chartOptions={chartOptions}
        />
      </AnalyticsCard>
    </AnalyticsPage>
  )
}

export default CostPerWellPage
