import { planTrajectoryColor, scale, surveyTrajectoryColor } from './ThreeDeeView'
import { pointInterp, pointInterpTVD } from 'utils/surveyInterpolation'
import { getTargets3D } from './targets3d'

import { Vector3 } from 'three'
import { threeDeeScan } from 'utils/threeDeeScan'

function checkArrayDataValid(inputData) {
  if (!inputData) return false
  if (inputData === null) return false
  if (!Array.isArray(inputData)) return false
  if (inputData.length === 0) return false

  return true
}

function getWellColor(wellType) {
  if (wellType === 'Planned') return planTrajectoryColor
  return surveyTrajectoryColor
}

function getAnnotations(outputArray, refWellData, annotations) {
  annotations.forEach((annot) => {
    const { md, ns, ew, tvd } = pointInterp(refWellData, annot.md)

    if (md >= 0) {
      outputArray.push({
        x: ns * scale,
        y: -tvd * scale,
        z: ew * scale,
        name: annot.annotation,
      })
    }
  })
}

function getFormations(outputArray, refWellData, formations) {
  for (let i = 0; i < formations.length - 1; i++) {
    const { md, ns, ew } = pointInterpTVD(refWellData, formations[i].tvd)

    outputArray.push({
      x: md >= 0 ? ns * scale : 0,
      y: -parseFloat(formations[i].tvd) * scale,
      z: md >= 0 ? ew * scale : 0,
      name: formations[i + 1].formation,
      found: md >= 0 ? true : false,
    })
  }
}

function getCasings(outputArray, refWellData, casing) {
  let csgLen = 100 //This is the length of the casing interval to be displayed
  casing.forEach((csg) => {
    const { md, ns, ew, tvd } = pointInterp(refWellData, csg.MD)
    const interpPoint2 = pointInterp(refWellData, csg.MD - csgLen > 0 ? csg.MD - csgLen : 0)

    if (md >= 0 && interpPoint2.md >= 0) {
      outputArray.push({
        x: ns * scale,
        y: -tvd * scale,
        z: ew * scale,
        x2: interpPoint2.ns * scale,
        y2: -interpPoint2.tvd * scale,
        z2: interpPoint2.ew * scale,
        name: `${csg.OD} ${csg.Type}`,
        od: csg.OD,
      })
    }
  })
}

function createWellData(surveyData, name, color) {
  if (!Array.isArray(surveyData)) return {}

  let wellData = {
    name,
    color,
    data: [],
    svyData: [],
  }

  surveyData.forEach((svy) => {
    wellData.data.push(new Vector3(svy.ns * scale, -svy.tvd * scale, svy.ew * scale))
    wellData.svyData.push(svy)
  })

  return wellData
}
export function createChartData(wellData, wellName) {
  let chartData = { refData: [], offsetData: [], formations: [], annotations: [], casing: [], targets: [] }

  let svyCopy = []
  if (checkArrayDataValid(wellData?.wellPlan?.surveyData) && checkArrayDataValid(wellData?.surveys)) {
    wellData.surveys.forEach((svy) => {
      svyCopy.push(threeDeeScan({ ...svy }, wellData.wellPlan.surveyData))
    })
  } else {
    svyCopy = wellData?.surveys
  }

  if (checkArrayDataValid(svyCopy)) chartData.refData.push(createWellData(svyCopy, wellName, getWellColor('Actual')))

  if (checkArrayDataValid(wellData?.wellPlan?.surveyData)) {
    chartData.offsetData.push(
      createWellData(wellData.wellPlan.surveyData, wellData.wellPlan.offsetWellbore, getWellColor('Planned')),
    )
  }

  if (checkArrayDataValid(wellData?.offsetWells)) {
    wellData.offsetWells.forEach((well) => {
      if (well.isPrincipal === true) return
      if (well.name === wellName) return
      chartData.offsetData.push(createWellData(well.surveyData, well.offsetWellbore, getWellColor(well.wellStatus)))
    })
  }

  if (checkArrayDataValid(wellData?.surveys)) {
    if (checkArrayDataValid(wellData.actualWellData?.casing))
      getCasings(chartData.casing, wellData.surveys, wellData.actualWellData.casing)
    if (checkArrayDataValid(wellData.actualWellData?.annotations))
      getAnnotations(chartData.annotations, wellData.surveys, wellData.actualWellData.annotations)
    if (checkArrayDataValid(wellData.actualWellData?.lithologies))
      getFormations(chartData.formations, wellData.surveys, wellData.actualWellData.lithologies)
  }

  if (checkArrayDataValid(wellData.targets)) chartData.targets = getTargets3D(wellData.targets, scale)

  return chartData
}
