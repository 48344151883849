import React, { useEffect, useRef, useState } from 'react'

import Card from '@material-ui/core/Card'
import { makeStyles } from '@material-ui/styles'
import Skeleton from '@material-ui/lab/Skeleton'

import UserList from './UserList'
import useAxios from 'components/common/hooks/useAxios'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'
import { multiParse } from 'utils'

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: '#192734',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '80%',
  },
}))

const UserAdminCard = () => {
  const _isMounted = useRef(false)
  const classes = useStyles()
  const [orgs, SetOrgs] = useState({})

  const { newCancelToken, cancelPreviousRequest } = useAxiosCancelToken()
  const [{ data, loading }] = useAxios({
    url: '/admin/getOrgIcons',
    cancelToken: newCancelToken(),
  })

  useEffect(() => {
    _isMounted.current = true

    return () => {
      _isMounted.current = false
      cancelPreviousRequest()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (_isMounted.current && data) {
      const mfpBoundary = data.substring(2, data.search('Content')).trim()
      let parts = multiParse(Buffer.from(data), mfpBoundary)
      // let parts = multipart.Parse(data, mfpBoundary)
      SetOrgs(parts)
    }
  }, [data])

  return (
    <Card className={classes.card}>
      {loading ? <Skeleton variant='text' /> : <UserList orgs={orgs} />}
    </Card>
  )
}

export default UserAdminCard
