import React, { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'

import Page from '../../common/Page'
import { currentPageAtom } from 'atoms'
import { PAGE_KEYS } from 'components/ActionBar/pageDefs'
import OrgAdminCard from './OrgAdminCard'

const OrgAdminPage = () => {
  const setActivePage = useSetRecoilState(currentPageAtom)

  useEffect(() => {
    setActivePage(PAGE_KEYS.adminInnovaOrgsKey)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page maxWidth>
        <OrgAdminCard />
    </Page>
  )
}

export default OrgAdminPage
