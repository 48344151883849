import React, { useEffect, useState } from 'react'
import map from 'lodash/map'

import Table from 'components/common/Table'
import useAxiosWithUnitFetch from 'components/common/hooks/useAxiosWithUnitFetch'
import useAxiosCancelToken from 'components/common/hooks/useAxiosCancelToken'

import useUnits, { UNITS_FOR } from 'components/common/hooks/useUnits'

import columns from '../utils'
import { FormControl, MenuItem, Select } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  selectBHA: {
    padding: 5,
    color: 'black',
    background: 'white',
  },
}))

const adjustColumnTitles = (key, units) =>
  map(columns[key], (column) => ({
    ...column,
    title: column.unit ? `${column.title} (${units[column.unit]})` : column.title,
  }))

const WellPageSlideSheet = ({ apiRoute, columnKey, filtering, search, well }) => {
  const classes = useStyles()
  const { newCancelToken, isCancel } = useAxiosCancelToken()
  const [{ data, loading, error }] = useAxiosWithUnitFetch({
    url: apiRoute,
    data: {
      wellName: well,
    },
    cancelToken: newCancelToken(),
  })
  const [selectedSS, setSS] = useState('')
  const units = {
    [UNITS_FOR.Depth]: useUnits(UNITS_FOR.Depth),
    [UNITS_FOR.Diameter]: useUnits(UNITS_FOR.Diameter),
    [UNITS_FOR.FlowRate]: useUnits(UNITS_FOR.FlowRate),
    [UNITS_FOR.Pressure]: useUnits(UNITS_FOR.Pressure),
    [UNITS_FOR.Temperature]: useUnits(UNITS_FOR.Temperature),
    [UNITS_FOR.Torque]: useUnits(UNITS_FOR.Torque),
    [UNITS_FOR.Weight]: useUnits(UNITS_FOR.Weight),
  }
  const currentColumns = adjustColumnTitles(columnKey, units)

  useEffect(() => {
    if (data) {
      setSS(data[0].bha)
    }
  }, [data])

  useEffect(() => {
    if (error !== undefined && error !== null) {
      if (isCancel(error)) return
    }
  }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSlideSelect = (event) => {
    setSS(event.target.value)
  }

  const getSlideSheet = (bhaString) => {
    if (data && selectedSS !== null && selectedSS !== '') {
      let rec = data.find((ss) => ss.bha === selectedSS)
      if (rec) {
        return rec.slideData
      }
    }
    return undefined
  }

  return (
    <React.Fragment>
      <FormControl>
        <div className={classes.selectBHA}>
          <strong>Selected BHA</strong>:{' '}
          <Select id='slideSheet-select' value={selectedSS ? selectedSS : ''} onChange={handleSlideSelect}>
            {data
              ? data.map((ss) => (
                  <MenuItem value={ss.bha} key={ss.bha}>
                    {ss.bha}
                  </MenuItem>
                ))
              : null}
          </Select>
        </div>
      </FormControl>
      <Table
        data={getSlideSheet()}
        loading={loading}
        columns={currentColumns}
        options={{
          filtering: filtering || false,
          paging: false,
          search: false,
          toolbar: false,
          showTitle: false,
          maxBodyHeight: '625px',
          rowStyle: {
            fontSize: 12,
          },
        }}
      />
    </React.Fragment>
  )
}

export default WellPageSlideSheet
